import React from 'react'
import { shape, string, func, bool, object } from 'prop-types'

import FormElement from 'components/shared/FormElement'

import { isSiretValid, isValidFrenchPhoneNumber } from 'utils/validation'

/**
 * Page used to manage a partipant :
 * - display informations
 * - edit informations
 * - create a partipant
 * 
 * @returns JSX
 */

const Informations = ({
    formFields = {},
    formEvents = {},
    showLocalErrors = {},
    handleValidation = () => {},
    handleInputChange = () => {},
    handleConnexionEmailChange = () => {},
    isCreation = false
}) => {
    return (
            <div className="l-grid">
                <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4">
                    {/* Raison Sociale */}
                    <FormElement
                        value={formFields.structureName}
                        name="structureName"
                        type="text"
                        label="Raison Sociale"
                        className="u-mg-bottom-m"
                        classNameInput="u-bg-transparent"
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                        disabled={!formEvents.editable}
                        required
                    />
                </div>
                <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-8">
                    {/* SIRET */}
                    <FormElement
                        value={formFields.structureSiret}
                        name="structureSiret"
                        type="text"
                        label="SIRET"
                        className="u-mg-bottom-m"
                        classNameInput="u-bg-transparent"
                        maxLength={14}
                        customRules={[
                            {
                                key: 'siret-invalid',
                                check: (value) => isSiretValid(value),
                                message: "Le numéro SIRET n'est pas valide."
                            }
                        ]}
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                        disabled={!isCreation}
                        required
                    />
                </div>
                <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4">
                    {/* Nom de famille */}
                    <FormElement
                        value={formFields.contactLastname}
                        name="contactLastname"
                        type="text"
                        label="Nom"
                        className="u-mg-bottom-m"
                        classNameInput="u-bg-transparent"
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                        disabled={!formEvents.editable}
                        required
                        />
                </div>
                <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4">
                    {/* Prénom */}
                    <FormElement
                        value={formFields.contactFirstname}
                        name="contactFirstname"
                        type="text"
                        label="Prénom"
                        className="u-mg-bottom-m"
                        classNameInput="u-bg-transparent"
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                        disabled={!formEvents.editable}
                        required
                        />
                </div>
                <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4">
                    {/* Téléphone */}
                    <FormElement
                        value={formFields.structurePhonenumber}
                        name="structurePhonenumber"
                        type="text"
                        label="Tél"
                        className="u-mg-bottom-m"
                        classNameInput="u-bg-transparent"
                        customRules={[
                            {
                                key: 'structure-phone-invalid',
                                check: (value) => isValidFrenchPhoneNumber(value),
                                message: "Le format de numéro de téléphone n'est pas valide."
                            }
                        ]}
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                        disabled={!formEvents.editable}
                        required
                    />
                </div>
                <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4">
                    {/* Adresse */}
                    <FormElement
                        value={formFields.structureAddress}
                        name="structureAddress"
                        type="text"
                        label="Adresse"
                        className="u-mg-bottom-m"
                        classNameInput="u-bg-transparent"
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                        disabled={!formEvents.editable}
                        required
                    />
                </div>
                <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4">
                    {/* Code Postal */}
                    <FormElement
                        value={formFields.structurePostCode}
                        name="structurePostCode"
                        type="text"
                        label="Code Postal"
                        className="u-mg-bottom-m"
                        classNameInput="u-bg-transparent"
                        customRules={[
                            {
                                key: 'structurePostCode-invalid',
                                check: (value) => {
                                    return value ? (value.toString().length === 5) : true
                                },
                                message: "Le code postal n'est pas valide."
                            }
                        ]}
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                        disabled={!formEvents.editable}
                        required
                    />
                </div>
                <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4">
                    {/* Ville */}
                    <FormElement
                        value={formFields.structureCity}
                        name="structureCity"
                        type="text"
                        label="Ville"
                        className="u-mg-bottom-m"
                        classNameInput="u-bg-transparent"
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                        disabled={!formEvents.editable}
                        required
                    />
                </div>
                <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4">
                    {/* Email de connexion */}
                    <FormElement
                        value={formFields.connexionEmail}
                        name="connexionEmail"
                        type="mail"
                        label="E-mail de connexion"
                        className="u-mg-bottom-m"
                        classNameInput="u-bg-transparent"
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleConnexionEmailChange}
                        disabled={!isCreation}
                        required
                    />
                </div>
                <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4">
                    {/* Email de contact */}
                    <FormElement
                        value={formFields.contactEmail}
                        name="contactEmail"
                        type="mail"
                        label="E-mail de contact"
                        className="u-mg-bottom-m"
                        classNameInput="u-bg-transparent"
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                        disabled={!formEvents.editable}
                        required
                    />
                </div>
                <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                    {/* Site web */}
                    <FormElement
                        value={formFields.website}
                        name="website"
                        type="text"
                        label="Site web"
                        className="u-mg-bottom-m"
                        classNameInput="u-bg-transparent"
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                        disabled={!formEvents.editable}
                    />
                </div>
            </div>
    )
}

Informations.propTypes = {
    formFields: shape({
        structureName: string,
        structureSiret: string,
        structureAddress: string,
        structureCity: string,
        structurePostCode: string,
        structurePhoneNumber: string,
        emailConnexion: string,
        beneficiaryEmail: string,
        structureEmployeeQuantity: string,
        structureEmployeeCategory: string
    }),
    setFormFields: func,
    className: string,
    formEvents: object,
    showLocalErrors: bool,
    handleValidation: func,
    handleInputChange: func,
    handleConnexionEmailChange: func,
    isCreation: bool
}

export default Informations