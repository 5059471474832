// Encapsulate axios in a service
import Axios from 'axios'
import NProgress from 'nprogress'
import jwtDecode from 'jwt-decode'
import localStorageService, { STORAGE_KEY } from 'services/localStorage'

export const API_BASE_URL_TEST = 'https://dev.ocode.team/'
export const API_BASE_URL_PROD = '/'
export const API_TIMEOUT_GET = 30000

Axios.defaults.headers.common = {
    // 'X-Requested-With': 'XMLHttpRequest',
    // 'X-CSRF-TOKEN': '',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Accept-Version': '1.0.0',
    // 'Content-Encoding': 'gzip',
}

const httpService = {
    // Axios instance
    instance: null,
    // Config
    config: {},

    setProgressBar: () => NProgress.start(),
    hideProgressBar: () => NProgress.done(true),

    async send(method, url, data = {}, headers = {}, onUploadProgress = null) {
        if (this.instance) {
            return this.instance.request({
                url,
                data,
                method,
                headers,
                onUploadProgress
            })
        }
    },

    async get(url) {
        return (await this.send('get', url, {})).data
    },

    async post(url, data = {}, onUploadProgress = null) {
        return (await this.send('post', url, data, onUploadProgress)).data
    },

    async put(url, data = {}) {
        return (await this.send('put', url, data)).data
    },

    async patch(url, data = {}) {
        return (await this.send('patch', url, data)).data
    },

    async delete(url, data = {}) {
        return (await this.send('delete', url, data)).data
    },

    // GENRIC ERROR FUNCTION --------

    catchError(e) {
       return e?.response?.data ? ({ error: e?.response?.data }) : null
    },

    // CONFIG MANAGEMENT --------

    // Save config to local storage.
    _saveConfig() {
        // @TODO: JWT should not be stored in local storage for XSS reason ?
        // See if we can set it from an httponly session cookie ?
        localStorageService.set(STORAGE_KEY, JSON.stringify(this.config))
        // console.log('saved config: ', this.config)
    },

    // Load initial config value from local storage.
    _loadConfig() {
        let _config
        try {
            _config = JSON.parse(localStorageService.get(STORAGE_KEY))
        } catch (_error) {
            _config = {}
        }
        return { ...this.config, ..._config }
    },

    clearSession() {
        this.config.token = null
        localStorageService.remove(STORAGE_KEY)
    },

    // TOKEN MANAGEMENT --------

    // Check if token is expired
    isTokenExpired() {
        if (!this.config.token) return true
        try {
            const decoded = jwtDecode(this.config.token)
            return new Date() >= new Date(decoded.exp * 1000)
        }
        catch(e) {
            return true
        }
    },

    /**
     * Returns the token configuration or try to refresh the token if expired
     * @param {*} hooks - object with hooks
     * @param {*} hooks.logout - logout function
     * @param {*} hooks.logout - addAlert function
     * @returns the token configurationx
     */
    async getToken({ logout, addAlert } = {}) {
        if (this.isTokenExpired()) {
            try {
                // If token is invalid, clear it, then refresh it
                this.config.token = null
                await this._refreshToken()
            } catch (error) {
                console.error('refresh token error: ', error)
                // authentification timeout
                if (logout) logout()
                if (addAlert) addAlert('error', 'Veuillez vous reconnecter')
            }
        }
        return this.config.token
    },

    // Get new access token from backend
    async _refreshToken() {
        if (!this.config.refreshToken) throw new Error('No refresh token')
        // token in the response will be intercepted
        // Note that if the refreshToken is invalid (not tested), then the token will remain null,
        // the user will have to manually reset all tokens (ie login)
        await this.instance.request({
            url: 'oepvapi/signin',
            data: {},
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.config.refreshToken}` }
        })
    },

    // INIT --------

    init() {
        // Init config from local storage.
        this.config = this._loadConfig()

        // Create new instance
        this.instance = Axios.create({
            baseURL: process.env.REACT_APP_MODE === 'production' ? API_BASE_URL_PROD : API_BASE_URL_TEST,
            timeout: API_TIMEOUT_GET
        })

        // Intercept the request to make sure the existing token is injected into the header
        this.instance.interceptors.request.use(async config => {
            this.setProgressBar()
            // Use fresh token
            if (!config.headers.Authorization && this.config.token) {
                const token = await this.getToken()
                if (token) config.headers.Authorization = `Bearer ${token}`
            }
            return config
        })

        // Intercept the response and...
        this.instance.interceptors.response.use(
            response => {
                this.hideProgressBar()
                // ...get the tokens if they exists and save them
                const token = response.headers.Authorization || response.data.access_token
                const refreshToken = response.data.refresh_token
                // Update config object if a token is updated
                if ((token && token !== this.config.token) || refreshToken) {
                    this.config = {
                        ...this.config,
                        token: token || this.config.token,
                        refreshToken: refreshToken || this.config.refreshToken
                    }
                    this._saveConfig()
                }
                return response
            },
            error => {
                this.hideProgressBar()
                if (error.response === undefined) {
                    return Promise.reject(error)
                }

                // Manage error response
                switch (error.response.status) {
                    case 400: // Bad Request
                    case 401: // Unauthorized error
                         // if we're not trying to login => the token must have expired
                        if (!(error.config.method === 'post')) {
                            // @todo: LOGOUT ?
                        }
                        break
                    case 419: // Authentication Timeout
                        // window.location.reload()
                        break
                    default:
                        break
                }

                return Promise.reject(error)
            }
        )
    }

}

export default httpService