import React from 'react'
import { NavLink } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import { useAuth } from 'hooks/useAuth'
import PDFCGU from 'assets/data/CGU.pdf'
import PDFConfidentialite from 'assets/data/Politique_de_Confidentialite_Plateforme_FUB_Services_17_03_2022.pdf'

const CONTACT_MAIL = 'contact@employeurprovelo.fr'
const CONTACT_MAIL_PRO = 'catalogue@employeurprovelo.fr'

const Footer = ({ className }) => {
    const { user } = useAuth()

    // Manage copy to clipboard
    const [isCopied, setIsCopied] = React.useState(false)
	const copyToClipboard = (text) => {
        copy(text.toString())
		setIsCopied(true)
		setTimeout(() => {
			setIsCopied(false)
		}, 2000)
    }

    return (
        <footer className={'c-footer u-pd-vt-m ' + (className ? className : '')}>
            <div className="l-container">
                <nav className={'u-flex u-flex-dir-col u-flex-dir-row@main u-flex-center-vt u-flex-center-hz u-flex-between@main'}>
                    <span className="u-pd-vt-xs">{new Date().getFullYear()} © O°code</span>
                    <div className="">
                        <button
                            className={'c-footer__copy-cta u-primary ' + (isCopied ? 'is-copied' : '')}
                            onClick={() => copyToClipboard(user && user?.entity_data?.gender > 2 ? CONTACT_MAIL_PRO : CONTACT_MAIL)}
                            style={{ animationDuration: '0.5s', animationDelay: '0' }}
                        >
                            <div className="c-footer__copy-cta__label">
                                <div>{user && user?.entity_data?.gender > 2 ? CONTACT_MAIL_PRO : CONTACT_MAIL}</div>
                            </div>
                            <div className="c-footer__copy-cta__hover">
                                <div>Copier l'email</div>
                            </div>
                            <div className="c-footer__copy-cta__done">
                                <div>Email copié !</div>
                            </div>
                        </button>
                    </div>
                    <div className="u-flex u-pd-vt-xs u-pd-hz-l@main">
                        {/* <NavLink to="/conditions-generales-d-utilisation">C.G.U.</NavLink>; */}
                        <a href={PDFCGU} target={'_blank'} rel="noopener noreferrer">
                            C.G.U.
                        </a>
                    </div>
                    <div className="u-flex u-pd-vt-xs u-pd-hz-l@main">
                        <a href={PDFConfidentialite} target={'_blank'} rel="noopener noreferrer">
                            Politique de confidentialité
                        </a>
                    </div>
                    <div className="u-flex u-pd-vt-xs u-pd-hz-m">
                        <NavLink to="/mentions-legales">Mentions légales</NavLink>
                    </div>
                    <div>
                        {process.env.REACT_APP_VERSION && `Version: ${process.env.REACT_APP_VERSION}` }
                    </div>
                </nav>
            </div>
        </footer>
    )
}

export default Footer
