import React, { useEffect, useState } from 'react'
import { func, shape, string } from 'prop-types'

import './styles.scss'

const SINGLE_CHOICE = 'Choix unique'

const RESPONSE_A = 'response_a'
const RESPONSE_B = 'response_b'
const RESPONSE_C = 'response_c'
const RESPONSE_D = 'response_d'

const Responses = ({
    question,
    setResponded
}) => {
    const responsesMapper = (question) => {
        const responses = new Map()
        const { response_a, response_b, response_c, response_d } = question
        if(response_a) responses.set(RESPONSE_A, { content: response_a, checked: question.userResponses ? question.userResponses.includes(RESPONSE_A) : false })
        if(response_b) responses.set(RESPONSE_B, { content: response_b, checked: question.userResponses ? question.userResponses.includes(RESPONSE_B) : false })
        if(response_c) responses.set(RESPONSE_C, { content: response_c, checked: question.userResponses ? question.userResponses.includes(RESPONSE_C) : false })
        if(response_d) responses.set(RESPONSE_D, { content: response_d, checked: question.userResponses ? question.userResponses.includes(RESPONSE_D) : false })

        return responses
    }

    const [responses, setResponses] = useState(responsesMapper(question))
    
    const isSingleChoice = question.question_type === SINGLE_CHOICE

    const toggleResponse = (key) => {
        setResponses((responses) => {
            const responsesClone = new Map(responses)
            const responseToUpdate = responsesClone.get(key)

            if(isSingleChoice) {
                responsesClone.forEach((response, key) => {
                    responsesClone.set(key, { ...response, checked: false })
                })
            }
            
            responsesClone.set(key, { ...responseToUpdate, checked: !responseToUpdate.checked })
            return responsesClone
        })
    }

    useEffect(() => {
        setResponded({ question, responses })
    }, [question.num_question, responses])

    return Array.from(responses).map(([key, response]) => (
        <button 
            key={key}
            onClick={() => toggleResponse(key)}
            className={`response u-mg-top-s u-mg-right-m ${response.checked ? 'checked' : ''}`}
        >
            {response.content}
        </button>
    ))
}

Responses.propTypes = {
    question: shape({
        response_a: string, 
        response_b: string, 
        response_c: string, 
        response_d: string
    }),
    setResponded: func
}

export default Responses