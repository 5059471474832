import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import ProtectedLayout from 'layouts/Protected'
import ArrowIcon from 'components/svg/Arrow'
import { privateRoutes } from 'router/routes'

const SuccessPage = () => {
    const location = useLocation()

    return (
        <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main">
            <div className="l-container u-relative">
                <NavLink to={'/catalogue'} className="u-flex">
                    <span className="u-flex" style={{ transform: 'rotate(180deg)' }}><ArrowIcon /></span>
                    <span className="u-mg-left-xs">Retour au catalogue</span>
                </NavLink>

                <h1 className="c-h1 u-primary u-center u-mg-vt-xl">Votre demande a bien été envoyée !</h1>

                <div className="l-container-md u-pd-vt-xl">
                    <p className="u-center u-pd-right-l@main u-mg-bottom-m u-mg-0@main">Vous pouvez désormais suivre l’évolution de votre demande dans l’onglet <br />« suivi de mes activités » de votre compte. </p>
                </div>

                <div className="u-flex u-flex-center-hz u-flex-dir-col u-flex-dir-row-reverse@main u-pd-vt-xl">
                    <NavLink
                        to={location?.state?.urlRedirect || privateRoutes.monitoringActivities.benefits.path}
                        className="c-btn c-btn--secondary u-pd-hz-l u-mg-bottom-m u-mg-hz-xl@main"
                    >
                        Suivre ma demande
                    </NavLink>
                    
                    <NavLink
                        to={'/'}
                        className="c-btn c-btn--primary u-pd-hz-l u-mg-bottom-m u-mg-hz-xl@main"
                    >
                        Revenir à l'accueil
                    </NavLink>
                </div>

            </div>
        </ProtectedLayout>
    )
}

export default SuccessPage
