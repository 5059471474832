import React from "react"

const EyeHideIcon = ({ size }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 100 100" style={{ width: size, height: size }} aria-hidden="true">
			<rect width='100' height='100' fill="none"/>
			<path fill="currentColor" d='M48.57,78.21c-1.18,0-2.36,0-3.54-.13C18.65,76.19,2.16,53.89,1.47,52.94L.4,51.48,1.47,50C2.16,49.06,18.65,26.76,45,24.87,62.83,23.6,80.62,32,97.9,49.73l1.7,1.75-1.7,1.74C81.77,69.81,65.19,78.21,48.57,78.21ZM6.69,51.48c4.07,4.86,18.43,20.18,38.74,21.62C61.11,74.2,77,66.93,92.59,51.48,77,36,61.11,28.75,45.43,29.85,25.13,31.29,10.76,46.61,6.69,51.48Z' />
			<path fill="currentColor" d='M50.1,70.48a19,19,0,1,1,19-19A19,19,0,0,1,50.1,70.48Zm0-33a14,14,0,1,0,14,14A14,14,0,0,0,50.1,37.45Z' />
			<polygon fill="currentColor" points='18.23 85.35 88.84 14.74 81.77 14.74 11.16 85.35 18.23 85.35' />
		</svg>
	)
}

export default EyeHideIcon
