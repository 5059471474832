import React from 'react'
import { bool, func, number, string } from 'prop-types'

import EyeSeeIcon from 'components/svg/EyeSee'

import './styles.scss'

const InformationToVisualize = ({ 
    className = '',
    label = '', 
    iconColor = 'red', 
    iconSize = 40,
    url, 
    onClickToAction,
    asCol = false 
}) => {

    const displayDocument = () => window.open(url || '', '_blank').focus()

    return (
        <div 
            className={`u-flex ${asCol ? 'u-flex-dir-col u-flex-center-vt' : 'u-flex-dir-row u-flex-baseline-vt' } ${className}`}
        >
            <div className="u-grey90 u-fs-xs">
                {label}
            </div>
            <button className="u-mg-left-m" type="button" onClick={onClickToAction || displayDocument} >
                <EyeSeeIcon color={iconColor} size={iconSize} className="vertical-align" />
            </button>
        </div>
    )
}

InformationToVisualize.propTypes = {
    className: string,
    label: string,
    url: string,
    iconColor: string,
    iconSize: number,
    onClickToAction: func,
    asCol: bool,
}

export default InformationToVisualize