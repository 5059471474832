import React from 'react'
import { arrayOf, func, object } from 'prop-types'
import { useTable } from 'react-table'

import { quoteStatusList } from 'utils/constants'

import Emoji from 'components/shared/Emoji'

const BidTable = ({ data, request, validateBid }) => {

    // providers table
    const columns = React.useMemo(
        () => [
            {
                Header: 'Nom du fournisseur',
                accessor: 'provider_data.structureName',
            },
            {
                Header: 'Téléphone',
                accessor: 'provider_data.contactPhonenumber',
            },
            {
                Header: 'Adresse e-mail',
                accessor: 'provider_data.contactEmail',
            }
        ],
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows
    } = useTable(
        {
            columns,
            data
        }
    )

    const getColumnSize = React.useCallback((columnName) => {
        // sum should be 24
        switch (columnName) {
            case columns[0].accessor:
                return 'c-table__cell--6'
            case columns[1].accessor:
                return 'c-table__cell--3'
            case columns[2].accessor:
                return 'c-table__cell--6'
            // case "properties.natlscale":
            //     return "c-table__cell--6"
            default:
                return ''
        }
    }, [])

    const providerStatus = (status) => {
        return quoteStatusList.find(i => i.value === status)?.id
    }

    return (
        <>
            <table className="c-table c-table--secondary c-table--responsive" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr className="c-table__row" {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th className={'c-table__cell u-pd-m ' + getColumnSize(column?.id)} {...column.getHeaderProps()}>
                                    <span className={'u-ellipsis'}>{column.render('Header')}</span>
                                </th>
                            ))}
                            <th className={'c-table__cell c-table__cell--9 u-pd-m'}>
                                <span className={'u-ellipsis'}>Devis</span>
                            </th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)
                        return (
                            <tr className={'c-table__row u-pd-m u-pd-0@main'} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td className={'c-table__cell u-pd-m@main ' + getColumnSize(cell?.column?.id)} {...cell.getCellProps()}>
                                            <span className={'u-ellipsis'}>{cell.render('Cell')}</span>
                                        </td>
                                    )
                                })}
                                <td className={'c-table__cell u-pd-m@main c-table__cell--9'}>
                                    {(providerStatus(row?.original?.quote_status) === 'choosen-provider') &&
                                        <p className="u-italic">Demande envoyée au fournisseur</p>
                                    }
                                    {(providerStatus(row?.original?.quote_status) === 'provided-bid') &&
                                        <div className="u-flex u-flex-center-hz u-flex-between@main">
                                            <a
                                                className={'c-btn c-btn--white-bordered u-pd-hz-xl u-mg-hz-s'}
                                                target="_blank" rel="noopener noreferrer"
                                                href={row?.original?.quote_full_media_url}
                                                disabled={!row?.original?.quote_full_media_url}
                                            >
                                                Consulter
                                            </a>
                                            <button
                                                type="button"
                                                className="c-btn c-btn--primary u-pd-hz-m u-mg-hz-s"
                                                onClick={() => validateBid({
                                                    request,
                                                    selectedProviderId: row?.original?.request_provider_id
                                                })}
                                            >
                                                Valider
                                            </button>
                                        </div>
                                    }
                                    {(providerStatus(row?.original?.quote_status) === 'validated-bid') &&
                                        <a
                                            className={'c-btn c-btn--primary u-pd-hz-xl u-mg-hz-s'}
                                            target="_blank" rel="noopener noreferrer"
                                            href={row?.original?.quote_full_media_url}
                                            disabled={!row?.original?.quote_full_media_url}
                                        >
                                            Consulter le devis validé
                                        </a>
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            { data.length === 0 &&
                <div className="u-bg-white u-primary u-center u-pd-hz-m u-pd-vt-l">
                    Aucun prestataire n'existe pour cette demande <Emoji label="visage triste" symbol="😟" />
                </div>
            }
        </>
    )
}

BidTable.propTypes = {
    data: arrayOf(object),
    request: object,
    validateBid: func
}

export default BidTable