import * as ReactIs from 'react-is'
import * as Ibantools from 'ibantools'

export const isDefined = (obj) => {
    return typeof (obj) !== 'undefined' && obj !== null
}

export const isFileNotEmpty = (obj) => {
    return isNotEmpty(obj) && Object.keys(obj).length > 0
}

export const isObjectEmpty = (obj) => {
    return Object.entries(obj).length <= 0
}

export const isNotEmpty = (obj) => {
	return typeof obj !== 'undefined' && obj !== null && obj !== '' && obj.length !== 0
}

export const isNotEmptyNumber = (obj) => {
	return Number(obj) !== 0
}

export const isPasswordValid = (str) => {
    const reg = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\W]{8,}$/)
    return !(str.match(reg) == null)
}

export const isEmailValid = (str) => {
    const reg = new RegExp(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{1,15}|[0-9]{1,3})(\]?)$/)
    return !(str.match(reg) == null)
}

export const isAccountNumberValid = (str) => {
    const reg = new RegExp(/^\d{11}$/)
    return !(str.match(reg) == null)
}

export const isInt = (str) => {
    const reg = new RegExp(/^\d+$/)
    return !(str.match(reg) == null)
}

export const isArray = (obj) => {
    return Array.isArray(obj)
}

export const isReactElement = (obj) => {
    return ReactIs.isElement(obj)
}

export const isSiretValid = (siret) => {
    const size = 14
    if (isNaN(siret) || siret.length !== size) return false
    let bal = 0
    let total = 0
    for (let i = size - 1; i >= 0; i--) {
        const step = (siret.charCodeAt(i) - 48) * (bal + 1)
        total += (step > 9) ? step - 9 : step
        bal = 1 - bal
    }
    // <!> Cas spécial lié aux établissements La Poste, la somme simple des chiffres doit être un multiple de 5
    return (total % 10 === 0 || sumOfDigits(siret) % 5 === 0) ? true : false
}

function sumOfDigits(num) {
    let sum = 0
    num = num.toString()
    for (let i = 0; i < num.length; i++) {
      sum += parseInt(num.charAt(i))
    }
    return sum
  }

export const isRnaValid = (rna) => {
    // start with W + 9 numbers
    const reg = new RegExp(/^[w,W]\d{9}$/)
    return !(rna.match(reg) == null)
}

export const isValidFrenchPhoneNumber = (phonenumber) => {
    const metropolitanFranceReg = new RegExp(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/)
    const overseasFranceReg = new RegExp(/^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))\s*[1-9](?:[\s.-]*\d{2}){3,4}$/)
    // src: https://en.wikipedia.org/wiki/Telephone_numbers_in_France
    // 262, 263 = La Réunion, Mayotte and IO territories ; 508 = Saint-Pierre-et-Miquelon
    // 590 = Guadeloupe, Saint-Martin et Saint-Barthélemy ; 594 = French Guiana (Guyane) ; 596 = Martinique
    // 681 = Wallis-et-Futuna ; 687 = Nouvelle-Calédonie
    // 689 = French Polynesia
    return !(phonenumber?.match(metropolitanFranceReg) === null) || !(phonenumber?.match(overseasFranceReg) === null)
}

export const isIBANValid = (str) => {
    const cleanStr = str.replace(/\s/g, '')
    return Ibantools.isValidIBAN(cleanStr)
}
export const isBICValid = (str) => {
    const cleanStr = str.replace(/\s/g, '')
    return Ibantools.isValidBIC(cleanStr)
}