import React from 'react'
import { string, func, array, number, bool } from 'prop-types'
import FormElement from './FormElement'

const Comment = ({ 
  value, 
  showLocalErrors,
  handleValidation,
  handleInputChange,
  placeholder,
  maxLength,
  customRules
}) => {
  return (
      <FormElement
        value={value}
        name="comment"
        label="Commentaire"
        type="textarea"
        className={'u-pd-top-l'}
        classNameInput={'u-b-base u-mg-vt-m'}
        rows={'10'}
        placeholder={placeholder}
        showErrors={showLocalErrors}
        onValidate={handleValidation}
        onChange={handleInputChange}
        maxLength={maxLength}
        customRules={customRules}
      />
  )
}

Comment.propTypes = {
  value: string,
  showLocalErrors: bool,
  handleValidation: func,
  handleInputChange: func,
  placeholder: string,
  maxLength: number,
  customRules: array
}

export default Comment
