import React from 'react'
import Tabs from 'components/shared/Tabs'
import Benefit from './Benefit'
import { privateRoutes } from 'router/routes'
import CatalogRoutes from 'components/catalog/CatalogRoutes'

const BenefitsMonitoring = () => {

    const benefitsTabs = [
        {
            label: 'Équipements de stationnement',
            url: `${privateRoutes.monitoringActivities.benefits.path}/${CatalogRoutes[0].url}`
        },
        {
            label: 'Formations à la pratique du vélo',
            url: `${privateRoutes.monitoringActivities.benefits.path}/${CatalogRoutes[1].url}`
        },
        {
            label: 'Prestations de maintenance et de réparations',
            url: `${privateRoutes.monitoringActivities.benefits.path}/${CatalogRoutes[2].url}`
        },
        {
            label: 'Conseil et accompagnement',
            url: `${privateRoutes.monitoringActivities.benefits.path}/${CatalogRoutes[3].url}`
        }
    ]

    return (
        <>
            <Tabs tabsContent={benefitsTabs} isNested></Tabs>
            <Benefit></Benefit>
        </>
    )
}

export default BenefitsMonitoring