import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import ProtectedLayout from 'layouts/Protected'
import ArrowIcon from 'components/svg/Arrow'
import CatalogRoutes from 'components/catalog/CatalogRoutes'

const BidPage = () => {
    const { slug } = useParams()
    const route = CatalogRoutes.find(tab => tab.url === slug)

    return (
        <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main">
            <div className="l-container u-relative">
                <NavLink to={`/catalogue/${slug}`} className="u-flex">
                    <span className="u-flex" style={{ transform: 'rotate(180deg)' }}><ArrowIcon /></span>
                    <span className="u-mg-left-xs">Retour ({route?.label})</span>
                </NavLink>

                {route?.componentBid && React.createElement(route?.componentBid)}

            </div>
        </ProtectedLayout>
    )
}

export default BidPage
