import React from 'react'
import { func, number, object, shape } from 'prop-types'

import { useModal } from 'hooks/useModal'

import ModalConfirmation from 'components/shared/ModalConfirmation'

import certificationUtil, { CERTIFICATION_LEVELS } from 'utils/domain/certification'
import dateService from 'services/date'

import './styles.scss'

const CertificationCell = (cell, status, updateData) => {
  const { showModal, hideModal } = useModal()
  const isEnable = cell?.isEnable && status !== null

  const handleEditCertificationLevel = (originalRowValues, newCertificationLevel) => {
    const entityId = originalRowValues.entity_id
    const auditId = originalRowValues.audit_id
    const auditStatus = originalRowValues.audit_status
    const auditDate = originalRowValues.audit_date
    const auditMedias = originalRowValues.audit_media

    // payload
    const auditToUpdate = {
      audit_status: auditStatus,
      audit_label: newCertificationLevel,
      entity_id: entityId,
      audit_media: auditMedias,
      audit_date: dateService.formatDate(auditDate, 'yyyy-MM-dd HH:mm:ss')
    }

    let modalTitle = ''
    if (newCertificationLevel === CERTIFICATION_LEVELS[0].value) modalTitle = 'Êtes-vous sûr de ne pas vouloir attribuer de label à ce bénéficiaire ?'
    else modalTitle = `Êtes-vous sûr de vouloir attribuer le label ${certificationUtil.getLabelByCertificationLevel(newCertificationLevel).toLowerCase()} à ce bénéficiaire ?`

    showModal(ModalConfirmation,
      {
        title: modalTitle,
        validate: async () => {
          await updateData(auditId, auditToUpdate)
          hideModal()
        }
      },
      { isClosable: false }
    )
  }

  return (
    <>
      {isEnable &&
        <select
          value={status}
          aria-label="Niveau de certification"
          className={`audit-certification ${certificationUtil.getClassNameByCertificationLevel(status)}`}
          onChange={(e) => handleEditCertificationLevel(cell.row.original, parseInt(e.target.value))}
        >
          {certificationUtil.getAllCertificationsHTMLOptions()}
        </select>}
    </>
  )
}

CertificationCell.propTypes = {
  cell: object.isRequired,
  value: number.isRequired,
  column: shape({
    updateData: func.isRequired
  }).isRequired
}

export default CertificationCell