import React from 'react'
import { string, node } from 'prop-types'

import { PRIMARY_COLOR } from 'utils/icons'
import InfoIcon from 'components/svg/Info'

const Card = ({ title, status, children, className = '' }) => (
    <div className={`c-card u-pd-l ${className}`}>
        <div className="u-flex u-flex-dir-col">
            {title && (
                <div className="u-mg-bottom-s u-blue u-fs-m u-bold">
                    {title}
                </div>
            )}
            {status && (
                <div className="u-flex u-flex-dir-row u-mg-bottom-l u-blue u-fs-s u-flex-center-vt">
                    <InfoIcon color={PRIMARY_COLOR} className="u-mg-right-s"/> {status}
                </div>
            )}
        </div>
        {children}
    </div>
)

Card.propTypes = {
    title: string,
    status: string,
    className: string,
    children: node,
}

export default Card