import React from "react"
import { isReactElement } from "utils/validation"

const Checkbox = ({
    // Attributes
    value,
    name,
    label = "",
    checkboxLabel = "",
    disabled,
    readonly = false,
    // Styles
    style,
    className = "",
    classNameLabel = "",
    classNameInput = "",
    classNameOption = "u-flex-center-vt",
    // Error management
    required = false,
    customRules,
    showErrors = false,
    // Handler
    onChange,
    onValidate
}) => {
    const [errors, setErrors] = React.useState([])

    const rules = React.useMemo(() => [
        {
            key: "checkbox-not-checked",
            check: (value) => required ? !!value : true,
            message: "Ce champ est obligatoire"
        },
        ...(customRules || [])
    ], [required]) // eslint-disable-line
    // customRules in dependencies array trigger an infinite loop,
    // because custom rules include a check property defining a new function,
    // preventing useMemo to return a valid shallow-comparison
    // or event useDeepMemo to return a valid deep-comparison

    const validate = React.useCallback((currentValue) => {
        let newErrors = []
        rules.forEach(rule => {
            if (!rule.check(currentValue)) {
                newErrors.push({
                    origin: name,
                    rule: rule.key,
                    message: rule.message
                })
            }
        })
        setErrors(newErrors)
        if (onValidate) onValidate(name, newErrors)
    }, [rules, name, onValidate])

    // validate on mount and each time value change
    React.useEffect(() => {
        validate(value)
        return () => {
            // On unmounted erros and validation are no longer needed
            setErrors([])
            if (onValidate) onValidate(name, [])
        }
    }, [value, validate, onValidate, name])

    return (
        <>
            <div className={"c-form-group " + className} style={style}>
                {label && <label className={"c-label u-mg-bottom-xs " + classNameLabel}>{label} {required && "*"}</label>}
                <label
                    htmlFor={name}
                    className={"u-flex " + classNameOption + (value  ? " is-checked" : "")}
                >
                    <input
                        className={"c-checkbox u-mg-right-m " + classNameInput}
                        type="checkbox"
                        value={value}
                        checked={value}
                        name={name}
                        id={name}
                        disabled={disabled}
                        readOnly={readonly}
                        required={required}
                        onChange={(e) => !readonly && onChange(e)}
                        onBlur={() => validate(value)}
                    />
                    <p className="u-fs-xs u-mg-0">
                        {checkboxLabel && (
                            isReactElement(checkboxLabel) ? React.cloneElement(checkboxLabel) : checkboxLabel
                        )}
                    </p>
                </label>

                {/* Error messages */}
                {showErrors && errors && errors.map((error) => {
                    return <p key={error.message} className="u-fs-xs u-danger">{error.message}</p>
                })}

            </div>
        </>
    )
}


export default Checkbox
