import React from 'react'

const Step5 = () => (
    <div className="u-pd-top-xl">
        <div className="u-primary u-bold u-mg-bottom-m">
            Le vol de vélo est un des principaux freins à son usage. C'est pourquoi il est nécessaire que votre site soit équipé d'un stationnement vélo pratique, sûr, confortable et capacitaire pour que chacun puisse attacher son vélo.
        </div>
        {/* TODO add guide link */}
        Sur le site du programme, vous trouverez <a className="u-primary u-bold u-underline" href="https://employeurprovelo.fr/objectif-employeur-pro-velo/ressources" target="_blank" rel="noreferrer">un guide</a>  vous expliquant ce qu'est un bon espace de stationnement pour les vélos. 

        <ul className="u-pd-left-l u-mg-top-m u-mg-bottom-m">
            <li>            
                Le matériel doit en particulier être de type arceau pour permettre d'attacher à la fois le cadre et une roue (évitez les pince-roues ou rateliers). 
            </li>
            <li>
                La mise en place d'un dispositif de stationnement répondant aux exigences du programme, est un critère obligatoire pour obtenir la labellisation Employeur Pro-Vélo.
            </li>
            <li>
                De plus, encourager l'usage du vélo c'est libérer de l'espace sur votre site car sur une place de stationnement automobile on peut attacher 6 à 8 vélos!
            </li>
        </ul>

        Des équipements complémentaires comme <span className="u-secondary u-bold">des casiers, un vestiaire ou des douches</span> améliorent le confort de la pratique du vélo.   
    </div> 
)

export default Step5


