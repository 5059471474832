import React, { useEffect, useState } from 'react'

import dateService from 'services/date'
import { useAuth } from 'hooks/useAuth'
import ProtectedLayout from 'layouts/Protected'
import Roadmap from 'components/shared/Roadmap'
import { privateRoutes } from 'router/routes'
import { isCommitmentCharterAlreadySigned } from 'utils/domain/user'
import { getMyScopingMeeting } from 'services/api/scoping-meeting/ScopingMeetingApi'
import { getRequests } from 'services/api/CatalogApi'
import * as AuditApi from 'services/api/audit/auditApi'
import { useAlert } from 'hooks/useAlert'
import { REALIZED_STATUS as SCOPING_MEETING_REALIZED_STATUS, PAID_STATUS as SCOPING_MEETING_PAID_STATUS, hasAScopingMeeting } from 'utils/domain/scopingMeetings'
import { REALIZED_STATUS as AUDIT_REALIZED_STATUS, PAID_STATUS as AUDIT_PAID_STATUS } from 'utils/domain/audit.js'
import { NO_CERTIFICATION, WAITING_CERTIFICATION } from 'utils/domain/certification'
import AutoDiagProvider, { useAutoDiag } from 'pages/protected/Autodiag/context'
import { isObjectEmpty } from 'utils/validation'

import illustration from 'assets/images/services-educatifs-mobilite.jpg'


const getSteps = ({
    user,
    scopingMeeting,
    audit,
    lastRequest,
    autoDiagForFill,
    lastAutoDiagCompleted,
}) => {

    // The master mail is a unique mail that is allowed to infringe roadmap steps
    const isMasterEmail = user.entity_data?.mail === process.env.REACT_APP_MASTER_EMAIL
    const isLabeled = audit?.audit_label && audit?.audit_label !== NO_CERTIFICATION
    const isWaitingLabelisation = audit?.audit_label === WAITING_CERTIFICATION || (isObjectEmpty(audit) && Boolean(audit?.audit_media?.documents.find(document => document.media_gender === 'LABEL_AUDIT')))
    const framingMeetingStatus = scopingMeeting?.framing_meeting_status
    const auditStatus = audit?.audit_status

    return [
        {
            index: 1,
            title: 'Réaliser mon autodiagnostic',
            description: 'En réalisant cette étape, vous mesurez la maturité cyclable de votre établissement en répondant à une série de questions élaborées par nos experts vélo. A l’issue de cet « autodiagnostic », vous découvrez votre potentiel à être labellisé « Employeur Pro-Vélo » !',
            date: Boolean(lastAutoDiagCompleted) ? `Autodiagnostic réalisé le ${dateService.formatDate(lastAutoDiagCompleted.modify_date)}` : '',
            state: Boolean(lastAutoDiagCompleted) || isMasterEmail,
            linkText: autoDiagForFill?.diag_id?.toString() ? 'Reprendre mon autodiagnostic en cours' : 'Commencer mon autodiagnostic',
            link: privateRoutes.autoDiag.navLink({ step: privateRoutes.autoDiag.paramsAllowed[0] }),
        },
        {
            index: 2,
            title: 'Planifier ma réunion de cadrage',
            description: 'La réunion de présentation du parcours d’accompagnement proposé par le Programme est une étape importante vers la labellisation. En physique ou à distance, nos partenaires vélo vous présentent plus en détails les résultats de votre autodiagnostic et vous décrivent notre catalogue de prestations d’accompagnement.',
            date: (framingMeetingStatus === SCOPING_MEETING_REALIZED_STATUS || framingMeetingStatus === SCOPING_MEETING_PAID_STATUS) ? `Réunion de cadrage réalisée le : ${dateService.formatDate(scopingMeeting.framing_meeting_date)}` : '',
            state: framingMeetingStatus === SCOPING_MEETING_REALIZED_STATUS || framingMeetingStatus === SCOPING_MEETING_PAID_STATUS || isMasterEmail,
            linkText: hasAScopingMeeting(scopingMeeting) ? 'Suivre ma réunion' : 'Planifier ma réunion',
            link: hasAScopingMeeting(scopingMeeting) ? privateRoutes.monitoringActivities.scopingMeeting.path : privateRoutes.scopingMeeting.path,
        },
        {
            index: 3,
            title: 'Accéder au catalogue du programme OEPV',
            description: 'Cette étape se réalise en quelques clics ! Pour bénéficier d’un accompagnement au Programme et du cofinancement, il est nécessaire de signer en ligne la charte d’accès au programme OEPV.',
            date: isCommitmentCharterAlreadySigned(user) ? `Charte d'accès signée le : ${dateService.formatDate(user?.entity_data?.charte_accept)}` : '',
            state: isCommitmentCharterAlreadySigned(user) || isMasterEmail,
            linkText: isCommitmentCharterAlreadySigned(user) ? 'Voir le document' : 'Signer la charte',
            link: isCommitmentCharterAlreadySigned(user) ? privateRoutes.monitoringActivities.commitmentCharter.path : privateRoutes.commitmentCharter.path,
        },
        {
            index: 4,
            title: 'Accéder aux prestations du catalogue',
            description: 'Vous pouvez demander des prestations depuis notre catalogue ! Vos places de stationnement, vos séances de sensibilisation à l’usage du vélo ou encore les ateliers d’entretien des vélos pour vos salariés ne sont plus qu’à quelques clics. ',
            date: lastRequest?.create_date ? `Dernière prestation demandée le : ${dateService.formatDate(lastRequest.create_date)}` : '',
            state: auditStatus || isMasterEmail,
            linkText: lastRequest?.create_date ? 'Commander de nouvelles prestations' : 'Accéder aux prestations',
            link: privateRoutes.catalog.path,
        },
        {
            index: 5,
            title: 'Demander mon audit de labellisation',
            description: 'C’est la dernière étape avant la labellisation. L’audit, réalisé par des organismes agréés, vous permet d’obtenir votre label « Employeur Pro-Vélo » selon 3 niveaux : or, argent ou bronze.',
            date: (auditStatus === AUDIT_REALIZED_STATUS || auditStatus === AUDIT_PAID_STATUS) ? `Audit réalisé le : ${dateService.formatDate(audit.audit_date)}` : '',
            state: ((auditStatus === AUDIT_REALIZED_STATUS || auditStatus === AUDIT_PAID_STATUS) && !isWaitingLabelisation) || isMasterEmail,
            linkText: auditStatus ? 'Suivre mon audit' : 'Demander mon audit de labellisation',
            link: auditStatus ? privateRoutes.monitoringActivities.audit.path : privateRoutes.audit.path,
        },
        {
            index: 6,
            title: 'Accéder au label',
            description: isLabeled
                ? 'Téléchargez votre label « Employeur Pro-Vélo » ainsi que votre kit de communication associé !'
                : 'Votre audit est terminé et il ne vous permet malheureusement pas d\'être certifié Employeur Pro-vélo. Pour plus de détails, veuillez consulter votre rapport d\'audit en cliquant sur "Suivi de mes activités"',
            state: false, // last step, left to false
            linkText: 'Accéder au label',
            link: isLabeled ? privateRoutes.label.path : null,
        }
    ]
}

const HomeClientWithProviderPage = () => {
    const { user } = useAuth()
    const { addAlert } = useAlert()
    const [roadmapIsReady, setRoadmapIsReady] = React.useState(false)
    const [myScopingMeeting, setMyScopingMeeting] = useState({})
    const [myAudit, setMyAudit] = useState({})
    const [lastRequest, setLastRequest] = useState({})
    const { getAutoDiagForFill, getLastAutoDiagCompleted, autodiagsCollection } = useAutoDiag()

    const fetchMyScopingMeeting = async () => {
        try {
            const { res: scopingMeeting, getError } = await getMyScopingMeeting()
            if (getError()) throw getError()

            setMyScopingMeeting(scopingMeeting)
        } catch (error) {
            addAlert('error', error?.toString())
        }
    }

    const fetchMyAudit = async () => {
        try {
            const { res: audit, getError } = await AuditApi.getMyAudit()
            if (getError()) throw getError()

            setMyAudit(audit)
        } catch (error) {
            addAlert('error', error?.toString())
        }
    }

    const fetchMyRequests = async () => {
        try {
            const { res: requests, getError: getRequestsError } = await getRequests()
            if (getRequestsError()) throw getRequestsError()

            if (requests) {
                setLastRequest(requests[0])
            }
        } catch (error) {
            addAlert('error', error?.toString())
        }
    }

    const fetchData = async () => {
        await Promise.all([fetchMyScopingMeeting(), fetchMyAudit(), fetchMyRequests()])
        setRoadmapIsReady(true)
    }

    useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main">
            <div className="u-flex u-flex-center-vt u-pd-hz-xxl">
                <img alt="" src={illustration} style={{ width: 400 }} />
                <h1 className="c-h1 u-secondary u-bold u-center u-uppercase u-mg-left-l">Mon parcours vers la labellisation</h1>
            </div>

            {roadmapIsReady ?
                <Roadmap steps={getSteps({
                    user,
                    scopingMeeting: myScopingMeeting,
                    audit: myAudit,
                    lastRequest,
                    autoDiagForFill: getAutoDiagForFill(),
                    lastAutoDiagCompleted: getLastAutoDiagCompleted(),
                    autodiagsCollection
                })} />
                :
                <div className="u-center u-pd-vt-xl">
                    <div className="c-spinner"></div>
                </div>
            }
        </ProtectedLayout>
    )
}


const HomeClientPage = () => {
    const { user } = useAuth()
    return (
        <AutoDiagProvider autodiagUser={user}>
            <HomeClientWithProviderPage />
        </AutoDiagProvider>
    )
}

export default HomeClientPage
