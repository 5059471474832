import httpService from 'services/http'

import {
    getError,
    getEntitiesError,
    getYourselfAssignFolderError,
    getBackOfficeFoldersError,
    getRequestError,
    getBackOfficeEntityError,
    getBackOfficeBeneficiariesError,
    checkEntityBackOfficeRequestError,
    editBackOfficeAdvisorError,
    editBackOfficeRequestError,
    getCreateRequestError,
    getEditRequestError,
    getRequestsError,
    getBackendRequestsError,
    getRequestProvidersError,
    getRequestHistoryError,
    getBackOfficeRequestHistoryError,
    getValidateProviderEmailError,
    editBackOfficeEntityError,
    getBackOfficeCommentError,
    // regularisation
    getCreateRegularisationFolderError,
    getRegularisationFoldersError,
    getCreateProviderDataError,
    getProviderEquipmentsError,
    getEditEquipmentSupply,
    getRequestEquipmentsError,
    getAllEquipmentsSupplyListError
} from 'services/api/errors'

const ENTITIES_RESPONSE_LIMITATION = 200 // A maximum of 200 entities is return by the route oepvapi/entities

const getEntities = async ({
    gender: entityGender,
    services: entityServices,
    query: entityQuery,
    createBefore: createdBeforeQuery,
    activeEntities
} = {}) => {
    const genderParam = entityGender?.toString() ? `gender=${entityGender}` : null
    const servicesParam = entityServices?.toString() ? `services=${entityServices}` : null
    const queryParam = entityQuery?.toString() ? `query=${entityQuery}` : null
    const createdBeforeParm = createdBeforeQuery?.toString() ? `created_before=${createdBeforeQuery}` : null
    const activeParam = activeEntities ? 'active=true' : null
    const params = [genderParam, servicesParam, queryParam, createdBeforeParm, activeParam].filter(param => !!param).join('&')
    const res = await httpService.get(`oepvapi/entities?${params}`).catch(e => httpService.catchError(e))
    // console.log('getEntities: ', entityGender, entityServices, res)

    // oepvapi/entities API route is limited to 200 response. To get all entities, we use a loop do/while.
    let isMustAnotherCall = false
    if(res?.length === ENTITIES_RESPONSE_LIMITATION) {
        do {
        const { create_date } = res[res.length - 1]
        const newCreatedBeforeParam = create_date?.toString() ? `created_before=${create_date}` : null
        const params = [genderParam, servicesParam, queryParam, newCreatedBeforeParam].filter(param => !!param).join('&')
        const nextRes = await httpService.get(`oepvapi/entities?${params}`).catch(e => httpService.catchError(e))
        isMustAnotherCall = nextRes.length === ENTITIES_RESPONSE_LIMITATION
        res.push(...nextRes)
        } while (isMustAnotherCall)
    }

    return {
        res,
        getError: getError(res, getEntitiesError)
    }
}

const getEntitiesLocation = async ({
    services: entityServices,
    latitude,
    longitude,
    mapRadius,
    activeEntities
} = {}) => {
    const servicesParam = entityServices?.toString() ? `services=${entityServices}` : null
    const locationParam = `latitude=${latitude}&longitude=${longitude}&radius=${mapRadius}000`
    const activeParam = activeEntities ? 'active=true' : null
    const params = [servicesParam, locationParam, activeParam].filter(p => !!p).join('&')
    const res = await httpService.get(`oepvapi/entities_location?${params}`).catch(e => httpService.catchError(e))
    // console.log('getEntities by location res: ', entityServices, res)
    return {
        res,
        getError: getError(res, getEntitiesError)
    }
}

const createRequest = async (request) => {
    const res = await httpService.post('oepvapi/request', request, {}).catch(e => httpService.catchError(e))
    // console.log('createRequest res: ', res)
    return {
        res,
        getError: getError(res, getCreateRequestError),
    }
}

const editRequest = async (editedRequest) => {
    const res = await httpService.put('oepvapi/request', editedRequest).catch(e => httpService.catchError(e))
    // console.log('editRequest res: ', res)
    return {
        res,
        getError: getError(res, getEditRequestError),
    }
}

const getRequests = async (requestGender) => {
    // requestGender filters on service types (équipements, mobilité, services techniques, ...)
    const res = await httpService.get(`oepvapi/requests${typeof(requestGender) !== 'undefined' ? `?gender=${requestGender}` : ''}`).catch(e => httpService.catchError(e))
    // console.log('getRequests res: ', requestGender, typeof(requestGender), res)
    return {
        res,
        getError: getError(res, getRequestsError)
    }
}


const getRequest = async (requestId) => {
    const res = await httpService.get(`oepvapi/request_by_fub_id/${requestId}`).catch(e => httpService.catchError(e))
    // console.log('getRequest res: ', res)
    return {
        res,
        getError: getError(res, getRequestError),
    }
}

const getRequestsForDashboardAdmin = async (requestGender) => {
    const res = await httpService.get(`oepvapi/backend_fub/requests${requestGender?.toString() ? `?gender=${requestGender}` : ''}`).catch(e => httpService.catchError(e))
    // console.log('getRequestsForDashboardAdmin res: ', requestGender, typeof(requestGender), res)
    return {
        res,
        getError: getError(res, getBackendRequestsError)
    }
}

const getRequestHistory = async (requestId) => {
    const res = await httpService.get(`oepvapi/request/${requestId}/history`).catch(e => httpService.catchError(e))
    // console.log('getRequestHistory res: ', res)
    return {
        res,
        getError: getError(res, getRequestHistoryError),
    }
}

const getRequestProviders = async (requestId) => {
    const res = await httpService.get(`oepvapi/requests_quotes/${requestId}`).catch(e => httpService.catchError(e))
    // console.log('getRequestProviders res: ', res)
    return {
        res,
        getError: getError(res, getRequestProvidersError),
    }}

const getBackendRequests = async (requestStatus) => {
    const res = await httpService.get(`oepvapi/backend/requests${requestStatus ? `?status=${requestStatus}` : ''}`).catch(e => httpService.catchError(e))
    // console.log('getBackendRequests res: ', requestStatus, res)
    return {
        res,
        getError: getError(res, getBackendRequestsError)
    }
}

const editBackendRequest = async (editedRequest) => {
    const res = await httpService.put('oepvapi/backend/request', editedRequest).catch(e => httpService.catchError(e))
    // console.log('editBackendRequest res: ', res)
    return res
}

const validateProviderEmail = async (mail, gender) => {
    const res = await httpService.post('oepvapi/check_email_provider', { mail, gender }, {}).catch(e => httpService.catchError(e))
    // console.log('validateEmail res: ', res)
    return {
        res,
        getError: getError(res, getValidateProviderEmailError)
    }
}

// BACK OFFICE

const getBackOfficeEntity = async (entityId) => {
    const res = await httpService.get(`oepvapi/backend_fub/entity/${entityId}`).catch(e => httpService.catchError(e))
    // console.log('getBackOfficeEntity res: ', res)
    return {
        res,
        getError: getError(res, getBackOfficeEntityError),
    }
}

const getBackOfficeEntityHistory = async (entityId) => {
    const res = await httpService.get(`oepvapi/backend_fub/entity/${entityId}/history`).catch(e => httpService.catchError(e))
   // console.log('getBackOfficeEntityHistory res: ', res)
    return {
        res,
        getError: getError(res, getBackOfficeEntityError),
    }
}

const getBackOfficeBeneficiaries = async () => {
    const res = await httpService.get('oepvapi/backend_fub/beneficiaries').catch(e => httpService.catchError(e))
    // console.log('getBackOfficeBeneficiaries res: ', res)
    return {
        res,
        getError: getError(res, getBackOfficeBeneficiariesError),
    }
}

const editBackOfficeEntity = async (entity) => {
    const res = await httpService.put('oepvapi/backend_fub/entity', entity).catch(e => httpService.catchError(e))
    // console.log('editBackOfficeEntity res: ', res)
    return {
        res,
        getError: getError(res, editBackOfficeEntityError),
    }
}

const editBackOfficeEntityComment = async (updatedComment) => {
    const res = await httpService.put('/oepvapi/backend_fub/request/comment_or_sending_fub', updatedComment).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, getBackOfficeCommentError),
    }
}

const getBackOfficeFolders = async () => {
    const res = await httpService.get('oepvapi/backend_fub/folders').catch(e => httpService.catchError(e))
    // console.log('getBackOfficeFolders res: ', res)
    return {
        res,
        getError: getError(res, getBackOfficeFoldersError),
    }
}

const getBackOfficeFoldersValidate = async () => {
    const res = await httpService.get('oepvapi/backend_fub/folders_validated').catch(e => httpService.catchError(e))
    // console.log('getBackOfficeFoldersValidate res: ', res)
    return {
        res,
        getError: getError(res, getBackOfficeFoldersError),
    }
}

const getBackOfficeRequestsByEntity = async (entityId) => {
    const res = await httpService.get(`oepvapi/backend_fub/requests/${entityId}`).catch(e => httpService.catchError(e))
    // console.log('getBackOfficeRequestsByEntity res: ', res)
    return {
        res,
        getError: getError(res, getRequestsError),
    }
}

const getBackOfficeRegularisationsByEntity = async (entityId) => {
    const res = await httpService.get(`oepvapi/backend_fub/regularizations/${entityId}`).catch(e => httpService.catchError(e))
    // console.log('getBackOfficeRegularisationsByEntity res: ', res)
    return {
        res,
        getError: getError(res, getRequestsError),
    }
}

const getBackOfficeRequestHistory = async (requestId) => {
    const res = await httpService.get(`oepvapi/backend_fub/request/${requestId}/history`).catch(e => httpService.catchError(e))
    //console.log('getBackOfficeRequestHistory res: ', res)
    return {
        res,
        getError: getError(res, getBackOfficeRequestHistoryError),
    }
}

const editBackOfficeAuditEntity = async (updatedAudit) => {
    const res = await httpService.put('oepvapi/backend_fub/modify_audit_provider', updatedAudit).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, editBackOfficeRequestError),
    }
}
const cancelBackOfficeAuditEntity = async (editedAudit) => {
    const res = await httpService.put('oepvapi/backend_fub/cancel_audit', editedAudit).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, editBackOfficeRequestError),
    }
}

const editBackOfficeRequest = async (editedRequest) => {
    const res = await httpService.put('oepvapi/backend_fub/request', editedRequest).catch(e => httpService.catchError(e))
    // console.log('editBackOfficeRequest res: ', res)
    return {
        res,
        getError: getError(res, editBackOfficeRequestError),
    }
}
    
const editBackOfficeScopingMeetingEntity = async (updatedScopingMeeting) => {
    const res = await httpService.put('oepvapi/backend_fub/modify_framing_meeting', updatedScopingMeeting).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, editBackOfficeRequestError),
    }
}

const cancelBackOfficeScopingMeetingEntity = async (editedScopingMeeting) => {
    const res = await httpService.put('oepvapi/backend_fub/cancel_framing_meeting', editedScopingMeeting).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, editBackOfficeRequestError),
    }
}

const yourSelfAssignFolder = async () => {
    const res = await httpService.put('oepvapi/backend_fub/affect_folder').catch(e => httpService.catchError(e))
    // console.log('getBackOfficeFolders res: ', res)
    return {
        res,
        getError: getError(res, getYourselfAssignFolderError),
    }
}

const checkEntityBackOfficeRequest = async (checkEntity) => {
    const res = await httpService.put('oepvapi/backend_fub/check_entity', checkEntity).catch(e => httpService.catchError(e))
    // console.log('checkEntityBackOfficeRequest res: ', res)
    return {
        res,
        getError: getError(res, checkEntityBackOfficeRequestError),
    }
}

const editBackOfficeAdvisorEntity = async (data) => {
    const res = await httpService.put('oepvapi/backend_fub/attach_entity', data).catch(e => httpService.catchError(e))
    // console.log('editBackOfficeAdvisorEntity res: ', res)
    return {
        res,
        getError: getError(res, editBackOfficeAdvisorError),
    }
}

const editBackOfficeAdvisorRequest = async (data) => {
    const res = await httpService.put('oepvapi/backend_fub/attach_request', data).catch(e => httpService.catchError(e))
    // console.log('editBackOfficeAdvisorRequest res: ', res)
    return {
        res,
        getError: getError(res, editBackOfficeAdvisorError),
    }
}

// Regularisation
const createRegularisationFolder = async ({ entity_id, request_id, amount, comment, unique_id }) => {
    const payload = {
        entity_id,
        request_id,
        regularization_data: {
            amount,
            comment
        },
        unique_id
    }
    const res = await httpService.post('oepvapi/backend_fub/regularization', payload, {}).catch(e => httpService.catchError(e))
    // console.log('createRegularisationFolder res: ', res)
    return {
        res,
        getError: getError(res, getCreateRegularisationFolderError)
    }
}

const getRegularisationFolders = async (entity_id) => {
    // NB: without given entity_id, all regulation folders would be returned
    const params = Boolean(entity_id) && entity_id.length > 0 ? `/${entity_id}` : ''
    const res = await httpService.get(`oepvapi/backend_fub/regularizations${params}`,).catch(e => httpService.catchError(e))
    // console.log('getRegularisationFolders res: ', res)
    return {
        res,
        getError: getError(res, getRegularisationFoldersError)
    }
}

const createProviderData = async (payload) => {
    const res = await httpService.post('oepvapi/backend_fub/equipment_supply', payload, {}).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, getCreateProviderDataError)
    }
}

const getProviderEquipments = async (entity_id) => {
    const res = await httpService.get(`oepvapi/backend_fub/equipments_supply?entity_id=${entity_id}`,).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, getProviderEquipmentsError)
    }
}

const getAllEquipmentsSupplyList = async () => {
    const res = await httpService.get('oepvapi/backend_fub/equipments_supply_list').catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, getAllEquipmentsSupplyListError)
    }
}

const editRequestEquipments = async (payload) => {
    const res = await httpService.put('oepvapi/backend_fub/requests/attach_equipments_supply', payload).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, getEditEquipmentSupply),
    }
}

const getRequestEquipments = async (requestId) => {
    const res = await httpService.get(`oepvapi/backend_fub/requests/equipments_supply?request_id=${requestId}`,).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, getRequestEquipmentsError)
    }
}

export {
    getRequestsForDashboardAdmin,
    getEntities,
    getEntitiesLocation,
    createRequest,
    editRequest,
    getRequests,
    getRequest,
    getRequestHistory,
    getRequestProviders,
    getBackendRequests,
    editBackendRequest,
    editBackOfficeEntityComment,
    validateProviderEmail,
    // Back Office
    getBackOfficeFolders,
    getBackOfficeRequestsByEntity,
    getBackOfficeRegularisationsByEntity,
    getBackOfficeEntity,
    getBackOfficeBeneficiaries,
    editBackOfficeEntity,
    checkEntityBackOfficeRequest,
    getBackOfficeRequestHistory,
    editBackOfficeRequest,
    yourSelfAssignFolder,
    editBackOfficeAdvisorEntity,
    editBackOfficeAdvisorRequest,
    getBackOfficeFoldersValidate,
    getBackOfficeEntityHistory,
    // Regularisation
    createRegularisationFolder,
    getRegularisationFolders,
    // Provider data
    createProviderData,
    getProviderEquipments,
    getAllEquipmentsSupplyList,
    editRequestEquipments,
    getRequestEquipments,
    // Audit
    editBackOfficeAuditEntity,
    cancelBackOfficeAuditEntity,
    // Scoping meeting
    editBackOfficeScopingMeetingEntity,
    cancelBackOfficeScopingMeetingEntity,
}
