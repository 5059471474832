import { func, string, shape } from 'prop-types'

const CardItem = ({ item, className = '', onClick }) => {
    return (
        <div className={`c-item-catalogue u-pd-m ${className}`}>
            <div className="u-flex-1 u-flex u-flex-dir-col u-mg-bottom-m">
                <h3 className="c-h4">{item?.label}</h3>
                <div className="u-flex-1 u-flex u-flex-dir-col u-flex-center-hz u-mg-top-xs">
                    {item?.picture && <img src={item.picture} alt={item?.label} />}
                </div>
            </div>
            <ul className="c-list-hz u-fs-xs u-mg-bottom-l">
                <li className="c-list-hz__item u-pd-s u-center">
                    <span className="u-bold l-col">Durée</span>
                    {item?.duration}
                    {item?.duration2 && <p>{item.duration2}</p>}
                </li>
                { item?.target &&
                    <li className="c-list-hz__item u-pd-xs u-center">
                        <span className="u-bold l-col">Cibles</span>
                        {item?.target}
                    </li>
                }
                { item?.place &&
                    <li className="c-list-hz__item u-pd-xs u-center">
                        <span className="u-bold l-col">Lieu</span>
                        {item?.place}
                    </li>
                }
                { item?.slot &&
                    <li className="c-list-hz__item u-pd-xs u-center">
                        <span className="u-bold l-col">Créneau</span>
                        {item?.slot}
                    </li>
                }
                <li className="c-list-hz__item u-pd-s u-center">
                    <span className="u-bold l-col">Prix</span>
                    {item?.price}
                    {item?.price2 && <p>{item.price2}</p>}
                </li>
                { item?.nbMeeting &&
                    <li className="c-list-hz__item u-pd-xs u-center">
                        <span className="u-bold l-col">Nombre minimum d’entretien(s) sur place avec le consultant</span>
                        {item?.nbMeeting}
                    </li>
                }
            </ul>
            <button
                type="button"
                className="c-btn c-btn--primary u-mg-hz-auto u-pd-hz-xl"
                onClick={onClick}
            >
                En savoir plus / Effectuer une demande 
            </button>
        </div>
    )
}

CardItem.propTypes = {
    item: shape({
        label: string,
        description: string,
        duration: string,
        target: string,
        place: string,
        slot: string,
        price: string
    }),
    className: string,
    onClick: func
}

export default CardItem