import XLSX from 'xlsx'
import { pdfjs } from "react-pdf"

import dateService from 'services/date'
import { isNotEmpty } from 'utils/validation'
import { addFormatsToCells } from 'utils/downloadFile'

const VALID_EXTENSIONS = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'pdf', 'PDF']

export const getFileType = (targetResult) => {
    let header = ''
    let type = ''

    const arr = (new Uint8Array(targetResult)).subarray(0, 4)
    for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16)
    }

    switch (header) {
        case '89504e47':
            type = 'image/png'
            break
        case '47494638':
            type = 'image/gif'
            break
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
            type = 'image/jpeg'
            break
        case '25504446':
            type = 'application/pdf'
            break
        default:
            type = 'unknown'
            break
    }

    return type
}

export const exportXlsx = (columns, data, name = '') => {
    // prepare data
    const tabToExport = []
    // Header array
    tabToExport.push(Object.values(columns))
    // Data arrays
    data.forEach(item => {
        const rowToPush = []
        Object.keys(columns).forEach(columnKey => {
            rowToPush.push(item[columnKey])
        })
        tabToExport.push(rowToPush)
    })
    // prepare xlsx
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(tabToExport)
    addFormatsToCells(ws)
    // export sheet then file
    XLSX.utils.book_append_sheet(wb, ws, 'export-oepv')
    XLSX.writeFile(wb, `${name}-${dateService.getCurrentDate('yyyy-MM-dd')}.xlsx`)
}

export const isValidExtension = (extension) => VALID_EXTENSIONS.indexOf(extension) !== -1
export const isPDFExtension = (extension) => extension?.toLowerCase() === 'pdf'
export const getFileExtension = (fileName) => Boolean(fileName) && fileName.split(".")?.pop().slice(0,3)
export const getStructureNameFormatted = (structureName) => {
    return structureName
        .trim().toLowerCase()
        .replace(/[^a-zA-Z0-9- ]/g, '') // remove special chars
        .replace(/\s+/g, '-') // replaces spaces by hyphens
        .normalize('NFD').replace(/\p{Diacritic}/gu, '') // replaces accented chars by unaccented chars
}

/***
 * getFirstPageFromPdf
 * Extract first page from PDF 
 * Return image as string
 */
export const getFirstPageFromPdf = async (blob) => {
    if(!isNotEmpty(blob)) return null
  
    // 1- Get the document url
    const loadingTask = pdfjs.getDocument(URL.createObjectURL(blob))
  
    return loadingTask.promise.then(async (pdf) => {
        // 2- Get first page of the document
        return pdf.getPage(1).then(async (page) => {
            // 3- Create canvas
            const viewport = page.getViewport({ scale: 1.5 })
            const canvas = document.createElement('canvas')
  
            // Prepare canvas using PDF page dimensions
            const context = canvas.getContext('2d')
            canvas.height = viewport.height
            canvas.width = viewport.width
  
            // Render PDF page into canvas context
            const renderContext = { canvasContext: context, viewport: viewport }
            const view = page.render(renderContext)
  
            // 4- Render PDF page into canvas context
            return view.promise.then(() => canvas.toDataURL('image/jpeg'))
      })
    })
  }
  

/***
 * convertUrlToBlob
 * Return Blob file from a string
 */
export const convertUrlToBlob = async (url) => {
    return fetch(url)
    .then(response => response.blob())
  } 


export const generateThumbnailFromMedia = async ({ media }) => {
    if(!isNotEmpty(media)) return null
  
    // fileImageResizer needs a Blob file => transform url to Blob if necessary 
    const convertImageToBlob = convertUrlToBlob(media)
  
    // First, convert PDF to Blob, then get first page
    const file = await getFirstPageFromPdf(await convertImageToBlob)
    return file
  }