import React from 'react'
import { Text, View, } from '@react-pdf/renderer'

import { formationList } from 'components/catalog/AdviceAndSupport/list'
import styles from 'components/pdf/PDFStyles'
import DataViewPDF from 'components/pdf/PDFDataView'
import RequestPDF from 'components/pdf/PDFRequestTemplate'
import { monitoringPDFpropTypes } from 'components/pdf/monitoringPDFpropTypes'

const AdviceAndSupportPDF = ({ beneficiary, request, provider }) => {
    const formation = formationList.find(formation => formation.id === request?.request_description?.type)
    // On se base sur le unitPrice de la formation pour le prix de la prestation
    // Il y a un cas spécifique pour la formation "Stratégie vélo" qui a un prix plafonné en fonction de l'effectif de la structure
    const price = formation?.id === 'cycling-strategy' && Number(beneficiary.structureEmployeeQuantity) >= 250 ? formation?.maxUnitPrice : formation?.unitPrice
    
    return (
        <RequestPDF
            beneficiary={beneficiary}
            title={`Demande de prestation n°${request?.request_fub_id}`}
            comment={request?.request_description?.comment}
            createDate={request?.create_date}
            provider={provider}
        >
            <View style={styles.section} wrap={false}>
                <View>
                    <Text style={[styles.h2, styles.textTransform, styles.bold]}>Prestation de service conseil et accompagnement souhaitée</Text>
                </View>
                <View style={styles.grid}>
                    <View style={styles.sectionLeft}>
                        <Text style={styles.h3}>{formation?.label}</Text>
                        <DataViewPDF
                            label="Montant de la prestation"
                            value={price + ' €' || ' - €'}
                            styles={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: 200 }}
                        />
                    </View>
                    <View style={styles.sectionRight}>
                        {/* Illustration */}
                    </View>
                </View>
            </View>
        </RequestPDF>
    )
}

AdviceAndSupportPDF.propTypes = monitoringPDFpropTypes

export default AdviceAndSupportPDF