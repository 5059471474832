import { bool } from 'prop-types'

const ProviderNotFoundHelp = ({ isGeolocation = false }) => (
    <div className="u-fs-xs u-mg-top-m u-center">
        { isGeolocation && 
            <>Dans la mesure du possible, essayez de privilégier les prestataires les plus proches de chez vous. <br/></>
        }
        Vous souhaitez faire appel à un fournisseur qui ne figure pas dans la liste ? <br />Proposez-lui de se référencer au programme en 
        <a
            className="u-primary u-va-baseline u-mg-left-xs u-underline"
            href="https://employeurprovelo.fr/rejoindre-le-programme/je-suis-prestataire/fournisseur-stationnements-velo/"
            target={'_blank'}
            rel="noopener noreferrer"
        >
            cliquant ici
        </a>
    </div>
)

ProviderNotFoundHelp.propTypes = {
    isGeolocation: bool
}

export default ProviderNotFoundHelp