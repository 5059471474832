import React from 'react'
import { useParams, useHistory } from 'react-router-dom'

import * as CatalogApi from 'services/api/CatalogApi'

import { privateRoutes } from 'router/routes'

import {
    getFolderTypeLabel,
    BACK_OFFICE_OPERATIONS, 
    getRequestToEdit,
    isValidated,
    REQUEST_MOBILITY_VALUE,
    REQUEST_SUPPORT_VALUE,
    REQUEST_TECHNICAL_VALUE,
    isMobilityFolder,
    isAdviceAndSupportFolder,
    isTechnicalFolder,
    calculateMobilityHelpAmount,
    calculateAdviceAndSupportAmount,
    calculateTechnicalHelpAmount,
    getInformationsByType,
    VALIDATED_STATUS,
    PENDING_STATUS,
    REFUSED_STATUS,
    GO_BACK_ADVISOR_STATUS,
    advisorStatusList,
    supervisorStatusList,
    getRequestStatusFromBackOffice
} from 'utils/domain/request'
import { formatNumberAndAddEurCurrency } from 'utils/number'

import { useAlert } from 'hooks/useAlert'
import useBonus from 'hooks/useBonus'
import useMediaQuery from 'hooks/useMediaQuery'
import { jsonHash } from 'utils/hash'
import { useAuth } from 'hooks/useAuth'

import BackOfficeLayout from 'layouts/back-office'

import BeneficiaryInformations from 'components/back-office/BeneficiaryInformations'
import Card from 'components/platform/Card'
import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'
import { downloadRequestPDF } from 'components/catalog/catalogUtils'
import MobilityHelpPDF from 'components/catalog/MobilityHelp/MobilityHelpPDF'
import AdviceAndSupportPDF from 'components/catalog/AdviceAndSupport/AdviceAndSupportPDF'
import Information from 'components/platform/Informations/Information'

import ServiceFolderProvider, { useServiceFolder } from './context'
import Bid from './Bid'
import Inputs from './Inputs'
import Emargements from './Emargements'
import InterventionReport from './InterventionReport'
import TechnicalServicePDF from 'components/catalog/Technical/TechnicalServicePDF'
import Comment from 'components/shared/Comment'
import { scrollToTop } from 'utils/scrollTo'

import FormElement from 'components/shared/FormElement'
import { isNotEmpty } from 'utils/validation'

const ServiceFolder = () => {
    const [amounts, setAmounts] = React.useState({
        totalAmount: null,
        amountToRefund: null
    })

    const [formFields, setFormFields ] = React.useState({})
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [showGlobalError, setShowGlobalError] = React.useState(false)
    const [errors, setErrors] = React.useState([])

    const [requestType, setRequestType ] = React.useState({})

    const { addAlert } = useAlert()
    const { requestId } = useParams()
    const history = useHistory()
    const { isABackOfficeManagementSupervisor } = useAuth()

    const { request, beneficiary, fetchRequest } = useServiceFolder()
    const isLarge = useMediaQuery('(min-width: 1500px)')

    const [ disabledActions, setDisabledActions] = React.useState(false)
    const [isChangingStatus, setIsChangingStatus] = React.useState(false)
    const [ disabledButton, setDisabledButton] = React.useState(false)

    const { getBonusByRequests } = useBonus({ isFromBackOffice: true })

    const requestsGroupedByGender = beneficiary?.requestsGroupedByGender || {}
    const requests = Object.values(requestsGroupedByGender).flat()
    const regularisationFolders = beneficiary?.regularisationFolders ?? []

    const {
        totalAmountValidated,
        availableBonus,
    } = getBonusByRequests({
        requests: [...requests, ...regularisationFolders],
        userEntityData: beneficiary?.entity_data,
        beneficiary: beneficiary
    })

    React.useEffect(() => {
        fetchRequest(requestId)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setFormFields({
            sessionsQuantity: request?.sessionsQuantity?.toString(),
            personsQuantity: request?.personsQuantity?.toString(),
            comment: request?.request_data?.operation_data?.comment,
            daysCompleted: request?.request_data?.operation_data?.daysCompleted?.toString(),
            row_hash: request?.row_hash
        })
    }, [request?.status]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
       setFormFields({
            sessionsQuantity: request?.sessionsQuantity?.toString(),
            personsQuantity: request?.personsQuantity?.toString(),
            bikesQuantity: request?.request_description?.bikesQuantity?.toString(),
            daysCompleted: request?.request_data?.operation_data?.daysCompleted?.toString(),
            comment: request?.request_data?.operation_data?.comment,
            row_hash: request?.row_hash
        })
        setRequestType(getInformationsByType(request?.request_description?.type))
    }, [request])

    React.useEffect(() => {
        updateAmounts(requestId, beneficiary?.entity_data?.structureEmployeeQuantity)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId, beneficiary, request, formFields.sessionsQuantity, formFields.personsQuantity, formFields.bikesQuantity, formFields.daysCompleted])

    const commentHasChanged = request?.request_data?.operation_data?.comment && formFields?.comment && jsonHash(formFields.comment) === jsonHash(request.request_data.operation_data.comment)

    const isCyclingStrategyRequest = requestType?.id === 'cycling-strategy'
    const isBeneficiaryBigStructure = beneficiary?.entity_data?.structureEmployeeQuantity >= 250

    // Un jour de formation = 850 euros. On multiplie chaque jour par ce montant. Il n'est pas degressif
    // Le maximum du plafond remboursable correspond au requestType?.unitPrice
    const ceilingPerTraining = isCyclingStrategyRequest && isBeneficiaryBigStructure ? Number(requestType?.maxUnitPrice) : Number(requestType?.unitPrice)
    const globalAmount = isNotEmpty(formFields.daysCompleted) ? Math.min(850 * Number(formFields.daysCompleted), ceilingPerTraining) : 0
    const amountToRepay = Math.min(globalAmount * 0.6, ceilingPerTraining)

   const getExtraDataToSave = () => {
        if(isMobilityFolder(requestId)) return getExtraMobilityDataToSave()
        if(isTechnicalFolder(requestId)) return getExtraTechnicalDataToSave()
        return {
            comment: formFields.comment,
            daysCompleted: formFields.daysCompleted
        }
    }

    const getExtraMobilityDataToSave = () => {
        const sessionsQuantity = Number(formFields.sessionsQuantity)
        const personsQuantity = Number(formFields.personsQuantity)
        const comment = formFields.comment
        
        return {
            personsQuantity,
            sessionsQuantity,
            comment
        }
    }

    const getExtraTechnicalDataToSave = () => {
        const sessionsQuantity = Number(formFields.sessionsQuantity)
        const bikesQuantity = Number(formFields.bikesQuantity)
        const comment = formFields.comment
        
        return {
            bikesQuantity,
            sessionsQuantity,
            comment
        }
    }

    const getRequestPdfTemplate = (requestGender) => {
        switch(requestGender) {
            case REQUEST_MOBILITY_VALUE:
                return MobilityHelpPDF
            case REQUEST_TECHNICAL_VALUE:
                return TechnicalServicePDF
            case REQUEST_SUPPORT_VALUE:
                return AdviceAndSupportPDF
            default:
                return null
        }
    }

    const handleDownloadRequestPDF = async () => {
        const { error } = await downloadRequestPDF({
            user: beneficiary,
            request,
            provider: request.provider,
            pdfTemplate: getRequestPdfTemplate(request.gender),
            readRequestProviders: false,
            isFromBackOffice: true
        })

        if (error) addAlert('error', "Une erreur s'est produite lors de la génération de la demande. Veuillez réessayer.")
    }

    const handleChangeRequestStatus = async (event) => {
        event.preventDefault()
        setDisabledButton(true)

        if(formFields.status === VALIDATED_STATUS) {
            // On valide la demande => "validé"
            validateRequest()
            setDisabledButton(false)
        } else if(formFields.status === PENDING_STATUS) {
            try {
                const { getError } = await CatalogApi.editBackOfficeRequest(
                    getRequestToEdit(
                        request, 
                        BACK_OFFICE_OPERATIONS.BID.ASK_MORE_INFORMATIONS, 
                        getExtraDataToSave()
                    )
                )
                if (getError()) throw getError()
                fetchRequest()

                addAlert('success', 'Le dossier a bien été passé en "En attente".')
                setIsChangingStatus(false)
    
            } catch(error) {
                addAlert('error', error?.toString())
            } finally {
                setDisabledButton(false)
            }
        } else if(formFields.status === REFUSED_STATUS) {
            // On refuse la demande => "refusé"
            declineRequest()
            setDisabledButton(false)
        } else if(formFields.status === GO_BACK_ADVISOR_STATUS) {
            // On est superviseur - admin bo gestion et on passe la request en "Retour conseiller"
            await handleRemoveSendingFub()
        } else {
            // Statut "transmis_fub" => on passe le boolean à true.
            try {
                const updatedSendingFub = {
                    'last_row_hash': formFields.row_hash,
                    'request_id': request.request_id,
                    'sending_fub': true
                }
                    
                const { getError } = await CatalogApi.editBackOfficeEntityComment(updatedSendingFub)
                if (getError()) throw getError()
                addAlert('success', 'Le dossier a bien été passé en "Transmis FUB".')
                fetchRequest()
                setIsChangingStatus(false)
            } catch(error) {
                addAlert('error', error?.toString())
            } finally {
                setDisabledButton(false)
            }
        }
    }

    const handleRemoveSendingFub = async () => {
        try {
            const updatedSendingFub = {
                'last_row_hash': request?.row_hash,
                'request_id': request.request_id,
                'sending_fub': false
            }
                
            const { getError: getCommentError } = await CatalogApi.editBackOfficeEntityComment(updatedSendingFub)
            if (getCommentError()) throw getCommentError()

            addAlert('success', 'Le dossier a bien été retourné au conseiller.')
            fetchRequest()
            setIsChangingStatus(false)
        } catch(error) {
            addAlert('error', error?.toString())
        }
    }

    const validateRequest = async () => {
        if(!isFormReady()) return
        try {
            setDisabledActions(true)

            const { res, getError } = await CatalogApi.editBackOfficeRequest(
                getRequestToEdit(
                    request, 
                    BACK_OFFICE_OPERATIONS.BID.VALIDATE,
                    getExtraDataToSave(),
                    amounts.amountToRefund,
                    amounts.totalAmount
                )
            )

            if (getError()) throw getError()
            addAlert('success', 'Dossier validé.')
            // DOLIBARR ERROR
            if(res?.erp_response?.error) addAlert('error', "Une erreur a été remontée sur l'interface Dolibarr. Veuillez vous connecter à Dolibarr et faire la modification.")

            history.push(privateRoutes.backOffice.backOfficeHome.path)
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
        }
    }

    const askMoreInformations = async (event) => {
        try {
            setDisabledActions(true)
            // const { getError } = await CatalogApi.editBackOfficeRequest(
            //     getRequestToEdit(
            //         request, 
            //         BACK_OFFICE_OPERATIONS.BID.ASK_MORE_INFORMATIONS, 
            //         getExtraDataToSave()
            //     )
            // )
            // if (getError()) throw getError()
            
            // fetchRequest()
            const subject = `OEPV: Demande d'infos complémentaires pour le dossier n°${request?.request_fub_id}`
            window.location = `mailto:${beneficiary?.contactEmail}?subject=${subject}`
            event.preventDefault()
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
        }
    }

    const saveComment = async () => {
        try {
            setDisabledActions(true)

            const updatedComment = {
                'last_row_hash': formFields.row_hash,
                'request_id': request.request_id,
                'comment': formFields.comment,
            }

            const { res, getError } = await CatalogApi.editBackOfficeEntityComment(updatedComment)
            if (getError()) throw getError()

            addAlert('success', 'Le commentaire a bien été ajouté')
            fetchRequest()

            if (res) {
                setFormFields(s => ({
                    ...s,
                    row_hash: res?.row_hash,
                    comment: res?.comment,
                }))
            }
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
        }
    }
    
    const declineRequest = async () => {
        if(!isFormReady()) return
        try {
            setDisabledActions(true)
            const { getError } = await CatalogApi.editBackOfficeRequest(
                getRequestToEdit(request, BACK_OFFICE_OPERATIONS.PICTURES.DECLINE, getExtraDataToSave())
            )
            if (getError()) throw getError()

            addAlert('success', 'Le dossier a bien été refusé')
            history.push(privateRoutes.backOffice.backOfficeHome.path)
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
        }
    }

    const handleInputChange = (event) => {
        // Use max input value to make maxLength of 5
        const maxValue = 9999
        const target = event.target
        const name = target.name
        // Because maxLength is not accepted for type number => maxLength 5
        if(event.target.value <= maxValue || name === 'comment') {
            setFormFields(s => ({
                ...s,
                [name]: target.value
            }))
        }
    }

    const folderTypeLabel = getFolderTypeLabel(requestId)

    const updateAmounts = (requestId, beneficiaryEmployeeQuantity) => {
        if(isMobilityFolder(requestId)) {
            setAmounts(
                calculateMobilityHelpAmount({
                    type: request?.request_description?.type,
                    quantity: request?.request_description?.type === 'training-back-to-bike' // Si Remise en selle => on prend en compte le nombre de participants
                    ? (parseInt(formFields?.personsQuantity))
                    : (parseInt(formFields?.sessionsQuantity)) // Sinon on prend en compte le nombre de sessions
                })
            )
        } else if (isAdviceAndSupportFolder(requestId)) {
            setAmounts(
                calculateAdviceAndSupportAmount({ type: request?.request_description?.type, beneficiaryEmployeeQuantity: beneficiaryEmployeeQuantity, daysCompleted: formFields.daysCompleted })
            )
        } else if (isTechnicalFolder(requestId)) {
            setAmounts(
                calculateTechnicalHelpAmount({
                    type: request?.request_description?.type,
                    quantity: request?.request_description?.type === 'bike-overhaul' // Si Révision du vélo des salariés => on prend en compte le nombre de vélos à réparer
                    ? (parseInt(formFields?.bikesQuantity)) 
                    : (parseInt(formFields?.sessionsQuantity)) // Sinon on prend en compte le nombre de sessions
                })
            )
        } else {
            setAmounts({
                totalAmount: null,
                amountToRefund: null
            })
        }
    }

    const isFormReady = () => {
        const formReady = !(errors.length > 0)
        if (!formReady) {
            setShowLocalErrors(true)
            setShowGlobalError(true)
            addAlert('error', 'Erreur(s) dans le formulaire.')
            scrollToTop()
            return false
        }
        return true
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const formInputElementCommonProps = {
        value: formFields.daysCompleted,
        name: 'daysCompleted',
        type: 'number',
        label: 'Nombre de jours réalisés',
        className: 'u-mg-bottom-m u-mg-right-l',
        classNameLabel: 'u-grey90 u-fs-xxs u-regular',
        required: true,
		showErrors: showLocalErrors,
		onValidate: handleValidation,
		onChange: handleInputChange,
	}

    const isSendingFub = request.request_data?.operation_data?.sending_fub ?? false
    const isBlockDisabled = isSendingFub && !isABackOfficeManagementSupervisor
    
    return (
        <BackOfficeLayout>
            <div className={`${isBlockDisabled ? 'is-disabled-block' : ''}`}>
                <div className="u-flex u-flex-dir-col u-mg-bottom-l">
                    <h2 className="c-h2 u-bold u-primary">
                        Dossier {folderTypeLabel} : <br />
                        {requestType?.shortLabel} - {beneficiary?.structureName}
                    </h2>
                    <h3 className="c-h3 u-bold u-primary u-mg-top-m u-mg-bottom-l">Bon de commande n°{request?.request_fub_id}</h3>
                    <div className={`u-flex ${isLarge ? 'u-flex-dir-row' : 'u-flex-dir-col'}`}>
                        <form className={`${isLarge ? 'u-mg-right-l l-col-8 ' : 'l-col-12'}`}>
                            { showGlobalError && isNotEmpty(errors) && <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur qui empêche la validation du formulaire.</p>}
                            <div className='u-flex'>
                                <Card title="Demande de prestations" className="u-max-w-60-percent">
                                    <InformationToVisualize 
                                        label="Consulter la demande de prestation" 
                                        onClickToAction={handleDownloadRequestPDF}
                                    />
                                </Card>
                                <Card className="u-w-40-percent">
                                    <p><span className='u-blue u-fs-m u-bold'>Statut : </span> {getRequestStatusFromBackOffice(request.status, isSendingFub)}</p>
                                </Card>
                            </div>

                            <Card title={`Prestation ${folderTypeLabel}`} className="u-mg-top-m">
                                {isMobilityFolder(requestId) &&
                                <>
                                    <div className="u-flex u-flex-dir-row u-mg-top-l">
                                        <Inputs 
                                            request={request}
                                            formFields={formFields}
                                            handleValidation={handleValidation}
                                            handleInputChange={handleInputChange}
                                            showLocalErrors={showLocalErrors}

                                        />
                                        <Emargements className='u-flex u-flex-dir-col' emargements={request?.emargements} />
                                    </div>
                                    <div className="u-flex u-flex-dir-row u-mg-top-l">
                                        <Information 
                                            label="Montant total de la facture" 
                                            value={(amounts.totalAmount?.toString() && !Number.isNaN(amounts.totalAmount)) ? formatNumberAndAddEurCurrency(amounts.totalAmount) : '-'} 
                                        />
                                        <Information 
                                            label="Montant à rembourser"
                                            value={(amounts.amountToRefund?.toString() && !Number.isNaN(amounts.amountToRefund)) ? formatNumberAndAddEurCurrency(amounts.amountToRefund) : '-'} 
                                        />
                                    </div>
                                </>
                                }
                                {isAdviceAndSupportFolder(requestId) &&
                                    <div>
                                        <div className='u-flex'>
                                            {isCyclingStrategyRequest && !isBeneficiaryBigStructure &&
                                                <FormElement
                                                    {...formInputElementCommonProps}
                                                    customRules={[
                                                        {
                                                            key: 'account-number-invalid',
                                                            check: (value) => value <= 8 && value > 0,
                                                            message: 'Le nombre doit être compris entre 1 et 8'
                                                        }
                                                    ]}
                                                />
                                            }

                                            {isCyclingStrategyRequest && isBeneficiaryBigStructure &&
                                                <FormElement
                                                    {...formInputElementCommonProps}
                                                    customRules={[
                                                        {
                                                            key: 'account-number-invalid',
                                                            check: (value) => value <= 10 && value > 0,
                                                            message: 'Le nombre doit être compris entre 1 et 10'
                                                        }
                                                    ]}
                                                />
                                            }

                                            {requestType?.id === 'training-and-tools-of-cycling-team' &&
                                                <FormElement
                                                    {...formInputElementCommonProps}
                                                    customRules={[
                                                        {
                                                            key: 'account-number-invalid',
                                                            check: (value) => value <= 3 && value > 0,
                                                            message: 'Le nombre doit être compris entre 1 et 3'
                                                        }
                                                    ]}
                                                />
                                            }

                                            {requestType?.id === 'specifications-training' &&
                                                <FormElement
                                                    {...formInputElementCommonProps}
                                                    customRules={[
                                                        {
                                                            key: 'account-number-invalid',
                                                            check: (value) => value <= 2 && value > 0,
                                                            message: 'Le nombre doit être compris entre 1 et 2'
                                                        }
                                                    ]}
                                                />
                                            }

                                            <InterventionReport url={request?.intervention?.full_media_url} />
                                        </div>

                                        {isCyclingStrategyRequest &&
                                            <p className='u-danger u-fs-xxs'>Attention : le nombre de jours ne peut dépasser le plafond
                                            (8 jours pour un site employeur de moins de 250 salariés, 10
                                            jours pour un site employeur de plus de 250 salariés). Si le
                                            nombre de jours de prestation est supérieur au plafond
                                            concerné, ce dépassement devra faire l’objet d’une facture
                                            distincte qui ne sera pas prise en compte dans le calcul de la
                                            prime versée par le programme OEPV.</p>
                                        }

                                        <div className="u-flex u-flex-dir-row u-mg-top-l">
                                            <Information 
                                                label="Montant total de la facture" 
                                                value={formatNumberAndAddEurCurrency(globalAmount) ?? '-'} 
                                            />
                                            <Information 
                                                label="Montant à rembourser" 
                                                value={formatNumberAndAddEurCurrency(amountToRepay) ?? '-'} 
                                            />
                                        </div>
                                    </div>
                                }
                                {isTechnicalFolder(requestId) &&
                                    <div>
                                        <div className="u-flex u-flex-dir-row u-mg-top-l">
                                            <Inputs 
                                                request={request}
                                                formFields={formFields}
                                                handleValidation={handleValidation}
                                                handleInputChange={handleInputChange}
                                                showLocalErrors={showLocalErrors}
                                            />
                                            <Emargements emargements={request?.emargements} />
                                        </div>
                                        <div className="u-flex u-flex-dir-row u-mg-top-l">
                                            <Information 
                                                label="Montant total de la facture" 
                                                value={(amounts.totalAmount && !Number.isNaN(amounts.totalAmount)) ? formatNumberAndAddEurCurrency(amounts.totalAmount) : '-'} 
                                            />
                                            <Information 
                                                label="Montant à rembourser" 
                                                value={(amounts.amountToRefund && !Number.isNaN(amounts.amountToRefund)) ? formatNumberAndAddEurCurrency(amounts.amountToRefund) : '-'} 
                                            />
                                        </div>
                                    </div>
                                }

                                <Bid 
                                    billUrl={request?.bill?.full_media_url}
                                    paymentProofUrl={request?.payment_proof?.full_media_url}
                                />
                                <Comment 
                                    value={formFields.comment}
                                    showLocalErrors={showLocalErrors}
                                    handleValidation={handleValidation}
                                    handleInputChange={handleInputChange}
                                    placeholder={'Ajouter un commentaire à votre dossier'}
                                    maxLength={1000}
                                />
                                <button
                                    type="button"
                                    className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-pd-s"
                                    onClick={saveComment}
                                    disabled={commentHasChanged}
                                >
                                    Enregistrer le commentaire
                                </button>


                                {!isValidated(request?.status) && (
                            <>
                            {isChangingStatus &&
                                <div className="u-flex u-flex-center-hz u-flex-end-vt u-mg-top-m">
                                    <button
                                        type="button"
                                        className="c-btn c-btn--s c-btn--danger c-btn--back-office u-pd-hz-m u-mg-hz-m u-pd-s"
                                        onClick={() => {
                                            setIsChangingStatus(false)
                                        }}
                                    >
                                        Annuler
                                    </button>

                                    {/* List of advisors */}
                                    <FormElement
                                        value={formFields.status}
                                        options={isABackOfficeManagementSupervisor ? supervisorStatusList(request) : advisorStatusList(request)}
                                        name="status"
                                        type="select"
                                        label="Changer le statut"
                                        className="u-pd-hz-m"
                                        showErrors={showLocalErrors}
                                        onValidate={handleValidation}
                                        onChange={handleInputChange}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                    
                                    <button
                                        type="button"
                                        className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-mg-hz-m u-pd-s"
                                        onClick={handleChangeRequestStatus}
                                        disabled={
                                            !isNotEmpty(formFields.status) || disabledButton
                                        }
                                    >
                                        Valider
                                    </button>
                                </div>
                            }
                            {!isChangingStatus &&
                                <div className="u-flex u-flex-dir-row u-flex-center-hz u-mg-top-m">
                                    <button
                                        type="button"
                                        className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-mg-m u-pd-s"
                                        onClick={() => setIsChangingStatus(true)}
                                        disabled={disabledActions}
                                    >
                                        Changer le statut
                                    </button>
                                    <button
                                        type="button"
                                        className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-mg-m u-pd-s"
                                        onClick={askMoreInformations}
                                        disabled={disabledActions}
                                    >
                                        Demander des informations complémentaires
                                    </button>
                                </div>
                                }
                            </>
                        )}
                            </Card>
                        </form>
                        <div className={`l-col-12 l-col-4@small-desktop ${isLarge ? '' : 'u-mg-top-m'}`}>
                            <Card>
                                <p><span className='u-blue u-fs-m u-bold'>Prime disponible : </span>{formatNumberAndAddEurCurrency(availableBonus - (totalAmountValidated + beneficiary?.amount_refunded))}</p>
                            </Card>
                            <BeneficiaryInformations initialBeneficiary={beneficiary} />
                        </div>
                    </div>
                </div>
            </div>
        </BackOfficeLayout>
    )
}

const ServiceFolderPage = () => (<ServiceFolderProvider><ServiceFolder /></ServiceFolderProvider>)

 export default ServiceFolderPage