import httpService from 'services/http'
import { getError } from 'services/api/errors'
import {
    cancelAuditError,
    getMyAuditError,
    planAuditError,
    getMyAuditsError,
    updateMyAuditError,
    getAuditsError
} from './errors'

// ↓ Beneficiaries calls ↓

export const getMyAudit = async () => {
    const res = await httpService.get('oepvapi/audit').catch(e => httpService.catchError(e))
    console.log('getMyAudit res: ', res)
    return {
        res,
        getError: getError(res, getMyAuditError)
    }
}

export const getBackOfficeBeneficiaryAudit = async (entity_id) => {
    const res = await httpService.get(`/oepvapi/backend_fub/audit/${entity_id}`).catch(e => httpService.catchError(e))
    console.log('getBackOfficeBeneficiaryAudit res: ', res)
    return {
        res,
        getError: getError(res, getMyAuditError)
    }
}

export const updateAuditStatus = async (audit_id, data) => {
    const res = await httpService.put(`/oepvapi/backend_fub/audit/${audit_id}`, data).catch(e => httpService.catchError(e))

    return {
        res,
        getError: getError(res, getMyAuditError)
    }
}

export const planAudit = async () => {
    const res = await httpService.post('oepvapi/audit', {}, {}).catch(e => httpService.catchError(e))
    console.log('planAudit res: ', res)
    return {
        res,
        getError: getError(res, planAuditError)
    }
}

export const cancelAudit = async () => {
    const res = await httpService.put('oepvapi/audit', {}).catch(e => httpService.catchError(e))
    console.log('cancelAudit res: ', res)
    return {
        res,
        getError: getError(res, cancelAuditError),
    }
}

// ↓ BO calls ↓

export const getMyAudits = async () => {
    const res = await httpService.get('oepvapi/backend/audits').catch(e => httpService.catchError(e))
    console.log('getMyAudits res: ', res)
    return {
        res,
        getError: getError(res, getMyAuditsError)
    }
}

export const updateMyAudit = async (audit_id, data) => {
    const res = await httpService.put(`/oepvapi/backend/audit/${audit_id}`, data).catch(e => httpService.catchError(e))
    console.log('updateMyAudit res: ', res)
    return {
        res,
        getError: getError(res, updateMyAuditError)
    }
}

export const getAudits = async () => {
    const res = await httpService.get('oepvapi/backend_fub/audits').catch(e => httpService.catchError(e))
    console.log('getAudits res: ', res)
    return {
        res,
        getError: getError(res, getAuditsError)
    }
}