import React from 'react'
import { bool, func, array, number } from 'prop-types'

const Pagination = ({ 
  pageOptions, 
  gotoPage, 
  canPreviousPage, 
  pageIndex, 
  pageCount, 
  canNextPage,
  paginationLength
}) => {

  // Manage pagination
  // -------------------
  const middleDistance = Math.round((paginationLength - 1) / 2)
  const pageShouldBeHiddenInPagination = (index) => {
      // in the first pages,
      // pagination should not exceed PAGINATION_LENGTH
      const condition1 = (pageIndex <= middleDistance && index > paginationLength - 1)
      // in the middle pages,
      // show x pages before currentPage and y pages after currentPage
      const condition2 = (
          (pageIndex > middleDistance)
          && (pageIndex < (pageOptions.length - middleDistance))
          && (index < (pageIndex - middleDistance) || index > (pageIndex + middleDistance - 1))
      )
      // in the last pages,
      // always show the x last pages
      const condition3 = (
          (pageIndex > (pageOptions.length - 1 - middleDistance))
          && index < (pageOptions.length - paginationLength)
      )
      return (condition1 || condition2 || condition3)
  }

  return (
    <>
      { pageOptions && pageOptions.length > 1 &&
            <div className="c-pagination u-flex u-flex-center-hz u-mg-top-xl u-mg-bottom-m">
                <button
                    type="button" className="c-btn c-btn--primary u-pd-hz-xs u-mg-hz-m"
                    onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                >
                    {'<<'}
                </button>
                {pageOptions.map(index => {
                    if (pageShouldBeHiddenInPagination(index)) return <React.Fragment key={index}></React.Fragment>
                    return (
                        <button
                            type="button"
                            key={index}
                            className={(index === pageIndex ? 'u-bold' : '')}
                            style={{ width: '30px' }}
                            onClick={() => gotoPage(index)}
                        >
                            {index + 1}
                        </button>
                    )
                })}
                <button
                    type="button" className="c-btn c-btn--primary u-pd-hz-s u-mg-hz-m"
                    onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}
                >
                    {'>>'}
                </button>
            </div>
        }
    </>
  )
}

Pagination.propTypes = {
    pageOptions: array, 
    gotoPage: func, 
    canPreviousPage: bool, 
    pageIndex: number, 
    pageCount: number, 
    canNextPage: bool,
    paginationLength: number
}

export default Pagination
