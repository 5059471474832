export const getError = (res, errorFn) => {
    if(res?.error) console.error(res?.error, errorFn(res?.error))

    return () => (!res || res?.error) ? errorFn(res?.error) : null
}

export const getEntitiesError = (error) => {
    return 'Nous n\'avons pas pu récupérer les entités disponibles : l\'opération n\'est pas permise.'
}

export const getAutoDiagsError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return "La récupération de l'autodiagnostic n'est pas autorisée"
        default:
            return "Une erreur s'est produite lors de la récupération de l'autodiagnostic"
    }
}

export const getAutoDiagsFromBackOfficeError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return "La récupération des autodiagnostics n'est pas autorisée"
        case 'InvalidArgument':
            return 'Il y a un problème avec une des informations des autodiagnostics'
        default:
            return "Une erreur s'est produite lors de la récupération des autodiagnostics"
    }
}

export const createAutoDiagError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            return "Il y a un problème avec une des informations de l'autodiagnostic"
        case 'NotAuthorizedException':
            return "La sauvegarde de l'autodiagnostic n'est pas autorisée"
        case 'EntityNotFoundException':
            return "Votre compte n'a pas été trouvé pour sauvegarder l'autodiagnostic "
        default:
            return "Une erreur s'est produite lors de la sauvegarde de l'autodiagnostic"
    }
}

export const updateAutoDiagError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            return "Il y a un problème avec une des informations de l'autodiagnostic"
        case 'NotAuthorizedException':
            return "La mise à jour de l'autodiagnostic n'est pas autorisée"
        case 'EntityNotFoundException':
            return "Votre autodiagnostic n'a pas été trouvé pour effectuer la mise à jour "
        default:
            return "Une erreur s'est produite lors de la mise à jour de l'autodiagnostic"
    }
}

export const updateStatusError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            return 'Il y a un problème avec la valeur du status'
        case 'NotAuthorizedException':
            return "La mise à jour du status n'est pas autorisée"
        case 'EntityNotFoundException':
            return "Le profil n'a pas été trouvé pour effectuer la mise à jour "
        default:
            return "Une erreur s'est produite lors de la mise à jour du status"
    }
}

export const getUsersError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return "La récupération des utilisateurs n'est pas autorisée"
        default:
            return "Une erreur s'est produite lors de la récupération des utilisateurs"
    }
}

export const getUserError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            return 'Il y a un problème avec une des informations de l\'utilisateur'
        case 'DuplicatesValueException':
            return 'Le siret saisi appartient à un autre compte inscrit'
        case 'EntityNotFoundException':
            return "Votre compte n'a pas été trouvé"
        case 'EntityVersionMismatchException':
            return "Les informations que vous aviez, avant modification, n'étaient pas à jour. Veuillez actualiser la page pour disposer des informations à jour."
        case 'NotAuthorizedException':
            return "La modification de votre compte n'est pas autorisée"
        case 'FramingMeetingRequiredException':
            return "Vous n'avez pas encore effectué votre réunion de cadrage"
        default:
            return "Une erreur s'est produite lors de l'enregistrement"
    }
}

export const getUpdateBackOfficeUserError = (error) => {
    switch (error?.code) {
        case 'DuplicatesValueException':
            return 'Le siret saisi appartient à un autre compte inscrit'
        case 'EntityNotFoundException':
            return "Votre compte n'a pas été trouvé"
        case 'EntityVersionMismatchException':
            return "Les informations que vous aviez, avant modification, n'étaient pas à jour. Veuillez actualiser la page pour disposer des informations à jour."
        case 'NotAuthorizedException':
            return "La modification de votre compte n'est pas autorisée"
        default:
            return "Une erreur s'est produite lors de l'enregistrement"
    }
}

export const getForgotPasswordError = (error) => {
    switch (error?.code) {
        case 'TooManyRequestsException':
            return 'Un code de confirmation a déjà été envoyé à cette adresse mail'
        default:
            return 'Une erreur s\'est produite lors de l\'envoi du mail'
    }
}

export const getCreateBackOfficeUserError = (error) => {
    switch (error?.code) {
        case 'DuplicatesValueException':
            return "L'email saisi appartient à un autre compte inscrit"
        case 'NotAuthorizedException':
            return "La création du compte n'est pas autorisée"
        default:
            return "Une erreur s'est produite lors de l'enregistrement"
    }
}

export const getSignUpError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            return 'Il y a un problème avec une des informations de l\'utilisateur'
        case 'TooManyRequestsException':
            return 'Cet utilisateur existe déjà'
        case 'NotAuthorizedException':
            if(error?.message === 'Unknown mail.') return 'L\'email renseigné est inconnu'
            if(error?.message === 'Code expired or invalid.') return 'Le code est expiré ou invalide'
            return 'Vous n\'avez pas les droits nécessaires pour créer un utilisateur'
        case 'DuplicatesValueException':
            return 'Le siret saisi appartient à un autre compte inscrit'
        default:
            return "Une erreur s'est produite lors de la création du compte utilisateur"
    }
}

export const getDeleteEmailError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            return 'Il y a un problème avec une des informations deu mail'
        default:
            return "Une erreur s'est produite lors de la suppression du mail utilisateur"
    }
}

export const getYourselfAssignFolderError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return "Vous n'avez pas les droits nécessaires pour affecter un dossier."
        case 'FolderNotFoundException':
            return "Aucun dossier n'est disponible."
        case 'UserNotFoundException':
            return "L'utilisateur est introuvable'."
        default:
            return "Une erreur s'est produite lors de l'affectation d'un dossier"
    }
}

export const getBackOfficeFoldersError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return "Vous n'avez pas les droits nécessaires pour récupérer les dossier."
        case 'UserNotFoundException':
            return "L'utilisateur est introuvable'."
        default:
            return "Une erreur s'est produite lors de la récupération des requêtes du Back Office"
    }
}

export const getRequestError = (error) => {
    switch (error?.code) {
        case 'EntityStatusException':
            return "L'entité lié à la demande est introuvable."
        case 'RequestNotFoundException':
            return 'La demande est introuvable.'
        case 'NotAuthorizedException':
            return "Vous n'êtes pas autorisé à faire cette opération."
        default:
            return "Une erreur s'est produite lors de la récupération de la demande"
    }
}

export const getRequestsError = (error) => {
    switch (error?.code) {
        case 'EntityStatusException':
            return "L'entité lié à la demande n'est pas valide"
        case 'NotAuthorizedException':
            return "Vous n'êtes pas authorisé à faire cette opération"
        case 'InvalidArgument':
            return 'Il y a un problème avec une des informations'
        default:
            return "Une erreur s'est produite lors de la récupération des demandes de prestation"
    }
}

export const getRequestProvidersError = (error) => {
    switch (error?.code) {
        case 'EntityStatusException':
            return "L'entité lié à la demande n'est pas valide"
        case 'NotAuthorizedException':
            return "Vous n'êtes pas authorisé à faire cette opération"
        case 'InvalidArgument':
            return 'Il y a un problème avec une des informations'
        default:
            return "Une erreur s'est produite lors de la récupération des demandes fournisseur"
    }
}

export const getBackendRequestsError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return "Vous n'êtes pas authorisé à faire cette opération"
        case 'InvalidArgument':
            return 'Il y a un problème avec un des arguments de la requête'
        default:
            return "Une erreur s'est produite lors de la récupération des requêtes du Back Office"
    }
}

export const getCreateRequestError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return "Vous n'avez pas les droits nécessaires pour faire une demande de prestation."
        case 'TooManyRequestsException':
            return "Une demande de prestation est déjà en cours d'envoi."
        case 'EntityStatusException':
            return "Votre inscription n'est pas valide ou n'a pas encore été validée."
        case 'ChartAcceptedRequiredException':
            return "Vous n'avez pas encore signé la charte d'accès"
        default:
            return "Une erreur s'est produite lors de l'envoi de la demande."
    }
}

export const getEditRequestError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return "Vous n'avez pas les droits nécessaires pour effectuer des modifications."
        case 'RequestNotFoundException':
            return "La mise à jour a échoué car le dossier initial n'a pas pu être identifié."
        case 'RequestVersionMismatchException':
            return 'La version de votre demande que vous essayez de modifier est obsolète.'
        case 'ProviderNotFoundException':
            return "Un des prestataires sélectionnés n'est pas répertorié dans notre base."
        case 'InvalidArgument':
            return "Il y a un problème avec une des informations de l'entité"
        default:
            return "Une erreur s'est produite lors de l'envoi des factures et/ou preuves de paiement"
    }
}

export const getBackOfficeEntityError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            return "L'entité est introuvable."
        case 'EntityNotFoundException':
            return "L'entité est introuvable."
        case 'NotAuthorizedException':
            return "Vous n'êtes pas autorisé à faire cette opération."
        default:
            return "Une erreur s'est produite lors de la récupération de l'entité."
    }
}

export const getBackOfficeBeneficiariesError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return "Vous n'êtes pas autorisé à faire cette opération."
        default:
            return "Une erreur s'est produite lors de la récupération des bénéficiaires."
    }
}

export const editBackOfficeEntityError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            return "Il y a un problème avec les informations de l'entité."
        case 'EntityNotFoundException':
            return "L'entité est introuvable."
        case 'NotAuthorizedException':
            return "Vous n'êtes pas autorisé à faire cette opération."
        case 'EntityVersionMismatchException':
            return "Vous êtes en train de modifier une ancienne version de l'entité."
        case 'DuplicatesValueException':
            return 'Ce numéro de SIRET existe déjà sur la plateforme.'
        case 'ServicesNeededException':
            return "Un ou plusieurs services sélectionné(s) n'existe(nt) pas."
        case 'ServicesValueException':
            return "Au moins un service n'existe pas."
        default:
            return "Une erreur s'est produite lors de la mise à jour de l'entité."
    }
}

export const checkEntityBackOfficeRequestError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
        case 'NotAuthorizedException':
            return 'La requête est introuvable.'
        case 'UserNotFoundException':
            return 'L\'utilisateur lié à la demande est introuvable'
        case 'EntityNotFoundException':
            return 'L\'entité lié à la demande est introuvable.'
        case 'EntityVersionMismatchException':
            return 'Vous essayez de modifier une version périmée de cette entité.'
        case 'DuplicatesValueException':
            return 'Le siret saisi appartient à un autre compte inscrit'
        default:
            return 'Une erreur s\'est produite lors de la tentative de mise à jour de la requête.'
    }
}

export const editBackOfficeRequestError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            case 'RequestNotFoundException':
            return 'La requête est introuvable.'
        case 'RequestVersionMismatchException':
            return 'La version de votre demande que vous essayez de modifier est obsolète.'
        case 'EntityStatusException':
            return "L'entité liée à la demande est introuvable."
        case 'DataExceptionBeforeSendToErp':
            return 'Données invalides pour créer la facture'
        default:
            return "Une erreur s'est produite lors de la tentative de mise à jour de la requête."
    }
}

export const editBackOfficeAdvisorError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            return 'La requête est introuvable.'
        case 'NotAuthorizedException':
            return 'Cette demande n\'est pas autorisée.'
        case 'EntityNotFoundException':
            return 'L\'entité est introuvable.'
        case 'RequestNotFoundException':
            return 'La demande est introuvable.'
        case 'FubUserNotFoundException':
            return 'Le conseiller est introuvable.'
        default:
            return "Une erreur s'est produite lors de la tentative de mise à jour de la requête."
    }
}

export const getRequestHistoryError = (error) => {
    switch (error?.code) {
        case 'EntityStatusException':
            return "L'entité lié à la demande n'est pas valide"
        case 'InvalidArgument':
            return 'Il y a un problème avec une des informations de l\'utilisateur'
        default:
            return "Une erreur s'est produite lors de la tentative de lecture de l'historique."
    }
}

export const getBackOfficeRequestHistoryError = (error) => {
    switch (error?.code) {
        case 'EntityStatusException':
            return "L'entité lié à la demande n'est pas valide"
        case 'InvalidArgument':
            return 'Il y a un problème avec une des informations de l\'utilisateur'
        default:
            return "Une erreur s'est produite lors de la tentative de lecture de l'historique."
    }
}

export const getValidateProviderEmailError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            return 'Il y a un problème avec une des informations du mail'
        case 'NotAuthorizedException':
            return 'La vérification d\'une adresse mail n\'est pas autorisée'
        case 'MailNotAvailableException':
            return 'Ce mail n\'est pas disponible'
        case 'TooManyRequestsException':
            return 'Un code a déjà été envoyé mais n\'a pas encore été utilisé'
        default:
            return "Une erreur s'est produite lors de la tentative de validation de l'adresse mail"
    }
}

export const getBackOfficeCommentError = (error) => {
    switch (error?.code) {
        case 'RequestVersionMismatchException':
            return 'Vous essayez de modifier une version obsolète de cette demande.'
        case 'RequestNotFoundException':
            return 'La demande n\'a pas été trouvée'
        case 'NotAuthorizedException':
            return 'Il n\'est pas possible d\'ajouter un commentaire'
        default:
            return "Une erreur s'est produite lors de la tentative de validation du commentaire"
    }
}

export const getCreateRegularisationFolderError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            return 'Il y a un problème avec une des informations du dossier de régularisation'
        case 'APIExceptionWhenSendToErp':
            return 'Le SIRET fourni est invalide/inexistant'
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits pour créer ce dossier de régularisation.'
        default:
            return "Une erreur s'est produite lors de la tentative de création d'un dossier de régularisation"
    }
}

export const getRegularisationFoldersError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            return 'Les dossiers de régularisation n\'ont pas pu être récupérés'
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits pour accéder à ces dossiers de régularisation.'
        default:
            return "Une erreur s'est produite lors de la tentative de récupération de dossiers de régularisation"
    }
}

export const getCreateProviderDataError = (error) => {
    switch (error?.code) {
        case 'InvalidArgument':
            return 'Il y a un problème avec une des informations dans le fichier importé.'
        case 'EntityNotFoundException':
            return 'Le fournisseur n\'a pas été trouvé.'
        case 'EmptyEquipmentSupplyException':
            return 'La ligne ne peut pas être supprimée car elle n\'existe pas.'
        case 'EntityIdNotSameException':
            return 'Le siret n\'est pas celui du fournisseur.'
        case 'NotAuthorizedException':
            return "Vous n'êtes pas autorisé à faire cette opération."
        case 'AlreadyRequestException': 
            return 'Il n\'est pas possible de modifier un équipement déjà utilisé dans au moins un dossier bénéficiaire.'
        default:
            return "Une erreur s'est produite lors de l'ajout de cet équipement"
    }
}

export const getProviderEquipmentsError = (error) => {
    switch (error?.code) {
        case 'EntityNotFoundException':
            return 'Le fournisseur n\'a pas été trouvé.'
        case 'NotAuthorizedException':
            return "Vous n'êtes pas autorisé à faire cette opération."
        default:
            return "Une erreur s'est produite lors de l'ajout de la table de correspondance"
    }
}

export const getAllEquipmentsSupplyListError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return "Vous n'êtes pas autorisé à faire cette opération."
        default:
            return "Une erreur s'est produite lors de la récupération des équipements"
    }
}

export const getEditEquipmentSupply = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return "Vous n'êtes pas autorisé à faire cette opération."
        default:
            return "Une erreur s'est produite lors de la mise à jour de la demande"
    }
}

export const getRequestEquipmentsError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return "Vous n'êtes pas autorisé à faire cette opération."
        default:
            return "Une erreur s'est produite lors de la mise à jour de la demande"
    }
}