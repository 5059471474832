import React from 'react'
import { func, object } from 'prop-types'

import { useAlert } from 'hooks/useAlert'
import { useModal } from 'hooks/useModal'
import * as CatalogApi from 'services/api/CatalogApi'
import dateService from 'services/date'
import FormUploadBid from 'components/platform/FormUploadBid'
import { quoteStatusList } from 'utils/constants'

import ProtectedLayout from 'layouts/Protected'
import Accordion from 'components/shared/Accordion'
import EquipementPDF from 'components/pdf/EquipementPDF'
import FormElement from 'components/shared/FormElement'
import Emoji from 'components/shared/Emoji'
import { downloadRequestPDF } from 'components/catalog/catalogUtils'

const ModalUploadBid = ({ callback, request }) => {
    const { hideModal } = useModal()
    const validate = () => {
        hideModal()
        callback()
    }
    return (
        <div className="u-pd-xl">
            <h2 className="c-h2 u-primary u-center u-mg-vt-l">Déposer un devis</h2>
           <FormUploadBid onSuccess={validate} request={request} />
            <div className="u-flex u-flex-center-hz">
                <button
                    type="button"
                    className="c-btn c-btn--white-bordered u-pd-hz-l u-mg-bottom-m u-mg-0@main"
                    onClick={hideModal}
                >
                    Fermer
                </button>
            </div>
        </div>
    )
}

ModalUploadBid.propTypes = {
    callback: func,
    request: object
}

const statusFilter = [
    { value: -1, label: 'Tous', id: 'all' },
    ...quoteStatusList
]

const ProviderMonitoringPage = () => {
    const [requests, setRequests] = React.useState([])
    const [filteredRequests, setFilteredRequests] = React.useState([])
    const [filter, setFilter] = React.useState(statusFilter[0])

    const { addAlert } = useAlert()
    const { showModal } = useModal()


    const fetchData = async () => {
        try {
            const { res: backendRequests, getError } = await CatalogApi.getBackendRequests()
            if(getError()) throw getError()
            
            setRequests(backendRequests)
        } catch (error) {
            addAlert('error', error?.toString())
            
        }
    }

    // fetch data on first load
    React.useEffect(() => fetchData(), []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        // Manage filter
        setFilteredRequests(
            (typeof filter === 'number' && filter > -1)
                ? requests.filter(req => req?.quote_status === filter)
                : requests
        )
    }, [filter, requests])

    const handleDownloadRequestPDF = async (request) => {
        const { error } = await downloadRequestPDF({
            user: request,
            request,
            pdfTemplate: EquipementPDF,
            pdfTitle: 'demande-de-devis',
            readRequestProviders: false
        })

        if (error) addAlert('error', "Une erreur s'est produite lors de la génération du dossier. Veuillez réessayer.")
    }

    const uploadBid = (event, { request }) => {
        event.preventDefault()
        showModal(
            ModalUploadBid,
            {
                request,
                callback: () => {
                    // refresh data
                    fetchData()
                }
            },
            { style: { maxWidth: '800px' } }
        )
    }

    const getQuoteStatusLabel = (status) => {
        return quoteStatusList.find(i => i.value === status)?.label
    }

    return (
        <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main">
            <div className="l-container-xxl u-flex">
                <div>
                    <h1 className="c-h1 u-secondary u-center u-uppercase u-mg-bottom-xl">Mes demandes de devis</h1>
                    <div className="u-flex u-flex-end@main u-mg-bottom-xl">
                        <FormElement
                            value={filter}
                            options={statusFilter}
                            name={filter}
                            type="select"
                            label="Trier par status"
                            style={{ minWidth: '300px' }}
                            className=""
                            required={false}
                            onChange={e => setFilter(e?.target?.value)}
                        />
                    </div>
                    {filteredRequests?.length > 0 && filteredRequests.map((request, index) => {
                        return (
                                <Accordion
                                    key={index}
                                    className="u-mg-bottom-xl"
                                    head={() => {
                                        return (
                                            <div className="u-pd-vt-l">
                                                <div className="u-flex u-flex-between u-flex-center-vt u-flex-dir-col u-flex-dir-row@main u-pd-hz-m u-mg-bottom-m">
                                                    <h3 className="c-h3 u-font-base u-bold u-mg-bottom-m u-mg-bottom-0@main">Demande n°{request?.request_fub_id}</h3>
                                                    <span className="u-mg-hz-m u-bold u-mg-bottom-m u-mg-bottom-0@main">{getQuoteStatusLabel(request?.quote_status)}</span>
                                                    <button
                                                        type="button"
                                                        className="c-btn c-btn--white u-pd-hz-l u-mg-bottom-m u-mg-bottom-0@main"
                                                        onClick={() => handleDownloadRequestPDF(request)}
                                                    >
                                                        Consulter la demande (pdf)
                                                    </button>
                                                </div>
                                                <div className="u-flex u-flex-between u-flex-center-vt@main u-flex-dir-col u-flex-dir-row@main u-pd-hz-m">
                                                    <div className="u-flex u-flex-dir-col u-flex-dir-row@main">
                                                        <p className="u-fs-s">{request?.entity_data?.structureName} ({request?.entity_data?.structureCity})</p>
                                                        <span className="u-mg-hz-s u-hide u-inline-block@main">&mdash;</span>
                                                        <p className="u-fs-s">{request?.entity_data?.mail}</p>
                                                    </div>
                                                    <p className="u-fs-s">Envoyée le {dateService.formatDate(request?.create_date, 'dd/MM/y')}</p>
                                                </div>
                                            </div>
                                        )
                                    }}
                                    // openingCallback={() => {
                                    //     // Call API when the accordion is uncollapsed
                                    //     ; (async (caseId) => {
                                    //         await getCurrentBid(caseId)
                                    //     })()
                                    // }}
                                >
                                    <div className="u-pd-hz-xl u-pd-vt-l">
                                        {request?.request_description?.full_media_url &&
                                            <div className="u-center u-underline u-mg-bottom-m">
                                                <a href={request?.request_description?.full_media_url} target={'_blank'} rel="noopener noreferrer" className="u-underline">
                                                Afficher la photo de l'emplacement
                                                </a>
                                            </div>
                                        }
                                        {request?.quote_status < 2 &&
                                            <>
                                                <p className="u-center u-mg-bottom-l">Vous n'avez pas encore déposé de devis pour cette demande.</p>
                                                <div className="u-flex u-flex-center-hz">
                                                    <button
                                                        type="button"
                                                        className="c-btn c-btn--secondary u-pd-hz-xl u-mg-bottom-l"
                                                        onClick={(e) => uploadBid(e, { request })}
                                                    >
                                                        Déposer un devis
                                                    </button>
                                                </div>
                                            </>
                                        }
                                        {request?.quote_status === 2 &&
                                            <>
                                                <div className="u-flex u-flex-between u-flex-center-vt u-flex-dir-col u-flex-dir-row@main u-mg-bottom-l">
                                                    <div className="u-mg-bottom-m">
                                                        <p className="u-mg-bottom-l">Vous avez déposé un devis le {dateService.formatDate(request?.modify_date, 'dd/MM/y')}.</p>
                                                        <a
                                                            className={'c-btn c-btn--secondary u-pd-hz-xl'}
                                                            target="_blank" rel="noopener noreferrer"
                                                            href={request?.quote_full_media_url}
                                                            disabled={!request?.quote_full_media_url}
                                                        >
                                                            Consulter mon devis
                                                        </a>
                                                    </div>
                                                    <div className="u-mg-bottom-m">
                                                        <p className="u-mg-bottom-l">Pour le remplacer, cliquez ci-dessous.</p>
                                                        <button
                                                            type="button"
                                                            className="c-btn c-btn--white-bordered u-pd-hz-xl"
                                                            onClick={(e) => uploadBid(e, { request })}
                                                        >
                                                            Envoyer un nouveau devis
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {request?.quote_status > 2 &&
                                            <>
                                                <div className="u-flex u-flex-center-hz u-flex-center-vt u-flex-dir-col u-flex-dir-row@main u-mg-bottom-l">
                                                    <a
                                                        className={'c-btn c-btn--white-bordered u-mg-hz-m u-pd-hz-xl'}
                                                        target="_blank" rel="noopener noreferrer"
                                                        href={request?.quote_full_media_url}
                                                        disabled={!request?.quote_full_media_url}
                                                    >
                                                        Consulter mon devis
                                                    </a>
                                                </div>
                                            </>
                                        }
                                        
                                    </div>
                                </Accordion>
                        )
                    })}
                    {(!filteredRequests || filteredRequests?.length === 0) &&
                        <p className="u-center u-mg-top-m">
                            Aucune demande ne semble correspondre à votre recherche <Emoji label="visage triste" symbol="😟"/>
                        </p>
                    }
                </div>
                <section className='u-mg-left-l'>
                    <h3 className="c-h3 u-bold u-primary u-center u-mg-bottom-m">Besoin d'informations ?</h3>
                    <div>
                        <a href="https://employeurprovelo.fr/wp-content/uploads/2022/06/OEPV_Guide-stationnement_20220616_page.pdf" target={'_blank'} rel="noopener noreferrer" className="c-btn c-btn--secondary u-pd-hz-l u-mg-vt-m">
                            Guide stationnement vélo 
                        </a>
                        <a href="https://employeurprovelo.fr/objectif-employeur-pro-velo/catalogue/stationnement-velo/" target={'_blank'} rel="noopener noreferrer" className="c-btn c-btn--secondary u-pd-hz-l u-mg-vt-m">
                            Prise en charge financière 
                        </a>
                    </div>
                </section>
            </div>
        </ProtectedLayout>
    )
}

export default ProviderMonitoringPage
