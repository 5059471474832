import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import BackOfficeLayout from 'layouts/back-office'
import Card from 'components/platform/Card'
import BeneficiaryInformations from 'components/back-office/BeneficiaryInformations'
import { getBackOfficeEntity, checkEntityBackOfficeRequest, editBackOfficeEntity } from 'services/api/CatalogApi'
import { useAlert } from 'hooks/useAlert'
import useMediaQuery from 'hooks/useMediaQuery'
import { useAuth } from 'hooks/useAuth'
import FormElement from 'components/shared/FormElement'
import { isNotEmpty } from 'utils/validation'
import { 
    getBackOfficeStatus, 
    isKBISFolder, 
    SIRENE, 
    isSIRENEFolder, 
    KBIS, 
    SIRET, 
    BACK_OFFICE_ENTITY_OPERATIONS, 
    getCheckEntityBody
} from 'utils/domain/beneficiary'
import { 
    VALIDATED_STATUS,
    PENDING_STATUS,
    TO_VALIDATE_STATUS,
    SENDING_FUB_STATUS,
    GO_BACK_ADVISOR_STATUS,
} from 'utils/domain/request'
import { scrollToTop } from 'utils/scrollTo'
import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'
import RowInformations from 'components/platform/Informations/RowInformations'
import { privateRoutes } from 'router/routes'
import Comment from 'components/shared/Comment'
import { removeFullMediaUrlProperties } from 'utils/payloadBuilder'
import { getKeywords } from 'components/auth/FormSignup'
import { jsonHash } from 'utils/hash'

const CHECK_SIRET_URL = 'https://avis-situation-sirene.insee.fr/'

const defaultFormFields = {
    comment: ''
}

const EntityFolder = () => {
    const { beneficiaryId } = useParams()
    const [ beneficiary, setBeneficiary ] = useState()
    const [ entityFolder, setEntityFolder ] = useState({})
    const [ rowHash, setRowHash ] = useState(null)
    const [isChangingStatus, setIsChangingStatus] = useState(false)
    const [ disabledButton, setDisabledButton] = useState(false)
    const { addAlert } = useAlert()
    const history = useHistory()
    const { isABackOfficeManagementSupervisor } = useAuth()
    const isLarge = useMediaQuery('(min-width: 1500px)')

    const [ formFields, setFormFields ] = useState(defaultFormFields)
    const [ initStatus, setInitStatus ] = useState()
    const [ showLocalErrors, setShowLocalErrors ] = React.useState(false)
    const [ showGlobalError, setShowGlobalError ] = React.useState(false)
    const [ errors, setErrors ] = React.useState([])

    const isKBIS = beneficiary && isKBISFolder(beneficiary?.gender)
    const isSIRENE = beneficiary && isSIRENEFolder(beneficiary?.gender)
    const folderType = isKBIS ? KBIS : isSIRENE ? SIRENE : SIRET

    // Lors de l'enregistrement d'un document SIRENE 'Account.js', le type de dossier envoyé à l'API est KBIS
    // Petit hack ici pour pouvoir ouvrir le document SIRENE
    const hackFolderType = isSIRENE ? KBIS : folderType

    const url = beneficiary?.documents?.find(el => el.media_gender === hackFolderType)?.full_media_url || ''

    const commentHasChanged = entityFolder?.entity_data?.comment && formFields?.comment && jsonHash(formFields.comment) === jsonHash(entityFolder?.entity_data?.comment)

    const fetchEntity = async () => {
        try {
            const { res, getError } = await getBackOfficeEntity(beneficiaryId)
            if (getError()) throw getError()
            setEntityFolder(res)

            const statusValue = () => {
                if(res.entity_data.sending_fub) return SENDING_FUB_STATUS
                if(!res.entity_check || !res.entity_check.action_benef) return TO_VALIDATE_STATUS
                if(res.entity_check && res.entity_check.is_check) return VALIDATED_STATUS // warning : entityCheck.is_check and entityCheck.action_benef can be true together. But if is_check is true, it has priority, so folder is validated.
                return PENDING_STATUS
            }

            setFormFields(
                { 
                    comment: res.entity_data?.comment,
                    status: statusValue()
                }
            )
            setInitStatus(statusValue())
            const payload = {
                create_date: res.create_date,
                ...res.entity_data
            }
            setBeneficiary(payload)
            setRowHash(res.row_hash)
        } catch(error) {
            addAlert('error', error?.toString())
        }
    }

    React.useEffect(() => {
        fetchEntity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkEntity = async (entityCheck) => {
        const entityToCheck = { id: beneficiaryId, rowHash }

        const { res: entityChecked, getError } = await checkEntityBackOfficeRequest(
            getCheckEntityBody(entityToCheck, entityCheck)
        )
        if (getError()) throw getError()

        setEntityFolder(prevEntityFolder => ({ ...prevEntityFolder, entity_check: entityChecked.entity_check }))
        setRowHash(entityChecked.row_hash)
        return entityChecked
    }

    const saveEntityComment = async () => {
        try {
            const entityToEdit = { 
                entity_id: entityFolder.entity_id,
                status: entityFolder.status,
                last_row_hash: entityFolder.row_hash,
                keywords: getKeywords(entityFolder.entity_data),
                entity_data: {
                    ...entityFolder.entity_data,
                    comment: formFields.comment,
                }
            }

            const { getError } = await editBackOfficeEntity(entityToEdit)
            if (getError()) throw getError()

            addAlert('success', 'Le commentaire a bien été ajouté')
            fetchEntity()
        } catch(error) {
            addAlert('error', error?.toString())
        }
    }

    const saveComment = async (entityChecked) => {
        const entityToEdit = { 
            entity_id: entityChecked.entity_id,
            status: entityChecked.status,
            last_row_hash: entityChecked.row_hash,
            keywords: getKeywords(entityChecked.entity_data),
            entity_data: {
                ...entityFolder.entity_data,
                comment: formFields.comment,
            }
        }
        if(entityChecked.entity_data?.documents) entityToEdit.entity_data['documents'] = removeFullMediaUrlProperties(entityChecked.entity_data.documents)
        const { res, getError } = await editBackOfficeEntity(entityToEdit)
        if (getError()) throw getError()
        return res
    }

    const handleChangeRequestStatus = async (event) => {
        event.preventDefault()

        setDisabledButton(true)
        if(formFields.status === VALIDATED_STATUS) {
            // On valide la demande => "validé"
            validate()
        } else if(formFields.status === PENDING_STATUS) {
            // On est conseiller et on passe le dossier en "En attente"
            if(!isFormReady()) return

            const entityChecked = await checkEntity(BACK_OFFICE_ENTITY_OPERATIONS.checkEntity.askMoreInformations)
            await saveComment(entityChecked)
            await fetchEntity()

            addAlert('success', 'Le dossier a bien été passé en "En attente".')
            setIsChangingStatus(false)
    
        } else if(formFields.status === GO_BACK_ADVISOR_STATUS) {
            await handleRemoveSendingFub()
        } else {
            // Statut "transmis_fub" => on passe le boolean à true.
            try {
                const entityToEdit = { 
                    entity_id: entityFolder.entity_id,
                    status: entityFolder.status,
                    last_row_hash: entityFolder.row_hash,
                    keywords: getKeywords(entityFolder.entity_data),
                    entity_data: {
                        ...entityFolder.entity_data,
                        sending_fub: true,
                    }
                }
                    
                const { getError } = await editBackOfficeEntity(entityToEdit)
                if (getError()) throw getError()
                addAlert('success', 'Le dossier a bien été passé en "Transmis FUB".')
                fetchEntity()
                setIsChangingStatus(false)
            } catch(error) {
                addAlert('error', error?.toString())
            } finally {
                setDisabledButton(false)
            }
        }
    }

    const handleRemoveSendingFub = async () => {
        try {
            const entityToEdit = { 
                entity_id: entityFolder.entity_id,
                status: entityFolder.status,
                last_row_hash: entityFolder.row_hash,
                keywords: getKeywords(entityFolder.entity_data),
                entity_data: {
                    ...entityFolder.entity_data,
                    sending_fub: false,
                }
            }
                
            const { getError } = await editBackOfficeEntity(entityToEdit)
            if (getError()) throw getError()
            addAlert('success', 'Le dossier a bien été retourné au conseiller.')
            fetchEntity()
            setIsChangingStatus(false)
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledButton(false)
        }
    }

    const askMoreInformations = async () => {
        try {
            setDisabledButton(true)
            const subject = `OEPV: Demande d'informations complémentaires - Dossier ${beneficiaryId}`
            window.location = `mailto:${beneficiary?.contactEmail}?subject=${subject}`
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledButton(false)
        }
    }

    const validate = async () => {
        try {
            if(!isFormReady()) return
            const entityChecked = await checkEntity(BACK_OFFICE_ENTITY_OPERATIONS.checkEntity.validate)
            await saveComment(entityChecked)
            
            addAlert('success', `Le ${folderType} a bien été validé`)
            history.push(privateRoutes.backOffice.backOfficeHome.path)
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledButton(false)
        }
    }

    const checkSiret = () => {
        window.open(CHECK_SIRET_URL, '_blank').focus()
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
      }, [])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
            ? target.checked
            : target.type === 'file'
                ? target
                : target.value
        const name = target.name

        setFormFields({
            ...formFields,
            [name]: value
        })
    }

    const isFormReady = () => {
        const formReady = !(errors.length > 0)
        if (!formReady) {
            setShowLocalErrors(true)
            setShowGlobalError(true)
            addAlert('error', 'Erreur(s) dans le formulaire.')
            scrollToTop()
            return false
        }
        return true
    }

    const isSendingFub = entityFolder.entity_data?.sending_fub ?? false
    const isBlockDisabled = isSendingFub && !isABackOfficeManagementSupervisor
    const status = entityFolder && getBackOfficeStatus(entityFolder?.entity_check, isSendingFub)

    const advisorStatusListForRegisterEntity = (entityFolder) => [
        {
            value: VALIDATED_STATUS,
            label: 'Validé',
        },
        {
            value: PENDING_STATUS,
            label: 'En attente',
            disabled: entityFolder?.entity_check?.action_benef
        },
        {
            value: SENDING_FUB_STATUS,
            label: 'Transmis FUB',
        },
    ]

    const supervisorStatusListForRegisterEntity = (entityFolder) => [
        {
            value: VALIDATED_STATUS,
            label: 'Validé',
        },
        {
            value: GO_BACK_ADVISOR_STATUS,
            label: 'Retour conseiller',
            disabled: !entityFolder.entity_data.sending_fub
        },
        {
            value: SENDING_FUB_STATUS,
            label: 'Transmis FUB',
            disabled: entityFolder.entity_data.sending_fub
        },
    ]

    if(beneficiary === undefined) return(<BackOfficeLayout><div className="c-spinner" /></BackOfficeLayout>)
    
    return(
        <BackOfficeLayout>
            <div className={`${isBlockDisabled ? 'is-disabled-block' : ''}`}>
                <div className="u-flex u-flex-dir-col">
                    <h1 className="c-h1 u-bold u-primary u-mg-top-m u-mg-bottom-xl">
                        {`Dossier Contrôle ${isSIRENE ? 'Avis de situation au répertoire' : ''} ${folderType} - ${beneficiary?.structureName}`}
                    </h1>
                    <div className="u-flex u-flex-dir-col u-flex-dir-row@small-desktop">
                        <form className={`l-col-12 l-col-8@small-desktop u-mg-top-m ${isLarge ? 'u-mg-right-l' : ''}`}>
                            { showGlobalError && <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur qui empêche la validation du formulaire.</p>}
                            <Card title={`${isSIRENE ? 'Avis de situation au répertoire' : ''} ${folderType}`} status={status} className="u-pd-l">
                                {isKBIS
                                    &&
                                    <>
                                        <InformationToVisualize label={'Contrôler la conformité du KBIS de l\'établissement'} url={url}/>
                                        <Comment 
                                            value={formFields.comment}
                                            showLocalErrors={showLocalErrors}
                                            handleValidation={handleValidation}
                                            handleInputChange={handleInputChange}
                                            placeholder={'Ajouter un commentaire à votre dossier'}
                                            maxLength={1000}
                                        />
                                    </>
                                }

                                {isSIRENE
                                    &&
                                    <>
                                        <InformationToVisualize label={'Contrôler la conformité de l’avis de situation au répertoire SIRENE'} url={url}/>
                                        <Comment 
                                            value={formFields.comment}
                                            showLocalErrors={showLocalErrors}
                                            handleValidation={handleValidation}
                                            handleInputChange={handleInputChange}
                                            placeholder={'Ajouter un commentaire à votre dossier'}
                                            maxLength={1000}
                                        />
                                    </>
    }
                                    {(!isKBIS && !isSIRENE) &&
                                        <>
                                            <div className="u-flex">
                                                <RowInformations informations={[{ label: SIRET, value: beneficiary?.siret }]}/>
                                                <button
                                                    type="button" onClick={checkSiret}
                                                    className="c-btn c-btn--green c-btn--back-office u-pd-hz-m u-mg-m u-flex-self-end u-text-transform-none"
                                                >
                                                    Vérifier le SIRET
                                                </button>
                                            </div>
                                            <Comment 
                                                value={formFields.comment}
                                                showLocalErrors={showLocalErrors}
                                                handleValidation={handleValidation}
                                                handleInputChange={handleInputChange}
                                                placeholder={'Ajouter un commentaire à votre dossier'}
                                                maxLength={1000}
                                            />
                                        </>
                                }
                                <button
                                    type="button"
                                    className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-pd-s"
                                    onClick={saveEntityComment}
                                    disabled={commentHasChanged}
                                >
                                    Enregistrer le commentaire
                                </button>

                                {isChangingStatus &&
                                <div className="u-flex u-flex-center-hz u-flex-end-vt u-mg-top-m">
                                    <button
                                        type="button"
                                        className="c-btn c-btn--s c-btn--danger c-btn--back-office u-pd-hz-m u-mg-hz-m u-pd-s"
                                        onClick={() => {
                                            setIsChangingStatus(false)
                                        }}
                                    >
                                        Annuler
                                    </button>

                                    {/* List of advisors */}
                                    <FormElement
                                        value={formFields.status}
                                        options={isABackOfficeManagementSupervisor ? supervisorStatusListForRegisterEntity(entityFolder) : advisorStatusListForRegisterEntity(entityFolder)}
                                        name="status"
                                        type="select"
                                        label="Changer le statut"
                                        className="u-pd-hz-m"
                                        showErrors={showLocalErrors}
                                        onValidate={handleValidation}
                                        onChange={handleInputChange}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                    
                                    <button
                                        type="button"
                                        className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-mg-hz-m u-pd-s"
                                        onClick={handleChangeRequestStatus}
                                        disabled={
                                            // Disabled button if the status is the same as the initial status
                                            initStatus === formFields.status
                                        }
                                    >
                                        Valider
                                    </button>
                                </div>
                            }
                            {!isChangingStatus &&
                                <div className="u-flex u-flex-dir-row u-flex-center-hz u-mg-top-m">
                                    <button
                                        type="button"
                                        className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-mg-m u-pd-s"
                                        onClick={() => setIsChangingStatus(true)}
                                        disabled={disabledButton}
                                    >
                                        Changer le statut
                                    </button>
                                    <button
                                        type="button"
                                        className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-mg-m u-pd-s"
                                        onClick={askMoreInformations}
                                        disabled={disabledButton}
                                    >
                                        Demander des informations complémentaires
                                    </button>
                                </div>
                                }
                            </Card>
                        </form>
                        <div className="l-col-12 l-col-4@small-desktop u-mg-top-m u-mg-bottom-m">
                            <BeneficiaryInformations initialBeneficiary={ beneficiary } />
                        </div>
                    </div>
                </div>
            </div>
        </BackOfficeLayout>
    )
}

export default EntityFolder