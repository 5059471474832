import React from 'react'
import { func, object, shape, string } from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import { getColor } from 'utils/styles'
import saveBlob from 'utils/saveBlob'
import { fileHash } from 'utils/hash'

import dateService from 'services/date'
import { useModal } from 'hooks/useModal'
import { useAlert } from 'hooks/useAlert'
import { getMediaUrlPrefix } from 'utils/domain/user'
import uploadFile from 'services/uploadFile'
import { getFileType, isPDFExtension } from 'utils/file'
import {
    isAskedAuditStatus, isAuditReportDocument, isCertifDocument,
    isCgvDocument, isOrderedAuditStatus, isPaidAuditStatus, isRealizedAuditStatus
} from 'utils/domain/audit'

import DownloadIcon from 'components/svg/Download'
import UploadIcon from 'components/svg/Upload'

import './styles.scss'

const MAX_FILE_SIZE = 20000000

const ImportDownloadCell = ({ cell, updateData }) => {

    const { addAlert } = useAlert()
    const { hideModal } = useModal()
    const ref = React.useRef(null)

    const isEnable = cell?.isEnable
    if (!isEnable) return (<></>)

    const audit = cell.row.original
    const mediaGender = cell?.mediaGender
    const documentType = cell?.documentType
    const auditStatus = cell.row.original?.audit_status
    const cellId = audit.audit_id
    const structureName = audit.entity_data?.structureName

    // document to import or download
    const media = audit?.audit_media?.documents
        .find(document => document.media_gender === mediaGender)

    const isUploadable = (() => {
        if (isCgvDocument(mediaGender) && !media && isAskedAuditStatus(auditStatus)) return true
        if (isAuditReportDocument(mediaGender) && !media && isRealizedAuditStatus(auditStatus)) return true
        if (isCertifDocument(mediaGender) && !media && isRealizedAuditStatus(auditStatus) && isPaidAuditStatus) return true
        return false
    })()

    const isEditable = (() => {
        if (isCgvDocument(mediaGender) && isOrderedAuditStatus(auditStatus)) return true
        if (isAuditReportDocument(mediaGender) && (isRealizedAuditStatus(auditStatus) || isPaidAuditStatus(auditStatus))) return true
        if (isCertifDocument(mediaGender) && (isRealizedAuditStatus(auditStatus) || isPaidAuditStatus(auditStatus))) return true
        return false
    })()

    const getDocumentFileName = (media) => {
        const extension = media?.media_url?.split('.').pop()
        switch (documentType) {
            case 'audit':
                return `rapport-audit-${structureName}-${dateService.getCurrentDate('dd-MM-y')}.${extension}`
            case 'reunion-de-cadrage':
                return `rapport-de-reunion-de-cadrage-${structureName}-${dateService.getCurrentDate('dd-MM-y')}.${extension}`
            case 'certification':
                return `attestation-${structureName}-${dateService.getCurrentDate('dd-MM-y')}.${extension}`
            case 'cgv':
                return `cgv-${structureName}-${dateService.getCurrentDate('dd-MM-y')}.${extension}`
            default:
                return null
        }
    }

    const downloadMedia = async () => {
        try {
            const documentFileName = getDocumentFileName(media)
            const document = await fetch(media?.full_media_url)
            const blob = await document.blob()
            saveBlob(blob, documentFileName)
        } catch (error) {
            addAlert('error', error?.toString())
        }
    }

    const isFileValid = (fileExtension, fileSize, maxSize = MAX_FILE_SIZE) => {
        if (!isPDFExtension(fileExtension)) {
            addAlert('error', 'Extension du fichier non valide. Format accepté : .pdf')
            return false
        }

        if (fileSize > maxSize) {
            const sizeInMo = maxSize / 1000000
            addAlert('error', `Taille du fichier maximum dépassée (${sizeInMo}Mo).`)
            return false
        }

        return true
    }

    const handleBlobHasBeenRead = async (event, fileInfos) => {
        try {
            const type = getFileType(event.target.result)

            if (!type || type === 'unknown') {
                addAlert('error', 'Type du fichier inconnu...')
                return
            }

            const blob = new Blob([new Uint8Array(event.target.result)], { type: fileInfos.fileType })
            const id = uuidv4()

            await uploadFile(documentType, { id, extension: fileInfos.fileExtension, blob })
            const documentToUpload = {
                media_url: `${getMediaUrlPrefix()}${id}.${fileInfos.fileExtension}`,
                media_hash: fileInfos.hash_file,
                media_gender: mediaGender
            }

            await updateData(documentToUpload)
            hideModal()
        } catch (error) {
            addAlert('error', 'Une erreur est survenue pendant le téléversement du document')
        }
    }

    const handleChange = async (event) => {
        const [file] = event.target.files

        const fileExtension = file.name.split('.').pop()
        const fileType = file.type === 'application/pdf' ? 'application/pdf' : 'image/png'

        if (!isFileValid(fileExtension, file.size)) return

        const hash_file = await fileHash(file)

        const reader = new FileReader()

        reader.addEventListener('loadend', (event) =>
            handleBlobHasBeenRead(event, { fileType, fileExtension, hash_file }))

        reader.readAsArrayBuffer(file)
    }

    return (
        <div className='u-left u-mg-left-l import-download-media u-flex'>
            {isUploadable &&
                <div ref={ref}>
                    <label id="label-upload-media" htmlFor={`upload-media-${cellId}`} >
                        <UploadIcon className="vertical-align" color={getColor().primary} />
                    </label>
                    <input
                        type="file" name="document" id={`upload-media-${cellId}`} className="u-sr-only"
                        onChange={mediaGender && handleChange}
                    />
                </div>
            }
            {media &&
                <div ref={ref}>
                    <button
                        type="button" aria-label="Télécharger le document"
                        onClick={() => downloadMedia()}
                    >
                        <DownloadIcon className="vertical-align" color={getColor().primary} />
                    </button>
                    {isEditable &&
                        <>
                            <label id="label-update-media" htmlFor={`update-report-${cellId}-${mediaGender}`} >
                                modifier
                            </label>
                            <input
                                type="file" name="document" id={`update-report-${cellId}-${mediaGender}`} className="u-sr-only"
                                onChange={mediaGender && handleChange}
                            />
                        </>
                    }
                </div>
            }
        </div>
    )
}

export default ImportDownloadCell

ImportDownloadCell.propTypes = {
    cell: object.isRequired,
    media: shape({
        full_media_url: string.isRequired,
        media_hash: string.isRequired,
        media_url: string.isRequired
    }),
    updateData: func.isRequired
}