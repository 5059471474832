import React from 'react'
import { useParams, useHistory } from 'react-router-dom'

import * as CatalogApi from 'services/api/CatalogApi'
import * as UtilsApi from 'services/api/UtilsApi'
import { getKeywords } from 'components/auth/FormSignup'
import { useAlert } from 'hooks/useAlert'
import { useModal } from 'hooks/useModal'
import ModalConfirmation from 'components/shared/ModalConfirmation'

import { checkIsBackOfficeLeadSupervisor } from 'utils/domain/user'

import BackOfficeLayout from 'layouts/back-office'
import Card from 'components/platform/Card'
import MobilityInformations from './MobilityInformation'
import ParticipantInformations from './Informations'
import TechnicalInformation from './TechnicalInfomation'
import SupportInformation from './SupportInformation'

import ScopingMeetingInformation from './ScopingMeetingInformation'
import AuditInformation from './AuditInformation'
import { useAuth } from 'hooks/useAuth'
import { scrollToTop } from 'utils/scrollTo'
import { getValidPassword } from 'utils/functions'
import { isNotEmpty } from 'utils/validation'
import { isEmpty } from 'lodash'
import { privateRoutes } from 'router/routes'
import { GENDER_PROVIDER_VALUE, auditProvider, mobilityProvider, scopingMeetingProvider, supportProvider } from 'utils/domain/provider'
import httpService, { API_BASE_URL_PROD, API_BASE_URL_TEST } from 'services/http'

const defaultFormEvents = {
  ready: true,
  editable: false
}

const ParticipantDetail = () => {

  const [ isCreation, setIsCreation ] = React.useState(false)
  const [ participant, setParticipant ] = React.useState() // displayed user 

  // form states
  const [formFields, setFormFields] = React.useState()
  const [formEvents, setFormEvents] = React.useState(defaultFormEvents)
  const [showLocalErrors, setShowLocalErrors] = React.useState(false)
  const [showGlobalError, setShowGlobalError] = React.useState(false)
  const [errors, setErrors] = React.useState([])

  const { addAlert } = useAlert()
  const { showModal } = useModal()
  const { user, logout } = useAuth()
  const { userId: participantId } = useParams()
  const history = useHistory()

  const isBOLeadSupervisor = checkIsBackOfficeLeadSupervisor(user?.entity_data)

  React.useEffect(() => {
    setFormFields(prevFormFields => ({
      ...prevFormFields,
      ...getFormFieldsFromParticipant(participant),
    }))
  }, [participant])

  React.useEffect(() => {
    // creation
    if(isEmpty(participantId)) {
      setIsCreation(true)
      setFormEvents((prevFormEvents) => ({ ...prevFormEvents, editable: true }))
    } else { // modification
      fetchRequest() 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantId])

  const fetchRequest = async() => {
    const { res: participantResponse, getError } = await CatalogApi.getBackOfficeEntity(participantId)
    if (getError()) throw getError()

    setParticipant({
      ...participantResponse,
      isActive: isNotEmpty(participantResponse?.entity_data?.active_entity) ? participantResponse.entity_data.active_entity : true
    })

    return {
        ...participantResponse,
        ...participantResponse?.entity_data
    }
  }

  // ------------------------------------------------------------------------------
  //              Form fields builder
  // ------------------------------------------------------------------------------
  const getFormFieldsFromParticipant = (participant) => {
    const participantData = participant?.entity_data
    return {
      // bloc "informations participant"
      structureName: participantData?.structureName || '',
      structureSiret: participantData?.structureSiret || '',
      contactLastname: participantData?.contactLastname || '',
      contactFirstname: participantData?.contactFirstname || '',
      structureAddress: participantData?.structureAddress || '',
      structureCity: participantData?.structureCity || '',
      structurePostCode: participantData?.structurePostCode || '',
      connexionEmail: participantData?.mail || '',
      contactEmail: participantData?.contactEmail || '',
      structurePhonenumber: participantData?.structurePhonenumber || '',
      website: participantData?.website || '',

      // bloc "Services éducatifs mobilité et services techniques"
      trainingBackToBike: participantData?.['training-back-to-bike'] === 'Oui',
      trainingCompagny: participantData?.['training-company'] === 'Oui',
      drivingSafelyCompany: participantData?.['driving-safely-company'] === 'Oui',
      trainingElectricBike: participantData?.['training-electric-bike'] === 'Oui',
      praticalTrainingElectricBike: participantData?.['pratical-training-electric-bike'] === 'Oui',
      trainingTrafficCode: participantData?.['training-traffic-code'] === 'Oui',
      technicalCheck: participantData?.['technical-check'] === 'Oui',
      technicalMaintenance: participantData?.['technical-maintenance'] === 'Oui',
      technicalWorkshops: participantData?.['technical-workshops'] === 'Oui',
      bikeOverhaul: participantData?.['bike-overhaul'] === 'Oui',

      // bloc "Services conseil et accompagnement"
      auditPreparation: participantData?.['audit-preparation'] === 'Oui',
      specificationsTraining: participantData?.['specifications-training'] === 'Oui',
      actionPlan: participantData?.['action-plan'] === 'Oui',
      cyclingStrategy: participantData?.['cycling-strategy'] === 'Oui',
      setUpAndTraining: participantData?.['setup-and-training'] === 'Oui',
      trainingAndToolsOfCyclingTeam: participantData?.['training-and-tools-of-cycling-team'] === 'Oui',
      diagnosticMobility: participantData?.['diagnostic-mobility'] === 'Oui',
      department: participantData?.area?.filter((dpt, index) => participantData?.area?.indexOf(dpt) === index) || [],

      // bloc "Réunion de cadrage"
      framingMeetingAvailable: participantData?.framing_meeting_available ? true : false,
      framingMeetingLot: participantData?.lot,

      //bloc "Audit"
      auditAvailable: participantData?.audit_available ? true : false,
      auditLot: participantData?.lot_audit
    }
  }

  const handleValidation = React.useCallback((name, errs) => {
    setErrors((s) => {
        const cleanErrors = [...s.filter((e) => e.origin !== name)]
        return [
            ...cleanErrors,
            ...errs
        ]
    })
  }, [])

  const handleInputChange = (event) => {
    const target = event.target
    const name = target.name

    let value = null
    switch(target.type) {
      case 'checkbox':
        value = target.checked
        break
      case 'file':
        value = target
        break
      case 'radio':
        value = target.value === 'true' ? true : false
        break
      default: 
        value = target.value
    }

    setFormFields(prevFormFields => ({
      ...prevFormFields,
      [name]: value 
    }))
  }

  const handleConnexionEmailChange = (event) => {
    setFormFields({
        ...formFields,
        contactEmail: event.target.value,
        connexionEmail: event.target.value
    })
  }

  const showValidationModal = (event) => {
    event.preventDefault()
    const titleMotal = isCreation ? 'Êtes-vous sûr(e) de vouloir créer cet intervenant ?' : 'Êtes-vous sûr(e) de vouloir modifier la fiche ?'
    const bodyModal = isCreation ? 'En cliquant sur "confirmer" vous enregistrez cet intervenant.' : 'En cliquant sur "confirmer" vous modifiez les informations du participant.'

    showModal(
        ModalConfirmation,
        {
            title: titleMotal,
            body: bodyModal,
            validate: handleSubmitValidation,
            closeAfterValidation: true
        }
    )
  }

  // ------------------------------------------------------------------------------
  //              Form submit method
  // ------------------------------------------------------------------------------
  const handleSubmitValidation = async () => {
    // Validate inputs
    const isFormReady = errors.length === 0
    if (!isFormReady) {
      setShowLocalErrors(true)
      setShowGlobalError(true)
      addAlert('error', 'Erreur(s) dans le formulaire.')
      scrollToTop()
      return
    }
    try {      
      // Calculate coordinates
      console.info('Coordinates have changed')
      const coordinates = await UtilsApi.getCoordinatesFromAdress(formFields?.structureAddress, formFields?.structurePostCode)                

      if (isNotEmpty(coordinates)) {
      
        if(!formFields.trainingBackToBike && !formFields.trainingCompagny && !formFields.trainingElectricBike && !formFields.trainingTrafficCode
          && !formFields.technicalCheck && !formFields.technicalMaintenance && !formFields.technicalWorkshops && !formFields.bikeOverhaul && !formFields.auditPreparation && !formFields.specificationsTraining
          && !formFields.actionPlan && !formFields.setUpAndTraining && !formFields.trainingAndToolsOfCyclingTeam && !formFields.diagnosticMobility && !formFields.framingMeetingAvailable && !formFields.cyclingStrategy
          && !formFields.auditAvailable) {
            throw new Error('au moins un service doit être attaché à cet intervenant.')
        }

        const isActivePartipant = isCreation ? true : participant?.isActive
        isCreation ? await createParticipant(coordinates) : await updateParticipant(isActivePartipant, coordinates)

      } else {
        const newError = {
          origin: 'structurePostCode',
          rule: 'structurePostCode-notFound',
          message: 'Le code postal n\'existe pas.'
        }
        setErrors((prevErrors) => [...prevErrors, newError])
        throw new Error('Adresse non reconnue, code postal inexistant.')
      }
    } catch (error) {
      addAlert('error', error?.message)
      setShowGlobalError(true)
      setShowLocalErrors(true)
      setFormEvents({ editable: true, ready: false })
    }
  }

  const checkEmail = async () => {
    // Check email
    const { res } = await CatalogApi.validateProviderEmail(formFields?.connexionEmail, GENDER_PROVIDER_VALUE)
    if(res?.error) {
      console.error(res.error.message)
      throw new Error('L\'e-mail que vous renseignez est déjà utilisé.')
    }
    return res
  }

  // ------------------------------------------------------------------------------
  //              Call API : create participant
  // ------------------------------------------------------------------------------
  const createParticipant = async (coordinates) => {
  
    // Create participant
    const participantToCreate = await buildCreateParticipantPayload(coordinates)

    // TODO: prevent saving token sent by the signUp route
    // request options
    const createEntityRoute = 'oepvapi/entity'
    const createEntityUrl = process.env.REACT_APP_MODE === 'production'
      ? API_BASE_URL_PROD + createEntityRoute
      : API_BASE_URL_TEST + createEntityRoute

    const options = {
      method: 'POST',
      body: JSON.stringify(participantToCreate),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Accept-Version': '1.0.0',
        'Authorization': `Bearer ${await httpService.getToken({ logout, addAlert })}`
      }
    }

    try {
      const response = await fetch(createEntityUrl, options)

      if(response.ok) {
        addAlert('success', 'Intervenant enregistré.')
        backToParticipantsList()
      }

      const error = await response.json()
      if (error?.code) throw error
    } catch (error) {

      if (error.code === 'NotAuthorizedException') {
        addAlert('error', 'Vous n\'êtes pas autorisé à créer un intervenant')
      } else if (error.code === 'TooManyRequestsException') {
        addAlert('error', 'Ce bénéficiaire existe déjà')
      } else if (error.code === 'ServicesValueException') {
        addAlert('error', 'Vous devez sélectionner au moins un service pour enregistrer l\'intervenant')
      } else if (error.code === 'DuplicatesValueException') {
        addAlert('error', 'Le SIRET est déjà enregistré pour un autre intervenant')
      } else {
        addAlert('error', 'Une erreur s\'est produite lors de la création d\'un intervenant')
      }

      setFormEvents({ ready: true, editable: true })
    }  
  }

  const buildCreateParticipantPayload = async (coordinates) => {
    const mailInfos = await checkEmail()
    const entityId = mailInfos?.entity_id
    const code = mailInfos?.code

    const entity_data = {
      structureName: formFields.structureName,
      structureSiret: formFields.structureSiret,
      contactLastname: formFields.contactLastname,
      contactFirstname: formFields.contactFirstname,
      structureAddress: formFields.structureAddress,
      structureCity: formFields.structureCity,
      structurePostCode: formFields.structurePostCode,
      structurePhonenumber: formFields.structurePhonenumber,
      website: formFields.website,
      contactEmail: formFields.contactEmail,
      mail: formFields.connexionEmail,
      longitude: coordinates[0],
      latitude: coordinates[1],
      active_entity: true,

      // bloc "Services éducatifs mobilité et services techniques"
      'training-back-to-bike': formFields.trainingBackToBike ? 'Oui' : 'Non',
      'training-company': formFields.trainingCompagny ? 'Oui' : 'Non',
      'driving-safely-company': formFields.drivingSafelyCompany ? 'Oui' : 'Non',
      'training-electric-bike': formFields.trainingElectricBike ? 'Oui' : 'Non',
      'pratical-training-electric-bike': formFields.praticalTrainingElectricBike ? 'Oui' : 'Non',
      'training-traffic-code': formFields.trainingTrafficCode ? 'Oui' : 'Non',
      'technical-check': formFields.technicalCheck ? 'Oui' : 'Non',
      'technical-maintenance': formFields.technicalMaintenance ? 'Oui' : 'Non',
      'technical-workshops': formFields.technicalWorkshops ? 'Oui' : 'Non',
      'bike-overhaul': formFields.bikeOverhaul ? 'Oui' : 'Non',

      // bloc "Services conseil et accompagnement"
      'audit-preparation': formFields.auditPreparation ? 'Oui' : 'Non',
      'specifications-training': formFields.specificationsTraining ? 'Oui' : 'Non',
      'action-plan': formFields.actionPlan ? 'Oui' : 'Non',
      'cycling-strategy': formFields.cyclingStrategy ? 'Oui' : 'Non',
      'setup-and-training': formFields.setUpAndTraining ? 'Oui' : 'Non',
      'training-and-tools-of-cycling-team': formFields.trainingAndToolsOfCyclingTeam ? 'Oui' : 'Non',
      'diagnostic-mobility': formFields.diagnosticMobility ? 'Oui' : 'Non',
      area: formFields.department,

      // bloc "Réunion de cadrage"
      framing_meeting_available: formFields.framingMeetingAvailable,
      lot: formFields.framingMeetingLot,

      //bloc "Audit"
      audit_available: formFields.auditAvailable,
      lot_audit: formFields.auditLot,

      // properties required by the API
      gender: GENDER_PROVIDER_VALUE,
      services: getServices(),
      siret: formFields.structureSiret
    }

    return {
      entity_id: entityId,
      code: code,
      password: getValidPassword(),
      keywords: getKeywords(formFields),
      entity_data
    }
  }

  // ------------------------------------------------------------------------------
  //              Call API : update participant
  // ------------------------------------------------------------------------------
  const updateParticipant = async (newActiveStatus, coordinates) => {
    try {
      const payload = buildUpdateParticipantPayload(newActiveStatus, coordinates)
      const { getError } = await CatalogApi.editBackOfficeEntity(payload) // call API to update beneficiary
      if(getError()) throw getError()
      fetchRequest()
      addAlert('success', 'Modification enregistrée')
      setFormEvents({ ready: true, editable: false })
      setShowGlobalError(false)
      setShowLocalErrors(false)
      scrollToTop()
    } catch (error) {
      addAlert('error', error?.message)
      setShowGlobalError(true)
      setShowLocalErrors(true)
      setFormEvents({ editable: true, ready: false })
    }
  }

  const updateParticipantStatus = async (newActiveStatus) => {
    try {
      const entity_data = {
        ...participant.entity_data,
        active_entity: newActiveStatus, // if the provider is active or not (warning, if the user is inactive, he stays as auditor or scoping meeting provider)
      }

      const payload = {
        entity_id: participant.entity_id,
        status: participant.status,
        last_row_hash: participant.row_hash,
        keywords: getKeywords(participant.entity_data),
        entity_data,
      }

      const { getError } = await CatalogApi.editBackOfficeEntity(payload) // call API to update beneficiary
      if(getError()) throw getError()
      fetchRequest()
      addAlert('success', 'Modification enregistrée')
      setFormEvents({ ready: true, editable: false })
      setShowGlobalError(false)
      setShowLocalErrors(false)
      scrollToTop()
    } catch (error) {
      addAlert('error', error?.message)
      setShowGlobalError(true)
      setShowLocalErrors(true)
      setFormEvents({ editable: true, ready: false })
    }
  }

  const buildUpdateParticipantPayload = (isActiveStatus, coordinates) => {
    const entity_data = {
      ...participant.entity_data,
      structureName: formFields.structureName,
      structureSiret: formFields.structureSiret,
      contactLastname: formFields.contactLastname,
      contactFirstname: formFields.contactFirstname,
      structureAddress: formFields.structureAddress,
      structureCity: formFields.structureCity,
      structurePostCode: formFields.structurePostCode,
      contactEmail: formFields.contactEmail,
      mail: formFields.connexionEmail,
      structurePhonenumber: formFields.structurePhonenumber,
      website: formFields.website,
      active_entity: isActiveStatus, // if the provider is active or not (warning, if the user is inactive, he stays as auditor or scoping meeting provider)
      longitude: coordinates[0],
      latitude: coordinates[1],

      // bloc "Services éducatifs mobilité et services techniques"
      'training-back-to-bike': formFields.trainingBackToBike ? 'Oui' : 'Non',
      'training-company': formFields.trainingCompagny ? 'Oui' : 'Non',
      'driving-safely-company': formFields.drivingSafelyCompany ? 'Oui' : 'Non',
      'training-electric-bike': formFields.trainingElectricBike ? 'Oui' : 'Non',
      'pratical-training-electric-bike': formFields.praticalTrainingElectricBike ? 'Oui' : 'Non',
      'training-traffic-code': formFields.trainingTrafficCode ? 'Oui' : 'Non',
      'technical-check': formFields.technicalCheck ? 'Oui' : 'Non',
      'technical-maintenance': formFields.technicalMaintenance ? 'Oui' : 'Non',
      'technical-workshops': formFields.technicalWorkshops ? 'Oui' : 'Non',
      'bike-overhaul': formFields.bikeOverhaul ? 'Oui' : 'Non',

      // bloc "Services conseil et accompagnement"
      'audit-preparation': formFields.auditPreparation ? 'Oui' : 'Non',
      'specifications-training': formFields.specificationsTraining ? 'Oui' : 'Non',
      'action-plan': formFields.actionPlan ? 'Oui' : 'Non',
      'cycling-strategy': formFields.cyclingStrategy ? 'Oui' : 'Non',
      'setup-and-training': formFields.setUpAndTraining ? 'Oui' : 'Non',
      'training-and-tools-of-cycling-team': formFields.trainingAndToolsOfCyclingTeam ? 'Oui' : 'Non',
      'diagnostic-mobility': formFields.diagnosticMobility ? 'Oui' : 'Non',
      area: formFields.department,

      // bloc "Réunion de cadrage"
      framing_meeting_available: formFields.framingMeetingAvailable,
      lot: formFields.framingMeetingLot,

      //bloc "Audit"
      audit_available: formFields.auditAvailable,
      lot_audit: formFields.auditLot,

      // properties required by the API
      gender: GENDER_PROVIDER_VALUE,
      services: getServices(participant.entity_data.services),
      siret: formFields.structureSiret
    }

    return {
      entity_id: participant.entity_id,
      status: participant.status,
      last_row_hash: participant.row_hash,
      keywords: getKeywords(participant.entity_data),
      entity_data
    }
  }

  const showCancelationModal = (event) => {
    event.preventDefault()
    showModal(
        ModalConfirmation,
        {
            title: 'Êtes-vous sûr(e) de vouloir annuler ?',
            body: 'En cliquant sur "confirmer" vous serez redirigé(e) sur la liste des intervenants.',
            validate: backToParticipantsList,
            closeAfterValidation: true
        }
    )
  }

  const backToParticipantsList = () => {
    history.push({
      pathname: privateRoutes.backOffice.participantsAdministration.path
    })
  }

  const toggleEditableForm = () => {
    setFormEvents(prevFormEvents => ({
        ...prevFormEvents,
        editable: !formEvents.editable
    }))
  }

  const getServices = (currentServices) => {
    let newServices = ''

    const isMatchMobilityProvider = currentServices?.includes(mobilityProvider.serviceId) || false
    const isMatchSupportProvider = currentServices?.includes(supportProvider.serviceId) || false
    const isMatchScopingMeetingProvider = currentServices?.includes(scopingMeetingProvider.serviceId) || false
    const isMatchAuditProvider = currentServices?.includes(auditProvider.serviceId) || false

    if(isMatchMobilityProvider || formFields.trainingBackToBike || formFields.trainingCompagny || formFields.trainingElectricBike || formFields.trainingTrafficCode ||
      formFields.technicalCheck || formFields.technicalMaintenance || formFields.technicalWorkshops || formFields.bikeOverhaul) {
      newServices = mobilityProvider.serviceId
    }
    if(isMatchSupportProvider || formFields.auditPreparation || formFields.specificationsTraining || formFields.actionPlan || formFields.cyclingStrategy || formFields.setUpAndTraining || formFields.trainingAndToolsOfCyclingTeam || formFields.diagnosticMobility || formFields.department) {
      newServices = isNotEmpty(newServices) ? `${newServices},AEC` : 'AEC'
    }
    if(isMatchScopingMeetingProvider || formFields.framingMeetingAvailable) {
      newServices = isNotEmpty(newServices) ? `${newServices},RCA` : 'RCA'
    }
    if(isMatchAuditProvider || formFields.auditAvailable) {
      newServices = isNotEmpty(newServices) ? `${newServices},AUD` : 'AUD'
    }
    return newServices
  }

  const showDesactivationModal = (event) => {
    event.preventDefault()
    const titleAction = participant?.isActive ? 'désactiver' : 'réactiver'
    showModal(
        ModalConfirmation,
        {
            title: `Êtes-vous sûr(e) de vouloir ${titleAction} la fiche ?`,
            body: 'Attention, des dossiers en cours peuvent être impactés par ces modifications. Vous devez en informer les bénéficiaires concernés.',
            validate: () => updateParticipantStatus(!participant?.isActive),
            closeAfterValidation: true
        }
    )
  }

  const validationBtnLabel = React.useMemo(() => {
    if(formEvents?.editable) {
      if(isCreation) return 'Valider le prestataire'
      return 'Valider les modifications'
    }
    
    if(participant?.isActive) return 'Désactiver la fiche'
    if(!participant?.isActive) return 'Réactiver la fiche'
  }, [formEvents?.editable, participant?.isActive, isCreation])

  return (
    <BackOfficeLayout>
      <form>
        <div>
            { !isCreation && <h1 className="c-h1 u-inline-block u-mg-right-xl u-mg-bottom-l u-bold u-primary">Fiche de {participant?.entity_data?.structureName}</h1>}
            { (isCreation && isBOLeadSupervisor) && <h1 className="c-h1 u-inline-block u-mg-right-xl u-mg-bottom-l u-bold u-primary">Créer un intervenant</h1>}
            { (!isCreation && isBOLeadSupervisor) && <span className={`u-bold c-h3 ${participant?.isActive ? 'u-success' : 'u-danger'}`}>{participant?.isActive ? 'Actif' : 'Inactif'}</span> }
            { showGlobalError && <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur lors de la validation du formulaire.</p>}

          <div className="l-grid u-mg-left-s u-mg-right-s u-flex-nowrap">
            <Card title="Informations intervenant" className="l-col-7">
              <ParticipantInformations
                formFields={formFields}
                formEvents={formEvents}
                showLocalErrors={showLocalErrors}
                handleValidation={handleValidation}
                handleInputChange={handleInputChange}
                handleConnexionEmailChange={handleConnexionEmailChange}
                isCreation={isCreation}
              />
            </Card>
            <Card title="Services éducatifs mobilité et services techniques" className="l-col-5">
              <div className="u-flex">
                <MobilityInformations 
                  formFields={formFields}
                  formEvents={formEvents}
                  showLocalErrors={showLocalErrors}
                  handleValidation={handleValidation}
                  handleInputChange={handleInputChange}
                />
                <TechnicalInformation
                  formFields={formFields}
                  formEvents={formEvents}
                  showLocalErrors={showLocalErrors}
                  handleValidation={handleValidation}
                  handleInputChange={handleInputChange}
                />
              </div>
            </Card>
          </div>
          <div className="l-grid u-mg-left-s u-mg-right-s u-flex-nowrap">
            <Card title="Services Conseil et accompagnement" className="l-col-12" >
              <SupportInformation
                formFields={formFields}
                formEvents={formEvents}
                showLocalErrors={showLocalErrors}
                handleValidation={handleValidation}
                handleInputChange={handleInputChange}
                setFormFields={setFormFields}
              />
            </Card>
          </div>
          <div className="l-grid u-mg-left-s u-mg-right-s u-flex-nowrap">
            <Card className="l-col-6" title="Réunion de cadrage">
              <ScopingMeetingInformation
                formFields={formFields}
                formEvents={formEvents}
                showLocalErrors={showLocalErrors}
                handleValidation={handleValidation}
                handleInputChange={handleInputChange}
                isCreation={isCreation}
              />
            </Card>
            <Card className="l-col-6" title="Audit">
              <AuditInformation
                formFields={formFields}
                formEvents={formEvents}
                showLocalErrors={showLocalErrors}
                handleValidation={handleValidation}
                handleInputChange={handleInputChange}
                isCreation={isCreation}
              />
            </Card>
          </div>
          {isBOLeadSupervisor && <div className="u-flex u-flex-center-hz u-pd-top-l u-mg-negative-hz-m u-pd-hz-m u-mg-hz-0@main u-pd-hz-0@main u-mg-top-l">
              { formEvents?.editable &&
                <>
                  <button
                    type="button"
                    className="c-btn c-btn--white-bordered c-btn--m u-pd-hz-l u-mg-bottom-xl"
                    style={{ width: '100%', maxWidth: '460px' }}
                    onClick={showCancelationModal}
                  >
                    Annuler
                  </button>
                  <div className="u-flex u-flex-center-hz u-pd-hz-l" style={{ minWidth: '250px' }}>
                    <button
                      type="submit"
                      className="c-btn c-btn--primary c-btn--m u-pd-hz-l u-mg-bottom-xl"
                      style={{ width: '100%', maxWidth: '460px' }}
                      onClick={showValidationModal}
                    >
                      {validationBtnLabel}
                    </button>
                  </div>
                </>
              }
              { !formEvents?.editable && 
                <>
                  <button
                    type="button"
                    className="c-btn c-btn--primary c-btn--m u-pd-hz-l u-mg-bottom-xl"
                    style={{ width: '100%', maxWidth: '300px' }}
                    onClick={toggleEditableForm}
                  >
                      Modifier
                  </button>
                  <button
                    type="submit"
                    className="c-btn c-btn--danger c-btn--m u-pd-hz-l u-mg-bottom-xl u-mg-left-s"
                    style={{ width: '100%', maxWidth: '300px' }}
                    onClick={showDesactivationModal}
                  >
                    {validationBtnLabel}
                  </button>
                </>
              }
          </div>}
        </div>
      </form>
    </BackOfficeLayout>
  )
}

export default ParticipantDetail