import React from 'react'
import { string, func, bool, object, shape } from 'prop-types'
import FormElement from 'components/shared/FormElement'
import { isNotEmpty } from 'utils/validation'

const ScopingMeetingInformation = ({
  formFields = {},
  formEvents = {},
  showLocalErrors = {},
  handleValidation = () => {},
  handleInputChange = () => {}
}) => {

  return (
    <div className='u-flex u-flex-dir-row u-mg-right-auto'>
      <div className='l-col-6'>
        <FormElement
          value={formFields.framingMeetingAvailable}
          options={[
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' }
          ]}
          classNameOption="c-btn u-mg-right-m input-hidden"
          classNameLabel="u-mg-vt-m"
          name="framingMeetingAvailable"
          type="radio"
          label="Intervenant actif"
          showErrors={showLocalErrors}
          onValidate={handleValidation}
          onChange={handleInputChange}
          disabled={!formEvents.editable}
        />
       </div>
       <div className='l-col-6'>
        <FormElement 
          value={formFields.framingMeetingLot}
          classNameLabel="u-mg-vt-m"
          type='text'
          label="Nombre de lots"
          name="framingMeetingLot"
          customRules={[
            {
                key: 'scoping-meeting-lot-inconsistent',
                check: (value) => {
                  if(formFields.framingMeetingAvailable && (!isNotEmpty(value) || value === '0')) return false
                  return true
                },
                message: 'Au moins un lot doit être renseigné si cet intervenant est actif.'
            }
          ]}
          customRulesTrigger={formFields.framingMeetingAvailable}
          showErrors={showLocalErrors}
          onValidate={handleValidation}
          onChange={handleInputChange}
          disabled={!formEvents.editable}
        />
      </div>
    </div>
  )
}

ScopingMeetingInformation.propTypes = {
  formFields: shape({
    structureName: string,
    structureSiret: string,
    structureAddress: string,
    structureCity: string,
    structurePostCode: string,
    structurePhoneNumber: string,
    emailConnexion: string,
    beneficiaryEmail: string,
    structureEmployeeQuantity: string,
    structureEmployeeCategory: string
  }),
  setFormFields: func,
  formEvents: object,
  showLocalErrors: bool,
  handleValidation: func,
  handleInputChange: func
}

export default ScopingMeetingInformation
