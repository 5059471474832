import React, { useEffect, useState } from 'react'
import { number, shape, string } from 'prop-types'

import { useAlert } from 'hooks/useAlert'
import dateService from 'services/date'
import { getEntityGender } from 'utils/domain/global'
import { getBackOfficeEntity } from 'services/api/CatalogApi'

import RowInformations from '../platform/Informations/RowInformations'
import Card from '../platform/Card'

const BeneficiaryInformations = ({
    initialBeneficiary = {},
    beneficiaryId,
    className = ''
}) => {
    const { addAlert } = useAlert()
    const [beneficiary, setBeneficiary] = useState(initialBeneficiary)

    const getEntity = async() => {
        const { res: entityResponse, getError } = await getBackOfficeEntity(beneficiaryId)
        if (getError()) throw getError()  
        const beneficiary = {
            ...entityResponse,
            ...entityResponse?.entity_data
        }
        setBeneficiary(beneficiary)
    }

    useEffect(() => {
        try {
            if(beneficiaryId) getEntity()
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }, [beneficiaryId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setBeneficiary(initialBeneficiary)
    }, [initialBeneficiary])

    const [
        name,
        gender,
        siret,
        address,
        city,
        zipCode,
        phoneNumber,
        creation_date,
        contactName,
        contactTel,
        contactEmail
    ] = [
        {
            label: 'Raison Sociale',
            value: beneficiary.structureName
        },
        {
            label: 'Nature de l\'établissement',
            value: getEntityGender(beneficiary.gender)?.label
        },
        {
            label: 'SIRET',
            value: beneficiary.structureSiret
        },
        {
            label: 'Adresse',
            value: beneficiary.structureAddress
        },
        {
            label: 'Ville',
            value: beneficiary.structureCity
        },
        {
            label: 'Code Postal',
            value: beneficiary.structurePostCode
        },
        {
            label: 'Tel',
            value: beneficiary.structurePhonenumber
        },
        {
            label: 'Date de création du compte',
            value: beneficiary?.create_date && dateService.formatDate(beneficiary?.create_date)
        },
        {
            label: 'Responsable du compte',
            value: `${beneficiary.contactLastname} ${beneficiary.contactFirstname}`
        },
        {
            label: 'Tél du responsable',
            value: beneficiary.contactPhonenumber
        },
        {
            label: 'Email du responsable',
            value: beneficiary.contactEmail
        },
    ]

    return (
        <Card title="Informations bénéficiaire" className={className}>
            <RowInformations className="u-mg-l" key={1} informations={[name]} />
            <RowInformations className="u-mg-l" key={2} informations={[gender]} />
            <RowInformations className="u-mg-l" key={3} informations={[siret]} />
            <RowInformations className="u-mg-l" key={4} informations={[address]} />
            <RowInformations className="u-mg-l" key={5} informations={[zipCode, city]} />
            <RowInformations className="u-mg-l" key={6} informations={[phoneNumber]} />
            <RowInformations className="u-mg-l" key={7} informations={[creation_date]} />
            <hr className="c-hr-solid u-max-w-90-percent"/>
            <RowInformations className="u-mg-l" key={8} informations={[contactName]} />
            <RowInformations className="u-mg-l" key={9} informations={[contactTel]} />
            <RowInformations className="u-mg-l" key={10} informations={[contactEmail]} />
        </Card>
    )
}

BeneficiaryInformations.propTypes = {
    beneficiaryId: string,
    initialBeneficiary: shape({
        structureName: string,
        structureAddress: string,
        structureCity: string,
        structurePostCode: string,
        structureSiret: string,
        structurePhonenumber: string,
        create_date: string,
        mail: string,
        gender: number,
        contactFirstname: string,
        contactLastname: string,
        contactPhonenumber: string,
        contactEmail: string
    }),
    className: string
}

export default BeneficiaryInformations