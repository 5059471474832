import React from 'react'
import { Redirect } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import NotFound from 'router/NotFound'

import { privateRoutes } from 'router/routes'

const Home = () => {
    const { 
        isABackOfficeManagementUser, 
        isABackOfficeLeadUser, 
        isABackOfficeRelationshipManager,
        isABackOfficeRelationshipOperator
    } = useAuth()

    if(isABackOfficeManagementUser) {
        return isABackOfficeRelationshipOperator ? <Redirect to={privateRoutes.backOffice.beneficiaries.path} /> : <Redirect to={privateRoutes.backOffice.folders.path} /> 
    }

    if(isABackOfficeLeadUser) {
        return isABackOfficeRelationshipManager ? <Redirect to={privateRoutes.backOffice.management.path} /> : <Redirect to={privateRoutes.backOffice.participantsAdministration.path} />
    }

    return <NotFound />
}

export default Home