import httpService from 'services/http'

const getS3Url = async (documentName) => {
    const res = await httpService.get(`oepvapi/mediaputurl?name=${documentName}`, {}).catch(e => httpService.catchError(e))
    // console.log('getS3Url res: ', res)
    return res
}

export const getDateFromServer = async () => {
    const res = await httpService.get('oepvapi/health').catch(e => httpService.catchError(e))
    return res
}

const getCoordinatesFromAdress = async (address, postCode) => {
    const coordinates = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${address.replace(/ /g, '+')}&limit=1&postcode=${postCode}`)
                            .then(r => r.json())
                            .then(r => r.features[0]?.geometry?.coordinates)
                            .catch(e => undefined)

    if(coordinates === undefined) {
        // console.info("Impossible de trouver des coordonnées avec l'adresse complète, tentative à partir du code postal.")
        return await fetch(`https://api-adresse.data.gouv.fr/search/?q=${postCode}&limit=1&type=municipality`)
                            .then(r => r.json())
                            .then(r => r.features[0]?.geometry?.coordinates)
                            .catch(e => undefined)
    }

    return coordinates
}

const getDepartmentFromPostalCode = async (postCode) => {
    const department = await fetch(`https://geo.api.gouv.fr/departements/${postCode}`)
                            .then(r => r.json())
                            .catch(e => httpService.catchError(e))

    return department
}

const getDepartmentFromName = async (departement) => {
    const department = await fetch(`https://geo.api.gouv.fr/departements?nom=${departement}&limit=5`)
                            .then(r => r.json())
                            .catch(e => httpService.catchError(e))

    return department
}

const getDepartmentFromCode = async (code) => {
    const department = await fetch(`https://geo.api.gouv.fr/departements?code=${code}&limit=5`)
                            .then(r => r.json())
                            .catch(e => httpService.catchError(e))

    return department
}

const getDepartments = async () => {
    const department = await fetch('https://geo.api.gouv.fr/departements')
                            .then(r => r.json())
                            .catch(e => httpService.catchError(e))

    return department
}

export { getS3Url, getCoordinatesFromAdress, getDepartmentFromPostalCode, getDepartmentFromName, getDepartmentFromCode, getDepartments }