import React from 'react'
import { string, oneOfType, number } from 'prop-types'

import { DEFAULT_SIZE, DEFAULT_COLOR } from 'utils/icons'

const FilterIcon = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR, className }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" style={{ minWidth: size, width: size, minHeight: size, height: size }} viewBox="0 0 24 24" ><path fill={color} d="M13 20v-4.586L20.414 8c.375-.375.586-.884.586-1.415V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v2.585c0 .531.211 1.04.586 1.415L11 15.414V22l2-2z"></path></svg>
	)
}

FilterIcon.propTypes = {
    size: oneOfType([string, number]),
    color: string,
    className: string,
}

export default FilterIcon
