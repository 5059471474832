import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Workflow from './Workflow'
import { STEPS } from 'pages/protected/Autodiag/context'
import Restitution from 'components/autodiag/Restitution'
import { privateRoutes } from 'router/routes'

const RestitutionPage = () => {
    const { step } = useParams()

    const currentStep = STEPS[step]

    const history = useHistory()

    const goToScopingMeetingPage = () => {
        history.push(privateRoutes.scopingMeeting.path)
    }

    return (
        <div className="l-container">
            <h1 className="c-h1 u-primary u-bold u-center u-mg-top-l u-mg-bottom-l">
                {currentStep.position}. {currentStep.title}
            </h1>
            
            <Workflow currentStep={currentStep} />

            <Restitution />

            {/* Keep for next Sprint <div 
                className="u-flex u-flex-dir-col u-pd-top-l u-mg-bottom-m scoping-introduction u-center">
                <p className="u-fs-xs u-mg-bottom-l">
                Pour chaque question de cet autodiagnostic, une couleur verte signifie que votre réponse satisfait le critère, une couleur bleue qu'un point de vigilance est signalé, une couleur orange que le critère n'est pas rempli. Prêtez une attention particulière aux critères signalés comme obligatoires pour que votre site soit labellisé. Lisez attentivement les commentaires : ils contiennent des conseils pour progresser dans votre démarche pro-vélo. 
                Pour vous aider à vous repérer le cahier des charges la numérotation des questions reprend celle des critères du 
                <a className="u-underline" href="https://employeurprovelo.fr/wp-content/uploads/2021/06/Referentiel-LEPV_A4_BD02.pdf" target="_blank" rel="noreferrer">cahier des charges employeur pro-vélo</a>. 
                Nous vous conseillons aussi la lecture du 
                <a className="u-underline" href="https://www.fub.fr/sites/fub/files/fub/livreblanc_employeursprovelo.pdf" target="_blank" rel="noreferrer">livre blanc "devenir employeur pro-vélo"</a>
                </p>
            </div> */}

            <div className="u-flex u-flex-dir-col u-center">
                <div 
                    className="u-flex u-flex-dir-col u-pd-top-l u-pd-bottom-l u-mg-bottom-xl scoping-introduction">
                    <div className="u-bold u-primary u-mg-bottom-m">
                        Vous pouvez maintenant demander une réunion de cadrage via votre espace personnel.
                    </div>
                    <p className="u-fs-s u-mg-bottom-l">
                    Vous pouvez maintenant demander une réunion de cadrage via votre espace personnel. 
                    Cet entretien gratuit d'1h30 vous permettra d'approfondir ce premier rendu de votre positionnement, de poser des questions et 
                    de découvrir les prestations dont vous pouvez bénéficier grâce au programme (équipements, formation, accompagnement...) 
                    pour viser la labellisation. N'oubliez pas au préalable de charger dans votre espace personnel les documents nécessaires (K-bis...).
                    </p>
                    <button
                        type="button" onClick={goToScopingMeetingPage}
                        className="c-btn c-btn--secondary u-mg-bottom-xl">
                        Demander ma réunion de cadrage
                    </button>
                </div>

            </div>
        </div>
    )
}

export default RestitutionPage