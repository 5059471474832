import React from 'react'
import { arrayOf, shape, string } from 'prop-types'

import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'

const Emargements = ({
    emargements = []
}) => (
    <div className="u-flex u-flex-dir-col u-mg-left-l">
        {emargements?.map(({ full_media_url }, index) => 
            <InformationToVisualize 
                key={full_media_url} 
                label={`Vérifier la feuille d'émargement ${index + 1}`} 
                url={full_media_url} 
            />
        )}
    </div>
) 

Emargements.propTypes = {
    emargements: arrayOf(
        shape({
            full_media_url: string
        })
    )
}

export default Emargements