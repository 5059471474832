import React from 'react'

const useMediaQuery = (mediaQuery) => {
  const [isVerified, setIsVerified] = React.useState(!!window.matchMedia(mediaQuery).matches)

  React.useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches)

    mediaQueryList.addEventListener("change", documentChangeHandler)

    documentChangeHandler()

    return () => {
        mediaQueryList.removeEventListener("change", documentChangeHandler)
    }
}, [mediaQuery])

  return isVerified
}

export default useMediaQuery