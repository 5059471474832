import {
    StyleSheet,
} from '@react-pdf/renderer'

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Helvetica',
        fontSize: 12,
        paddingVertical: 40,
        paddingHorizontal: 60
    },
    sectionTitle: {
        flexDirection: 'row',
        paddingBottom: 10,
        marginBottom: 20,
        borderBottomColor: '#005a7c',
        borderBottomStyle: 'solid',
        borderBottomWidth: 2
    },
    section: {
        paddingBottom: 20,
        marginBottom: 20,
        borderBottomColor: '#005a7c',
        borderBottomStyle: 'solid',
        borderBottomWidth: 2
    },
    grid: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    sectionLeft: {
        flexGrow: 1,
        paddingRight: 10
    },
    sectionRight: {
        flexGrow: 1,
        paddingLeft: 25
    },
    h1: {
        fontSize: 20,
        color: '#005a7c',
        marginBottom: 10,
    },
    h2: {
        fontSize: 16,
        marginBottom: 20,
        color: '#005a7c',
    },
    h3: {
        fontSize: 14,
        marginBottom: 20,
        color: '#005a7c',
    },
    image: {
        width: 500,
        marginBottom: 50
    },
    // Utilities
    bold: {
        fontFamily: 'Helvetica-Bold'
    },
    uppercase: {
        textTransform: 'uppercase'
    }
})

export default styles