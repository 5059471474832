import React from 'react'
import { isEmailValid, isNotEmpty } from 'utils/validation'
import * as UserApi from 'services/api/UserApi'
import { useAlert } from 'hooks/useAlert'

const InputMail = ({
    // Attributes
    id = null,
    value = '',
    contactEmailValue = null, // used to check email
    name,
    label = '',
    placeholder = '',
    autocomplete,
    disabled,
    readonly = false,
    // API
    mailToValidate = false,
    initPassword = false,
    apiCallback,
    // Styles
    style,
    className = '',
    classNameLabel = '',
    classNameInput = '',
    // Error management
    required = false,
    customRules,
    showErrors,
    setShowErrors = () => {},
    // Handler
    onChange,
    onValidate
}) => {
    const [errors, setErrors] = React.useState([])
    const [backendError, setBackendError] = React.useState(null)
    const [btnDisabled, setBtnDisabled] = React.useState(true)

    const { addAlert } = useAlert()

    const rules = React.useMemo(() => [
        {
            key: 'mail-empty',
            check: (value) => required ? isNotEmpty(value) : true,
            message: 'Vous devez renseigner un e-mail'
        },
        {
            key: 'mail-invalid',
            check: (value) => isNotEmpty(value) ? isEmailValid(value.trim()) : true,
            message: 'Adresse e-mail invalide'
        },
        ...(customRules || [])
    ], [required]) // eslint-disable-line
    // customRules in dependencies array trigger an infinite loop,
    // because custom rules include a check property defining a new function,
    // preventing useMemo to return a valid shallow-comparison
    // or event useDeepMemo to return a valid deep-comparison

    const validate = React.useCallback((currentValue) => {
        const newErrors = []
        rules.forEach(rule => {
            if (!rule.check(currentValue)) {
                newErrors.push({
                    origin: name,
                    rule: rule.key,
                    message: rule.message
                })
            }
        })
        setErrors(newErrors)
        onValidate(name, newErrors)
    }, [rules, name, onValidate])

    // validate on mount and each time value change
    React.useEffect(() => {
        validate(value)
        return () => {
            // On unmounted erros and validation are no longer needed
            setErrors([])
            if (onValidate) onValidate(name, [])
        }
    }, [value, validate, onValidate, name])

    // disable btn validation if there is an error
    React.useEffect(() => setBtnDisabled(errors.length > 0), [errors])

    const validateEmailWithBackend = async () => {
        if (errors.length === 0) {
            setBtnDisabled(true)
            const body = {
                mail: value,
                contactEmail: contactEmailValue
            }
            const sendMail = await UserApi.validateEmail(body)

            if (!sendMail || sendMail?.error) {
                let errorMessage = ''
                switch (sendMail?.error?.code) {
                    case 'MailNotAvailableException':
                        errorMessage = "Cet email n'est pas disponible."
                        break
                    case 'TooManyRequestsException':
                        errorMessage = "Cet email a déjà reçu un code mais n'a pas été encore utilisé."
                        break
                    default:
                        errorMessage = "Une erreur s'est produite lors de l'envoi du mail"
                }

                addAlert('error', errorMessage)
                setBackendError(errorMessage)
            }
            else if (apiCallback) {
                apiCallback(sendMail)
                addAlert('success', 'Un nouveau code vous a été envoyé, veuillez vérifier votre adresse e-mail.')
            }
            
            setBtnDisabled(false)
        }
    }

    const initPasswordWithBackend = async () => {
        if (errors.length === 0) {
            setBtnDisabled(true)

            try {
                const { getError: getForgotPasswordError } = await UserApi.forgotPassword(value)                
                if(getForgotPasswordError()) throw getForgotPasswordError()

                setShowErrors(false)
                addAlert('success', 'Si cette adresse est connue, vous recevrez un code de confirmation par email.')
            } catch (error) {
                addAlert('error', error?.toString())
                setBackendError(error?.toString())
                setShowErrors(true)
            } finally {
                setBtnDisabled(false)
            }
        }
    }

    return (
        <>
            <div className={'c-form-group ' + className} style={style}>
                <label className={'c-label u-mg-bottom-xs ' + classNameLabel} htmlFor={id}>{label} {required && '*'}</label>
                <input
                    className={
                        'c-input '
                        + (showErrors && errors.length > 0 ? 'has-error ' : ' ')
                        + (classNameInput ? classNameInput : '')
                    }
                    id={id}
                    name={name}
                    type={'text'}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    readOnly={readonly}
                    autoComplete={autocomplete ?? 'email'}
                    required={required}
                    onChange={(e) => !readonly && onChange(e)}
                    onBlur={() => validate(value)}
                />

                {mailToValidate &&
                    <button type="button" className="c-btn c-btn--primary u-fs-xs u-br-base u-pd-hz-m" disabled={btnDisabled} onClick={validateEmailWithBackend}>
                        Valider
                    </button>
                }
                {initPassword &&
                    <button type="button" className="c-btn c-btn--secondary u-fs-xs u-br-base u-pd-hz-m" disabled={btnDisabled} onClick={initPasswordWithBackend}>
                        Envoyer
                    </button>
                }

                {/* Error messages */}
                {showErrors && errors && errors.map((error, index) => {
                    return <p key={index} className="u-fs-xs u-danger">{error.message}</p>
                })}
                {showErrors && backendError && 
                    <p className="u-fs-xs u-danger">{backendError}</p>
                }
            </div>
        </>
    )
}


export default InputMail
