import React from 'react'
import XLSX from 'xlsx'

import { useAlert } from 'hooks/useAlert'
import { useAuth } from 'hooks/useAuth'
import { useModal } from 'hooks/useModal'
import * as CatalogApi from 'services/api/CatalogApi'
import * as UserApi from 'services/api/UserApi'
import * as UtilsApi from 'services/api/UtilsApi'
import httpService, { API_BASE_URL_PROD, API_BASE_URL_TEST } from 'services/http'
import { isNotEmpty } from 'utils/validation'
import { EQUIPMENT_PROVIDER_ID, GENERIC_PROVIDER_ID } from 'utils/domain/provider'
import {
    getProviderTableCorrelation,
    getProviderKeywords,
    getProviderTableCorrelationHeader
} from 'components/admin/UploadDataCorrelation'
import { getValidPassword } from 'utils/functions'
import { PASSWORD_LENGTH } from 'utils/constants'

import UploadDataTable from 'components/admin/UploadDataTable'
import UploadIcon from 'components/svg/Upload'
import CrossIcon from 'components/svg/Cross'
import ModalConfirmation from 'components/shared/ModalConfirmation'
import FormElement from 'components/shared/FormElement'

const uploadStatusList = [
    { value: -1, label: 'Tous', id: 'all' },
    { value: 0, label: 'Non-téléversé', id: 'not-uploaded' },
    { value: 1, label: 'Téléversé', id: 'uploaded', color: 'success' },
    { value: 2, label: 'Erreur', id: 'error', color: 'danger' },
    { value: 3, label: 'Supprimé', id: 'deleted', color: 'danger' }
]

const VALID_EXTENSIONS = ['xlsx', 'xls']

const defaultFormEvents = {
    ready: true,
    done: false
}

const userOptions = [
    { value: EQUIPMENT_PROVIDER_ID, label: 'Fournisseurs d\'équipements' },
    { value: GENERIC_PROVIDER_ID, label: 'Prestataires de services' }
]

const defaultFormFields = {
    startingRow: '2',
    dataType: EQUIPMENT_PROVIDER_ID,
    file: null,
    data: [],
    uploadError: [],
    operationType: 'post'
}

const FormUploadData = () => {
    const [data, setData] = React.useState([])
    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)

    const { addAlert } = useAlert()
    const { logout } = useAuth()
    const { showModal, hideModal } = useModal()

    const handleXlsx = (event) => {
        const file = [...event.target.files][0]
        if (file) {
            const fileName = file.name
            const fileExtension = fileName.split('.').pop()

            if (VALID_EXTENSIONS.indexOf(fileExtension) === -1) {
                addAlert('error', 'Extension de fichier invalide')
                return
            }

            const reader = new FileReader()
            const readFileAsBinary = !!reader.readAsBinaryString

            setFormFields(formFields => ({
                ...formFields,
                file: {
                    type: 'file',
                    fileName: fileName,
                    extension: fileExtension
                }
            }))

            reader.onload = (e) => {
                // Parse data
                const bstr = e.target.result
                const wb = XLSX.read(bstr, { type: readFileAsBinary ? 'binary' : 'array' })
                // Get first worksheet
                const wsname = wb.SheetNames[0]
                const ws = wb.Sheets[wsname]
                // Convert array of arrays
                let data
                try {
                    data = XLSX.utils.sheet_to_json(ws, { header: 1, range: parseInt(formFields?.startingRow) })
                } catch (e) {
                    addAlert('error', 'Issue when reading sheet')
                    console.error('Issue when reading sheet', e)
                }

                if (data) {
                    // Format
                    const formatedData = data
                        .filter(row => row?.length > 0)
                        .map(row => getProviderTableCorrelation(row, formFields.dataType))
                        .map(row => ({
                            uploadStatus: 0, // init status
                            ...row
                        }))
                    setData(formatedData)
                }
            }
            readFileAsBinary
                ? reader.readAsBinaryString(file)
                : reader.readAsArrayBuffer(file)
        }
		
	}

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
                        ? target.checked
                        : target.type === 'file'
                            ? target
                            : target.value
        const name = target.name
        setFormFields(previousFormFields => ({ ...previousFormFields, [name]: value }))
    }

    const inputFileEl = React.useRef(null)
    const handleRemove = (event) => {
        event.preventDefault()
        inputFileEl.current.value = ''
        setFormFields(s => ({
            ...s,
            file: null,
            data: [],
            uploadError: [],
        }))
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const handleCreateProvider = async (provider, mailInfos, providerIndex) => {
        try{
            const providerToCreate = {
                entity_id: mailInfos?.entity_id,
                code: mailInfos?.code,
                password: getValidPassword(PASSWORD_LENGTH),
                entity_data: { ...provider },
                keywords: getProviderKeywords(provider)
            }
        
            // TODO: prevent saving token sent by the signUp route
            // request options
            const createEntityRoute = 'oepvapi/entity'
            const createEntityUrl = process.env.REACT_APP_MODE === 'production'
                ? API_BASE_URL_PROD + createEntityRoute
                : API_BASE_URL_TEST + createEntityRoute
            const options = {
                method: 'POST',
                body: JSON.stringify(providerToCreate),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Accept-Version': '1.0.0',
                    'Authorization': `Bearer ${await httpService.getToken({ logout, addAlert })}`
                }
            }
                
            await fetch(createEntityUrl, options).catch(e => {
                throw new Error(`signUp-${getSignUpError(e)}`)
            })
    
            const { getError: getSignUpError } = await UserApi.signUp(providerToCreate)
            if(getSignUpError()) throw new Error(`signUp-${getSignUpError()}`)            
        } catch (error) {
            addErrorOnRow(providerIndex, `${error || 'default'}`)
        }
    }

    const handleUpdateProvider = async (provider, providerIndDb, providerIndex) => {
        try {
            const entityData = { ...providerIndDb.entity_data, ...provider }
            delete entityData?.error
            delete entityData?.uploadStatus
    
            const providerToUpdate = {
                entity_id: providerIndDb.entity_id,
                entity_data: entityData,
                status: providerIndDb.status,
                keywords: getProviderKeywords(provider),
                last_row_hash: providerIndDb.row_hash,
            }
        
            const { getError: getUpdateError } = await UserApi.updateUser(providerToUpdate)
            if(getUpdateError()) throw new Error(`update-${getUpdateError()}`)            
        } catch (error) {
            addErrorOnRow(providerIndex, `${error || 'default'}`)            
        }
    }

    const uploadProvider = async (providerToUpload, allEntities) => {
        const providerIndex = data.indexOf(providerToUpload)
        const provider = { ...providerToUpload }
        try {

            // Calculate coordinates
            const coordinates = await UtilsApi.getCoordinatesFromAdress(provider?.structureAddress, provider?.structurePostCode)
            if (!isNotEmpty(coordinates)) addErrorOnRow(providerIndex, 'coordinates-Adresse non reconnue, code postal inexistant')
            else {
                provider.latitude = coordinates[1]
                provider.longitude = coordinates[0]
            }
            // Even without coordinates, an error is indicated on the row but integration continue
            
            
            //// PROVIDER READ WITH QUERY, COMMENTED ON PURPOSE, FOR NOW WE ONLY GET THE "BRUT FORCE APPROCH"////
            // const { res: providersFound, getError: getProvidersError } =
            //     await CatalogApi.getEntities({ query: `O-S-${provider.siret}` })
            // if(getProvidersError()) throw getProvidersError()
            
            // if(providersFound.length > 1) throw new Error('providers-Plus d\'un fournisseur trouvé pour un même SIRET')
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

            const providerFound = allEntities.find(currentProvider => currentProvider.entity_data.mail === provider?.mail)

            let entityId = undefined
            
            //// PROVIDER READ WITH QUERY, COMMENTED ON PURPOSE, FOR NOW WE ONLY GET THE "BRUT FORCE APPROCH"////
            // UPDATE PROVIDER
            // if(providersFound.length === 1) {
            //     entityId = providersFound[0].entity_id
            //     handleUpdateProvider(provider, providersFound[0])
            // }
            // // CREATE PROVIDER
            // else if(providersFound.length === 0) {
            ////////////////////////////////////////////////////////////////////////////////////////////////////

            if(providerFound) {
                entityId = providerFound.entity_id
                handleUpdateProvider(provider, providerFound, providerIndex)
            }
            else {
                // Check email
                const { res: mailInfos, getError: getErrorValidateEmail } = await CatalogApi.validateProviderEmail(provider?.mail, provider?.gender)
                if(getErrorValidateEmail()) throw new Error(`checkmail-${getErrorValidateEmail()}`)

                entityId = mailInfos?.entity_id
                handleCreateProvider(provider, mailInfos, providerIndex)
            }
        
            setFormFields(previousFormFields => {
                const updatedData = data
                updatedData[providerIndex].uploadStatus = uploadStatusList.find(s => s.id === 'uploaded').value
                // updatedData[providerIndex].error = null
                updatedData[providerIndex].entity_id = entityId

                return { ...previousFormFields, data: updatedData }
            })
        } catch (error) {
            addErrorOnRow(providerIndex, `${error || 'default'}`)
        }
    }

    const deleteProvider = async (provider) => {
        const providerIndex = data.indexOf(provider)
        try {
            if (!provider?.mail) throw new Error('no mail')

            const { getError } = await UserApi.deleteEmail(provider?.mail)
            if(getError()) throw getError()
            
            setFormFields(previousFormFields => {
                const updatedData = data
                updatedData[providerIndex].error = null
                updatedData[providerIndex].uploadStatus = uploadStatusList.find(s => s.id === 'deleted').value
                
                return { ...previousFormFields, data: updatedData }
            })
            
        } catch (error) {
            addErrorOnRow(providerIndex, error?.toString())
        }
    }
    
    //// Temporay "BRUT FORCE APPROCH" to get all entities ////
    /**
     * The service called send a limit of 200 entities, therefore we call the service again until all data have been read
     * by given the create_date of the last entities previously read to the service
     */
    const getAllEntities = async () => {
        let currentCreateBefore = ''
        let allEntitiesRead = []
        let allEntitiesHaveBeenFound = false

        try {
            do {
                const { res: providersFound, getError: getAllProvidersError } =
                    await CatalogApi.getEntities(currentCreateBefore ? { createBefore: currentCreateBefore } : {})
                if(getAllProvidersError()) throw getAllProvidersError()

                allEntitiesRead = [...allEntitiesRead, ...providersFound]

                if(providersFound.length < 200) allEntitiesHaveBeenFound = true
                else currentCreateBefore = providersFound[providersFound.length - 1].create_date
            } while (!allEntitiesHaveBeenFound)
            
        } catch (error) {
            addAlert('error', 'Problème à la lecture des entités')
            console.error(error?.toString())
        }

        return allEntitiesRead
    }

    const handleConfirmation = async () => {
        // hide error
        setShowLocalErrors(false)
        // prevent form to be submitted again
        setFormEvents({ ...formEvents, ready: false })
        // Refresh error count
        setFormFields(previousFormFields => ({ ...previousFormFields, uploadError: [] }))

        let count = data.length

        if (formFields.operationType === 'post') {
            // Temporay "BRUT FORCE APPROCH" cause keywords can be unstable
            const allEntities = await getAllEntities()
            
            for (const provider of data) {
                await uploadProvider(provider, allEntities)
                count--
                if (count === 0) setEndOfProcess()
            }
        }
        else if (formFields.operationType === 'delete') {
            for (const provider of data) {
                await deleteProvider(provider)
                count--
                if (count === 0) setEndOfProcess()
            }
        }
        setEndOfProcess()
    }

    const titleConfirmation = formFields.operationType === 'post'
    ? 'Confirmer le téléversement de ces données'
    : 'Confirmer la suppression de ces données'
    
    const handleSubmit = async () => {
        // Validate inputs
        const formReady = !(errors.length > 0)

        if (!formReady) {
            setShowLocalErrors(true)
        }
        else {
            showModal(
                ModalConfirmation,
                { title: titleConfirmation, validate: handleConfirmation }
            )

        }
    }

    // Show alert when operation is done
    React.useEffect(() => {
        if (formEvents.done) {
            if (formFields?.uploadError.length >= data.length) {
                addAlert('error', "Une erreur s'est produite lors de l'opération")
            }
            else if (formFields?.uploadError.length === 0) {
                addAlert('success', "L'opération a été réalisée avec succès.")
            }
            else {
                addAlert('warning', `L'opération a été réalisée avec succès mais nous avons rencontré des problèmes avec ${formFields?.uploadError.length} élément${formFields?.uploadError.length > 1 ? 's' : ''}`)
            }
        }
    }, [formEvents.done]) // eslint-disable-line react-hooks/exhaustive-deps

    const setEndOfProcess = () => {
        setFormEvents(formEvents => ({ ...formEvents, ready: true, done: true }))
        hideModal()
        // Reset form event
        setTimeout(() => {
            setFormEvents(formEvents => ({ ...formEvents, done: false }))
        }, 3000)
    }

    const addErrorOnRow = (rowIndex, error) => {
        setFormFields(s => {
            // update data (for table)
            const updatedData = data
            if (!isNotEmpty(updatedData[rowIndex].error)) {
                updatedData[rowIndex].error = error
            } else {
                updatedData[rowIndex].error += ` - ${error}`
            }
            updatedData[rowIndex].uploadStatus = uploadStatusList.find(s => s.id === 'error').value
            // update error in state
            const updatedUploadError = s.uploadError
            const existingErrorindex = updatedUploadError.findIndex(e => e === rowIndex)
            if (!(existingErrorindex > -1)) {
                updatedUploadError.push(rowIndex)
            }
            return {
                ...s,
                data: updatedData,
                uploadError: updatedUploadError
            }
        })
    }

    // Manage filtering for table
    // -------------------
    const [filter, setFilter] = React.useState(uploadStatusList[0])
    React.useEffect(() => {
        // Manage filter
        setFormFields(formFields => ({
            ...formFields,
            data: (typeof filter === 'number' && filter > -1)
                ? data.filter(i => i.uploadStatus === filter)
                : data
        })
    )}, [filter, data])
   
    return (
        <form>
            <div className="l-grid">
                <div className="l-col-12 l-col-5@main u-pd-m">
                    <FormElement
                        value={formFields.dataType}
                        name="dataType"
                        type="radio"
                        label="Type de données à téléverser :"
                        options={userOptions}
                        className="u-mg-bottom-l"
                        classNameOptionsContainer="u-flex-dir-col"
                        classNameLabel="u-mg-bottom-m"
                        classNameOption="u-flex-0 u-flex-center-vt"
                        required={true}
                        disabled={formFields?.data.length > 0}
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="l-col-12 l-col-4@main u-pd-m">
                    <FormElement
                        value={formFields.operationType}
                        name="operationType"
                        type="radio"
                        label="Type d'opération :"
                        options={[
                            { value: 'post', label: 'Ajouter/Modifier (POST/PUT)' },
                            { value: 'delete', label: 'Supprimer (DELETE)' },
                        ]}
                        className="u-mg-bottom-l"
                        classNameOptionsContainer="u-flex-dir-col"
                        classNameLabel="u-mg-bottom-m"
                        classNameOption="u-flex-0 u-flex-center-vt"
                        required={true}
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="l-col-12 l-col-3@main u-pd-m">
                    <FormElement
                        value={formFields.startingRow}
                        name="startingRow"
                        type="number"
                        label="Première ligne de données dans le tableau"
                        className="u-mg-bottom-m"
                        required={true}
                        disabled={formFields?.data.length > 0}
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="l-col-12 l-col-8@main u-pd-m">
                    <div className={'c-form-group'}>
                        <label className={'c-label u-mg-bottom-xs'}>Document Xlsx</label>
                        <input
                            ref={inputFileEl}
                            type="file"
                            className="u-sr-only"
                            id={'file'}
                            name={'file'}
                            onChange={handleXlsx}
                        />
                        <label className={'c-input-file'} htmlFor={'file'}>
                            <UploadIcon size={20} />
                            <div className="u-flex-1 u-pd-left-m">
                                {formFields?.file
                                    ? <>{formFields?.file?.fileName}</>
                                    : <>Ajouter un fichier</>
                                }
                            </div>
                            {formFields?.file && 
                                <button
                                    onClick={handleRemove}
                                    aria-label="Remove"
                                >
                                    <CrossIcon size={20} />
                                </button>
                            }
                        </label>
                    </div>
                </div>
            </div>

            
            <UploadDataTable
                className="u-pd-vt-xl u-mg-bottom-m"
                data={formFields?.data}
                uploadStatusList={uploadStatusList}
                currentFilter={filter}
                setDataFilter={setFilter}
                headers={getProviderTableCorrelationHeader(formFields.dataType)}
            />

            <div className="u-flex u-flex-center-hz u-pd-vt-m">
                {formEvents.ready &&
                    <button
                        type="button"
                        className={
                            'c-btn u-pd-hz-xl '
                            + (formFields.operationType === 'post' ? 'c-btn--secondary' : 'c-btn--danger')
                        }
                        onClick={handleSubmit}
                        disabled={errors.length > 0 || !(formFields?.file)}
                    >
                        {formFields.operationType === 'post' ? 'Ajouter' : 'Supprimer'} les données
                    </button>
                }
                {!formEvents.ready &&
                    <div className="c-spinner"></div>
                }
            </div>
        </form>
    )
}

export default FormUploadData
