import React from 'react'
import { arrayOf, string, shape } from 'prop-types'

import { generateThumbnailFromMedia, getFileExtension } from 'utils/file'

import attachmentWithoutBike from 'assets/images/attachment-without-bike.jpg'
import attachmentWithBike from 'assets/images/attachment-with-bike.jpg'
import equipmentPathway from 'assets/images/equipment-pathway.jpg'
import equipmentFrontLighting from 'assets/images/equipment-front-lighting.jpg'
import './styles.scss'
import { isNotEmpty } from 'utils/validation'

const PicturesValidationSample = ({
    pictures = [],
}) => {

    return (
        <>
            <ul className="l-grid u-reset-list  u-fs-xs u-mg-vt-l">
                <li className="l-col-12 l-col-3@main u-pd-xs u-pd-hz-s">
                    <BeneficiaryPicture picture={pictures[0]?.full_media_url} defaultPicture={equipmentPathway} alt="Vue d’ensemble de l’installation dans son environnement " />
                    <div className="u-semibold u-mg-bottom-s">Vue d’ensemble de l’installation dans son environnement </div>
                    <p>
                        La photo est prise avec un certain recul pour permettre de juger du cheminement piéton ou cycliste pour atteindre
                        l'équipement. Elle permet aussi de juger la présence d’un éclairage (qu’il soit intégré ou non à l’équipement). L’ensemble des attaches doit être visible.
                    </p>
                </li>
                <li className="l-col-12 l-col-3@main u-pd-xs u-pd-hz-s">
                    <BeneficiaryPicture picture={pictures[1]?.full_media_url} defaultPicture={equipmentFrontLighting} alt="Vue d’ensemble de l’installation avec les supports d’attache visibles " />
                    <div className="u-semibold u-mg-bottom-s">Vue d’ensemble de l’installation avec les supports d’attache visibles </div>
                    <p>
                        La photo est prise avec moins de recul que pour la photo 1. S’il s’agit d’une consigne sécurisée, on doit pouvoir voir l’intérieur de la consigne. 
                    </p>
                </li>
                <li className="l-col-12 l-col-3@main u-pd-xs u-pd-hz-s">
                    <BeneficiaryPicture picture={pictures[2]?.full_media_url} defaultPicture={attachmentWithoutBike} alt="Zoom sur un support d’attache" />
                    <div className="u-semibold u-mg-bottom-s">Zoom sur un support d’attache</div>
                    <p>
                        La photo est prise de manière à montrer un support d’attache, de près, dans son intégralité. Elle permet de juger l’ancrage au sol ou au mur du support et la possibilité d’attacher le vélo au niveau du cadre et de la roue avant avec un antivol U. 
                    </p>
                </li>
                <li className="l-col-12 l-col-3@main u-pd-xs u-pd-hz-s">
                    <BeneficiaryPicture picture={pictures[3]?.full_media_url} defaultPicture={attachmentWithBike} alt="Photo complémentaire" />
                    <div className="u-semibold u-mg-bottom-s">Photo complémentaire</div>
                    <p>
                        La photo montre un élément qui n’est pas forcément visible sur les 3 premières photos, par exemple : équipements complémentaires, éclairage, système d’assistance pour les supports double-étage. 
                    </p>
                </li>
            </ul>
        </>

    )
}

const BeneficiaryPicture = ({ picture, defaultPicture, alt }) => {
    const [pdfThumbnail, setPdfThumbnail] = React.useState()
    const isPDFExtension = getFileExtension(picture) === 'pdf'

    React.useEffect(() => {
		// Build thumbnail image from PDF
		if (isNotEmpty(picture) && isPDFExtension) {
            generateThumbnailFromMedia({ media: picture }).then((file) => {
                if (typeof file === 'string') setPdfThumbnail(file)
			})
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [picture])

    return (
        <>
            {isNotEmpty(picture) &&
                <a href={picture} target="_blank" rel="noreferrer noopener">
                    {isPDFExtension && <img className="picture" src={pdfThumbnail} alt={alt} />}
                    {!isPDFExtension && <img className="picture" src={picture} alt={alt} />}
                </a>
            }
            {!isNotEmpty(picture) &&
                <img className="picture" src={defaultPicture} alt={alt} />
            }
        </>

    )
}

PicturesValidationSample.propTypes = {
    pictures: arrayOf(
        shape({
            full_media_url: string
        })
    )
}

export default PicturesValidationSample