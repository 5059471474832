import React, { useEffect, useState } from 'react'

import { useModal } from 'hooks/useModal'
import dateService from 'services/date'
import { func, string } from 'prop-types'
import { updateScopingMeeting } from 'services/api/scoping-meeting/ScopingMeetingApi'
import { useAlert } from 'hooks/useAlert'
import { REALIZED_STATUS } from 'utils/domain/scopingMeetings'

const today = dateService.getCurrentDate('y-MM-dd')

const ScopingMeetingRealizedModal = ({ entityId, cancel = () => {}, fetchMyScopingMeetings = () => {} }) => {
    const { hideModal } = useModal()
    const { addAlert } = useAlert()

    const [meetingDate, setMeetingDate] = useState(today)
    const [disableConfirm, setDisableConfirm] = useState(false)

    const updateScopingMeetingStatus = async () => {
        try {
            const { getError } = await updateScopingMeeting(
                {
                    entity_id: entityId,
                    framing_meeting_status: REALIZED_STATUS,
                    framing_meeting_date: dateService.formatDate(meetingDate, 'yyyy-MM-dd HH:mm:ss')
                }
            )
            if (getError()) throw getError()
            fetchMyScopingMeetings()
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        } finally {
            hideModal()
        }
    }

    useEffect(() => {
        setDisableConfirm(meetingDate === '')
    }, [meetingDate])

    const cancelAndHide = () => {
        cancel()
        hideModal()
    }

    return (
        <div className="u-pd-xl">
            <h2 className="c-h2 u-primary u-center u-mg-vt-l">La réunion de cadrage a été réalisée le</h2>

            <div className="u-flex u-flex-center-hz">
                <input
                    id="date" type="date" value={meetingDate} max={today}
                    onChange={(event) => setMeetingDate(event.target.value)}
                />
            </div>

            <div className="u-flex u-flex-dir-row u-mg-top-l u-flex-around">
                <button
                    type="button"
                    className="c-btn c-btn--white-bordered u-pd-hz-l u-mg-bottom-m u-mg-0@main"
                    onClick={cancelAndHide}
                >
                    Annuler
                </button>
                <button
                    type="button"
                    className={'c-btn c-btn--primary u-pd-hz-l u-mg-bottom-m u-mg-0@main'}
                    onClick={() => updateScopingMeetingStatus()}
                    disabled={disableConfirm}
                >
                    Confirmer
                </button>
            </div>
        </div>
    )
}

ScopingMeetingRealizedModal.propTypes = {
    entityId: string.isRequired,
    cancel: func.isRequired
}

export default ScopingMeetingRealizedModal