import React from "react"
import { isNotEmpty } from "utils/validation"

const InputTextarea = ({
    // Attributes
    id = null,
    value = "",
    name,
    label = "",
    placeholder = "",
    disabled,
    readonly = false,
    maxLength = 500,
    // Styles
    style,
    inputStyle,
    classNameInput = "",
    className = "",
    classNameLabel = "",
    // Error management
    required = false,
    customRules,
    showErrors,
    // Handler
    onChange,
    onBlur,
    onValidate,
    ...rest
}) => {
    const [errors, setErrors] = React.useState([])

    const rules = React.useMemo(() => [
        {
            key: "input-empty",
            check: (value) => required ? isNotEmpty(value) : true,
            message: "Ce champ est obligatoire"
        },
        ...(customRules || [])
    ], [required]) // eslint-disable-line
    // customRules in dependencies array trigger an infinite loop,
    // because custom rules include a check property defining a new function,
    // preventing useMemo to return a valid shallow-comparison
    // or event useDeepMemo to return a valid deep-comparison

    const validate = React.useCallback((currentValue) => {
        let newErrors = []
        rules.forEach(rule => {
            if (!rule.check(currentValue)) {
                newErrors.push({
                    origin: name,
                    rule: rule.key,
                    message: rule.message
                })
            }
        })
        setErrors(newErrors)
        onValidate(name, newErrors)
    }, [rules, name, onValidate])

    // validate on mount and each time value change
    React.useEffect(() => {
        validate(value)
        return () => {
            // On unmounted erros and validation are no longer needed
            setErrors([])
            if (onValidate) onValidate(name, [])
        }
    }, [value, validate, onValidate, name])

    return (
        <>
            <div className={"c-form-group " + className} style={style}>
                <label className={"c-label u-mg-bottom-xs " + classNameLabel} htmlFor={id}>
                    {label} {required && "*"}
                    <span className="u-fs-xs u-regular">({maxLength - value.length} {(maxLength - value.length > 1) ? "caractères restants" : "caractère restant"})</span>
                </label>


                <textarea
                    className={
                        "c-textarea "
                        + (showErrors && errors.length > 0 ? "has-error " : " ")
                        + (classNameInput ? classNameInput : "")
                    }
                    style={inputStyle}
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    value={value}
                    disabled={disabled}
                    readOnly={readonly}
                    required={required}
                    onChange={(e) => !readonly && onChange(e)}
                    onBlur={(event) => {
                        validate(value)
                        onBlur && onBlur(event)
                    }}
                    {...rest}
                />

                {/* Error messages */}
                {showErrors && errors && errors.map((error, index) => {
                    return <p key={index} className="u-fs-xs u-danger">{error.message}</p>
                })}
            </div>
        </> 
    )
}


export default InputTextarea
