import React from 'react'
import { useParams } from 'react-router'

import BackOfficeLayout from 'layouts/back-office'
import BeneficiaryDetails from 'components/platform/BeneficiaryDetails'

const BeneficiaryDetail = () => {

    const { beneficiaryId } = useParams()
    
    return (
        <BackOfficeLayout>
            <BeneficiaryDetails beneficiaryId={beneficiaryId} />
        </BackOfficeLayout>
    )
}

export default BeneficiaryDetail