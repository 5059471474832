import React from 'react'
import { bool, func, number, shape, string } from 'prop-types'
import { isNeedComplementDocumentsBid, isNotNeedComplementDocumentsBid, requestList } from 'utils/domain/request'
import FormElement from 'components/shared/FormElement'
import TrashIcon from 'components/svg/Trash'

const Emargement = ({
    position,
    name, 
    document, 
    handleChange, 
    handleValidation,
    handleRemove,
    disabledRemoveAction,
    showErrors, 
    requestType,
    requestStatus
}) => {
    const isAdviceMonitoring = requestType === requestList.adviceAndSupportFolder

    const getDocumentLabel = () => {
        if (isAdviceMonitoring) {
            return document.full_media_url ? 'Synthèse d\'intervention' : 'Charger la synthèse d’intervention'
        }
        return document.full_media_url ? `Feuille d'émargement ${position}` : `Charger la feuille d'émargement ${position}`
    }
    
    return (
        <div className="u-flex u-flex-dir-col u-pd-m">
            <div className="u-flex u-flex-dir-row">
                <FormElement
                    key={name}
                    value={document}
                    type="file"
                    name={name}
                    label={getDocumentLabel()}
                    maxFileSize={10}
                    acceptPDF
                    className={'u-mg-bottom-m'}
                    required={!document.full_media_url}
                    showErrors={showErrors}
                    onValidate={handleValidation}
                    onChange={handleChange}
                    disabled={document.full_media_url && isNotNeedComplementDocumentsBid(requestStatus)}
                    defaultValue={document.full_media_url ? document : null}
                    forcePlaceholder={document.full_media_url && 'Charger un nouveau fichier'}
                />
                { (!document.full_media_url || isNeedComplementDocumentsBid(requestStatus)) && 
                    <button type="button" className='u-mg-left-s' disabled={disabledRemoveAction} onClick={() => handleRemove(name)}>
                        <TrashIcon size={15} color={disabledRemoveAction ? 'grey' : 'red'} />
                    </button>
                }
                {isAdviceMonitoring && <p className='u-mg-left-l u-fs-xs u-mg-top-m u-danger'>Attention : seule la synthèse du rapport doit être importée</p>}
            </div>
            {!document.full_media_url && <p className="u-italic u-fs-xxs u-mg-bottom-m">
                PDF, JPEG de 10 Mo max.
            </p>}
        </div>
    )
}

Emargement.propTypes = {
    position: number,
    name: string,
    document: shape({ full_media_url: string }),
    handleChange: func,
    handleValidation: func,
    handleRemove: func,
    disabledRemoveAction: bool,
    showErrors: bool,
    requestType: string,
    requestStatus: number,
    forceDisplayTrash: bool
}

export default Emargement