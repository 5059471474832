import { arrayOf, bool, object } from 'prop-types'
import React from 'react'
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom'
import './styles.scss'

const Tabs = ({ tabsContent, hasNested = false, isNested = false }) => {
    const { url: currentUrl } = useRouteMatch()
    const { pathname } = useLocation()

    const isMatchCurrentUrl = (url) => url === currentUrl || url === pathname

    const getDynamicNavLinkClassName = (url) => {
        if(isMatchCurrentUrl(url)) return 'current-position u-primary'
        else if(isNested) return 'u-grey70'
        else return 'u-secondary'
    }

    return (
    <div className={`u-flex u-flex-dir-row monitoring-tabs ${hasNested ? 'has-nested' : ''}${isNested ? 'is-nested' : ''}`}>
        {tabsContent.map((tab, index) => (
            <div className={`u-flex u-flex-dir-col u-flex-center-vt ${isNested ? '' : 'grow-one'}`} key={index}>
                <NavLink
                    onClick={(e) => isMatchCurrentUrl(tab.url) && e.preventDefault()}
                    className={`${getDynamicNavLinkClassName(tab.url)} navlink u-bold u-fs-m`}
                    key={index} to={tab.url}>{tab.label}
                </NavLink>
                {!isMatchCurrentUrl(tab.url) && <div className="position" />}
                {isMatchCurrentUrl(tab.url) && <div className="dot"/>}
            </div>
        ))}
    </div>
)
}

Tabs.propTypes = {
    tabsContent: arrayOf(object),
    hasNested: bool,
    isNested: bool
}

export default Tabs