import React from 'react'
import { func, object } from 'prop-types'

import dateService from 'services/date'

import { useAlert } from 'hooks/useAlert'
import ImportDownloadCell from 'components/shared/Table/Cells/ImportDownloadCell'
import { WAITING_CERTIFICATION } from 'utils/domain/certification'
import { AUDIT_CGV_DOC_GENDER } from 'utils/domain/audit'

const ImportDownloadDocumentCell = (cell, updateMyAudit) => {

  const { addAlert } = useAlert()
  const audit = cell.row.original
  const auditId = audit?.audit_id
  const auditStatus = audit?.audit_status
  const auditDate = audit?.audit_date
  const entityId = audit?.entity_id
  const medias = audit?.audit_media // all audit documents (report, certification)
  const auditCertification = audit?.audit_label

  const updateData = async (documentToUpload) => {
    try {
      const getDocuments = () => {
        if (medias?.documents) {
          // fix api : multiple documents with the same gender are allowed, but it doesn't make sense. So here we removed item if gender already exists.
          const documentsCleaned = [...medias?.documents].filter(document => document.media_gender !== documentToUpload.media_gender)
          return [...documentsCleaned, documentToUpload]
        }
        else return [documentToUpload]
      }
      const isCgvDocument = documentToUpload.media_gender === AUDIT_CGV_DOC_GENDER
      // payload
      const auditToUpdate = {
        entity_id: entityId,
        audit_status: auditStatus,
        // if it's a cgv document, audit_date doesn't exists at this time.
        ...(!isCgvDocument ? { audit_date: dateService.formatDate(auditDate, 'yyyy-MM-dd HH:mm:ss') } : {}),
        audit_media: { documents: getDocuments() },
        audit_label: auditCertification ? auditCertification : WAITING_CERTIFICATION
      }

      await updateMyAudit(auditId, auditToUpdate)
      addAlert('success', 'Le document a été uploadé')
    } catch (error) {
      console.error(error)
      addAlert('error', error?.toString())
    }
  }

  return <ImportDownloadCell
    cell={cell}
    updateData={updateData}
  />
}

ImportDownloadDocumentCell.propTypes = {
  cell: object.isRequired,
  value: object,
  updateData: func.isRequired
}

export default ImportDownloadDocumentCell