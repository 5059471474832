import { NavLink } from 'react-router-dom'
import { shape, string, func, arrayOf } from 'prop-types'

import { publicRoutes } from 'router/routes'

import EyeSeeIcon from 'components/svg/EyeSee'

export const UserDetail = ({ userId, route, target = '' }) => {

  const detailRoutePath = userId => {
    if(!userId?.toString()) return publicRoutes.notFound
    return route.navLink({ userId })
  }

  return (
      <NavLink 
          className="u-green90" 
          aria-label="Afficher le détail"
          to={detailRoutePath(userId)}
          target={target}
      >
          <EyeSeeIcon size={20} className="icon" />
      </NavLink>
  )
}

UserDetail.propTypes = {
  userId: string,
  route: shape({
    path: string,
    navLink: func.isRequired,
    roles: arrayOf(string)
  }),
  target: string
}