import scopingMeetingStatusColumn from './status/scopingMeetingStatusColumn'
import importDownloadScopingMeetingColumn from './import-download-report/importDownloadScopingMeetingColumn'
import downloadAutodiagColumn from './downloadAutodiagColumn'
import dateService from 'services/date'

const myScopingMeetingsColumns = ({ setData, refreshData }) => {
    return [
        {
            Header: 'Date de la demande',
            accessor: 'create_date',
            sortType: (a, b) => {

                const frenchStrDateA = a.original.create_date
                const frenchStrDateB = b.original.create_date

                const englishStrDateA = dateService.convertFrenchStringDateToEnglishStringDate(frenchStrDateA)
                const englishStrDateB = dateService.convertFrenchStringDateToEnglishStringDate(frenchStrDateB)
                
                return new Date(englishStrDateB) - new Date(englishStrDateA)
            }
        },
        {
            Header: 'Raison sociale',
            accessor: 'structureName',
        },
        {
            Header: 'Nombre d\'employés',
            accessor: 'structureEmployeeCategory',
        },
        {
            Header: 'Téléphone',
            accessor: 'contactPhonenumber',
        },
        {
            Header: 'Email',
            accessor: 'contactEmail',
        },
        {
            Header: 'Code postal',
            accessor: 'postalCode',
        },
        scopingMeetingStatusColumn({ setData }),
        {
            Header: 'Date de la réunion',
            accessor: 'framing_meeting_date',
            sortType: (a, b) => {
                const dateA = a.original?.framing_meeting_date
                const dateB = b.original?.framing_meeting_date
                return new Date(dateB) - new Date(dateA)
            },
            Cell: ({ value }) => {
                return value ? <>{dateService.formatDate(value)}</> : <></>
            }
        },
        downloadAutodiagColumn,
        importDownloadScopingMeetingColumn({ refreshData })
    ]
}

export default myScopingMeetingsColumns