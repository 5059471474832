import React from "react"
import {
    View,
    StyleSheet,
    Image
} from "@react-pdf/renderer"
import Logo from "assets/images/logo-oepv.png"
import dateService from "services/date"
import styles from "./PDFStyles"
import DataViewPDF from "./PDFDataView"

// Header component
const PDFHeader = ({createDate}) => {
    const headerStyles = StyleSheet.create({
        header: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: 10,
            marginBottom: 30,
            borderBottomColor: "#005a7c",
            borderBottomStyle: "solid",
            borderBottomWidth: 2
        },
        leftColumn: {
            flexDirection: "row",
            alignItems: "center"
        },
        logo: {
            width: "100px"
        }
    })
    return (
        <View style={headerStyles.header}>
            {/* Logo */}
            <View style={headerStyles.leftColumn}>
                <Image src={Logo} style={headerStyles.logo} />
            </View>
            <View style={styles.rightColumn}>
                <DataViewPDF
                    label={"Date de création"}
                    value={dateService.formatDate(createDate, "dd/MM/y")}
                />
            </View>
        </View>
    )
}

export default PDFHeader