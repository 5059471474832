import { number, object, string } from 'prop-types'

import { FOLDER_PAID, REQUEST_REGULARISATION_PAID, isValidated } from 'utils/domain/request'
import { formatNumberAndAddEurCurrency } from 'utils/number'

import dateService from 'services/date'
import { isNotEmpty } from 'utils/validation'


const PaymentDateCell = ({ cell, value }) => {
    const folderIsNotPaid = cell?.row?.original?.status !== FOLDER_PAID
    const folderRegularisationIsPaid = Boolean(cell?.row?.original?.regularisation_status) && cell?.row?.original?.regularisation_status === REQUEST_REGULARISATION_PAID
    if (folderIsNotPaid && !folderRegularisationIsPaid) return <></>
    return (
    <div className="u-center">
        {dateService.formatDate(value)}
    </div>
)
}
PaymentDateCell.propTypes = {
    cell: object,
    value: string,
}

const BenefitCell = ({ value: benefit }) => (
    <div className="u-primary u-bold u-center">
        {benefit}
    </div>
)
BenefitCell.propTypes = {
    value: string
}

const FolderNumberCell = ({ value: folderNumber, cell }) => (
    <>{folderNumber}</>
)
FolderNumberCell.propTypes = {
    value: string,
    cell: object
}

const AmountCell = ({ value: amount }) => (
    <div className="u-center">
        {formatNumberAndAddEurCurrency(amount)}
    </div>
)
AmountCell.propTypes = {
    value: number
}

const BonusCell = ({ value: bonus, cell }) => {
    // Two types of bonus :
    // 1 : bonus = the initial bonus which should have been refunded if the bonus was necessary
    // 2 : realBonus = the bonus that the beneficiary will receive
    const bonusAmount = isNotEmpty(cell?.row?.original?.realBonus) ? cell.row.original.realBonus : bonus
    // Ici on compare les valeurs arrondies car il peut y avoir des erreurs de calculs (Exemple : 523 et 522.8000000000001)
    const isRealBonus = isNotEmpty(cell?.row?.original?.realBonus) && (Math.round(cell.row.original.realBonus) !== Math.round(bonus))

    return (
        <div className="u-center">
            {/* the real bonus is display if exists and if it's different from bonus */}
            { isRealBonus && 
                <>
                    <span className='u-text-decoration-line-through'>{formatNumberAndAddEurCurrency(bonus)}</span>
                    <span className='u-mg-left-m u-bold '>{formatNumberAndAddEurCurrency(bonusAmount)}</span>
                </>
            }
            { !isRealBonus && 
                <>
                    <span>{formatNumberAndAddEurCurrency(bonus)}</span>
                </>
            }
        </div>
    )
}

BonusCell.propTypes = {
    value: number,
    cell: object,
}

const StatusCell = ({ value: labelStatus, cell }) => (
    <div className={`${isValidated(cell.row.original.status) ? 'u-green90' : 'u-primary'} u-center`}>
        {labelStatus}
    </div>
)
StatusCell.propTypes = {
    value: string,
    cell: object
}

const CreatedDate = ({ value: date }) => (
    <div className="u-center">
        {dateService.formatDate(date)}
    </div>
)

CreatedDate.propTypes = {
    value: string
}

export const paymentColumns = [
    {
        Header: 'Prestation',
        accessor: 'genderLabel',
        enableRowSpan: true,
        className: 'c-table__cell--3 u-center',
        Cell: BenefitCell
    },
    {
        Header: 'N° de dossier',
        accessor: 'request_fub_id',
        className: 'c-table__cell--3 u-center',
        Cell: FolderNumberCell
    },
    {
        Header: () => <span className="u-break-spaces">Date de la demande</span>,
        accessor: 'create_date',
        className: 'c-table__cell--4 u-center',
        Cell: CreatedDate
    },
    {
        Header: () => <span className="u-break-spaces">Montant HT de la facture ou du devis</span>,
        accessor: 'amount',
        className: 'c-table__cell--3 u-center',
        Cell: AmountCell
    },
    {
        Header: () => <span className="u-break-spaces">Prime correspondante</span>,
        accessor: 'bonus',
        className: 'c-table__cell--3 u-center',
        Cell: ({ cell, value }) => {
            return BonusCell({ cell, value })
        }
    },
    {
        Header: 'Statut',
        accessor: 'statusLabel',
        className: 'c-table__cell--3 u-center',
        Cell: StatusCell
    },
    {
        Header: () => <span className="u-break-spaces">Date de versement</span>,
        accessor: 'modify_date',
        className: 'c-table__cell--3 u-center',
        Cell: ({ cell, value }) => {
            return PaymentDateCell({ cell, value })
        }
    },
]