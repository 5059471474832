import React from 'react'
import { Redirect, Route, useParams } from 'react-router-dom'

import BenefitsMonitoring from 'components/monitoring/activities/Benefits'
import ProtectedLayout from 'layouts/Protected'
import MonitoringRoutes from './MonitoringRoutes'
import { privateRoutes } from 'router/routes'
import Tabs from 'components/shared/Tabs'
import AutodiagnosticMonitoring from 'components/monitoring/activities/Autodiagnostic'
import ScopingMeetingMonitoring from 'components/monitoring/activities/ScopingMeeting'
import CommitmentCharterMonitoring from 'components/monitoring/activities/CommitmentCharter'
import AuditMonitoring from 'components/monitoring/activities/Audit'
import CatalogRoutes from 'components/catalog/CatalogRoutes'

const MonitoringActivities = () => {
    const { slug } = useParams()

    return (
        <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main monitoring-activities">
            <div className="l-container-xxl">
                <div className="u-flex u-flex-dir-col">
                    <h1 className="c-h1 u-secondary u-center u-uppercase u-mg-bottom-xl">Suivi de mes activités</h1>
                </div>

                <Tabs tabsContent={MonitoringRoutes} hasNested={slug === 'prestations'}></Tabs>
                
                <Route exact path={privateRoutes.monitoringActivities.autodiagnostic.path}>
                    <AutodiagnosticMonitoring/>
                </Route>
                <Route exact path={privateRoutes.monitoringActivities.scopingMeeting.path}>
                    <ScopingMeetingMonitoring/>
                </Route>
                <Route exact path={privateRoutes.monitoringActivities.commitmentCharter.path}>
                    <CommitmentCharterMonitoring/>
                </Route>
                <Route exact path={privateRoutes.monitoringActivities.benefits.path}>
                    <Redirect to={`${privateRoutes.monitoringActivities.benefits.path}/${CatalogRoutes[0].url}`}></Redirect>
                </Route>
                <Route exact path={`${privateRoutes.monitoringActivities.benefits.path}/:slug`}>
                    <BenefitsMonitoring/>
                </Route>
                <Route exact path={privateRoutes.monitoringActivities.audit.path}>
                    <AuditMonitoring/>
                </Route>
            </div>
        </ProtectedLayout>
    )
}

export default MonitoringActivities
