import { sha3_256 } from 'js-sha3'
import { Promise } from 'bluebird'
import multihash from 'multihashes'
import strictjson from 'json-stable-stringify'

export const fileHash = (filename) => {
    return new Promise((resolve, reject) => {
        let hash = sha3_256.create()
        var reader = new FileReader()
        reader.addEventListener('loadend', () => {
            // console.log("reader.result",reader.result)
            hash.update(reader.result)
            let buf = Buffer.from(hash.hex(), 'hex')
            resolve(multihash.toB58String(multihash.encode(buf, 'sha3-256')))
            return
        })
        reader.readAsArrayBuffer(filename)
        return
    })
}

export const jsonHash = (json_data) => {
    json_data = strictjson(json_data, (a, b) => (a.key > b.key ? 1 : -1))
    return sha3_256.create().update(json_data).hex()
}