import React from 'react'
import { bool, string, object, func } from 'prop-types'

import dateService from 'services/date'
import * as CatalogApi from 'services/api/CatalogApi'
import * as AuditApi from 'services/api/audit/auditApi'

import { useAlert } from 'hooks/useAlert'
import Card from 'components/platform/Card'
import Information from 'components/platform/Informations/Information'
import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'
import FormElement from 'components/shared/FormElement'
import { auditProvider } from 'utils/domain/provider'
import { isNotEmpty } from 'utils/validation'
import { RESERVED_STATUS, ORDERED_STATUS } from 'utils/domain/audit'


const defaultFormFields = {
    provider: null
}

const Audit = ({
    audit,
    className,
    isBOLeadSupervisor,
    loadBeneficiaryDetails
}) => {
    const [providerList, setProviderList] = React.useState([])
    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [errors, setErrors] = React.useState([])
    const { addAlert } = useAlert()

    const hasAnAudit = audit?.hasAnAudit
    const auditorStructureName = audit?.provider_data?.structureName
    const createDate = audit?.create_date
    const auditDate = audit?.audit_date
    const urlReport = audit?.audit_media?.documents.find(document => document.media_gender === 'RAPPORT_AUDIT')?.full_media_url

    const isAuditLocked = audit?.audit_status === RESERVED_STATUS
    const isAuditPlanned = isNotEmpty(audit?.planned_date)

    const fetchEntities = async () => {
        try {   
            const activeEntities = true // param used to search only active providers
            const { res: entities, getError } = await CatalogApi.getEntities({ services: auditProvider.serviceId, activeEntities })
            if (getError()) throw getError()
            
            if(isNotEmpty(entities)) 
                setProviderList(() => 
                    entities.map((item) => {
                        return {
                            value: item.entity_id,
                            label: item.entity_data.structureName,
                        }
                    }),
                )
        } catch (error) {
            addAlert('error', 'Une erreur est survenue lors de la récupération des prestataires')
        }
    }

    React.useEffect(() => {
        // Si le user est un superviseur du BO gestion, on récupère la liste des prestataires
        if(isBOLeadSupervisor && hasAnAudit) fetchEntities()
    }, [isBOLeadSupervisor]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if(isNotEmpty(providerList)) {
            setFormFields(s => ({
                ...s,
                provider: providerList?.find((item) => item.value === audit?.service_provider_entity_id)
            }))
        }
    }, [providerList]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleInputChange = (event) => {
        const target = event.target
        const name = target.name
        setFormFields(s => ({
            ...s,
            [name]: target.value
        }))
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const cancelAudit = async () => {
        try {   
            const editedScopingMeeting = {
                entity_id: audit?.entity_id,
            }
            const { getError } = await CatalogApi.cancelBackOfficeAuditEntity(editedScopingMeeting)
            if (getError()) throw getError()
            addAlert('success', 'L\'audit a bien été annulé.')
            loadBeneficiaryDetails()
        } catch (error) {
            addAlert('error', 'Une erreur est survenue lors l\'annulation de l\'audit.')
        }
     }

     const changeAuditStatus = async (status) => {
        try {
            const payload = {
                audit_status: status
            }
            const { res, getError } = await AuditApi.updateAuditStatus(audit?.audit_id, payload)
            if (getError()) throw getError()
            if (res) addAlert('success', 'Le changement de statut a bien été pris en compte !')
            loadBeneficiaryDetails()
        } catch (error) {
            addAlert('error', 'Vous ne pouvez pas effectuer cette action')
        }
    }

    const handleSubmit = async () => {
        try {   
            const updatedScopingMeeting = {
                entity_id: audit?.entity_id,
                provider_id: formFields.provider,
            }
            const { getError } = await CatalogApi.editBackOfficeAuditEntity(updatedScopingMeeting)
            if (getError()) throw getError()
            addAlert('success', 'L\'intervenant de l\'audit a bien été remplacé.')
            loadBeneficiaryDetails()
        } catch (error) {
            addAlert('error', 'Une erreur est survenue lors du changement de l\'intervenant.')
        }
    }

    const cardTitle = `Audit ${isAuditLocked ? '(bloqué)' : ''}`

    return (
        <>
            <Card title={cardTitle} className={`${className}`} >
                {hasAnAudit && (
                    <div className="u-flex u-flex-dir-row">
                        {((isBOLeadSupervisor && isNotEmpty(auditDate)) || (!isBOLeadSupervisor && audit?.service_provider_entity_id)) && 
                            <Information 
                                label="Prestataire:" 
                                value={auditorStructureName} 
                            />
                        }

                        {isBOLeadSupervisor && isNotEmpty(audit?.service_provider_entity_id) && !isNotEmpty(auditDate) &&  (
                            <div className="u-flex u-flex-dir-col u-pd-right-l">
                                {/* List of advisors */}
                                <FormElement
                                    value={formFields.provider}
                                    options={providerList}
                                    name="provider"
                                    type="select"
                                    label="Changer le prestataire"
                                    className="u-pd-vt-m"
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    isOptionDisabled={(option) => option.disabled}
                                    disabled={isAuditLocked}
                                />
                                <div className="u-flex u-flex-between">
                                    <button
                                        type="button"
                                        className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m  u-pd-s"
                                        onClick={handleSubmit}
                                        disabled={!isNotEmpty(formFields.provider) || formFields.provider === audit?.service_provider_entity_id || isAuditLocked}
                                    >
                                        Valider
                                    </button>
                                    <button
                                        type="button"
                                        className="c-btn c-btn--s c-btn--danger c-btn--back-office u-pd-hz-m u-mg-left-m u-pd-s"
                                        onClick={() => setFormFields(s => ({
                                            ...s,
                                            provider: providerList?.find((item) => item.value === audit?.service_provider_entity_id)
                                        }))}
                                        disabled={formFields.provider === audit?.service_provider_entity_id || isAuditLocked}
                                    >
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className="l-grid u-flex-dir-col u-flex-self-center">
                            <div className="u-mg-bottom-s">
                                <Information 
                                    label="Audit demandé le :" 
                                    value={dateService.formatDate(createDate)}
                                />
                            </div>
                            {isAuditPlanned && (
                                <>
                                    <div className="u-mg-bottom-s">
                                        <Information 
                                            label="Audit planifié au :" 
                                            value={dateService.formatDate(audit.planned_date)}
                                        />
                                    </div>
                                   {isBOLeadSupervisor && !auditDate && <p className='u-fs-xs u-danger'><strong>Attention :</strong> Pas d’annulation 15 jours avant la date de planification</p>}
                                </>

                            )}
                            
                            {auditDate && (
                                <Information 
                                    label="Audit réalisé le :" 
                                    value={dateService.formatDate(auditDate)}
                                />
                            )}
                            {!createDate && (
                                <div className="u-grey90 u-fs-xs u-mg-top-s">
                                    L'audit n'a pas été réalisé.
                                </div>
                            )}
                        </div>

                        {isBOLeadSupervisor && isNotEmpty(audit?.service_provider_entity_id) && !isNotEmpty(auditDate) && (
                            <div className='u-flex u-flex-dir-col u-pd-left-l'>
                                {isAuditPlanned &&
                                    <>
                                        {!isAuditLocked && 
                                            <button
                                                type="button"
                                                className="c-btn c-btn--danger u-fs-xs c-btn--s u-pd-xs u-mg-bottom-m"
                                                style={{ width: '100%', maxWidth: '460px' }}
                                                onClick={() => changeAuditStatus(RESERVED_STATUS)}
                                            >
                                                Bloqué
                                            </button>
                                        }
                                        {isAuditLocked && 
                                            <button
                                                type="button"
                                                className="c-btn c-btn--danger u-fs-xs c-btn--s u-pd-xs u-mg-bottom-m"
                                                style={{ width: '100%', maxWidth: '460px' }}
                                                onClick={() => changeAuditStatus(ORDERED_STATUS)}
                                            >
                                                Commandé
                                            </button>
                                        }
                                    </>
                                }

                                <button
                                    type="button"
                                    className="c-btn c-btn--primary u-fs-xs c-btn--s u-pd-xs"
                                    style={{ width: '100%', maxWidth: '460px' }}
                                    onClick={cancelAudit}
                                    disabled={isAuditLocked}
                                >
                                    Annuler la demande d'audit
                                </button>
                            </div>
                        )}
                        {urlReport && ( 
                            <InformationToVisualize
                                className="l-grid u-flex-self-center"
                                label="Consulter le rapport d'audit" 
                                url={urlReport} 
                                iconColor="green"
                                asCol
                            />
                        )}
                    </div>
                )}
                {!hasAnAudit && (
                    <div className="u-grey90 u-fs-xs u-mg-top-s">
                        La demande d'audit n'a pas été faite.
                    </div>
                )}
            </Card> 
        </>
    )
}

Audit.propTypes = {
    audit: object,
    className: string,
    isBOLeadSupervisor: bool,
    loadBeneficiaryDetails: func,
}

export default Audit
