import React, { useState, useEffect, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { string, shape } from 'prop-types'

import { useAlert } from 'hooks/useAlert'
import * as CatalogApi from 'services/api/CatalogApi'
import BackOfficeLayout from 'layouts/back-office'
import { beneficiaryList, getBeneficiaryByValue } from 'utils/domain/beneficiary'
import { privateRoutes } from 'router/routes'
import dateService from 'services/date';

import Card from 'components/platform/Card'
import Table from 'components/shared/Table'
import FolderIcon from 'components/svg/Folder'
import { SelectColumnFilter, equalSomeFn } from 'components/shared/Table/SelectColumnFilter';
import { DateColumnFilter, dateBetweenFilterFn } from 'components/shared/Table/DateColumnFilter';

const GoToDetailCell = ({ cell }) => {
    return (
        <NavLink to={privateRoutes.backOffice.beneficiaryDetails.navLink({ beneficiaryId: cell?.value })} >
            <FolderIcon size={20} className="icon" />
        </NavLink>
    )
}

GoToDetailCell.propTypes = {
    cell: shape({
        value: string
    })
}

const BackOfficeBeneficiaries = () => {
    const [ beneficiaries, setBeneficiaries ] = useState([])

    const { addAlert } = useAlert()

    useEffect(() => {
        try {
            loadBeneficiaries()
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }, [])

    const loadBeneficiaries = async () => {
        const entities = await Promise.all(beneficiaryList.map(async ({ value: genderValue }) => {
            const { res: readEntities, getError: getEntitiesError } = await CatalogApi.getEntities({ gender: genderValue })
            if(getEntitiesError()) throw getEntitiesError()

            return readEntities
        }))

        setBeneficiaries(entities.flat().sort((a,b) => new Date(b.create_date) - new Date(a.create_date)).map(entity => ({ 
                            ...entity.entity_data, 
                            entity_id: entity.entity_id,
                            gender: getBeneficiaryByValue(entity.entity_data.gender)?.label,
                            create_date: dateService.formatDate(entity.create_date)
                        }))
        )
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Raison sociale',
                accessor: 'structureName'
            },
            {
                //Header: 'Nature de l\'établissement',
                accessor: 'gender',
                Filter: SelectColumnFilter,
                filter: equalSomeFn,
                meta: {
                    label: "Nature de l\'établissement",
                }
            },
            {
                Header: 'Siret',
                accessor: 'siret'
            },
            {
                //Header: 'Date de création',
                accessor: 'create_date',
                Filter: DateColumnFilter,
                filter: dateBetweenFilterFn,
                meta: {
                    label: 'Date de création',
                },
                sortType: (a, b) => {
                    const frenchStrDateA = a.original.creationDate;
                    const frenchStrDateB = b.original.creationDate;

                    const englishStrDateA =
                    dateService.convertFrenchStringDateToEnglishStringDate(
                        frenchStrDateA
                    );
                    const englishStrDateB =
                    dateService.convertFrenchStringDateToEnglishStringDate(
                        frenchStrDateB
                    );

                    return new Date(englishStrDateB) - new Date(englishStrDateA);
                },
            },
            {
                Header: 'Ville',
                accessor: 'structureCity'
            },
            {
                Header: 'Email',
                accessor: 'contactEmail'
            },
            {
                Header: 'Téléphone',
                accessor: 'structurePhonenumber'
            },
            {
                Header: 'Consulter la fiche',
                accessor: 'entity_id',
                Cell: GoToDetailCell
            },
        ]
    , [beneficiaries]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BackOfficeLayout>
            <div className="u-flex u-flex-dir-col">
                <h1 className="c-h1 u-bold u-primary u-mg-bottom-l">Rechercher un bénéficiaire</h1>
                <Card className="u-pd-top-xl u-mg-bottom-l">
                    <Table columns={columns} data={beneficiaries} specificSortBy={[ { id: 'create_date', asc: true } ]} hasFilters={true} isSortable={false} />
                </Card>
            </div>
        </BackOfficeLayout>
    )
}

export default BackOfficeBeneficiaries