import React, { useEffect, useState, useCallback } from 'react'
import { func, number, shape, string } from 'prop-types'
import * as Ibantools from 'ibantools'

import uploadFile from 'services/uploadFile'
import { useAlert } from 'hooks/useAlert'
import { isFileNotEmpty, isIBANValid, isBICValid, isNotEmpty } from 'utils/validation'
import FormElement from 'components/shared/FormElement'
import { getKeywords } from 'components/auth/FormSignup'
import { getMediaUrlPrefix } from 'utils/domain/user'
import dateService from 'services/date'
import * as CatalogApi from 'services/api/CatalogApi'
import * as UserApi from 'services/api/UserApi'
import { 
    isBetweenStatus, 
    PICTURES_VALIDATED,
    BACK_OFFICE_OPERATIONS,
    isNeedComplementAfterSignOffSheetsUploaded,
    REQUEST_VALIDATED,
    FOLDER_PAID
} from 'utils/domain/request'
import EyeSee from 'components/svg/EyeSee'

const OPERATION_CODE = BACK_OFFICE_OPERATIONS.BID.UPLOAD_BY_BENEFICIARY

const FormDocumentBid = ({ 
    requestPosition, 
    request, 
    user,
    labelSubmitDisabled = '',
    successMessage,
    isUploadDisableFn,
    refreshData
}) => {
    const isSendBillEnabled = isBetweenStatus({
        status: request?.status,
        start: PICTURES_VALIDATED,
        end: REQUEST_VALIDATED
    })

    const defaultFormEvents = {
        ready: isSendBillEnabled,
    }

    useEffect(() => {
        setFormEvents({
            ready: isSendBillEnabled
        })
    }, [isSendBillEnabled])

    const { addAlert } = useAlert()
    const [isFormModified, setIsFormModified] = useState(false)

    const [formFields, setFormFields] = useState({
        bank_account_holder: user?.entity_data.bank_account_holder || '',
        bic: user?.entity_data.bic, 
        iban: Ibantools.friendlyFormatIBAN(user?.entity_data.iban || '')
    })
    const [formEvents, setFormEvents] = useState(defaultFormEvents)
    const [showLocalErrors, setShowLocalErrors] = useState(false)
    const [errors, setErrors] = useState([])

    const billFormField = formFields[`bill-${requestPosition}`]

    const paymentProofFormField = formFields[`payment_proof-${requestPosition}`]
    const isAlreadySent = (user?.entity_data.bic || user?.entity_data.iban) && Boolean(billFormField?.full_media_url && paymentProofFormField?.full_media_url)
   
    const submitLabelFormNotSent = isSendBillEnabled ? 'Envoyer' : labelSubmitDisabled


    const handleValidation = useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    useEffect(() => {
        getPayRequest()
    }, [])

    const getPayRequest = async () => {
        const { res: history } = await CatalogApi.getRequestHistory(request.request_id)
        if(history.length > 0) {
            // On remonte les events de type PAYREQUEST. Le statut 72 peut avoir ou non des documents. On filtre donc sur l'event où ils sont présents
            const payRequestOperations = history.filter(stack => {

                return stack.request_data.operation_code === OPERATION_CODE && isNotEmpty(stack.request_data.operation_data?.documents)
            })
            // Set last PAYREQUEST operation as documents state

            if(payRequestOperations.length > 0) {
                const lastPayRequestOperation = payRequestOperations[0]
    
                lastPayRequestOperation.request_data.operation_data?.documents?.forEach((document) => {
                    setFormFields((formFields) => ({
                        ...formFields,
                        [`${document.media_gender}-${requestPosition}`]: document
                    }))           
                })
                return
            }
        }
    }
    
    const formatDocumentsForSave = ({
        row_hash,
        uploadResponse
    }) => {
        const editedDocuments = uploadResponse.map(({ name, fileToUpload, fileAlreadyUploaded = {} }) => {
            const media_id = fileAlreadyUploaded.media_id || fileToUpload.id
            const media_url = fileAlreadyUploaded.media_url || `${getMediaUrlPrefix()}${media_id}.${fileToUpload.extension}`
            const media_hash = fileAlreadyUploaded.media_hash || fileToUpload.value

            return {
                media_gender: name,
                media_id,
                media_url,
                media_hash,
            }
        })

        return {
            request_id: request.request_id,
            request_data: {
                operation_code: OPERATION_CODE,
                operation_data: {
                    documents: editedDocuments,
                    operation_date: dateService.getCurrentDate('yyyy-MM-dd HH:mm:ss'),
                    comment: request?.request_data?.operation_data?.comment
                },
            },
            request_search_data: null,
            last_row_hash: row_hash
        }
    }

    const uploadDocuments = async () => {
        const uploadPromises = [
            isFileNotEmpty(billFormField) && uploadFile('bill', billFormField),
            isFileNotEmpty(paymentProofFormField) && uploadFile('payment_proof', paymentProofFormField)
        ].filter(promise => promise)

        const uploadResponse = await Promise.all(uploadPromises)
        
        const { res: currentRequest, getError: getErrorGetRequest } = await CatalogApi.getRequest(request.request_fub_id)
        if (getErrorGetRequest()) throw getErrorGetRequest()

        const { getError } = await CatalogApi.editRequest(formatDocumentsForSave({
            row_hash: currentRequest.row_hash,
            uploadResponse
        }))
        if (getError()) throw getError()
    }

    const updateUser = async () => {
        const { iban, bic, bank_account_holder } = formFields
        const { getError } = await UserApi.updateUser({
            entity_id: user?.entity_id,
            entity_data: {
                ...user?.entity_data,
                iban,
                bic,
                bank_account_holder
            },
            keywords: getKeywords({
                ...user?.entity_data,
            }),
            last_row_hash: user.row_hash,
            status: user?.status,
        })

        if (getError()) throw getError()
    }

    const handleInputChange = (event) => {
        const target = event.target
        const name = target.name
        setIsFormModified(true)
        setFormFields(s => ({
            ...s,
            [name]: target.value
        }))
    }

    const handleFileInputChange = (event) => {
        const target = event.target
        const name = target.name
        setShowLocalErrors(false)
        setIsFormModified(true)
        setFormFields((formFields) => ({
            ...formFields,
            [name]: target
        }))
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        // Validate inputs
        const formReady = !(errors.length > 0)

        if (!formReady) {
            setShowLocalErrors(true)
        } else {
            try {
                setFormEvents({ ready: false })
                if(formFields.iban !== user.entity_data.iban || formFields.bic !== user.entity_data.bic || formFields.bank_account_holder !== user.entity_data.bank_account_holder) {
                    await updateUser()
                }
                
                const needToUploadDocument = isNeedComplementAfterSignOffSheetsUploaded(request?.status)
                                             || billFormField?.id || paymentProofFormField?.id
                if(needToUploadDocument) {
                    await uploadDocuments()
                }

                await getPayRequest()
                refreshData && refreshData()
                addAlert('success', successMessage)
            } catch(error) {
                console.error(error)
                addAlert(
                    'error', 
                    error?.toString() || "Une erreur s'est produite lors de l'envoi des factures et/ou preuve de paiement"
                )
            } finally {
                setFormEvents({ ready: true })
                setIsFormModified(false)
            }
        }
    }

    const isSubmitDisabled = () => {
        if(isNeedComplementAfterSignOffSheetsUploaded(request?.status)) return false

        return !formEvents.ready || (isAlreadySent && !isFormModified)
    }

    const getSubmitLabel = () => {
        if(isNeedComplementAfterSignOffSheetsUploaded(request?.status)) {
            const isDocumentBillUploaded = billFormField?.id || paymentProofFormField?.id
            return isDocumentBillUploaded ? 'Mettre à jour mes pièces justificatives' : 'Confirmer mes pièces justificatives'
        }
        
        return isAlreadySent ? 'Mettre à jour' : submitLabelFormNotSent
    }

    return (
        <div className="u-pd-vt-l c-accordion__forms">
            <div className="u-pd-top-s u-pd-bottom-l u-semibold u-fs-m ">
                Renseigner votre RIB, envoyer la facture et la preuve de paiement
            </div>
            <form>
                <div className="l-grid u-mg-negative-hz-m">
                    <FormElement
                        value={formFields.bank_account_holder}
                        name="bank_account_holder"
                        type="text"
                        label="Titulaire"
                        className="u-mg-bottom-m l-col-12 u-pd-m"
                        required
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="l-grid u-mg-negative-hz-m">
                    <FormElement
                        value={formFields.iban}
                        name="iban"
                        type="text"
                        label="IBAN"
                        className="u-mg-bottom-m l-col-6 u-pd-m"
                        required
                        customRules={[
                            {
                                key: 'account-number-invalid',
                                check: (value) => isIBANValid(value),
                                message: "Le numéro IBAN n'est pas valide."
                            }
                        ]}
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                        onBlur={(event) => {
                            const result = { ...event }
                            if (result.target) result.target.value = Ibantools.friendlyFormatIBAN(event.target.value)
                            handleInputChange(result)
                        }}
                    />
                    <FormElement
                        value={formFields.bic}
                        name="bic"
                        type="text"
                        label="BIC"
                        className="u-mg-bottom-m l-col-6 u-pd-m"
                        required
                        customRules={[
                            {
                                key: 'account-number-invalid',
                                check: (value) => isBICValid(value),
                                message: "Le numéro BIC n'est pas valide."
                            }
                        ]}
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="l-grid u-mg-negative-hz-m">
                    <div className="l-col-12 u-pd-m">
                        <FormElement
                            value={billFormField}
                            disabled={billFormField?.full_media_url && isUploadDisableFn(request?.status)}
                            defaultValue={billFormField?.full_media_url ? billFormField : null}
                            type="file"
                            name={`bill-${requestPosition}`}
                            label='Charger la facture'
                            maxFileSize={10}
                            acceptPDF
                            className="u-mg-bottom-m"
                            widthClassName=""
                            required
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleFileInputChange}
                        />
                        {!billFormField?.full_media_url && 
                            <p className="u-italic u-fs-xxs u-mg-bottom-m">PDF, JPEG de 10 Mo max.</p>
                        }
                    </div>
                </div>
                <div className="l-grid u-mg-negative-hz-m">
                    <div className="l-col-12 l-col-6@main u-pd-m">
                        <FormElement
                            value={paymentProofFormField}
                            disabled={paymentProofFormField?.full_media_url && isUploadDisableFn(request?.status)}
                            defaultValue={paymentProofFormField?.full_media_url ? paymentProofFormField : null}
                            type="file"
                            name={`payment_proof-${requestPosition}`}
                            label='Charger votre preuve de paiement'
                            maxFileSize={10}
                            acceptPDF
                            className="u-mg-bottom-m"
                            widthClassName=""
                            required
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleFileInputChange}
                        />
                        <p className='u-mg-hz-s'><a className='u-flex u-flex-center-vt u-danger u-fs-xs' href='https://employeurprovelo.fr/wp-content/uploads/2023/09/OEPV_preuvepaiement_20230914.pdf' target='_blank'><EyeSee className='u-mg-right-s' color={'#c53762'} size={20} /> Visualiser une preuve de paiement conforme </a></p>
                    </div>
                    <div className="l-col-12 l-col-6@main u-pd-m">
                        <div className='c-info-card '>
                            <p>Est accepté : </p>
                            <ul>
                                <li>Une <strong>facture acquittée </strong>de la part du prestataire comportant la date de règlement, la mention « payée », la signature et le cachet du prestataire.</li>
                            <ul>
                            <p>Ou</p>
                            </ul>    
                                <li>Un <strong>mandat de prélèvement SEPA</strong>. Les captures d’écran de compte bancaire ne sont pas acceptées. </li>
                            </ul> 
                        </div>
                    </div>
                    {!paymentProofFormField?.full_media_url && 
                        <div className='u-mg-hz-m'>
                            <p className="u-italic u-fs-xxs u-mg-bottom-s">
                                Preuve de virement, preuve de prélèvement du chèque, ticket de CB.
                            </p>
                            <p className="u-italic u-fs-xxs u-mg-bottom-m">PDF, JPEG de 10 Mo max.</p>
                        </div>
                    }
                </div>
                {request?.status !== FOLDER_PAID &&
                    <div className="u-flex u-flex-center-hz u-mg-top-l">
                        <button
                            type="button"
                            className="c-btn c-btn--secondary c-btn--center u-pd-hz-l"
                            onClick={handleSubmit}
                            disabled={isSubmitDisabled()}
                        >
                            {getSubmitLabel()}
                        </button>
                    </div>
                }
            </form>
        </div>
    )
}

FormDocumentBid.propTypes = {
    requestPosition: number,
    user: shape({
        row_hash: string,
        entity_data: shape({
            bic: string,
            iban: string
        })
    }),
    request: shape({
        request_id: string,
        status: number,
        row_hash: string,
    }),
    labelSubmitDisabled: string,
    successMessage: string,
    isUploadDisableFn: func,
    refreshData: func
}

export default FormDocumentBid
