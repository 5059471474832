import ProtectedLayout from 'layouts/Protected'
import React from 'react'

import ScopingMeetingPlanning from './planning'

const ScopingMeeting = () => {

    return (
        <ProtectedLayout>
            <ScopingMeetingPlanning/>
        </ProtectedLayout>
    )
}

export default ScopingMeeting