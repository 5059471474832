import React from 'react'
import flow from 'lodash.flow'
import { string } from 'prop-types'

import allAPE from 'assets/data/nomenclature-dactivites-francaise-naf-rev-2-code-ape.json'

import { useAuth } from 'hooks/useAuth'
import { useAlert } from 'hooks/useAlert'
import useBonus from 'hooks/useBonus'

import * as UserApi from 'services/api/UserApi'
import * as UtilsApi from 'services/api/UtilsApi'
import dateService from 'services/date'
import uploadFile from 'services/uploadFile'

import { apeCompanyList, apeAssociationList } from 'utils/constants'
import { isValidFrenchPhoneNumber, isSiretValid, isNotEmpty } from 'utils/validation'
import { employeeCategory } from 'utils/domain/beneficiary'
import { objectsHaveDiverged } from 'utils/functions'
import { scrollToTop } from 'utils/scrollTo'
import { getMediaUrlPrefix } from 'utils/domain/user'
import { ASSOCIATION_VALUE, PRIVATE_SECTOR_VALUE, PUBLIC_SECTOR_VALUE, PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE } from 'utils/domain/beneficiary'

import ProtectedLayout from 'layouts/Protected'

import Emoji from 'components/shared/Emoji'
import FormElement from 'components/shared/FormElement'
import { getKeywords } from 'components/auth/FormSignup'

const defaultFormEvents = {
    ready: true,
    editable: false
}

const AccountPage = ({ className }) => {
    const { user, refreshUser } = useAuth()
    const { calculateBonus } = useBonus()

    const [formFields, setFormFields] = React.useState({})

    React.useEffect(() => {
        if(isNotEmpty(formFields?.structureEmployeeQuantity)) {
            const correspondingCategory = getCorrespondingEmployeeCategory(formFields?.structureEmployeeQuantity)
            setFormFields((s) => ({
                ...s,
                structureEmployeeCategory: correspondingCategory
            }))
        } 

    }, [formFields?.structureEmployeeQuantity])

    const isUserAlreadyUpdateAnAdditionalDocument = Boolean(user?.entity_data?.documents?.find(el => el.media_gender === 'PJ')) ?? false

    const getFormFieldsFromUserData = (userData) => {
        return {
            structureName: userData?.structureName || '',
            structureSiret: userData?.structureSiret || '',
            structureRna: userData?.structureRna || 'W',
            structureEmployeeQuantity: userData?.structureEmployeeQuantity || '',
            structureEmployeeCategory: userData?.structureEmployeeCategory || null,
            structureAddress: userData?.structureAddress || '',
            structurePostCode: userData?.structurePostCode || '',
            structureCity: userData?.structureCity || '',
            structurePhonenumber: userData?.structurePhonenumber || '',
            contactLastname: userData?.contactLastname || '',
            contactFirstname: userData?.contactFirstname || '',
            contactRole: userData?.contactRole || '',
            connexionEmail: userData?.mail || '',
            contactEmail: userData?.contactEmail || '',
            contactPhonenumber: userData?.contactPhonenumber || '',
            // Kbis
            document: userData?.documents?.find(el => el.media_gender === 'KBIS') || '',
            additionalDocument: userData?.documents?.find(el => el.media_gender === 'PJ') || '',
            documentUpdated: '',
            additionalDocumentUpdated: '',
            // Specific asso
            // assoDate: userData?.assoDate || "",
            // assoFolio: userData?.assoFolio || "",
            // assoVol: userData?.assoVol || "",
            // Others
            documents: userData.documents || ''
            // latitude: userData?.latitude || "",
            // longitude: userData?.longitude || "",
        }
    }

    // Role management
    const disambiguateRole = React.useCallback(
        (role) => {
            switch (parseInt(role)) {
                case PRIVATE_SECTOR_VALUE:
                    return 'company'
                case PUBLIC_SECTOR_VALUE:
                    return 'public'
                case ASSOCIATION_VALUE:
                    return 'asso'
                case PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE:
                    return 'company-public-vocation' 
                default:
                    break
            }
        },
        []
    )
    const currentRole = disambiguateRole(user?.entity_data?.gender)

    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [showGlobalError, setShowGlobalError] = React.useState(false)
    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)

    const { addAlert } = useAlert()

    const isKbisOrSiretValidated = !!user.entity_check?.is_check

    // We change structureCodeApe in the view account
    React.useEffect(() => {
        const getStructureCodeApe = () => {
            const initialStructureCodeApe = user?.entity_data.structureCodeApe
            if (initialStructureCodeApe) {
                const labelAPE = allAPE.find(item => item?.fields?.code_naf === initialStructureCodeApe)
                if (labelAPE) {
                    return {
                        structureCodeApe: {
                            label: labelAPE.fields.code_naf + ' - ' + labelAPE.fields.intitule_naf_40,
                            value: labelAPE.fields.code_naf
                        }
                    }
                }
            }
            return {}
        }

        setFormFields(formField => ({
            ...formField,
            ...getFormFieldsFromUserData(user?.entity_data),
            ...getStructureCodeApe()
        }))
    }, [user])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
            ? target.checked
            : target.type === 'file'
                ? target
                : target.value
        const name = target.name

        setFormFields({
            ...formFields,
            [name]: value
        })
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const getUserToEdit = () => ({
        entity_id: user?.entity_id,
        entity_data: {
            ...flow([
                Object.entries,
                // only keep state properties starting with (structure|asso|contact)
                arr => arr.filter(([key, value]) => key.match(new RegExp(/^(structure|asso|contact).*$/))),
                Object.fromEntries
            ])(formFields),
            siret: formFields?.structureSiret,
            contactEmail: formFields?.contactEmail,
            gender: parseInt(user?.entity_data?.gender), // immutable
            // default coordinates
            latitude: user?.entity_data?.latitude,
            longitude: user?.entity_data?.longitude,
            // default documents
            documents: user?.entity_data?.documents,
            // default prime
            prime: user?.entity_data?.prime
        },
        status: user?.status,
        keywords: getKeywords(formFields),
        last_row_hash: user?.row_hash
    })

    const handleDocument = async (type, fieldName) => {
        let document = {}
        if (isNotEmpty(fieldName)) {
            const { name: gender } = await uploadFile(type, {
                id: fieldName?.id,
                extension: fieldName?.extension,
                blob: fieldName?.blob
            })

            document = {
                media_gender: gender,
                media_id: fieldName?.id,
                media_url: `${getMediaUrlPrefix()}${fieldName?.id}.${fieldName?.extension}`,
                media_hash: fieldName?.value
            }
        }
        return document
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        // Validate inputs
        const formReady = !(errors.length > 0)
        if (!formReady) {
            setShowLocalErrors(true)
            setShowGlobalError(true)
            addAlert('error', 'Erreur(s) dans le formulaire.')
            scrollToTop()
            return
        }

        try {
            // hide error
            setShowGlobalError(false)
            setShowLocalErrors(false)
            // prevent form to be submitted again
            setFormEvents({ ...formEvents, ready: false })

            // Manage API
            // ---------------
            // prepare data
            const editedUser = getUserToEdit()

            // Recalculate coordinates if address has changed
            if (objectsHaveDiverged(user.entity_data, formFields, ['structureAddress', 'structurePostCode'])) {
                console.info('Coordinates have changed')
                const coordinates = await UtilsApi.getCoordinatesFromAdress(formFields?.structureAddress, formFields?.structurePostCode)

                if (isNotEmpty(coordinates)) {
                    editedUser.entity_data['latitude'] = coordinates[1]
                    editedUser.entity_data['longitude'] = coordinates[0]
                } else {
                    throw new Error('Adresse non reconnue, code postal inexistant')
                }
            }

            // Recalculate document (KBIS) url ?
            // -------------
            if (isNotEmpty(formFields?.documentUpdated)) {
                const kbisDocument = await handleDocument('KBIS', formFields?.documentUpdated)

                if(editedUser.entity_data['documents']) {
                    const kbisIndex = editedUser.entity_data['documents'].findIndex(el => el.media_gender === 'KBIS')
                    editedUser.entity_data['documents'][kbisIndex] = kbisDocument
                } else {
                    editedUser.entity_data['documents'] = [kbisDocument]
                }
            }

            // Additional document
            // -------------
            if(isNotEmpty(formFields?.additionalDocumentUpdated)) {
                const additionnalDocument = await handleDocument('PJ', formFields?.additionalDocumentUpdated)
                editedUser.entity_data['documents'].push(additionnalDocument)
            } 

            // Recalculate prime
            // -------------
            if (objectsHaveDiverged(user.entity_data, formFields, ['structurePostCode', 'structureEmployeeCategory'])) {
                const oldPrime = user.entity_data.prime
                const newPrime = calculateBonus(formFields?.structurePostCode, formFields?.structureEmployeeCategory)
                if(oldPrime !== newPrime) {
                    console.info(('Prime has changed'))
                    editedUser.entity_data['prime'] = newPrime
                }
            }

            // if KBIS/Siret are validated (is_check = true) we don't send entity_check. 
            if(!user.entity_check?.is_check) {
                editedUser.entity_check = {
                    action_benef: false,
                    date_check: dateService.getCurrentDate('yyyy-MM-dd HH:mm:ss'),
                    is_check: false
                }
            }

            updateUser(editedUser)
        } catch (error) {
            addAlert('error', error?.toString())
            setShowGlobalError(true)
        } finally {
            setFormEvents(oldFormEvents => ({ ...oldFormEvents, ready: true }))
        }
    }

    const updateUser = async (userToUpdate) => {
        const { res: updateUser, getError } = await UserApi.updateUser({
            ...userToUpdate,
            entity_data: {
                ...user.entity_data,
                ...userToUpdate.entity_data
            }
        })
        if (getError()) throw getError()

        addAlert('success', 'Modification enregistrée')
        refreshUser()
        setFormFields(getFormFieldsFromUserData(updateUser?.entity_data))
        toggleEditableForm()
    }

    const toggleEditableForm = () => {
        // Force the refresh of the user to avoid competitive access with old hash
        refreshUser()

        setFormEvents(formEvents => ({
            ...formEvents,
            editable: !formEvents.editable
        }))
    }

    const cancelModifications = () => {
        toggleEditableForm()
        setFormFields(getFormFieldsFromUserData(user?.entity_data))
    }

    const getCorrespondingEmployeeCategory = (quantity) => {
        if (!quantity) return null
        return (quantity < 50)
            ? employeeCategory[0].value
            : (quantity > 249)
                ? (quantity > 999) ? employeeCategory[3].value : employeeCategory[2].value
                : employeeCategory[1].value
    }

    const isDisabled = !formEvents.editable || isKbisOrSiretValidated

    return (
        <ProtectedLayout className="my-account-page u-pd-hz-m u-pd-top-m u-pd-hz-0@main u-pd-top-xl@main">
            <h1 className="c-h1 u-secondary u-center u-uppercase u-mg-bottom-xl">Mon compte</h1>
            <form className={className ? className : ''}>

                {showGlobalError &&
                    <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur lors de la validation du formulaire.</p>
                }

                {/* Structure */}
                <div className="u-pd-top-m u-pd-top-xl@main u-mg-bottom-l">
                    <div className="l-container-lg">
                        <h2 className="c-h2 u-bold u-primary u-mg-bottom-l">Nature de l'établissement</h2>
                        { isKbisOrSiretValidated &&
                            <div className="u-mg-bottom-m u-bold">
                                Établissement vérifié <Emoji label="visage triste" symbol="✅"/>
                            </div>
                        }
                        <div className="l-grid u-mg-negative-hz-m ">
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Raison sociale */}
                                <FormElement
                                    value={formFields.structureName}
                                    name="structureName"
                                    type="text"
                                    label="Nom de l'établissement"
                                    className="u-mg-bottom-m"
                                    required={true}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main">
                                {/* SIRET */}
                                <FormElement
                                    value={formFields.structureSiret}
                                    name="structureSiret"
                                    type="text"
                                    label="SIRET du site"
                                    className="u-pd-m u-mg-bottom-m"
                                    maxLength={14}
                                    required={true}
                                    customRules={[
                                        {
                                            key: 'siret-invalid',
                                            check: (value) => isSiretValid(value),
                                            message: "Le numéro SIRET n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            {currentRole !== 'public' &&
                                <>
                                    <div className={'l-col-12 l-col-6@main u-pd-m'}>
                                        {/* Document */}
                                        <FormElement
                                            value={formFields.documentUpdated}
                                            defaultValue={formFields.document}
                                            type="file"
                                            name="documentUpdated"
                                            label={(() => {
                                                if (currentRole === 'asso') return 'Annonce de votre existence publiée au JOAFE'
                                                if (currentRole === 'company-public-vocation') return 'Avis de situation au répertoire SIRENE'
                                                return 'Kbis'
                                            })()}
                                            className={'u-mg-bottom-m'}
                                            // required={currentRole === "company"} // only the KBIS is mandatory
                                            showErrors={showLocalErrors}
                                            onValidate={handleValidation}
                                            onChange={handleInputChange}
                                            disabled={isDisabled}
                                        />
                                        {currentRole === 'company' && formEvents.editable &&
                                            <p className="u-fs-xxs u-mg-bottom-m">Extrait Kbis de moins 3 mois au format png, jpg ou pdf</p>
                                        }
                                        {currentRole === 'asso' && formEvents.editable &&
                                            <p className="u-fs-xxs u-mg-bottom-m">Un document de 10Mo maximum au format pdf, png ou jpg</p>
                                        }
                                    </div>

                                    <div className={'l-col-12 l-col-6@main u-pd-m'}>
                                        {/* Additional document */}
                                        <FormElement
                                            value={formFields.additionalDocumentUpdated}
                                            defaultValue={formFields.additionalDocument}
                                            type="file"
                                            name="additionalDocumentUpdated"
                                            label={'Pièce complémentaire'}
                                            className={'u-mg-bottom-m'}
                                            showErrors={showLocalErrors}
                                            onValidate={handleValidation}
                                            onChange={handleInputChange}
                                            disabled={!formEvents.editable || isUserAlreadyUpdateAnAdditionalDocument}
                                        />
                                    </div>

                                    <div className="l-col-12 l-col-6@main u-pd-m">
                                        {/* Code APE */}
                                        <FormElement
                                            value={formFields.structureCodeApe}
                                            defautValue={user?.entity_data.structureCodeApe}
                                            options={currentRole === 'company' ? apeCompanyList : apeAssociationList}
                                            name="structureCodeApe"
                                            type="select"
                                            label="Code APE / NAF"
                                            className="u-mg-bottom-m"
                                            required={false}
                                            showErrors={showLocalErrors}
                                            onValidate={handleValidation}
                                            onChange={handleInputChange}
                                            disabled={isDisabled}
                                        />

                                    </div>
                                </>
                            }
                            <div className="l-col-12 u-pd-m">
                                {/* Adress */}
                                <FormElement
                                    value={formFields.structureAddress}
                                    name="structureAddress"
                                    type="text"
                                    label="Adresse"
                                    className="u-mg-bottom-m"
                                    required={true}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* structurePostCode */}
                                <FormElement
                                    value={formFields.structurePostCode}
                                    name="structurePostCode"
                                    type="number"
                                    label="Code postal"
                                    className="u-mg-bottom-m"
                                    maxLength={5}
                                    required={true}
                                    customRules={[
                                        {
                                            key: 'structurePostCode-invalid',
                                            check: (value) => {
                                                return value ? (value.length === 5) : true
                                            },
                                            message: "Le code postal n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* City */}
                                <FormElement
                                    value={formFields.structureCity}
                                    name="structureCity"
                                    type="text"
                                    label="Ville"
                                    className="u-mg-bottom-m"
                                    required={true}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Tel */}
                                <FormElement
                                    value={formFields.structurePhonenumber}
                                    name="structurePhonenumber"
                                    type="text"
                                    label="Numéro de téléphone de l'établissement"
                                    className="u-mg-bottom-m"
                                    required={true}
                                    customRules={[
                                        {
                                            key: 'structure-phone-invalid',
                                            check: (value) => isValidFrenchPhoneNumber(value),
                                            message: "Le format de numéro de téléphone n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>
                        <div className="l-grid u-mg-negative-hz-m">
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Quantité */}
                                <FormElement
                                    value={formFields.structureEmployeeQuantity}
                                    name="structureEmployeeQuantity"
                                    type="number"
                                    label="Nombre exact de salariés dans l'établissement"
                                    className="u-mg-bottom-m"
                                    required={false}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Catégorie de salariés */}
                                <FormElement
                                    value={formFields.structureEmployeeCategory}
                                    options={employeeCategory}
                                    defaultValue={employeeCategory.find(category => category.value === formFields.structureEmployeeCategory)}
                                    name="structureEmployeeCategory"
                                    type="select"
                                    label="Effectif de l'établissement"
                                    className="u-mg-bottom-m"
                                    required={true}
                                    localValidation={
                                        // Validate that structureEmployeeCategory corresponds to the structureEmployeeQuantity
                                        formFields.structureEmployeeQuantity
                                            ? getCorrespondingEmployeeCategory(formFields?.structureEmployeeQuantity) === formFields.structureEmployeeCategory
                                            : true
                                    }
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                                {formEvents.editable && (
                                    <p className="u-fs-xxs u-mg-bottom-m">
                                        Attention, il vous est indiqué de préciser le nombre d’employés qui travaillent effectivement à l’adresse de votre site et non celui de votre groupe ou de votre société. Ce champ fera l’objet d’une vérification de la part du programme OEPV.
                                    </p>
                                )}
                            </div>
                        </div>
                        <p className="">Si vous souhaitez profiter du programme pour plusieurs de vos établissements, vous devez inscrire chaque établissement indépendamment et indiquer respectivement leur nombre de salariés.</p>
                    </div>
                </div>

                {/* Contact */}
                <div className="u-bg-secondary u-pd-hz-m u-pd-vt-m u-pd-vt-xl@main">
                    <div className="l-container-lg">
                        <h2 className="c-h2 u-bold u-primary u-mg-bottom-l">Responsable du compte</h2>
                        <div className="l-grid u-mg-negative-hz-m">
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Lastname */}
                                <FormElement
                                    value={formFields.contactLastname}
                                    name="contactLastname"
                                    type="text"
                                    label="Nom"
                                    className="u-mg-bottom-m"
                                    classNameInput="u-bg-transparent"
                                    required={true}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Firstname */}
                                <FormElement
                                    value={formFields.contactFirstname}
                                    name="contactFirstname"
                                    type="text"
                                    label="Prénom"
                                    className="u-mg-bottom-m"
                                    classNameInput="u-bg-transparent"
                                    required={true}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Contact role */}
                                <FormElement
                                    value={formFields.contactRole}
                                    name="contactRole"
                                    type="text"
                                    label="Fonction"
                                    className="u-mg-bottom-m"
                                    classNameInput="u-bg-transparent"
                                    required={true}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Phone */}
                                <FormElement
                                    value={formFields.contactPhonenumber}
                                    name="contactPhonenumber"
                                    type="text"
                                    label="Téléphone"
                                    className="u-mg-bottom-m"
                                    classNameInput="u-bg-transparent"
                                    required={true}
                                    customRules={[
                                        {
                                            key: 'phone-invalid',
                                            check: (value) => isValidFrenchPhoneNumber(value),
                                            message: "Le format de numéro de téléphone n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Email de connexion */}
                                <FormElement
                                    value={formFields.connexionEmail}
                                    name="connexionEmail"
                                    type="email"
                                    label="E-mail de connexion"
                                    className="u-mg-bottom-s"
                                    classNameInput="u-bg-transparent"
                                    required={true}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled
                                />
                                <p className="u-fs-xxs u-justify u-mg-bottom-m">Cet email correspond à votre identifiant pour vous connecter à la plateforme, c’est aussi celui qui sera utilisé si vous souhaitez modifier votre mot de passe de connexion. Il ne peut plus être modifié jusqu’à la fin du programme.</p>
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Email de contact */}
                                <FormElement
                                    value={formFields.contactEmail}
                                    name="contactEmail"
                                    type="email"
                                    label="E-mail de contact"
                                    className="u-mg-bottom-s"
                                    classNameInput="u-bg-transparent"
                                    required={true}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                />
                                <p className="u-fs-xxs u-justify u-mg-bottom-m">Cet email désigne l’adresse sur laquelle vous seront envoyés tous les emails de la plateforme (confirmations de demandes, informations sur votre parcours, validations de documents, récapitulatifs de commandes de prestations, bordereaux de versement de prime).</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="u-flex u-flex-center-hz u-pd-top-l u-mg-negative-hz-m u-pd-hz-m u-mg-hz-0@main u-pd-hz-0@main u-mg-top-l">
                    {formEvents?.editable ?
                        <>
                            <div className="u-pd-l">
                                <button
                                    type="button"
                                    className="c-btn c-btn--white-bordered c-btn--m u-pd-hz-xl u-mg-bottom-xl"
                                    style={{ width: '100%', maxWidth: '460px' }}
                                    onClick={cancelModifications}
                                >
                                    Annuler
                                </button>
                            </div>
                            <div className="u-flex u-flex-center-hz u-pd-l" style={{ minWidth: '250px' }}>
                                {formEvents.ready &&
                                    <button
                                        type="submit"
                                        className="c-btn c-btn--primary c-btn--m u-pd-hz-xl u-mg-bottom-xl"
                                        style={{ width: '100%', maxWidth: '460px' }}
                                        onClick={handleSubmit}
                                    >
                                        Enregistrer
                                    </button>
                                }
                                {!formEvents.ready &&
                                    <div className="c-spinner"></div>
                                }
                            </div>
                        </>
                        :
                        <button
                            type="button"
                            className="c-btn c-btn--primary c-btn--m u-pd-hz-l u-mg-bottom-xl"
                            style={{ width: '100%', maxWidth: '460px' }}
                            onClick={toggleEditableForm}
                        >
                            Modifier
                        </button>
                    }
                </div>
            </form>
        </ProtectedLayout >
    )
}

AccountPage.propTypes = {
    className: string
}

export default AccountPage
