import { number, shape, string } from 'prop-types'

export const monitoringPDFpropTypes = {
    beneficiary: shape({
        structureName: string.isRequired,
        structurePhonenumber: string.isRequired,
        structureAddress: string.isRequired,
        structurePostCode: string.isRequired,
        structureCity: string.isRequired,
        contactLastname: string.isRequired,
        contactFirstname: string.isRequired,
        contactPhonenumber: string.isRequired,
        contactEmail: string.isRequired
    }).isRequired,
    request: shape({
        request_description: shape({
            type: string,
            comment: string.isRequired,
            bikesQuantity: number,
            personsQuantity: number,
            sessionsQuantity: number
        }),
        request_fub_id: string.isRequired,
        create_date: string
    }).isRequired,
    provider: shape({
        structureName: string.isRequired,
        structureAddress: string.isRequired,
        structurePostCode: number.isRequired,
        structureCity: string.isRequired,
        mail: string.isRequired,
        structurePhonenumber: string.isRequired
    })
}