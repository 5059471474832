import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import Logo from 'components/LogoOEPV'
import FolderIcon from 'components/svg/Folder'
import TeamIcon from 'components/svg/Team'
import LogoutIcon from 'components/svg/Logout'
import PaymentIcon from 'components/svg/Payment'
import Statistic from 'components/svg/Statistic'
import Download from 'components/svg/Download'
import { publicRoutes, privateRoutes } from 'router/routes'
import { useAuth } from 'hooks/useAuth'

import {
    backOfficeManagementAdvisor,
    backOfficeManagementSupervisor,
    backOfficeRelationshipManager,
    backOfficeRelationshipOperator
} from 'utils/domain/user'
import './styles.scss'
import Padlock from 'components/svg/Padlock'

const SidePanel = () => {
    const {
        user, logout,
        isABackOfficeManagementUser,
        isABackOfficeManagementSupervisor,
        isABackOfficeLeadUser,
        isABackOfficeRelationshipManager,
        isABackOfficeRelationshipOperator,
        isASupervisor,
        isAnAdvisor
    } = useAuth()

    const loginPath = publicRoutes.backOffice.login.path

    // [BO Gestion] = used to display a back-office management user side-panel
    const beneficiariesPath = privateRoutes.backOffice.beneficiaries.path
    const foldersPath = privateRoutes.backOffice.folders.path

    const isMatchHome = useRouteMatch(privateRoutes.backOffice.backOfficeHome.path)?.isExact
    const isMatchFolders = useRouteMatch(privateRoutes.backOffice.folders.path)?.isExact
    const isMatchBeneficiaries = useRouteMatch(privateRoutes.backOffice.beneficiaries.path)?.isExact
    const isMatchBeneficiaryDetails = useRouteMatch(privateRoutes.backOffice.beneficiaryDetails.path)?.isExact
    const isMatchEntityFolder = useRouteMatch(privateRoutes.backOffice.entityFolder.path)?.isExact
    const isMatchRequestFolder = useRouteMatch(privateRoutes.backOffice.requestFolder.path)?.isExact
    const isBeneficiariesActive = isMatchBeneficiaries || isMatchBeneficiaryDetails
    const isMatchDetailFolder = isMatchEntityFolder || isMatchRequestFolder

    // [BO Pilotage] = used to display a side-panel for back-office lead user || a back-office management supervisor
    const participantsAdministrationPath = privateRoutes.backOffice.participantsAdministration.path
    const providersAdministrationPath = privateRoutes.backOffice.providersAdministration.path
    const beneficiariesAdministrationPath = privateRoutes.backOffice.beneficiariesAdministration.path
    const accreditationsPath = privateRoutes.backOffice.accreditations.path
    const paymentsPath = privateRoutes.backOffice.payments.path
    const managementPath = privateRoutes.backOffice.management.path
    const uploadProviderDataPath = privateRoutes.backOffice.uploadproviderdata.path

    const isMatchParticipantsAdministration = useRouteMatch(privateRoutes.backOffice.participantsAdministration.path)
    const isMatchProvidersAdministration = useRouteMatch(privateRoutes.backOffice.providersAdministration.path)
    const isMatchBeneficiariesAdministration = useRouteMatch(privateRoutes.backOffice.beneficiariesAdministration.path)
    const isMatchAccreditations = useRouteMatch(privateRoutes.backOffice.accreditations.path)?.isExact
    const isMatchAccreditationDetail = useRouteMatch(privateRoutes.backOffice.accreditationDetail.path)?.isExact
    const isMatchAccreditationCreation = useRouteMatch(privateRoutes.backOffice.accreditationCreation.path)?.isExact
    const isMatchPayments = useRouteMatch(paymentsPath)?.isExact
    const isMatchManagement = useRouteMatch(managementPath)?.isExact
    const isMatchUpload = useRouteMatch(uploadProviderDataPath)?.isExact

    const isParticipantsAdministrationActive = isMatchParticipantsAdministration
    const isProvidersAdministrationActive = isMatchProvidersAdministration
    const isBeneficiariesAdministrationActive = isMatchBeneficiariesAdministration 
    const isAccreditationsActive = isMatchAccreditations || isMatchAccreditationDetail || isMatchAccreditationCreation

    return (
        <div className="sidenav">
            <NavLink exact to={loginPath} className="menu-item-logo">
                <Logo className="logo" />
            </NavLink>
            <div className="u-center u-mg-bottom-l">
                <div className="u-blue u-bold u-italic ">
                    {(isABackOfficeManagementSupervisor || isAnAdvisor) && 'Back Office Gestion'}
                    {isABackOfficeLeadUser && !isABackOfficeRelationshipManager && 'Back Office Pilotage'}
                    {isABackOfficeRelationshipManager && backOfficeRelationshipManager?.label }
                    {isABackOfficeRelationshipOperator && backOfficeRelationshipOperator?.label }
                </div>
                <div className="u-blue u-bold u-italic u-fs-xs">
                    {/* Conseiller */}
                    {(isAnAdvisor) && backOfficeManagementAdvisor?.shortLabel}
                     {/* Superviseur */}
                    {isASupervisor && backOfficeManagementSupervisor?.shortLabel}
                </div>
                <div className="u-grey90 u-fs-s c-mail">
                    {user?.entity_data?.mail}
                </div>
            </div>

            {/* BackOffice conseiller */}
            {isABackOfficeManagementUser && !isABackOfficeRelationshipOperator &&
                <>
                    <NavLink
                        exact
                        to={foldersPath}
                        className="menu-item"
                        activeClassName="is-active"
                        isActive={() => isMatchHome || isMatchFolders || isMatchDetailFolder}
                    >
                        <FolderIcon size={20} className="icon" />
                        <span>Mes dossiers</span>
                    </NavLink>
                    <NavLink
                        exact
                        to={beneficiariesPath}
                        className="menu-item"
                        activeClassName="is-active"
                        isActive={() => isBeneficiariesActive}
                    >
                        <TeamIcon size={40} className="icon" />
                        <span>Rechercher un bénéficiaire</span>
                    </NavLink>
                </>
            }

            {/* CRB opérateur */}
            {isABackOfficeRelationshipOperator &&
                <>
                    <NavLink
                        exact
                        to={beneficiariesPath}
                        className="menu-item"
                        activeClassName="is-active"
                        isActive={() => isBeneficiariesActive}
                    >
                        <TeamIcon size={40} className="icon" />
                        <span>Rechercher un bénéficiaire</span>
                    </NavLink>
                </>
            }

            {/* BackOffice Superviseur */}
            {isABackOfficeManagementSupervisor && 
                <>
                    <NavLink
                            exact
                            to={accreditationsPath}
                            className="menu-item"
                            activeClassName="is-active"
                            isActive={() => isAccreditationsActive}
                        >
                            <Padlock size={40} className="icon" />
                            <span>Gestion des habilitations</span>
                        </NavLink>
                </>
            }

            {/* BackOffice Pilotage */}
            {isABackOfficeLeadUser && !isABackOfficeRelationshipManager && 
                <>
                    <NavLink
                        exact
                        to={participantsAdministrationPath}
                        className="menu-item"
                        activeClassName="is-active"
                        isActive={() => isParticipantsAdministrationActive || isProvidersAdministrationActive || isBeneficiariesAdministrationActive}
                    >
                        <TeamIcon size={40} className="icon" />
                        <span>Gestion des utilisateurs</span>
                    </NavLink>

                    {(isParticipantsAdministrationActive || isProvidersAdministrationActive || isBeneficiariesAdministrationActive) &&
                        <ul className="sub-items">
                            <li>
                                <NavLink
                                    exact
                                    to={participantsAdministrationPath}
                                    className="sub-item"
                                    activeClassName="is-active"
                                    isActive={() => isParticipantsAdministrationActive }
                                >
                                    <span>Intervenants</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to={providersAdministrationPath}
                                    className="sub-item"
                                    activeClassName="is-active"
                                    isActive={() => isProvidersAdministrationActive}
                                >
                                    <span>Fournisseurs d'équipements</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to={beneficiariesAdministrationPath}
                                    className="sub-item"
                                    activeClassName="is-active"
                                    isActive={() => isBeneficiariesAdministrationActive}
                                >
                                    <span>Bénéficiaires</span>
                                </NavLink>
                            </li>
                        </ul>
                    }

                    <NavLink
                        exact
                        to={accreditationsPath}
                        className="menu-item"
                        activeClassName="is-active"
                        isActive={() => isAccreditationsActive}
                    >
                        <Padlock size={40} className="icon" />
                        <span>Gestion des habilitations</span>
                    </NavLink>

                    <NavLink
                        exact
                        to={paymentsPath}
                        className="menu-item"
                        activeClassName="is-active"
                        isActive={() => isMatchPayments}
                    >
                        <PaymentIcon size={40} className="icon" />
                        <span>Gestion des paiements</span>
                    </NavLink>

                    <NavLink
                        exact
                        to={managementPath}
                        className="menu-item"
                        activeClassName="is-active"
                        isActive={() => isMatchManagement}
                    >
                        <Statistic size={30} className="icon" />
                        <span>Pilotage</span>
                    </NavLink>

                    <NavLink
                        exact
                        to={uploadProviderDataPath}
                        className="menu-item"
                        activeClassName="is-active"
                        isActive={() => isMatchUpload}
                    >
                        <Download size={30} className="icon" />
                        <span>Table de correspondance</span>
                    </NavLink>
                </>
            }
            {isABackOfficeRelationshipManager &&
                <NavLink
                    exact
                    to={managementPath}
                    className="menu-item"
                    activeClassName="is-active"
                    isActive={() => isMatchManagement}
                >
                    <Statistic size={30} className="icon" />
                    <span>Pilotage</span>
                </NavLink>
            }

            {/* CRB superviseur */}
            {isABackOfficeRelationshipManager &&
                <NavLink
                    exact
                    to={managementPath}
                    className="menu-item"
                    activeClassName="is-active"
                    isActive={() => isMatchManagement}
                >
                    <Statistic size={30} className="icon" />
                    <span>Pilotage</span>
                </NavLink>
            }

            <button className="menu-item" onClick={logout}>
                <LogoutIcon size={20} className="icon" />
                <div>Déconnexion</div>
            </button>
        </div>
    )
}

export default SidePanel
