import React from 'react'
import { motion, AnimatePresence } from 'services/framerMotion'
import { useModal } from 'hooks/useModal'
import CrossIcon from 'components/svg/Cross'
import { bool, func, object, string } from 'prop-types'

export const ModalOverlayVariants = {
    open: {
        opacity: '0.6',
        transition: {
            when: 'beforeChildren'
        }
    },
    closed: {
        opacity: '0',
        transition: {
            when: 'afterChildren'
        },
    }
}

export const ModalVariants = {
    open: {
        opacity: '1',
        y: '0',
    },
    closed: {
        opacity: '0',
        y: '50%',
    }
}

const Modal = ({ children, onClose, modalClassName, ...props }) => {
    return (
        <AnimatePresence>
            {children && (
                <motion.div
                    key="modal-wrapper"
                    className="c-modal-wrapper"
                >
                    <motion.div className={`c-modal-overlay ${props.isClosable ? '' : 'no-cursor'}`}
                        key="modal-overlay"
                        onClick={props.isClosable ? onClose : () => {}}
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={ModalOverlayVariants}>
                    </motion.div>

                    <motion.div className={'c-modal ' + (modalClassName ? modalClassName : '')}
                        {...props}
                        key="modal"
                        onClick={e => e.stopPropagation()}
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={ModalVariants}
                    >
                        { props.isClosable &&
                            <button onClick={onClose} aria-label="Fermer la modale" className="c-modal__close u-pd-xs">
                                <CrossIcon size={25} />
                                <span className="u-mg-top-xxs u-fs-xxs">Fermer</span>
                            </button>
                        }

                        {children}
                    </motion.div>
                </motion.div>
            )}
         </AnimatePresence>
    )
}

Modal.propTypes = {
    children: object,
    onClose: func,
    modalClassName: string,
    isClosable: bool
}

const ModalRoot = () => {
    const { component, modalProps, hideModal } = useModal()
    return (
        <Modal onClose={hideModal} {...modalProps}>
            { component }
        </Modal>
    )
}


export { Modal as default, ModalRoot }
