import React from 'react'
import { node } from 'prop-types'

import * as UserApi from 'services/api/UserApi'
import httpService from 'services/http'
import localStorageService, { ENTITY_ID_KEY, BACKOFFICE_USER_ID_KEY } from 'services/localStorage'
import { useFiltersMemory } from 'hooks/useFiltersMemory'

import { useBasket } from 'hooks/useBasket'
import {
    checkIsABeneficiary,
    checkIsBackOfficeUser, 
    checkIsASupervisor,
    checkIsABackOfficeLeadUser,
    checkIsBackOfficeLeadSupervisor,
    checkIsABackOfficeManagementUser, 
    checkIsBackOfficeManagementSupervisor,
    checkIsABackOfficeManagementAdvisor,
    checkIsAnAdvisor, 
    isADashboardAdminUser,
    checkIsPlatformUser,
    checkIsABackOfficeRelationshipManager,
    checkIsABackOfficeRelationshipOperator
} from 'utils/domain/user'

const AuthContext = React.createContext({
    user: null,
    isABeneficiaryUser: false,
    isASupervisor: false,
    isAnAdvisor: false,
    isABackOfficeUser: false,
    isABackOfficeLeadUser: false,
    isABackOfficeManagementUser: false,
    isABackOfficeManagementSupervisor: false,
    isABackOfficeManagementAdvisor: false,
    isABackOfficeRelationshipManager: false,
    isABackOfficeRelationshipOperator: false,
    isLoggedIn: false,
    acceptedCGU: false,
    previousUserRef: null,
    refreshUser: async () => {},
    login: async () => {},
    logout: () => {},
    setPreviousUserRef: () => {}
})

const useAuth = () => {
    const context = React.useContext(AuthContext)
    if (context === undefined) {
      throw new Error('useAuth must be used within a AuthContextProvider')
    }
  
    return context
}

const AuthProvider = ({ children }) => {
    const [user, setUser] = React.useState(null)
    const [isBackOfficeSignIn, setIsBackOfficeSignIn] = React.useState(false)
    const isLoggedIn = React.useMemo(() => !!user, [user])
    const acceptedCGU = React.useMemo(() => user?.entity_data?.acceptedCGU, [user])
    const { setBasket } = useBasket()
    const previousUserRef = React.useRef(null)
    const {
        clearAllFilters
    } = useFiltersMemory()

    const isABeneficiaryUser = checkIsABeneficiary({ gender: user?.entity_data?.gender, services: user?.entity_data?.services })
    const isASupervisor = checkIsASupervisor({ gender: user?.entity_data?.gender, permission: user?.entity_data?.permission })
    const isAnAdvisor = checkIsAnAdvisor({ gender: user?.entity_data?.gender, permission: user?.entity_data?.permission })
    const isABackOfficeUser = checkIsBackOfficeUser({ gender: user?.entity_data?.gender })
    const isABackOfficeLeadUser = checkIsABackOfficeLeadUser({ gender: user?.entity_data?.gender }) // Back Office Pilotage
    const isABackOfficeRelationshipManager = checkIsABackOfficeRelationshipManager({ permission: user?.entity_data?.permission })
    const isABackOfficeRelationshipOperator = checkIsABackOfficeRelationshipOperator({ permission: user?.entity_data?.permission })
    const isBackOfficeLeadSupervisor = checkIsBackOfficeLeadSupervisor({ gender: user?.entity_data?.gender, permission: user?.entity_data?.permission })
    const isABackOfficeManagementUser = checkIsABackOfficeManagementUser({ gender: user?.entity_data?.gender })
    const isABackOfficeManagementSupervisor = checkIsBackOfficeManagementSupervisor({ gender: user?.entity_data?.gender, permission: user?.entity_data?.permission }) // Back office gestion supervisor
    const isABackOfficeManagementAdvisor = checkIsABackOfficeManagementAdvisor({ gender: user?.entity_data?.gender, permission: user?.entity_data?.permission }) // Back office gestion conseiller

    const userHasCoordinates = user?.entity_data?.latitude !== undefined && user?.entity_data?.longitude !== undefined

    const fetchUser = React.useCallback(async (entity_id) => {       
        const userData = await UserApi.getUser(entity_id)
        let newUser
        
        if (!userData || userData?.error) {
            newUser = null
        } else {
            newUser = {
                entity_id,
                ...userData
            }
            setUser(newUser)
            localStorageService.set(ENTITY_ID_KEY, entity_id)
        }
            
        return !!newUser
    }, [])

    const login = React.useCallback(async (mail, password, {
        backOfficeSignIn
    }) => {
        if (user) return true
        
        const signInResponse = await UserApi.signIn(mail, password)

        if (!signInResponse || signInResponse?.error) {
            return signInResponse
        } else if(backOfficeSignIn && !checkIsBackOfficeUser({ gender: signInResponse?.entity_gender })) {
            return {
                error: {
                    code: 'NotAuthorizedException'
                }
            }
        } else if(
                checkIsPlatformUser({ gender: signInResponse?.entity_gender }) || 
                checkIsBackOfficeUser({ gender: signInResponse?.entity_gender })
            ) {
            const entityId = signInResponse.entity_id
            setIsBackOfficeSignIn(backOfficeSignIn)

            // If admin, not need for getUser
            if (checkIsBackOfficeUser({ gender: signInResponse?.entity_gender }) || 
                isADashboardAdminUser({ gender: signInResponse?.entity_gender })) {
                setUser({
                    entity_id: entityId,
                    entity_data: {
                        permission: signInResponse.user_permission,
                        gender: signInResponse.entity_gender,
                        mail
                    }
                })
                localStorageService.set(BACKOFFICE_USER_ID_KEY, signInResponse?.user_id)
                return true
            }
            const newUser = await fetchUser(entityId)
            
            return !!newUser
        } else {
            return {
                error: {
                    code: 'NotAuthorizedException'
                }
            }
        }
    }, [user, fetchUser])

    const logout = () => {
        setBasket(null)
        clearAllFilters()
        httpService.clearSession()
        localStorageService.remove(ENTITY_ID_KEY)
        localStorageService.remove(BACKOFFICE_USER_ID_KEY)
        setUser(null)
    }

    const refreshUser = async () => await fetchUser(user.entity_id)

    const handleEntityUser = (userEntityId) => {
        UserApi.getUser(userEntityId).then(res => {
            if (res && !res.error) {
                setUser({
                    ...res,
                    entity_id: userEntityId
                })
            } else {
                logout()
            }
        })
    }

    const handleBackOfficeUser = async (backOfficeUserId) => {
        try {
            const { res, getError } = await UserApi.getBackOfficeUser(backOfficeUserId)
            if(getError()) throw getError()

            setUser({
                entity_data: {
                    ...res?.description,
                    permission: res.permission,
                    gender: res.gender,
                    mail: res.mail,
                }
            })
            
        } catch(error) {
            console.error(error?.toString())
            logout()
        }
    }
    
    React.useEffect(() => {
        // Init http service
        httpService.init()

        // Auto-login
        // On app load, if a entity_id + token is available in localStorage, restore the user
        const userEntityId = localStorageService.get(ENTITY_ID_KEY)
        if (userEntityId) handleEntityUser(userEntityId)

        const backOfficeUserId = localStorageService.get(BACKOFFICE_USER_ID_KEY)
        if(backOfficeUserId) handleBackOfficeUser(backOfficeUserId)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const setPreviousUserRef = (userId) => previousUserRef.current = userId

    return (
        <AuthContext.Provider value={{ 
            user, 
            isLoggedIn, 
            isABeneficiaryUser,
            isBackOfficeSignIn, 
            isASupervisor, 
            isAnAdvisor, 
            isABackOfficeUser,
            isABackOfficeLeadUser,
            isBackOfficeLeadSupervisor,
            isABackOfficeRelationshipManager,
            isABackOfficeRelationshipOperator,
            isABackOfficeManagementUser,
            isABackOfficeManagementSupervisor,
            isABackOfficeManagementAdvisor,
            userHasCoordinates, 
            acceptedCGU, 
            previousUserRef, 
            login, 
            logout, 
            setPreviousUserRef, 
            refreshUser 
        }}>
            {children}
        </AuthContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: node
}

export { useAuth, AuthProvider }