import React from 'react'
import { number, string, oneOfType } from 'prop-types'

import { DEFAULT_SIZE } from 'utils/icons'

const ChevronExpandIcon = ({ size = DEFAULT_SIZE, color }) => {
	return (
        <svg style={{ width: size, height: size }} fill={color} viewBox="0 0 14 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="chevron-expand" transform="translate(-5.000000, -3.000000)" fill="#6A6E73">
                    <path d="M5.46899997,13.719 C5.60970637,13.5779375 5.80075908,13.4986593 5.99999997,13.4986593 C6.19924087,13.4986593 6.39029358,13.5779375 6.53099998,13.719 L12,19.1895 L17.469,13.719 C17.7622632,13.4257368 18.2377368,13.4257368 18.531,13.719 C18.8242632,14.0122632 18.8242632,14.4877368 18.531,14.781 L12.531,20.781 C12.3902936,20.9220626 12.1992409,21.0013407 12,21.0013407 C11.8007591,21.0013407 11.6097064,20.9220626 11.469,20.781 L5.46899997,14.781 C5.32793742,14.6402936 5.2486593,14.4492409 5.2486593,14.25 C5.2486593,14.0507591 5.32793742,13.8597064 5.46899997,13.719 L5.46899997,13.719 Z M5.46899997,10.281 C5.60970637,10.4220625 5.80075908,10.5013407 5.99999997,10.5013407 C6.19924087,10.5013407 6.39029358,10.4220625 6.53099998,10.281 L12,4.81049997 L17.469,10.281 C17.7622632,10.5742632 18.2377368,10.5742632 18.531,10.281 C18.8242632,9.98773678 18.8242632,9.51226319 18.531,9.21899999 L12.531,3.21899996 C12.3902936,3.07793741 12.1992409,2.99865929 12,2.99865929 C11.8007591,2.99865929 11.6097064,3.07793741 11.469,3.21899996 L5.46899997,9.21899999 C5.32793742,9.35970638 5.2486593,9.5507591 5.2486593,9.74999999 C5.2486593,9.94924088 5.32793742,10.1402936 5.46899997,10.281 L5.46899997,10.281 Z"></path>
                </g>
            </g>
        </svg>
	)
}


ChevronExpandIcon.propTypes = {
    size: oneOfType([string, number]),
    color: string
}


export default ChevronExpandIcon

