import Formation from 'assets/images/formation-outillage-equipe-velo.jpg'
import Strategie from 'assets/images/definition-strategie-velo.jpg'
import Audit from 'assets/images/cahier-charges-audit.jpg'

import Test from 'assets/images/cycling-strategy-costs.png'

export const cyclingStrategy = {
    label: 'Définition d’une stratégie vélo',
    shortLabel: 'Définition d’une stratégie vélo',
    id: 'cycling-strategy',
    picture: Strategie,
    description: `
Un consultant référencé par le programme accompagne votre établissement pour évaluer la pratique actuelle du vélo par 
votre personnel et définir un objectif de progression de cette part modale (% de déplacements domicile-travail ou professionnels réalisés à vélo). 
Avec la désignation d’une personne référente pour animer la démarche pro-vélo, c’est une des premières actions à entreprendre avant de construire un plan d’actions pouvant mener à la labellisation Employeur Pro-Vélo de l’établissement.
    `,
    duration: '< 250 salariés : 8 jours max',
    duration2: '> 250 salariés : 10 jours max',
    price: '< 250 salariés : 6 800€ HT max',
    price2: '> 250 salariés : 8 500€ HT max',
    unitPrice: 6800,
    maxUnitPrice: 8500,
    refundPortion: 0.6,
    nbMeeting: 1,
    display: true,
    content: `
### Contenu

Cette intervention d’un consultant peut comprendre :
- **La réalisation d’un diagnostic axé sur le vélo :**
    - Une enquête et analyse des pratiques de déplacement des employés (questionnaire) visant à évaluer la part modale du vélo,
    - Un croisement de cette analyse avec le système vélo local (aménagements, accessibilité du site, services...),
    - Une identification des actions pro-vélo déjà existantes,
    - Une évaluation du potentiel de report modal vers le vélo et la définition d’indicateurs pour outiller votre démarche pro-vélo.
- **D’un plan d’action vélo :**
    - La co-construction d’un plan d’actions pro-vélo (ambition, calendrier, moyens, mesures pro-vélo, suivi) portant sur les 5 axes du cahier des charges (pilotage & stratégie, communication & motivation, services, sécurité, équipements)
    - Un appui opérationnel à la réalisation des actions sur un ou plusieurs axes du cahier des charges:
        - Sélection des équipements et services proposés par le catalogue du programme OEPV (choix dialogue avec les fournisseurs)
        - Mise en place du Forfait Mobilités Durables pour développer l’usage du vélo
        - Plan de communication/d’animation
        - Sécurité (identification des risques, programme de formation...)


### L’employeur

- Désigne une personne en charge du pilotage de la prestation et des échanges avec le consultant (le référent vélo),
- Convient avec le consultant des modalités de réalisation de la prestation (rdv sur site, mise à disposition de documents, modalités d’enquête auprès du personnel...)


### Le consultant

- Adapte sa méthode, le temps de la prestation et ses outils d’intervention à la maturité, aux besoins, à l’organisation et à la taille du site employeur,
- Assure au moins un rendez-vous sur place sur le site employeur (sauf contraintes sanitaires ou de sécurité spécifiques),
- Remet un rapport à l’employeur à la fin de sa prestation

### Coût

Le prix jour-homme est de 850 € HT. La prestation est prise en charge à 60 % par le programme OEPV, sur la base d’un montant plafonné qui dépend de la taille du site.
![Coût](${Test})
    `
}

export const trainingAndToolsOfCyclingTeam = {
    label: 'Organisation, formation et outillage de l’équipe vélo',
    shortLabel: 'Organisation formation outillage',
    id: 'training-and-tools-of-cycling-team',
    picture: Formation,
    description: `
Un consultant référencé par le programme accompagne votre établissement pour former et outiller la personne en charge de la démarche pro-vélo de l’établissement : pilotage, animation, suivi d’indicateurs... 
Si cette personne n’est pas encore nommée, le consultant peut vous conseiller dans la mise en place d’une organisation pro-vélo (constitution d’une équipe projet, mise à jour des fiches de poste…). 
Pour pouvoir être labellisé Employeur Pro-Vélo, un établissement doit obligatoirement avoir désigné et missionné une personne référente.
    `,
    duration: '3 jours max (selon le niveau du référent vélo)',
    price: '2 550€ HT max',
    unitPrice: 2550,
    refundPortion: 0.6,
    nbMeeting: 1,
    display: true,
    content: `
### Contenu

Cette intervention de 3 jours maximum d’un consultant comprend :
- L’appui à la mise en place d’une équipe d’animation de la démarche pro-vélo et au choix d’un(e) référente/pilote,
- Une formation aux enjeux du système vélo,
- Une proposition d’outils de suivi et de communication,
- Si besoin, la co-construction d’argumentaire, co-animation de réunions, négociation...

### L’employeur

- Désigne une personne en charge du pilotage de la prestation,
- Convient avec le consultant des modalités de réalisation de la prestation (rdv sur site, mise à disposition d’une salle de réunion...).

### Le consultant

- Adapte sa méthode et ses outils d’intervention à la maturité, au temps de formation (limité à 3 jours d’intervention), aux besoins, à l’organisation et à la taille du site employeur,
- Assure au moins un rendez-vous sur place dans l’établissement (sauf contraintes sanitaires ou de sécurité spécifiques),
- Remet à l’employeur :
    - Une note de synthèse sur l’accompagnement réalisé,
    - Les supports de formation (comprenant les outils proposés ou les coordonnées pour les obtenir).

### Coût 

Prix forfaitaire de 850 € par jour avec un maximum de 3 jours de prestation (2550€ HT), pris en charge à 60 % par le programme Objectif Employeur Pro-Vélo, soit un reste à charge de 340 € par jour de prestation.
    `
}

export const specificationsTraining = {
    label: 'Formation au cahier des charges, audit à blanc',
    shortLabel: 'Formation au cahier des charges, audit à blanc',
    id: 'specifications-training',
    picture: Audit,
    description: `
Un consultant référencé par le programme accompagne votre établissement à la prise en main du cahier des charges du label employeur pro-vélo et peut réaliser un audit à blanc. 
Cette intervention s’envisage si votre établissement est déjà bien avancé dans sa démarche employeur pro-vélo, pour se préparer à l’audit de labellisation.
    `,
    duration: '2 jours max',
    price: '1 700€ HT max',
    unitPrice: 1700,
    refundPortion: 0.6,
    nbMeeting: 1,
    display: true,
    content: `
### Contenu

Cette intervention de 2 jours maximum d’un consultant comprend :
- Formation/prise en main du cahier des charges du label employeur pro-vélo,
- Définition d’une ambition réaliste de labellisation (calendrier et niveau au regard du plan d’actions),
- Réalisation d’un audit à blanc (détection des écarts et des points de sensibilité, recommandations pour progresser, conseil sur les éléments de preuve à compléter pour l’audit).

### L’employeur

- Désigne une personne en charge du pilotage de la prestation,
- Mobilise son établissement pour que l’audit à blanc lui soit profitable (temps dédié, rassemblement des éléments de preuve…),
- Convient avec le consultant des modalités de réalisation de la prestation (rdv sur site, mise à disposition d’une salle de réunion…).

### Le consultant

- Adapte sa méthode et ses outils d’intervention à la maturité, au temps de sa prestation (2 jours maximum), aux besoins, à l’organisation et à la taille du site employeur,
- Assure au moins un rendez-vous sur place sur le site de l’employeur (sauf contraintes sanitaires ou de sécurité spécifiques),
- Remet à l’employeur la grille complétée du cahier des charges du label employeur pro-vélo pour son site employeur avec l’identification des points forts et des points faibles, et des recommandations pour progresser.

### Coût

Prix forfaitaire de 850 € par jour avec un maximum de 2 jours de prestation (1700€ HT), pris en charge à 60 % par le programme Objectif Employeur Pro-Vélo, soit un reste à charge de 340 € par jour de prestation. 
    `
}

export const diagnosticMobility = {
    label: 'Diagnostic mobilité axé sur le vélo',
    shortLabel: 'Diagnostic mobilité',
    id: 'diagnostic-mobility',
    picture: Strategie,
    duration: '2 jours',
    price: '1600€ HT',
    unitPrice: 1600,
    refundPortion: 0.6,
    nbMeeting: 1,
    display: false,
}

export const actionPlan = {
    label: "Élaboration/enrichissement d'un plan d’actions pro-vélo et appui opérationnel à la réalisation",
    shortLabel: 'Élaboration plan d\'action',
    id: 'action-plan',
    picture: Strategie,
    duration: '4 jours',
    price: '3200€ HT',
    unitPrice: 3200,
    refundPortion: 0.6,
    nbMeeting: 2,
    display: false,
}

export const auditPreparation = {
    label: 'Prise en main du cahier des charges employeur pro-vélo, préparation à l’audit, audit à blanc',
    shortLabel: 'Préparation à l\'audit',
    id: 'audit-preparation',
    description: `
Un consultant référencé par le programme accompagne votre établissement à la prise en main du cahier des charges du label employeur pro-vélo et peut réaliser un audit à blanc. 
Cette intervention s’envisage si votre établissement est déjà bien avancé dans sa démarche employeur pro-vélo, pour se préparer à l’audit de labellisation.
    `,
    duration: '1,5 jours',
    price: '1200€ HT',
    unitPrice: 1200,
    refundPortion: 0.6,
    nbMeeting: 1,
    display: false,
}

export const setupAndTraining = {
    label: 'Organisation, formation et outillage de l’équipe vélo',
    shortLabel: 'Organisation formation outillage',
    id: 'setup-and-training',
    description: `
Un consultant référencé par le programme accompagne votre établissement pour former et outiller la personne en charge de la démarche pro-vélo de l’établissement : pilotage, animation, suivi d’indicateurs... 
Si cette personne n’est pas encore nommée, le consultant peut vous conseiller dans la mise en place d’une organisation pro-vélo (constitution d’une équipe projet, mise à jour des fiches de poste…). 
Pour pouvoir être labellisé Employeur Pro-Vélo, un établissement doit obligatoirement avoir désigné et missionné une personne référente.
    `,
    duration: '1,5 jours',
    price: '1200€ HT',
    unitPrice: 1200,
    refundPortion: 0.6,
    nbMeeting: 1,
    display: false,
}

export const formationList = [
    cyclingStrategy,
    trainingAndToolsOfCyclingTeam,
    setupAndTraining,
    auditPreparation,
    specificationsTraining,
    diagnosticMobility,
    actionPlan
]