// Inspired by
// https://github.com/react-csv/react-csv/blob/master/src/core.js

const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const isJsons = (array) => Array.isArray(array) && array.every(
    row => (typeof row === 'object' && !(row instanceof Array))
)

const isArrays = (array) => Array.isArray(array) && array.every(
    row => Array.isArray(row)
)

const getJsonsHeaders = (array) => Array.from(
    array.map(json => Object.keys(json))
        .reduce((a, b) => new Set([...a, ...b]), [])
)


const getHeaderValue = (property, obj) => {
    const foundValue = property
        .replace(/\[([^\]]+)]/g, '.$1')
        .split('.')
        .reduce((o, p, i, arr) => {
        // if at any point the nested keys passed do not exist, splice the array so it doesnt keep reducing
        if (o[p] === undefined) {
            arr.splice(1)
            return arr
        } else {
            return o[p]
        }
    }, obj)
    // if at any point the nested keys passed do not exist then looks for key `property` in object obj
    return (foundValue === undefined) ? ((property in obj) ? obj[property] : '') : foundValue
}

const jsons2arrays = (jsons, headers) => {
    headers = headers || getJsonsHeaders(jsons)
    // allow headers to have custom labels, defaulting to having the header data key be the label
    let headerLabels = headers
    let headerKeys = headers
    if (isJsons(headers)) {
        headerLabels = headers.map((header) => header.label)
        headerKeys = headers.map((header) => header.key)
    }
    const data = jsons.map((object) => headerKeys.map((header) => getHeaderValue(header, object)))
    return [headerLabels, ...data]
}

const elementOrEmpty = (element) => (typeof element === 'undefined' || element === null) ? '' : element

const joiner = (data, separator = ',', enclosingCharacter = '"') => {
    return data
        .filter(e => e)
        .map(row =>
            row
                .map((element) => elementOrEmpty(element))
                .map(column => `${enclosingCharacter}${column}${enclosingCharacter}`)
                .join(separator)
        )
        .join('\n')
}

const arrays2csv = ((data, headers, separator, enclosingCharacter) =>
  joiner(headers ? [headers, ...data] : data, separator, enclosingCharacter)
)

const jsons2csv = ((data, headers, separator, enclosingCharacter) =>
    joiner(jsons2arrays(data, headers), separator, enclosingCharacter)
)

const string2csv = ((data, headers, separator, enclosingCharacter) =>
    (headers) ? `${headers.join(separator)}\n${data}` : data
)

export const dataToCSV = (data, headers, separator, enclosingCharacter) => {
    if (isJsons(data)) return jsons2csv(data, headers, separator, enclosingCharacter)
    if (isArrays(data)) return arrays2csv(data, headers, separator, enclosingCharacter)
    if (typeof data === 'string') return string2csv(data, headers, separator)
    throw new TypeError('Data should be a "String", "Array of arrays" OR "Array of objects"')
}

export const csvToBlob = (data, headers = null) => {
    const config = {
        uFEFF: true,
        headers,
        separator: ',',
    }
    const csv = dataToCSV(data, config.headers, config.separator)
    const type = isSafari() ? 'application/csv' : 'text/csv'
    const blob = new Blob([config.uFEFF ? '\uFEFF' : '', csv], { type })
    return blob
}