import ServiceEquipmentSelection from './Equipment/ServiceEquipmentSelection'
import ServiceEquipmentBid from './Equipment/ServiceEquipmentBid'

import ServiceMobilityHelpSelection from './MobilityHelp/ServiceMobilityHelpSelection'
import ServiceMobilityHelpBid from './MobilityHelp/ServiceMobilityHelpBid'

import ServiceTechnicalSelection from './Technical/ServiceTechnicalSelection'
import ServiceTechnicalBid from './Technical/ServiceTechnicalBid'
import AdviceAndSupportSelection from './AdviceAndSupport/AdviceAndSupportSelection'
import AdviceAndSupportBid from './AdviceAndSupport/AdviceAndSupportBid'
import { privateRoutes } from 'router/routes'
import { REQUEST_EQUIPMENTS_VALUE, REQUEST_MOBILITY_VALUE, REQUEST_SUPPORT_VALUE, REQUEST_TECHNICAL_VALUE } from 'utils/domain/request'

import Prestations_equipements from 'assets/images/Equipements-de-stationnement.jpg'
import Remise_en_selle from 'assets/images/Formations-pratique-quotidien.jpg'
import Aide_preparation_audi from 'assets/images/Conseil-accompagnement.jpg'
import Atelier_participatif from 'assets/images/Prestations-maintenance-reparations.jpg'

const CatalogRoutes = [
    {
        label: 'Equipements de stationnement ',
        bidLabel: 'Demande de devis',
        url: 'prestation-equipement',
        monitoringUrl: privateRoutes.monitoringActivities.benefits.equipment.path,
        componentSelection: ServiceEquipmentSelection,
        componentBid: ServiceEquipmentBid,
        thumbnail: {
            label: 'Equipements de stationnement ',
            description: [
                'La présence de places de stationnement vélo est déterminante pour vos salariés et visiteurs qui viennent à vélo : offrez-leur un équipement sécurisé et de qualité.', 'Un bon stationnement vélo leur permettra de laisser sereinement leur vélo, que ce soit pour quelques heures ou une journée. '
            ],
            picture: Prestations_equipements
        },
        gender: REQUEST_EQUIPMENTS_VALUE
    },
    {
        label: 'Formations à la pratique du vélo',
        bidLabel: 'Demande de prestation',
        url: 'prestation-formation-velo-au-quotidien',
        monitoringUrl: privateRoutes.monitoringActivities.benefits.mobility.path,
        componentSelection: ServiceMobilityHelpSelection,
        componentBid: ServiceMobilityHelpBid,
        thumbnail: {
            label: 'Formations à la pratique du vélo',
            description: [
                'Les formations à la pratique du vélo au quotidien renforcent la prévention des risques inhérents à la pratique de la mobilité vélo. Ils intègrent le plan de formation de l’entreprise.', 
                'Les prestations sont réalisées par des moniteurs expérimentés. L’accompagnement proposé est adapté aux capacités du bénéficiaire en matière de mobilité vélo.'
            ],
            picture: Remise_en_selle
        },
        gender: REQUEST_MOBILITY_VALUE
    },
    {
        label: 'Prestations de maintenance et de réparations',
        bidLabel: 'Demande de prestation',
        url: 'prestation-maintenance-reparation',
        monitoringUrl: privateRoutes.monitoringActivities.benefits.technical.path,
        componentSelection: ServiceTechnicalSelection,
        componentBid: ServiceTechnicalBid,
        thumbnail: {
            label: 'Prestations de maintenance et de réparations',
            description: [
                "Un vélo bien entretenu est une garantie supplémentaire de la sécurité de vos salariés lors de leurs déplacements à vélo. La maintenance d'un vélo est aussi facteur d'un meilleur confort d'utilisation.", 'Ces prestations de maintenance et de réparations sont des prestations d’entretien de maintenance de flottes de vélos, ou du vélo personnel de vos salariés.'
            ],
            picture: Atelier_participatif
        },
        gender: REQUEST_TECHNICAL_VALUE
    },
    {
        label: 'Conseil et accompagnement',
        bidLabel: 'Demande de prestation',
        url: 'accompagnement-et-conseils',
        monitoringUrl: privateRoutes.monitoringActivities.benefits.adviceAndSupport.path,
        componentSelection: AdviceAndSupportSelection,
        componentBid: AdviceAndSupportBid,
        thumbnail: {
            label: 'Conseil et accompagnement',
            description: [
                "Vous êtes convaincu des bénéfices du vélo pour votre organisation mais ne savez pas comment mettre en oeuvre un plan d'actions pro-vélo efficace, et sécurisant pour vos salariés. Les prestations \"conseil\" de ce catalogue visent à renforcer votre stratégie vélo, faire monter en compétence votre référent.e vélo et/ou vous préparer à la labellisation Employeur Pro-vélo. Le label est à portée de clic, alors n'hésitez plus."
            ],
            picture: Aide_preparation_audi
        },
        gender: REQUEST_SUPPORT_VALUE
    }
]

export default CatalogRoutes