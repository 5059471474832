import React from 'react'

import { useAlert } from 'hooks/useAlert'
import { privateRoutes } from 'router/routes'

import dateService from 'services/date'
import * as AuditApi from 'services/api/audit/auditApi'

import { getColor } from 'utils/styles'
import {
    AUDIT_CERTIFICATION_DOC_GENDER,
    AUDIT_REPORT_DOC_GENDER,
    AUDIT_CGV_DOC_GENDER,
    getLabelFromAuditStatus, isAskedAuditStatus, isCanceledAuditStatus,
    isOrderedAuditStatus, isPaidAuditStatus, isRealizedAuditStatus
} from 'utils/domain/audit'
import certificationUtil, { WAITING_CERTIFICATION } from 'utils/domain/certification'
import { exportXlsx } from 'utils/file'
import { isInZNIList } from 'utils/domain/beneficiary'
import { stringSorter } from 'utils/sort'
import { useModal } from 'hooks/useModal'

import Card from 'components/platform/Card'
import Table from 'components/shared/Table'
import DownloadIcon from 'components/svg/Download'
import { UserDetail } from 'components/back-office/UsersInformations/Detail'
import CalendarIcon from 'components/svg/Calendar'
import ImportDownloadDocumentCell from './cells/importDownloadDocument'
import ModalPlanning from 'components/shared/ModalPlanning'
import StatusCell from './cells/status'
import CertificationCell from './cells/certificationLevel'

const columnsToExport = {
    exportCreateDate: 'Date de la demande',
    exportStructureName: 'Raison sociale',
    exportStructureAddress: 'Adresse',
    exportStructureCity: 'Ville',
    exportStructureMail: 'Email',
    exportAuditCGV: 'CGV envoyées',
    exportAuditStatus: 'Statut de l\'audit',
    exportAuditOrderedDate: 'Date de commande de l\'audit',
    exportAuditDate: 'Date de réalisation de l\'audit',
    exportAuditReportDocument: 'Rapport d\'audit envoyé',
    exportAuditCertification: 'Niveau de label',
    exportAuditCertificationDocument: 'Attestation envoyée',
    exportIsZNI: 'Structure en ZNI'
}

const MyAuditsPage = () => {

    const [myAudits, setMyAudits] = React.useState([])
    const { showModal, hideModal } = useModal()
    const { addAlert } = useAlert()

    React.useEffect(() => {
        fetchMyAudits()
    }, [])

    const addExportPropertiesToAuditObj = (auditsMapped) => {
        return auditsMapped.map(audit => {

            const hasAuditDocumentByType = (audit, docType) => {
                const isDocument = document => document?.media_gender === docType
                return audit.audit_media?.documents.some(isDocument)
            }

            // used to export audits .xls file
            const exportProperties = {
                exportCreateDate: dateService.formatDate(audit?.create_date),
                exportStructureName: audit?.entity_data?.structureName,
                exportStructureAddress: audit?.entity_data?.structureAddress,
                exportStructureCity: audit?.entity_data?.structureCity,
                exportStructureMail: audit?.entity_data?.mail,
                exportAuditCGV: hasAuditDocumentByType(audit, AUDIT_CGV_DOC_GENDER) ? 'Oui' : 'Non',
                exportAuditStatus: getLabelFromAuditStatus(audit?.audit_status),
                exportAuditOrderedDate: audit?.planned_date ? dateService.formatDate(audit?.planned_date) : '',
                exportAuditDate: audit?.audit_date ? dateService.formatDate(audit?.audit_date) : '',
                exportAuditReportDocument: hasAuditDocumentByType(audit, AUDIT_REPORT_DOC_GENDER) ? 'Oui' : 'Non',
                exportAuditCertification: certificationUtil.getLabelByCertificationLevel(audit?.audit_label),
                exportAuditCertificationDocument: hasAuditDocumentByType(audit, AUDIT_CERTIFICATION_DOC_GENDER) ? 'Oui' : 'Non',
                exportIsZNI: isInZNIList(audit?.entity_data?.structurePostCode) ? 'Oui' : 'Non'
            }
            return Object.assign(audit, exportProperties)
        })
    }

    const fetchMyAudits = async () => {
        try {
            const { res: audits, getError } = await AuditApi.getMyAudits()
            if (getError()) throw getError()
            if (!Array.isArray(audits)) setMyAudits([])

            const auditsMapped = addExportPropertiesToAuditObj(audits)

            setMyAudits(auditsMapped)
        } catch (error) {
            addAlert('error', error?.toString())
        }
    }

    const updateAudit = async (auditId, auditPayload) => {
        try {
            const { getError } = await AuditApi.updateMyAudit(auditId, auditPayload)
            if (getError()) throw getError()
            fetchMyAudits()
        } catch (error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }

    const handleCalendarModalOrdered = (cell) => {
        const audit = cell.row.original
        const auditStatus = audit?.audit_status
        const entityId = audit?.entity_id
        const auditId = audit?.audit_id
        const auditToUpdate = {
            entity_id: entityId,
            audit_status: auditStatus,
            audit_label: WAITING_CERTIFICATION, // waiting certification is set by default (the auditor could change it later)
        }
        showModal(ModalPlanning,
            {
                title: 'Veuillez indiquer la date de planification de l\'audit.',
                validation: async (date) => {
                    const formattedDate = dateService.formatDate(date, 'yyyy-MM-dd HH:mm:ss')
                    const auditToUpdateWithDate = {
                        ...auditToUpdate,
                        audit_planned_date: formattedDate
                    }
                    await updateAudit(auditId, auditToUpdateWithDate)
                    hideModal()
                },
                audit: audit,
                minDate: dateService.getCurrentDate('y-MM-dd')
            },
            { isClosable: false }
        )
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Date de la demande',
                accessor: 'create_date',
                sortType: (a, b) => {
                    return new Date(b.original.create_date) - new Date(a.original.create_date)
                },
                Cell: ({ cell }) => dateService.formatDate(cell.row.original.create_date)
            },
            {
                Header: 'Raison sociale',
                accessor: 'entity_data.structureName',
                sortType: (a, b) => {
                    const nameA = a.original.entity_data.structureName
                    const nameB = b.original.entity_data.structureName
                    return stringSorter(nameA, nameB)
                }
            },
            {
                Header: () => <span className="u-break-spaces">Adresse</span>,
                accessor: 'entity_data.structureAddress',
                Cell: ({ cell }) => cell.row.original.entity_data?.structureAddress
            },
            {
                Header: 'Ville',
                accessor: 'entity_data.structureCity',
            },
            {
                Header: 'Email',
                accessor: 'entity_data.mail',
                sortType: (a, b) => {
                    const mailA = a.original.entity_data.mail
                    const mailB = b.original.entity_data.mail
                    return stringSorter(mailA, mailB)
                }
            },
            {
                Header: () => <span className="u-break-spaces">Accéder à la fiche bénéficiaire</span>,
                accessor: 'entity_id',
                hideSortIcon: true,
                Cell: ({ cell }) => {
                    return <UserDetail userId={cell.value} route={privateRoutes.auditedBeneficiaryDetails} target={'_blank'} />
                },
            },
            {
                Header: () => <span className="u-break-spaces">Importer/télécharger CGV</span>,
                accessor: 'audit_media.documents',
                hideSortIcon: true,
                sortType: (a, b) => {
                    const cgvA = !!a.original?.audit_media?.documents.find(document => document.media_gender === AUDIT_CGV_DOC_GENDER)
                    const cgvB = !!b.original?.audit_media?.documents.find(document => document.media_gender === AUDIT_CGV_DOC_GENDER)
                    return cgvA - cgvB
                },
                Cell: ({ cell }) => {
                    const cellStatus = cell.row.original?.audit_status
                    const cellParams = {
                        ...cell,
                        isEnable: true,
                        documentType: 'cgv',
                        mediaGender: AUDIT_CGV_DOC_GENDER
                    }
                    return ImportDownloadDocumentCell(cellParams, updateAudit)
                },
            },
            {
                Header: 'Statut de l\'audit',
                accessor: 'audit_status',
                updateData: updateAudit,
                Cell: StatusCell
            },
            {
                Header: 'Date de l\'audit commandé',
                accessor: 'planned_date',
                sortType: (a, b) => {
                    const dateA = a.original?.planned_date
                    const dateB = b.original?.planned_date
                    return new Date(dateB) - new Date(dateA)
                },
                Cell: ({ cell, value }) => {
                    const audit = cell.row.original
                    const cellStatus = audit?.audit_status
                    const dateToDisplay = value && !isCanceledAuditStatus(cellStatus) ? dateService.formatDate(value) : ''
                    return (
                        <div className={'u-flex u-flex-center-vt'}>
                            {isOrderedAuditStatus(cellStatus) &&
                                <button
                                    type="button"
                                    aria-label="Télécharger le document"
                                    onClick={() => handleCalendarModalOrdered(cell)}
                                    className='u-mg-left-m'
                                ><CalendarIcon className="vertical-align" color={getColor().primary} />
                                </button>
                            }
                            <p className='u-mg-left-m'>{dateToDisplay}</p>
                        </div>
                    )
                }
            },
            {
                Header: 'Date de l\'audit réalisé',
                accessor: 'audit_date',
                sortType: (a, b) => {
                    const dateA = a.original?.audit_date
                    const dateB = b.original?.audit_date
                    return new Date(dateB) - new Date(dateA)
                },
                Cell: ({ value }) => {
                    const dateToDisplay = value ? dateService.formatDate(value) : ''
                    return (
                        <p> {dateToDisplay}</p >
                    )
                }
            },
            {
                Header: () => <span className="u-break-spaces">Importer/télécharger un rapport d'audit</span>,
                accessor: 'audit_media.documents[0]',
                sortType: (a, b) => {
                    const certificatA = !!a.original?.audit_media?.documents.find(document => document.media_gender === AUDIT_REPORT_DOC_GENDER)
                    const certificatB = !!b.original?.audit_media?.documents.find(document => document.media_gender === AUDIT_REPORT_DOC_GENDER)
                    return certificatA - certificatB
                },
                Cell: ({ cell }) => {
                    const cellStatus = parseInt(cell.row.original?.audit_status)
                    const cellParams = {
                        ...cell,
                        isEnable: isRealizedAuditStatus(cellStatus) || isPaidAuditStatus(cellStatus),
                        media: cell.row.original?.audit_media?.documents.find(document => document.media_gender === AUDIT_REPORT_DOC_GENDER),
                        documentType: 'audit',
                        mediaGender: AUDIT_REPORT_DOC_GENDER
                    }
                    return ImportDownloadDocumentCell(cellParams, updateAudit)
                },
            },
            {
                Header: () => <span className="u-break-spaces">Indiquer un niveau de label</span>,
                accessor: 'audit_label',
                Cell: ({ cell, value }) => {
                    const cellParams = {
                        ...cell,
                        isEnable: cell.row.original?.audit_media?.documents.some(document => document.media_gender === AUDIT_REPORT_DOC_GENDER),
                    }
                    return CertificationCell(cellParams, value, updateAudit)
                },
            },
            {
                Header: () => <span className="u-break-spaces">Importer/télécharger l'attestation</span>,
                accessor: 'audit_media.documents[1]',
                sortType: (a, b) => {
                    const certificatA = !!a.original?.audit_media?.documents.find(document => document.media_gender === AUDIT_CERTIFICATION_DOC_GENDER)
                    const certificatB = !!b.original?.audit_media?.documents.find(document => document.media_gender === AUDIT_CERTIFICATION_DOC_GENDER)
                    return certificatA - certificatB
                },
                Cell: ({ cell }) => {
                    const cellParams = {
                        ...cell,
                        isEnable: cell.row.original?.audit_media?.documents.some(document => document.media_gender === AUDIT_REPORT_DOC_GENDER),
                        media: cell.row.original?.audit_media?.documents.find(document => document.media_gender === AUDIT_CERTIFICATION_DOC_GENDER),
                        documentType: 'certification',
                        mediaGender: AUDIT_CERTIFICATION_DOC_GENDER
                    }
                    return ImportDownloadDocumentCell(cellParams, updateAudit)
                },
            },
        ],
        [myAudits]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='u-mg-hz-xxl u-mg-bottom-l my-audits'>
            <h1 className="c-h1 u-secondary u-bold u-center u-mg-top-l u-mg-bottom-l u-uppercase">
                Mes audits
            </h1>
            <div className="u-flex u-flex-between u-flex-center-vt">
                <p className="u-primary u-bold u-mg-bottom-xl">Retrouver tous mes audits :</p>
                <button
                    type="button"
                    className="c-btn c-btn--primary u-pd-hz-xl u-mg-m u-flex-self-end u-text-transform-none"
                    onClick={() => exportXlsx(columnsToExport, myAudits, 'export-mes-audits')}
                >
                    <DownloadIcon color={getColor().white} />
                    <span className='u-mg-left-s'>Exporter mes audits</span>
                </button>
            </div>
            <Card className="u-pd-top-xl u-mg-bottom-l fixedfirstcolumns">
                <Table columns={columns} data={myAudits} specificSortBy={[{ id: 'audit_status', asc: true }, { id: 'entity_data.structureName', asc: true }]} isPaginable={true} />
            </Card>
        </div>
    )
}

export default MyAuditsPage