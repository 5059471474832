import React from 'react'
import PublicLayout from 'layouts/Public'


const PolicyPage = () => {
    return (
        <PublicLayout showHeader={true} className="u-pd-m u-pd-hz-0@main u-pd-vt-xl@main">
            <div className="l-container">
                <h1 className="c-h1 u-bold u-primary u-mg-bottom-l">Mentions légales</h1>
                <div className="l-container-lg">
                    <div className="u-mg-m">
                        <h2 className="c-h2 u-bold u-mg-bottom-l">Présentation de la plateforme :</h2>
                        <p>
                            En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs de la plateforme <a className="u-primary" href="http://app-employeurprovelo.fr">http://app-employeurprovelo.fr</a> l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :
                        </p>
                        <ul className="u-pd-left-m">
                            <li className="u-mg-l">
                                <span className="u-bold">Propriétaire</span> : FUB Services – Société par actions simplifiée à associé unique au capital de 5 000 € – Fédération française des Usagers de la Bicyclette, 12 rue Finkmatt, 67000 Strasbourg,<br />
                                Numéro de téléphone : +33 (0)3 88 75 71 90 <br />
                                Email : <a className="u-primary" href="mailto:contact@employeurprovelo.fr">contact@employeurprovelo.fr</a>
                            </li>
                            <li className="u-mg-l">
                                <span className="u-bold">Créateur</span> : OCODE, Société par actions simplifiée, immatriculée au Registre du Commerce et des Sociétés de La Roche-Sur-Yon sous le numéro 822 879 128, dont le siège social est situé́ 322 B route du Puy Charpentreau, 85000 LA ROCHE-SUR-YON au capital social de 90 900 €. Numéro de TVA intracommunautaire : FR06822879128. <br />
                                Email : <a className="u-primary" href="mailto:bonjour@o-code.co">bonjour@o-code.co</a><br />
                                Numéro de téléphone : +33 (0)2 79 93 60 10
                            </li>
                            <li className="u-mg-l">
                                <span className="u-bold">Créateur</span> : OCODE, Société par actions simplifiée, immatriculée au Registre du Commerce et des Sociétés de La Roche-Sur-Yon sous le numéro 822 879 128, dont le siège social est situé́ 322 B route du Puy Charpentreau, 85000 LA ROCHE-SUR-YON au capital social de 90 900 €. Numéro de TVA intracommunautaire : FR06822879128. <br />
                                Email : <a className="u-primary" href="mailto:bonjour@o-code.co">bonjour@o-code.co</a> <br />
                                Numéro de téléphone : +33 (0)2 79 93 60 10
                            </li>
                            <li className="u-mg-l">
                                <span className="u-bold">Responsable publication</span> : FUB Services – Société par actions simplifiée à associé unique au capital de 5 000 € – Fédération française des Usagers de la Bicyclette, 12 rue Finkmatt, 67000 Strasbourg,<br />
                                Email : <a className="u-primary" href="mailto:contact@employeurprovelo.fr">contact@employeurprovelo.fr</a> <br />
                                Numéro de téléphone : +33 (0)3 88 75 71 90.
                            </li>
                            <li className="u-mg-l">
                                <span className="u-bold">Hébergeur</span> :  La plateforme est hébergée par la sociétéOcode SAS - 322 bis route du Puy Charpentreau 85 000 La Roche-sur-Yon.<br />
                                Email : <a className="u-primary" href="mailto:info@o-code.co">info@o-code.co</a>
                            </li>
                        </ul>
                    </div>
                    <div className="u-mg-m">
                        <h2 className="c-h2 u-bold u-mg-bottom-l">Mention « Copyright et droits de propriété intellectuelle » :</h2>
                        <p>
                            FUB Services est propriétaire des droits de propriété intellectuelle ou détient les droits
                            d’usage sur tous les éléments accessibles sur la plateforme, notamment les textes, images,
                            graphismes, logos, icônes, sons, logiciels.
                            Toute reproduction, représentation, modification, publication, adaptation de tout ou partie
                            des éléments de la plateforme, quel que soit le moyen ou le procédé utilisé, est interdite,
                            sauf autorisation écrite préalable de FUB Services.
                            Toute exploitation non autorisée de la plateforme ou d’un quelconque élément qu’il contient
                            sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux
                            dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                        </p>
                    </div>
                    <div className="u-mg-m">
                        <h2 className="c-h2 u-bold u-mg-bottom-l">Mentions relatives à la gestion de vos données personnelles :</h2>
                        <p>
                            Les informations recueillies dans le cadre de l’utilisation de la plateforme font l’objet d’un
                            traitement informatique uniquement destiné à la fourniture d’une réponse à votre demande
                            dans un cadre professionnel. Pour en savoir plus sur la gestion de vos données personnelles
                            et pour exercer vos droits, contactez le Programme par email à l’adresse suivante : <a className="u-primary" href="mailto:contact@objectifemployeurprovelo.fr">contact@objectifemployeurprovelo.fr</a>
                        </p>
                    </div>
                    <div className="u-mg-m">
                        <h2 className="c-h2 u-bold u-mg-bottom-l">Limitations contractuelles sur les données techniques :</h2>
                        <p>
                            La plateforme web responsive utilise la technologie ReactJS et sa base de données est sous blockchain.
                            La plateforme ne pourra être tenu responsable de dommages matériels liés à son utilisation.
                            De plus, l’utilisateur de la plateforme s’engage à accéder à la plateforme en utilisant un
                            matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.
                        </p>
                    </div>
                    <div className="u-mg-m">
                        <h2 className="c-h2 u-bold u-mg-bottom-l">Limitations de responsabilité :</h2>
                        <p>
                            FUB Services ne pourra être tenue responsable des dommages directs et indirects causés au
                            matériel de l’utilisateur, lors de l’accès à la plateforme, et résultant soit de l’utilisation d’un
                            matériel ne répondant pas aux spécifications indiquées au point « Limitations contractuelles
                            sur les données techniques », soit de l’apparition d’un bug ou d’une incompatibilité.
                            FUB Services ne pourra également être tenue responsable des dommages indirects (tels par
                            exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation de la plateforme.
                        </p>
                    </div>
                    <div className="u-mg-m">
                        <h2 className="c-h2 u-bold u-mg-bottom-l">Droit applicable et attribution de juridiction :</h2>
                        <p>
                            Tout litige en relation avec l’utilisation du site www.employeurprovelo.fr est soumis au droit
                            français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.
                        </p>
                    </div>
                </div>

            </div>
        </PublicLayout>
    )
}

export default PolicyPage
