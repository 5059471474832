import React from 'react'
import ProtectedLayout from 'layouts/Protected'

const ErrorPage = () => {
    return (
        <ProtectedLayout>
            <div className="u-flex u-flex-center-hz u-pd-vt-xl">
                <div className="l-form-width">
                    <p className="">Une erreur est survenue, veuillez ...</p>
                </div>
            </div>
        </ProtectedLayout>
    )
}

export default ErrorPage
