import React from 'react'
import ProtectedLayout from 'layouts/Protected'
import FormUploadData from 'components/admin/FormUploadData'
import { useAuth } from 'hooks/useAuth'
import { entityGenderList } from 'utils/constants'
import FormDownloadData from 'components/admin/FormDownloadData'

const AdminHomePage = () => {
    const { user, logout } = useAuth()
    React.useEffect(() => {
        // Be sure only the admin can access this view
        if (!(user?.entity_data?.gender === entityGenderList.find(e => e.id === 'admin').value)) {
            logout()
        }
    }, [user]) // eslint-disable-line
    return (
        <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main">
            <div className="l-container">
                <h1 className="c-h1 u-secondary u-center u-mg-bottom-xl">Dashboard Administrateur</h1>

                <section className="u-flex u-flex-dir-col u-flex-start-vt u-mg-top-l">
                    <h2 className="c-h2 u-primary u-center u-mg-bottom-xl">Télécharger des données</h2>
                    <FormDownloadData />
                </section>
                <section className="u-mg-top-xl">
                    <h2 className="c-h2 u-primary u-center u-mg-bottom-xl">Téléverser des données</h2>
                    <FormUploadData />
                </section>
            </div>
        </ProtectedLayout>
    )
}

export default AdminHomePage
