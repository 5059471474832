import { AUTODIAG_STATUS_DONE } from 'pages/protected/Autodiag/context'
import autodiagFile from 'pages/protected/Autodiag/autodiagnostic.xlsx'
import dateService from 'services/date'
import XLSX from 'xlsx'

export const STEP_0 = 'introduction'
export const STEP_1 = 'pilotage-strategie'
export const STEP_2 = 'communication-motivation'
export const STEP_3 = 'services'
export const STEP_4 = 'securite'
export const STEP_5 = 'equipements'
export const STEP_6 = 'restitution'

const START_ROW = 2

const REQUIREMENT_MET = 'OK'
const POINT_OF_CAUTION = 'NOK'
const REQUIREMENT_NOT_MET = 'KO'

export const MANDATORY_REQUIREMENT = 'O'
export const OPTIONAL_REQUIREMENT = 'F'

const MULTIPLE_QUESTION_TYPE = 'Choix multiple'
const SINGLE_QUESTION_TYPE = 'Choix unique'

const autodiagService = {

    sortAutoDiagsById(autoDiagA, autoDiagB) {
        return autoDiagB.diag_id - autoDiagA.diag_id
    },

    getLastAutoDiagCompletedFromAutodiags(autodiags) {
        if(autodiags.length) {
            return autodiags.sort(autodiagService.sortAutoDiagsById)
                .find(({ diag_status }) => diag_status === AUTODIAG_STATUS_DONE)
        }
        
        return null
    },

    getFilename(structureName) {
        const structureNameFormatted = structureName
                            .trim().toLowerCase()
                            .replace(/[^a-zA-Z0-9- ]/g, '') // remove special chars
                            .replace(/\s+/g, '-') // replaces spaces by hyphens
                            .normalize('NFD').replace(/\p{Diacritic}/gu, '') // replaces accented chars by unaccented chars
    
        const currentDate = dateService.getCurrentDate('dd-MM-y')
        return`restitution-autodiag-${structureNameFormatted}-${currentDate}`
    },

    getRestitutionScore(questions) {
        let mandatoryRequirementMetScore = 0
        let optionalRequirementMetScore = 0
        let requirementMetScore = 0
        let pointOfCautionScore = 0
        let requirementNotMetScore = 0
        let nbrOptional = 0
        let nbrMandatory = 0
        
        const reportAttributs = [
            'response_a',
            'response_b',
            'response_c',
            'response_d'
        ]
    
        const getResponsesToCheck = (requirement, question) => reportAttributs
                .filter(key => question[`${key}_report`] === requirement)
    
        Object.values(questions).flat().forEach(question => {
            const responseToCheckRequirement = getResponsesToCheck(REQUIREMENT_MET, question)
            const requirementConditionMet = responseToCheckRequirement.every(
                response => question.userResponses?.includes(response)
            )
    
            if(requirementConditionMet) {
                requirementMetScore++
            }
    
            if(requirementConditionMet && question.requirement_type === MANDATORY_REQUIREMENT) {
                mandatoryRequirementMetScore++
            }
    
            if(requirementConditionMet && question.requirement_type === OPTIONAL_REQUIREMENT) {
                optionalRequirementMetScore++
            }
    
            const responseToCheckPointOfCaution = getResponsesToCheck(POINT_OF_CAUTION, question)
            const pointOfCautionConditionMet = responseToCheckPointOfCaution.some(
                response => question.userResponses?.includes(response)
            )
    
            if(pointOfCautionConditionMet) {
                pointOfCautionScore++
            }
    
            const responseToCheckRequirementNotMet = getResponsesToCheck(REQUIREMENT_NOT_MET, question)
            const requirementNotMetConditionMet = responseToCheckRequirementNotMet.some(
                response => question.userResponses?.includes(response)
            )
    
            if(requirementNotMetConditionMet) {
                requirementNotMetScore++
            }
    
            if(question.requirement_type === OPTIONAL_REQUIREMENT && question.userResponses) {
                nbrOptional++
            }
    
            if(question.requirement_type === MANDATORY_REQUIREMENT && question.userResponses) {
                nbrMandatory++
            }
    
        })
        return { 
            mandatoryRequirementMetScore, 
            optionalRequirementMetScore,
            requirementMetScore, 
            pointOfCautionScore, 
            requirementNotMetScore,
            nbrOptional,
            nbrMandatory,
        }
    },

    getTotalRespOptionnalStep(questionSteps) {
        const score = autodiagService.getRestitutionScore(questionSteps)
        return `${autodiagService.getRespTotalOptional(questionSteps)}/${score.nbrOptional}`
    },

    getTotalRespMandatoryStep(questionSteps) {
        const score = autodiagService.getRestitutionScore(questionSteps)
        return `${score.mandatoryRequirementMetScore}/${score.nbrMandatory}`
    },

    getRespTotalOptional(questionSteps) {
        let respTotalOptional = 0
        questionSteps.filter(quest => quest.userResponses?.length).forEach(question => {
            if(question.question_type === SINGLE_QUESTION_TYPE) {
                question.userResponses.forEach(response => {
                    if (question.requirement_type === OPTIONAL_REQUIREMENT && question[`${response}_report`] === 'OK') {
                        respTotalOptional++
                    }
                })
            }
            if(question.question_type === MULTIPLE_QUESTION_TYPE) {
                let atLeastOneResponseIsOk = false
                question.userResponses.forEach(response => {
                    if (question.requirement_type === OPTIONAL_REQUIREMENT && question[`${response}_report`] === 'OK') {
                        return atLeastOneResponseIsOk = true
                    }
                })
                atLeastOneResponseIsOk && respTotalOptional++
            }
        })
    
        return respTotalOptional
    },

    getTotalRespOptional(questions) {
        let respTotalOptional = 0
        Object.entries(questions).forEach(([, questionSteps]) => (
            respTotalOptional += autodiagService.getRespTotalOptional(questionSteps)
        ))
        return respTotalOptional
    },

    formatQuestionsStepFromXLSX(questions) { return questions.slice(START_ROW).map(question => ({ ...question, userResponses: [] })) },
    
    async importAutodiagFile() {
        const responseFile = await fetch(autodiagFile)
        const blobFile = await responseFile.blob()
         
        const wb = XLSX.read(await blobFile.arrayBuffer(), { type: 'array' })

        const strategyWS = wb.Sheets[wb.SheetNames[1]]
        const communicationWS = wb.Sheets[wb.SheetNames[2]]
        const servicesWS = wb.Sheets[wb.SheetNames[3]]
        const securityWS = wb.Sheets[wb.SheetNames[4]]
        const equipmentsWS = wb.Sheets[wb.SheetNames[5]]

        const header = [
            'num_question', 
            'question', 
            'conditional_question', 
            'question_type', 
            'comment', 
            'response_a',
            'response_b',
            'response_c',
            'response_d', 
            'response_a_report',
            'response_b_report',
            'response_c_report',
            'response_d_report',
            'requirement_type',
            'response_a_explanation',
            'response_b_explanation',
            'response_c_explanation',
            'response_d_explanation'
        ]
 
        const options = { defval: null, header }

        const scoreExplanationsWS = wb.Sheets[wb.SheetNames[6]]
        const scoreExplanationsOptions = { defval: null, header: ['score', 'explanation'] }

        try {
            const strategy = XLSX.utils.sheet_to_json(strategyWS, options)
            const communication = XLSX.utils.sheet_to_json(communicationWS, options)
            const services = XLSX.utils.sheet_to_json(servicesWS, options)
            const security = XLSX.utils.sheet_to_json(securityWS, options)
            const equipments = XLSX.utils.sheet_to_json(equipmentsWS, options)

            const explanations = XLSX.utils.sheet_to_json(scoreExplanationsWS, scoreExplanationsOptions)

            const result = {
                [STEP_1]: autodiagService.formatQuestionsStepFromXLSX(strategy),
                [STEP_2]: autodiagService.formatQuestionsStepFromXLSX(communication),
                [STEP_3]: autodiagService.formatQuestionsStepFromXLSX(services),
                [STEP_4]: autodiagService.formatQuestionsStepFromXLSX(security),
                [STEP_5]: autodiagService.formatQuestionsStepFromXLSX(equipments),
            }

            return {
                result,
                explanations: explanations.slice(13, 17)
            }
        } catch (error) {
            const errorMessage = 'Une erreur est survenue lors de la récupération des questions'
            console.error(errorMessage, error)
            throw errorMessage
        }    
    }

}

export default autodiagService