import React from 'react'
import { useAuth } from 'hooks/useAuth'
import Header from 'components/Header'
import Footer from 'components/Footer'
import CheckBeneficiaryAccess from 'components/platform/CheckBeneficiaryAccess'

const ProtectedLayout = ({ children, className, showHeader = true, showFooter = true }) => {
    const { isLoggedIn, isABeneficiaryUser } = useAuth()
    return (
        <div className={
            'u-sticky-view '
            + (showHeader ? 'u-pd-top-navbar ' : '')
            + (isLoggedIn ? 'is-connected ' : '')}>
            
            {isABeneficiaryUser && <CheckBeneficiaryAccess />}

            { showHeader && <Header /> }

            <main className={'c-main-content u-pd-hz-m ' + (className || '')}>
                { children }
            </main>

            {showFooter && <Footer /> }
        </div>
    )
}

export default ProtectedLayout
