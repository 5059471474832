import React from 'react'
import { array, number, shape, string } from 'prop-types'

import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'
import Card from 'components/platform/Card'

import certificationUtil, { NO_CERTIFICATION } from 'utils/domain/certification'

const Certification = ({ audit, className }) => {

    const certificationLevel = audit?.audit_label
    const certificationDocumentUrl = audit?.audit_media?.documents?.find(document => document.media_gender === 'LABEL_AUDIT')?.full_media_url

    const handleLabelToDisplay = () => {
        if(certificationLevel === NO_CERTIFICATION) return 'Pas de label obtenu'
        return `NIVEAU ${certificationUtil.getLabelByCertificationLevel(certificationLevel)?.toUpperCase()}`
    }

    return (
        <Card title="Labellisation" className={`${className}`}>
            { !Number.isInteger(certificationLevel) &&
                <div className="u-grey90 u-fs-xs u-mg-top-s">
                    La labellisation n'a pas été faite.
                </div>
            }
            { Number.isInteger(certificationLevel) && 
                <div className="l-grid">
                <div className="l-col-4">
                    { certificationUtil.getSVGByCertificationLevel(certificationLevel) }
                </div>
                <div className="l-col-4 u-center u-flex-self-center">
                    <h4 className="c-h4 u-bold u-primary">
                        {handleLabelToDisplay()}
                    </h4>
                </div>
                { certificationDocumentUrl &&
                    <div className="l-col-4 u-flex-self-center">
                        <InformationToVisualize
                            label="Consulter le certificat" 
                            url={certificationDocumentUrl} 
                            iconColor="green"
                            asCol
                        />
                    </div>
                }
            </div>
            }
        </Card> 
    )
}

Certification.propTypes = {
    audit: shape({
        audit_label: number,
        audit_media: shape({
            documents: array
        })
    }),
    className: string,
  }

export default Certification
