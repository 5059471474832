import React from 'react'
import { NavLink } from 'react-router-dom'

import PublicLayout from 'layouts/Public'
import FormForgotPassword from 'components/auth/FormForgotPassword'
import RegisterBlock from 'components/auth/RegisterBlock'
import Logo from 'components/LogoOEPV'

import { publicRoutes } from 'router/routes'
import useBackOfficeRoutes from 'hooks/useBackOfficeRoutes'
import illustration from 'assets/images/cycliste-signin.png'

const LOGO_SIZE = 120

const ForgotPasswordPage = () => {
    const { isCurrentRouteBackOffice } = useBackOfficeRoutes()
    const loginPath = isCurrentRouteBackOffice ? publicRoutes.backOffice.login.path : publicRoutes.login.path

    return (
        <PublicLayout fixedLayout={true}>
            <div className="u-relative l-grid u-flex-1">
                <div className="l-col-12 l-col-8@main u-pd-bottom-l u-pd-hz-m u-pd-hz-0@main u-relative u-zi-1" style={{ paddingTop: `${LOGO_SIZE + 60}px` }}>

                    <div className="u-fixed u-top-0 u-left-0 u-pd-m u-mg-m@main u-mg-0">
                        <Logo style={{ height: `${LOGO_SIZE}px`, width: 'auto' }} />
                    </div>

                    <div className="l-form-width u-mg-hz-auto u-bg-white">
                        <h1 className="c-h1 u-bold u-secondary u-center u-mg-bottom-m">Mot de passe oublié ?</h1>
                        <NavLink to={loginPath} className="u-block u-fs-xs u-center u-underline u-mg-bottom-l">Mot de passe retrouvé ?</NavLink>
                    </div>

                    <div className="l-form-width u-mg-hz-auto u-pd-bottom-l u-pd-m u-bg-white">
                        <p className="u-mg-bottom-m">Pour pouvoir changer votre mot de passe nous allons vous envoyer un mail avec un code. Après l'avoir saisi vous devrez entrer un nouveau mot de passe.</p>
                        <p className="u-mg-bottom-m">Ensuite vous pourrez vous connecter à l'aide du nouveau mot de passe défini.</p>
                    </div>
                    <div className="l-form-width u-mg-hz-auto u-bg-white u-pd-m">
                        <FormForgotPassword successRedirection={loginPath} />
                    </div>
                </div>

                <div className="u-absolute u-bottom-0 u-left-0 u-hide u-block@main">
                    <img alt="" src={illustration} />
                </div>

                {!isCurrentRouteBackOffice && (
                    <div className="l-col-12 l-col-4@main u-bg-primary u-pd-l u-pd-xxl@main">
                        <RegisterBlock className="u-white" />
                    </div>
                )}
            </div>

        </PublicLayout>
    )
}

export default ForgotPasswordPage

