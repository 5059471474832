import React from 'react'
import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import CatalogRoutes from 'components/catalog/CatalogRoutes'
import PrivateRoute from './PrivateRoute'
import { servicesEnum, GENDER_PROVIDER_VALUE } from 'utils/domain/provider'
import { ADMIN_USER_GENDER } from 'utils/domain/user'
import { publicRoutes, privateRoutes } from './routes'

// Public
import SignupPage from 'pages/public/Signup'
import SigninPage from 'pages/public/Signin'
import ForgotPasswordPage from 'pages/public/ForgotPassword'
import PolicyPage from 'pages/public/Policy'
import CGUPage from 'pages/public/CGU'
import NotFound from 'router/NotFound'
import Maintenance from 'router/Maintenance'

// Private
import HomePage from 'pages/protected/Home'
import CommitmentPage from 'pages/protected/Commitment'
import AutodiagPage from 'pages/protected/Autodiag'
import CatalogPage from 'pages/protected/Catalog'
import CatalogItemPage from 'pages/protected/CatalogItem'
import BidPage from 'pages/protected/Bid'
import AccountPage from 'pages/protected/Account'
import ErrorPage from 'pages/protected/Error'
import AcceptCGU from 'pages/protected/AcceptCGU'
import SuccessPage from 'pages/protected/Success'
import MonitoringActivities from 'pages/protected/monitoring/activities/MonitoringActivities'
import ScopingMeeting from 'pages/protected/ScopingMeeting'
import Audit from 'pages/protected/Audit'
import Label from 'pages/protected/Label'
import MyBonusPage from 'pages/protected/MyBonus/MyBonus'
import AdminHomePage from 'pages/protected/admin/AdminHome'

// Providers
import ProviderMonitoringPage from 'pages/protected/provider/ProviderMonitoring'
import ProviderAccountPage from 'pages/protected/provider/ProviderAccount'

// Scoping meeting users
import ScopingMeetingAccountPage from 'pages/protected/ScopingMeeting/ScopingMeetingAccount'
import ScopingMeetingProviderPage from 'pages/protected/ScopingMeetingProvider'

// Audit users
import AuditHomePage from 'pages/protected/AuditBO/AuditHome'
import AuditAccountPage from 'pages/protected/AuditBO/AuditAccount'

// Back Office
import BackOfficeSignIn from 'pages/back-office/Signin'
import BackOfficeHomePage from 'pages/back-office/Home'
import EntityFolder from 'pages/back-office/Folders/EntityFolder'
import RequestFolder from 'pages/back-office/Folders/RequestFolder'
import Folders from 'pages/back-office/Folders'
import BackOfficeExample from 'layouts/back-office'
import AccreditationDetail from 'pages/back-office/Accreditations/Detail'
import Accreditations from 'pages/back-office/Accreditations'
import Payments from 'pages/back-office/Payments'
import Participants from 'pages/back-office/Participants'
import ParticipantDetail from 'pages/back-office/Participants/Detail'
import EquipmentsProviders from 'pages/back-office/EquipmentsProviders'
import EquipmentsProviderDetail from 'pages/back-office/EquipmentsProviders/Detail'
import BeneficiariesAdministration from 'pages/back-office/Beneficiaries/Administration'
import BeneficiaryAdministrationDetail from 'pages/back-office/Beneficiaries/Administration/Detail'
import Beneficiaries from 'pages/back-office/Beneficiaries'
import BeneficiaryDetails from 'pages/back-office/Beneficiaries/Details'
import BeneficiaryRegularisation from 'pages/back-office/Beneficiaries/Regularisation'
import AuditedBeneficiaryDetails from 'pages/protected/AuditBO/beneficiaryDetails'
import Management from 'pages/back-office/Management'
import { UploadProviderData } from 'pages/back-office/UploadProviderData'


const ProfilHomePage = () => {
    const { user } = useAuth()
    const services = user?.entity_data?.services?.split(',')
    const isEquipmentProvider = services?.includes(servicesEnum.equipment)
    const isAuditProvider = user?.entity_data?.audit_available
    const isScopingMeetingProvider = user?.entity_data?.framing_meeting_available

    switch (user?.entity_data?.gender) {
        case ADMIN_USER_GENDER:
            return <AdminHomePage />
        case GENDER_PROVIDER_VALUE:
            if (isEquipmentProvider) {
                return <ProviderMonitoringPage />
            }
            if (isScopingMeetingProvider) {
                return <ScopingMeetingProviderPage />
            }
            if (isAuditProvider) {
                return <AuditHomePage />
            }
            return null
        default:
            return <HomePage />
    }
}

const ProfilAccountPage = () => {
    const { user } = useAuth()
    const services = user?.entity_data?.services?.split(',')
    switch (user?.entity_data?.gender) {
        case GENDER_PROVIDER_VALUE:
            if (services?.includes(servicesEnum.equipment)) {
                return <ProviderAccountPage />
            }
            if (services?.includes(servicesEnum.scopingMeeting)) {
                return <ScopingMeetingAccountPage />
            }
            if (services?.includes(servicesEnum.audit)) {
                return <AuditAccountPage />
            }
            return null
        default:
            return <AccountPage />
    }
}

const Routes = () => {
    if (process.env.REACT_APP_MAINTENANCE === 'true') {
        return (
            <Switch>
                <Route path={publicRoutes.maintenance.path} component={Maintenance} />
                <Redirect to={publicRoutes.maintenance.path} />
            </Switch>
        )
    }

    return (
        <Switch>
            {/* Public */}
            <Route exact path="/">
                <Redirect to={publicRoutes.login.path} />
            </Route>
            <Route path={publicRoutes.signin.path} component={SignupPage} />
            <Route path={publicRoutes.login.path} component={SigninPage} />
            <Route path={publicRoutes.lostPassword.path} component={ForgotPasswordPage} />
            <Route path={publicRoutes.legalNotices.path} component={PolicyPage} />
            <Route path={publicRoutes.termsOfUse.path} component={CGUPage} />

            <Route path={publicRoutes.notFound.path} component={NotFound} />

            <Route exact path={publicRoutes.maintenance.path}>
                <Redirect to={publicRoutes.login.path} />
            </Route>

            {/* Protected */}

            <PrivateRoute
                path={privateRoutes.home.path}
                component={
                    ProfilHomePage()
                        ? ProfilHomePage
                        : () => <Redirect to={publicRoutes.notFound.path} />
                    }
                roles={privateRoutes.home.roles}
            />

            <PrivateRoute
                path={privateRoutes.commitmentCharter.path}
                component={CommitmentPage}
                roles={privateRoutes.commitmentCharter.roles}
            />

            <PrivateRoute
                exact
                path={privateRoutes.autoDiag.path}
                component={AutodiagPage}
                roles={privateRoutes.autoDiag.roles}
            />

            <PrivateRoute
                path={privateRoutes.myAccount.path}
                component={
                    ProfilAccountPage()
                        ? ProfilAccountPage
                        : () => <Redirect to={publicRoutes.notFound.path} />
                }
                roles={privateRoutes.myAccount.roles}
            />

            <PrivateRoute
                path={privateRoutes.scopingMeeting.path}
                component={ScopingMeeting}
                roles={privateRoutes.scopingMeeting.roles}
            />
            <PrivateRoute
                path={privateRoutes.audit.path}
                component={Audit}
                roles={privateRoutes.audit.roles}
            />
            <PrivateRoute
                path={privateRoutes.label.path}
                component={Label}
                roles={privateRoutes.label.roles}
            />
            <PrivateRoute
                path={privateRoutes.catalogItem.path}
                component={CatalogItemPage}
                roles={privateRoutes.catalogItem.roles}
            />
            <PrivateRoute
                path={privateRoutes.catalog.path}
                component={CatalogPage}
                roles={privateRoutes.catalog.roles}
            />

            <PrivateRoute
                path={privateRoutes.serviceRequestItem.path}
                component={BidPage}
                roles={privateRoutes.serviceRequestItem.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.serviceRequest.path}
                roles={privateRoutes.serviceRequest.roles}
            >
                <Redirect to={`${privateRoutes.serviceRequest.path}/${CatalogRoutes[0]?.url}`} />
            </PrivateRoute>

            <PrivateRoute
                path={privateRoutes.monitoringActivitiesItem.path}
                component={MonitoringActivities}
                roles={privateRoutes.monitoringActivitiesItem.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.monitoringActivities.path}
                roles={privateRoutes.monitoringActivities.roles}
            >
                <Redirect to={privateRoutes.monitoringActivities.autodiagnostic.path} />
            </PrivateRoute>
            <PrivateRoute
                path={privateRoutes.bonus.path}
                component={MyBonusPage}
                roles={privateRoutes.bonus.roles}
            />

            <PrivateRoute
                path={privateRoutes.acceptCGU.path}
                component={AcceptCGU}
                roles={privateRoutes.acceptCGU.roles}
            />
            <PrivateRoute path={privateRoutes.error.path} component={ErrorPage} />
            <PrivateRoute path={privateRoutes.success.path} component={SuccessPage} />
            <PrivateRoute
                path={privateRoutes.auditedBeneficiaryDetails.path}
                component={AuditedBeneficiaryDetails}
                roles={privateRoutes.auditedBeneficiaryDetails.roles}
            />

            {/* Public : Back Office */}
            <Route path={publicRoutes.backOffice.login.path} component={BackOfficeSignIn} />
            <Route path={publicRoutes.backOffice.lostPassword.path} component={ForgotPasswordPage} />
            <Route path={publicRoutes.backOffice.example.path} component={BackOfficeExample} />

            {/* Private : Back Office */}
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.accreditations.path}
                component={Accreditations}
                roles={privateRoutes.backOffice.accreditations.roles}

            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.accreditationDetail.path}
                component={AccreditationDetail}
                roles={privateRoutes.backOffice.accreditationDetail.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.accreditationCreation.path}
                component={AccreditationDetail}
                roles={privateRoutes.backOffice.accreditationCreation.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.participantsAdministration.path}
                component={Participants}
                roles={privateRoutes.backOffice.participantsAdministration.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.participantDetailsAdministration.path}
                component={ParticipantDetail}
                roles={privateRoutes.backOffice.participantDetailsAdministration.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.participantCreationAdministration.path}
                component={ParticipantDetail}
                roles={privateRoutes.backOffice.participantCreationAdministration.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.providersAdministration.path}
                component={EquipmentsProviders}
                roles={privateRoutes.backOffice.providersAdministration.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.providerCreationAdministration.path}
                component={EquipmentsProviderDetail}
                roles={privateRoutes.backOffice.providerCreationAdministration.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.providerDetailAdministration.path}
                component={EquipmentsProviderDetail}
                roles={privateRoutes.backOffice.providerDetailAdministration.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.providerModificationAdministration.path}
                component={EquipmentsProviderDetail}
                roles={privateRoutes.backOffice.providerModificationAdministration.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.beneficiariesAdministration.path}
                component={BeneficiariesAdministration}
                roles={privateRoutes.backOffice.beneficiariesAdministration.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.beneficiaryDetailAdministration.path}
                component={BeneficiaryAdministrationDetail}
                roles={privateRoutes.backOffice.beneficiaryDetailAdministration.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.backOfficeHome.path}
                component={BackOfficeHomePage}
                roles={privateRoutes.backOffice.backOfficeHome.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.requestFolder.path}
                component={RequestFolder}
                roles={privateRoutes.backOffice.requestFolder.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.entityFolder.path}
                component={EntityFolder}
                roles={privateRoutes.backOffice.entityFolder.roles}
            />
            <PrivateRoute
                path={privateRoutes.backOffice.folders.path}
                component={Folders}
                roles={privateRoutes.backOffice.folders.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.beneficiaries.path}
                component={Beneficiaries}
                roles={privateRoutes.backOffice.beneficiaries.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.beneficiaryDetails.path}
                component={BeneficiaryDetails}
                roles={privateRoutes.backOffice.beneficiaryDetails.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.beneficiaryRegularisation.path}
                component={BeneficiaryRegularisation}
                roles={privateRoutes.backOffice.beneficiaryRegularisation.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.payments.path}
                component={Payments}
                roles={privateRoutes.backOffice.payments.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.management.path}
                component={Management}
                roles={privateRoutes.backOffice.management.roles}
            />
            <PrivateRoute
                exact
                path={privateRoutes.backOffice.uploadproviderdata.path}
                component={UploadProviderData}
                roles={privateRoutes.backOffice.uploadproviderdata.roles}
            />
            <Route component={NotFound} />

        </Switch>
    )
}

export default Routes