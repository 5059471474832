import React from 'react'
import { bool, func, number, shape, string } from 'prop-types'

import FormElement from 'components/shared/FormElement'

const customValidationRules = [
    {
        key: 'min-value-invalid',
        check: (value) => Number(value) >= 1,
        message: 'La valeur doit être supérieur ou égal à 1'
    }
]

const FormFieldsPropTypes = {
    sessionsQuantity: string,
    personsQuantity: string,
    bikesQuantity: string,
}

const Inputs = ({
    amountToRefund,
    totalAmount,
    request,
    formFields,
    handleValidation,
    handleInputChange,
    showLocalErrors
}) => {
    if(request?.isBackToBikeTraining) {
        return (
            <div className="u-flex u-flex-dir-col u-width-full">
                <BackToBikeInputs
                    trainingName={request?.trainingInformation?.shortLabel} 
                    personsQuantity={formFields?.personsQuantity}
                    sessionsQuantity={formFields?.sessionsQuantity}
                    showLocalErrors={showLocalErrors}
                    handleValidation={handleValidation}
                    handleInputChange={handleInputChange}
                />
            </div>
        )
    }

    const requestType = request?.request_description?.type

    if(requestType === 'technical-check' || requestType === 'technical-maintenance') {
        return (
            <div className="u-flex u-flex-dir-col">

                <TechnicalBikeInputs
                    trainingName={requestType === 'technical-check' ? 'contrôlés' : 'réparés'} 
                    bikesQuantity={formFields.bikesQuantity}
                    showLocalErrors={showLocalErrors}
                    handleValidation={handleValidation}
                    handleInputChange={handleInputChange}
                />

            </div>
        )
    }

    if(requestType === 'technical-workshops' || requestType === 'bike-overhaul') {
        return (
            <div className="u-flex u-flex-dir-col">

                <TechnicalBikeFormationInputs
                    trainingName="Atelier mécanique participatif pour les employés"
                    bikesQuantity={formFields.bikesQuantity}
                    sessionsQuantity={formFields?.sessionsQuantity}
                    showLocalErrors={showLocalErrors}
                    handleValidation={handleValidation}
                    handleInputChange={handleInputChange}
                />

            </div>
        )
    }

    return (
        <div className="u-flex u-flex-dir-col">
            <TrainingInputs
                trainingName={request?.trainingInformation?.shortLabel} 
                sessionsQuantity={formFields?.sessionsQuantity}
                personsQuantity={formFields?.personsQuantity}
                showLocalErrors={showLocalErrors}
                handleValidation={handleValidation}
                handleInputChange={handleInputChange}
            />
        </div>
    )
}

Inputs.propTypes = {
    totalAmount: number,
    amountToRefund: number,
    request: shape({
        isBackToBikeTraining: bool,
        trainingInformation: shape({
            shortLabel: string
        })
    }),
    formFields: shape(FormFieldsPropTypes),
    handleValidation: func,
    handleInputChange: func,
    showLocalErrors: bool
}

const GenericInputsPropTypes = {
    traningName: string,
    showLocalErrors: bool,
    handleValidation: func,
    handleInputChange: func
}

const TrainingInputs = ({
    trainingName,
    sessionsQuantity,
    personsQuantity,
    showLocalErrors,
    handleValidation,
    handleInputChange
}) => {

    return (
        <div className="l-grid u-mg-bottom-m">
            <div className="l-col-5 u-mg-right-xl">
                <FormElement
                    value={sessionsQuantity}
                    name="sessionsQuantity"
                    type="number"
                    label={<>Nombre de sessions de formation <br /> "{trainingName}"</>}
                    className="u-mg-bottom-m"
                    classNameLabel="u-grey90 u-fs-xxs u-regular"
                    customRules={customValidationRules}
                    required
                    showErrors={showLocalErrors}
                    onValidate={handleValidation}
                    onChange={handleInputChange}
                />
            </div>
            <div className="l-col-5">
                <FormElement
                    value={personsQuantity}
                    name="personsQuantity"
                    type="number"
                    label={<>Nombre de participants <br /> "{trainingName}"</>}
                    className="u-mg-bottom-m"
                    classNameLabel="u-grey90 u-fs-xxs u-regular"
                    customRules={customValidationRules}
                    required
                    showErrors={showLocalErrors}
                    onValidate={handleValidation}
                    onChange={handleInputChange}
                />
            </div>
        </div>
    )
}

TrainingInputs.propTypes = {
    ...GenericInputsPropTypes,
    sessionsQuantity: FormFieldsPropTypes.sessionsQuantity,
    personsQuantity: FormFieldsPropTypes.personsQuantity,
}

const BackToBikeInputs = ({
    trainingName,
    sessionsQuantity,
    personsQuantity,
    showLocalErrors,
    handleValidation,
    handleInputChange
}) => {

    return (
        <div className="l-grid u-mg-bottom-m u-width-full">
            <div className="l-col-5 u-mg-right-xl">
                <FormElement
                    value={sessionsQuantity}
                    name="sessionsQuantity"
                    type="number"
                    label={<>Nombre de sessions de formation <br /> "{trainingName}"</>}
                    className="u-mg-bottom-m"
                    classNameLabel="u-grey90 u-fs-xxs u-regular"
                    customRules={customValidationRules}
                    required
                    showErrors={showLocalErrors}
                    onValidate={handleValidation}
                    onChange={handleInputChange}
                />
            </div>
            <div className="l-col-5">
                <FormElement
                    value={personsQuantity}
                    name="personsQuantity"
                    type="number"
                    label={<>Nombre de participants <br /> "{trainingName}"</>}
                    className="u-mg-bottom-m"
                    classNameLabel="u-grey90 u-fs-xxs u-regular"
                    customRules={customValidationRules}
                    required
                    showErrors={showLocalErrors}
                    onValidate={handleValidation}
                    onChange={handleInputChange}
                />
            </div>
        </div>
    )
}

BackToBikeInputs.propTypes = {
    ...GenericInputsPropTypes,
    sessionsQuantity: FormFieldsPropTypes.sessionsQuantity,
    personsQuantity: FormFieldsPropTypes.personsQuantity,
}

const TechnicalBikeInputs = ({
    trainingName,
    bikesQuantity,
    showLocalErrors,
    handleValidation,
    handleInputChange
}) => {

    return (
        <FormElement
            value={bikesQuantity}
            name="bikesQuantity"
            type="number"
            label={<>Nombre de vélos {trainingName}</>}
            className="u-mg-bottom-m"
            classNameLabel="u-grey90 u-fs-xxs u-regular"
            customRules={customValidationRules}
            required
            showErrors={showLocalErrors}
            onValidate={handleValidation}
            onChange={handleInputChange}
        />
    )
}

TechnicalBikeInputs.propTypes = {
    ...GenericInputsPropTypes,
    bikesQuantity: FormFieldsPropTypes.bikesQuantity,
}

const TechnicalBikeFormationInputs = ({
    bikesQuantity,
    sessionsQuantity,
    showLocalErrors,
    handleValidation,
    handleInputChange
}) => {

    return (
        <div className="l-grid u-mg-bottom-m u-width-full">
            <div className="l-col-5 u-mg-right-xl">
                <FormElement
                    value={bikesQuantity}
                    name="bikesQuantity"
                    type="number"
                    label={'Nombre de vélo réparés'}
                    className="u-mg-bottom-m"
                    classNameLabel="u-grey90 u-fs-xxs u-regular"
                    customRules={customValidationRules}
                    required
                    showErrors={showLocalErrors}
                    onValidate={handleValidation}
                    onChange={handleInputChange}
                />
            </div>
            <div className="l-col-5">
                <FormElement
                    value={sessionsQuantity}
                    name="sessionsQuantity"
                    type="number"
                    label={'Nombre de sessions'}
                    className="u-mg-bottom-m"
                    classNameLabel="u-grey90 u-fs-xxs u-regular"
                    customRules={customValidationRules}
                    required
                    showErrors={showLocalErrors}
                    onValidate={handleValidation}
                    onChange={handleInputChange}
                />
            </div>
        </div>
    )
}

TechnicalBikeFormationInputs.propTypes = {
    ...GenericInputsPropTypes,
    sessionsQuantity: FormFieldsPropTypes.sessionsQuantity,
    bikesQuantity: FormFieldsPropTypes.bikesQuantity
}


export default Inputs