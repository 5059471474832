import { func, object, shape, string } from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import { getColor } from 'utils/styles'
import saveBlob from 'utils/saveBlob'
import { fileHash } from 'utils/hash'
import dateService from 'services/date'
import { useAlert } from 'hooks/useAlert'
import { getMediaUrlPrefix } from 'utils/domain/user'
import uploadFile from 'services/uploadFile'
import { REALIZED_STATUS, ASKED_STATUS, PLANNED_STATUS, CANCELED_STATUS } from 'utils/domain/scopingMeetings'
import { updateScopingMeeting } from 'services/api/scoping-meeting/ScopingMeetingApi'

import DownloadIcon from 'components/svg/Download'
import UploadIcon from 'components/svg/Upload'

import './styles.scss'
import { getFileType, isPDFExtension } from 'utils/file'

const MAX_FILE_SIZE = 20000000

const ImportDownloadCell = ({ cell, value: scopingMeetingReportMedia, column: { refreshData } }) => {
    const { addAlert } = useAlert()

    const idEntity = cell.row.original.entity_id
    const cellId = cell.row.original.id
    const structureName = cell.row.original.structureName
    const meetingStatus = cell.row.values.meetingStatus
    const isNotDisplayable = meetingStatus === ASKED_STATUS || meetingStatus === PLANNED_STATUS
    const framing_meeting_date = cell.row.original.framing_meeting_date

    if(isNotDisplayable) return (<></>)

    const downloadScopingMeetingReport = async () => {
        try {
            const extension = scopingMeetingReportMedia?.media_url?.split('.').pop()
            const documentTitle = `rapport-de-reunion-de-cadrage-${structureName}-${dateService.getCurrentDate('dd-MM-y')}.${extension}`
            const meetingReport = await fetch(scopingMeetingReportMedia?.full_media_url)
            const blob = await meetingReport.blob()
            saveBlob(blob, documentTitle)
        } catch(error) {
            addAlert('error', error?.toString())
        }
    }

    const uploadScopingMeetingReport = async (document, meetingStatus) => {
        try {
            const scopingMeetingToUpdate = {
                entity_id: idEntity,
                framing_meeting_status: meetingStatus ? meetingStatus : REALIZED_STATUS,
                framing_meeting_date,
                document
            }

            const { getError } = await updateScopingMeeting(scopingMeetingToUpdate)
            if (getError()) throw getError()

            addAlert('success', 'Le document a été uploadé')
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }

    const isFileValid = (fileExtension, fileSize, maxSize = MAX_FILE_SIZE) => {
        if (!isPDFExtension(fileExtension)) {
            addAlert('error', 'Extension du fichier non valide. Format accepté : .pdf')
            return false
        }

        if (fileSize > maxSize) {
            const sizeInMo = maxSize / 1000000
            addAlert('error', `Taille du fichier maximum dépassée (${sizeInMo}Mo).`)
            return false
        }

        return true
    }

    const handleBlobHasBeenRead = async (event, fileInfos, meetingStatus) => {
        try {
            const type = getFileType(event.target.result)

            if (!type || type === 'unknown') {
                addAlert('error', 'Type du fichier inconnu...')
                return
            }

            const blob = new Blob([new Uint8Array(event.target.result)], { type: fileInfos.fileType })
            const id = uuidv4()

            await uploadFile('reunion-de-cadrage', { id, extension: fileInfos.fileExtension, blob })

            const documentToUpload = {
                media_url: `${getMediaUrlPrefix()}${id}.${fileInfos.fileExtension}`,
                media_hash: fileInfos.hash_file
            }

            await uploadScopingMeetingReport(documentToUpload, meetingStatus)
            refreshData()
        } catch(error) {
            addAlert('error', 'Une erreur est survenue pendant le téléversement du document')
        }
    }

    const handleChange = async (event, meetingStatus) => {
        const [file] = event.target.files

        const fileExtension = file.name.split('.').pop()
        const fileType = file.type === 'application/pdf' ? 'application/pdf' : 'image/png'

        if(!isFileValid(fileExtension, file.size)) return

        const hash_file = await fileHash(file)

        const reader = new FileReader()

        reader.addEventListener('loadend', (event) =>
            handleBlobHasBeenRead(event, { fileType, fileExtension, hash_file }, meetingStatus))

        reader.readAsArrayBuffer(file)
    }

    return (
        <div className='u-left u-mg-left-l import-download-meeting-report u-flex'>
            { !scopingMeetingReportMedia && meetingStatus !== CANCELED_STATUS && 
                <>
                    <label id="label-upload-report" aria-label="Envoyer le rapport de réunion de cadrage" htmlFor={`upload-report-${cellId}`}>
                        <UploadIcon className="vertical-align" color={getColor().primary} />
                    </label>
                    <input
                        type="file" name="photo" id={`upload-report-${cellId}`}
                        onChange={handleChange}
                    />
                </>
            }
            { scopingMeetingReportMedia &&
                <>
                    <button
                        type="button" aria-label="Télécharger le rapport de réunion de cadrage"
                        onClick={() => downloadScopingMeetingReport()}
                    >
                        <DownloadIcon className="vertical-align" color={getColor().primary} />
                    </button>
                    { meetingStatus !== CANCELED_STATUS && 
                        <>
                            <label id="label-update-report" area-label="Modifier le rapport de réunion de cadrage" htmlFor={`update-report-${cellId}`} >
                                modifier
                            </label>
                            <input
                                type="file" name="photo" id={`update-report-${cellId}`}
                                onChange={e => handleChange(e, meetingStatus)}
                            />
                        </>
                    }
                </>
            }
        </div>
    )
}

ImportDownloadCell.propTypes = {
    cell: object.isRequired,
    value: shape({
        full_media_url: string.isRequired,
        media_hash: string.isRequired,
        media_url: string.isRequired
    }),
    column: shape({
        refreshData: func.isRequired
    }).isRequired
}

const importDownloadScopingMeetingColumn = ({ refreshData }) => {
    return {
        Header: () => <span className="u-break-spaces">Importer/télécharger le rapport de cadrage</span>,
        accessor: 'framing_meeting_media_rapport_url',
        sortType: (a, b) => {
            const scopingMeetingA = !! a.original?.framing_meeting_media_rapport_url
            const scopingMeetingB = !! b.original?.framing_meeting_media_rapport_url
            return scopingMeetingA - scopingMeetingB
        },
        Cell: ImportDownloadCell,
        refreshData,
        style: [{ textAlign: 'left' }]
    }
}

export default importDownloadScopingMeetingColumn