import React, { useState } from 'react'
import { node } from 'prop-types'

import * as CatalogApi from 'services/api/CatalogApi'
import { useAlert } from 'hooks/useAlert'
import { BACK_OFFICE_OPERATIONS, DOCUMENTS } from 'utils/domain/request'
import { getBeneficiary } from 'utils/domain/admin'
import { isNotEmpty } from 'utils/validation'

const RequestFolderContext = React.createContext({
    request: {},
    beneficiary: {},
    setRequest: () => {},
    setBeneficiary: () => {},
    fetchRequest: () => {},
    fetchRequestEquipments: () => {},
})

export const useRequestFolder = () => React.useContext(RequestFolderContext)

export const OTHER_NON_ELIGIBLE_COSTS = 'Autres frais non éligibles'

const RequestForlderProvider = ({ children }) => {
    const [request, setRequest] = useState({})
    const [requestEquipmentArray, setRequestEquipmentArray] = React.useState([])
    const [beneficiary, setBeneficiary] = useState({})
    const { addAlert } = useAlert()

    const getValidatedBid = (history) => {
        const quoteOperations = history.filter(stack => stack.request_data?.operation_code === BACK_OFFICE_OPERATIONS.BID.RECEIVE_QUOTE)
        
        const validatedBidStack = history?.find(stack => stack.request_data?.operation_code === BACK_OFFICE_OPERATIONS.BID.VALIDATE_BID)
        const providerId = validatedBidStack?.request_data?.operation_data?.provider_id

        if(!quoteOperations.length || !providerId) return null
        
        const validatedBid = quoteOperations
            .filter(stack => stack.request_data.operation_data.operation_entity_id === providerId)
            .sort((a, b) => new Date(b.history_date) - new Date(a.history_date))[0]
        
        return validatedBid
    }


    const getBidBackOfficeInformations = (requestHistory) => {
        const stack = requestHistory.filter(stack => 
            stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.BID.VALIDATE
        )[0]
        if(!stack) return null
        const {
            amount_to_refund,
            nbOfAdditionalServices,
            nbOfBikesPlaces,
        } = stack.request_data.operation_data || {}
        return {
            amount_to_refund,
            nbOfAdditionalServices,
            nbOfBikesPlaces
        }
    }

    const getRequestHistory = async(requestId) => {
        const { res: requestHistoryResponse, getError } = await CatalogApi.getBackOfficeRequestHistory(requestId)
        
        if (getError()) throw getError()
        
        const lastPicturesUploaded = requestHistoryResponse.filter(stack => 
            stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.PICTURES.UPLOAD_BY_BENEFICIARY
        )[0]

        // On remonte les events de type PAYREQUEST. Le statut 72 peut avoir ou non des documents. On filtre donc sur l'event où ils sont présents
        const lastDocumentsUploaded = requestHistoryResponse.filter(stack => 
            stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.BID.UPLOAD_BY_BENEFICIARY && isNotEmpty(stack.request_data.operation_data?.documents)
        )[0]

        return {
            pictures: lastPicturesUploaded?.request_data?.operation_data?.documents,
            validatedBid: getValidatedBid(requestHistoryResponse),
            bill: lastDocumentsUploaded?.request_data?.operation_data.documents?.find(document => document.media_gender === DOCUMENTS.bill),
            payment_proof: lastDocumentsUploaded?.request_data?.operation_data.documents?.find(document => document.media_gender === DOCUMENTS.payment_proof),
            bidInformations: getBidBackOfficeInformations(requestHistoryResponse)
        }
    }

    const fetchRequest = async(id) => {
        try {
            const { res: requestResponse, getError } = await CatalogApi.getRequest(id || request?.request_fub_id)
        
            if (getError()) throw getError()  
            
            const lastUpdates = await getRequestHistory(requestResponse.request_id)
            const currentBeneficiary = await getBeneficiary(requestResponse.request_entity_id)

            setRequest({
                ...requestResponse,
                ...lastUpdates
            })
            setBeneficiary(currentBeneficiary)

        } catch(error) {
            addAlert('error', error?.toString())
        }
    }

    const fetchRequestEquipments = async (requestId) => {
        try {
            const { res: requestEquipmentResponse, getError } = await CatalogApi.getRequestEquipments(requestId)
        
            if (getError()) throw getError()  

            setRequestEquipmentArray(() => [
                ...requestEquipmentResponse.map((equipment) => {
                    return {
                        supply_id: equipment.equipments_supply_id,
                        date: equipment.equipments_supply_data.date,
                        equipment: equipment.equipments_supply_data.equipment,
                        category: equipment.equipments_supply_data.category,
                        name: equipment.equipments_supply_data.name,
                        placeNumber: equipment.equipments_supply_data.placeNumber,
                        quantity: equipment.equipments_supply_data.quantity,
                        price: equipment.equipments_supply_data.price,
                        editing: false,
                        optional: equipment.equipments_supply_data.optional
                    }
                }),
            ])

        } catch(error) {
            addAlert('error', error?.toString())
        }
    }

    const additionalEquipmentTotalAmount = isNotEmpty(requestEquipmentArray) && requestEquipmentArray?.reduce((acc, equipment) => acc + (Number(equipment.quantity) * Number(equipment.price)), 0)
    
    const totalEligibleCosts = 
        isNotEmpty(requestEquipmentArray) && 
            requestEquipmentArray?.reduce((acc, equipment) => {
                const isNonEligibleEquipment = equipment.category === OTHER_NON_ELIGIBLE_COSTS
                const amount = isNonEligibleEquipment ? 0 : Number(equipment.quantity) * Number(equipment.price)
                return (acc + amount)
            }, 0)

    return (
        <RequestFolderContext.Provider value={{ 
            fetchRequest, 
            fetchRequestEquipments, 
            requestEquipmentArray, 
            setRequestEquipmentArray, 
            additionalEquipmentTotalAmount,
            totalEligibleCosts,

            request, 
            setRequest, 

            beneficiary, 
            setBeneficiary }}>
            {children}
        </RequestFolderContext.Provider>
    )
}

RequestForlderProvider.propTypes = {
    children: node
}

export default RequestForlderProvider