import React from 'react'
import { string, func, bool, object, shape } from 'prop-types'
import { getDepartments } from 'services/api/UtilsApi'
import FormElement from 'components/shared/FormElement'
import CrossIcon from 'components/svg/Cross'
import { stringSorter } from 'utils/sort'

const SupportInformation = ({
  formFields = {},
  formEvents = {},
  showLocalErrors = {},
  handleValidation = () => {},
  handleInputChange = () => {},
  setFormFields = () => {}
}) => {

  const [departmentsActive, setDepartmentsActive] = React.useState() // departments where the participant is present
  const [departmentsFromGouvAPI, setDepartementsFromGouvAPI] = React.useState([]) // departments from Gouv API
  const [departmentsDisplayedAsHTMLSelect, setDepartmentsDisplayedAsHTMLSelect] = React.useState([]) // departments display has options in <select> HTML element
  const [departmentSelected, setDepartmentSelected] = React.useState({}) // department clicked in <select> HTML element
  
  const removeZero = code => {
    return code.charAt(0) === '0' ? code.substring(1) : code
  }

  React.useEffect(() => {
    if(formFields.department?.length > 0 && departmentsFromGouvAPI.length > 0 && !departmentsActive) {
      const departmentsActiveValued = formFields.department.map(dptCode => departmentsFromGouvAPI?.find(dptFormApi => {
        return removeZero(dptFormApi.code) === removeZero(dptCode.toString())
      }))
      setDepartmentsActive(departmentsActiveValued)
      setDepartmentsDisplayedAsHTMLSelect(prevDpts => {
        const departmentsActiveRemoved = prevDpts.filter(dpt => !!!departmentsActiveValued?.find(dptActive => dptActive.code === dpt.code))
        return [...departmentsActiveRemoved]
      })
    }
  }, [formFields.department, departmentsFromGouvAPI, departmentsActive])

  React.useEffect(() => {
    fetchDepartments()
  }, [])

  const fetchDepartments = async () => {
    const departmentsFromGouvAPI = await getDepartments()
    setDepartementsFromGouvAPI(departmentsFromGouvAPI)
    setDepartmentsDisplayedAsHTMLSelect(departmentsFromGouvAPI)
  }

  const handleChangeDepartments = (e) => {
    const dptSelected = e.target.value
    setDepartmentSelected(dptSelected)
    setDepartmentsDisplayedAsHTMLSelect(prevDpts => [...prevDpts.filter(dpt => dpt.code !== dptSelected.code)])
    setDepartmentsActive(prevDpts => prevDpts ? [...prevDpts, dptSelected] : [dptSelected])
    setFormFields(prevFormFields => ({
      ...prevFormFields,
      department: prevFormFields?.department ? [...prevFormFields.department, dptSelected.code] : dptSelected.code
    }))
  }

  const handleRemoveDepartment = (dptToRemove) => {
    setFormFields(prevFormFields => ({
      ...prevFormFields,
      department: prevFormFields.department.filter(prevDpt => removeZero(prevDpt.toString()) !== removeZero(dptToRemove.code)) // some data in database are without a zero. 
    }))

    setDepartmentsDisplayedAsHTMLSelect(prevDpts => {
      const newDptsList = [...prevDpts, dptToRemove]
      return newDptsList.sort((dptA, dptB) => stringSorter(dptB.code, dptA.code))
    })
    setDepartmentsActive(prevDpts => [...prevDpts.filter(dptActive => dptActive.code !== dptToRemove.code)])
  }

  // Manual form validation
  const atLeastOneAdviceAndSupportIsChecked = () => formFields.auditPreparation || formFields.specificationsTraining || formFields.actionPlan || formFields.setUpAndTraining || formFields.trainingAndToolsOfCyclingTeam || formFields.diagnosticMobility || formFields.cyclingStrategy

  const adviceServicesTriggerValidation = React.useMemo(() => [formFields.auditPreparation, formFields.specificationsTraining, formFields.actionPlan, formFields.cyclingStrategy, formFields.setUpAndTraining, formFields.trainingAndToolsOfCyclingTeam, formFields.diagnosticMobility, formFields.department], 
    [formFields.auditPreparation, formFields.specificationsTraining, formFields.actionPlan, formFields.setUpAndTraining, formFields.trainingAndToolsOfCyclingTeam, formFields.diagnosticMobility, formFields.department, formFields.cyclingStrategy]
  )

  return (
    <>
      <div className="u-flex u-mg-top-m">
        <div className='l-col-6'>
        <FormElement
            value={formFields.cyclingStrategy}
            name="cyclingStrategy"
            type="checkbox"
            checkboxLabel="Définition d’une stratégie vélo"
            className="u-mg-bottom-m"
            classNameInput="u-bg-transparent"
            showErrors={showLocalErrors}
            onValidate={handleValidation}
            onChange={handleInputChange}
            disabled={!formEvents.editable}
          />
          <FormElement
            value={formFields.trainingAndToolsOfCyclingTeam}
            name="trainingAndToolsOfCyclingTeam"
            type="checkbox"
            checkboxLabel="Organisation, formation et outillage de l’équipe vélo"
            className="u-mg-bottom-m"
            classNameInput="u-bg-transparent"
            showErrors={showLocalErrors}
            onValidate={handleValidation}
            onChange={handleInputChange}
            disabled={!formEvents.editable}
          />
          <FormElement
            value={formFields.specificationsTraining}
            name="specificationsTraining"
            type="checkbox"
            checkboxLabel="Formation au cahier des charges, audit à blanc"
            className="u-mg-bottom-m"
            classNameInput="u-bg-transparent"
            showErrors={showLocalErrors}
            onValidate={handleValidation}
            onChange={handleInputChange}
            disabled={!formEvents.editable}
          />
          <FormElement
            value={formFields.actionPlan}
            name="actionPlan"
            type="checkbox"
            checkboxLabel="Élaboration/enrichissement d'un plan d’actions pro-vélo et appui opérationnel à la réalisation"
            className="u-mg-bottom-m"
            classNameInput="u-bg-transparent"
            showErrors={showLocalErrors}
            onValidate={handleValidation}
            onChange={handleInputChange}
            disabled={true}
          />
          <FormElement
            value={formFields.diagnosticMobility}
            name="diagnosticMobility"
            type="checkbox"
            checkboxLabel="Diagnostic mobilité axé sur le vélo"
            className="u-mg-bottom-m"
            classNameInput="u-bg-transparent"
            showErrors={showLocalErrors}
            onValidate={handleValidation}
            onChange={handleInputChange}
            disabled={true}
          />
        </div>
        <div className='l-col-6'>
          <div>
            <div className='u-flex u-flex-dir-col l-grid u-mg-bottom-m u-mg-left-xs'>
                <div className='u-mg-bottom-s u-blue u-bold u-grey90 u-fs-xs'>
                    Départements d'intervention
                </div>
                <div className='u-fs-xs u-mg-bottom-s u-flex u-flex-wrap u-blue u-semi-bold u-fs-xs'>
                  { departmentsActive && departmentsActive.map((dpt) => {
                    return (
                      <>
                        { !formEvents.editable && <div key={dpt?.code} className="u-blue u-semi-bold u-mg-right-m u-mg-top-m c-btn is-disabled">{dpt?.nom}</div>}
                        { formEvents.editable && 
                          <div key={dpt.code} onClick={() => handleRemoveDepartment(dpt)} className="u-blue u-semi-bold u-mg-right-m u-mg-top-m c-btn">
                            {dpt?.nom && dpt.nom}
                            <CrossIcon size={10} className="u-mg-left-s" />
                          </div>}
                      </>
                    )
                  })}
                </div>
              </div>
              { formEvents.editable && 
                  <FormElement
                    value={departmentSelected}
                    options={departmentsDisplayedAsHTMLSelect && departmentsDisplayedAsHTMLSelect.map((dpt) => ({
                      label: `${dpt.nom} - ${dpt.code}`,
                      value: {
                        nom: dpt.nom,
                        code: dpt.code
                      }
                    }))}
                    name="departments"
                    type="select"
                    label="Départements"
                    className="u-mg-bottom-m"
                    required={false}
                    customRules={[
                      {
                          key: 'department-empty',
                          check: () => {
                            if(atLeastOneAdviceAndSupportIsChecked() && formFields.department?.length === 0) return false
                            return true
                          },
                          message: 'Vous devez sélectionner au moins un département.'
                      },
                      {
                        key: 'department-empty',
                        check: () => {
                          if(formFields.department?.length > 0 && !atLeastOneAdviceAndSupportIsChecked()) return false
                          return true
                        },
                        message: 'Si vous renseignez un département, veillez à cocher au moins un service conseil et accompagnement.'
                      }
                    ]}
                    customRulesTrigger={adviceServicesTriggerValidation}
                    showErrors={showLocalErrors}
                    onValidate={handleValidation}
                    onChange={handleChangeDepartments}
                    disabled={!formEvents.editable}
                  /> }
          </div>
        </div>
      </div>
    </>
)}

SupportInformation.propTypes = {
  formFields: shape({
    structureName: string,
    structureSiret: string,
    structureAddress: string,
    structureCity: string,
    structurePostCode: string,
    structurePhoneNumber: string,
    emailConnexion: string,
    beneficiaryEmail: string,
    structureEmployeeQuantity: string,
    structureEmployeeCategory: string
  }),
  setFormFields: func,
  formEvents: object,
  showLocalErrors: bool,
  handleValidation: func,
  handleInputChange: func
}

export default SupportInformation