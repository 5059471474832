import BackOfficeLayout from 'layouts/back-office'
import Card from 'components/platform/Card'
import { FormUploadProviderData } from './FormUploadProviderData'


export const UploadProviderData = () => {

    return (
        <BackOfficeLayout>
        <div className="u-flex u-flex-dir-col">
            <h1 className="c-h1 u-bold u-primary">Table de correspondance</h1>
            <Card className="u-flex u-flex-dir-col u-flex-start-vt u-mg-top-l" title="Charger la liste">
                <FormUploadProviderData />
            </Card>
        </div>
      </BackOfficeLayout>
    )
}