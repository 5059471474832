import React, { useState, useEffect } from 'react'
import * as Ibantools from 'ibantools'

import * as UserApi from 'services/api/UserApi'

import { isEmailValid, isValidFrenchPhoneNumber } from 'utils/validation'
import { isIBANValid, isBICValid } from 'utils/validation'

import { useAuth } from 'hooks/useAuth'
import { useAlert } from 'hooks/useAlert'

import FormElement from 'components/shared/FormElement'
import { getKeywords } from 'components/auth/FormSignup'

import ProtectedLayout from 'layouts/Protected'
import { scrollToTop } from 'utils/scrollTo'

const AuditAccountPage = () => {
    const { user, refreshUser } = useAuth()
    const { addAlert } = useAlert()

    const [showGlobalError, setShowGlobalError] = useState(false)
    const [formFields, setFormFields] = useState({})
    const [formEvents, setFormEvents] = useState({
        ready: true,
        editable: false
    })
    const [errors, setErrors] = useState([])
    const [showLocalErrors, setShowLocalErrors] = useState(false)

    const getFormFieldsFromUserData = (userData) => {
        return {
            mail: '',
            contactEmail: '',
            structurePostCode: '',
            structureAddress: '',
            structureCity: '',
            structurePhonenumber: '',
            website: '',
            iban: '',
            bic: '',
            ...userData,
        }
    }

    useEffect(() => {
        setFormFields(getFormFieldsFromUserData(user?.entity_data))
    }, [user])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
            ? target.checked
            : target.type === 'file'
                ? target
                : target.value
        const name = target.name

        setFormFields({
            ...formFields,
            [name]: value
        })
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const cancelModifications = () => {
        toggleEditableForm()
        setFormFields(getFormFieldsFromUserData(user?.entity_data))
        setShowGlobalError(false)
        setShowLocalErrors(false)
    }

    const toggleEditableForm = () => {
        setFormEvents({
            ...formEvents,
            editable: !formEvents.editable
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        // Validate inputs
        const formReady = !(errors.length > 0)

        if (!formReady) {
            scrollToTop()
            setShowLocalErrors(true)
            setShowGlobalError(true)
        } else {
            // hide error
            setShowGlobalError(false)
            setShowLocalErrors(false)
            // prevent form to be submitted again
            setFormEvents({
                ...formEvents,
                ready: false
            })

            // Manage API
            const editedUser = {
                entity_id: user?.entity_id,
                entity_data: formFields,
                status: user?.status,
                keywords: getKeywords(formFields),
                last_row_hash: user?.row_hash
            }

            updateUser(editedUser)
        }
    }

    const updateUser = async (userToUpdate) => {
        try {
            const { getError } = await UserApi.updateUser(userToUpdate)
            if (getError()) throw getError()

            addAlert('success', 'Modifications enregistrées')
            refreshUser()
            toggleEditableForm()
        } catch(error) {
            addAlert('error', error?.toString())
            setShowGlobalError(true)
        } finally {
            setFormEvents(formEvents => ({
                ...formEvents,
                ready: true
            }))
        }
    }

    return (
        <ProtectedLayout className="my-account-page u-pd-hz-m u-pd-top-m u-pd-hz-0@main u-pd-top-xl@main">
            <h1 className="c-h1 u-secondary u-center u-uppercase u-mg-bottom-xl">Mon compte</h1>
            <div className="u-mg-bottom-l l-container-lg">
                <div className='u-primary u-bold u-mg-bottom-s'>Bienvenue sur votre espace personnel OEPV !</div>
                <div className="u-fs-xs">
                    Pour toute question sur le Programme ou pour modifier vos informations, contactez notre équipe support en envoyant un mail à l’adresse suivante :
                    <a className="u-primary u-va-baseline" href="mailto:catalogue@employeurprovelo.fr"> catalogue@employeurprovelo.fr</a>
                </div>
            </div>
            <form>
                {showGlobalError &&
                    <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur lors de la validation du formulaire.</p>
                }
                {/* Structure */}
                <div className="u-pd-top-m u-pd-top-xl@main u-mg-bottom-l">
                    <div className="l-container-lg">
                        <h2 className="c-h2 u-bold u-primary u-mg-bottom-l">Information de mon établissement</h2>
                        <div className="l-grid u-mg-negative-hz-m ">
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Nom de l'établissement */}
                                <FormElement
                                    value={formFields.structureName}
                                    name="structureName"
                                    type="text"
                                    label="Nom de l'établissement"
                                    className="u-mg-bottom-m"
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={true}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* SIRET du site */}
                                <FormElement
                                    value={formFields.siret}
                                    name="siret"
                                    type="text"
                                    label="SIRET du site"
                                    className="u-mg-bottom-m"
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={true}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Address */}
                                <FormElement
                                    value={formFields.structureAddress}
                                    name="structureAddress"
                                    type="text"
                                    label="Adresse"
                                    className="u-mg-bottom-m"
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                    required={formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* structurePostCode */}
                                <FormElement
                                    value={formFields.structurePostCode + ''}
                                    name="structurePostCode"
                                    type="number"
                                    label="Code postal"
                                    className="u-mg-bottom-m"
                                    maxLength={5}
                                    customRules={[
                                        {
                                            key: 'structurePostCode-invalid',
                                            check: (value) => {
                                                return value ? (value.length === 5) : true
                                            },
                                            message: "Le code postal n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                    required={formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* City */}
                                <FormElement
                                    value={formFields.structureCity}
                                    name="structureCity"
                                    type="text"
                                    label="Ville"
                                    className="u-mg-bottom-m"
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                    required={formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Tel */}
                                <FormElement
                                    value={formFields.structurePhonenumber}
                                    name="structurePhonenumber"
                                    type="text"
                                    label="Téléphone de l'établissement"
                                    className="u-mg-bottom-m"
                                    customRules={[
                                        {
                                            key: 'structure-phone-invalid',
                                            check: (value) => isValidFrenchPhoneNumber(value),
                                            message: "Le format de numéro de téléphone n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                    required={formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Email de connexion*/}
                                <FormElement
                                    value={formFields.mail}
                                    name="mail"
                                    type="email"
                                    label="E-mail de connexion"
                                    className="u-mg-bottom-s"
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={true}
                                />
                                <p className="u-fs-xxs u-justify u-mg-bottom-m">Cet email correspond à votre identifiant pour vous connecter à la plateforme, c’est aussi celui qui sera utilisé si vous souhaitez modifier votre mot de passe de connexion. Il ne peut plus être modifié jusqu’à la fin du programme.</p>
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Email de contact*/}
                                <FormElement
                                    value={formFields.contactEmail}
                                    name="contactEmail"
                                    type="email"
                                    label="E-mail de contact"
                                    className="u-mg-bottom-s"
                                    onValidate={handleValidation}
                                    showErrors={showLocalErrors}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                    required={formEvents.editable}
                                />
                                <p className="u-fs-xxs u-justify u-mg-bottom-m">Cet email désigne l’adresse sur laquelle vous seront envoyés tous les emails de la plateforme (confirmations de demandes, informations sur votre parcours, validations de documents, récapitulatifs de commandes de prestations, bordereaux de versement de prime).</p>
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* website */}
                                <FormElement
                                    value={formFields.website}
                                    name="website"
                                    type="text"
                                    label="Site internet"
                                    className="u-mg-bottom-m"
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                />
                            </div>
                        </div>
                        <h2 className="c-h2 u-bold u-primary u-mg-bottom-l">
                            Coordonnées bancaires
                        </h2>
                        <div className="l-grid u-mg-negative-hz-m ">
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* IBAN */}
                                <FormElement
                                    value={formFields.iban}
                                    name="iban"
                                    type="text"
                                    label="IBAN"
                                    className="u-mg-bottom-m"
                                    required
                                    customRules={[
                                        {
                                            key: 'account-number-invalid',
                                            check: (value) => isIBANValid(value),
                                            message: "Le numéro IBAN n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    onBlur={(event) => {
                                        const result = { ...event }
                                        if (result.target) result.target.value = Ibantools.friendlyFormatIBAN(event.target.value)
                                        handleInputChange(result)
                                    }}
                                    disabled={!formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* BIC */}
                                <FormElement
                                    value={formFields.bic}
                                    name="bic"
                                    type="text"
                                    label="BIC"
                                    className="u-mg-bottom-m"
                                    required
                                    customRules={[
                                        {
                                            key: 'account-number-invalid',
                                            check: (value) => isBICValid(value),
                                            message: "Le numéro BIC n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="u-flex u-flex-center-hz u-pd-top-l u-mg-negative-hz-m u-pd-hz-m u-mg-hz-0@main u-pd-hz-0@main">
                    {formEvents?.editable ?
                        <>

                            <div className="u-pd-left-l">
                                <button
                                    type="button"
                                    className="c-btn c-btn--white-bordered u-fs-s c-btn--m u-pd-hz-l u-mg-bottom-xl"
                                    style={{ width: '100%', maxWidth: '600px' }}
                                    onClick={cancelModifications}
                                >
                                    Annuler
                                </button>
                            </div>
                            <div className="u-pd-left-l">
                                <button
                                    type="submit"
                                    className="c-btn c-btn--primary u-fs-s c-btn--m u-pd-hz-l u-mg-bottom-xl"
                                    style={{ width: '100%', maxWidth: '600px' }}
                                    onClick={handleSubmit}
                                >
                                    Enregistrer
                                </button>
                            </div>
                        </>
                        :
                        <button
                            type="button"
                            className="c-btn c-btn--primary u-fs-s c-btn--m u-pd-hz-l u-mg-bottom-xl"
                            onClick={toggleEditableForm}
                        >
                            Modifier mes coordonnées
                        </button>
                    }
                </div>

                {/* expertises */}
                <div className="u-bg-secondary u-pd-m  u-pd-hz-0@main u-pd-vt-xl@main">
                    <div className="l-container-lg">
                        <h2 className="c-h2 u-bold u-primary u-mg-bottom-l">Prestation de l'entité</h2>
                        <div className="l-grid u-mg-negative-hz-m">
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Aditeur actifs */}
                                <FormElement
                                    value={formFields.audit_available}
                                    name="dataType"
                                    type="radio"
                                    label="Organisme auditeur actif"
                                    options={[
                                        { value: true, label: 'Oui' },
                                        { value: false, label: 'Non' }
                                    ]}
                                    className="u-mg-bottom-l"
                                    classNameOptionsContainer="u-flex-dir-row"
                                    classNameLabel="u-mg-bottom-m"
                                    classNameOption="u-flex-0 u-flex-center-vt"
                                    disabled={true}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Nombre de lots */}
                                <FormElement
                                    value={formFields.lot_audit}
                                    name="lot_audit"
                                    type="text"
                                    label="Nombre de lots"
                                    className="u-mg-bottom-m"
                                    classNameInput="u-bg-transparent"
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ProtectedLayout>
    )
}

export default AuditAccountPage