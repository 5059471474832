import React from 'react'
import ReactTooltip from "react-tooltip"
import camelCase from "lodash.camelcase"
import { string } from 'prop-types'

import info from 'assets/images/info.svg'

const Information = ({
    label,
    value,
    className = '',
    classNameLabel = '',
    classNameValue = '',
    description
}) => (
    <div className={`u-flex u-flex-dir-col l-grid c-information-data ${className}`}>
        <div className={classNameLabel ? classNameLabel : 'u-grey90 u-fs-xs'}>
            {label}
        </div>
        <div className={classNameValue ? classNameValue : 'u-blue u-semi-bold u-fs-xs'}>
            {value}
        </div>
        {description && 
            <>
                <div data-tip={description}><a data-tip data-for={camelCase(label)} className='c-tooltip-icon'><img src={`${info}`} /></a></div>
                <ReactTooltip effect='solid' id={camelCase(label)} aria-haspopup='true' backgroundColor='#005a7c' >
                    <p>{description}</p>
                </ReactTooltip>
            </>
        }
    </div>
)

Information.propTypes = {
    label: string.isRequired,
    value: string,
    className: string,
    classNameLabel: string,
    classNameValue: string,
    description: string,
}

export default Information