import React from 'react'
import RCSlider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { isNotEmpty } from 'utils/validation'
import { arrayOf, bool, func, number, object, oneOf, shape, string } from 'prop-types'

const Slider = ({
    // Attributes
    value,
    step,
    min,
    max,
    name,
    unit,
    label = '',
    disabled,
    // Styles
    style,
    className,
    classNameLabel = '',
    // Error management
    required = false,
    customRules,
    showErrors,
    // Handler
    onChange,
    onAfterChange = () => {},
    onValidate
}) => {
    const [errors, setErrors] = React.useState([])

    const rules = React.useMemo(() => [
        {
            key: 'input-empty',
            check: (value) => required ? isNotEmpty(value) : true,
            message: 'Ce champ est obligatoire'
        },
        ...(customRules || [])
    ], [required]) // eslint-disable-line
    // customRules in dependencies array trigger an infinite loop,
    // because custom rules include a check property defining a new function,
    // preventing useMemo to return a valid shallow-comparison
    // or event useDeepMemo to return a valid deep-comparison

    const validate = React.useCallback((currentValue) => {
        const newErrors = []
        rules.forEach(rule => {
            if (!rule.check(currentValue)) {
                newErrors.push({
                    origin: name,
                    rule: rule.key,
                    message: rule.message
                })
            }
        })
        setErrors(newErrors)
        onValidate(name, newErrors)
    }, [rules, name, onValidate])

    // validate on mount and each time value change
    React.useEffect(() => {
        validate(value)
        return () => {
            // On unmounted erros and validation are no longer needed
            setErrors([])
            if (onValidate) onValidate(name, [])
        }
    }, [value, validate, onValidate, name])

    const handleChange = (event) => {
        const inputValue = {
            target: {
                name: name,
                value: event
            }
        }
        onChange(inputValue)
    }

    return (
        <>
            <div className={'c-form-group ' + (className)} style={style}>
                {label && 
                <label className={'c-label u-mg-bottom-s ' + classNameLabel} htmlFor="code">
                    {label} {required && '*'}:
                    <span className="u-bold u-mg-left-s">{value}{unit}</span>
                </label>
                }
                <RCSlider
                    step={step}
                    min={min}
                    max={max}
                    value={value}
                    onChange={(e) => handleChange(e)}
                    onAfterChange={onAfterChange}
                    disabled={disabled}
                    className={'c-range u-pd-vt-m'}
                />

                {/* Error messages */}
                {showErrors && errors && errors.map((error, index) => {
                    return <p key={index} className="u-fs-xs u-danger">{error.message}</p>
                })}
            </div>
        </>
    )
}

Slider.propTypes = {
    // Attributes
    value: number,
    step: number,
    min: number,
    max: number,
    name: string,
    unit: oneOf(['km']),
    label: string,
    disabled: bool,
    // Styles
    style: object,
    className: string,
    classNameLabel: string,
    // Error management
    required: bool,
    customRules: arrayOf(
        shape({
            key: string,
            check: func,
            message: string
        })
    ),
    showErrors: bool,
    // Handler
    onChange: func,
    onAfterChange: func,
    onValidate: func
}

export default Slider
