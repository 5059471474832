import React from 'react'
import { string } from 'prop-types'

import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'

const Bid = ({
    billUrl,
    paymentProofUrl
}) => (
    <>
        <div className="u-mg-top-l u-mg-bottom-s u-blue u-fs-m u-bold">
            Facture et preuve de paiement
        </div>
        <InformationToVisualize 
            label="Vérifier la facture acquittée" 
            url={billUrl} 
        />
        <InformationToVisualize 
            label="Vérifier la preuve de paiement" 
            url={paymentProofUrl} 
        />
    </>
)

Bid.propTypes = {
    billUrl: string,
    paymentProofUrl: string
}

export default Bid