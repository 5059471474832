import React from "react"
import { isNotEmpty, isDefined } from "utils/validation"

const Radio = ({
    // Attributes
    value,
    options,
    name,
    label = "",
    disabled,
    readonly = false,
    // Styles
    style,
    className = "",
    classNameLabel = "",
    classNameOptionsContainer = "",
    classNameOption = "",
    classNameOptionLabel = "",
    // Error management
    required = false,
    customRules,
    showErrors,
    // Handler
    onChange,
    onValidate
}) => {
    const [errors, setErrors] = React.useState([])

    const rules = React.useMemo(() => [
        {
            key: "radio-empty",
            check: (value) => required ? isNotEmpty(value) : true,
            message: "Ce champ est obligatoire"
        },
        ...(customRules || [])
    ], [required]) // eslint-disable-line
    // customRules in dependencies array trigger an infinite loop,
    // because custom rules include a check property defining a new function,
    // preventing useMemo to return a valid shallow-comparison
    // or event useDeepMemo to return a valid deep-comparison

    const validate = React.useCallback((currentValue) => {
        let newErrors = []
        rules.forEach(rule => {
            if (!rule.check(currentValue)) {
                newErrors.push({
                    origin: name,
                    rule: rule.key,
                    message: rule.message
                })
            }
        })
        setErrors(newErrors)
        onValidate(name, newErrors)
    }, [rules, name, onValidate])

    // validate on mount and each time value change
    React.useEffect(() => {
        validate(value)
        return () => {
            // On unmounted erros and validation are no longer needed
            setErrors([])
            if (onValidate) onValidate(name, [])
        }
    }, [value, validate, onValidate, name])

    return (
        <>
            <div className={className} style={style}>
                {label && <label className={"c-label u-mg-bottom-xs " + classNameLabel}>{label} {required && "*"}</label>}
                <div className={"u-inline-flex " + classNameOptionsContainer}>
                    {options && options.map((option, index) => {
                        return (
                            <label
                                htmlFor={option.id}
                                className={
                                    "c-radio u-mg-bottom-s "
                                    + (classNameOption || "")
                                    + ((value === (isDefined(option.value) ? option.value : option.label)) ? " is-checked" : "")
                                    + (disabled ? " is-disabled" : "")
                                }
                                key={`${option.id}-${index}`}
                            >
                                <input
                                    className="u-sr-only"
                                    type="radio"
                                    value={isDefined(option.value) ? option.value : option.label}
                                    name={name}
                                    id={option.id}
                                    disabled={disabled}
                                    readOnly={readonly}
                                    required={required}
                                    onChange={(e) => !readonly && onChange(e)}
                                    onBlur={() => validate(value)}
                                />
                                <span className={classNameOptionLabel}>{option.label}</span>
                            </label>
                        )
                    })}
                </div>
                {/* Error messages */}
                {showErrors && errors && errors.map((error) => {
                    return <p key={error.message} className="u-fs-xs u-danger">{error.message}</p>
                })}
            </div>
        </>
    )
}


export default Radio
