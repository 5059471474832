import { get as baseGet, set as baseSet, remove as baseRemove } from 'local-storage'

const STORAGE_KEY = 'OEPV_sessionConfig'
const ENTITY_ID_KEY = 'OEPV_id'
const BACKOFFICE_USER_ID_KEY = 'OEPV_BACKOFFICE_USER_id'
const REMEMBER_MAIL_KEY = 'OEPV_rememberMail'

const localStorageService = {
    get(key, defaultValue = null) {
        const value = baseGet(key)

        return value === null ? defaultValue : value
    },

    set(key, value) {
        return baseSet(key, value)
    },

    remove(key) {
        baseRemove(key)
    }
}

export { localStorageService as default, REMEMBER_MAIL_KEY, STORAGE_KEY, ENTITY_ID_KEY, BACKOFFICE_USER_ID_KEY}