import { isInZNIList } from 'utils/domain/beneficiary'
import {
  AUDIT_CERTIFICATION_DOC_GENDER,
  AUDIT_CGV_DOC_GENDER,
  AUDIT_REPORT_DOC_GENDER,
  getLabelFromAuditStatus,
  RESERVED_STATUS
} from 'utils/domain/audit'
import certificationUtil from 'utils/domain/certification'
import {
  getFeedbackToEquipmentBeneficiary,
  getFeedbackToReportBeneficiary,
  getFeedbackToTrainingBeneficiary,
  isTechnicalFolder,
  isMobilityFolder,
} from 'utils/domain/request'
import { convertDataToStringFromArray } from 'utils/downloadFile'

// ------------------------------------ //
//               Header
// ------------------------------------ //

export const requestEquipmentsHeader = [
  [
    ...Array(4).fill(null),
    'Equipements',
    ...Array(19).fill(null),
  ],
  [
    ...Array(4).fill(null),
    'Informations bénéficiaires',
    ...Array(8).fill(null),
    'Informations BO Gestion',
    ...Array(9).fill(null),
  ]
]

export const requestMobilityHeader = [
  [
    ...Array(3).fill(null),
    'Informations bénéficiaires',
    ...Array(2).fill(null),
    'Informations prestation',
    ...Array(4).fill(null),
    'Informations BO Gestion',
    ...Array(1).fill(null),
  ]
]

export const requestSupportHeader = [
  [
    ...Array(5).fill(null),
    'Conseils et accompagnements',
    ...Array(7).fill(null),
  ],
  [
    ...Array(5).fill(null),
    'Informations bénéficiaires',
    ...Array(1).fill(null),
    'Informations BO Gestion',
    ...Array(1).fill(null),
  ]
]

// ------------------------------------ //
//               Sub-Header
// ------------------------------------ //

export const requestEquipmentsSubHeader = [
  'N° Bénéficiaire',
  'SIRET',
  'Nom de l\'établissement',
  'Date de la demande',
  'N° de dossier',
  // Equipements
  // Informations bénéficiaires
  'Nb de vélos attachés',
  'Nb de vélos abrités',
  'Nb de vélos sécurisés',
  'Pompes',
  'Casiers',
  'Kit outillage',
  'Pose oui/non',
  'Nom du fournisseur sélectionné',
  // Informations BO Gestion
  'Validation du devis',
  'Statut',
  'Montant HT saisi par le bénéficiaire',
  'Montant HT théorique à rembourser',
  'Montant HT réel à rembourser',
]

export const requestMobilitySubHeader = [
  'N° Bénéficiaire',
  'SIRET',
  'Nom de l\'établissement',
  'Date de la demande',
  // Informations bénéficiaires
  'N° de dossier',
  'Nom du prestataire sélectionné',
  // Informations prestation
  'Catégorie de prestation',
  'Prestation',
  'Nombre de sessions',
  'Nombre de participants',
  'Nombre de vélos réparés',
  // Informations BO Gestion
  'Statut',
  'Montant HT',
  'Montant HT théorique à rembourser',
  'Montant HT réel à rembourser'
]

export const requestSupportSubHeader = [
  'N° Bénéficiaire',
  'SIRET',
  'Nom de l\'établissement',
  'Date de la demande',
  'N° de dossier',
  // Conseils et accompagnement
  // Informations bénéficiaires
  'Nom du prestataire sélectionné',
  'Prestation',
  // Informations BO Gestion
  'Statut',
  'Nombre de jours pour la réalisation de la prestation',
  'Montant HT',
  'Montant HT théorique à rembourser',
  'Montant HT réel à rembourser',
]

export const requestScopingMeetingSubHeader = [
  'Date de la demande',
  'Raison sociale',
  'Nombre d\'employés',
  'Téléphone',
  'Email',
  'Code postal',
  'Statut de la réunion',
  'Rapport de cadrage téléchargé'
]

export const requestAuditSubHeader = [
  'Date de la demande',
  'N° Bénéficiaire',
  'Raison sociale',
  'Nombre d\'employés',
  'ZNI',
  'Téléphone',
  'Email',
  'Nom de l\'organisme auditeur',
  'CGV téléchargées',
  'Statut de l\'audit',
  'Rapport d\'audit téléchargé',
  'Attestation téléchargée',
  'Label',
  'Date de changement de statut "Bloqué"'
]

// ------------------------------------ //
//               Mapping
// ------------------------------------ //

export const mapEquipmentsRequests = (requests) => requests.map(request => {

  const requestArray = [
    request?.request_entity_id,
    request?.siret,
    request?.entity?.entity_data?.structureName,
    request?.create_date,
    request?.request_fub_id,
    // Equipements
    // Informations bénéficiaires (we use the data validated by the back-office team if it exists, otherwise we use the data from the bid)
    request?.request_data?.operation_data?.nbOfBikesPlaces
      ? request?.request_data?.operation_data?.nbOfBikesPlaces
      : request?.request_data?.operation_data?.request_description?.bike_hitch
        ? request?.request_data?.operation_data?.request_description?.bike_hitch
        : request?.request_description?.bike_hitch,
    request?.request_data?.operation_data?.request_description?.bike_shelter ? request?.request_data?.operation_data?.request_description?.bike_shelter : request?.request_description?.bike_shelter,
    request?.request_data?.operation_data?.request_description?.bike_secure_storage ? request?.request_data?.operation_data?.request_description?.bike_secure_storage : request?.request_description?.bike_secure_storage,
    request?.request_data?.operation_data?.request_description?.bike_pumps ? request?.request_data?.operation_data?.request_description?.bike_pumps : request?.request_description?.bike_pumps,
    request?.request_data?.operation_data?.request_description?.lockers ? request?.request_data?.operation_data?.request_description?.lockers : request?.request_description?.lockers,
    request?.request_data?.operation_data?.request_description?.bike_toolkit ? request?.request_data?.operation_data?.request_description?.bike_toolkit : request?.request_description?.bike_toolkit,
    request?.request_data?.operation_data?.request_description?.installation ? request?.request_data?.operation_data?.request_description?.installation : request?.request_description?.installation,
    request?.provider_data?.structureName,
    // Informations BO Gestion
    request?.status >= 3 ? 'Oui' : 'Non',
    getFeedbackToEquipmentBeneficiary(request?.status),
    request?.quote_amount,
    request?.request_data?.operation_data?.amount_to_refund,
    request?.request_data?.operation_data?.real_amount_to_refund
  ]

  return convertDataToStringFromArray(requestArray) //allows consistent alignment in excel column

})

export const mapMobilityRequests = (requests) => requests.map(request => {
  const getRequestCategory = (requestFubId) => {
    if (isTechnicalFolder(requestFubId)) return 'Services techniques'
    if (isMobilityFolder(requestFubId)) return 'Services éducatifs'
    return ''
  }

  const getRequestTypeName = (requestType) => {
    switch(requestType) {
        case 'training-company':
            return 'Formation "Vélo aux abords du lieu de travail"'
        case 'driving-safely-company': 
            return 'Formation « Rouler en sécurité​ »'
        case 'training-back-to-bike':
            return 'Formation "Remise en selle"'
        case 'training-electric-bike':
            return 'Formation pratique prise en main vélo à assistance électrique (VAE)'
        case 'pratical-training-electric-bike':
            return 'Formation pratique prise en main vélo à assistance électrique (VAE/VCAE)'
        case 'training-traffic-code':
          return 'Formation théorique « La route vue du guidon »'
        case 'technical-check':
          return 'Contrôle technique des vélos personnels des employés'
        case 'technical-maintenance':
            return 'Entretien et réparation des vélos personnels des employés'
        case 'bike-overhaul':
          return 'Révision du vélo des salariés'
        case 'technical-workshops':
          return 'Atelier mécanique participatif'
        default:
            return ''
    }
  }

  const requestArray = [
    request?.request_entity_id,
    request?.siret,
    request?.entity?.entity_data?.structureName,

    // Informations bénéficiaires (we use the data validated by the back-office team if it exists, otherwise we use the data from the bid)
    request?.create_date,
    request?.request_fub_id,
    request?.providerStructureName,

    // Informations prestation
    getRequestCategory(request?.request_fub_id),
    getRequestTypeName(request?.request_description?.type),
    request?.request_data?.operation_data?.sessionsQuantity ?? request?.request_data?.operation_data?.request_description?.sessionsQuantity ?? request?.request_description?.sessionsQuantity,
    request?.request_data?.operation_data?.personsQuantity ?? request?.request_data?.operation_data?.request_description?.personsQuantity ?? request?.request_description?.personsQuantity,
    request?.request_data?.operation_data?.bikesQuantity ?? request?.request_data?.operation_data?.request_description?.bikesQuantity ?? request?.request_description?.bikesQuantity,

   // Informations BO Gestion
    getFeedbackToTrainingBeneficiary(request?.status),
    request?.request_data?.operation_data?.total_amount ?? request?.request_description?.amount,
    request?.request_data?.operation_data?.amount_to_refund,
    request?.request_data?.operation_data?.real_amount_to_refund
  ]

  return convertDataToStringFromArray(requestArray) //allows consistent alignment in excel column

})

export const mapSupportRequests = (requests) => requests.map(request => {
  const getRequestTypeName = (requestType) => {
    switch(requestType) {
        case 'diagnostic-mobility':
            return 'Diagnostic mobilité axé sur le vélo'
        case 'setup-and-training':
        case 'training-and-tools-of-cycling-team':
            return 'Organisation, formation et outillage de l\'équipe vélo'
        case 'action-plan':
            return 'Élaboration/enrichissement d\'un plan d’actions pro-vélo et appui opérationnel à la réalisation'
        case 'audit-preparation':
          return 'Prise en main du cahier des charges employeur pro-vélo, préparation à l’audit, audit à blanc'
        case 'specifications-training':
          return 'Formation au cahier des charges, audit à blanc'
        case 'cycling-strategy':
          return 'Définition d’une stratégie vélo'
        default:
            return ''
    }
  }

  const requestArray = [
    request?.request_entity_id,
    request?.siret,
    request?.entity?.entity_data?.structureName,
    request?.create_date,
    request?.request_fub_id,
    // Conseils et accompagnements
    // Informations bénéficiaires
    request?.providerStructureName,
    getRequestTypeName(request?.request_description?.type),
    // Informations BO Gestion
    getFeedbackToReportBeneficiary(request?.status),
    request?.request_data?.operation_data?.daysCompleted,
    request?.request_data?.operation_data?.total_amount ?? request?.request_description?.amount,
    request?.request_data?.operation_data?.amount_to_refund,
    request?.request_data?.operation_data?.real_amount_to_refund,
  ]

  return convertDataToStringFromArray(requestArray) //allows consistent alignment in excel column
})

export const mapScopingMeetingRequests = (requests) => requests.map(request => {

  const requestArray = [
    request?.create_date,
    request?.entity_data?.structureName,
    request?.entity_data?.structureEmployeeQuantity,
    request?.entity_data?.structurePhonenumber,
    request?.entity_data?.mail,
    request?.entity_data?.structurePostCode,
    getLabelFromAuditStatus(request?.framing_meeting_status),
    request?.framing_meeting_media_rapport_url ? 'Oui' : 'Non'
  ]

  return convertDataToStringFromArray(requestArray) //allows consistent alignment in excel column

})

export const mapAuditRequests = (requests) => requests.map(request => {
  const requestArray = [
    request?.create_date,
    request?.entity_id,
    request?.entity_data?.structureName,
    request?.entity_data?.structureEmployeeQuantity,
    isInZNIList(request?.entity_data?.structurePostCode) ? 'Oui' : 'Non',
    request?.entity_data?.structurePhonenumber,
    request?.entity_data?.mail,
    request?.provider_data?.structureName,
    request?.audit_media?.documents?.some(document => document.media_gender === AUDIT_CGV_DOC_GENDER) ? 'Oui' : 'Non',
    getLabelFromAuditStatus(request?.audit_status),
    request?.audit_media?.documents?.some(document => document.media_gender === AUDIT_REPORT_DOC_GENDER) ? 'Oui' : 'Non',
    request?.audit_media?.documents?.some(document => document.media_gender === AUDIT_CERTIFICATION_DOC_GENDER) ? 'Oui' : 'Non',
    certificationUtil.getLabelByCertificationLevel(request?.audit_label),
    request?.audit_status === RESERVED_STATUS ? request.modify_date : '',

  ]

  return convertDataToStringFromArray(requestArray) //allows consistent alignment in excel column

})

export const requestsEquipmentsCellsMerged = [
  // Equipments
  { s: { r: 0, c: 4 }, e: { r: 0, c: 23 } },
  // Informations bénéficiaires
  { s: { r: 1, c: 4 }, e: { r: 1, c: 17 } },
  // Informations BO Gestion
  { s: { r: 1, c: 18 }, e: { r: 1, c: 23 } },
]

export const requestsMobilityCellsMerged = [
  // Informations bénéficiaires
  { s: { r: 0, c: 3 }, e: { r: 0, c: 4 } },
  // Services éducatifs
  { s: { r: 0, c: 5 }, e: { r: 0, c: 8 } },
  // Services techniques
  { s: { r: 0, c: 9 }, e: { r: 0, c: 11 } },
  // Informations BO Gestion
  { s: { r: 0, c: 12 }, e: { r: 0, c: 13 } }
]

export const requestsSupportCellsMerged = [
  // Conseils et accompagnements
  { s: { r: 0, c: 3 }, e: { r: 0, c: 12 } },
  // Informations bénéficiaires
  { s: { r: 1, c: 3 }, e: { r: 1, c: 8 } },
  // Informations BO Gestion
  { s: { r: 1, c: 9 }, e: { r: 1, c: 11 } }
]

export const requestsCellsMerged = {
  equipments: requestsEquipmentsCellsMerged,
  mobility: requestsMobilityCellsMerged,
  support: requestsSupportCellsMerged,
}

// ------------------------------- //
//             Export
// ------------------------------- //

export const getRequestsToExport = (requests, requestType) => {
  const requestHeader = {
    'request-equipment': requestEquipmentsHeader,
    'request-mobility': requestMobilityHeader,
    'request-advice-and-support': requestSupportHeader,
    'request-scoping-meeting': [],
    'request-audit': [],
  }[requestType]
  const requestSubHeader = {
    'request-equipment': requestEquipmentsSubHeader,
    'request-mobility': requestMobilitySubHeader,
    'request-advice-and-support': requestSupportSubHeader,
    'request-scoping-meeting': requestScopingMeetingSubHeader,
    'request-audit': requestAuditSubHeader,
  }[requestType]
  const mapper = {
    'request-equipment': mapEquipmentsRequests,
    'request-mobility': mapMobilityRequests,
    'request-advice-and-support': mapSupportRequests,
    'request-scoping-meeting': mapScopingMeetingRequests,
    'request-audit': mapAuditRequests,
  }[requestType]

  // prepare data
  return [
    // Header array
    ...requestHeader,
    requestSubHeader,
    // Data arrays
    ...mapper(requests)
  ]
}