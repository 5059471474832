import React from "react"

const CheckIcon = ({ size, className }) => {
	return (
		<svg height="100" width="100" viewBox="0 0 515.556 515.556" xmlns="http://www.w3.org/2000/svg" style={{ width: size, height: size }} className={className || ""} aria-hidden="true">
			<path fill="currentColor" d="M0 274.226l176.549 176.886L515.556 112.44l-48.67-47.997-290.337 290L47.996 225.891z"/>
		</svg>
	)
}

export default CheckIcon
