import React from 'react'
import { array } from 'prop-types'

import Table from 'components/shared/Table'
import Card from 'components/platform/Card'

import { paymentColumns } from './paymentColumns'
import './styles.scss'

const Payments = ({ requests = [] }) => (

    <Card title="Versement de la Prime" className="l-col-12 payment-page">
        {Boolean(requests.length) && (
            <Table
                className="payment-table u-right"
                columns={paymentColumns}
                data={requests}
                rowClassName='u-pd-vt-m u-fs-xs'
            />
        )}
        {!requests.length && (
            <div className="u-grey90 u-fs-xs u-mg-top-s">
                Aucun versement effectué.
            </div>
        )}
    </Card>
)

Payments.propTypes = {
    requests: array
}

export default Payments