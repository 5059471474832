import React from 'react'
import { array } from 'prop-types'
import { useTable } from 'react-table'

import Emoji from 'components/shared/Emoji'

const BidTable = ({
    data
}) => {

    // providers table
    const columns = React.useMemo(
        () => [
            {
                Header: 'Nom de l\'intervenant',
                accessor: 'provider_data.structureName',
            },
            {
                Header: 'Téléphone',
                accessor: 'provider_data.structurePhonenumber',
            },
            {
                Header: 'Adresse e-mail',
                accessor: 'provider_data.contactEmail'
            }
        ],
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows
    } = useTable(
        {
            columns,
            data
        }
    )

    const getColumnSize = React.useCallback((columnName) => {
        // sum should be 24
        switch (columnName) {
            case columns[0].accessor:
                return 'c-table__cell--5'
            case columns[1].accessor:
                return 'c-table__cell--3'
            case columns[2].accessor:
                return 'c-table__cell--6'
            // case "properties.natlscale":
            //     return "c-table__cell--6"
            default:
                return ''
        }
    }, []) // eslint-disable-line

    return (
        <>
            <table className="c-table c-table--secondary c-table--responsive" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr className="c-table__row" {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th className={'c-table__cell u-pd-m ' + getColumnSize(column?.id)} {...column.getHeaderProps()}>
                                    <span className={'u-ellipsis'}>{column.render('Header')}</span>
                                </th>
                            ))}
                            <th className={'c-table__cell c-table__cell--10 u-pd-m'}>
                                <span className={'u-ellipsis'}>Récapitulatif de la demande</span>
                            </th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)
                        return (
                            <tr className={'c-table__row u-pd-m u-pd-0@main'} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td className={`c-table__cell u-pd-m@main u-fs-xxs ${getColumnSize(cell?.column?.id)}`} 
                                            {...cell.getCellProps()}
                                        >
                                            <span className={'u-ellipsis'}>{cell.render('Cell')}</span>
                                        </td>
                                    )
                                })}
                                <td className="c-table__cell u-pd-m@main u-fs-xxs c-table__cell--10">
                                    <span className={'u-ellipsis'}>
                                        {row?.original?.summary?.label} : {row?.original?.summary?.bikesQuantity
                                            ? row?.original?.summary?.bikesQuantity + ' vélo(s)'
                                            : row?.original?.summary?.sessionsQuantity + ' session(s)'}
                                    </span>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            { data.length === 0 &&
                <div className="u-bg-white u-primary u-center u-pd-hz-m u-pd-vt-l">
                    Aucun prestataire n'existe pour cette demande <Emoji label="visage triste" symbol="😟" />
                </div>
            }
        </>
    )
}

BidTable.propTypes = {
    data: array
}

export default BidTable