import React from 'react'
import { NavLink, useParams } from 'react-router-dom'

import ProtectedLayout from 'layouts/Protected'
import ArrowIcon from 'components/svg/Arrow'
import CatalogRoutes from 'components/catalog/CatalogRoutes'
import RouterTabs from 'components/shared/RouterTabs'
import { privateRoutes } from 'router/routes'

const CatalogItemPage = () => {
    const { slug } = useParams()

    const getTabFromUrl = (url) => CatalogRoutes.find(tab => tab.url === url)

    return (
        <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main">
            <div className="l-container u-relative">
                <NavLink to={privateRoutes.catalog.path} className="u-flex">
                    <span className="u-flex" style={{ transform: 'rotate(180deg)' }}><ArrowIcon /></span>
                    <span className="u-mg-left-xs">Retour au catalogue</span>
                </NavLink>

                <RouterTabs
                    tabsContent={CatalogRoutes}
                    activeTab={getTabFromUrl(slug) || CatalogRoutes[0]}
                    basename={privateRoutes.catalog.path}
                    componentProperty={'componentSelection'}
                />

            </div>
        </ProtectedLayout>
    )
}

export default CatalogItemPage
