import React, { useState, useCallback, useEffect } from 'react'
import { func, number, shape, string, oneOf } from 'prop-types'
import Spinner from 'react-svg-spinner'

import { useAlert } from 'hooks/useAlert'
import uploadFile from 'services/uploadFile'
import * as CatalogApi from 'services/api/CatalogApi'
import { getMediaUrlPrefix } from 'utils/domain/user'
import {
    isBetweenStatus,
    CHOOSEN_PROVIDER,
    requestList,
    isNeedComplementDocumentsBid,
    isNeedComplementAfterSignOffSheetsUploaded,
    isWaitingForQuote
} from 'utils/domain/request'
import Emargement from './Emargement'
import { attendanceSheetRequest } from 'components/catalog/catalogUtils'

const SIGNINGS_CODE = 'EMARGEMENTS'
const REPORT_CODE = 'INTERVENTION'
const MAX_EMARGEMENT_DOCS = 20

const FormEmargement = ({
    request,
    requestPosition,
    refetchRequests,
    requestType = ''
}) => {
    const { addAlert } = useAlert()
    const [documents, setDocuments] = useState(new Map())
    const [formState, setFormState] = useState({ readyToSubmit: true })
    const [position, setPosition] = useState(requestPosition)
    const [showLocalErrors, setShowLocalErrors] = useState(false)
    const [errors, setErrors] = useState([])
    const [uploading, setUploading] = useState(false)
    const [showGlobalError] = useState(false)

    const isSendEmargementsEnabled = !isNeedComplementAfterSignOffSheetsUploaded(request?.status) &&
                                    (isBetweenStatus({ status: request.status, end: CHOOSEN_PROVIDER }) || isNeedComplementDocumentsBid(request?.status))

    const isAdviceMonitoring = requestType === requestList.adviceAndSupportFolder

    useEffect(() => {
        getEmargements()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getEmargements = async () => {
        try {
            const { res: history, getError } = await CatalogApi.getRequestHistory(request.request_id)
            if(getError()) throw getError()

            if(history.length > 0) {

                // Operations are filtered
                const operationCode = isAdviceMonitoring ? REPORT_CODE : SIGNINGS_CODE
                const emargementOperations = history.filter(stack => stack.request_data.operation_code === operationCode)

                // Set last EMARGEMENTS operation as documents state
                if(emargementOperations.length > 0) {
                    const lastEmargementsOperation = emargementOperations[0]
                    const documents = lastEmargementsOperation.request_data.operation_data.documents

                    documents.forEach((document) => {
                        setDocuments((documents) => {
                            const documentsClone = new Map(documents)
                            documentsClone.set(document.media_gender, document)
                            return documentsClone
                        })
                    })

                    const currentPosition = documents[0]?.media_gender?.charAt(0) || 0
                    setPosition(currentPosition)
                    return
                }
            }
            setDocuments(new Map([[`${position}-EMARG-1`, {}]]))
        } catch (error) {
            addAlert('error', error?.toString())
        }
    }

    const handleValidation = useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const handleInputChange = (event) => {
        const newDocument = event.target
        setShowLocalErrors(false)
        setDocuments((documents) => {
            const documentsClone = new Map(documents)
            documentsClone.set(newDocument.name, newDocument)
            return documentsClone
        })
    }


    const getNextDocumentKey = (documents) => {
        const nextKey = Math.max(...Array.from(documents).map(([key]) => Number(key.split('-')[2])), 0) + 1
        return `${position}-EMARG-${nextKey}`
    }

    const addEmergement = () => {
        setShowLocalErrors(false)
        setDocuments((documents) => {
            const documentsClone = new Map(documents)
            documentsClone.set(getNextDocumentKey(documentsClone), {})
            return documentsClone
        })
    }

    const removeEmergement = (name) => {
        setDocuments((documents) => {
            const documentsClone = new Map(documents)
            documentsClone.delete(name)
            return getDocumentsWithSortedKeys(documentsClone)
        })
    }

    const getDocumentsWithSortedKeys = (documents) => {
        const documentsWithSortedKeys = new Map()
        Array.from(documents).forEach(([, document], index) => {
            documentsWithSortedKeys.set(`${position}-EMARG-${index + 1}`, document)
        })
        return documentsWithSortedKeys
    }

    const uploadDocuments = async () => {
        try {
            setUploading(true)
            const documentsWithSortedKeys = getDocumentsWithSortedKeys(documents)
            const documentsUploaded = await Promise.all(
                Array.from(documentsWithSortedKeys).map(([key, document]) => uploadFile(key, document))
            )
            const editedDocuments = documentsUploaded.map(documentUploaded => {
                const currentDocument = documents.get(documentUploaded.name)
                const media_id = currentDocument.media_id || currentDocument.id
                const media_url = currentDocument.media_url || `${getMediaUrlPrefix()}${media_id}.${documents.get(documentUploaded.name).extension}`
                const media_hash = currentDocument.media_hash || currentDocument.value

                return {
                    media_gender: documentUploaded.name,
                    media_id,
                    media_url,
                    media_hash,
                }
            })

            // We change the operationCode if we send an intervention rapport in "Conseil et accompagnement"
            const operationCode = isAdviceMonitoring ? 'INTERVENTION' : 'EMARGEMENTS'

            const { getError } = await attendanceSheetRequest({ request, operationCode, documents: editedDocuments })
            if(getError()) throw getError()

            const successMessage = isAdviceMonitoring ? 'Le rapport d\'intervention a bien été ajouté' : 'Les feuilles d\'émargements ont bien été ajoutées'
            addAlert('success', successMessage)
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setFormState({ readyToSubmit: true })
            setUploading(false)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        // Validate inputs
        const formReady = !(errors.length > 0)
        if (!formReady) {
            setShowLocalErrors(true)
            return
        }
        setFormState({ readyToSubmit: false })
        await uploadDocuments()
        await getEmargements()
        refetchRequests()
        setFormState({ readyToSubmit: true })
    }

    const hasDocumentUploaded = () => Array.from(documents.values()).some(document => Boolean(document.id))

    const getSubmitLabel = () => {
        if(isNeedComplementDocumentsBid(request?.status)){
            return hasDocumentUploaded() ? 'Mettre à jour mes pièces justificatives' : 'Confirmer mes pièces justificatives'
        }

        return isAdviceMonitoring ? 'Envoyer la synthèse d’intervention' : 'Envoyer la/les feuille(s)'
    }

    const canAddDocuments = documents.size < MAX_EMARGEMENT_DOCS && isSendEmargementsEnabled && !isAdviceMonitoring && (isNeedComplementDocumentsBid(request?.status) || isWaitingForQuote(request?.status))

    return (
        <>
            <div className="u-pd-top-l u-pd-borrom-s u-black">
                Pour demander le versement de votre prime, vous devez vous munir
                {isAdviceMonitoring ? ' de la synthèse d’intervention de la prestation, ' : ' de la feuille d’émargement de la session (ou les feuilles), '}
                votre RIB, votre facture acquittée et la preuve de votre paiement.
            </div>


            <div className="u-pd-top-s u-pd-bottom-s u-semibold u-fs-m ">
                {isAdviceMonitoring ? 'Envoyer la synthèse d’intervention' : 'Envoyer une ou des feuilles d’émargement'}
            </div>

            <form >
                {showGlobalError &&
                    <p className="u-fs-xs u-danger u-mg-bottom-m">L'envoi des documents n'a pu être effectué.</p>
                }
                <div className="l-grid u-mg-negative-hz-m">
                    {Array.from(documents).map(([key, document], index) => (
                        <div className="l-col-6" key={`${key}-${document.id}`}>
                            <Emargement
                                position={index + 1}
                                name={key}
                                document={document}
                                requestType={requestType}
                                showErrors={showLocalErrors}
                                handleValidation={handleValidation}
                                handleChange={handleInputChange}
                                handleRemove={removeEmergement}
                                disabledRemoveAction={documents.size <= 1}
                                requestStatus={request?.status}
                            />
                        </div>
                        )
                    )}
                </div>
                { canAddDocuments &&
                    <button
                        type="button"
                        className="c-btn u-pd-hz-l u-mg-left-m "
                        onClick={addEmergement}
                        disabled={!formState.readyToSubmit}
                    >
                        Ajouter une feuille d'émargement
                    </button>
                }
                {isSendEmargementsEnabled &&
                    (
                        <div className="u-flex u-flex-center-hz u-mg-top-l">
                            <button
                                type="submit"
                                className="c-btn c-btn--secondary u-pd-hz-xl"
                                onClick={handleSubmit}
                                disabled={!formState.readyToSubmit}
                            >
                                {uploading && <Spinner size="18px" />}
                                    {getSubmitLabel()}
                            </button>
                        </div>
                    )
                }
            </form>
        </>

    )
}

FormEmargement.propTypes = {
    requestPosition: number,
    request: shape({
        request_id: string,
        status: number,
        row_hash: string,
    }),
    refetchRequests: func,
    requestType: oneOf(Object.values(requestList)),
}

export default FormEmargement