import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { useBasket } from 'hooks/useBasket'
import { arrayOf, func, object, oneOf, shape, string } from 'prop-types'

const RouterTabs = ({
    tabsContent, // list of tabs
    activeTab, // current tabs
    basename, // basename for the tab url
    componentProperty = 'component', // property to find the component to show
    className
}) => {
    const history = useHistory()
    const { setBasket } = useBasket()

    const toggle = tab => {
        if (activeTab !== tab) {
            // reset basket
            setBasket(null)
            // redirect to corresponding tab view
            history.push(`${basename}/${tab.url}`)
        }
     }

    return (
        <div className={'c-tabs u-pd-vt-l u-mg-hz-auto ' + (className || '')}>
            <nav className="c-tabs__nav">
                {tabsContent.map((tab, index) => {
                    if (!tab.url || !tab.componentSelection) return null
                    return (
                        <div
                            className={'c-tabs__nav-item u-pd-vt-m ' + (activeTab === tab ? 'is-active' : '')}
                            key={index}
                            role="button"
                            tabIndex={0}
                            onClick={() => toggle(tab)}
                            onKeyDown={() => toggle(tab)}
                        >
                            <h3 className={' ' + (activeTab === tab ? 'c-h2 u-secondary' : 'c-h4 u-black')}>{tab.label}</h3>
                        </div>
                    )
                })}
            </nav>
            <div className="u-pd-hz-l@main u-pd-top-xl u-pd-bottom-l">
                <AnimatePresence initial={false}>
                    <motion.div
                        className=""
                        key={'content-' + activeTab.url}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        {React.createElement(activeTab[componentProperty])}
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    )
}

RouterTabs.propTypes = {
    tabsContent: arrayOf(
        shape({
            url: string,
            label: string,
            componentSelection: func
        })
    ), // list of tabs
    activeTab: object, // current tabs
    basename: string, // basename for the tab url
    componentProperty: oneOf(['componentSelection', 'componentBid']), // property to find the component to show
    className: string
}

export default RouterTabs
