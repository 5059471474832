// ↓ Beneficiaries calls errors ↓

export const getMyAuditError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour récupérer les informations de votre audit.'
        default:
            return "Une erreur s'est produite lors de la tentative de mise à jour de la requête."
    }
}

export const planAuditError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour planifier un audit.'
        case 'AutodiagRequiredException': // TODO CHANGE
            return "Vous n'avez pas encore finalisé votre autodiagnostic"
        default:
            return "Une erreur s'est produite lors de la tentative de mise à jour de la requête."
    }
}

export const cancelAuditError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour annuler un audit.'
        case 'NotFoundException':
            return 'Aucun audit n\'est associé à ce compte.'
        default:
            return "Une erreur s'est produite lors de la tentative de mise à jour de la requête."
    }
}

// ↓ Auditors calls errors ↓

export const getAuditsError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour récupérer les informations sur les audits.'
        default:
            return "Une erreur s'est produite lors de la récupération des audits."
    }
}

export const getMyAuditsError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour récupérer les informations sur vos audits.'
        default:
            return "Une erreur s'est produite lors de la récupération de vos audits."
    }
}

export const updateMyAuditError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour mettre à jour cet audit.'
        case 'AuditNotFoundException':
            return 'Aucun audit n\'est associé à ce compte.'
        default:
            return "Une erreur s'est produite lors de la tentative de mise à jour de la requête."
    }
}