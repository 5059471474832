import jwtDecode from 'jwt-decode'
import localStorageService, { STORAGE_KEY } from 'services/localStorage'

import { PRIVATE_SECTOR_VALUE, PUBLIC_SECTOR_VALUE, ASSOCIATION_VALUE, PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE } from './beneficiary'
import { GENDER_PROVIDER_VALUE } from './provider'

export const ACTIVE_STATUS_USER = 1
export const ACTIVE_STATUSES_USER = [0, 1, 2, 3]
export const INACTIVE_STATUS_USER = 5

export const SUPERVISOR_PERMISSION = 0
export const ADVISOR_PERMISSION = 1
export const RELATIONSHIP_MANAGER_PERMISSION = 5
export const RELATIONSHIP_OPERATOR_PERMISSION = 6

const DASHBOARD_ADMIN_USER_GENDER = 9

export const BACKOFFICE_USER_STATUS = {
    ACTIVE: 1,
    DELETED: 9
}

export const BACKOFFICE_MANAGEMENT_ADVISOR = 'backoffice_management_advisor'
export const BACKOFFICE_MANAGEMENT_SUPERVISOR = 'backoffice_management_supervisor'
const BACKOFFICE_MANAGEMENT_USER_GENDER = 10

export const BACKOFFICE_LEAD_ADVISOR = 'backoffice_lead_advisor'
export const BACKOFFICE_LEAD_SUPERVISOR = 'backoffice_lead_supervisor'
export const BACKOFFICE_RELATIONSHIP_MANAGER = 'backoffice_relationship_manager'
export const BACKOFFICE_RELATIONSHIP_OPERATOR = 'backoffice_relationship_operator'
const BACKOFFICE_LEAD_USER_GENDER = 11

export const ADMIN_USER_GENDER = 9
export const ADMIN_USER_ID = 'admin'
export const ADMIN_USER_ROLE = 'admin'

export const backOfficeManagementAdvisor = {
    id: BACKOFFICE_MANAGEMENT_ADVISOR,
    value: BACKOFFICE_MANAGEMENT_USER_GENDER,
    label: 'Conseiller du Back Office gestion',
    shortLabel: 'Conseiller',
    role: BACKOFFICE_MANAGEMENT_ADVISOR,
    permission: ADVISOR_PERMISSION
}

export const backOfficeManagementSupervisor = {
    id: BACKOFFICE_MANAGEMENT_SUPERVISOR,
    value: BACKOFFICE_MANAGEMENT_USER_GENDER,
    label: 'Superviseur du Back Office gestion',
    shortLabel: 'Superviseur',
    role: BACKOFFICE_MANAGEMENT_SUPERVISOR,
    permission: SUPERVISOR_PERMISSION
}

export const backOfficeLeadAdvisor = {
    id: BACKOFFICE_LEAD_ADVISOR,
    value: BACKOFFICE_LEAD_USER_GENDER,
    label: 'Conseiller du Back Office pilotage',
    shortLabel: 'Conseiller',
    role: BACKOFFICE_LEAD_ADVISOR,
    permission: ADVISOR_PERMISSION
}

export const backOfficeLeadSupervisor = {
    id: BACKOFFICE_LEAD_SUPERVISOR,
    value: BACKOFFICE_LEAD_USER_GENDER,
    label: 'Superviseur du Back Office pilotage',
    shortLabel: 'Superviseur',
    role: BACKOFFICE_LEAD_SUPERVISOR,
    permission: SUPERVISOR_PERMISSION
}

export const backOfficeRelationshipManager = {
    id: BACKOFFICE_RELATIONSHIP_MANAGER,
    value: BACKOFFICE_LEAD_USER_GENDER,
    label: 'CRB superviseur',
    shortLabel: 'CRB superviseur',
    role: BACKOFFICE_RELATIONSHIP_MANAGER,
    permission: RELATIONSHIP_MANAGER_PERMISSION
}

export const backOfficeRelationshipOperator = {
    id: BACKOFFICE_RELATIONSHIP_OPERATOR,
    value: BACKOFFICE_MANAGEMENT_USER_GENDER,
    label: 'CRB opérateur',
    shortLabel: 'CRB opérateur',
    role: BACKOFFICE_RELATIONSHIP_OPERATOR,
    permission: RELATIONSHIP_OPERATOR_PERMISSION
}

export const admin = {
    id: 'admin',
    value: ADMIN_USER_GENDER,
    label: 'Administrateur',
    role: 'admin',
    permission: SUPERVISOR_PERMISSION
}

export const backOfficeUsers = [
    admin,
    backOfficeManagementAdvisor,
    backOfficeManagementSupervisor,
    backOfficeLeadAdvisor,
    backOfficeLeadSupervisor,
    backOfficeRelationshipManager,
    backOfficeRelationshipOperator
]

// TODO: Move ADMIN_USER_GENDER from platform to backoffice user
export const checkIsPlatformUser = ({ gender }) => [
    ADMIN_USER_GENDER,
    PRIVATE_SECTOR_VALUE,
    PUBLIC_SECTOR_VALUE,
    PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE,
    ASSOCIATION_VALUE,
    GENDER_PROVIDER_VALUE,
    ADMIN_USER_GENDER
].includes(parseInt(gender))

export const getLabelBypermission = (index) => {
    switch (index) {
        case SUPERVISOR_PERMISSION:
            return backOfficeLeadSupervisor.shortLabel
        case ADVISOR_PERMISSION:
            return backOfficeManagementAdvisor.shortLabel
        case RELATIONSHIP_MANAGER_PERMISSION:
            return backOfficeRelationshipManager.shortLabel
        case RELATIONSHIP_OPERATOR_PERMISSION:
            return backOfficeRelationshipOperator.shortLabel
        default:
            return '-'
    }
}

export const checkIsABeneficiary = ({ gender }) =>
    [PUBLIC_SECTOR_VALUE, PRIVATE_SECTOR_VALUE, ASSOCIATION_VALUE, PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE].includes(gender)

// Back Office Pilotage et Gestion
export const checkIsBackOfficeUser = ({ gender }) => [
    BACKOFFICE_MANAGEMENT_USER_GENDER,
    BACKOFFICE_LEAD_USER_GENDER
].includes(gender)

export const checkIsASupervisor = ({
    gender,
    permission
}) => checkIsBackOfficeUser({ gender }) && permission === SUPERVISOR_PERMISSION

export const checkIsAnAdvisor = ({
    gender,
    permission
}) => checkIsBackOfficeUser({ gender }) && permission === ADVISOR_PERMISSION

// Back Office Pilotage
export const checkIsABackOfficeManagementUser = ({
    gender,
}) => gender === BACKOFFICE_MANAGEMENT_USER_GENDER

export const checkIsABackOfficeManagementAdvisor = ({
    gender,
    permission
}) => checkIsAnAdvisor({ gender, permission }) && gender === BACKOFFICE_MANAGEMENT_USER_GENDER

export const checkIsBackOfficeManagementSupervisor = ({ // Back Office Gestion - Superviseur
    gender,
    permission
}) => checkIsASupervisor({ gender, permission }) && gender === BACKOFFICE_MANAGEMENT_USER_GENDER

// Back Office Gestion
export const checkIsABackOfficeRelationshipManager = ({
    permission
}) => permission === RELATIONSHIP_MANAGER_PERMISSION

// Back Office Gestion
export const checkIsABackOfficeRelationshipOperator = ({
    permission
}) => permission === RELATIONSHIP_OPERATOR_PERMISSION

export const checkIsABackOfficeLeadUser = ({
    gender,
}) => gender === BACKOFFICE_LEAD_USER_GENDER

export const checkIsBackOfficeLeadAdvisor = ({
    gender,
    permission
}) => checkIsAnAdvisor({ gender, permission }) && gender === BACKOFFICE_LEAD_USER_GENDER

export const checkIsBackOfficeLeadSupervisor = ({ // Back Office Pilotage - Superviseur
    gender,
    permission
}) => checkIsASupervisor({ gender, permission }) && gender === BACKOFFICE_LEAD_USER_GENDER


export const isADashboardAdminUser = ({ gender }) => gender === DASHBOARD_ADMIN_USER_GENDER


export const getAccessToken = () => {
    const config = JSON.parse(localStorageService.get(STORAGE_KEY))
    return config ? config.token : null
}

export const getMediaUrlPrefix = () => {
    const accessToken = getAccessToken()
    const decoded = jwtDecode(accessToken)
    return `${decoded.region}/oepv-documents-${decoded.region}/${decoded.entity_id}/`
}

export const getEntityId = () => {
    const accessToken = getAccessToken()
    const decoded = jwtDecode(accessToken)
    return decoded.entity_id
}

export const getFullUrlCharter = (user) => {
    return user?.entity_data?.documents?.find(document => document.media_gender === COMMITMENT_CHARTER_MEDIA_GENDER)?.full_media_url
}

export const isCommitmentCharterAlreadySigned = (user) => Boolean(user?.entity_data?.charte_accept)

export const COMMITMENT_CHARTER_MEDIA_GENDER = 'commitment_charter'
