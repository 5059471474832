import { func, number, object, shape } from 'prop-types'

import { CANCELED_STATUS, REALIZED_STATUS, PROCESS_STATUS, PAID_STATUS, ASKED_STATUS, getClassNameFromMeetingStatus } from 'utils/domain/scopingMeetings'

import './styles.scss'

const StatusCell = ({ cell, value: meetingStatus, column: { updateData } }) => {
    const isDisabled = meetingStatus === REALIZED_STATUS || meetingStatus === CANCELED_STATUS || meetingStatus === PAID_STATUS
    return (
        <div>
            <select
                value={meetingStatus} aria-label="Choix du statut de la réunion"
                className={getClassNameFromMeetingStatus(meetingStatus)}
                onChange={(event) => updateData(cell.row.original, parseInt(event.target.value))}
                disabled={isDisabled}
            >
                { PROCESS_STATUS.map((status, index) => {
                    // If the meeting is canceled or realized, the status cannot be changed
                    // If the meeting status is in ASKED_STATUS, the status can be changed to PLANNED_STATUS only => disabled REALIZED_STATUS
                    const isOptionDisabled = status.value === CANCELED_STATUS || status.value === PAID_STATUS || (meetingStatus === ASKED_STATUS && status.value === REALIZED_STATUS)
                    return (
                        <option value={status.value} key={index} disabled={isOptionDisabled}>{status.label}</option>
                    )
                }
                    
                )}
            </select>
        </div>
    )
}

StatusCell.propTypes = {
    cell: object.isRequired,
    value: number.isRequired,
    column: shape({
        updateData: func.isRequired
    }).isRequired
}


const scopingMeetingStatusColumn = ({ setData }) => {
    return {
        Header: 'Statut de la réunion',
        accessor: 'meetingStatus',
        updateData: setData,
        Cell: StatusCell
    }
}

export default scopingMeetingStatusColumn