import React from 'react'
import { node, number, shape, string } from 'prop-types'
import {
    Page,
    Text,
    View,
    Document,
} from '@react-pdf/renderer'

import dateService from 'services/date'
import styles from './PDFStyles'
import HeaderPDF from './PDFHeader'
import DataViewPDF from './PDFDataView'
import { isNotEmpty } from 'utils/validation'

const PDFRequestTemplate = ({
    children,
    title,
    beneficiary = null,
    comment = null,
    createDate = dateService.getCurrentDate(),
    provider = null
}) => {

    return (
        <Document>
            <Page size="A4" style={styles.page}>

                <HeaderPDF createDate={createDate} />

                {isNotEmpty(title) &&
                    <View style={styles.sectionTitle} wrap={false}>
                        <Text style={styles.h1}>{title}</Text>
                    </View>
                }

                {isNotEmpty(beneficiary) &&
                    <View style={styles.section} wrap={false}>
                        <View>
                            <Text style={[styles.h2, styles.textTransform, styles.bold]}>Bénéficiaire</Text>
                        </View>
                        <View style={styles.grid}>
                            <View style={styles.sectionLeft}>
                                <Text style={styles.h3}>Etablissement</Text>
                                <DataViewPDF
                                    label={'Raison sociale'}
                                    value={beneficiary?.structureName}
                                />
                                <DataViewPDF
                                    label={"Téléphone de l'établissement"}
                                    value={beneficiary?.structurePhonenumber}
                                />
                                <DataViewPDF
                                    label={'Adresse postale'}
                                    value={beneficiary?.structureAddress}
                                />
                                <DataViewPDF
                                    label={'Code postal'}
                                    value={beneficiary?.structurePostCode}
                                />
                                <DataViewPDF
                                    label={'Ville'}
                                    value={beneficiary?.structureCity}
                                />
                            </View>
                            <View style={styles.sectionRight}>
                                <Text style={styles.h3}>Responsable</Text>
                                <DataViewPDF
                                    label={'Nom'}
                                    value={beneficiary?.contactLastname}
                                />
                                <DataViewPDF
                                    label={'Prénom'}
                                    value={beneficiary?.contactFirstname}
                                />
                                <DataViewPDF
                                    label={'Téléphone'}
                                    value={beneficiary?.contactPhonenumber}
                                />
                                <DataViewPDF
                                    label={'E-mail'}
                                    value={beneficiary?.contactEmail}
                                />
                            </View>
                        </View>
                    </View>
                }

                {children}

                {isNotEmpty(comment) &&
                    <View style={styles.section} wrap={false}>
                        <View>
                            <Text style={[styles.h2, styles.textTransform, styles.bold]}>Informations complémentaires</Text>
                            <DataViewPDF
                                label={'Commentaire'}
                                value={comment}
                            />
                        </View>
                    </View>
                }

                {isNotEmpty(provider) &&
                    <View style={styles.section} wrap={false}>
                        <View>
                            <Text style={[styles.h2, styles.textTransform, styles.bold]}>Prestataire sélectionné</Text>
                        </View>
                        <View style={styles.grid}>
                            <View style={styles.sectionLeft}>
                                <Text style={styles.h3}>Etablissement</Text>
                                <DataViewPDF
                                    label={'Raison sociale'}
                                    value={provider?.structureName}
                                />
                                <DataViewPDF
                                    label={'Adresse postale'}
                                    value={provider?.structureAddress}
                                />
                                <DataViewPDF
                                    label={'Code postal'}
                                    value={provider?.structurePostCode}
                                />
                                <DataViewPDF
                                    label={'Ville'}
                                    value={provider?.structureCity}
                                />
                            </View>
                            <View style={styles.sectionRight}>
                                <Text style={styles.h3}>Contact</Text>
                                <DataViewPDF
                                    label={'E-mail'}
                                    value={provider?.contactEmail}
                                />
                                <DataViewPDF
                                    label={'Téléphone du responsable'}
                                    value={provider?.structurePhonenumber}
                                />
                            </View>
                        </View>
                    </View>
                }
            </Page>
        </Document>
    )
}

PDFRequestTemplate.propTypes = {
    children: node,
    title: string,
    beneficiary: shape({
        structureName: string,
        structurePhonenumber: string,
        structureAddress: string,
        structurePostCode: string,
        structureCity: string,
        contactLastname: string,
        contactFirstname: string,
        contactPhonenumber: string,
        contactEmail: string
    }),
    comment: string,
    createDate: string,
    provider: shape({
        structureName: string,
        structureAddress: string,
        structurePostCode: number,
        structureCity: string,
        mail: string,
        contactPhonenumber: string
    })
}

export default PDFRequestTemplate
