import React from 'react'
import { useParams } from 'react-router-dom'
import useMediaQuery from 'hooks/useMediaQuery'

import { 
    PICTURES_UPLOADED, 
    PICTURES_VALIDATED,
    ALL_DOCUMENTS_UPLOADED,
    REQUEST_VALIDATED,
    isBetweenStatus,
    getRequestStatusFromBackOffice 
} from 'utils/domain/request'
import useBonus from 'hooks/useBonus'
import * as UserApi from 'services/api/UserApi'

import BackOfficeLayout from 'layouts/back-office'
import { equipmentList } from 'components/catalog/Equipment/list'

import Pictures from 'pages/back-office/Folders/RequestFolder/EquipmentFolder/Pictures'
import EquipmentBid from 'pages/back-office/Folders/RequestFolder/EquipmentFolder/EquipmentBid'
import RequestFolderProvider, { useRequestFolder } from 'pages/back-office/Folders/RequestFolder/EquipmentFolder/context'
import Card from 'components/platform/Card'
import { isNotEmpty, isObjectEmpty } from 'utils/validation'
import { formatNumberAndAddEurCurrency } from 'utils/number'
import { useAuth } from 'hooks/useAuth'
import dateService from 'services/date'

import BeneficiaryInformations from 'components/back-office/BeneficiaryInformations'
import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'

const EquipmentFolder = () => {
    const { requestId } = useParams()
    const { isABackOfficeManagementSupervisor } = useAuth()
    const { request, beneficiary, fetchRequest } = useRequestFolder()
    const isLarge = useMediaQuery('(min-width: 1500px)')
    const [configurationType, setConfigurationType] = React.useState()
    const [isAdditionnalEquipment, setIsAdditionnalEquipment] = React.useState(false)

    const { getBonusByRequests } = useBonus({ isFromBackOffice: true })

    const requestsGroupedByGender = beneficiary?.requestsGroupedByGender || {}
    const requests = Object.values(requestsGroupedByGender).flat()
    const regularisationFolders = beneficiary?.regularisationFolders ?? []
    const [providerName, setProviderName] = React.useState('')

    const {
        totalAmountValidated,
        availableBonus,
    } = getBonusByRequests({
        requests: [...requests, ...regularisationFolders],
        userEntityData: beneficiary?.entity_data,
        beneficiary: beneficiary
    })

    React.useEffect(() => {
        fetchRequest(requestId)
    }, [])

    const isDisplayPicture = isBetweenStatus({ status: request?.status, start: PICTURES_UPLOADED, end: PICTURES_VALIDATED })
    const isDisplayEquipmentBid = isBetweenStatus({ status: request?.status, start: ALL_DOCUMENTS_UPLOADED, end: REQUEST_VALIDATED })

    const fetchProvider = async (provider) => {
        const providerData = await UserApi.getUser(provider)
        if(isNotEmpty(providerData)) setProviderName(providerData.entity_data?.raison_sociale ?? providerData.entity_data?.structureName)
    }

    React.useEffect(() => {
        if(!isObjectEmpty(request)) {
            const requestEquipment = equipmentList.filter(i => !i.optional).filter(item => {
                return Object.keys(request?.request_description).includes(item?.id)
            })
            if(isNotEmpty(requestEquipment)) setConfigurationType(requestEquipment[0])
            const additionnalEquipment = equipmentList.filter(i => i.optional).filter(item => {
                return Object.keys(request?.request_description).includes(item?.id)
            })
            if(isNotEmpty(additionnalEquipment)) setIsAdditionnalEquipment(true)
        }

        if(isNotEmpty(request?.validatedBid?.request_data.operation_data.operation_entity_id)) {
            fetchProvider(request?.validatedBid?.request_data.operation_data.operation_entity_id)
        }
    }, [request])
    
    const bonusStillAvailable = availableBonus - (totalAmountValidated + beneficiary?.amount_refunded)
    const bonusAmount = bonusStillAvailable <= 0 ? 0 : bonusStillAvailable

    const isSendingFub = request.request_data?.operation_data?.sending_fub ?? false

    return (
        <BackOfficeLayout>
            <div className={'u-flex u-flex-dir-col u-mg-bottom-l'}>
                <h1 className="c-h1 u-bold u-primary u-mg-bottom-m">Dossier équipement - {beneficiary?.structureName}</h1>

                <div className="u-flex u-flex-dir-col u-flex-dir-row@small-desktop">
                        <div className={'l-col-12 l-col-8@small-desktop'}>
                            <div className='u-flex'>
                            {(configurationType || isAdditionnalEquipment) && 
                                <Card className="u-w-60-percent">
                                    <p><span className='u-blue u-fs-m u-bold'>Configuration d’aménagement : </span></p>
                                    <p>{isNotEmpty(configurationType) ? configurationType?.label : "Equipement complémentaire seul"}</p>
                                    <p className='u-mg-top-m'><span className='u-blue u-fs-m u-bold'>Equipement complémentaire : </span> {isAdditionnalEquipment ? "Oui": "Non"}</p>
                                </Card>
                            }
                            <Card className="u-w-40-percent">
                                <p><span className='u-blue u-fs-m u-bold'>Statut : </span> {getRequestStatusFromBackOffice(request.status, isSendingFub)}</p>
                            </Card>
                        </div>

                        {providerName &&
                            <Card className="w-full">
                                <p><span className='u-blue u-fs-m u-bold'>Fournisseur : </span>{providerName}</p>
                            </Card>
                        }

                        {isNotEmpty(request?.validatedBid?.request_data.operation_data.operation_date) && 
                            <div className='u-flex u-width-full'>
                                <Card className="u-width-full">
                                    <div className='u-flex u-flex-center-vt'>
                                        <p className='u-blue u-fs-m u-bold u-mg-right-xs'>Devis : </p>
                                        <p>Demande effectuée le {dateService.formatDate(request?.create_date)}</p>
                                    </div>
                                    <InformationToVisualize 
                                        label="Consulter le devis validé par le bénéficiaire" 
                                        url={request?.validatedBid?.request_data.operation_data.full_media_url} 
                                    />
                                </Card>
                            </div>  
                        }

                        {isDisplayEquipmentBid && (      
                            <div className={'u-flex u-flex-dir-col w-full'}>                  
                                <EquipmentBid isBlockDisabled={isSendingFub && !isABackOfficeManagementSupervisor} />
                            </div>
                        )}

                        {isDisplayPicture && 
                            <div className="w-full">
                                <Pictures />
                            </div>
                        }
                    </div>
                    <div className={`l-col-12 l-col-4@small-desktop ${isLarge ? '' : 'u-mg-top-m'}`}>
                        <Card>
                            <p><span className='u-blue u-fs-m u-bold'>Prime disponible : </span>{formatNumberAndAddEurCurrency(bonusAmount)}</p>
                        </Card>
                        <BeneficiaryInformations initialBeneficiary={beneficiary} />
                    </div>
                </div>
            </div>        
        </BackOfficeLayout>
    )
}

const EquipmentFolderPage = () => (<RequestFolderProvider><EquipmentFolder /></RequestFolderProvider>)

 export default EquipmentFolderPage