import React from 'react'
import { useParams } from 'react-router-dom'

import { isServiceFolder, isEquipmentFolder } from 'utils/domain/request'

import NotFound from 'router/NotFound'

import EquipmentFolder from './EquipmentFolder'
import ServiceFolder from './ServiceFolder'

const RequestFolder = () => {
    const { requestId } = useParams()

    if(isServiceFolder(requestId)) {
        return <ServiceFolder />
    }

    if(isEquipmentFolder(requestId)) {
        return <EquipmentFolder />
    }

    return <NotFound />    
}

export default RequestFolder