import React from 'react'
import { arrayOf, func, shape, string } from 'prop-types'

const TableBody = ({
    getTableBodyProps,
    rows,
    prepareRow,
    rowClassName = 'flex-row'
}) => {
    return (
        <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
            rows.map(row => {
                // Prepare the row for display
                prepareRow(row)
                return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                    {// Loop over the rows cells
                    row.cells.map(cell => {
                        // Apply the cell props
                        return (
                            <td className={`${rowClassName} u-fs-xxs`} {...cell.getCellProps()}>
                                {// Render the cell contents
                                cell.render('Cell')}
                            </td>
                        )
                    })}
                    </tr>
                )
            })}
        </tbody>
    )
}

const cellProp = shape({
    getCellProps: func.isRequired,
    render: func
})

const rowProp = shape({
    getRowProps: func,
    cells: arrayOf(cellProp).isRequired
})

TableBody.propTypes = {
    getTableBodyProps: func.isRequired,
    prepareRow: func.isRequired,
    rows: arrayOf(rowProp).isRequired,
    rowClassName: string
}

export default TableBody
