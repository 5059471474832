import { entityGenderList } from 'utils/constants'
import { employeeCategory } from 'utils/domain/beneficiary'
import { getLabelFromAuditStatus, RESERVED_STATUS } from 'utils/domain/audit'
import certificationUtil from 'utils/domain/certification'
import { ACTIVE_STATUSES_USER } from 'utils/domain/user'
import { getLabelFromMeetingStatus } from 'utils/domain/scopingMeetings'
import { convertDataToStringFromArray } from 'utils/downloadFile'
import { isNotEmpty, isNotEmptyNumber } from 'utils/validation'
import dateService from 'services/date'


// ------------------------------- //
//             Header
// ------------------------------- //

export const beneficiaryHeader = [
    'Date de création',
    'N° Bénéficiaire',
    'Typologie de l\'établissement',
    'Nom de l\'établissement',
    'Catégorie salariés',
    'Nombre de salariés',
    'Code NAF',
    'SIRET',
    'E-mail de connexion',
    'E-mail de contact',
    'Adresse',
    'Code Postal',
    'Ville',
    'Fonction',
    'Téléphone du responsable du compte',
    'KBIS/SIRET/SIRENE Validé',
    'Date de validation KBIS/SIRET/SIRENE',
    'Autodiagnostic réalisé',
    'Date de l\'autodiagnostic',
    'Charte d\'accès signée',
    'Date de charte',
    'Date de demande réunion de cadrage',
    'Intervenant réunion de cadrage',
    'Statut réunion de cadrage',
    'Date de réalisation de réunion de cadrage',
    'Rapport de cadrage importé',
    'Organisme auditeur',
    'Statut audit',
    'Date de demande d\'audit',
    'Date de l\'audit planifié',
    'Date de réalisation de l\'audit',
    'Rapport d\'audit importé',
    'Site engagé',
    'Niveau de Label',
    'Certificat téléchargé',
    'Prime initiale',
    'Prime engagée',
    'Prime validée non remboursée',
    'Prime remboursée',
    'Prime disponible',
    'Statut',
    'Date du changement du statut'
]

export const equipmentProviderHeader = [
    'ID fournisseur',
    'Nom commercial',
    'Raison sociale',
    'SIRET',
    'Code NAF/APE',
    'Adresse postale (n° et voie)',
    'Code postal',
    'Ville',
    'N° téléphone établissement',
    'Site internet (sans le http)',
    'Infos complémentaires',
    'Civilité (M. / Mme)',
    'Nom',
    'Prénom',
    'E-mail de connexion',
    'E-mail de contact',
    'N° téléphone responsable',
    'Support d’attache',
    'Abri',
    'Consigne sécurisée',
    'Station de gonflage',
    'Station d’outillage',
    'Casiers',
    'Installation',
    'Pose France métropolitaine',
    'Pose DOM-TOM',
    'Fourniture France métropolitaine',
    'Fourniture DOM-TOM'
]

export const mobilityProviderHeader = [
    'Nom de la structure',
    'SIRET',
    'RNA',
    'Adresse postale (n° et voie)',
    'Code postal',
    'Ville',
    'N° téléphone établissement',
    'E-mail de connexion',
    'E-mail de contact',
    'Site internet',
    'Infos complémentaires',
    'Formation "Remise en selle"',
    'Formation « Rouler en sécurité​ »',
    'Formation pratique prise en main vélo à assistance électrique (VAE/VCAE)',
    'Formation théorique « La route vue du guidon »',
    'Révision du vélo des salariés',
    'Contrôle technique des vélos personnels des employés',
    'Entretien et réparation des vélos personnels des employés',
    'Atelier mécanique participatif pour les employés',
    'Statut'
]

export const supportProviderHeader = [
    'Nom de la structure',
    'SIRET',
    'RNA',
    'Adresse postale (n° et voie)',
    'Code postal',
    'Ville',
    'N° téléphone établissement',
    'E-mail de connexion',
    'E-mail de contact',
    'Site internet',
    'Infos complémentaires',
    'Département(s) d\'intervention Conseil et Accompagnement ',
    'Définition d’une stratégie vélo',
    'Diagnostic mobilité axé sur le vélo',
    'Organisation, formation et outillage de l’équipe vélo',
    'Élaboration/enrichissement d\'un plan d’actions pro-vélo et appui opérationnel à la réalisation',
    'Formation au cahier des charges, audit à blanc',
    'Statut'
]

export const scopingMeetingProviderHeader = [
    'Nom de la structure',
    'SIRET',
    'Adresse postale (n° et voie)',
    'Code postal',
    'Ville',
    'N° téléphone établissement',
    'E-mail de connexion',
    'E-mail de contact',
    'Site internet (sans le http)',
    'Infos complémentaires',
    'Auditeurs actif',
    'Nombre de lots'
]

export const auditProviderHeader = [
    'Nom de la structure',
    'SIRET',
    'Adresse postale (n° et voie)',
    'Code postal',
    'Ville',
    'N° téléphone établissement',
    'E-mail de connexion',
    'E-mail de contact',
    'Site internet (sans le http)',
    'Infos complémentaires',
    'Intervenant actif',
    'Nombre de lots'
]

// ------------------------------- //
//             Mapping
// ------------------------------- //

export const mapBeneficiaries = (beneficiaries) => beneficiaries.map(async beneficiary => {
    const beneficiaryArray = [
        beneficiary?.create_date,
        beneficiary?.entity_id,
        entityGenderList.find(gender => gender.value === parseInt(beneficiary?.gender))?.label,
        beneficiary?.entity_data?.structureName,
        employeeCategory.find(category => category.value === beneficiary?.structureEmployeeCategory)?.label,
        beneficiary?.entity_data?.structureEmployeeQuantity,
        beneficiary?.entity_data?.structureCodeApe,
        beneficiary?.entity_data?.siret,
        beneficiary?.entity_data?.mail,
        beneficiary?.entity_data?.contactEmail,
        beneficiary?.entity_data?.structureAddress,
        beneficiary?.entity_data?.structurePostCode,
        beneficiary?.entity_data?.structureCity,
        beneficiary?.entity_data?.contactRole,
        beneficiary?.entity_data?.contactPhonenumber,
        beneficiary?.entity_check?.is_check ? 'Oui' : 'Non',
        beneficiary?.entity_check?.date_check ? beneficiary?.entity_check?.date_check : '',
        beneficiary?.diag?.diag_status ? 'Oui' : 'Non',
        beneficiary?.diag?.modify_date ? beneficiary?.diag?.modify_date : '',
        beneficiary?.entity_data?.charte_accept ? 'Oui' : 'Non',
        beneficiary?.entity_data?.charte_accept ? dateService.formatDate(beneficiary?.entity_data?.charte_accept) : '',
        // Réunion de cadrage - Framing meeting
        beneficiary?.framing_meeting?.create_date ? beneficiary.framing_meeting.create_date : '', // Date de demande réunion de cadrage
        beneficiary?.framing_meeting?.provider_data?.structureName,
        beneficiary?.framing_meeting?.framing_meeting_status ? getLabelFromMeetingStatus(beneficiary?.framing_meeting?.framing_meeting_status) : 'N/A',
        beneficiary?.framing_meeting?.framing_meeting_date ? beneficiary.framing_meeting.framing_meeting_date : '', // Date de réalisation de réunion de cadrage
        beneficiary?.framing_meeting?.framing_meeting_media_rapport_url ? 'Oui' : 'Non',
        
        beneficiary?.audit?.provider_data?.structureName,
        beneficiary?.audit?.audit_status ? getLabelFromAuditStatus(beneficiary?.audit?.audit_status) : 'N/A',

        // Dates lié à l'audit
        beneficiary?.audit?.create_date ? beneficiary?.audit?.create_date : '',
        beneficiary?.audit?.planned_date ? beneficiary?.audit?.planned_date : '',
        beneficiary?.audit?.audit_date ? beneficiary?.audit?.audit_date : '',

        beneficiary?.audit?.audit_media?.documents?.find(document => document.media_gender === 'RAPPORT_AUDIT') ? 'Oui' : 'Non',
        // Site engagé - un bénéficiaire ayant pris au moins une prestation validée par le BO Gestion et/ou étant labellisé
        // étant labellisé => on regarde si le rapport d'audit a été impporté
        // au moins une prestation validée => on regarde si Prime remboursée ou Prime validée non remboursée est sup à O
        (isNotEmpty(beneficiary?.amount_refunded) && isNotEmptyNumber(beneficiary?.amount_refunded)) || (isNotEmpty(beneficiary?.amount_validated) && isNotEmptyNumber(beneficiary?.amount_validated)) || beneficiary?.audit?.audit_media?.documents?.find(document => document.media_gender === 'RAPPORT_AUDIT') ? 'Oui' : 'Non',
        beneficiary?.audit?.audit_label ? certificationUtil.getLabelByCertificationLevel(beneficiary?.audit?.audit_label) : 'N/A',
        beneficiary?.audit?.audit_media?.documents?.find(document => document.media_gender === 'LABEL_AUDIT') ? 'Oui' : 'Non',
        beneficiary?.availableBonus,
        beneficiary?.totalAmountEngaged,
        beneficiary?.amount_validated ? beneficiary?.amount_validated : '',
        Math.round(beneficiary?.amount_refunded),
        Math.round(beneficiary?.remainingBonus),
        beneficiary?.status && ACTIVE_STATUSES_USER.includes(beneficiary?.status) ? 'Actif' : 'Inactif',
        beneficiary?.audit?.audit_status === RESERVED_STATUS ? beneficiary?.audit?.modify_date : ''
    ]

    return convertDataToStringFromArray(beneficiaryArray) //allows consistent alignment in excel column
})

export const mapEquipmentProviders = (providers) => providers.map(provider => {

    const providersArray = [
        provider?.entity_id,
        provider?.structureName,
        provider?.raison_sociale,
        provider?.structureSiret,
        provider?.structureCodeApe,
        provider?.structureAddress,
        provider?.structurePostCode,
        provider?.structureCity,
        provider?.structurePhonenumber,
        provider?.website,
        provider?.informations,
        provider?.contactGender,
        provider?.contactLastname,
        provider?.contactFirstname,
        provider?.mail,
        provider?.contactEmail,
        provider?.contactPhonenumber,
        provider?.bike_hitch,
        provider?.bike_shelter,
        provider?.bike_secure_storage,
        provider?.bike_pumps,
        provider?.bike_toolkit,
        provider?.lockers,
        provider?.installation,
        provider?.france_installation,
        provider?.dom_tom_installation,
        provider?.france_supply,
        provider?.dom_tom_supply,
    ]

    return convertDataToStringFromArray(providersArray) //allows consistent alignment in excel column

})

export const mapMobilityProviders = (schools) => schools.map(school => {
    const schoolArray = [
        school?.structureName,
        school?.structureSiret,
        school?.structureRna,
        school?.structureAddress,
        school?.structurePostCode,
        school?.structureCity,
        school?.structurePhonenumber,
        school?.mail,
        school?.contactEmail,
        school?.website,
        school?.informations,
        school['training-back-to-bike'],
        school['driving-safely-company'] ?? school['training-company'],
        school['pratical-training-electric-bike'] ?? school['training-electric-bike'],
        school['training-traffic-code'],
        school['bike-overhaul'] ?? 'Non',
        school['technical-check'],
        school['technical-maintenance'],
        school['technical-workshops'],
        isNotEmpty(school?.entity_data?.active_entity) ? school.entity_data.active_entity ? "Actif" : "Inactif"  : "Actif",
    ]

    return convertDataToStringFromArray(schoolArray) //allows consistent alignment in excel column

})

export const mapSupportProviders = (supports) => supports.map(support => {

    const supportArray = [
        support?.structureName,
        support?.structureSiret,
        support?.structureRna,
        support?.structureAddress,
        support?.structurePostCode,
        support?.structureCity,
        support?.structurePhonenumber,
        support?.mail,
        support?.contactEmail,
        support?.website,
        support?.informations,
        support?.area?.join(','),
        support['cycling-strategy'],
        support['diagnostic-mobility'],
        support['setup-and-training'] || support['training-and-tools-of-cycling-team'],
        support['action-plan'],
        support['audit-preparation'] || support['specifications-training'],
        isNotEmpty(support?.entity_data?.active_entity) ? support.entity_data.active_entity ? "Actif" : "Inactif" : "Actif",
    ]

    return convertDataToStringFromArray(supportArray) //allows consistent alignment in excel column

})

export const mapScopingMeetingProviders = (scopingMeetings) => scopingMeetings.map(scopingMeeting => {
    const scopingMeetingArray = [
        scopingMeeting?.structureName,
        scopingMeeting?.siret,
        scopingMeeting?.structureAddress,
        scopingMeeting?.structurePostCode,
        scopingMeeting?.structureCity,
        scopingMeeting?.structurePhonenumber,
        scopingMeeting?.mail,
        scopingMeeting?.contactEmail,
        scopingMeeting?.website,
        scopingMeeting?.informations,
        scopingMeeting?.framing_meeting_available ? 'Oui' : 'Non',
        scopingMeeting?.lot || 0
    ]

    return convertDataToStringFromArray(scopingMeetingArray) //allows consistent alignment in excel column
})

export const mapAuditProviders = (audits) => audits.map(audit => {

    const auditArray = [
        audit?.structureName,
        audit?.siret,
        audit?.structureAddress,
        audit?.structurePostCode,
        audit?.structureCity,
        audit?.structurePhonenumber,
        audit?.mail,
        audit?.contactEmail,
        audit?.website,
        audit?.informations,
        audit?.audit_available ? 'Oui' : 'Non',
        audit?.lot_audit || 0
    ]

    return convertDataToStringFromArray(auditArray) //allows consistent alignment in excel column
})


// ------------------------------- //
//             Export
// ------------------------------- //

export const getEntitiesToExport = async (entities, entityType) => {
    const header = {
        beneficiary: beneficiaryHeader,
        equipment: equipmentProviderHeader,
        mobility: mobilityProviderHeader,
        adviceAndSupport: supportProviderHeader,
        scopingMeeting: scopingMeetingProviderHeader,
        audit: auditProviderHeader
    }[entityType]
    const mapper = {
        beneficiary: mapBeneficiaries,
        equipment: mapEquipmentProviders,
        mobility: mapMobilityProviders,
        adviceAndSupport: mapSupportProviders,
        scopingMeeting: mapScopingMeetingProviders,
        audit: mapAuditProviders
    }[entityType]
    // prepare data
    return [
        // Header array
        header,
        // Data arrays
        ...await Promise.all(mapper(entities))
    ]
}



















