import React from 'react'
import { array, number, object, shape, string } from 'prop-types'

import { useAlert } from 'hooks/useAlert'

import {
    SIGN_OFF_REPORT_UPLOADED,
    isBetweenStatus, 
    isCancelled,
    getFeedbackToReportBeneficiary, 
    getLastReportOfHistory,
    getAdviceAndSupportInformations,
    VALIDATED_BID,
    BACK_OFFICE_OPERATIONS
} from 'utils/domain/request'
import dateService from 'services/date'

import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'
import { downloadRequestPDF } from 'components/catalog/catalogUtils'
import AdviceAndSupportPDF from 'components/catalog/AdviceAndSupport/AdviceAndSupportPDF'
import { isNotEmpty } from 'utils/validation'

import Request from '../Request'

const AdviceAndSupport = ({ request, beneficiary }) => {
    const { addAlert } = useAlert()

    const isRequestCancelled = isCancelled(request?.status)
    const isDisplayReport = isBetweenStatus({ status: request?.status, start: SIGN_OFF_REPORT_UPLOADED }) && !isRequestCancelled
    const isBidValidated = isBetweenStatus({ status: request.status, start: VALIDATED_BID }) && !isRequestCancelled

    const report = getLastReportOfHistory(request.history)
    const adviceAndSupportInformations = getAdviceAndSupportInformations(request.request_description.type)

    const requestInitEvent = request.history.find(event => event?.request_data?.operation_code === BACK_OFFICE_OPERATIONS.INIT)
    const requestedDate = isNotEmpty(requestInitEvent) ? dateService.formatDate(requestInitEvent?.history_date) : ''

    const handleDownloadAdviceAndSupportPDF = async () => {
        const { error } = await downloadRequestPDF({
            user: beneficiary,
            request,
            provider: request.provider,
            pdfTemplate: AdviceAndSupportPDF,
            readRequestProviders: false,
            isFromBackOffice: true
        })

        if (error) addAlert('error', "Une erreur s'est produite lors de la génération de la demande. Veuillez réessayer.")
    }

    return (
        <Request
            request={{
                fubId: request.request_fub_id,
                label: adviceAndSupportInformations?.label,
                statusLabel: getFeedbackToReportBeneficiary(request.status, { adminView: true }),
                status: request.status,
                provider: request.provider_data.structureName,
                requestDate: requestedDate ?? ''
            }}
            bonus={{
                isDisplay: isBidValidated,
                amount: request.bonus,
                realBonus: request?.realBonus
            }}
            bidDocuments={{
                isDisplay: request.bidDocuments.isUploaded,
                bill: request?.bidDocuments?.bill,
                proofOfPayment: request?.bidDocuments?.proofOfPayment,
            }}
            pdfOverview= {{
                isDisplay: true,
                title: 'Récapitulatif de la demande',
                onClick: handleDownloadAdviceAndSupportPDF
            }}
        >
            {isDisplayReport && (
                <Request.Documents title="Rapport d'intervention">
                    <InformationToVisualize 
                        className="u-mg-right-xl"
                        label="Rapport d'intervention"
                        url={report?.full_media_url} 
                        iconColor="green"
                        iconSize={30}
                    />
                </Request.Documents>
            )}
        </Request>
    )
}

AdviceAndSupport.propTypes = {
    request: shape({
        status: number.isRequired,
        request_fub_id: string.isRequired,
        history: array.isRequired,
        bidDocuments: shape({
            bill: shape({ full_media_url: string }),
            proofOfPayment: shape({ full_media_url: string })
        }),
        request_description: shape({
            type: string.isRequired
        })
    }),
    beneficiary: object
}

export default AdviceAndSupport