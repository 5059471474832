// If a scoping meeting is created and canceled, cannot check Object.keys length
// because the back returns { message: "There is no meeting for this entity" }
export const hasAScopingMeeting = (scopingMeetingEntity) => scopingMeetingEntity?.create_date !== undefined

export const getKeywords = (data) => {
    let keywords = ''
    keywords += 'O-S-' + data?.structureSiret + ' '
    keywords += 'O-CP-' + data?.structurePostCode + ' '
    keywords += data?.contactEmail + ' '
    keywords += data?.structureName + ' '
    keywords += data?.structureAddress + ' '
    keywords += data?.structurePostCode + ' '
    keywords += data?.structureCity + ' '
    return keywords
}

// -----------------------------
// Status handler
// -----------------------------
export const ASKED_LABEL = 'Demandée'
export const PLANNED_LABEL = 'Planifiée'
export const REALIZED_LABEL = 'Réalisée'
export const CANCELED_LABEL = 'Annulée'
export const PAID_LABEL = 'Payée'

export const ASKED_STATUS = 1
export const PLANNED_STATUS = 2
export const REALIZED_STATUS = 3
export const CANCELED_STATUS = 4
export const PAID_STATUS = 12

export const PROCESS_STATUS = [
    { label: ASKED_LABEL, value: ASKED_STATUS, className: 'asked' },
    { label: PLANNED_LABEL, value: PLANNED_STATUS, className: 'planned' },
    { label: REALIZED_LABEL, value: REALIZED_STATUS, className: 'realized' },
    { label: PAID_LABEL, value: PAID_STATUS, className: 'paid' },
    { label: CANCELED_LABEL, value: CANCELED_STATUS, className: 'canceled' },
]

export const getClassNameFromMeetingStatus = (meetingStatus) =>
PROCESS_STATUS.find((status) => status.value === meetingStatus)?.className

export const getLabelFromMeetingStatus = (meetingStatus) =>
PROCESS_STATUS.find((status) => status.value === meetingStatus)?.label