import React from 'react'
import { NavLink } from 'react-router-dom'

import { privateRoutes } from 'router/routes'
import { useAutoDiag } from 'pages/protected/Autodiag/context'
import EMPTYAUTODIAG from 'assets/data/autodiagnostic-OEPV-vierge.pdf'

const AutodiagIntroduction = () => {
    const { getAutoDiagForFill } = useAutoDiag()

    return (
        <div className="l-container u-pd-vt-xxl u-pd-hz-l">
            <h1 className="c-h1 u-secondary u-bold u-uppercase u-center u-mg-top-l u-mg-bottom-xxl">
                Mesurer la politique cyclable de mon établissement
            </h1>
            <p className="u-primary u-bold u-mg-bottom-xl">
                Cet autodiagnostic est basé sur le cahier des charges du label Employeur Pro-Vélo qui permet d’évaluer la maturité pro-vélo de votre site. Ce questionnaire s'adresse à votre référent-e vélo, ou, si vous n'en avez pas encore désigné, à la personne en charge de la mobilité pour votre site (ou à défaut son responsable, la personne en charge de la RSE ou des ressources humaines).
            </p>
            <h3 className="u-mg-bottom-m">
                <a href="https://employeurprovelo.fr/wp-content/uploads/2021/06/Referentiel-LEPV_A4_BD02.pdf" target="_blank" rel="noreferrer">
                    Le&nbsp;         
                    <span className="u-underline">
                    cahier des charges du label employeur pro-vélo
                    </span>
                    &nbsp;comporte :
                </a>  
            </h3>
            <ul className="u-mg-bottom-xl u-pd-left-xl">
                 <li><strong className="u-danger">5 champs d’action</strong> (pilotage & stratégie, communication & motivation, services, sécurité, équipements)</li>
                 <li><strong className="u-danger">42 critères</strong> (46 pour les établissements recevant du public)</li> 
                 <li><strong className="u-danger">8 sont obligatoires</strong>, parmi ces 42 critères</li>
            </ul>

            <h3 className="u-mg-bottom-m">Nous vous recommandons de prendre connaissance du cahier des charges avant de débuter votre questionnaire.</h3>
            <p className="u-mg-bottom-l u-pd-left-xl">
                 La numérotation des questions reprend celle des critères du cahier des charges. Vous pouvez aussi télécharger le questionnaire pour le partager avec des collègues, afin de préparer vos réponses.
            </p>

            <h3 className="u-mg-bottom-m">Cet autodiagnostic que vous allez débuter dure environ <span className="u-danger">30 min</span> et comporte <span className="u-danger">37 questions</span> (au maximum).</h3>
            <p className="u-mg-bottom-xl u-pd-left-xl">        
                La période COVID perturbant l'organisation du travail, nous vous invitons à répondre en vous plaçant du point de vue d'une période "normale" (des actions pro-vélo que vous aviez prévues ont pu être annulées ou reportées par exemple). 
                Vous pouvez enregistrer votre questionnaire à tout moment pour le reprendre plus tard et revenir sur les questions déjà renseignées. L'autodiagnostic est réalisé sur une base déclarative et ne préjuge pas du résultat de l’audit qui pourra être réalisé à votre demande par un organisme accrédité. Lors de cet audit, vous aurez à rassembler des éléments de preuve pour l’auditeur.
            </p>

            <div className="u-mg-bottom-xl u-pd-l u-bg-primary-reduced">  
                <p className="u-primary u-pd-bottom-s"><strong>A savoir :</strong></p>
                <ul className="u-pd-left-xl u-primary">
                    <li className="u-pd-hz-s u-pd-bottom-s">Si vous ne souhaitez pas remplir tout de suite l’autodiagnostic en ligne, vous pouvez <a href={EMPTYAUTODIAG} target="_blank" rel="noreferrer"><strong>télécharger le questionnaire</strong></a> afin de préparer vos réponses</li>
                    <li className="u-pd-hz-s">
                        Même si vous pensez ne pas être bien situé ou que vous ne remplissez pas tous les critères obligatoires, pas de panique ! A l’issue de l’autodiagnostic, vous identifierez vos points forts et vos points faibles par rapport au cahier des charges, aurez accès à des conseils et des ressources, et pourrez en discuter lors de l’entretien de cadrage qui suivra.<br /> 
                        Le conseiller ou la conseillère vous accompagnera dans l’identification des prestations proposées et financées par le programme pour faire progresser votre établissement dans sa démarche pro-vélo.
                    </li>
                </ul>
            </div>

            <div className="u-flex u-flex-center-hz u-mg-top-l u-mg-bottom-l">
                <NavLink 
                    className="c-btn c-btn--secondary u-pd-hz-xl u-mg-hz-m" 
                    to={privateRoutes.autoDiag.navLink({ step: privateRoutes.autoDiag.paramsAllowed[1] })}
                >
                    { getAutoDiagForFill() ? 'Reprendre mon autodiagnostic en cours' : 'Commencer mon autodiagnostic'}
                </NavLink>
            </div>
        </div>
    )
}

export default AutodiagIntroduction