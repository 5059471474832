import React from 'react'
import FilterIcon from 'components/svg/Filter'
import { isNotEmpty } from 'utils/validation'
import { useFiltersMemory } from 'hooks/useFiltersMemory'
import { useLocation } from 'react-router-dom';

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, meta }
}) => {

  const {
    getSavedFilter, saveFilter, clearAllFiltersForPage
} = useFiltersMemory()

  const location = useLocation();
  const pageKey = location.pathname

  const [show, setShow] = React.useState(false)
  const [selectAll, setSelectAll] = React.useState(true)
  const [selectedOptions, setSelectedOptions] = React.useState([])
  const wrapperRef = React.useRef(null)

  const [options, setOptions] = React.useState([])

  const [isFinished, setIsFinished] = React.useState(false)

  const isStatusColumn = id === 'status'
  const isToValidateFolter = (value) => value === 'À valider - factures' || value === 'À valider - photos'
  const isNeedComplementFolter = (value) => value === 'En attente - factures' || value === 'En attente - photos'
  
  // Calculate the choices available to filter
  React.useEffect(() => {
    if(options.length === 0) {
      const opts = new Set()
      preFilteredRows.forEach((row) => {
        if(!isNotEmpty(row.values[id])) return null
        if (typeof row.values[id] == 'object') {
          opts.add(row.values[id][0])
        } else {
          // Cas spécial pour les statuts
          if(isStatusColumn && isToValidateFolter(row.values[id])) opts.add('À valider')
          else if(isStatusColumn && isNeedComplementFolter(row.values[id])) opts.add('En attente')
          else opts.add(row.values[id])
        }
      })
      setOptions([...opts.values()])
    }
  }, [preFilteredRows])


  React.useEffect(() => {
    // When options are ready
    if(options.length > 0) {
      // If some filter have already been checked previously, check them
      if(getSavedFilter(pageKey, id)) {
        setSelectedOptions(getSavedFilter(pageKey, id))
        setFilter(getSavedFilter(pageKey, id))
        setSelectAll(false)
      } else {
        // Or by default check them all at the start
        setSelectedOptions(options)
        setSelectAll(true)
      }
      setIsFinished(true)
    } 
  }, [options])

  // When click on reset all filter
  React.useEffect(() => {
    // need a isFinished variable, otherwise this is triggered at the start which we don't want in case we have saved filters
    // if no filter is checked, reset all filters
    if((filterValue === '' || (Array.isArray(filterValue) && filterValue[0] === "")) && isFinished) { 
      setSelectedOptions(options)
      clearAllFiltersForPage(pageKey)
      setSelectAll(true)
    }
  }, [filterValue, isFinished])

  const handleChange = (event) => {
    const option = event.target.value
    const updatedSelections = [...selectedOptions]

    if (updatedSelections.includes(option)) {
      const index = updatedSelections.indexOf(option)
      updatedSelections.splice(index, 1)
    } else {
      updatedSelections.push(option)
    }
    if(updatedSelections.length > 0) {
      setSelectAll(false)
    }
    setSelectedOptions(updatedSelections)
    saveFilter(pageKey, id, updatedSelections || undefined)
    if(updatedSelections.length === 0) setFilter(["null"])
    else setFilter(updatedSelections || undefined)
  }

  const handleSelectAll = (event) => {
    if(selectAll) {
      setFilter(["null"]) // hack to hide everything when no filter is checked
      saveFilter(pageKey, id, ["null"])
      setSelectedOptions([])
    } else {
      setFilter(options)
      saveFilter(pageKey, id, options)
      setSelectedOptions(options)
    }
    setSelectAll((val) => !val)
  }

  const displayFilter = (e) => {
    //e.stopPropagation();
    setShow((prevShow) => !prevShow)
  }

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && show) {
        setShow(false)
      }
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [show, setShow])

  // Render a multi-select box
  return (
    <div className="u-relative u-flex u-flex-center-hz u-flex-1" ref={wrapperRef}>
      <button className="filter-trigger u-flex u-flex-center-vt" onClick={displayFilter}>
        <FilterIcon size="25px" className="u-block" color={"#005a7c"} />
        {meta?.label && <span className="u-mg-left-xs u-semibold">{meta.label}</span>}
      </button>
      <div className={`filter ${show ? 'show' : 'hide'}`} >
      <label>
                <input
                  type="checkbox"
                  value={"selectAll"}
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
                Sélectionner tout
              </label>
        {options.map((option, i) => {
          return (
            <div key={i}>
              <label>
                <input
                  type="checkbox"
                  value={option}
                  checked={selectedOptions.includes(option)}
                  onChange={handleChange}
                />
                {option}
              </label>
            </div>
          )
        } )}
      </div>
    </div>
  )
}

export const equalSomeFn = (rows, ids, filterValue) => {
  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id];
      return (
        rowValue !== undefined && // Vérification pour s'assurer que rowValue n'est pas undefined
        rowValue !== null && // Vérification pour s'assurer que rowValue n'est pas null
        rowValue.length && // Vérification pour s'assurer que rowValue est un tableau non vide
        filterValue && // Vérification pour s'assurer que filterValue n'est pas undefined
        (filterValue.includes('null') ? rowValue === null : filterValue.some(val => rowValue.includes(val)))
      );
    });
  });
};