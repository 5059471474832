/**
 * Format number and add space between each digit
 * @param {*} number (eg : 1000)
 * @returns formatted integer number (eg : 1 000)
 */
export const formatNumber = (number) => {
    return (typeof number === 'number')
      ? new Intl.NumberFormat().format(number)
      : number
  }

/**
 * Format number and add space between each digit and add '€' symbol
 * @param {*} number (eg : 1000)
 * @returns formatted integer number with currency (eg : 1 000 €)
 */
 export const formatNumberAndAddEurCurrency = (number) => {
  return (typeof number === 'number')
    ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(number)
    : number
}