import React from 'react'
import { useHistory } from 'react-router-dom'
import { useBasket } from 'hooks/useBasket'
import { privateRoutes } from 'router/routes'
import { formationList } from './list'
import CardItem from '../CardItem'

const ServiceTechnicalSelection = () => {
    const { setBasket } = useBasket()
    const history = useHistory()

    const handleSubmit = (selectedItem) => {
        const basket = [selectedItem]
        setBasket(basket)
        // Redirect to bid view
        history.push(`${privateRoutes.serviceRequest.path}/prestation-maintenance-reparation`)
    }

    return (
        <section>
            <div className="l-grid u-mg-negative-hz-m">
                {formationList.filter(item => item.display !== false).map((item, index) => {
                    return (
                        <div className="l-col-12 l-col-6@main u-pd-m u-relative" key={'item' + index}>
                            <CardItem
                                className={'u-pd-hz-l u-pd-vt-xl'}
                                item={item}
                                onClick={() => handleSubmit(item)}
                            />                           
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default ServiceTechnicalSelection