import React from 'react'
import { string, func, bool, object, shape } from 'prop-types'
import FormElement from 'components/shared/FormElement'

const MobilityInformations = ({
  formFields = {},
  formEvents = {},
  showLocalErrors = {},
  handleValidation = () => {},
  handleInputChange = () => {}
}) => (
  <div className='u-flex u-flex-dir-col u-mg-top-m u-mg-right-auto'>
    <h5 className='u-primary u-mg-bottom-m'>Services techniques</h5>
    <FormElement 
      value={formFields.bikeOverhaul}
      name="bikeOverhaul"
      type="checkbox"
      checkboxLabel="Révision du vélo des salariés"
      className="u-mg-bottom-m"
      classNameInput="u-bg-transparent"
      showErrors={showLocalErrors}
      onValidate={handleValidation}
      onChange={handleInputChange}
      disabled={!formEvents.editable}
    />
    <FormElement 
      value={formFields.technicalWorkshops}
      name="technicalWorkshops"
      type="checkbox"
      checkboxLabel="Atelier mécanique"
      className="u-mg-bottom-m"
      classNameInput="u-bg-transparent"
      showErrors={showLocalErrors}
      onValidate={handleValidation}
      onChange={handleInputChange}
      disabled={!formEvents.editable}
    />
    <FormElement 
      value={formFields.technicalCheck}
      name="technicalCheck"
      type="checkbox"
      checkboxLabel="Contrôle technique"
      className="u-mg-bottom-m"
      classNameInput="u-bg-transparent"
      showErrors={showLocalErrors}
      onValidate={handleValidation}
      onChange={handleInputChange}
      disabled={true}
    />      
    <FormElement 
      value={formFields.technicalMaintenance}
      name="technicalMaintenance"
      type="checkbox"
      checkboxLabel="Réparation"
      className="u-mg-bottom-m"
      classNameInput="u-bg-transparent"
      showErrors={showLocalErrors}
      onValidate={handleValidation}
      onChange={handleInputChange}
      disabled={true}
    />      
  </div>
)

MobilityInformations.propTypes = {
  formFields: shape({
    structureName: string,
    structureSiret: string,
    structureAddress: string,
    structureCity: string,
    structurePostCode: string,
    structurePhoneNumber: string,
    emailConnexion: string,
    beneficiaryEmail: string,
    structureEmployeeQuantity: string,
    structureEmployeeCategory: string
  }),
  setFormFields: func,
  formEvents: object,
  showLocalErrors: bool,
  handleValidation: func,
  handleInputChange: func
}

export default MobilityInformations