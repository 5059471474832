import React from 'react'
import XLSX from 'xlsx'
import { string } from 'prop-types'

import { useAlert } from 'hooks/useAlert'
import DownloadIcon from 'components/svg/Download'
import { getEntitiesToExport } from 'components/admin/DownloadCorrelation/DownloadEntitiesCorrelation'
import { getRequestsToExport, requestsCellsMerged } from 'components/admin/DownloadCorrelation/DownloadRequestsCorrelation'
import { getFilesProcessedList } from 'components/admin/FilesProcessedListProcess'
import { isArray } from 'utils/validation'
import { REQUEST_VALIDATED } from 'utils/domain/request'

import * as CatalogApi from 'services/api/CatalogApi'
import * as ScopingMeetingApi from 'services/api/scoping-meeting/ScopingMeetingApi'
import * as AuditApi from 'services/api/audit/auditApi'
import { BENEFICIARY_ID } from 'utils/domain/beneficiary'
import { getColor } from 'utils/styles'
import dateService from 'services/date'
import { useModal } from 'hooks/useModal'
import ModalDateExport from 'components/shared/ModalDateExport'
import { useAuth } from 'hooks/useAuth'

import {
  REQUEST_EQUIPMENTS_VALUE,
  REQUEST_MOBILITY_VALUE,
  REQUEST_TECHNICAL_VALUE,
  REQUEST_SUPPORT_VALUE,
  REQUEST_EQUIPMENT_ID,
  REQUEST_MOBILITY_ID,
  REQUEST_ADVICE_AND_SUPPORT_ID,
  REQUEST_AUDIT_ID,
  SCOPING_MEETING_REQUESTS_ID,
  // groupRequestsByGender
} from 'utils/domain/request'
import {
  ADVICE_AND_SUPPORT_ID,
  EQUIPMENT_PROVIDER_ID,
  MOBILITY_PROVIDER_ID,
  SCOPING_MEETING_PROVIDER_ID,
  correspondingProviders,
  AUDIT_PROVIDER_ID
} from 'utils/domain/provider'
import useBonus from 'hooks/useBonus'
import { getAutodiagToExport } from './DownloadCorrelation/DownloadAutodiagCorrelation'
import { getAllAutoDiagsFromBackOffice } from 'services/api/EntityApi'
import { AUTODIAG_STATUS_DONE } from 'pages/protected/Autodiag/context'
import autodiagService from 'utils/autodiag'
import { getEquipmentsToExport } from './DownloadCorrelation/DownloadSupplyEquipments'
import { addFormatsToCells } from 'utils/downloadFile'


// User options
const USER_DOWNLOAD_OPTIONS = [
  { value: BENEFICIARY_ID, label: 'Liste des bénéficiaires inscrits sur la plateforme', fileName: 'beneficiaires', loading: false },
]

// Beneficiary options
const BENEFICIARY_DOWNLOAD_OPTIONS = [
  { value: BENEFICIARY_ID, label: 'Liste des bénéficiaires inscrits sur la plateforme', fileName: 'beneficiaires', loading: false },
  { value: EQUIPMENT_PROVIDER_ID, label: 'Liste des fournisseurs d\'équipements', fileName: 'fournisseurs-equipements', loading: false },
  { value: MOBILITY_PROVIDER_ID, label: 'Liste des prestataires de services (SEM et ST)', fileName: 'prestataires-SEM-et-ST', loading: false },
  { value: ADVICE_AND_SUPPORT_ID, label: 'Liste des prestataires C&A', fileName: 'prestataires-conseil-accompagnement' },
  { value: SCOPING_MEETING_PROVIDER_ID, label: 'Liste des intervenants réunions de cadrage inscrits sur la plateforme', fileName: 'intervenants-reunions-cadrage', loading: false },
  { value: AUDIT_PROVIDER_ID, label: 'Liste des auditeurs inscrits sur la plateforme', fileName: 'auditeurs', loading: false },
]

// Request options
const REQUEST_DOWNLOAD_OPTIONS = [
  { value: REQUEST_EQUIPMENT_ID, label: 'Liste des demandes de prestation d\'équipements', fileName: 'demandes-prestations-equipement', loading: false },
  { value: REQUEST_MOBILITY_ID, label: 'Liste des demandes de prestations de service mobilité et de services techniques', fileName: 'demandes-prestations-SEM-et-ST', loading: false },
  { value: REQUEST_ADVICE_AND_SUPPORT_ID, label: 'Liste des demandes de prestations de conseil et accompagnement', fileName: 'demandes-prestations-CA', loading: false },
  { value: SCOPING_MEETING_REQUESTS_ID, label: 'Liste des demandes de réunion de cadrage', fileName: 'demandes-reunions-cadrage', loading: false },
  { value: REQUEST_AUDIT_ID, label: 'Liste des demandes d\'audit', fileName: 'demandes-audits', loading: false },
]

// Autodiag
const AUTODIAG_DOWNLOAD_OPTION = { value: 'autodiag', label: 'Liste des réponses à l’autodiagnostic', fileName: 'reponses-autodiagnostic', loading: false }

// Provider Data
const PROVIDER_DATA = { value: 'provider-data', label: 'Liste des données de la table de correspondance', fileName: 'donnees-table-correspondance', loading: false }

// Processing time between each BO actions
// const PROCESSING_TIME_DOWNLOAD_OPTION = { value: 'delay', label: 'Liste des dossiers traités par le BO Gestion', fileName: 'delais-traitement-controles-BO', loading: false }

const FormDownloadData = () => {
  const { isABackOfficeRelationshipManager } = useAuth()
  const { showModal, hideModal } = useModal()
  const { addAlert } = useAlert()
  const { getRequestsWithBonus, getBonusByRequests } = useBonus({ isFromBackOffice: true })
  const [ downloadOptions, setDownloadOptions ] = React.useState([ ...BENEFICIARY_DOWNLOAD_OPTIONS, ...REQUEST_DOWNLOAD_OPTIONS, AUTODIAG_DOWNLOAD_OPTION, PROVIDER_DATA ])

  const exportXlsx = (data, cellsToMerged = [], fileName) => {
    // prepare xlsx
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(data)
    addFormatsToCells(ws)
    ws['!merges'] = cellsToMerged

    // export sheet then file
    XLSX.utils.book_append_sheet(wb, ws, 'export-oepv')
    XLSX.writeFile(wb, `Export-${fileName}-${dateService.getCurrentDate('dd-MM-y')}.xlsx`)
  }

  const handleGetEntities = async (dataType) => {
    let entities = []

    if(dataType === BENEFICIARY_ID) {
      const { res: beneficiaries, getError: getEntitiesError } = await CatalogApi.getBackOfficeBeneficiaries()
      if(getEntitiesError()) throw getEntitiesError()

      entities = await getBeneficiariesWithBonusInformations(beneficiaries)

    } else {
      const serviceId = correspondingProviders[dataType].serviceId

      const { res: readEntities, getError: getEntitiesError } = await CatalogApi.getEntities({ services: serviceId })
      if(getEntitiesError()) throw getEntitiesError()

      entities = [...readEntities]
    }

    if(Object.keys(entities).length === 0) {
      throw new Error('Aucune donnée à exporter.')
    }

    return entities.flat().map(entity => ({
      ...entity,
      ...entity.entity_data,
      entity_id: entity.entity_id,
      create_date: entity.create_date,
      entity_check: entity.entity_check
    }))
  }

  const getBeneficiariesWithBonusInformations = async (beneficiaries) => {

    // fonction qui traite un beneficiaire
    var process = async (beneficiary) => {
      const { res: requests, getError: getRequestsError } = await CatalogApi.getBackOfficeRequestsByEntity(beneficiary.entity_id)
      if(getRequestsError()) throw getRequestsError()

      const { res: regularisations, getError: getRegularisationsError } = await CatalogApi.getBackOfficeRegularisationsByEntity(beneficiary.entity_id)
      if(getRegularisationsError()) throw getRegularisationsError()

      // Adjust the regularisation folder structure to the request structure to fit the table
      const mappedRegularisationFolders = (regularisations ?? []).map(folder => ({
        genderLabel: 'Régularisation',
        request_fub_id: folder?.request_fub_id,
        create_date: folder?.create_date,
        amount: '-',
        bonus: +folder?.regularization_data?.amount,
        realBonus: +folder?.regularization_data?.real_amount_to_refund,
        real_amount_to_refund: +folder?.regularization_data?.real_amount_to_refund,
        statusLabel: folder?.status === 0 ? 'Validé' : 'Payé',
        regularisation_status: folder?.status,
        // eq of validated request
        status: folder?.status === 0 ? REQUEST_VALIDATED : 0
      }))

      const requestsWithBonus = await getRequestsWithBonus([...requests, ...mappedRegularisationFolders], beneficiary?.entity_data?.structureEmployeeQuantity)
      // const requestsGroupedByGender = groupRequestsByGender(requestsWithBonus)

      const {
        totalAmountEngaged,
        totalAmountValidated,
        availableBonus,
        remainingBonus
      } = getBonusByRequests({ requests: Object.values(requestsWithBonus).flat(), userEntityData: beneficiary?.entity_data })

      return {
        ...beneficiary,
        totalAmountEngaged: totalAmountEngaged,
        totalAmountValidated: totalAmountValidated,
        availableBonus: availableBonus,
        remainingBonus: remainingBonus
      }
    };

    // code discutable pour afficher la progression afin que l'utilisateur soit patient ;-)
    const newDiv = document.createElement("div");
    const newContent = document.createTextNode("");
    newDiv.style.position = 'fixed';
    newDiv.style.width = '200px';
    newDiv.style.height = '50px';
    newDiv.style.top = '0px';
    newDiv.style.right = '0px';
    newDiv.style.backgroundColor = '#DDD';
    newDiv.style.padding = '12px';
    newDiv.style.borderRadius = '8px';
    newDiv.appendChild(newContent);
    document.body.append(newDiv);

    var res = []; // tableau du résultat, on le concat avec le result de chaque beneficiaire
    var benRes = null;  // result d'un beneficiaire
    var total = beneficiaries.length;
    // boucle sur tous les beneficiaires, un par un on fait await afin d'un faire un à la fois
    for(var b=0;b<beneficiaries.length;b++){
      benRes = await process(beneficiaries[b]);
      newDiv.textContent = 'Export en cours : '+Math.round((b/total)*100)+'%';
      res = res.concat(benRes);
    }
    // on supprime le magnifique indicateur de progression
    document.body.removeChild(newDiv);

    return res;
  }

  const fetchRequestData = async (type) => {
    const { res: readRequests, getError: getRequestsError } = await CatalogApi.getRequestsForDashboardAdmin(type)
    if(getRequestsError()) throw getRequestsError()
    return [...(isArray(readRequests) ? readRequests : [])]
  }

  const fetchScopingMeetingRequests = async () => {
    const { res: readRequests, getError: getRequestsError } = await ScopingMeetingApi.getScopingMeetings()
    if(getRequestsError()) throw getRequestsError()
    return [...(isArray(readRequests) ? readRequests : [])]
  }

  const fetchAuditRequests = async () => {
    const { res: readRequests, getError: getRequestsError } = await AuditApi.getAudits()
    if(getRequestsError()) throw getRequestsError()
    return [...(isArray(readRequests) ? readRequests : [])]
  }

  const getRequestsWithSiret = (requests, entities) => {
    return requests.map(request => ({
      ...request,
      siret: entities.find(entity => entity?.entity_id === request?.request_entity_id)?.entity_data?.siret
    }))
  }

  const getRequestsWithProviderName = (requests, entities) => {
    return Promise.all([...requests].map(async request => ({
      ...request,
      providerStructureName: await getProviderName(request.request_id, entities)
    })))
  }

  const getProviderName = async (requestId, entities) => {
    const { res: historyRequests, getError: getBackOfficeRequestHistoryError } = await CatalogApi.getBackOfficeRequestHistory(requestId)
    if(getBackOfficeRequestHistoryError()) throw getBackOfficeRequestHistoryError()

    const providerHistoryRequest = historyRequests.find(request => request.request_data?.operation_code === 'PROVIDER')
    return entities.find(entity => entity?.entity_id === providerHistoryRequest?.request_data?.operation_data?.providers[0])?.entity_data?.structureName
  }

  const handleGetRequests = async (type) => {
    let requests = []
    const { res: entities, getError: getEntitiesError } = await CatalogApi.getEntities()
    if(getEntitiesError()) throw getEntitiesError()

    switch(type) {
      case REQUEST_EQUIPMENT_ID:
        const equipmentRequests = await fetchRequestData(REQUEST_EQUIPMENTS_VALUE)
        requests = getRequestsWithSiret(equipmentRequests, entities)
        break

      case REQUEST_MOBILITY_ID:
        const requestsMobility = await fetchRequestData(REQUEST_MOBILITY_VALUE)
        const requestsTechnicalService = await fetchRequestData(REQUEST_TECHNICAL_VALUE)
        const mobAndTechnicalServiceRequests = [...requestsMobility, ...requestsTechnicalService]

        requests = getRequestsWithSiret(mobAndTechnicalServiceRequests, entities)
        requests = await getRequestsWithProviderName(requests, entities)
        break

      case REQUEST_ADVICE_AND_SUPPORT_ID:
        const adviceAndSupportRequests = await fetchRequestData(REQUEST_SUPPORT_VALUE)

        requests = getRequestsWithSiret(adviceAndSupportRequests, entities)
        requests = await getRequestsWithProviderName(requests, entities)
        break

      case SCOPING_MEETING_REQUESTS_ID:
        requests = await fetchScopingMeetingRequests()
        break

      case REQUEST_AUDIT_ID:
        requests = await fetchAuditRequests()
        break

      default:
    }

    // if any data throw error
    if(Boolean(requests) && requests?.length === 0) {
      throw new Error('Aucune donnée à exporter.')
    }

    return requests.flat().map(request => ({
        ...request,
        entity: entities.find(entity => entity?.entity_id === request.request_entity_id),
        provider: entities.find(entity => entity?.entity_id === request.request_provider_id),
    }))
  }

  const handleLoadingOption = (state, option) => {
    setDownloadOptions(prevOptions => {
      prevOptions.find(currOption => currOption.value === option.value)
      const foundIndex = prevOptions.findIndex(currOption => currOption.value === option.value)
      prevOptions[foundIndex] = { ...prevOptions[foundIndex], loading: state }
      return [...prevOptions]
    })
  }

  const handleClickToDownload = async (downloadType, fileName, option, dates) => {

    try {
      handleLoadingOption(true, option)
      const requestDownloadOptionTypes = REQUEST_DOWNLOAD_OPTIONS.map(request => request.value)
      const userDownloadOptionTypes = BENEFICIARY_DOWNLOAD_OPTIONS.map(request => request.value)

      // Requests data
      if(requestDownloadOptionTypes.includes(downloadType)) {
        const requests = await handleGetRequests(downloadType)
        const data = getRequestsToExport(requests, downloadType)
        const cellsToMerged = requestsCellsMerged[downloadType] ? requestsCellsMerged[downloadType] : {}
        exportXlsx(data, cellsToMerged, fileName)
        handleLoadingOption(false, option)
      }
      // Entitie export
      else if(userDownloadOptionTypes.includes(downloadType)){
        const entities = await handleGetEntities(downloadType)
        const data = await getEntitiesToExport(entities, downloadType)
        exportXlsx(data, [], fileName)
        handleLoadingOption(false, option)
      }
      // Autodiag export
      else if(AUTODIAG_DOWNLOAD_OPTION.value === downloadType) {
        const tabEntityId = {}
        const params = { diagStatus: AUTODIAG_STATUS_DONE }
        // STEP 1 - get autodiags (call api)
        const { res: autodiags, getError } = await getAllAutoDiagsFromBackOffice(params)
        if(getError()) throw getError()

        const autodiagsWithEntityInformations = []
        for (let index = 0; index < autodiags?.length; index++) {
          const autodiag = autodiags[index]
          const oneAutodiagEntity = autodiag
          const entityId = autodiag.entity_id
          if (!tabEntityId[entityId]) {
            const { res: entity, getError } = await CatalogApi.getBackOfficeEntity(entityId)
            if(getError()) throw getError()
            tabEntityId[entityId] = entity
          }
          const entityToReturn = tabEntityId[entityId]
          oneAutodiagEntity.siret = entityToReturn.entity_data.siret
          oneAutodiagEntity.structureName = entityToReturn.entity_data.structureName
          autodiagsWithEntityInformations.push(oneAutodiagEntity)
        }

        const autodiagsFormatted = autodiagsWithEntityInformations
                                    .sort((a, b) => a.entity_id < b.entity_id) // ordered by entity_id
                                    .sort((a, b) => a.entity_id === b.entity_id && a.modify_date > b.modify_date) // ordered by the most recent autodiag (used if the same beneficiary have multiple autodiags)
        const { result: autodiagQuestionsFromFile } = await autodiagService.importAutodiagFile()
        const data = await getAutodiagToExport(autodiagsFormatted, autodiagQuestionsFromFile)

        data?.length ? exportXlsx(data, [], fileName) : addAlert('error', "Nous n'avons pas de données à exporter")
        handleLoadingOption(false, option)
      }
      // Supply Equipments export
      else if(PROVIDER_DATA.value === downloadType) {
        const { res: equipments, getError } = await CatalogApi.getAllEquipmentsSupplyList()
        if(getError()) throw getError()

        const reoderedEquipments = equipments.sort((a, b) => a.equipments_supply_id - b.equipments_supply_id)

        const data = await getEquipmentsToExport(reoderedEquipments)
        data?.length ? exportXlsx(data, [], fileName) : addAlert('error', "Nous n'avons pas de données à exporter")

        handleLoadingOption(false, option)
      }
      // Processing time between each BO actions
      // = the difference (in days) between the day on which the beneficiary performs an action that creates a folder in the Management BO or returns it to the "to be validated" status, and the day on which the corresponding check is carried out.
      // else if(PROCESSING_TIME_DOWNLOAD_OPTION.value === downloadType) {
      //     const histories = await getFilesProcessedList(dates)
      //     const data = await getProcessingTimeExport(histories)

      //     data.length ? exportXlsx(data, [], fileName) : addAlert('error', "Nous n'avons pas de données à exporter")
      //     handleLoadingOption(false, option)
      //     hideModal()
      // }
      else {
        throw new Error("Cet export n'est pas géré.")
      }
    } catch (error) {
        const errorMessage = error?.message?.toString() || error?.toString()
        addAlert('error', errorMessage)
        console.error(errorMessage)
        handleLoadingOption(false, option)
    }
  }

  const DownloadXLSXLink = ({ label = '', downloadType = '', fileName = '' }) => {

    const option = downloadOptions?.find(option => option.value === downloadType)

    return (
      <>
        { (option && !option.loading) &&
          <button
            className="u-mg-left-m u-mg-top-l"
            type="button"
            onClick={() => handleClickToDownload(downloadType, fileName, option)}
            aria-label=''
          >
            <DownloadIcon className="vertical-align u-mg-right-xl" color={getColor().primary} />
            <span>{label}</span>
          </button>
        }
        { (option && option.loading) &&
          <div className="u-mg-left-m u-mg-top-l">
            <div className="c-spinner vertical-align u-mg-right-l"></div>
            <span>{label}</span>
          </div>
        }
      </>
    )
  }

  const openModalForExport = (downloadType, fileName, option) => {
    showModal(
        ModalDateExport,
        {
            title: 'Souhaitez-vous choisir la date de début et fin de l’export pour les dossiers traités par le BO Gestion ?',
            validation: (dates) => handleClickToDownload(downloadType, fileName, option, dates)
        },
        { isClosable: false }
    )
}

  return (
    <>
      {isABackOfficeRelationshipManager &&
        USER_DOWNLOAD_OPTIONS.map((option, index) => {
            return (
              <DownloadXLSXLink
                label={option.label}
                downloadType={option.value}
                fileName={option.fileName}
                key={index}
              />
            )
        })
      }
      {!isABackOfficeRelationshipManager &&
        <>
          { BENEFICIARY_DOWNLOAD_OPTIONS.map((option, index) => {
              return (
                <DownloadXLSXLink
                  label={option.label}
                  downloadType={option.value}
                  fileName={option.fileName}
                  key={index}
                />
              )
          })}
          { REQUEST_DOWNLOAD_OPTIONS.map((option, index) => {
              return (
                <DownloadXLSXLink
                  label={option.label}
                  downloadType={option.value}
                  fileName={option.fileName}
                  key={index}
                />
              )
          })}
          <DownloadXLSXLink
            label={AUTODIAG_DOWNLOAD_OPTION.label}
            downloadType={AUTODIAG_DOWNLOAD_OPTION.value}
            fileName={AUTODIAG_DOWNLOAD_OPTION.fileName}
          />

          <DownloadXLSXLink
            label={PROVIDER_DATA.label}
            downloadType={PROVIDER_DATA.value}
            fileName={PROVIDER_DATA.fileName}
          />

          {/* Cas spécifique pour "Liste des dossiers traités par le BO Gestion" */}
          {/* <div>
              <button
                className="u-mg-left-m u-mg-top-l"
                type="button"
                onClick={() => openModalForExport(PROCESSING_TIME_DOWNLOAD_OPTION.value, PROCESSING_TIME_DOWNLOAD_OPTION.fileName, 'delay')}
                aria-label=''
              >
                <DownloadIcon className="vertical-align u-mg-right-xl" color={getColor().primary} />
                <span>{PROCESSING_TIME_DOWNLOAD_OPTION.label}</span>
              </button>
          </div> */}
        </>
      }
    </>
  )
}

FormDownloadData.propTypes = {
    className: string
}
export default FormDownloadData
