import { number, string, oneOf } from 'prop-types'
import React from 'react'

import { DEFAULT_SIZE } from 'utils/icons'

const StarIcon = ({ size = DEFAULT_SIZE, color }) => {
	return (
        <svg style={{ width: size, height: size }} fill={color} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -10 511.991 511">            
            <path d="M510.652 185.883a27.177 27.177 0 00-23.402-18.688l-147.797-13.418-58.41-136.75C276.73 6.98 266.918.497 255.996.497s-20.738 6.483-25.023 16.53l-58.41 136.75-147.82 13.418c-10.837 1-20.013 8.34-23.403 18.688a27.25 27.25 0 007.937 28.926L121 312.773 88.059 457.86c-2.41 10.668 1.73 21.7 10.582 28.098a27.087 27.087 0 0015.957 5.184 27.14 27.14 0 0013.953-3.86l127.445-76.203 127.422 76.203a27.197 27.197 0 0029.934-1.324c8.851-6.398 12.992-17.43 10.582-28.098l-32.942-145.086 111.723-97.964a27.246 27.246 0 007.937-28.926zM258.45 409.605"/>
		</svg>
	)
}

StarIcon.propTypes = {
    size: oneOf([string, number]),
    color: string
}

export default StarIcon
