import React from 'react'

// Data
import { AuthProvider } from 'hooks/useAuth'
import { BasketProvider } from 'hooks/useBasket'

// Component
import { AlertProvider } from 'hooks/useAlert'
import { AsideMenuProvider } from 'hooks/useAsideMenu'
import { ModalProvider } from 'hooks/useModal'
import { ModalRoot } from 'components/shared/Modal'
import { DrawerProvider } from 'hooks/useDrawer'
import { FiltersMemoryProvider } from 'hooks/useFiltersMemory'
import { DrawerRoot } from 'components/shared/Drawer'
import { HelmetProvider } from 'react-helmet-async'

export const Providers = ({ children }) => {
    return (
        <HelmetProvider>
            <FiltersMemoryProvider>
                <BasketProvider>
                    <AuthProvider>
                            <ModalProvider>
                                <AlertProvider>
                                    <DrawerProvider>
                                        <AsideMenuProvider>
                                                <>{children}</>
                                                <DrawerRoot />
                                                <ModalRoot />
                                        </AsideMenuProvider>
                                    </DrawerProvider>
                                </AlertProvider>
                            </ModalProvider>
                    </AuthProvider>
                </BasketProvider>
            </FiltersMemoryProvider>
        </HelmetProvider>
    )
}

export default Providers