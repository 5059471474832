import React from 'react'
import ProtectedLayout from 'layouts/Protected'
import { NavLink } from 'react-router-dom'
import CatalogRoutes from 'components/catalog/CatalogRoutes'
import { privateRoutes } from 'router/routes'

const HomePage = () => {
    return (
        <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main">
            <div className="l-container">
                <h1 className="c-h1 u-secondary u-bold u-center u-mg-bottom-xl">Que voulez-vous faire ?</h1>

                <div className="l-grid">
                    {CatalogRoutes.map((item, index) => {
                        return (
                            <div className="l-col-12 l-col-6@main u-pd-m" key={index}>
                                <NavLink
                                    to={`${privateRoutes.catalog.path}/${item.url}`}
                                    className={'c-catalog-card  u-pd-m u-pd-l@main ' + (!item.url || !item.componentSelection ? 'u-disabled' : '')}
                                >
                                    <h3 className="c-h4 u-mg-bottom-m">{item?.thumbnail?.label}</h3>
                                    {item?.thumbnail?.description.map((d, i) => (<p className="u-mg-bottom-s" key={i}>{d}</p>))}

                                    {item?.thumbnail?.picture && <img src={item.thumbnail.picture} alt={item?.thumbnail?.label} />}
                                </NavLink>
                            </div>
                        )
                    })}
                </div>
            </div>
        </ProtectedLayout>
    )
}

export default HomePage
