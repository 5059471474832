import React, { useState, useMemo, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { string, shape } from 'prop-types'
import Spinner from 'react-svg-spinner'

import {
  getBeneficiaryByValue,
  getFolderTypeLabel,
  getBackOfficeStatus,
} from 'utils/domain/beneficiary'
import {
  getFolderTypeLabel as getRequestFolderTypeLabel,
  getBackOfficeListStatus as getBackOfficeRequestsListStatus,
  isMobilityFolder,
  isEquipmentFolder,
  isTechnicalFolder,
  NEED_COMPLEMENT_AFTER_SIGN_OFF_SHEETS_UPLOADED,
  PICTURES_VALIDATED,
  SIGN_OFF_REPORT_UPLOADED,
  REQUEST_REFUSED,
  REQUEST_VALIDATED,
  isAdviceAndSupportFolder,
} from 'utils/domain/request'

import { checkIsBackOfficeManagementSupervisor } from 'utils/domain/user'

import dateService from 'services/date'
import { getColor } from 'utils/styles'
import { useAlert } from 'hooks/useAlert'
import { privateRoutes } from 'router/routes'
import * as CatalogApi from 'services/api/CatalogApi'
import { getBackOfficeUsers } from 'services/api/UserApi'
import BackOfficeLayout from 'layouts/back-office'
import { useAuth } from 'hooks/useAuth'
import { useModal } from 'hooks/useModal'

import Card from 'components/platform/Card'
import Table from 'components/shared/Table'
import PlusIcon from 'components/svg/Plus'
import { PRIMARY_COLOR } from 'utils/icons'
import DownloadIcon from 'components/svg/Download'
import ModalDateExport from 'components/shared/ModalDateExport'
import { BACKOFFICE_USER_STATUS } from 'utils/domain/user'
import FormElement from 'components/shared/FormElement'

import { exportXlsx } from 'utils/downloadFile'
import { SelectColumnFilter, equalSomeFn } from 'components/shared/Table/SelectColumnFilter'
import {
  DateColumnFilter,
  dateBetweenFilterFn,
} from 'components/shared/Table/DateColumnFilter'
import { isNotEmpty } from 'utils/validation'

const columnsToExportForSupervisor = {
  structureName: "Nom de l'établissement",
  gender: "Typologie de l'établissement",
  structureCity: 'Ville',
  contactEmail: 'E-mail de contact',
  structurePhonenumber: 'Téléphone du responsable du compte',
  folderType: 'Acte BO',
  id: 'N° de dossier',
  status: 'Statut',
  advisor: 'Conseiller',
  creationDate: 'Date de création du dossier',
  lastUpdateDate: 'Date de dernière mise à jour du dossier',
  processingDelay: 'Délai de traitement (en jours)',
  comment: 'Commentaire',
}

const columnsToExportForAdvisor = {
  structureName: "Nom de l'établissement",
  gender: "Typologie de l'établissement",
  structureCity: 'Ville',
  contactEmail: 'E-mail de contact',
  structurePhonenumber: 'Téléphone du responsable du compte',
  folderType: 'Acte BO',
  id: 'N° de dossier',
  status: 'Statut',
  creationDate: 'Date de création du dossier',
  lastUpdateDate: 'Date de dernière mise à jour du dossier',
  processingDelay: 'Délai de traitement (en jours)',
  comment: 'Commentaire',
}

const GoToDetailCell = ({ cell }) => {
  const getPathFromCell = (cell) => {
    if (cell.row.original.isEntity)
      return privateRoutes.backOffice.entityFolder.navLink({
        beneficiaryId: cell?.row.original.id,
      })

    return privateRoutes.backOffice.requestFolder.navLink({
      requestId: cell?.row.original.id,
      beneficiaryId: cell?.row.original.entity_id,
    })
  }

  const isNavAvailable =
    isEquipmentFolder(cell?.row.original.id) ||
    isMobilityFolder(cell?.row.original.id) ||
    isAdviceAndSupportFolder(cell?.row.original.id) ||
    isTechnicalFolder(cell?.row.original.id) ||
    cell.row.original.isEntity

  const color = cell.value.includes('attente')
    ? 'u-yellow'
    : cell.value.includes('valider')
    ? 'u-green90'
    : 'u-danger'

  if (isNavAvailable) {
    return (
      <NavLink className={color} to={getPathFromCell(cell)}>
        {cell.value}
      </NavLink>
    )
  }

  return <div className="u-grey90">{cell.value}</div>
}

GoToDetailCell.propTypes = {
  cell: shape({
    value: string,
  }),
}

const Folders = () => {
  const [folders, setFolders] = useState([])
  const [foldersToExport, setFoldersToExport] = useState([])

  const [advisors, setAdvisors] = useState([])
  const [loadingNewFolder, setLoadignNewFolder] = useState(false)

  const [listToRealloc, setlistToRealloc] = useState([])

  const { user } = useAuth()
  const { addAlert } = useAlert()
  const { showModal, hideModal } = useModal()

  const DATE_PATTERN_IN = 'dd/MM/yyyy'
  const DATE_PATTERN_OUT = 'yyyy-MM-dd'
  const VALIDATED = 'Validé'

  // control if the connected user is a advisor (return false) or a supervisor (return true)
  const isSupervisor = checkIsBackOfficeManagementSupervisor({
    gender: user?.entity_data?.gender,
    permission: user?.entity_data?.permission,
  })

  React.useEffect(() => {
      if(isSupervisor) getAdvisors()
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if(isNotEmpty(advisors) && isSupervisor) getFolders()
    else if(!isSupervisor) getFolders()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisors, isSupervisor])

  const getFolders = useCallback(async () => {
    try {
      // 1 - Folders to display
      const { res: foldersResponse = [], getError } =
        await CatalogApi.getBackOfficeFolders()
      if (getError()) throw getError()
      const { entities, requests } = foldersResponse

      const requestsFiltered = requests.filter(({ status }) => {
        // Excluding some status from list 5 - 6 - 8 - 10 - 62
        return ![
          PICTURES_VALIDATED,
          SIGN_OFF_REPORT_UPLOADED,
          NEED_COMPLEMENT_AFTER_SIGN_OFF_SHEETS_UPLOADED,
          REQUEST_VALIDATED,
          REQUEST_REFUSED,
        ].includes(status)
      })

      const formattedFolders = formatFolders({
        entities: entities,
        requests: requestsFiltered,
      })

      formattedFolders.sort((a, b) => {
        const dateA = new Date(a.create_date)
        const dateB = new Date(b.create_date)
        return dateB.getTime() - dateA.getTime()
      }).sort((a, b) => {
        if (statusPriority(a.status) < statusPriority(b.status)) {
          return -1
        }
        if (statusPriority(a.status) > statusPriority(b.status)) {
          return 1
        }
        return 0
      })

      setFolders(formattedFolders)

      // 2 - Folders to Export
      const { res: foldersValidated, getError: getErrorValidate } =
        await CatalogApi.getBackOfficeFoldersValidate()
      if (getErrorValidate()) throw getErrorValidate()
      const {
        entities: entitiesValidatedToExport,
        requests: requestsValidatedToExport,
      } = foldersValidated

      // Only folders with a required action are displayed. But we export all the files except those displayed.
      const requestsExportableButNotDisplay = requests.filter(
        (request) => !requestsFiltered.includes(request)
      )
      const requestsToExport = [
        ...requestsValidatedToExport,
        ...requestsExportableButNotDisplay,
      ]

      const requestsFormatedToExport = formatFoldersToExport({
        entities: entitiesValidatedToExport,
        requests: requestsToExport,
      })

      // Also get regularisation folders to export
      const {
        res: regularisationFoldersResponse,
        getError: getRegularisationFoldersError,
      } = await CatalogApi.getRegularisationFolders()
      if (getRegularisationFoldersError()) throw getRegularisationFoldersError()
      const mappedRegularisationFolders = regularisationFoldersResponse.map(
        (request) => ({
          structureName: request?.entity_data?.structureName,
          gender: getBeneficiaryByValue(request?.entity_data?.gender)?.label,
          structureCity: request?.entity_data?.structureCity,
          mail: request?.entity_data?.mail,
          contactEmail: request?.entity_data?.contactEmail,
          structurePhonenumber: request?.entity_data?.structurePhonenumber,
          folderType: 'Régularisation',
          id: request?.request_fub_id,
          status: request?.status === 0 ? 'Validé' : 'Payé',
          creationDate: request?.create_date,
          lastUpdateDate: request?.create_date,
          processingDelay: 0,
          entityId: request?.entity_id,
          fubUserId: null,
          advisor: [request?.fub_user_name],
          comment: request?.regularization_data?.comment,
        })
      )
      setFoldersToExport([
        ...requestsFormatedToExport,
        ...mappedRegularisationFolders,
      ])
    } catch (error) {
    console.error(error)
    addAlert('error', error?.toString())
    }
  }, [advisors])

  const statusPriority = (status) => {
    if (isSupervisor)
      return status.includes('attente') // "Transmis FUB" as first line when logged as supervisor
        ? 2
        : status.includes('valider')
        ? 3
        : 1
    else
      return status.includes('attente') // "A valider" as first line when logged as advisor
        ? 2
        : status.includes('valider')
        ? 1
        : 3
  }

  const requestNewFolder = async () => {
    try {
      setLoadignNewFolder(true)
      const { getError } = await CatalogApi.yourSelfAssignFolder()
      if (getError()) throw getError()

      addAlert('success', 'Un nouveau dossier vous a été affecté')

      await getFolders()
    } catch (error) {
      console.error(error)
      addAlert('error', error?.toString())
    } finally {
      setLoadignNewFolder(false)
    }
  }

  const formatFolders = (folders) => {
    const { entities, requests: requestsEntity } = folders

    return [
      ...requestsEntity.map(
        ({
          create_date,
          status,
          entity_data,
          modify_date,
          request_fub_id,
          entity_id,
          fub_user_id,
          request_id,
          request_data,
        }) => ({
          structureName: entity_data?.structureName,
          gender: getBeneficiaryByValue(entity_data?.gender)?.label,
          structureCity: entity_data?.structureCity,
          mail: entity_data?.mail,
          contactEmail: entity_data?.contactEmail,
          structurePhonenumber: entity_data?.structurePhonenumber,
          creationDate: dateService.formatDate(create_date),
          create_date: create_date,
          folderType: getRequestFolderTypeLabel(request_fub_id),
          status: getBackOfficeRequestsListStatus(
            status,
            request_fub_id,
            request_data?.operation_data?.sending_fub
          ),
          advisor: getAdvisorNameById(fub_user_id),
          isEntity: false,
          id: request_fub_id,
          entity_id,
          lastUpdateDate: dateService.formatDate(modify_date),
          processingDelay: dateService.getDaysDifferenceBetweenDates(
            create_date,
            modify_date
          ),
          fubUserId: fub_user_id,
          requestId: request_id,
          comment: request_data.operation_data.comment,
        })
      ),
      ...entities.map(
        ({
          create_date,
          entity_check,
          entity_data,
          modify_date,
          entity_id,
          fub_user_id,
        }) => ({
          structureName: entity_data?.structureName,
          gender: getBeneficiaryByValue(entity_data?.gender)?.label,
          structureCity: entity_data?.structureCity,
          mail: entity_data?.mail,
          contactEmail: entity_data?.contactEmail,
          structurePhonenumber: entity_data?.structurePhonenumber,
          creationDate: dateService.formatDate(create_date),
          create_date: create_date,
          folderType: getFolderTypeLabel(entity_data?.gender),
          status: getBackOfficeStatus(entity_check, entity_data?.sending_fub),
          advisor: getAdvisorNameById(fub_user_id),
          isEntity: true,
          id: entity_id,
          lastUpdateDate: getLastUpdateDate(
            entity_check,
            modify_date,
            create_date,
            entity_id
          ),
          processingDelay: dateService.getDaysDifferenceBetweenDates(
            create_date,
            modify_date
          ),
          fubUserId: fub_user_id,
          comment: entity_data?.comment,
        })
      ),
    ]
  }

  const formatFoldersToExport = (folders) => {
    const { entities, requests } = folders
    return [
      ...requests.map(
        ({
          create_date,
          entity_data,
          modify_date,
          request_fub_id,
          fub_user_id,
          entity_id,
          status,
          request_data,
        }) => ({
          structureName: entity_data?.structureName,
          gender: getBeneficiaryByValue(entity_data?.gender)?.label,
          structureCity: entity_data?.structureCity,
          mail: entity_data?.mail,
          contactEmail: entity_data?.contactEmail,
          structurePhonenumber: entity_data?.structurePhonenumber,
          folderType: getRequestFolderTypeLabel(request_fub_id),
          id: request_fub_id,
          status: getBackOfficeRequestsListStatus(
            status,
            request_fub_id,
            request_data?.operation_data?.sending_fub
          ),
          creationDate: create_date,
          lastUpdateDate: modify_date,
          processingDelay: dateService.getDaysDifferenceBetweenDates(
            create_date,
            modify_date
          ),
          entityId: entity_id,
          fubUserId: fub_user_id,
          advisor: getAdvisorNameById(fub_user_id),
          comment: request_data.operation_data?.comment,
        })
      ),
      ...entities.map(
        ({
          create_date,
          entity_data,
          entity_id,
          fub_user_id,
          entity_check,
        }) => ({
          structureName: entity_data?.structureName,
          gender: getBeneficiaryByValue(entity_data?.gender)?.label,
          structureCity: entity_data?.structureCity,
          mail: entity_data?.mail,
          contactEmail: entity_data?.contactEmail,
          structurePhonenumber: entity_data?.structurePhonenumber,
          folderType: getFolderTypeLabel(entity_data?.gender),
          id: entity_id,
          status: VALIDATED,
          creationDate: create_date,
          lastUpdateDate: entity_check?.date_check,
          processingDelay: dateService.getDaysDifferenceBetweenDates(
            create_date,
            entity_check?.date_check
          ),
          fubUserId: fub_user_id,
          advisor: getAdvisorNameById(fub_user_id),
          comment: entity_data?.comment,
        })
      ),
    ]
  }

  const getLastUpdateDate = (
    entity_check,
    modify_date,
    create_date,
    entity_id
  ) => {
    try {
      const statusStr = getBackOfficeStatus(entity_check)
      if (statusStr === 'Validé')
        return dateService.formatDate(entity_check?.date_check)
      if (statusStr === 'À valider') return dateService.formatDate(create_date)
      if (statusStr === 'En attente')
        return modify_date && dateService.formatDate(modify_date)
      throw new Error('Adresse non reconnue, code postal inexistant')
    } catch (error) {
      console.log('error', error?.toString())
      console.log(
        'error',
        `entity_id de la donnée concernée par cette erreur : ${entity_id}`
      )
    }
  }

  // Get list of advisors
  const getAdvisors = useCallback(async () => {
    try {
      const { res: advisorsResponse, getError } = await getBackOfficeUsers()
      if (getError()) throw getError()
      setAdvisors(advisorsResponse)
    } catch (error) {
      console.error(error)
      addAlert('error', error?.toString())
    }
  }, [])

  const exportValidationHandler = (dates) => {
    let foldersFiltered = []

    if (!dates.areDates) {
      foldersFiltered = [...folders, ...foldersToExport]
    } else {
      const foldersValidatedFilteredByDates = getFoldersFilteredByDates(
        dates,
        foldersToExport
      )
      foldersFiltered = [...folders, ...foldersValidatedFilteredByDates]
    }

    exportXlsx(
      columnsToExportForAdvisor,
      columnsToExportForSupervisor,
      isSupervisor,
      foldersFiltered,
      'export-mes-dossiers'
    )
    hideModal()
  }

  const openModalForExport = () => {
    showModal(
      ModalDateExport,
      {
        title:
          'Souhaitez-vous choisir la date de début et fin de l’export pour les dossiers validés et refusés ?',
        validation: (dates) => exportValidationHandler(dates),
      },
      { isClosable: false }
    )
  }

  const getFoldersFilteredByDates = (dates, foldersValidated) => {
    return foldersValidated.filter((folder) => {
      // filtre is active only on validated folders. Others folders are return and displayed in the export file.
      if (folder.status === VALIDATED) {
        if (dates.firstDate && dates.lastDate) {
          const docUpdateDate = new Date(
            dateService.convertFrenchStringDateToEnglishStringDate(
              dateService.formatDate(folder.lastUpdateDate),
              DATE_PATTERN_IN,
              DATE_PATTERN_OUT
            )
          )
          const firstDate = new Date(dates.firstDate)
          const lastDate = new Date(dates.lastDate)
          return (
            (dateService.isAfterThan(docUpdateDate, firstDate) &&
              dateService.isBeforeThan(docUpdateDate, lastDate)) ||
            dateService.isEqualThan(docUpdateDate, firstDate) ||
            dateService.isEqualThan(docUpdateDate, lastDate)
          )
        }
        if (dates.firstDate) {
          const docUpdateDate = new Date(
            dateService.convertFrenchStringDateToEnglishStringDate(
              dateService.formatDate(folder.lastUpdateDate),
              DATE_PATTERN_IN,
              DATE_PATTERN_OUT
            )
          )
          const firstDate = new Date(dates.firstDate)
          return (
            dateService.isAfterThan(docUpdateDate, firstDate) ||
            dateService.isEqualThan(docUpdateDate, firstDate)
          )
        }
        if (dates.lastDate) {
          const docUpdateDate = new Date(
            dateService.convertFrenchStringDateToEnglishStringDate(
              dateService.formatDate(folder.lastUpdateDate),
              DATE_PATTERN_IN,
              DATE_PATTERN_OUT
            )
          )
          const lastDate = new Date(dates.lastDate)
          return (
            dateService.isBeforeThan(docUpdateDate, lastDate) ||
            dateService.isEqualThan(docUpdateDate, lastDate)
          )
        }
      }
      return folder
    })
  }

  const changeAdvisorAllocation = async (selectedFolder, e) => {
    const newAdvisor = e.target.value

    if (newAdvisor === selectedFolder.fubUserId) return
    try {
      // It's an entity
      if (selectedFolder.isEntity) {
        const entityInfo = {
          entity_id: selectedFolder.id,
          fub_user_id: newAdvisor,
        }

        const { getError } = await CatalogApi.editBackOfficeAdvisorEntity(
          entityInfo
        )
        if (getError()) throw getError()
      } else {
        // It's a request
        const entityInfo = {
          request_id: selectedFolder.requestId,
          fub_user_id: newAdvisor,
        }

        const { getError } = await CatalogApi.editBackOfficeAdvisorRequest(
          entityInfo
        )
        if (getError()) throw getError()
      }
      addAlert('success', 'Le dossier a bien été réaffecté.')
      //await getFolders();
    } catch (error) {
      console.error(error)
      addAlert('error', error?.toString())
    }
  }

  const changeAdvisorAllocationMultiple = async (e) => {
    const newAdvisor = e.target.value

    try {
      for (let i = 0; i < listToRealloc.length; i++) {
        const selectedFolder = folders.find((f) => f.id === listToRealloc[i])

        if (newAdvisor === selectedFolder.fubUserId) continue

        // It's an entity
        if (selectedFolder.isEntity) {
          const entityInfo = {
            entity_id: selectedFolder.id,
            fub_user_id: newAdvisor,
          }

          const { getError } = await CatalogApi.editBackOfficeAdvisorEntity(
            entityInfo
          )
          if (getError()) throw getError()
        } else {
          // It's a request
          const entityInfo = {
            request_id: selectedFolder.requestId,
            fub_user_id: newAdvisor,
          }

          const { getError } = await CatalogApi.editBackOfficeAdvisorRequest(
            entityInfo
          )
          if (getError()) throw getError()
        }
      }
      addAlert('success', 'Les dossiers ont bien été réaffectés.')
      await getFolders()
      setlistToRealloc([])
    } catch (error) {
      console.error(error)
      addAlert('error', error?.toString())
    }
  }

  const advisorsFormattedList = advisors
    .filter((advisor) => advisor.status === BACKOFFICE_USER_STATUS.ACTIVE)
    .map((advisor) => ({
      value: advisor.user_id,
      label:
        advisor?.description?.first_name +
        ' ' +
        advisor?.description?.last_name,
    }))

  const getAdvisorNameById = (id) => {
    return advisors
      .filter((user) => user.user_id === id)
      .map(
        (user) => user.description.first_name + ' ' + user.description.last_name
      )
  }

  const getAdvisorNameByRow = (row) => {
    return getAdvisorNameById(row.original.fubUserId)
  }

  const addToChangeAllocation = (event) => {
    const option = event.target.value
    if (!listToRealloc.includes(option)) {
      setlistToRealloc((list) => [...list, option])
    } else {
      const updatedList = listToRealloc.filter((item) => item !== option)
      setlistToRealloc(updatedList)
    }
  }

  const columns = useMemo(
    () => {
      const baseCols = [
        {
          Header: 'Raison sociale',
          accessor: 'structureName', // accessor is the "key" in the data
          canFilter: false,
          filterable: false,
        },
        {
          //Header: "Nature de l\'établissement",
          accessor: 'gender',
          Filter: SelectColumnFilter,
          //filter: "includesSome",
          filter: equalSomeFn,
          meta: {
            label: "Nature de l'établissement",
          },
          filterable: true,
        },
        {
          Header: 'Ville',
          accessor: 'structureCity',
          canFilter: false,
          filterable: false,
        },
        {
          Header: 'Email',
          accessor: 'contactEmail',
          canFilter: false,
          filterable: false,
        },
        {
          Header: 'Tel',
          accessor: 'structurePhonenumber',
          canFilter: false,
          filterable: false,
        },
        {
          //Header: 'Date de création',
          accessor: 'creationDate',
          Filter: DateColumnFilter,
          filter: dateBetweenFilterFn,
          meta: {
            label: 'Date de création',
          },
          filterable: true,
        },
        {
          //Header: 'Dossier',
          accessor: 'folderType',
          Filter: SelectColumnFilter,
          filter: 'includesSome',
          meta: {
            label: 'Dossier',
          },
          filterable: true,
        },
        {
          //Header: 'Statut',
          accessor: 'status',
          Filter: SelectColumnFilter,
          filter: 'includesSome',
          Cell: GoToDetailCell,
          meta: {
            label: 'Statut',
          },
          filterable: true,
        },
      ]

      // If user logged is a supervisor, 2 columns in additional
      const adminCols = [
        {
          //Header: 'Conseiller',
          accessor: 'advisor',
          Filter: SelectColumnFilter,
          filter: 'includesSome',
          meta: {
            label: 'Conseiller',
          },
          Cell: (
            cell // fubUserId
          ) => (
            <FormElement
              value={cell?.row.original.advisor[0]}
              options={advisorsFormattedList}
              type="select"
              name="newAdvisor"
              onChange={(val) =>
                changeAdvisorAllocation(cell?.row.original, val)
              }
              style={{ width: '150px' }}
            />
          ),
          filterable: true,
        },
        {
          Header: 'Réaffectation',
          accessor: 'isEntity',
          meta: {
            label: 'Conseiller',
          },
          Cell: (cell) => (
            <input
              type="checkbox"
              checked={listToRealloc.includes(cell?.row.original.id)}
              value={cell?.row.original.id}
              onChange={addToChangeAllocation}
            />
          ),
          filterable: true,
        },
      ]

      return [...baseCols, ...(isSupervisor ? adminCols : [])]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSupervisor, advisors, listToRealloc]
  )

  return (
    <BackOfficeLayout>
      <div className="u-flex u-flex-dir-col">
        <h1 className="c-h1 u-bold u-primary">Mes dossiers</h1>
        <button
          type="button"
          className="c-btn c-btn--green c-btn--back-office u-pd-hz-xl u-mg-m u-flex-self-end u-text-transform-none"
          onClick={requestNewFolder}
          disabled={loadingNewFolder}
        >
          {loadingNewFolder ? (
            <Spinner size="24px" color={PRIMARY_COLOR} />
          ) : (
            <PlusIcon color={PRIMARY_COLOR} />
          )}
          <span className="u-mg-left-s">M'affecter un nouveau dossier</span>
        </button>
        <div className="u-flex u-flex-between u-flex-center-vt">
          <p className="u-primary u-bold u-mg-m">
            Retrouver tous mes dossiers :
          </p>
          <button
            className="c-btn c-btn--primary u-pd-hz-l u-mg-m"
            onClick={openModalForExport}
          >
            <DownloadIcon className="u-mg-right-l" color={getColor().white} />
            Exporter mes dossiers
          </button>
        </div>
        <Card className="u-pd-top-xl u-mg-bottom-l">
          {listToRealloc.length > 0 && (
            <FormElement
              label={'Réaffectation multiple'}
              value={'all'}
              options={advisorsFormattedList}
              type="select"
              name="newAdvisorAll"
              onChange={(val) => changeAdvisorAllocationMultiple(val)}
              style={{ width: '150px', float: 'right' }}
            />
          )}
          <Table
            columns={columns}
            data={folders}
            isPaginable={true}
            hasFilters={true}
            isSortable={false}
          />
        </Card>
      </div>
    </BackOfficeLayout>
  )
}

export default Folders
