import dateService from 'services/date'

import {
    formationList as mobilityHelpFormations,
    companyTraining,
    praticalElectricBikeTraining,
    drivingSafelyCompany,
    backToBikeTraining,
    electricBikeTraining,
    trafficCodeTraining
} from 'components/catalog/MobilityHelp/list'

import {
    formationList as technicalHelpFormation,
    bikeOverhaul,
    technicalWorkshops
} from 'components/catalog/Technical/list'

import {
    formationList as adviceAndSupportFormations,
    setupAndTraining,
    trainingAndToolsOfCyclingTeam,
    cyclingStrategy,
    auditPreparation,
    specificationsTraining
} from 'components/catalog/AdviceAndSupport/list'

import { isNotEmpty } from 'utils/validation'

// Prestataires d'équipements
export const REQUEST_EQUIPMENTS_VALUE = 0
export const REQUEST_EQUIPMENTS_BONUS_PERCENTAGE = 40 / 100
export const REQUEST_EQUIPMENT_ID = 'request-equipment'

// Vélo-écoles (prestataires de service mobilité)
export const REQUEST_MOBILITY_VALUE = 1
export const REQUEST_MOBILITY_BONUS_PERCENTAGE = 60 / 100
export const REQUEST_MOBILITY_ID = 'request-mobility'

// Prestataires de services techniques
export const REQUEST_TECHNICAL_VALUE = 2
export const REQUEST_TECHNICAL_BONUS_PERCENTAGE = 40 / 100
export const REQUEST_TECHNICAL_ID = REQUEST_MOBILITY_ID

// Prestataires d'accompagnement et de conseil
export const REQUEST_SUPPORT_VALUE = 3
export const REQUEST_SUPPORT_BONUS_PERCENTAGE = 60 / 100
export const REQUEST_ADVICE_AND_SUPPORT_ID = 'request-advice-and-support'

export const REQUEST_AUDIT_ID = 'request-audit'
export const SCOPING_MEETING_REQUESTS_ID = 'request-scoping-meeting'

// Regularisation
export const REQUEST_REGULARISATION_VALIDATED = 0
export const REQUEST_REGULARISATION_PAID = 1

export const BACK_OFFICE_OPERATIONS = {
    INIT: 'INIT',
    PROVIDER: 'PROVIDER',
    PICTURES: {
        UPLOAD_BY_BENEFICIARY: 'PHOTOS',
        VALIDATE: 'VALPHOTOS',
        ASK_MORE_INFORMATIONS: 'COMPLEMENTPHOTOS',
        DECLINE: 'NONELIGIBLE'
    },
    BID: {
        RECEIVE_QUOTE: 'QUOTE',
        VALIDATE_BID: 'VAL',
        UPLOAD_BY_BENEFICIARY: 'PAYREQUEST',
        ASK_MORE_INFORMATIONS: 'COMPLEMENTREQUEST',
        VALIDATE: 'VALREQUEST',
        DECLINE: 'NONELIGIBLE'
    },
    EMARGEMENTS: {
        UPLOAD_BY_BENEFICIARY: 'EMARGEMENTS'
    },
    REPORT: {
        UPLOAD_BY_BENEFICIARY: 'INTERVENTION'
    }
}

export const DOCUMENTS = {
    bill: 'bill',
    payment_proof: 'payment_proof'
}

// Changement de statut côté BO gestion
export const VALIDATED_STATUS = 1
export const PENDING_STATUS = 2
export const SENDING_FUB_STATUS = 3
export const REFUSED_STATUS = 4
export const TO_VALIDATE_STATUS = 5
export const GO_BACK_ADVISOR_STATUS = 6

export const advisorStatusList = (request) => [
    {
        value: VALIDATED_STATUS,
        label: 'Validé',
    },
    {
        value: PENDING_STATUS,
        label: 'En attente',
        disabled: request.status === ALL_DOCUMENTS_NEED_COMPLEMENT || request.status === PICTURES_NEED_COMPLEMENT
    },
    {
        value: SENDING_FUB_STATUS,
        label: 'Transmis FUB',
    },
]

export const supervisorStatusList = (request) => [
    {
        value: VALIDATED_STATUS,
        label: 'Validé',
    },
    {
        value: GO_BACK_ADVISOR_STATUS,
        label: 'Retour conseiller',
        disabled: !request.request_data?.operation_data?.sending_fub
    },
    {
        value: SENDING_FUB_STATUS,
        label: 'Transmis FUB',
        disabled: request.request_data?.operation_data?.sending_fub
    },
    {
        value: REFUSED_STATUS,
        label: 'Refusé',
    },
]

export const getMobilityHelpFormationByType = (type) => mobilityHelpFormations.find(({ id }) => id === type)

export const calculateMobilityHelpAmount = ({
    type,
    quantity
}) => {
    const formation = mobilityHelpFormations.find(({ id }) => id === type)

    if(!formation) {
        return {
            totalAmount: null,
            amountToRefund: null
        }
    }
    const totalAmount = quantity * formation.unitPrice
    return {
        totalAmount,
        amountToRefund: totalAmount * formation.refundPortion
    }
}

export const getTechnicalHelpFormationByType = (type) => technicalHelpFormation.find(({ id }) => id === type)

export const calculateTechnicalHelpAmount = ({
    type,
    quantity,
}) => {
    const formation = technicalHelpFormation.find(({ id }) => id === type)
    if(!formation) {
        return {
            totalAmount: null,
            amountToRefund: null
        }
    }

    const totalAmount = quantity * formation.unitPrice
    return {
        totalAmount,
        amountToRefund: totalAmount * formation.refundPortion
    }
}

export const calculateAdviceAndSupportAmount = ({ type, beneficiaryEmployeeQuantity, daysCompleted }) => {
    const formation = adviceAndSupportFormations.find(({ id }) => id === type)

    const isCyclingStrategyRequest = type === 'cycling-strategy'
    const isBeneficiaryBigStructure = beneficiaryEmployeeQuantity >= 250

    const ceilingPerTraining = isCyclingStrategyRequest && isBeneficiaryBigStructure ? formation?.maxUnitPrice : formation?.unitPrice
    
    if(!formation) {
        return {
            totalAmount: null,
            amountToRefund: null
        }
    }

    const globalAmount = isNotEmpty(daysCompleted) ? Math.min(850 * Number(daysCompleted), ceilingPerTraining) : 0

    return {
        totalAmount: globalAmount,
        amountToRefund: Math.min(globalAmount * 0.6, ceilingPerTraining)
    }
}

export const getRequestToEdit = (request, operationCode, extraData = {}, amountToRefund = null, totalAmount = null) => ({
    request_id: request.request_id,
    request_data: {
        operation_code: operationCode,
        operation_data: {
            operation_date: dateService.getCurrentDate('yyyy-MM-dd HH:mm:ss'),
            amount_to_refund: amountToRefund,
            total_amount: totalAmount,
            ...extraData
        },
    },
    last_row_hash: request.row_hash
})

export const groupRequestsByGender = (requests) => ({
    [REQUEST_EQUIPMENTS_VALUE]: requests.filter(({ gender }) => gender === REQUEST_EQUIPMENTS_VALUE),
    [REQUEST_MOBILITY_VALUE]: requests.filter(({ gender }) => gender === REQUEST_MOBILITY_VALUE),
    [REQUEST_TECHNICAL_VALUE]: requests.filter(({ gender }) => gender === REQUEST_TECHNICAL_VALUE),
    [REQUEST_SUPPORT_VALUE]: requests.filter(({ gender }) => gender === REQUEST_SUPPORT_VALUE),
})

export const getLastEmargementsOfHistory = (requestHistory) => {
    const lastEmargementsUploaded = requestHistory?.find(stack =>
        stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.EMARGEMENTS.UPLOAD_BY_BENEFICIARY
    )
    return lastEmargementsUploaded?.request_data?.operation_data.documents
}

export const getLastInterventionOfHistory = (requestHistory) => {
    const lastInterventionUploaded = requestHistory?.find(stack =>
        stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.REPORT.UPLOAD_BY_BENEFICIARY
    )
    return lastInterventionUploaded?.request_data?.operation_data.documents[0]
}

export const getLastPhotosOfHistory = (requestHistory) => {
    const stack = requestHistory?.find(stack =>
        stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.PICTURES.UPLOAD_BY_BENEFICIARY
    )
    if(!stack) return null
    return stack.request_data.operation_data.documents
}
// On remonte les events de type PAYREQUEST. Le statut 72 peut avoir ou non des documents. On filtre donc sur l'event où ils sont présents
export const getLastBillUploadedFromHistory = (requestHistory) => {
    const lastDocumentsUploaded = requestHistory?.find(stack =>
        stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.BID.UPLOAD_BY_BENEFICIARY && isNotEmpty(stack.request_data.operation_data?.documents)
    )
    return lastDocumentsUploaded?.request_data?.operation_data.documents?.find(
        document => document.media_gender === DOCUMENTS.bill
    )
}
// On remonte les events de type PAYREQUEST. Le statut 72 peut avoir ou non des documents. On filtre donc sur l'event où ils sont présents
export const getLastProofOfPaymentUploadedFromHistory = (requestHistory) => {
    const lastDocumentsUploaded = requestHistory?.find(stack =>
        stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.BID.UPLOAD_BY_BENEFICIARY  && isNotEmpty(stack.request_data.operation_data?.documents)
    )
    return lastDocumentsUploaded?.request_data?.operation_data.documents?.find(
        document => document.media_gender === DOCUMENTS.payment_proof
    )
}

export const getValidatedBidFromHistory = (requestHistory) => {
    const quoteOperations = requestHistory.filter(stack => stack.request_data?.operation_code === BACK_OFFICE_OPERATIONS.BID.RECEIVE_QUOTE)

    const validatedBidStack = requestHistory?.find(stack => stack.request_data?.operation_code === BACK_OFFICE_OPERATIONS.BID.VALIDATE_BID)
    const providerId = validatedBidStack?.request_data?.operation_data?.provider_id

    if(!quoteOperations.length || !providerId) return null

    const [ validatedBid ] = quoteOperations
        .filter(stack => stack.request_data.operation_data.operation_entity_id === providerId)
        .sort((a, b) => new Date(b.history_date) - new Date(a.history_date))

    return validatedBid
}

export const getLastReportOfHistory = (requestHistory) => {
    const lastReportUploaded = requestHistory?.find(stack =>
        stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.REPORT.UPLOAD_BY_BENEFICIARY
    )
    return lastReportUploaded?.request_data?.operation_data.documents[0]
}

export const getEquipmentsBidInformationsFromHistory = (equipmentHistory) => {
    const stack = equipmentHistory?.find(stack =>
        stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.BID.VALIDATE
    )

    if(!stack) return null

    const {
        amount_to_refund,
        nbOfBikesPlaces,
    } = stack.request_data.operation_data || {}

    return {
        amount_to_refund,
        nbOfBikesPlaces
    }
}

export const getQuantityOfServiceRequestsFromHistory = (serviceRequestHistory) => {

    const lastAskMoreInformation = serviceRequestHistory.find(stack =>
        stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.BID.ASK_MORE_INFORMATIONS
    )

    const requestInit = serviceRequestHistory.find(stack =>
        stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.INIT
    )

    const sessionsQuantity = lastAskMoreInformation?.request_data?.operation_data?.sessionsQuantity ||
        requestInit?.request_data?.operation_data?.request_description?.sessionsQuantity

    const personsQuantity = lastAskMoreInformation?.request_data?.operation_data?.personsQuantity ||
        requestInit?.request_data?.operation_data?.request_description?.personsQuantity

    return {
        personsQuantity,
        sessionsQuantity
    }
}

export const getEquipmentServiceInfosFromHistory = (equipmentHistory) => {
    const stack = equipmentHistory.find(stack =>
        stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.BID.ASK_MORE_INFORMATIONS
    )

    if(!stack) return null

    const {
        amount_to_refund,
        nbOfBikesPlaces,
        request_description
    } = stack.request_data.operation_data || {}

    return {
        amount_to_refund,
        nbOfBikesPlaces,
        request_description
    }
}

export const getQuantityOfTechnicalRequestsFromHistory = (serviceRequestHistory) => {

    const lastAskMoreInformation = serviceRequestHistory.find(stack =>
        stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.BID.ASK_MORE_INFORMATIONS
    )

    const requestInit = serviceRequestHistory.find(stack =>
        stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.INIT
    )

    const bikesQuantity = lastAskMoreInformation?.request_data?.operation_data?.bikesQuantity ||
        requestInit?.request_data?.operation_data?.request_description?.bikesQuantity

    return {
        bikesQuantity,
    }
}

export const getHelpMobilityType = (type) => ({
    isCompanyTraining: type === companyTraining.id,
    isDrivingSafelyCompany: type === drivingSafelyCompany.id,
    isBackToBikeTraining: type === backToBikeTraining.id,
    isElectricBikeTraining: type === electricBikeTraining.id,
    isPraticalElectricBikeTraining: type === praticalElectricBikeTraining.id,
    isTrafficCodeTraning: type === trafficCodeTraining.id,
})

export const getTrainingInformation = (type) => ({
    [companyTraining.id]: companyTraining,
    [drivingSafelyCompany.id]: drivingSafelyCompany,
    [backToBikeTraining.id]: backToBikeTraining,
    [electricBikeTraining.id]: electricBikeTraining,
    [praticalElectricBikeTraining.id]: praticalElectricBikeTraining,
    [trafficCodeTraining.id]: trafficCodeTraining,
})[type] || null

export const getTechnicalInformations = (type) => ({
    [bikeOverhaul.id]: bikeOverhaul,
    [technicalWorkshops.id]: technicalWorkshops
})[type] || null

export const getAdviceAndSupportInformations = (type) => ({
    [setupAndTraining.id]: setupAndTraining,
    [trainingAndToolsOfCyclingTeam.id]: trainingAndToolsOfCyclingTeam,
    [cyclingStrategy.id]: cyclingStrategy,
    [auditPreparation.id]: auditPreparation,
    [specificationsTraining.id]: specificationsTraining
})[type] || null

export const getInformationsByType = (type) => {
    if(getTrainingInformation(type)) return getTrainingInformation(type)
    if(getTechnicalInformations(type)) return getTechnicalInformations(type)
    if(getAdviceAndSupportInformations(type)) return getAdviceAndSupportInformations(type)
    return type
}


export const isEquipmentFolder = (requestFubId) => requestFubId?.startsWith('EQU')
export const isMobilityFolder = (requestFubId) => requestFubId?.startsWith('MOB')
export const isAdviceAndSupportFolder = (requestFubId) => requestFubId?.startsWith('AEC')
export const isTechnicalFolder = (requestFubId) => requestFubId?.startsWith('TEC')

export const isServiceFolder = (requestFubId) =>
    isAdviceAndSupportFolder(requestFubId) || isMobilityFolder(requestFubId) || isTechnicalFolder(requestFubId)

export const getFolderTypeLabel = (value) => {
    if(isEquipmentFolder(value)) return 'Equipement'

    if(isMobilityFolder(value)) return 'Services Éducatifs mobilité'

    if(isAdviceAndSupportFolder(value)) return 'Conseil et accompagnement'

    if(isTechnicalFolder(value)) return 'Services techniques'

    return null
}

export const isWaitingForQuote = (status) => status === CHOOSEN_PROVIDER
export const isReceivedQuote = (status) => status === PROVIDED_BID
export const isValidatedBid = (status) => status === VALIDATED_BID
export const isPicturesUploaded = (status) => [PICTURES_UPLOADED, PICTURES_UPLOADED_BACK].includes(status)
export const isNeedComplementPictures = (status) => status === PICTURES_NEED_COMPLEMENT
export const isValidatedPictures = (status) => status === PICTURES_VALIDATED
export const isSignOffSheetsUploaded = (status) => status === SIGN_OFF_SHEETS_UPLOADED
export const isSignOffReportUploaded = (status) => status === SIGN_OFF_REPORT_UPLOADED
export const isNeedComplementAfterSignOffSheetsUploaded = (status) => status === NEED_COMPLEMENT_AFTER_SIGN_OFF_SHEETS_UPLOADED
export const isNeedComplementAfterSignOffReportUploaded = (status) => status === NEED_COMPLEMENT_AFTER_SIGN_OFF_REPORT_UPLOADED
export const isNeedComplementDocumentsBid = (status) => status === ALL_DOCUMENTS_NEED_COMPLEMENT
export const isNotNeedComplementDocumentsBid = (status) => !isNeedComplementDocumentsBid(status)
export const isDocumentsBidUploaded = (status) => [ALL_DOCUMENTS_UPLOADED, ALL_DOCUMENTS_UPLOADED_BACK].includes(status)
export const isValidated = (status) => status === REQUEST_VALIDATED || status === FOLDER_VALIDATED
export const isCancelled = (status) => status === CANCEL
export const isRefused = (status) => status === REQUEST_REFUSED
export const isPaid = (status) => status === FOLDER_PAID
export const isEngaged = (status) => {
    const engagedAndToValidateStatus = [
        CHOOSEN_PROVIDER, // 1
        VALIDATED_BID, // 3
        PICTURES_UPLOADED, // 4
        PICTURES_VALIDATED, // 5
        SIGN_OFF_SHEETS_UPLOADED, // 6
        SIGN_OFF_REPORT_UPLOADED, // 6
        ALL_DOCUMENTS_UPLOADED, // 7
        PICTURES_NEED_COMPLEMENT, // 41
        PICTURES_UPLOADED_BACK, // 42
        NEED_COMPLEMENT_AFTER_SIGN_OFF_SHEETS_UPLOADED, // 62
        NEED_COMPLEMENT_AFTER_SIGN_OFF_REPORT_UPLOADED, // 62
        ALL_DOCUMENTS_NEED_COMPLEMENT, // 71
        ALL_DOCUMENTS_UPLOADED_BACK // 72
    ]
    return engagedAndToValidateStatus.includes(status)
}

const getPositionStatus = (status) =>
    requestStatusList.findIndex(statusValue => statusValue === status)

export const isBetweenStatus = ({ status, start, end }) => {
    const statusPosition = getPositionStatus(status)
    const startPosition = getPositionStatus(start)
    const endPosition = getPositionStatus(end)

    if(!start?.toString()) return statusPosition <= endPosition
    if(!end?.toString()) return statusPosition >= startPosition

    return statusPosition >= startPosition && statusPosition <= endPosition
}

export const INIT = 0
export const CHOOSEN_PROVIDER = 1
export const PROVIDED_BID = 2
export const VALIDATED_BID = 3
export const PICTURES_UPLOADED = 4
export const PICTURES_VALIDATED = 5
export const SIGN_OFF_SHEETS_UPLOADED = 6
export const SIGN_OFF_REPORT_UPLOADED = 6
export const ALL_DOCUMENTS_UPLOADED = 7
export const REQUEST_VALIDATED = 8
export const FOLDER_VALIDATED = 8
export const CANCEL = 9
export const REQUEST_REFUSED = 10
export const FOLDER_PAID = 12
export const PICTURES_NEED_COMPLEMENT = 41
export const PICTURES_UPLOADED_BACK = 42
export const NEED_COMPLEMENT_AFTER_SIGN_OFF_SHEETS_UPLOADED = 62
export const NEED_COMPLEMENT_AFTER_SIGN_OFF_REPORT_UPLOADED = 62
export const ALL_DOCUMENTS_NEED_COMPLEMENT = 71
export const ALL_DOCUMENTS_UPLOADED_BACK = 72

export const requestStatusList = [
    // in a front perspective, 0 (init) and 1 (choosen provider) is the same step
    INIT, // prestataires choisis
    // Bid
    PROVIDED_BID, // devis ajouté par le prestataire
    VALIDATED_BID, // devis validé par le bénéficiaire
    // Pictures (equipments)
    PICTURES_UPLOADED, // photos ajoutées par le bénéficiaire
    PICTURES_NEED_COMPLEMENT, // compléments nécessaires sur les photos
    PICTURES_UPLOADED_BACK, // photos complétées par le bénéficiaire
    PICTURES_VALIDATED, // photos validées
    // Documents
    SIGN_OFF_SHEETS_UPLOADED, // le bénéficiaire a ajouté des feuilles d'émargement (mobilité)
    ALL_DOCUMENTS_UPLOADED, // le bénéficiaire a complété l'ensemble des pièces demandées et soumet sa demande pour demander le versement
    NEED_COMPLEMENT_AFTER_SIGN_OFF_SHEETS_UPLOADED, // compléments nécessaires sur les feuilles d'émargements ou rapport d'intervention
    ALL_DOCUMENTS_NEED_COMPLEMENT, // compléments nécessaires sur les documents (hors photos)
    ALL_DOCUMENTS_UPLOADED_BACK, // documents (hors photos) complétés par le bénéficiaire
    // Rapport
    SIGN_OFF_REPORT_UPLOADED, // le bénéficiaire a ajouté le rapport d'intervention
    // Success / Refusal
    REQUEST_VALIDATED, // Demande validée (en attente de remboursement)
    CANCEL, // abandon', id: 'cancel' },
    REQUEST_REFUSED, // refus', id: 'request-refused' },
    FOLDER_PAID,
]

export const requestList = {
    equipmentFolder: 'equipment',
    mobilityFolder: 'mobility',
    adviceAndSupportFolder: 'advice-and-support',
    technicalFolder: 'technical',
}

export const getRequestStatusFromBackOffice = (status, sendingFub = false) => {
    if(sendingFub) return 'transmis FUB'

    switch (status) {
        case ALL_DOCUMENTS_UPLOADED:
        case ALL_DOCUMENTS_UPLOADED_BACK:
            return 'à valider'
        case FOLDER_VALIDATED:
        case REQUEST_VALIDATED:
            return 'validé'
        case ALL_DOCUMENTS_NEED_COMPLEMENT:
        case PICTURES_NEED_COMPLEMENT:
            return 'en attente'
        case REQUEST_REFUSED:
            return 'refusé'
        default :
            return 'à valider'
    }
}

export const getLabelDateFromRequestStatus = (status) => {
    if(isWaitingForQuote(status))
        return 'Demande envoyée'
    if(isReceivedQuote(status))
        return 'Devis reçu(s)'
    if(isValidatedBid(status))
        return 'Devis validé'
    if(isPicturesUploaded(status) || isNeedComplementPictures(status))
        return 'Photos envoyées'
    if(isValidatedPictures(status))
        return 'Photos validées'
    if(isDocumentsBidUploaded(status) || isNeedComplementDocumentsBid(status))
        return 'Facture envoyée'
    if(isValidated(status))
        return 'Facture validée'
    if(isCancelled(status))
        return 'Projet annulé'
    if(isRefused(status))
        return 'Projet refusé'
    if(isPaid(status))
        return 'Prime versée'
    return ''
}

export const getFeedbackToBackOfficeUser = (status) => {
    if(isValidated(status))
        return 'Validé.'
    if(isValidatedPictures(status))
        return 'Conformité des travaux validée, en attente des factures.'
    if(isNeedComplementDocumentsBid(status) || isNeedComplementPictures(status))
        return 'En cours, demande d\'informations complémentaires envoyée.'
    return ''
}

export const getFeedbackToEquipmentBeneficiary = (status, adminView = false) => {
    if(isWaitingForQuote(status))
        return 'En attente de devis'
    if(isReceivedQuote(status))
        return 'Devis reçu(s) en attente de validation'
    if(isValidatedBid(status))
        return 'Devis validé en attente de photos'
    if(isPicturesUploaded(status))
        return 'Photos envoyées en attente de validation'
    if(isNeedComplementDocumentsBid(status))
        return 'Facture non conforme'
    if(isNeedComplementPictures(status))
        return 'Photos non conformes'
    if(isValidatedPictures(status))
        return 'Photos validées en attente de la facture'
    if(isDocumentsBidUploaded(status))
        return 'Facture envoyée en attente de validation'
    if(isValidated(status))
        return 'Facture validée'
    if(isCancelled(status))
        return adminView ? 'Projet annulé' : 'Demande annulée'
    if(isRefused(status))
        return 'Projet refusé'
    if(isPaid(status))
        return 'Prime versée'
    return ''
}

export const getFeedbackToTrainingBeneficiary = (status, adminView = false) => {
    if(isWaitingForQuote(status))
        return 'En attente de feuilles d\'émargement'
    if(isSignOffSheetsUploaded(status))
        return "Feuille(s) d'émargement envoyée(s)"
    if(isNeedComplementDocumentsBid(status))
        return 'Informations complémentaires attendues (feuille(s) d\'émargement)'
    if(isNeedComplementAfterSignOffSheetsUploaded(status))
        return 'Informations complémentaires attendues (facture acquittée et/ou preuve de paiement)'
    if(isDocumentsBidUploaded(status))
        return 'Facture et documents envoyés en attente de validation'
    if(isValidated(status))
        return adminView ? 'Demande de prestation validée' : 'Demande validée'
    if(isCancelled(status))
        return 'Projet annulé'
    if(isRefused(status))
        return 'Projet refusé'
    if(isPaid(status))
        return 'Demande payée'
    return ''
}

export const getFeedbackToReportBeneficiary = (status, adminView = false) => {
    if(isWaitingForQuote(status))
        return 'En attente de la synthèse  d’intervention'
    if(isSignOffReportUploaded(status))
        return 'Rapport d\'intervention envoyé'
    if(isNeedComplementDocumentsBid(status))
        return 'Informations complémentaires attendues (rapport d\'intervention)'
    if(isNeedComplementAfterSignOffSheetsUploaded(status))
        return 'Informations complémentaires attendues (facture acquittée et/ou preuve de paiement)'
    if(isDocumentsBidUploaded(status))
        return 'Rapport envoyé en attente de validation'
    if(isValidated(status))
        return adminView ? 'Demande de prestation validée' : 'Demande validée'
    if(isCancelled(status))
        return 'Projet annulé'
    if(isRefused(status))
        return 'Projet refusé'
    if(isPaid(status))
        return 'Demande payée'
    return ''
}

// status request
// 4  - (EQU) le bénéficiaire a soumis les photos
// 41 - (EQU) le backend a demandé des compléments sur les photos (le bénéficiaire peut recharger les photos)
// 42 - (EQU) le bénéficiaire a resoumis ses photos de travaux
// 5  - (EQU) le backend a validé les photos
// 6  - (MOB) le bénéficiaire a uploader les feuilles d'émargements
// 7  - (EQU et MOB) le bénéficiaire a demandé le remboursement (factures déposées)
// 71 - (EQU et MOB) le backend a demandé des compléments sur les factures
// 72 - (EQU et MOB) le bénéficiaire a resoumis ses factures
// 8  - (EQU et MOB) le backend a validé la demande
// 9  - (EQU et MOB) dossier non éligible
// 10 - (EQU et MOB) le backend a validé le dossier
// 12 - (EQU et MOB) le backend a payé le dossier
export const getBackOfficeListStatus = (status, value, sendingFub = false) => {
    if(sendingFub) return 'Transmis FUB'

    switch (status) {
        case CHOOSEN_PROVIDER:
        case VALIDATED_BID:
            return 'Engagé'
        case PICTURES_UPLOADED:
        case PICTURES_UPLOADED_BACK:
            return 'À valider - photos'
        case PICTURES_NEED_COMPLEMENT:
            return 'En attente - photos'
        case PICTURES_VALIDATED:
            return 'En attente des factures'
        case SIGN_OFF_SHEETS_UPLOADED:
        case SIGN_OFF_REPORT_UPLOADED:
        case ALL_DOCUMENTS_NEED_COMPLEMENT:
        case NEED_COMPLEMENT_AFTER_SIGN_OFF_REPORT_UPLOADED:
        case NEED_COMPLEMENT_AFTER_SIGN_OFF_SHEETS_UPLOADED:
            return isEquipmentFolder(value) ? 'En attente - factures' : 'En attente'
        case ALL_DOCUMENTS_UPLOADED:
        case ALL_DOCUMENTS_UPLOADED_BACK:
            return isEquipmentFolder(value) ? 'À valider - factures' : 'À valider'
        case FOLDER_VALIDATED:
        case REQUEST_VALIDATED:
            return 'Validé'
        case REQUEST_REFUSED:
            return 'Refusé'
        case FOLDER_PAID:
            return 'Payé'
        default:
            return "Une erreur s'est produite lors de l'affectation d'un dossier"
    }
}