import React from 'react'
import { shape, number, string, oneOfType } from 'prop-types'

import { DEFAULT_SIZE } from 'utils/icons'

const ChevronDownIcon = ({ size = { width: DEFAULT_SIZE, height: DEFAULT_SIZE }, color }) => {
	return (
        <svg style={size} fill={color} viewBox="0 0 14 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="chevron-down" transform="translate(-2.000000, -6.000000)" fill="#6A6E73">
                    <path d="M2.46899996,6.96899998 C2.60970636,6.82793743 2.80075907,6.7486593 2.99999996,6.7486593 C3.19924085,6.7486593 3.39029357,6.82793743 3.53099996,6.96899998 L12,15.4395 L20.469,6.96899998 C20.6587081,6.77929195 20.9352135,6.70520253 21.1943595,6.77464049 C21.4535055,6.84407845 21.6559216,7.0464945 21.7253595,7.30564049 C21.7947975,7.56478648 21.7207081,7.84129195 21.531,8.03099998 L12.531,17.031 C12.3902936,17.1720626 12.1992409,17.2513407 12,17.2513407 C11.8007591,17.2513407 11.6097064,17.1720626 11.469,17.031 L2.46899996,8.03099998 C2.32793741,7.89029359 2.24865928,7.69924087 2.24865928,7.49999998 C2.24865928,7.30075909 2.32793741,7.10970637 2.46899996,6.96899998 Z"></path>
                </g>
            </g>
        </svg>
	)
}


ChevronDownIcon.propTypes = {
    size: shape({
        width: oneOfType([string, number]),
        height: oneOfType([string, number])
    }),
    color: string
}


export default ChevronDownIcon

