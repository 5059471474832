import React, { useEffect, useState } from 'react'
import { objectOf, shape, string } from 'prop-types'

import * as CatalogApi from 'services/api/CatalogApi'

import { useAlert } from 'hooks/useAlert'

import { 
    INIT, 
    REQUEST_SUPPORT_VALUE, 
    REQUEST_EQUIPMENTS_VALUE, 
    REQUEST_MOBILITY_VALUE, 
    REQUEST_TECHNICAL_VALUE,
    ALL_DOCUMENTS_UPLOADED,
    isBetweenStatus,
    isCancelled,
    getLastBillUploadedFromHistory,
    getLastProofOfPaymentUploadedFromHistory,
    FOLDER_PAID,
} from 'utils/domain/request'

import Card from 'components/platform/Card'

import EquipmentRequest from './Equipment'
import MobilityRequest from './Mobility'
import TechnicalRequest from './Technical'
import AdviceAndSupportRequest from './AdviceAndSupport'

const RequestsOverview = ({ beneficiary = {} }) => {
    const { addAlert } = useAlert()
    const [requestsToDisplay, setRequestsToDisplay] = useState()
    
    const equipmentRequests = requestsToDisplay ? requestsToDisplay[REQUEST_EQUIPMENTS_VALUE] : []
    const mobilityRequests = requestsToDisplay ? requestsToDisplay[REQUEST_MOBILITY_VALUE] : []
    const technicalRequests = requestsToDisplay ? requestsToDisplay[REQUEST_TECHNICAL_VALUE] : []
    const adviceAndSupportRequests = requestsToDisplay ? requestsToDisplay[REQUEST_SUPPORT_VALUE] : []
    
    const { requestsGroupedByGender = {} } = beneficiary
    
    useEffect(() => {
        const requestsIds = Object.values(requestsGroupedByGender)?.flat().map(({ request_id }) => request_id)
        if(requestsIds.length) {
            getHistories(requestsIds)
        }
    }, [requestsGroupedByGender])

    const getHistories = async (requestIds = []) => {
        try {
            const historiesResponses = await Promise.all(requestIds.map(id => CatalogApi.getBackOfficeRequestHistory(id)))
            
            if(historiesResponses.some(({ res }) => res.error)) {
                throw 'Une erreur est survenue lors de la récupération des demandes de prestation.'
            }

            const histories = historiesResponses.map(({ res }) => res)
            
            const requestsWithHistoryMap = Object.entries(requestsGroupedByGender)
                .map(([ gender, requests ]) => {
                    const requestsWithHistory = requests.filter(request => request.status !== INIT)
                    .map(request => ({
                        ...request,
                        history: histories.find(([ firstStack ]) => firstStack?.request_id === request.request_id),
                    }))

                    return [ gender, requestsWithHistory ]
                })

            const requestWithBidInformationsMap = requestsWithHistoryMap
                .map(([ gender, requests ]) => {
                    const requestWithBidInformations = requests.map(request => {
                        const isRequestCancelled = isCancelled(request?.status)
                        const isDisplayEquipmentBid = isBetweenStatus({ status: request?.status, start: ALL_DOCUMENTS_UPLOADED, end: FOLDER_PAID }) && !isRequestCancelled
                        const bill = getLastBillUploadedFromHistory(request.history)
                        const proofOfPayment = getLastProofOfPaymentUploadedFromHistory(request.history)
                        return {
                            ...request,
                            history: histories.find(([ firstStack ]) => firstStack?.request_id === request.request_id),
                            bidDocuments: {
                                isUploaded: isDisplayEquipmentBid,
                                bill,
                                proofOfPayment
                            }
                        }
                    })
                    return [ gender, requestWithBidInformations ]
                })
            
            setRequestsToDisplay(Object.fromEntries(requestWithBidInformationsMap))
        } catch(error) {
            addAlert('error', error?.toString())
        }
    }

    return (
        <>
            {Boolean(technicalRequests.length) && (
                <div className="l-grid u-mg-left-s u-mg-right-s">
                    <div className="l-col-12">
                        <Card title="Prestations services techniques">
                            {technicalRequests.map(request => 
                                <TechnicalRequest 
                                    key={request.request_fub_id} 
                                    beneficiary={beneficiary} 
                                    request={request} 
                                />
                            )}
                        </Card>
                    </div>
                </div>
            )}

            {Boolean(mobilityRequests.length) && (
                <div className="l-grid u-mg-left-s u-mg-right-s">
                    <div className="l-col-12">
                        <Card title="Prestations Services éducatifs mobilité">
                            {mobilityRequests.map(request => 
                                <MobilityRequest 
                                    key={request.request_fub_id} 
                                    beneficiary={beneficiary} 
                                    request={request} 
                                />
                            )}
                        </Card>
                    </div>
                </div>
            )}

            {Boolean(equipmentRequests.length) && (
                <div className="l-grid u-mg-left-s u-mg-right-s">
                    <div className="l-col-12">
                        <Card title="Prestations d'équipements">
                            {equipmentRequests.map(request => 
                                <EquipmentRequest key={request.request_fub_id} request={request} />
                            )}
                        </Card>
                    </div>
                </div>
            )}

            {Boolean(adviceAndSupportRequests.length) && (
                <div className="l-grid u-mg-left-s u-mg-right-s">
                    <div className="l-col-12">
                        <Card title="Prestations Conseil et accompagnement">
                            {adviceAndSupportRequests.map(request => 
                                <AdviceAndSupportRequest 
                                    key={request.request_fub_id} 
                                    beneficiary={beneficiary} 
                                    request={request} 
                                />
                            )}
                        </Card>
                    </div>
                </div>
            )}
        </>
    )
}

RequestsOverview.propTypes = {
    beneficiary: shape({
        requestsGroupedByGender: objectOf(
            shape({
                request_id: string.isRequired,
                status: string.isRequired
            })
        )
    })
}

export default RequestsOverview