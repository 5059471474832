import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useHistory } from 'react-router-dom'

import { useBasket } from 'hooks/useBasket'
import { useAuth } from 'hooks/useAuth'
import { useAlert } from 'hooks/useAlert'
import { useModal } from 'hooks/useModal'
import { servicesEnum } from 'utils/domain/provider'
import { REQUEST_MOBILITY_VALUE } from 'utils/domain/request'
import { scrollToTop } from 'utils/scrollTo'
import { privateRoutes } from 'router/routes'
import { addProvidersRequest, createCatalogRequest, getProviders, providerFilters, remapProviders } from '../catalogUtils'

import FormElement from 'components/shared/FormElement'
import ProvidersTable from 'components/platform/ProvidersTable'
import ModalConfirmation from 'components/shared/ModalConfirmation'
import BidModalConfirmationBody from '../BidModalConfirmationBody'
import CatalogRoutes from '../CatalogRoutes'
import { Markdown } from 'components/shared/Markdown'

const defaultFormEvents = {
    ready: true
}

const ServiceMobilityHelpBid = () => {
    const { firstItem: currentItem, currentBasket, setBasket } = useBasket()
    const { user, userHasCoordinates } = useAuth()
    const history = useHistory()

    let defaultFormFields = React.useMemo(() => ({
        basket: [currentItem],
        schoolsSelected: [],
        geoquery: '',
        mapRadius: 500, //temp
        comment: '',
        id: null,
    }), [currentItem])

    // Manage dynamic state property
    if (currentItem) {
        const quantitySelection = currentItem?.quantitySelection
        if (quantitySelection) {
            defaultFormFields = {
                ...defaultFormFields,
                quantity: {
                    ...quantitySelection,
                    value: 0
                }
            }
        }
    } else {
        history.push(`${privateRoutes.catalog.path}/${CatalogRoutes[1].url}`)
    }

    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [showGlobalError, setShowGlobalError] = React.useState(false)

    const [isSliding, setIsSliding] = useState(false)

    const { addAlert } = useAlert()
    const { showModal, hideModal } = useModal()

    React.useEffect(() => {
        // Generate an uuid for the bid each time the view refresh
        // prevent submitting multiple times the same bid
        setFormFields((s) => ({
            ...s,
            id: uuidv4()
        }))
    }, [])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
            ? target.checked
            : target.type === 'file'
                ? target
                : target.value
        const name = target.name

        setFormFields(formerFormFields => ({ ...formerFormFields, [name]: value }))
    }

    const handleSelectChange = (event) => {
        setFormFields(formFields => ({
            ...formFields,
            quantity: {
                ...formFields?.quantity,
                value: event.target.value
            }
        }))
    }

    const handleGeoFilterChange = (event) => {
        setIsSliding(true)
        handleInputChange(event)
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((errors) => {
            const cleanErrors = [...errors.filter((e) => e.origin !== name)]
            return [ ...cleanErrors, ...errs ]
        })
    }, [])

    const handleSuccess = () => {
        hideModal()
        addAlert('success', 'Votre demande de prestation a bien été envoyée !')
        setBasket(null)
        history.push({ 
            pathname: privateRoutes.success.path,
            state: { urlRedirect: privateRoutes.monitoringActivities.benefits.mobility.path }
        })
    }

    const handleError = (error) => {
        addAlert('error', error?.toString())
        setShowGlobalError(true)
        setFormEvents((formEvents) => ({ ...formEvents, ready: true }))
        scrollToTop()
    }

    const handleMobilityBidRequest = () => createCatalogRequest({
        user,
        requestId: formFields?.id,
        requestGender: REQUEST_MOBILITY_VALUE,
        requestDescription: {
            type: currentItem?.id,
            comment: formFields?.comment.replace(/"/g, "'"),
            [formFields?.quantity?.property]: formFields?.quantity?.value,
            amount: currentItem?.unitPrice * Number(formFields?.quantity?.value)
        }
    })

    const handleSelectedProviders = (createRequest) => addProvidersRequest({
        request: createRequest,
        providersList: formFields?.schoolsSelected
    })

    const handleConfirmation = async () => {
        setShowLocalErrors(false)
        // prevent form to be submitted again
        setFormEvents((currentFormEvents) => ({ ...currentFormEvents, ready: false }))

        try {
            const { res: createRequest, getError: getMobilityBidRequestError } = await handleMobilityBidRequest()
            if(getMobilityBidRequestError()) throw getMobilityBidRequestError()

            const { getError: getSelectedProvidorsError } = await handleSelectedProviders(createRequest)
            if(getSelectedProvidorsError()) throw getSelectedProvidorsError()

            handleSuccess()
        } catch(error) {
            handleError(error)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        const hasSchoolSelected = formFields?.schoolsSelected?.length > 0
        if (!hasSchoolSelected) addAlert('error', 'Veuillez sélectionner un prestataire.')
        if (hasSchoolSelected && !formFields?.quantity?.value) addAlert('error', `Veuillez sélectionner un ${formFields?.quantity?.label?.toLowerCase()}`)
        
        // Validate inputs
        const formReady = !(errors.length > 0) && hasSchoolSelected && formFields?.quantity?.value

        if (!formReady) {
            setShowLocalErrors(true)
        } else {
            showModal(
                ModalConfirmation,
                {
                    title: 'Confirmer la demande de prestation',
                    body: BidModalConfirmationBody,
                    validate: handleConfirmation
                }
            )
        }
    }

    // Handle schools
    const [schools, setSchools] = React.useState([])
    const [filteredSchools, setFilteredSchools] = React.useState([])
    const [onlyShowSelectedSchools, setOnlyShowSelectedSchools] = React.useState(false)

    const applyFilters = () => {
        const { service: serviceFilter } = providerFilters(currentBasket)
        
        // entity_data when getEntities, no entity_data when getEntitiesByLocation
        const filteredSchools = schools
            .filter(school => serviceFilter(school))

        setFilteredSchools(filteredSchools)
    }

    const handleGetProviders = async () => {
        const beneficiaryLatitude = user?.entity_data?.latitude
        const beneficiaryLongitude = user?.entity_data?.longitude
        const { res: loadSchools, error: loadSchoolsError } = await getProviders({
            services: servicesEnum.mobility,
            remapProviders: (res) => remapProviders(res),
            latitude: beneficiaryLatitude,
            longitude: beneficiaryLongitude,
            mapRadius: formFields?.mapRadius
        })

        if (loadSchoolsError) addAlert('error', loadSchoolsError?.toString())
        else setSchools(loadSchools)

        setIsSliding(false)
    }

    React.useEffect(() => {
        handleGetProviders()
    }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if(formFields?.schoolsSelected.length < 1) {
            setOnlyShowSelectedSchools(false)
        }
    }, [formFields?.schoolsSelected])

    React.useEffect(() => {
        applyFilters()
    }, [schools]) // eslint-disable-line react-hooks/exhaustive-deps

    const sliderTooltip = userHasCoordinates ? '' : 'Coordonnées incorrectes, veuillez vérifier votre adresse dans la page "MON COMPTE"'

    return (
        <>
            <h1 className="c-h1 u-secondary u-center u-mg-vt-xl">{currentItem?.label}</h1>

            {currentItem?.content &&
                <section>
                    <Markdown>{currentItem.content}</Markdown>
                </section>
            }

            <hr className="u-mg-vt-xl" />
            
            <form>

                {showGlobalError &&
                    <p className="u-fs-xs u-danger u-center u-mg-bottom-m">Un problème est survenu lors de la demande de prestation.</p>
                }

                <section>
                    <h2 className="c-h2 u-center u-primary u-bold u-mg-bottom-xl">Formation souhaitée</h2>
                    <div className="u-flex u-flex-dir-col u-flex-dir-row@main u-flex-between">
                        <div>
                            {formFields?.basket && formFields?.basket.length > 0 &&
                                <>
                                    <h3 className="c-h3 u-primary u-mg-bottom-m">{currentItem?.label}</h3>
                                    <p className="u-mg-bottom-m">{currentItem?.price} ({currentItem?.target})</p>
                                </>
                            }
                            {(!formFields?.basket || formFields?.basket?.length === 0) &&
                                <p>Vous n'avez sélectionné aucune formation.</p>
                            }
                        </div>

                        <div>
                            {formFields?.quantity &&
                                <>
                                    <h3 className="c-h3 u-primary u-mg-bottom-m">Quantité souhaitée</h3>
                                    <FormElement
                                        value={formFields?.quantity?.value}
                                        options={formFields?.quantity?.options}
                                        name={formFields?.quantity?.property}
                                        type="select"
                                        label={formFields?.quantity?.label}
                                        className="u-pd-right-xl@main u-mg-bottom-m"
                                        style={{ minWidth: '300px' }}
                                        required
                                        showErrors={showLocalErrors}
                                        onValidate={handleValidation}
                                        onChange={handleSelectChange}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </section>

                <hr className="u-mg-vt-xl" />

                <section>
                    <h2 className="c-h2 u-center u-primary u-bold u-mg-bottom-l">Prestataire</h2>
                    <p className="u-center u-mg-hz-auto">
                        Vous ne pouvez choisir qu'une seule vélo école pour cette formation
                    </p>

                    {/* Show selection */}
                    <div className="u-flex">
                        <FormElement
                            value={onlyShowSelectedSchools}
                            name="onlyShowSelectedSchools"
                            type="checkbox"
                            label=""
                            checkboxLabel={
                                <span>
                                    Afficher uniquement la sélection
                            </span>
                            }
                            // disabled={formFields?.schoolsSelected?.length === 0}
                            className={'u-flex-1 u-pd-vt-m'}
                            classNameInput=""
                            classNameOption=""
                            onChange={(e) => setOnlyShowSelectedSchools(e.target?.checked)}
                            disabled={formFields?.schoolsSelected.length < 1}
                        />

                        <div data-tip={sliderTooltip}>
                            <FormElement
                                    disabled={!userHasCoordinates}
                                    value={formFields.mapRadius}
                                    name="mapRadius"
                                    type="slider"
                                    label="Curseur géographique"
                                    step={10}
                                    min={10}
                                    max={500}
                                    unit={'km'}
                                    className="u-flex-0 u-mg-bottom-l"
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleGeoFilterChange}
                                    onAfterChange={handleGetProviders}
                                />
                        </div>
                    </div>


                    <ProvidersTable
                        data={onlyShowSelectedSchools ? formFields?.schoolsSelected : filteredSchools}
                        hasSelectableRows={true}
                        selection={formFields?.schoolsSelected}
                        selectionLength={1}
                        setSelection={(value) => handleInputChange({ target: { name: 'schoolsSelected', value } })}
                        className="u-pd-bottom-l"
                        isLoadingProvider={isSliding}
                        isGeolocation
                    />
                </section>

                <hr className="u-mg-vt-xl" />

                <section>
                    <h2 className="c-h2 u-center u-primary u-bold u-mg-bottom-l">Informations supplémentaires</h2>
                    {/* Comment */}
                    <FormElement
                        value={formFields.comment}
                        name="comment"
                        type="textarea"
                        label="Commentaire"
                        placeholder="Nombres de personnes, niveaux, dates espérées, …"
                        className="u-mg-hz-auto u-mg-vt-xl"
                        style={{ maxWidth: '600px' }}
                        required={false}
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                    />
                </section>
                <div className="u-flex u-flex-center-hz u-mg-top-l u-mg-bottom-m">
                    {formEvents.ready &&
                        <button
                            type="submit"
                            className="c-btn c-btn--primary u-pd-hz-l"
                            onClick={handleSubmit}
                        >
                            Envoyer ma demande de prestation
                        </button>
                    }
                    {!formEvents.ready &&
                        <div className="c-spinner"></div>
                    }
                </div>
            </form>
        </>
    )
}

export default ServiceMobilityHelpBid
