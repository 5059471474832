import React from 'react'
import { privateRoutes } from 'router/routes'
import BackOfficeLayout from 'layouts/back-office'
import UsersInformations from 'components/back-office/UsersInformations'
import { equipmentProvider } from 'utils/domain/provider'

const EquipmentsProviders = () => {

  const textPage = {
    title: 'Fournisseurs',
    action: 'Créer un fournisseur'
  }

  const providersCriteria = {
    genders: [equipmentProvider.value],
    services: [equipmentProvider.serviceId]
  }

  const detailPageRoute = privateRoutes.backOffice.providerDetailAdministration
  const creationPageRoute = privateRoutes.backOffice.providerCreationAdministration

  return (
    <BackOfficeLayout>
      <UsersInformations 
        textPage={textPage} 
        usersCriteria={providersCriteria} 
        detailPageRoute={detailPageRoute}
        creationPageRoute={creationPageRoute}
      />
    </BackOfficeLayout>
  )
}

export default EquipmentsProviders
