import { beneficiaryList } from 'utils/domain/beneficiary'
import { backOfficeUsers } from 'utils/domain/user'
import { 
    REQUEST_EQUIPMENTS_VALUE, 
    REQUEST_MOBILITY_VALUE, 
    REQUEST_TECHNICAL_VALUE, 
    REQUEST_SUPPORT_VALUE
} from 'utils/domain/request'
import {
    provider,
    providersList
} from './domain/provider'
import allAPE from 'assets/data/nomenclature-dactivites-francaise-naf-rev-2-code-ape.json'

export const users = [
    ...beneficiaryList,
    ...providersList,
    ...backOfficeUsers
]

export const entityGenderList = [
    ...backOfficeUsers,
    ...beneficiaryList,
    provider
]

export const requestGenderList = [
    { value: REQUEST_EQUIPMENTS_VALUE, prestation: 'Equipement', label: "Prestataires d'équipements", id: 'equipments' },
    { value: REQUEST_MOBILITY_VALUE, prestation: 'Formations à la pratique du vélo au quotidien', label: 'Vélo-écoles (prestataires de formations à la pratique du vélo au quotidien)', id: 'mobility' },
    { value: REQUEST_TECHNICAL_VALUE, prestation: 'Prestations de maintenance et de réparations', label: 'Prestataires de maintenance et de réparations', id: 'technical' },
    { value: REQUEST_SUPPORT_VALUE, prestation: 'Services conseil & accompagnement', label: "Prestataires d'accompagnement et de conseil", id: 'support' }
]

// Quote = a reply to a request (e.g. a provider)
export const quoteStatusList = [
    { value: 0, label: 'En attente du téléversement', id: 'choosen-provider' },
    { value: 2, label: 'En cours', id: 'provided-bid' },
    { value: 3, label: 'Devis validé', id: 'validated-bid' },
    { value: 9, label: 'Refusé / Obsolète', id: 'cancel' },
]

export const apeCompanyList = allAPE.map((item) => ({
    label: `${item?.fields?.code_naf} - ${item?.fields?.intitule_naf_40}`,
    value: item?.fields?.code_naf
}))

export const apeAssociationList = apeCompanyList

export const apeAdministrationList = allAPE.filter((item) => {
    return ['8411Z', '8412Z', '8413Z', '8422Z', '8423Z', '8424Z', '8425Z', '8430A', '8430B', '8430C'].includes(item?.fields?.code_naf)
}).map((item) => ({
    label: `${item?.fields?.code_naf} - ${item?.fields?.intitule_naf_40}`,
    value: item?.fields?.code_naf
}))

export const sellerProximity = [
    { value: 'p0', label: 'Peu importe' },
    { value: 'p5', label: 'Moins de 5km' },
    { value: 'p10', label: 'Moins de 10km' },
    { value: 'p30', label: 'Moins de 30km' },
    { value: 'p50', label: 'Moins de 50km' },
    { value: 'pmore', label: 'Plus de 50km' }
]

export const PASSWORD_LENGTH = 9
