import React, { useState, useEffect, useMemo } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { string, shape } from 'prop-types'

import { PRIMARY_COLOR } from 'utils/icons'
import { getLabelBypermission, BACKOFFICE_USER_STATUS, RELATIONSHIP_MANAGER_PERMISSION } from 'utils/domain/user'
import { privateRoutes } from 'router/routes'

import { useAlert } from 'hooks/useAlert'
import * as UserApi from 'services/api/UserApi'
import { getBackOfficeFolders } from 'services/api/CatalogApi'
import BackOfficeLayout from 'layouts/back-office'

import Card from 'components/platform/Card'
import Table from 'components/shared/Table'
import PlusIcon from 'components/svg/Plus'

const GoToDetailCell = ({ cell }) => {
    return (
        <NavLink 
            className="u-green90" 
            to={privateRoutes.backOffice.accreditationDetail.navLink({ userId: cell?.value })}
        >
            Modifier
        </NavLink>
    )
}

GoToDetailCell.propTypes = {
    cell: shape({
        value: string
    })
}

const BackOfficeAccreditations = () => {
    const [ accreditations, setAccreditations ] = useState([])
    const [ folders, setFolders ] = useState([])

    const { addAlert } = useAlert()
    const history = useHistory()

    useEffect(() => {
        try {
            loadFolders()
            loadAccreditations()
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }, [])

    const loadFolders = async () => {
        const { res, getError } = await getBackOfficeFolders()
        if(getError()) throw getError()
        setFolders([ ...res.entities, ...res.requests ])
    }

    const getFoldersByUser = (userId) => {
        return folders.filter(folder => folder.fub_user_id === userId)
    }

    const getNotDeletedUsers = (users) => {
        return users.filter(user => user.status !== BACKOFFICE_USER_STATUS.DELETED)
    }

    const loadAccreditations = async () => {
        const { res, getError } = await UserApi.getBackOfficeUsers()
        if (getError()) throw getError()
        const usersFormated = getNotDeletedUsers(res)

        setAccreditations(usersFormated)
    }

    const createNewAccreditation = () => {
        history.push(privateRoutes.backOffice.accreditationCreation.path)
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Nom',
                accessor: 'description.last_name',
            },
            {
                Header: 'Prénom',
                accessor: 'description.first_name',
            },
            {
                Header: 'Email',
                accessor: 'mail',
            },
            {
                Header: 'Tel',
                accessor: 'description.phone_number',
            },
            {
                Header: 'Habilitation',
                accessor: 'permission',
                Cell: ({ cell }) => getLabelBypermission(cell.value)
            },
            {
                Header: 'Dossier en cours',
                Cell: ({ row }) => {
                    return row?.original?.permission === RELATIONSHIP_MANAGER_PERMISSION ? '-' : getFoldersByUser(row?.original?.user_id).length
                }
            },
            {
                Header: 'Actions',
                accessor: 'user_id',
                Cell: GoToDetailCell,
            },
        ], 
    [folders]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BackOfficeLayout>
            <div className="u-flex u-flex-dir-col">
                <h1 className="c-h1 u-bold u-primary">Gestion des habilitations</h1>
                <button 
                    type="button"
                    className="c-btn c-btn--green c-btn--back-office u-pd-hz-xl u-mg-m u-flex-self-end u-text-transform-none"
                    onClick={createNewAccreditation}
                >
                    <PlusIcon color={PRIMARY_COLOR} />
                    <span className='u-mg-left-s'>Créer un nouvel utilisateur</span>
                </button>
                <Card className="u-pd-top-xl u-mg-bottom-l">
                    <Table columns={columns} data={accreditations} specificSortBy={[ { id: 'creation_date', asc: true } ]} />
                </Card>
            </div>
        </BackOfficeLayout>
    )
}

export default BackOfficeAccreditations