import React from 'react'

import { useAlert } from 'hooks/useAlert'
import { useRequestFolder } from 'pages/back-office/Folders/RequestFolder/EquipmentFolder/context'
import FormElement from 'components/shared/FormElement'
import * as CatalogApi from 'services/api/CatalogApi'

import dateService from 'services/date'
import { formatNumberAndAddEurCurrency } from 'utils/number'
import { isNotEmpty, isNotEmptyNumber } from 'utils/validation'
import { AddEquipment } from './AddEquipment'
import { equipmentList } from 'components/catalog/Equipment/list'

import EditIcon from 'components/svg/Edit'
import TrashIcon from 'components/svg/Trash'
import CheckIcon from 'components/svg/Check'

const defaultFormFields = {
    date: new Date(),
    equipment: '',
    name: '',
    placeNumber: 0,
    quantity: 0,
    price: '',
}

export const ADD_EQUIPMENT_STATUS = 1
export const DELETE_EQUIPMENT_STATUS = 9
export const BIKE_TIES_LABEL = 'Attaches'

export const AdditionalEquipment = () => {
    const { 
        request, 
        requestEquipmentArray, 
        fetchRequestEquipments, 
        setRequestEquipmentArray,
        additionalEquipmentTotalAmount,
        totalEligibleCosts
    } = useRequestFolder()
    const { addAlert } = useAlert()

    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [isLoading, setIsLoading] = React.useState(false)
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [errors, setErrors] = React.useState([])

    const [providerEquipment, setProviderEquipment] = React.useState([])

    const fetchProviderEquipments = async () => {
        const { res: equipments } = await CatalogApi.getProviderEquipments(request?.validatedBid?.request_data.operation_data.operation_entity_id)
        if(isNotEmpty(equipments)) setProviderEquipment(equipments.filter(equipment => equipment.status !== 9)) // On filtre sur les équipements qui ne sont pas supprimés (status 9)
    }

    React.useEffect(() => {
        fetchProviderEquipments()
        fetchRequestEquipments(request.request_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleInputChange = (event) => {
        const target = event.target
        const name = target.name
        setFormFields(s => ({
            ...s,
            [name]: target.value
        }))
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const deleteAdditionalEquipment = async (selectedIndex) => {
        const selectedEquipment = requestEquipmentArray?.find((item, index) => index === selectedIndex)
        try {
            setIsLoading(true)
            const payload = {
                request_id: request.request_id,
                equipments_supply_id: selectedEquipment.supply_id,
                status: DELETE_EQUIPMENT_STATUS
            }
            const { getError } = await CatalogApi.editRequestEquipments(payload)
            if (getError()) throw getError()
            fetchRequestEquipments(request.request_id) // On fetch le tableau pour le mettre à jour
        } catch (error) {
            addAlert('error', error?.toString())
        } finally {
            setIsLoading(false)
        }
    }

    const editAdditionalEquipment = async (selectedEquipment) => {
        const updatedRows = requestEquipmentArray.map((row) => {
            if (row === selectedEquipment) {
                setFormFields(s => ({
                    ...s,
                    placeNumber: selectedEquipment.placeNumber,
                    quantity: selectedEquipment.quantity,
                    price: selectedEquipment.price,
                }))
                return { ...row, editing: true }
            }
            return row
        })
        setRequestEquipmentArray(updatedRows)
    }

    const saveAdditionalEquipment = async (selectedIndex) => {
        const selectedEquipment = requestEquipmentArray?.find((item, index) => index === selectedIndex)

        // Nous devons renvoyer pour chaque équipement si celui-ci est optionnel ou non
        // Nous en aurons besoin lors du calcul de la prime
        const isAdditionalEquipment = Boolean(equipmentList.filter(i => i.optional)?.find(item => {
            return item?.shortLabel === selectedEquipment?.category
        })) ?? false

        try {
            setIsLoading(true)
            const payload = {
                request_id: request.request_id,
                equipments_supply_id: selectedEquipment.supply_id,
                equipments_supply_data: {
                    date: selectedEquipment.date,
                    equipment: selectedEquipment.equipment,
                    category: selectedEquipment.category,
                    name: selectedEquipment.name,
                    quantity: formFields.quantity,
                    ...(isNotEmptyNumber(formFields.placeNumber) ? { placeNumber: formFields.placeNumber } : {}),
                    price: formFields.price,
                    optional: isAdditionalEquipment,
                },
                status: ADD_EQUIPMENT_STATUS
            }
            const { getError } = await CatalogApi.editRequestEquipments(payload)
            if (getError()) throw getError()
            fetchRequestEquipments(request.request_id) // On fetch le tableau pour le mettre à jour
        } catch (error) {
            addAlert('error', error?.toString())
        } finally {
            setIsLoading(false)
        }
    }
    const formElementCommonProps = {
        className: 'u-mg-hz-s',
		showErrors: showLocalErrors,
		onValidate: handleValidation,
		onChange: handleInputChange,
	}

    return (
        <div>

            <AddEquipment additionalEquipmentArray={requestEquipmentArray} providerEquipment={providerEquipment} />
            {isNotEmpty(requestEquipmentArray) && 
            <>
                <form>
                    <table className="c-table c-table--secondary c-table--responsive">
                        <thead>
                            <tr className='c-table__row u-fs-xxs'>
                                <th className={'c-table__cell u-pd-m c-table__cell--3'}>Date de facture</th>
                                <th className={'c-table__cell u-pd-m c-table__cell--4'}>Dénomination</th>
                                <th className={'c-table__cell c-table__cell--3'}>Equipement</th>
                                <th className={'c-table__cell u-pd-m c-table__cell--3'}>Catégorie</th>
                                <th className={'c-table__cell u-pd-m c-table__cell--2 u-center'}>Nb place</th>
                                <th className={'c-table__cell c-table__cell--2 u-center'}>Qté</th>
                                <th className={'c-table__cell u-pd-m c-table__cell--2 u-center'}>PU HT</th>
                                <th className={'c-table__cell u-pd-m c-table__cell--3 u-center'}>Montant HT</th>
                                <th className={'c-table__cell u-pd-m c-table__cell--2'}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestEquipmentArray.map((equipment, index) => {
                                const isBikeTiesEquipmentSeleted = equipment.category === BIKE_TIES_LABEL ?? false
                                const totalPrice = equipment.quantity * equipment.price
                                const isSaveButtonDisabled = !isNotEmpty(formFields.quantity) || !isNotEmpty(formFields.price)
                                return (
                                    <tr key={index} className='c-table__row u-pd-m u-pd-0@main u-fs-xxs'>
                                        <td className="c-table__cell u-pd-m@main c-table__cell--3">{dateService.formatDate(equipment?.date)}</td>
                                        <td className="c-table__cell u-pd-m@main c-table__cell--4">{equipment.name}</td>
                                        <td className="c-table__cell u-pd-m@main c-table__cell--3">{equipment.equipment}</td>
                                        <td className="c-table__cell u-pd-m@main c-table__cell--3">{equipment.category}</td>
                                        <td className="c-table__cell u-pd-m@main c-table__cell--2 u-center">
                                        {equipment.editing && isBikeTiesEquipmentSeleted && <FormElement
                                                {...formElementCommonProps}
                                                value={formFields.placeNumber}
                                                name="placeNumber"
                                                type="number"
                                                className="u-mg-hz-s u-width-80"
                                            />}
                                            {!equipment.editing && equipment.placeNumber}
                                            {!equipment.editing && !isBikeTiesEquipmentSeleted && 'NC'}
                                        </td>
                                        <td className="c-table__cell u-pd-m@main c-table__cell--2 u-center">
                                            {equipment.editing && <FormElement
                                                {...formElementCommonProps}
                                                value={formFields.quantity}
                                                name="quantity"
                                                type="number"
                                                className="u-mg-hz-s u-width-80"
                                            />}
                                            {!equipment.editing && equipment.quantity}
                                        </td>
                                        <td className="c-table__cell u-pd-m@main c-table__cell--2 u-center">
                                            {equipment.editing && <FormElement
                                                {...formElementCommonProps}
                                                value={formFields.price}
                                                name="price"
                                                type="number"
                                                className="u-mg-hz-s u-width-80"
                                            />}
                                            {!equipment.editing && equipment.price}
                                        </td>
                                        <td className="c-table__cell u-pd-m@main c-table__cell--3 u-center">{totalPrice}</td>
                                        <td className="c-table__cell u-pd-s@main c-table__cell--2">
                                            {!equipment.editing && <button type='button' onClick={() => editAdditionalEquipment(equipment)}><EditIcon size={18} /></button>}
                                            {equipment.editing && <button type='button' disabled={isSaveButtonDisabled || isLoading} onClick={() => saveAdditionalEquipment(index)}><CheckIcon size={18} /></button>}
                                            <button type='button' onClick={() => deleteAdditionalEquipment(index)} disabled={isLoading} className='u-mg-left-m'><TrashIcon size={18} /></button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>        
                </form>

                <div className='u-width-half u-mg-left-auto u-bg-grey30 u-mg-top-m'>
                    <div className='u-pd-l'>
                        <div className='u-mg-bottom-s u-flex u-flex-between'>
                            <p>Montant total HT de la facture :</p>
                            <div className='u-bold u-fs-m'>{formatNumberAndAddEurCurrency(additionalEquipmentTotalAmount)}</div>
                        </div>
                        <div className='u-flex u-flex-between'>
                            <p>Montant total éligible HT :</p>
                            <div className='u-right u-bold u-fs-m'>{formatNumberAndAddEurCurrency(totalEligibleCosts)}</div>
                        </div>
                    </div>
                </div>
            </>
            }
        </div>
    )
}
