/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
/* Resolved at runtime, react-markdown fills the html node content */
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { oneOfType, arrayOf, node, object } from 'prop-types'

export const Markdown = ({ children, specificComponents = {}, ...rest }) => {
    return (
        <ReactMarkdown
            components={{
                h1: ({ ...props }) => <h1 className="c-h1 u-primary u-bold u-mg-vt-l" {...props}></h1>,
                h2: ({ ...props }) => <h2 className="c-h2 u-primary u-bold u-mg-vt-l" {...props}></h2>,
                h3: ({ ...props }) => <h3 className="c-h3 u-primary u-bold u-mg-vt-l" {...props}></h3>,
                h4: ({ ...props }) => <h4 className="c-h4 u-primary u-bold u-mg-vt-m" {...props}></h4>,
                h5: ({ ...props }) => <h5 className="c-h5 u-primary u-bold u-mg-vt-s" {...props}></h5>,
                p: ({ ...props }) => <p className="u-mg-bottom-s" {...props}></p>,
                ul: ({ ...props }) => <ul className="u-pd-left-l u-mg-bottom-m" {...props}></ul>,
                li: ({ ...props }) => <li className="u-mg-top-xs" {...props}></li>,
                a: ({ ...props }) => <a className="u-underline" target="_blank" rel="noopener noreferrer" {...props}></a>,
                img: ({ ...props }) => <div className='u-flex u-flex-center-hz u-mg-vt-s'><div className='u-width-half'><img alt="" {...props}/></div></div>,
                ...specificComponents
            }}
            {...rest}
        >
            { children }
        </ReactMarkdown>
    )
}

Markdown.propTypes = {
    children: oneOfType([
        arrayOf(node),
        node
    ]).isRequired,
    specificComponents: object
}