import React from 'react'
import { array, number, shape, string } from 'prop-types'

import {
    getLastPhotosOfHistory, 
    isBetweenStatus, 
    PICTURES_UPLOADED,
    VALIDATED_BID,
    getValidatedBidFromHistory,
    getEquipmentsBidInformationsFromHistory,
    isCancelled,
    getFeedbackToEquipmentBeneficiary,
    BACK_OFFICE_OPERATIONS
} from 'utils/domain/request'
import dateService from 'services/date'

import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'

import Request from '../Request'
import { isNotEmpty } from 'utils/validation'

const Equipment = ({
    request,
}) => {
    const isRequestCancelled = isCancelled(request?.status)
    const isDisplayPhotos = isBetweenStatus({ status: request?.status, start: PICTURES_UPLOADED }) && !isRequestCancelled
    const isBidValidated = isBetweenStatus({ status: request.status, start: VALIDATED_BID }) && !isRequestCancelled

    const bid = getValidatedBidFromHistory(request.history)
    const photos = getLastPhotosOfHistory(request.history)
    const bidInformations = getEquipmentsBidInformationsFromHistory(request.history)

    const requestInitEvent = request.history.find(event => event?.request_data?.operation_code === BACK_OFFICE_OPERATIONS.INIT)
    const requestedDate = isNotEmpty(requestInitEvent) ? dateService.formatDate(requestInitEvent?.history_date) : ''

    return (
        <Request
            request={{
                fubId: request.request_fub_id,
                statusLabel: getFeedbackToEquipmentBeneficiary(request.status, { adminView: true }),
                status: request.status,
                provider: request.provider_data.structureName,
                requestDate: requestedDate ?? ''
            }}
            bonus={{
                isDisplay: isBidValidated,
                amount: request.bonus,
                realBonus: request?.realBonus
            }}
            bidDocuments={{
                isDisplay: request.bidDocuments.isUploaded,
                bill: request?.bidDocuments?.bill,
                proofOfPayment: request?.bidDocuments?.proofOfPayment,
            }}
            pdfOverview= {{
                isDisplay: isBidValidated,
                title: 'Consulter le devis validé',
                url: bid?.request_data?.operation_data?.full_media_url
            }}
        >
            {isBidValidated && (
                <Request.Detail>
                    <>
                        <div className="u-mg-right-xl">
                            <span className="u-grey90">Places de stationnements vendues : </span>
                            <span className="u-primary">{bidInformations ? bidInformations.nbOfBikesPlaces : 'Non renseigné' }</span>
                        </div>
                    </>
                </Request.Detail>
            )}
            {isDisplayPhotos && (
                <Request.Documents title="Photos des travaux">
                    {photos.map((photo, index) => (
                        <InformationToVisualize
                            key={`photo-${index + 1}`} 
                            className="u-mg-right-xl"
                            label={`Photo ${index + 1}`} 
                            url={photo.full_media_url} 
                            iconColor="green"
                            iconSize={30}
                        />
                    ))}
                </Request.Documents>
            )}
        </Request>
    )
}

Equipment.propTypes = {
    request: shape({
        status: number.isRequired,
        request_fub_id: string.isRequired,
        history: array.isRequired,
        bidDocuments: shape({
            bill: shape({ full_media_url: string }),
            proofOfPayment: shape({ full_media_url: string })
        })
    })
}

export default Equipment