import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import PublicLayout from 'layouts/Public'
import { useAuth } from 'hooks/useAuth'
import { publicRoutes } from './routes'

const NotFound = () => {
    const { goBack, push: historyPush } = useHistory()
    const { user } = useAuth()

    useEffect(() => {
        if (!user) historyPush(publicRoutes.login.path)
    }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <PublicLayout showHeader>
            <div className="u-mg-hz-auto">
                <h1 className="c-h1 u-mg-top-xl u-bold u-secondary u-center u-mg-bottom-xl">Page introuvable</h1>
                
                <button onClick={goBack} className="c-btn c-btn--primary u-pd-hz-xl">
                    Retourner à la page précédente
                </button>
            </div>
        </PublicLayout>
    )
}

export default NotFound
