import React from 'react'
import { useParams } from 'react-router'

import BackOfficeLayout from 'layouts/back-office'
import Regularisation from 'components/platform/Regularisation'

const BeneficiaryRegularisation = () => {

    const { beneficiaryId } = useParams()

    return (
        <BackOfficeLayout>
            <Regularisation beneficiaryId={beneficiaryId} />
        </BackOfficeLayout>
    )
}

export default BeneficiaryRegularisation