import React from 'react'
import { useRequestFolder } from 'pages/back-office/Folders/RequestFolder/EquipmentFolder/context'

import useBonus from 'hooks/useBonus'
import { formatNumberAndAddEurCurrency } from 'utils/number'
import { equipmentList } from 'components/catalog/Equipment/list'
import { isNotEmpty, isNotEmptyNumber } from 'utils/validation'

// Pour déduire la prime disponible, nous avons de plusieurs informations sur la request en cours et le bénéficiaire : 
// - Montant total éligible qui nous vient du calcul fait dans le tableau => totalEligibleCosts
// - Nombre de places créées, passées en paramètre du composant => nbOfBikesPlaces
// - L'équipement principal s'il y a et les équipements supplémentaires pour le plafond à associer => paymentLimit
// - La prime dispo qui est le calcul du reste dispo pour le bénéficiaire de prime
export const BonusCalculation = ({ nbOfBikesPlaces, setFormFields }) => {
    const { request, beneficiary, totalEligibleCosts, requestEquipmentArray } = useRequestFolder()
    const { getBonusByRequests } = useBonus({ isFromBackOffice: true })
    const requestsGroupedByGender = beneficiary?.requestsGroupedByGender || {}
    const requests = Object.values(requestsGroupedByGender).flat()
    const regularisationFolders = beneficiary?.regularisationFolders ?? []
    const {
        totalAmountValidated,
        availableBonus,
    } = getBonusByRequests({
        requests: [...requests, ...regularisationFolders],
        userEntityData: beneficiary?.entity_data,
        beneficiary: beneficiary
    })

    // Prime dispo
    const bonusStillAvailable = availableBonus - (totalAmountValidated + beneficiary?.amount_refunded)
    const bonusAmount = bonusStillAvailable <= 0 ? 0 : bonusStillAvailable

    // CALCUL DE LA PRIME
    // Equipement principal
    const mainEquipment = equipmentList.filter(i => !i.optional).filter(item => {
        return Object.keys(request?.request_description).includes(item?.id)
    })
    const isMainEquipment = isNotEmpty(mainEquipment)
    const mainEquipmentAmount = isMainEquipment ? nbOfBikesPlaces * mainEquipment[0]?.paymentLimit : 0

    // Equipements additionnels
    const additionnalEquipmentsQuantity = requestEquipmentArray.filter((item) => item?.optional).reduce((acc, item) => acc + Number(item.quantity), 0)

    const isAdditionnalEquipments = isNotEmptyNumber(additionnalEquipmentsQuantity)
    const additionnalEquipmentAmount = isAdditionnalEquipments ? 300 * additionnalEquipmentsQuantity : 0

    // Calcul final
    const globalAmount = Math.min(0.4 * totalEligibleCosts, mainEquipmentAmount + additionnalEquipmentAmount, bonusAmount)
    // console.log('total equipement principal :', mainEquipmentAmount, 'nbOfBikesPlaces :', nbOfBikesPlaces, 'equipement plafond :', mainEquipment[0]?.paymentLimit)
    // console.log('total equipement additionnel :', 'quantité :', additionnalEquipmentsQuantity, 'total :', additionnalEquipmentAmount)
    // console.log('Total : ', '0.4 * totalEligibleCosts :', 0.4 * totalEligibleCosts, 'mainEquipmentAmount + additionnalEquipmentAmount: ', mainEquipmentAmount + additionnalEquipmentAmount, 'bonusAmount :', bonusAmount)

    React.useEffect(() => {
        // On pousse le globalAmount au parent
        if(isNotEmpty(globalAmount)) {
            setFormFields(s => ({
                ...s,
                amount_to_refund: globalAmount,
            }))
        }
    }, [globalAmount]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='u-bg-grey30 u-flex u-flex-center-vt u-pd-m'>
            <div>Calcul de la prime : </div>
            <div className="u-blue u-fs-m u-bold u-mg-left-s">
                {formatNumberAndAddEurCurrency(globalAmount)}
            </div>
        </div>
    )
}