import React from 'react'
import { number, string } from 'prop-types'
import { DEFAULT_SIZE } from 'utils/icons'

const FolderIcon = ({ size = DEFAULT_SIZE, className }) => (
    <svg wxmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
         width={size} height={size} className={className}
    >
        <path fill="currentColor" d="M1.5 2.25A2.25 2.25 0 013.75 0h4.146c1.437 0 2.64.84 3.467 1.776C11.977 2.472 12.72 3 13.5 3h6.75a2.25 2.25 0 012.25 2.25v.96a2.248 2.248 0 011.284 2.319l-.96 7.686a3.75 3.75 0 01-3.725 3.285H4.9a3.75 3.75 0 01-3.722-3.285l-.96-7.686A2.25 2.25 0 011.5 6.21V2.25zM3 6h18v-.75a.75.75 0 00-.75-.75H13.5c-1.446 0-2.565-.944-3.261-1.731C9.561 2.001 8.73 1.5 7.896 1.5H3.75a.75.75 0 00-.75.75V6zm-.55 1.5a.75.75 0 00-.745.843l.96 7.686A2.25 2.25 0 004.9 18h14.202a2.25 2.25 0 002.233-1.971l.96-7.686a.75.75 0 00-.744-.843H2.45z"/>
    </svg>
)

FolderIcon.propTypes = {
    size: number,
    className: string
}

export default FolderIcon