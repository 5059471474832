import React from 'react'

import Card from 'components/platform/Card'
import {
    hasAnAudit, isOrderedAuditStatus, isRealizedAuditStatus,
    isCanceledAuditStatus, isPaidAuditStatus, isReservedAuditStatus, isAuditReportDocument, isAskedAuditStatus, AUDIT_CERTIFICATION_DOC_GENDER, isCertifDocument
} from 'utils/domain/audit'
import Emoji from 'components/shared/Emoji'
import ModalConfirmation from 'components/shared/ModalConfirmation'
import Table from 'components/shared/Table'
import DownloadIcon from 'components/svg/Download'

import { useAlert } from 'hooks/useAlert'
import { useModal } from 'hooks/useModal'

import * as AuditApi from 'services/api/audit/auditApi'
import dateService from 'services/date'

import saveBlob from 'utils/saveBlob'
import { getColor } from 'utils/styles'

import EyeSeeIcon from 'components/svg/EyeSee'

const AuditMonitoring = () => {
    const { addAlert } = useAlert()
    const { showModal } = useModal()

    const [myAudit, setMyAudit] = React.useState({})
    const [hiddenColumns, setHiddenColumns] = React.useState([])

    const initAuditData = async () => {
        try {
            const { res: audit, getError } = await AuditApi.getMyAudit()
            if (getError()) throw getError()
            const formattedAudit = {
                ...audit,
                cgvMediaUrl: audit?.audit_media?.documents[0]?.full_media_url
            }
            setMyAudit(formattedAudit)
        } catch (error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }

    React.useEffect(() => {
        initAuditData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Audit status
    const auditStatus = myAudit?.audit_status
    const isAskedAudit = isAskedAuditStatus(auditStatus)
    const isOrderedAudit = isOrderedAuditStatus(auditStatus)
    const isOrderedAndPlannedAudit = isOrderedAudit && Boolean(myAudit?.planned_date)
    const isReservedAudit = isReservedAuditStatus(auditStatus)
    const isCanceledAudit = isCanceledAuditStatus(auditStatus)
    const isPaidAudit = isPaidAuditStatus(auditStatus)
    const isRealizedAudit = isRealizedAuditStatus(auditStatus)

    // Audit dates
    const auditCreateDate = myAudit?.create_date ? dateService.formatDate(myAudit?.create_date) : ''
    const auditPlannedDate = myAudit?.planned_date ? dateService.formatDate(myAudit?.planned_date) : ''
    const auditModifyDate = myAudit?.modify_date ? dateService.formatDate(myAudit?.modify_date) : ''
    const auditRealizedDate = isRealizedAudit || isPaidAudit ? dateService.formatDate(myAudit.audit_date) : ''

    // Audit document
    const auditReport = myAudit.audit_media?.documents?.find(document => isAuditReportDocument(document.media_gender))

    const isAuditCancelable = (isOrderedAudit && !Boolean(myAudit?.planned_date)) || isAskedAudit
    const hasPlannedAnAudit = hasAnAudit(myAudit)

    const cardTitle = (() => {
        if (Object.entries(myAudit).length > 0) {
            if (isAskedAudit) return `Audit demandé le ${auditCreateDate}`
            if (isOrderedAudit && !Boolean(myAudit?.planned_date)) return 'Audit commandé'
            if (isOrderedAndPlannedAudit) return `Audit commandé le ${auditPlannedDate}`
            if (isRealizedAudit || isPaidAudit) return `Audit réalisé le ${auditRealizedDate}`
            if (isReservedAudit) return 'Audit bloqué'
        }
    })()

    const columns = (() => {

        let columns = [
            {
                Header: 'Date de la demande',
                accessor: (data) => dateService.formatDate(data?.create_date),
            },
            {
                Header: 'CGV',
                accessor: 'cgvMediaUrl',
                Cell: ({ value: docUrl }) => {
                    if (docUrl) {
                        return (
                            <a href={docUrl} target='_blank' rel="noreferrer">
                                <EyeSeeIcon size={24} className="vertical-align" />
                            </a>
                        )
                    } else return <></>

                },
            },
            {
                Header: 'Raison sociale de l\'intervenant',
                accessor: 'provider_data.structureName',
            },
            {
                Header: 'Email de l\'intervenant',
                accessor: 'provider_data.contactEmail',
            },
            {
                Header: 'Téléphone',
                accessor: 'provider_data.structurePhonenumber',
            }
        ]

        // add date according to the audit status
        if (Object.entries(myAudit).length > 0) {
            if (isOrderedAudit) {
                columns.splice(2, 0, {
                    Header: 'Date audit commandé',
                    accessor: () => isOrderedAndPlannedAudit ? auditPlannedDate : '',
                })
            } else if (isRealizedAudit || isPaidAudit) {
                columns.splice(2, 0, {
                    Header: 'Date audit réalisé',
                    accessor: () => auditRealizedDate,
                })
            } else if (isCanceledAudit) {
                columns.splice(2, 0, {
                    Header: 'Date audit annulé',
                    accessor: () => auditModifyDate,
                })
            } else if (isReservedAudit) {
                columns.splice(2, 0, {
                    Header: 'Date audit bloqué',
                    accessor: () => auditModifyDate,
                })
            }
        }
        return columns
    })()

    React.useEffect(() => {
        if (isAskedAudit) {
            setHiddenColumns(['cgvMediaUrl'])
        }
    }, [auditStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    const displayReport = (auditReport) => {
        window.open(auditReport?.full_media_url || '', '_blank').focus()
    }

    const downloadReport = async (auditReport) => {
        if (!auditReport) return null
        try {
            const extension = auditReport?.media_url?.split('.').pop()
            const report = await fetch(auditReport?.full_media_url)
            const documentTitle = `mon-audit-${dateService.getCurrentDate('dd-MM-y')}.${extension}`
            const blob = await report.blob()
            saveBlob(blob, documentTitle)
        } catch (error) {
            addAlert('error', error?.toString())
        }
    }

    const cancelMyAudit = async () => {
        try {
            const { getError } = await AuditApi.cancelAudit()
            if (getError()) throw getError()

            setMyAudit({})
            addAlert('success', 'Votre audit a bien été annulé')
        } catch (error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }

    const handleCancel = () => {

        showModal(
            ModalConfirmation,
            {
                title: 'Annuler la demande d\'audit',
                body: 'En cliquant sur le bouton "Confirmer", vous annulez votre demande d\'audit.',
                validate: cancelMyAudit,
                closeAfterValidation: true
            }
        )
    }

    if (!hasPlannedAnAudit) {
        return (
            <p className="u-center u-mg-top-m">
                Vous n'avez pas encore prévu d'audit <Emoji label="visage triste" symbol="😟" />
            </p>
        )
    }

    return (
        <div className='u-flex u-flex-dir-col u-pd-hz-xl'>
            <div className="u-primary u-bold u-mg-left-m u-mg-bottom-l">
                Retrouver les informations de mon audit :
            </div>
            <Card
                title={cardTitle}
                className="u-pd-top-xl u-pd-bottom-xl u-pd-hz-xl u-mg-bottom-l u-mg-hz-l u-uppercase"
            >
                <Table
                    columns={columns}
                    data={[myAudit]}
                    isSortable={false}
                    hasSearchBar={false}
                    hiddenColumns={hiddenColumns}
                />
            </Card>
            <div className='u-flex u-mg-hz-auto'>
                {isOrderedAndPlannedAudit &&
                    <button
                        type="button"
                        disabled={true}
                        className="c-btn c-btn--success u-pd-hz-l"
                    >
                        Commandé
                    </button>
                }

                {isAuditCancelable &&
                    <button
                        type="button" onClick={handleCancel}
                        className="c-btn c-btn--secondary u-pd-hz-l"
                    >
                        Annuler ma demande d'audit
                    </button>
                }

                {isReservedAudit &&
                    <button
                        type="button" onClick={handleCancel}
                        className="c-btn c-btn--danger u-pd-hz-l"
                        disabled={true}
                    >
                        Bloqué
                    </button>
                }

                {(isRealizedAudit || isPaidAudit) && auditReport &&
                    <>
                        <button
                            type="button" onClick={() => displayReport(auditReport)}
                            className="c-btn c-btn--secondary u-pd-hz-l u-uppercase"
                        >
                            Consulter mon rapport
                        </button>
                        <button className="u-mg-left-m" type="button" onClick={() => downloadReport(auditReport)} aria-label="Télécharger mon rapport">
                            <DownloadIcon className="vertical-align" color={getColor().primary} />
                        </button>
                    </>
                }

                {(isRealizedAudit || isPaidAudit) && !auditReport &&
                    <p className="u-black u-mg-bottom-m u-italic u-grey90">
                        Aucun rapport transmis pour le moment
                    </p>
                }


            </div>

            {(isOrderedAndPlannedAudit || isReservedAudit) && <div className='u-mg-top-xl u-danger'>
                <p>
                    Pour annuler ou reporter votre demande d’audit, veuillez en faire la demande par écrit à l’adresse <a href="mailto:contact@employeurprovelo.fr">contact@employeurprovelo.fr</a> en mettant <strong>en copie l’organisme de labellisation</strong> qui a vous a été attribué.</p>
                <p className='u-mg-top-m'>Il n’est pas possible <strong>d’annuler</strong> ou <strong>reporter</strong> l’audit <strong>moins de 15 jours avant la date définie</strong> avec l’Organisme de labellisation. Si l’employeur fait une demande d’annulation ou de report d’audit 15 jours avant la date fixée de visite sur site, il devra s’acquitter auprès de la FUB d’un <strong>forfait de 2 500€</strong>. <strong>Tant qu’il n’aura pas versé ce forfait</strong>, il ne pourra pas planifier avec l’organisme de labellisation une nouvelle date d’audit.
                </p>
            </div>}
        </div>
    )
}

export default AuditMonitoring