import React from 'react'

const Step1 = () => (
    <div className="u-pd-top-xl">
        <div className="u-primary u-bold u-mg-bottom-m">
            Afin de développer l'usage du vélo dans votre établissement, il est nécessaire d'élaborer un plan d'action, des indicateurs et un pilotage de votre démarche pro-vélo. 
            Cette stratégie se compose de :<br />
        </div>
        <ul className="u-pd-left-l u-mg-top-m u-mg-bottom-m">
            <li>
                <span className="u-secondary u-bold">un diagnostic initial</span> des habitudes de transport des salariés (pratiques, besoins, freins…) à partir de questionnaires ou consultations, à la fois pour les trajets domicile-travail et les déplacements professionnels
            </li>
            <li>
                <span className="u-secondary u-bold">un plan d'action</span> avec des indicateurs et des objectifs, permettant une évaluation et une révision régulière
            </li>
            <li>
                <span className="u-secondary u-bold">une personne référente ou pilote « vélo »</span>, identifiée par les collaborateurs sur le site, qui anime la démarche
            </li>
        </ul>
        Ces <span className="u-primary u-bold">trois actions sont obligatoires</span> pour pouvoir labelliser votre établissement "employeur pro-vélo".
        Certains employeurs ({'>'}50 personnes sur un même site) ont l’obligation d’élaborer un « plan de mobilité employeur » (si la négociation annuelle obligatoire n'aboutit pas à un accord) et de le transmettre à leur collectivité locale.

        Les questions suivantes portent sur le pilotage et la stratégie mobilité du site de votre établissement. Si votre établissement est multi-sites, vos réponses devront concerner uniquement le site que vous avez enregistré pour ce compte.    
    </div> 
)

export default Step1