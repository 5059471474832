import * as CatalogApi from 'services/api/CatalogApi'

import dateService from 'services/date'
import { isNotEmpty } from 'utils/validation'
import {
        BACK_OFFICE_OPERATIONS,
        PICTURES_NEED_COMPLEMENT,
        PICTURES_UPLOADED_BACK,
        ALL_DOCUMENTS_NEED_COMPLEMENT,
        ALL_DOCUMENTS_UPLOADED_BACK,
        PICTURES_UPLOADED,
        ALL_DOCUMENTS_UPLOADED,
        REQUEST_REFUSED,
        isEquipmentFolder
} from 'utils/domain/request'
import { getFolderTypeLabel } from 'utils/domain/beneficiary'

const operationCodeExcludedList = [
        BACK_OFFICE_OPERATIONS.INIT, 
        BACK_OFFICE_OPERATIONS.PROVIDER,
        BACK_OFFICE_OPERATIONS.BID.RECEIVE_QUOTE,
        BACK_OFFICE_OPERATIONS.BID.VALIDATE_BID,
        BACK_OFFICE_OPERATIONS.EMARGEMENTS.UPLOAD_BY_BENEFICIARY,
        BACK_OFFICE_OPERATIONS.REPORT.UPLOAD_BY_BENEFICIARY
]

const beneficiaryOperationCode = [
        BACK_OFFICE_OPERATIONS.BID.UPLOAD_BY_BENEFICIARY,
        BACK_OFFICE_OPERATIONS.PICTURES.UPLOAD_BY_BENEFICIARY,
]

const pictureCollectionStatus = [
        PICTURES_NEED_COMPLEMENT,
        PICTURES_UPLOADED_BACK,
        PICTURES_UPLOADED
]

const documentCollectionStatus = [
        ALL_DOCUMENTS_NEED_COMPLEMENT, 
        ALL_DOCUMENTS_UPLOADED_BACK, 
        ALL_DOCUMENTS_UPLOADED
]

const operationCodeLabel = [
        {
                label: [
                        BACK_OFFICE_OPERATIONS.PICTURES.UPLOAD_BY_BENEFICIARY, 
                        BACK_OFFICE_OPERATIONS.BID.UPLOAD_BY_BENEFICIARY
                ],
                value: 'À valider'
        },
        {
                label: [
                        BACK_OFFICE_OPERATIONS.PICTURES.VALIDATE,
                        BACK_OFFICE_OPERATIONS.BID.VALIDATE
                ],
                value: 'Validé'
        },
        {
                label: [BACK_OFFICE_OPERATIONS.PICTURES.ASK_MORE_INFORMATIONS],
                value: 'En attente photos'
        },

        {
                label: [BACK_OFFICE_OPERATIONS.BID.ASK_MORE_INFORMATIONS],
                value: 'En attente compléments'
        },

        {
                label: [
                        BACK_OFFICE_OPERATIONS.BID.DECLINE,
                        BACK_OFFICE_OPERATIONS.PICTURES.DECLINE,
                        'CAN'
                ],
                value: 'Refusé'
        }
]

const entityHistoryStatus = [
        {
                label: 'A valider',
                value: 0
        },
        {
                label: 'En attente compléments',
                value: 1
        },
        {
                label: 'A valider',
                value: 2
        },
        {
                label: 'Validé',
                value: 3
        }
]

export const getFilesProcessedList = async (dates) => {

        try {
                // STEP 1 - Appels API aux 2 routes
                const { res: validatedFolderCollection, getError: getValidatedFolderCollectionError } = await CatalogApi.getBackOfficeFoldersValidate()
                if(getValidatedFolderCollectionError()) throw getValidatedFolderCollectionError()

                const { res: onGoingFolderCollection, getError: getOnGoingFolderCollectionError } = await CatalogApi.getBackOfficeFolders()
                if(getOnGoingFolderCollectionError()) throw getOnGoingFolderCollectionError()

                // STEP 2 - Construction du tableau de retour
                const folderCollection = []

                // Deadlines
                // Si l'utilisateur a sélectionné la même date de début et de fin, la date de fin doit se terminer à 23:59:59 pour inclure tous les historiques de la journée
                const isDeadlines = dates.areDates
                const deadlineStartDate = new Date(dates.firstDate)
                const deadlineEndDate = dateService.endOfDay(new Date(dates.lastDate))

                const isCreatedDateIncluding = (createdate) => {                        
                        return (dateService.isAfterThan(new Date(createdate), deadlineStartDate) && dateService.isBeforeThan(new Date(createdate), deadlineEndDate)) || 
                         dateService.isEqualThan(new Date(createdate), deadlineStartDate) || 
                         dateService.isEqualThan(new Date(createdate), deadlineEndDate)
                 } 
        
                // REQUESTS
                const getRequestHistory = async (request) => {
                        const { res: historyCollection } = await CatalogApi.getBackOfficeRequestHistory(request.request_id)
                                if(historyCollection.length > 0) {
                                        // De l'API, nous recevons l'historique d'une request, via plusieurs étapes
                                        // D'une étape à la suivante, nous devons garder l'information initiale => l'action faite par le bénéficiaire
                                        // On garde donc deux infos importantes dans un historyCollection :
                                        // le nom du step initial (grace au status)
                                        // et la date de création de ce step initial pour pouvoir comparer les dates ensuite
                                        
                                        let intialStepName
                                        let intialStepDate = ''
        
                                        let index = 1
        
                                        historyCollection
                                                // on exclue de notre historique tout ce qui est listé dans "operationCodeExcludedList"
                                                .filter((history) => !operationCodeExcludedList.includes(history.request_data?.operation_code))
                                                // on exclue de notre historique les annulations faites par le bénéficiaire
                                                .filter((history) => !(history.request_data?.operation_code === 'CAN' && history.status !== REQUEST_REFUSED))
                                                // on ordonne dans le sens chronologique
                                                .reverse()
                                                .forEach(history => {
                                                        const isBeneficiaryAction = beneficiaryOperationCode.includes(history.request_data?.operation_code)
        
                                                        const getHistoryResult = () => {
                                                                return operationCodeLabel?.find(operation => operation.label.includes(history.request_data?.operation_code))?.value
                                                        }
        
                                                        const getHistoryControlType = (history) => {
                                                                // pour les demandes de type 'EQU', on ne peut avoir que des photos ou factures
                                                                if(isEquipmentFolder(history.request_fub_id)) {
                                                                        if(pictureCollectionStatus.includes(history.status)) {
                                                                                intialStepName = 'Photos'
                                                                                return 'Photos'
                                                                        } else if(documentCollectionStatus.includes(history.status)) {
                                                                                intialStepName = 'Factures'
                                                                                return 'Factures'
                                                                        }
                                                                // les autres demandes sont liées à des dossiers
                                                                } else {
                                                                        intialStepName = 'Dossier'
                                                                        return 'Dossier'
                                                                }
                                                        }

                                                        const getCreationDate = intialStepDate ? intialStepDate : history.history_date
        
                                                        const newRequestObject = {
                                                                SIRET: request.entity_data.siret,
                                                                structureName: request.entity_data.structureName,
                                                                controlType: getHistoryResult() === 'À valider' ? getHistoryControlType(history) : intialStepName,
                                                                folderId: history.request_fub_id,
                                                                rank: index,
                                                                creationDate: getCreationDate,
                                                                processingDate: isBeneficiaryAction ? '' : history.history_date,
                                                                timeLimit: !isBeneficiaryAction ? dateService.getDaysDifferenceBetweenDates(getCreationDate, history.history_date) : '',
                                                                result: getHistoryResult(),
                                                        }
        
                                                        intialStepDate = isBeneficiaryAction ? history.history_date : ''
                                                        index++

                                                        isDeadlines && isCreatedDateIncluding(newRequestObject.creationDate) && folderCollection.push(newRequestObject)
                                                        !isDeadlines && folderCollection.push(newRequestObject)
                                                }
                                ) 
                        } 
                }
        
                const getRequestFolderCollectionValidatedHistory = async () => {
                        return validatedFolderCollection.requests && Promise.all(validatedFolderCollection.requests.map(request => getRequestHistory(request)))
                }
        
                const getRequestFolderCollectionOnGoingHistory = async () => {
                        return onGoingFolderCollection.requests && Promise.all(onGoingFolderCollection.requests.map(request => getRequestHistory(request)))
                }
        
                // ENTITIES
                const getEntityHistory = async (entity) => {
                        const { res: historyCollection } = await CatalogApi.getBackOfficeEntityHistory(entity.entity_id)
                        //console.log(historyCollection)
                        if(historyCollection.length > 0 && isNotEmpty(historyCollection[0])) {
                                let newEntityObject = {}
                                const isAtLeastOneValidEntityCheck = historyCollection.filter((history) => isNotEmpty(history.entity_check)).length > 0

                                const getHistoryResult = (entityHistory) => {
                                        return entityHistory.entity_check?.status ? entityHistoryStatus?.find(status => status.value === entityHistory.entity_check.status)?.label : entityHistoryStatus[0].label
                                }

                                if(!isAtLeastOneValidEntityCheck) {
                                        // Si l'entité a un historique avec seulement des entity_check à null, on envoie l'objet entity une seule fois avec un status 0

                                        newEntityObject = {
                                                SIRET: entity.entity_data.siret,
                                                structureName: entity.entity_data.structureName,
                                                controlType: getFolderTypeLabel(entity.entity_data?.gender),
                                                folderId: entity.entity_id,
                                                rank: 1,
                                                creationDate: entity.create_date,
                                                processingDate: entity.entity_check?.date_check,
                                                timeLimit: entity.entity_check?.date_check ? dateService.getDaysDifferenceBetweenDates(entity.create_date, entity.entity_check?.date_check) : '',
                                                result: entityHistoryStatus[0].label,
                                        }

                                        isDeadlines && isCreatedDateIncluding(entity.create_date) && folderCollection.push(newEntityObject)
                                        !isDeadlines && folderCollection.push(newEntityObject)

                                } else {
                                        let previousStepStatus = ''
                                        let intialStepDate = ''
                                        let index = 1

                                        historyCollection
                                                .reverse()
                                                .forEach(history => {
                                                        // Si certains status se suivent, on ne doit pas prendre en compte l'historique en cours pour l'export car il fera doublon avec le précédent
                                                        const getCurrentStepStatus = history?.entity_check?.status ? history.entity_check.status : 0
                                                        const isAnInvalidHistory = 
                                                                Boolean(previousStepStatus === 0 && getCurrentStepStatus === 2) || 
                                                                Boolean(previousStepStatus === 0 && getCurrentStepStatus === 0) || 
                                                                Boolean(previousStepStatus === 1 && getCurrentStepStatus === 1) || 
                                                                Boolean(previousStepStatus === 2 && getCurrentStepStatus === 2) || 
                                                                Boolean(previousStepStatus === 3 && getCurrentStepStatus === 3)

                                                        if(!isAnInvalidHistory) {
                                                                const getCreationDate = intialStepDate ? intialStepDate : history.create_date

                                                                newEntityObject = {
                                                                        SIRET: entity.entity_data.siret,
                                                                        structureName: entity.entity_data.structureName,
                                                                        controlType: getFolderTypeLabel(entity.entity_data?.gender),
                                                                        folderId: entity.entity_id,
                                                                        rank: index,
                                                                        creationDate: getCreationDate,
                                                                        processingDate: history.entity_check?.date_check,
                                                                        timeLimit: history.entity_check?.date_check ? dateService.getDaysDifferenceBetweenDates(getCreationDate, history.entity_check.date_check) : '',
                                                                        result: getHistoryResult(history),
                                                                }

                                                                intialStepDate = history.entity_check?.date_check
                                                                index++
                                                                isDeadlines && isCreatedDateIncluding(entity.create_date) && folderCollection.push(newEntityObject)
                                                                !isDeadlines && folderCollection.push(newEntityObject)
                                                        }

                                                previousStepStatus = getCurrentStepStatus
                                        })
                                }
                        }
                }

                const getEntityFolderCollectionValidatedHistory = async () => {
                        return validatedFolderCollection.entities && Promise.all(validatedFolderCollection.entities.map(entity => getEntityHistory(entity)))
                }
        
                const getEntityFolderCollectionOnGoingHistory = async () => {
                        return onGoingFolderCollection.entities && Promise.all(onGoingFolderCollection.entities.map(entity => getEntityHistory(entity)))
                }
        
                return await Promise.all(
                        [ 
                                getRequestFolderCollectionValidatedHistory(), 
                                getRequestFolderCollectionOnGoingHistory(),
                                getEntityFolderCollectionValidatedHistory(),
                                getEntityFolderCollectionOnGoingHistory()
                        ])
                        .then(() => {
                        // une fois toutes les requests passées via Promise.all, on passe les entities
                        // validatedFolderCollection.entities && getEntityCollection(validatedFolderCollection.entities)
                        // onGoingFolderCollection.entities && getEntityCollection(onGoingFolderCollection.entities)

                        return folderCollection
                })
        } catch (error) {
                const errorMessage = error?.message?.toString() || error?.toString()
                console.error(errorMessage)
        } 
            

}
