import React from 'react'
import { useTable, useSortBy, usePagination, useFilters, useGlobalFilter } from 'react-table'
import Emoji from 'components/shared/Emoji'

const PAGINATION_LENGTH = 10

const UploadProviderDataTable = ({
    data,
    headers,
    className
}) => {

    // Columns definition
    const columns = React.useMemo(
        () => {
            const columnsArray = [
                {
                    Header: 'Erreur',
                    accessor: 'error',
                    Cell: ({ cell }) => {
                        return (
                            <div className=''>{cell?.value ?? '-'}</div>
                        )
                      },
                },
                {
                    Header: 'Id',
                    accessor: 'id',
                },
                {
                    Header: 'Siret',
                    accessor: 'siret',
                },
                {
                    Header: 'Raison sociale',
                    accessor: 'raison_sociale',
                },
                {
                    Header: 'Catégorie',
                    accessor: 'category',
                },
                {
                    Header: 'Equipement',
                    accessor: 'equipment',
                },
                {
                    Header: 'Dénomination',
                    accessor: 'name',
                },
                {
                    Header: 'Statut',
                    accessor: 'status',
                },
            ]
    
          return columnsArray
        },
    [headers]) // eslint-disable-line react-hooks/exhaustive-deps

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        // rows,
        page, // the rows for the active page

        // pagination utilities
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        setGlobalFilter,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 10
            },
            // autoResetSelectedRows: false
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    // Manage global search
    // -------------------
    const [dataTableFilter, setDataTableFilter] = React.useState('')
    React.useEffect(() => setGlobalFilter(dataTableFilter), [dataTableFilter, setGlobalFilter])

    // Manage pagination
    // -------------------
    const middleDistance = Math.round((PAGINATION_LENGTH - 1) / 2)
    const pageShouldBeHiddenInPagination = (index) => {
        // in the first pages,
        // pagination should not exceed PAGINATION_LENGTH
        const condition1 = (pageIndex <= middleDistance && index > PAGINATION_LENGTH - 1)
        // in the middle pages,
        // show x pages before currentPage and y pages after currentPage
        const condition2 = (
            (pageIndex > middleDistance)
            && (pageIndex < (pageOptions.length - middleDistance))
            && (index < (pageIndex - middleDistance) || index > (pageIndex + middleDistance - 1))
        )
        // in the last pages,
        // always show the x last pages
        const condition3 = (
            (pageIndex > (pageOptions.length - 1 - middleDistance))
            && index < (pageOptions.length - PAGINATION_LENGTH)
        )
        return (condition1 || condition2 || condition3)
    }
    
    return (
        <>
            <div className={'c-table-data ' + (className ? className : '')}>
                <div className="c-table-data-container">
                    <table className="c-table c-table--responsive" {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr className="c-table__row" {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => {
                                        return (
                                            <th
                                                className={'c-table__cell u-pd-m '}
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                style={{ flexBasis: '200px', width: '200px' }}
                                            >
                                                <span className={'u-ellipsis'}>{column.render('Header')}</span>
                                                <span>
                                                    {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? ' 🔽'
                                                        : ' 🔼'
                                                    : ''}
                                                </span>
                                            </th>
                                        )
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <tr className={ 'c-table__row u-pd-m u-pd-0@main'} {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td
                                                    className={'c-table__cell u-pd-m@main '}
                                                    style={{ flexBasis: '200px', width: '200px' }}
                                                    {...cell.getCellProps()}
                                                >
                                                    <p>{cell.render('Cell')}</p>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    { data.length === 0 &&
                        <p className="u-center u-mg-top-m">
                            Aucune donnée disponible pour le moment
                        </p>
                    }
                </div>

                {/* Pagination */}
                { pageOptions && pageOptions.length > 1 &&
                    <div className="u-flex u-flex-center-hz">
                        <div className="c-pagination">
                            <button
                                type="button" className="c-btn c-btn--primary u-pd-hz-s u-mg-hz-m"
                                onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                            >
                                {'<<'}
                            </button>
                            {pageOptions.map(index => {
                                if (pageShouldBeHiddenInPagination(index)) return <React.Fragment key={index}></React.Fragment>
                                return (
                                    <button
                                        type="button"
                                        key={index}
                                        className={(index === pageIndex ? 'u-bold' : '')}
                                        style={{ width: '30px' }}
                                        onClick={() => gotoPage(index)}
                                    >
                                        {index + 1}
                                    </button>
                                )
                            })}
                            <button
                                type="button" className="c-btn c-btn--primary u-pd-hz-s u-mg-hz-m"
                                onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}
                            >
                                {'>>'}
                            </button>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default UploadProviderDataTable