import React from 'react'

import Emoji from 'components/shared/Emoji'
import DownloadIcon from 'components/svg/Download'
import Charter from 'components/Charter'

import { useAuth } from 'hooks/useAuth'
import { getFullUrlCharter, isCommitmentCharterAlreadySigned } from 'utils/domain/user'
import saveBlob from 'utils/saveBlob'
import { getColor } from 'utils/styles'

import './styles.scss'

const CommitmentCharterMonitoring = () => {
    const { user } = useAuth()

    const isCharterSigned = isCommitmentCharterAlreadySigned(user)

    if(!isCharterSigned){
        return (
            <p className="u-center u-mg-top-m">
                Vous n'avez pas encore signé la charte d'accès <Emoji label="visage triste" symbol="😟" />
            </p>
        )
    }

    const fullUrlCharter = getFullUrlCharter(user)
    
    const downloadCharter = async () => {
        const charter = await fetch(fullUrlCharter)
        const blob = await charter.blob()
        saveBlob(blob, 'charte-acces.pdf')
    }

    return (
        <div className='u-flex u-flex-dir-col commitment-charter-activity'>
            <div>
                <div className="u-primary u-bold u-mg-left-xxl u-mg-bottom-l">
                    Télécharger ma charte d'accès signée 
                    <button className="u-mg-left-m" type="button" onClick={downloadCharter}>
                        <DownloadIcon className="vertical-align" color={getColor().primary} />
                    </button>
                </div>
                <Charter urlPdf={fullUrlCharter} />
            </div>
        </div>
    )
}

export default CommitmentCharterMonitoring