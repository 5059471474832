import React from 'react'
import { number, object, string } from 'prop-types'
import { DEFAULT_SIZE } from 'utils/icons'


const LogoutIcon = ({ style, size = DEFAULT_SIZE, className }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height={size} width={size} viewBox="0 0 512 512" aria-hidden="true" style={style} className={className}>
			<path fill="currentColor" d="M330.667 192c5.888 0 10.667-4.779 10.667-10.667v-128C341.333 23.936 317.419 0 288 0H53.333C23.915 0 0 23.936 0 53.333v384c0 29.397 23.915 53.333 53.333 53.333H288c29.419 0 53.333-23.936 53.333-53.333v-128c0-5.888-4.779-10.667-10.667-10.667S320 303.445 320 309.333v128c0 17.643-14.357 32-32 32H53.333c-17.643 0-32-14.357-32-32v-384c0-17.643 14.357-32 32-32H288c17.643 0 32 14.357 32 32v128c0 5.888 4.779 10.667 10.667 10.667z"/>
			<path fill="currentColor" d="M480 234.667H138.667c-5.888 0-10.667 4.779-10.667 10.667S132.779 256 138.667 256H480c5.888 0 10.667-4.779 10.667-10.667s-4.779-10.666-10.667-10.666z"/>
			<path fill="currentColor" d="M487.531 237.824l-64-64c-4.16-4.16-10.923-4.16-15.083 0s-4.16 10.923 0 15.083l56.448 56.448-56.448 56.448c-4.16 4.16-4.16 10.923 0 15.083a10.716 10.716 0 0015.083.022l64-64c4.16-4.161 4.16-10.924 0-15.084z"/>
		</svg>
	)
}

LogoutIcon.propTypes = {
	style: object,
	size: number,
	className: string
}

export default LogoutIcon
