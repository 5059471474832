import React from 'react'

const BasketContext = React.createContext({
    currentBasket: null,
    setBasket: () => {}
})

const useBasket = () => React.useContext(BasketContext)

const BasketProvider = ({ children }) => {
    const [currentBasket, setCurrentBasket] = React.useState(null)
    const setBasket = (id) => setCurrentBasket(id)
    const firstItem = currentBasket ? currentBasket[0] : null

    return (
        <BasketContext.Provider value={{ currentBasket, setBasket, firstItem }}>
            {children}
        </BasketContext.Provider>
    )
}

export { useBasket, BasketProvider }