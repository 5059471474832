import React from 'react'
import { string, func, bool, object, shape } from 'prop-types'
import FormElement from 'components/shared/FormElement'

const EquipmentProviderAdditionalServices = ({
  formFields = {},
  formEvents = {},
  showLocalErrors = {},
  handleValidation = () => {},
  handleInputChange = () => {}
}) => (
  <div className='u-flex u-flex-dir-col u-mg-top-m u-mg-right-auto'>
    <h5 className='u-primary u-mg-bottom-m'>Services supplémentaires</h5>
    <FormElement 
      value={formFields.bikePumps}
      name="bikePumps"
      type="checkbox"
      checkboxLabel="Stations de gonflage"
      className="u-mg-bottom-m"
      classNameInput="u-bg-transparent"
      showErrors={showLocalErrors}
      onValidate={handleValidation}
      onChange={handleInputChange}
      disabled={!formEvents.editable}
    />      
    <FormElement 
      value={formFields.bikeToolkit}
      name="bikeToolkit"
      type="checkbox"
      checkboxLabel="Stations d'outillage"
      className="u-mg-bottom-m"
      classNameInput="u-bg-transparent"
      showErrors={showLocalErrors}
      onValidate={handleValidation}
      onChange={handleInputChange}
      disabled={!formEvents.editable}
    />      
    <FormElement 
      value={formFields.lockers}
      name="lockers"
      type="checkbox"
      checkboxLabel="Casiers"
      className="u-mg-bottom-m"
      classNameInput="u-bg-transparent"
      showErrors={showLocalErrors}
      onValidate={handleValidation}
      onChange={handleInputChange}
      disabled={!formEvents.editable}
    />
  </div>
)

EquipmentProviderAdditionalServices.propTypes = {
  formFields: shape({
    structureName: string,
    structureSiret: string,
    structureAddress: string,
    structureCity: string,
    structurePostCode: string,
    structurePhoneNumber: string,
    emailConnexion: string,
    beneficiaryEmail: string,
    structureEmployeeQuantity: string,
    structureEmployeeCategory: string
  }),
  setFormFields: func,
  formEvents: object,
  showLocalErrors: bool,
  handleValidation: func,
  handleInputChange: func
}

export default EquipmentProviderAdditionalServices