import React from 'react'
import { default as ReactSelect } from 'react-select'
import { isNotEmpty } from 'utils/validation'

const Select = ({
    // Attributes
    value,
    defaultValue,
    options,
    name,
    label = '',
    placeholder = '',
    disabled,
    readonly = false,
    isOptionDisabled,
    forceDefaultValue = false,
    // Styles
    style,
    className,
    classNameLabel = '',
    selectClassname,
    // Error management
    required = false,
    customRules,
    localValidation,
    customRulesTrigger,
    showErrors = false,
    // Handler
    onChange,
    onValidate,
}) => {
    const [selectedOption, setSelectedOption] = React.useState(null)
    const [errors, setErrors] = React.useState([])

    const getCorrespondingOption = (value) => {
        // FIXME: sometimes the value is stored in the value prop
        // sometimes it is stored in the label prop 😬
        const correspondingValue = options?.find((option) => option.value === value)
        const correspondingLabel = options?.find((option) => option.label === value)
        return correspondingValue || correspondingLabel
    }

    React.useEffect(() => {
        // Pour éviter des effets de bord, on vérifie que la valeur n'est pas déjà sélectionnée (valeur par défaut par exemple)
        const alreadySameOptionSelected = isNotEmpty(selectedOption?.value) && isNotEmpty(value) ? selectedOption?.value === value : false
        if (isNotEmpty(value) && !alreadySameOptionSelected) {
            if (value?.value) {
                handleChange(value)
            } else {
                // Si nous n'avons qu'une value et non un label/value alors on va chercher dans les options pour remonter cette valeur
                const correspondingOption = getCorrespondingOption(value)
                if (correspondingOption) handleChange(correspondingOption)
            } 
        }
    }, [value, options]) // eslint-disable-line

    React.useEffect(() => {
        if (defaultValue) setSelectedOption(defaultValue)
    }, [defaultValue, forceDefaultValue]) // eslint-disable-line

    const rules = React.useMemo(() => [
        {
            key: 'select-empty',
            check: (value) => required ? isNotEmpty(value) : true,
            message: 'Ce champ est obligatoire'
        },
        {
            key: 'select-invalid',
            check: (value) => (isNotEmpty(value) && isNotEmpty(localValidation)) ? localValidation : true,
            message: "Cette valeur n'est pas valide"
        },
        ...(customRules || [])
    ], [required, localValidation, customRulesTrigger]) // eslint-disable-line
    // customRules in dependencies array trigger an infinite loop,
    // because custom rules include a check property defining a new function,
    // preventing useMemo to return a valid shallow-comparison
    // or event useDeepMemo to return a valid deep-comparison

    const validate = React.useCallback((currentValue) => {
        const newErrors = []
        rules.forEach(rule => {
            if (!rule.check(currentValue)) {
                newErrors.push({
                    origin: name,
                    rule: rule.key,
                    message: rule.message
                })
            }
        })
        setErrors(newErrors)
        if (onValidate) onValidate(name, newErrors)
    }, [rules, name, onValidate])

    // validate on mount and each time value change
    React.useEffect(() => {
        validate(value)
        return () => {
            // On unmounted erros and validation are no longer needed
            setErrors([])
            if (onValidate) onValidate(name, [])
        }
    }, [value, validate, onValidate, name])

    const handleChange = (event) => {
        const inputValue = {
            target: {
                name: name,
                value: event.value,
                label: event.label
            }
        }
        setSelectedOption(inputValue.target)
        onChange(inputValue)
    }

    return (
        <>
            <div className={'c-form-group ' + (className)} style={style}>
                <label className={'c-label u-mg-bottom-xs ' + classNameLabel} htmlFor="code">{label} {required && '*'}</label>
                <ReactSelect
                    options={options}
                    value={selectedOption}
                    isDisabled={disabled || readonly}
                    placeholder={placeholder}
                    className={'c-select ' + (selectClassname ? selectClassname : '')}
                    classNamePrefix={'c-select'}
                    onChange={(e) => !readonly && handleChange(e)}
                    onFocus={() => validate(selectedOption)}
                    onBlur={() => validate(selectedOption?.value)}
                    isOptionDisabled={isOptionDisabled}
                />

                {/* Error messages */}
                {showErrors && errors && errors.map((error, index) => {
                    return <p key={index} className="u-fs-xs u-danger">{error.message}</p>
                })}
            </div>
        </>
    )
}


export default Select
