import React from 'react'
import { shape, number, string, oneOfType } from 'prop-types'

import { DEFAULT_SIZE } from 'utils/icons'

const ChevronUpIcon = ({ size = { width: DEFAULT_SIZE, height: DEFAULT_SIZE }, color }) => {
	return (
        <svg style={size} fill={color} viewBox="0 0 14 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="chevron-up" transform="translate(-2.000000, -6.000000)" fill="#6A6E73">
                    <path d="M11.469,6.96899998 C11.6097064,6.82793743 11.8007591,6.7486593 12,6.7486593 C12.1992409,6.7486593 12.3902936,6.82793743 12.531,6.96899998 L21.531,15.969 C21.8242632,16.2622632 21.8242632,16.7377368 21.531,17.031 C21.2377368,17.3242632 20.7622632,17.3242632 20.469,17.031 L12,8.56049999 L3.53099996,17.031 C3.23773676,17.3242632 2.76226316,17.3242632 2.46899996,17.031 C2.17573676,16.7377368 2.17573676,16.2622632 2.46899996,15.969 L11.469,6.96899998 Z"></path>
                </g>
            </g>
        </svg>
	)
}


ChevronUpIcon.propTypes = {
    size: shape({
        width: oneOfType([string, number]),
        height: oneOfType([string, number])
    }),
    color: string
}


export default ChevronUpIcon

