import React from 'react'
import { func, number, object, shape } from 'prop-types'

import { useModal } from 'hooks/useModal'
import {
    AUDITS_PROCESS_STATUS, getClassNameByAuditStatus,
    isAskedAuditStatus, isOrderedAuditStatus, isReservedAuditStatus,
    isCanceledAuditStatus, isPaidAuditStatus, isRealizedAuditStatus
} from 'utils/domain/audit'
import { WAITING_CERTIFICATION } from 'utils/domain/certification'
import dateService from 'services/date'

import ModalPlanning from 'components/shared/ModalPlanning'
import './styles.scss'

const StatusCell = ({ cell, value: status, column: { updateData } }) => {

    const { showModal, hideModal } = useModal()

    const isSelectDisabled =
        isAskedAuditStatus(status)
        || isRealizedAuditStatus(status)
        || isCanceledAuditStatus(status)
        || isReservedAuditStatus(status)
        || isPaidAuditStatus(status)
        || (isOrderedAuditStatus && !cell.row.original.planned_date)

    const handleEditAuditStatus = async (originalRowValues, newAuditStatus) => {
        const audit = originalRowValues
        const entityId = audit.entity_id
        const auditId = audit.audit_id
        const plannedDate = audit?.planned_date || ''

        // payload
        const auditToUpdate = {
            entity_id: entityId,
            audit_status: newAuditStatus,
            audit_label: WAITING_CERTIFICATION, // waiting certification is set by default (the auditor could change it later)
        }
        if (isRealizedAuditStatus(newAuditStatus))
            showModal(ModalPlanning,
                {
                    title: 'Veuillez indiquer la date de réalisation de l’audit. Si un label a été attribué au bénéficiaire, merci d’indiquer également cette date sur l\'attestation de labellisation.',
                    validation: async (date) => {
                        auditToUpdate.audit_date = dateService.formatDate(date, 'yyyy-MM-dd HH:mm:ss')
                        await updateData(auditId, auditToUpdate)
                        hideModal()
                    },
                    audit: audit,
                    minDate: dateService.formatDate(plannedDate, 'y-MM-dd')
                },
                { isClosable: false }
            )
        else
            await updateData(auditId, auditToUpdate)
    }

    return (
        <>
            <select
                value={status} aria-label="Choix du statut de l'audit"
                className={`audit-status ${getClassNameByAuditStatus(status)}`}
                onChange={(e) => handleEditAuditStatus(cell.row.original, parseInt(e.target.value))}
                disabled={isSelectDisabled}
            >
                {AUDITS_PROCESS_STATUS.map((status, index) => {
                    const statusValue = parseInt(status.value)
                    const isDisabledOption =
                        isAskedAuditStatus(statusValue)
                        || isOrderedAuditStatus(statusValue)
                        || isCanceledAuditStatus(statusValue)
                        || isReservedAuditStatus(statusValue)
                        || isPaidAuditStatus(statusValue)

                    return (
                        <option
                            value={statusValue}
                            key={index}
                            disabled={isDisabledOption}
                        >
                            {status.label}
                        </option>
                    )
                }
                )}
            </select>
        </>
    )
}

StatusCell.propTypes = {
    cell: object.isRequired,
    value: number.isRequired,
    column: shape({
        updateData: func.isRequired
    }).isRequired
}

export default StatusCell