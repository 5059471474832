import React from 'react'
import { func, string } from 'prop-types'
import { Document, Page, pdfjs } from 'react-pdf'

import PDFCharter from 'assets/data/charte-engagement.pdf'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const Charter = ({
    onLoadSuccess = () => {},
    urlPdf
}) => {

    const renderLoading = () => (
        <div className="u-flex u-flex-dir-col u-flex-center-vt u-mg-top-xxl">
            <div className="c-spinner"></div>
            <div className="u-primary u-mg-top-m">Chargement de la charte d'accès en cours...</div>
        </div>
    )

    const renderError = () => (
        <div className="u-flex u-flex-dir-col u-flex-center-vt u-mg-top-xxl">
            <div className="u-danger u-bold u-mg-top-m">Une erreur est survenue pendant le chargement de la charte d'accès</div>
        </div>
    )

    return (
        <Document 
            file={urlPdf || PDFCharter} 
            onLoadSuccess={onLoadSuccess} 
            loading={renderLoading}
            error={renderError}
            externalLinkTarget="_blank"
        >
            <Page scale={1.7} pageNumber={1} />
        </Document>
    )

}

Charter.propTypes = {
    onLoadSuccess: func,
    urlPdf: string,
}

export default Charter