import React from 'react'
import { Text, View, } from '@react-pdf/renderer'

import { formationList } from 'components/catalog/Technical/list'
import styles from 'components/pdf/PDFStyles'
import DataViewPDF from 'components/pdf/PDFDataView'
import RequestPDF from 'components/pdf/PDFRequestTemplate'
import { monitoringPDFpropTypes } from 'components/pdf/monitoringPDFpropTypes'

const TechnicalServicePDF = ({ beneficiary, request, provider }) => {
    const formation = formationList.find(f => f.id === request?.request_description?.type)
    const totalPrice = request?.request_description?.bikesQuantity
        ? request?.request_description?.bikesQuantity * formation?.unitPrice
        : request?.request_description?.sessionsQuantity * formation?.unitPrice
    return (
        <RequestPDF
            beneficiary={beneficiary}
            title={`Demande de prestation n°${request?.request_fub_id}`}
            comment={request?.request_description?.comment}
            createDate={request?.create_date}
            provider={provider}
        >
            <View style={styles.section} wrap={false}>
                <View>
                    <Text style={[styles.h2, styles.textTransform, styles.bold]}>Prestation de maintenance et de réparation souhaitée</Text>
                </View>
                <View style={styles.grid}>
                    <View style={styles.sectionLeft}>
                        <Text style={styles.h3}>{formation?.label}</Text>
                        {request?.request_description?.bikesQuantity &&
                            <DataViewPDF
                                label="Nombre de vélos"
                                value={request?.request_description?.bikesQuantity + ' vélo(s)'}
                                styles={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: 200 }}
                            />
                        }
                        {request?.request_description?.sessionsQuantity &&
                            <DataViewPDF
                                label="Nombre de sessions"
                                value={request?.request_description?.sessionsQuantity + ' session(s)'}
                                styles={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: 200 }}
                            />
                        }
                        <DataViewPDF
                            label="Montant de la prestation"
                            value={(request?.request_description?.amount || totalPrice || ' - ') + '€'}
                            styles={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: 200 }}
                        />
                    </View>
                    <View style={styles.sectionRight}>
                        {/* Illustration */}
                    </View>
                </View>
            </View>
        </RequestPDF>
    )
}

TechnicalServicePDF.propTypes = monitoringPDFpropTypes

export default TechnicalServicePDF