import React from 'react'

const DrawerContext = React.createContext({
    component: null,
    props: {},
    showDrawer: () => {},
    hideDrawer: () => {}
})

const useDrawer = () => React.useContext(DrawerContext)

const DrawerProvider = ({ children }) => {
    const [component, setComponent] = React.useState(null)

    // The drawer component is an hidden vertical menu that can coontain any component.
    // When the `showDrawer` is called with a component as argument, the Drawer is open
    // and the given component is inserted in it.
    const showDrawer = (_component, _componentProps) => {
        setComponent(React.createElement(_component, _componentProps))
        // Side effect
        document && document.querySelector('body').classList.add('u-overflow-hidden')
    }

    // The `hideDrawer` method rmove the previous component and this close the Drawer.
    const hideDrawer = () => {
        setComponent(null)
        // Side effect
        document && document.querySelector('body').classList.remove('u-overflow-hidden')
    }

    return (
        <DrawerContext.Provider value={{
            component, showDrawer, hideDrawer
        }}>
            {children}
        </DrawerContext.Provider>
    )
}

export { useDrawer, DrawerProvider }
