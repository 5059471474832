import DangerIcon from 'components/svg/Danger'

const BidModalCancelBody = () => (
    <div className="u-text-color">
        <p className="u-flex u-flex-center-vt u-mg-bottom-s">
            <DangerIcon size={20} className="u-mg-right-s" />
            <span className="u-bold">Attention</span>
        </p>
        <p className="u-mg-bottom-s">
            L'annulation de cette prestation sera définitive
        </p>
        <p className="u-mg-bottom-s">
            Le prestataire sollicité sera automatiquement informé de l'annulation de la demande de prestation.
        </p>
    </div>
)

export default BidModalCancelBody