import * as UtilsApi from 'services/api/UtilsApi'

const uploadFile = async (name, fileToUpload) => {
    return new Promise(async (resolve, reject) => {
        try {
            if(fileToUpload.full_media_url) {
                resolve({ name, fileAlreadyUploaded: fileToUpload })
                return
            }
            const S3url = await UtilsApi.getS3Url(`${fileToUpload?.id}.${fileToUpload?.extension}`)
            if(!S3url || !S3url?.url || !fileToUpload?.blob) {
                throw new Error('Une erreur est survenu pendant l\'envoi du document')
            }

            // 2 - put picture
            // issue with axios / blob / header authorization
            // https://github.com/axios/axios/issues/1569
            const fileUploaded = await fetch(
                S3url.url,
                {
                    method: 'PUT',
                    headers: { 'content-type': '' },
                    body: fileToUpload.blob,
                }
            )
            resolve({ name, fileToUpload, fileUploaded })
        } catch (error) {
            reject(error)
        }
    })
}

export default uploadFile