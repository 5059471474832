import React from 'react'
import { array, number, object, shape, string } from 'prop-types'

import { useAlert } from 'hooks/useAlert'

import {
    getFeedbackToTrainingBeneficiary, 
    isBetweenStatus, 
    SIGN_OFF_SHEETS_UPLOADED,
    VALIDATED_BID,
    isCancelled,
    getLastEmargementsOfHistory,
    getTechnicalInformations,
    getQuantityOfTechnicalRequestsFromHistory,
    BACK_OFFICE_OPERATIONS
} from 'utils/domain/request'

import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'
import { downloadRequestPDF } from 'components/catalog/catalogUtils'
import TechnicalServicePDF from 'components/catalog/Technical/TechnicalServicePDF'
import { isNotEmpty } from 'utils/validation'
import dateService from 'services/date'

import Request from '../Request'

const Technical = ({ request, beneficiary }) => {
    const { addAlert } = useAlert()

    const isRequestCancelled = isCancelled(request?.status)
    const isDisplayEmargements = isBetweenStatus({ status: request?.status, start: SIGN_OFF_SHEETS_UPLOADED }) && !isRequestCancelled
    const isBidValidated = isBetweenStatus({ status: request.status, start: VALIDATED_BID }) && !isRequestCancelled

    const emargements = getLastEmargementsOfHistory(request.history)
    const quantity = getQuantityOfTechnicalRequestsFromHistory(request.history)
    const technicalInformations = getTechnicalInformations(request.request_description.type)

    const requestInitEvent = request.history.find(event => event?.request_data?.operation_code === BACK_OFFICE_OPERATIONS.INIT)
    const requestedDate = isNotEmpty(requestInitEvent) ? dateService.formatDate(requestInitEvent?.history_date) : ''

    const handleDownloadTechnicalPDF = async () => {
        const { error } = await downloadRequestPDF({
            user: beneficiary,
            request,
            provider: request.provider,
            pdfTemplate: TechnicalServicePDF,
            readRequestProviders: false,
            isFromBackOffice: true
        })

        if (error) addAlert('error', "Une erreur s'est produite lors de la génération de la demande. Veuillez réessayer.")
    }
    
    return (
        <Request
            request={{
                fubId: request.request_fub_id,
                label: technicalInformations?.label,
                statusLabel: getFeedbackToTrainingBeneficiary(request.status, { adminView: true }),
                status: request.status,
                provider: request.provider_data.structureName,
                requestDate: requestedDate ?? ''
            }}
            bonus={{
                isDisplay: isBidValidated,
                amount: request.bonus,
                realBonus: request?.realBonus
            }}
            bidDocuments={{
                isDisplay: request.bidDocuments.isUploaded,
                bill: request?.bidDocuments?.bill,
                proofOfPayment: request?.bidDocuments?.proofOfPayment,
            }}
            pdfOverview= {{
                isDisplay: true,
                title: 'Récapitulatif de la demande',
                onClick: handleDownloadTechnicalPDF
            }}
        >
            {isBidValidated && quantity?.bikesQuantity && (
                <Request.Detail>
                    <div className="u-mg-right-xl">
                        <span className="u-grey90">Nombre de vélo(s) : </span>
                        <span className="u-primary">{quantity?.bikesQuantity}</span>
                    </div>
                </Request.Detail>
            )}
            {isDisplayEmargements && (
                <Request.Documents title="Feuille(s) d'émargment(s)">
                    {emargements.map((emargement, index) => (
                        <InformationToVisualize 
                            key={`emargement-${index + 1}`} 
                            className="u-mg-right-xl"
                            label={`Feuille d'émargement ${index + 1}`} 
                            url={emargement.full_media_url} 
                            iconColor="green"
                            iconSize={30}
                        />
                    ))}
                </Request.Documents>
            )}
        </Request>
    )
}

Technical.propTypes = {
    request: shape({
        status: number.isRequired,
        request_fub_id: string.isRequired,
        history: array.isRequired,
        bidDocuments: shape({
            bill: shape({ full_media_url: string }),
            proofOfPayment: shape({ full_media_url: string })
        }),
        request_description: shape({
            type: string.isRequired
        })
    }),
    beneficiary: object
}

export default Technical