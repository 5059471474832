import React from 'react'

const Step2 = () => (
    <div className="u-pd-top-xl">
        Une <span className="u-primary u-bold">communication régulière et diversifiée</span>, dans sa forme et son fond, incite à oser le vélo, changer ses habitudes et amplifier les bonnes pratiques. 
        Communiquer sur l'usage du vélo peut être réalisé par :
        <ul className="u-pd-left-l u-mg-top-m u-mg-bottom-m">
            <li>
                la mise à disposition <span className="u-secondary u-bold">d'informations</span>  (guide spécifique vélo, pages intranet, affichage sur le site...),
            </li>
            <li>
                l'<span className="u-secondary u-bold">envoi régulier de messages</span> (mail, lettre d'information...),
            </li>
            <li>
                l'organisation d'<span className="u-secondary u-bold">activités</span> (défi mobilité...), 
            </li>
            <li>
                ou l'<span className="u-secondary u-bold">animation d'une communauté</span> permettant de s'entraider et d'échanger des conseils.
            </li>
        </ul>
          
        Pour qu'un établissement soit labellisé pro-vélo, la communication doit au moins porter sur les bienfaits de la pratique du vélo et sur les aspects sécurité.
        L'incitation passe aussi par des mesures financières comme le Forfait Mobilités Durables dont la mise en place n'est pas obligatoire mais néanmoins fortement conseillée (le FMD consiste en la prise en charge par l’employeur, facultative et exonérée, des frais de transports domicile-travail pour le covoiturage, le vélo, les tickets de transports en commun à l'unité…s'ajoutant au remboursement de la moitié de l'abonnement aux transports en commun).
    </div> 
)

export default Step2

