import React from 'react'
import { oneOf } from 'prop-types'

import { STEP_1, STEP_2, STEP_3, STEP_4, STEP_5 } from 'pages/protected/Autodiag/context'

import Step1 from 'pages/protected/Autodiag/Steps/Description/Step1'
import Step2 from 'pages/protected/Autodiag/Steps/Description/Step2'
import Step3 from 'pages/protected/Autodiag/Steps/Description/Step3'
import Step4 from 'pages/protected/Autodiag/Steps/Description/Step4'
import Step5 from 'pages/protected/Autodiag/Steps/Description/Step5'


const descriptionsStep = {
    [STEP_1]: Step1,
    [STEP_2]: Step2,
    [STEP_3]: Step3,
    [STEP_4]: Step4,
    [STEP_5]: Step5,
}

const Description = ({ step }) => React.createElement(descriptionsStep[step])

Description.propTypes = {
    step: oneOf([
        STEP_1,
        STEP_2,
        STEP_3,
        STEP_4,
        STEP_5 
    ])
}

export default Description