import React from 'react'

import dateService from 'services/date'
import { useAutoDiag } from 'pages/protected/Autodiag/context'

const Restitution = () => {

    const { getLastAutoDiagCompleted, getQuestionsByAutoDiagId, getRestitutionScore, getScoreExplanation } = useAutoDiag()

    const autodiag = getLastAutoDiagCompleted()
    const questions = getQuestionsByAutoDiagId(autodiag?.diag_id)
    const score = getRestitutionScore(questions)

    const getMandatoryRequirementMetScoreRender = () => {
        if(!score.mandatoryRequirementMetScore?.toString()) {
           return <div className="c-spinner"></div>
        }

        if(score.mandatoryRequirementMetScore === 1) {
            return (
                <>
                    Vous <span className="u-green90">validez 1 critère</span> obligatoire sur 8
                </>
            )
        }

        return (
            <>
                Vous <span className="u-green90">validez {score.mandatoryRequirementMetScore} critères</span> obligatoires sur 8
            </>
        )
    }

    return (
        <div className="u-flex u-flex-dir-col u-center">
            <div className="u-bold u-primary u-mg-bottom-l">
                Votre autodiagnostic employeur pro-vélo du {dateService.formatDate(autodiag.modify_date)}
            </div>
            <div className="restitution-result u-pd-l u-bold u-primary u-mg-bottom-xl">
                {getMandatoryRequirementMetScoreRender()}
            </div> 
            <p className="u-primary u-fs-s u-mg-bottom-l">
                {getScoreExplanation(score)}
            </p>
            <p className="u-fs-s u-mg-bottom-l">
                Prenez connaissance des commentaires proposés pour chaque question, ils contiennent des conseils pour vous faire progresser dans votre démarche pro-vélo. Nous vous conseillons aussi la lecture du livre blanc <a href="https://employeurprovelo.fr/wp-content/uploads/2021/06/LivreBlanc_EmployeursProVelo_Light.pdf" target="_blank" rel="noreferrer" className="u-underline u-primary">"devenir un employeur pro vélo"</a>.
            </p>
            <a
                className="c-btn c-btn--primary u-mg-top-xl u-mg-bottom-xl"
                target="_blank" rel="noopener noreferrer"
                href={autodiag?.diag_data?.documents?.[0]?.full_media_url}
            >
                Consulter les résultats de l'autodiagnostic
            </a>
        </div>
    )
}

export default Restitution