import { useAlert } from 'hooks/useAlert'

import {
    getRequestHistory as getBeneficiaryRequestHistory,
    getBackOfficeRequestHistory
} from 'services/api/CatalogApi'
import { REQUEST_REGULARISATION_VALIDATED, REQUEST_REGULARISATION_PAID } from 'utils/domain/request'

import {
    isValidated,
    isEngaged,
    isEquipmentFolder,
    REQUEST_EQUIPMENTS_BONUS_PERCENTAGE,
    REQUEST_EQUIPMENTS_VALUE,
    REQUEST_MOBILITY_BONUS_PERCENTAGE,
    REQUEST_MOBILITY_VALUE,
    REQUEST_SUPPORT_BONUS_PERCENTAGE,
    REQUEST_SUPPORT_VALUE,
    REQUEST_TECHNICAL_BONUS_PERCENTAGE,
    REQUEST_TECHNICAL_VALUE,
    VALIDATED_BID,
    CANCEL,
    REQUEST_REFUSED,
    getBackOfficeListStatus
} from 'utils/domain/request'
import { requestGenderList } from 'utils/constants'
import { employeeCategory, isInZNIList } from 'utils/domain/beneficiary'

import { formationList as technicalServiceList } from 'components/catalog/Technical/list'
import { formationList as mobilityHelpList } from 'components/catalog/MobilityHelp/list'
import { formationList as adviceAndSupportList } from 'components/catalog/AdviceAndSupport/list'

const getTechnicalAmount = (request) => {
    const formation = technicalServiceList.find(f => f.id === request?.request_description?.type)

    return request?.request_description?.bikesQuantity
        ? request?.request_description?.bikesQuantity * formation?.unitPrice
        : request?.request_description?.sessionsQuantity * formation?.unitPrice
}

const getMobilityHelpAmount = (request) => {
    const formation = mobilityHelpList.find(f => f.id === request?.request_description?.type)

    return request?.request_description?.personsQuantity
        ? request?.request_description?.personsQuantity * formation?.unitPrice
        : request?.request_description?.sessionsQuantity * formation?.unitPrice
}

const getAdviceAndSupportAmount = (request, beneficiaryEmployeeQuantity) => {
  const formation = adviceAndSupportList.find(f => f.id === request?.request_description?.type)
  const price = formation?.id === 'cycling-strategy' && beneficiaryEmployeeQuantity >= 250 ? formation?.maxUnitPrice : formation?.unitPrice

  return price
}

/**
 * Sort by request gender (the type of benefit) and by validated status or not
 * validated status appear first in its gender category
 * Ex: 1|EQU|Validé, 2|EQU|Engagé, 3|MOB|Validé, 4|MOB|Validé, 5|MOB|Engagé, ...
 */
const sortRequest = (requestA, requestB) => {
    const validatedScoreA = isValidated(requestA.status) ? 0 : 1
    const scoreA = (requestA.gender * 10) + validatedScoreA

    const validatedScoreB = isValidated(requestB.status) ? 0 : 1
    const scoreB = (requestB.gender * 10) + validatedScoreB

    return (scoreA > scoreB) ? 1 : ((scoreB > scoreA) ? -1 : 0)
}

const useBonus = ({ isFromBackOffice } = { isFromBackOffice: false }) => {
    const { addAlert } = useAlert()

    const getRequestHistory = isFromBackOffice ? getBackOfficeRequestHistory : getBeneficiaryRequestHistory

    const calculateBonus = (postCode, category) => {
        const isZNI = isInZNIList(postCode)
        switch (category) {
            case employeeCategory[0].value:
                return isZNI ? 5000 : 4000
            case employeeCategory[1].value:
                return isZNI ? 7500 : 6000
            case employeeCategory[2].value:
                return isZNI ? 10000 : 8000
            case employeeCategory[3].value:
                return isZNI ? 40000 : 32000
            default:
                return null
        }
    }

    const getAvailableBonus = (beneficiaryEntityData) => {

        if (!beneficiaryEntityData) {
            return null
        }

        if (beneficiaryEntityData?.prime?.toString()) {
            return beneficiaryEntityData?.prime
        }

        return calculateBonus(
            beneficiaryEntityData?.structurePostCode,
            beneficiaryEntityData?.structureEmployeeCategory
        )
    }

    const getEquipmentAmount = async (request) => {
        const amount = request.request_data.operation_data.amount
        if (amount) return amount

        // After the validation of the bid amount by the beneficiary, this amount is not anymore available in backend
        return request.status > VALIDATED_BID ? await getEquipmentAmountAfterValidation(request?.request_id) : 0
    }

    const getEquipmentAmountAfterValidation = async (requestId) => {
        try {
            const { res: requestHistory, getError } = await getRequestHistory(requestId)
            if (getError()) throw getError()

            const amount = requestHistory.find(request => request.status === VALIDATED_BID)?.request_data?.operation_data?.amount
            return amount || 0
        } catch (error) {
            console.error(error?.toString())
            addAlert('error', error?.toString())
        }
        return 0
    }

    const getRequestAmount = async (request, beneficiaryEmployeeQuantity) => {
        if (request.request_description?.amount) return request.request_description.amount

        switch (request.gender) {
            case REQUEST_EQUIPMENTS_VALUE:
                return await getEquipmentAmount(request)
            case REQUEST_TECHNICAL_VALUE:
                return getTechnicalAmount(request)
            case REQUEST_MOBILITY_VALUE:
                return getMobilityHelpAmount(request)
            case REQUEST_SUPPORT_VALUE:
                return getAdviceAndSupportAmount(request, beneficiaryEmployeeQuantity)
            default:
                return 0
        }
    }

    const getRequestBonus = (request, amount = 0) => {
        // The request has been validated by the BO and has an amount_to_refund field set by him
        const amountToRefund = request.request_data?.operation_data?.amount_to_refund
        if (amountToRefund) return amountToRefund

        // The request has not been validated yet by the BO, the bonus equals a percent of the amount
        switch (request.gender) {
            case REQUEST_EQUIPMENTS_VALUE:
                return amount * REQUEST_EQUIPMENTS_BONUS_PERCENTAGE
            case REQUEST_TECHNICAL_VALUE:
                return amount * REQUEST_TECHNICAL_BONUS_PERCENTAGE
            case REQUEST_MOBILITY_VALUE:
                return amount * REQUEST_MOBILITY_BONUS_PERCENTAGE
            case REQUEST_SUPPORT_VALUE:
                return amount * REQUEST_SUPPORT_BONUS_PERCENTAGE
            default:
                return 0
        }
    }

    const getRequestsWithBonus = async (requests, beneficiaryEmployeeQuantity) => {
        const availableRequests2 = []
        for (let index = 0; index < requests.length; index++) {
            const request = requests[index];
            const requestAmount = await getRequestAmount(request, beneficiaryEmployeeQuantity)
            const oneRequest = request
            oneRequest.genderLabel = request?.genderLabel ?? requestGenderList.find(gender => gender.value === request.gender)?.prestation
            oneRequest.amount = Math.round(requestAmount)
            oneRequest.bonus = Math.round(getRequestBonus(request, requestAmount))
            oneRequest.realBonus = request?.request_data?.operation_data?.real_amount_to_refund
            oneRequest.statusLabel = getBackOfficeListStatus(request.status, request.request_fub_id)
            availableRequests2.push(oneRequest)
        }
        /*const availableRequests = await Promise.all(
            requests.map(async request => {
                const requestAmount = await getRequestAmount(request)

                return {
                    ...request,
                    genderLabel: request?.genderLabel ?? requestGenderList.find(gender => gender.value === request.gender)?.prestation,
                    amount: Math.round(requestAmount),
                    bonus: Math.round(getRequestBonus(request, requestAmount)),
                    realBonus: request?.request_data?.operation_data?.real_amount_to_refund,
                    statusLabel: getBackOfficeListStatus(request.status, request.request_fub_id)
                }
            })
        )*/

        return availableRequests2.filter(({ amount, bonus, status, quote_status, request_fub_id }) => {
            // Solve a case that is not handled on the back end side
            if (isEquipmentFolder(request_fub_id) && quote_status?.toString() && quote_status !== 3) {
                return false
            }

            return !Number.isNaN(amount) && !Number.isNaN(bonus) && ![CANCEL, REQUEST_REFUSED].includes(status)
        })
            .sort(sortRequest)
    }

    const getBonusByRequests = ({ requests, userEntityData, beneficiary }) => {
        const serviceRequests = requests.filter(({ genderLabel }) => genderLabel !== 'Régularisation')
        const regularisationRequests = requests.filter(({ genderLabel }) => genderLabel === 'Régularisation')

        const regularisationAmountValidated = regularisationRequests
            .filter(({ regularisation_status }) => regularisation_status === REQUEST_REGULARISATION_VALIDATED)
            .reduce((acc, request) => acc + request?.real_amount_to_refund, 0)
        const regularisationAmountPaid = regularisationRequests
            .filter(({ regularisation_status }) => regularisation_status === REQUEST_REGULARISATION_PAID)
            .reduce((acc, request) => acc + request?.real_amount_to_refund, 0)

        const regularisationAmount = regularisationAmountValidated + regularisationAmountPaid

        const availableBonus = getAvailableBonus(userEntityData)
        const consumedBonus = serviceRequests.reduce((acc, request) => acc + (request?.realBonus ?? request?.bonus), 0) + regularisationAmount
        const totalAmount = serviceRequests.reduce((acc, request) => acc + request.amount, 0) + regularisationAmount

        const totalAmountEngaged = serviceRequests.filter(request => isEngaged(request.status))
            .reduce((acc, request) => acc + (request?.realBonus ?? request?.bonus), 0)
        // NB: regularisation is automatically validated
        const totalAmountValidated = beneficiary?.amount_claimed - beneficiary?.amount_refunded

        const isPrimeTotallyConsumed = consumedBonus >= availableBonus
        const assignedBonus = isPrimeTotallyConsumed ? availableBonus : consumedBonus
        const remainingBonus = availableBonus - assignedBonus

        return {
            totalAmount,
            totalAmountValidated,
            totalAmountEngaged,
            availableBonus,
            consumedBonus,
            isPrimeTotallyConsumed,
            assignedBonus,
            remainingBonus,
            regularisationAmount
        }
    }


    return { calculateBonus, getRequestAmount, getRequestBonus, getRequestsWithBonus, getBonusByRequests }
}

export default useBonus