import { number, string, oneOf } from 'prop-types'
import React from 'react'

import { DEFAULT_SIZE, DEFAULT_COLOR } from 'utils/icons'

const InfoIcon = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR, className }) => {
	return (
        <svg style={{ width: size, height: size }} fill={color} className={className}>
            <path 
                d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5zm1.395 8.382l-1.502 7.057c-.106.51.044.8.457.8.29 0 .729-.105 1.029-.369l-.133.624c-.43.519-1.38.897-2.197.897-1.055 0-1.503-.633-1.212-1.978l1.107-5.203c.096-.439.009-.598-.432-.703l-.675-.124.123-.57 3.435-.431zM12 5.25a1.5 1.5 0 110 3 1.5 1.5 0 010-3z" 
                fill={color}
                fillRule="evenodd"
            />
        </svg>
	)
}

InfoIcon.propTypes = {
    size: oneOf([string, number]),
    color: string,
    className: string,
}

export default InfoIcon
