import generatePassword from 'password-generator'
import flow from 'lodash.flow'

import { jsonHash } from 'utils/hash'
import { isPasswordValid } from './validation'

export const flattenObject = (obj) => {
    const flattenedObject = {}
    Object.keys(obj).forEach(key => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(flattenedObject, flattenObject(obj[key]))
        }
        else {
            flattenedObject[key] = obj[key]
        }

    })
    return flattenedObject
}

export const toCapitalizeCase = (str) => {
    const trimStr = str ? str.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''
    return trimStr
}

export const removeSpaces = (value, replacementChar = '') => {
    const cleanValue = value ? value.toString().replaceAll(/\s+/g, replacementChar) : ''
    return cleanValue
}

export const objectsHaveDiverged = (obj1, obj2, propertiesArray) => {
    // Calculate an hash of obj1 properties
    const obj1hash = jsonHash({
        ...flow([
            Object.entries,
            arr => arr.filter(([key, value]) => propertiesArray ? propertiesArray.includes(key) : true),
            Object.fromEntries
        ])(obj1),
    })
    // Calculate an hash of obj2 properties
    const obj2Hash = jsonHash({
        ...flow([
            Object.entries,
            arr => arr.filter(([key, value]) => propertiesArray ? propertiesArray.includes(key) : true),
            Object.fromEntries
        ])(obj2),
    })
    // return if the hashes are different (true) or are the same (false)
    return !(obj1hash === obj2Hash)
}

export const normalizeBoolean = value => {
    if(typeof value === 'boolean') return value
    if (['true', 'oui'].includes(value?.toLowerCase())) return true
    if (['false', 'non'].includes(value?.toLowerCase())) return false
    return Boolean(value)
}

export const getValidPassword = (length) => {
    let passwordGenerated = ''
    while (!isPasswordValid(passwordGenerated)) {
        passwordGenerated = generatePassword(length, false, /^[a-zA-Z0-9]+$/)
    }
    return passwordGenerated
}