export const GENDER_PROVIDER_VALUE = 500

export const EQUIPMENT_PROVIDER_ID = 'equipment'
export const GENERIC_PROVIDER_ID = 'services-provider'

export const MOBILITY_PROVIDER_ID = 'mobility'
export const SCOPING_MEETING_PROVIDER_ID = 'scopingMeeting'
export const ADVICE_AND_SUPPORT_ID = 'adviceAndSupport'
export const AUDIT_PROVIDER_ID = 'audit'

export const provider = {
    value: GENDER_PROVIDER_VALUE
}

export const equipmentProvider = {
    ...provider,
    label: "Fournisseur d'équipements",
    role: EQUIPMENT_PROVIDER_ID,
    id: EQUIPMENT_PROVIDER_ID,
    serviceId: 'EQU'
}
export const mobilityProvider = {
    ...provider,
    label: 'Prestataire de services',
    role: MOBILITY_PROVIDER_ID,
    id: MOBILITY_PROVIDER_ID,
    serviceId: 'MOB'
}
export const supportProvider = {
    ...provider,
    label: 'Prestataire de conseil et accompagnement',
    role: ADVICE_AND_SUPPORT_ID,
    id: ADVICE_AND_SUPPORT_ID,
    serviceId: 'AEC'
}
export const scopingMeetingProvider = {
    ...provider,
    label: 'Intervenant réunion de cadrage',
    role: SCOPING_MEETING_PROVIDER_ID,
    id: SCOPING_MEETING_PROVIDER_ID,
    serviceId: 'RCA'
}
export const auditProvider = {
    ...provider,
    label: 'Intervenant d\'audit',
    role: AUDIT_PROVIDER_ID,
    id: AUDIT_PROVIDER_ID,
    serviceId: 'AUD'
}

export const correspondingProviders = {
    [EQUIPMENT_PROVIDER_ID]: equipmentProvider,
    [MOBILITY_PROVIDER_ID]: mobilityProvider,
    [ADVICE_AND_SUPPORT_ID]: supportProvider,
    [SCOPING_MEETING_PROVIDER_ID]: scopingMeetingProvider,
    [AUDIT_PROVIDER_ID]: auditProvider,
}

export const providersList = [
    equipmentProvider,
    mobilityProvider,
    supportProvider,
    scopingMeetingProvider,
    auditProvider
]

export const servicesEnum = providersList.reduce((acc, provider) => (
    { ...acc, [provider.id]: provider.serviceId }
), {})