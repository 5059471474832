import { number, string } from 'prop-types'
import React from 'react'
import { DEFAULT_COLOR, DEFAULT_SIZE } from 'utils/icons'

const DownloadIcon = ({ size = DEFAULT_SIZE, className, color = DEFAULT_COLOR }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height={size} width={size} viewBox="0 0 512 512" style={{ width: size, height: size }} className={className || ''} aria-hidden="true">
			  <path fill="currentColor" d="M409.785 278.5L256 432.285 102.215 278.5l28.285-28.285 105.5 105.5V0h40v355.715l105.5-105.5zM512 472H0v40h512zm0 0"/>
		</svg>
	)
}

DownloadIcon.propTypes = {
	size: number,
	className: string,
	color: string
}

export default DownloadIcon
