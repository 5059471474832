import React from 'react'

const FiltersMemoryContext = React.createContext({
    getSavedFilter: () => {},
    saveFilter: () => {},
    clearAllFilters: () => {},
    clearAllFiltersForPage: () => {}
})

const useFiltersMemory = () => React.useContext(FiltersMemoryContext)

const FiltersMemoryProvider = ({ children }) => {
    const [filterPersist, setFilterPersist] = React.useState({})

    const getSavedFilter = (page, id) => {
        if(filterPersist[page]) {
            return filterPersist[page][id]
        }
        return undefined
    }

    const saveFilter = (page, id, value) => {
        const updatedDict = { ...filterPersist }
        if (!updatedDict[page]) {
            updatedDict[page] = {}
          }
        updatedDict[page][id] = value
        setFilterPersist(updatedDict)
    }

    const clearAllFilters = () => {
        setFilterPersist({})
    }

    const clearAllFiltersForPage = (page) => {
        const updatedDict = { ...filterPersist }
        updatedDict[page] = {}
        setFilterPersist(updatedDict)
    }

    return (
        <FiltersMemoryContext.Provider value={{
            getSavedFilter, saveFilter, clearAllFilters, clearAllFiltersForPage
        }}>
            {children}
        </FiltersMemoryContext.Provider>
    )
}

export { useFiltersMemory, FiltersMemoryProvider }