import { convertDataToStringFromArray } from 'utils/downloadFile'
// ------------------------------- //
//             Export
// ------------------------------- //

export const getEquipmentsToExport = (folders) => {
  const equipmentsHeader = [
    'ID',
    'SIRET fournisseur',
    'Nom fournisseur',
    'Catégorie',
    'Equipement',
    'Dénomination',
  ]

  const getEquipmentsRows = (folders) => {
    return folders.map(folder => {

      const folderRow = [
        folder.equipments_supply_id,
        folder.siret,
        folder.supplier_name,
        folder.category,
        folder.equipment,
        folder.denomination,
      ]
      return convertDataToStringFromArray(folderRow) //allows consistent alignment in excel column
    })
  }

  // prepare data
  return [
      // Header array
      equipmentsHeader,
      ...getEquipmentsRows(folders)
  ]
}