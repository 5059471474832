import React from 'react'
import { Helmet } from 'react-helmet-async'

const Seo = () => {

    const title = 'OEPV'
    const defaultTitle = 'Objectif Employeur Pro-Vélo'
    const defaultDescription = 'Le Programme Objectif Employeur Pro-Vélo (OEPV) vise à accompagner les employeurs dans le développement d’une culture vélo.'
    const url = 'https://app-employeurprovelo.fr/'
    const logo = 'public/logo-oepv.png'
 
    return (
        <Helmet>
            <title>{defaultTitle}</title>
            <meta name="description" content={defaultDescription} />

            <meta property="og:title" content={defaultTitle} />
            <meta property="og:description" content={defaultDescription} />
            <meta property="og:image" content={url + logo} />
            <meta property="og:site_name" content={title} />
            <meta property="og:locale" content="fr_FR" />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={title} />
            <meta name="twitter:domain" content={url} />
            <meta name="twitter:title" content={defaultTitle} />
            <meta name="twitter:description" content={defaultDescription} />
            <meta name="twitter:image" content={url + logo} />
            <meta name="twitter:url" content={url} />
        </Helmet>
    )
}

export default Seo