import React, { useState } from 'react'
import { node } from 'prop-types'

import * as CatalogApi from 'services/api/CatalogApi'
import { useAlert } from 'hooks/useAlert'

import { getBeneficiary } from 'utils/domain/admin'
import { 
    BACK_OFFICE_OPERATIONS, 
    DOCUMENTS, 
    getHelpMobilityType, 
    getLastEmargementsOfHistory, 
    getLastInterventionOfHistory, 
    getTrainingInformation
} from 'utils/domain/request'
import { isNotEmpty } from 'utils/validation'

const ServiceFolderContext = React.createContext({
    request: {},
    beneficiary: {},
    setRequest: () => {},
    setBeneficiary: () => {},
    fetchRequest: () => {}
})

export const useServiceFolder = () => React.useContext(ServiceFolderContext)

const ServiceFolderProvider = ({ children }) => {
    const [request, setRequest] = useState({})
    const [beneficiary, setBeneficiary] = useState({})
    const { addAlert } = useAlert()

    const getRequestHistory = async(requestId) => {
        const { res: requestHistoryResponse, getError } = await CatalogApi.getBackOfficeRequestHistory(requestId)
        
        if (getError()) throw getError()
        // On remonte les events de type PAYREQUEST. Le statut 72 peut avoir ou non des documents. On filtre donc sur l'event où ils sont présents
        const lastDocumentsUploaded = requestHistoryResponse?.find(stack => 
            stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.BID.UPLOAD_BY_BENEFICIARY && isNotEmpty(stack.request_data.operation_data?.documents)
        )

        const lastAskMoreInformation = requestHistoryResponse?.find(stack => 
            stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.BID.ASK_MORE_INFORMATIONS
        )

        const requestInit = requestHistoryResponse?.find(stack => 
            stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.INIT
        )

        const bill = lastDocumentsUploaded?.request_data?.operation_data.documents?.find(
            document => document.media_gender === DOCUMENTS.bill
        )

        const payment_proof = lastDocumentsUploaded?.request_data?.operation_data.documents?.find(
            document => document.media_gender === DOCUMENTS.payment_proof
        )

        const provider = await getProviderSelected(requestHistoryResponse)

        const sessionsQuantity = lastAskMoreInformation?.request_data?.operation_data?.sessionsQuantity ||
            requestInit?.request_data?.operation_data?.request_description?.sessionsQuantity 

        const personsQuantity = lastAskMoreInformation?.request_data?.operation_data?.personsQuantity || 
            requestInit?.request_data?.operation_data?.request_description?.personsQuantity
        

        const amount_to_refund = lastAskMoreInformation?.request_data?.operation_data?.amount_to_refund

        return {
            amount_to_refund: amount_to_refund,
            emargements: getLastEmargementsOfHistory(requestHistoryResponse),
            intervention: getLastInterventionOfHistory(requestHistoryResponse),
            provider, 
            bill,
            payment_proof,
            sessionsQuantity,
            personsQuantity
        }
    }

    const getProviderSelected = async (requestHistoryResponse) => {
        const providerStack = requestHistoryResponse.filter(stack => 
            stack.request_data.operation_code === BACK_OFFICE_OPERATIONS.PROVIDER
        )[0]
        
        if(providerStack?.request_data?.operation_data?.providers?.length) {
            const providerId = providerStack?.request_data?.operation_data?.providers[0]
            const { res: provider, getError } = await CatalogApi.getBackOfficeEntity(providerId)
        
            if (getError()) throw getError()
            
            return provider ? provider?.entity_data : null
        }
        return null
    }

    const fetchRequest = async(id) => {
        try {
            const { res: requestResponse, getError } = await CatalogApi.getRequest(id || request?.request_fub_id)
            if (getError()) throw getError()  
            
            const lastUpdates = await getRequestHistory(requestResponse.request_id)
            const currentBeneficiary = await getBeneficiary(requestResponse.request_entity_id)

            setRequest({
                ...requestResponse,
                ...lastUpdates,
                ...getHelpMobilityType(requestResponse?.request_description?.type),
                trainingInformation: getTrainingInformation(requestResponse?.request_description?.type),
            })
            setBeneficiary(currentBeneficiary)

        } catch(error) {
            addAlert('error', error?.toString())
        }
    }

    return (
        <ServiceFolderContext.Provider value={{ fetchRequest, request, setRequest, beneficiary, setBeneficiary }}>
            {children}
        </ServiceFolderContext.Provider>
    )
}

ServiceFolderProvider.propTypes = {
    children: node
}

export default ServiceFolderProvider