import React from 'react'
import { privateRoutes } from 'router/routes'
import BackOfficeLayout from 'layouts/back-office'
import UsersInformations from 'components/back-office/UsersInformations'
import { beneficiaryList } from 'utils/domain/beneficiary'

const BeneficiariesAdministration = () => {

  const [privateBeneficiary, publicBeneficiary, associationBeneficiary, privateWithPublicVocationBeneficiary] = beneficiaryList

  const textPage = {
    title: 'Bénéficiaires'
  }

  const beneficiariesCriteria = {
    genders: [privateBeneficiary.value, publicBeneficiary.value, associationBeneficiary.value, privateWithPublicVocationBeneficiary.value],
    services: []
  }

  const detailPageRoute = privateRoutes.backOffice.beneficiaryDetailAdministration

  return (
    <BackOfficeLayout>
      <UsersInformations 
        textPage={textPage} 
        usersCriteria={beneficiariesCriteria} 
        detailPageRoute={detailPageRoute}
      />
    </BackOfficeLayout>
  )
}

export default BeneficiariesAdministration
