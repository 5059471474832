import React from 'react'
import { useTable, useFilters, usePagination, useGlobalFilter, useSortBy } from 'react-table'
import { arrayOf, bool, func, object, oneOfType, shape, string, number } from 'prop-types'
import SearchIcon from 'components/svg/Search'

import TableHeader from './TableHeader'
import TableBody from './TableBody'
import './styles.scss'
import Pagination from './Pagination'
import { useFiltersMemory } from 'hooks/useFiltersMemory'
import { isNotEmpty } from 'utils/validation'

const Table = ({
    columns,
    data,
    specificSortBy = [],
    isSortable = true,
    className = '',
    rowClassName = 'flex-row',
    hasSearchBar = true,
    hasFilters = false,
    isPaginable = false,
    pageSize = 10, // number of rows displayed
    paginationLength = 10, // number of clickable pages
    hiddenColumns = [''],
}) => {
    const sortBy = isSortable ? useSortBy : () => { }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        setGlobalFilter,
        setFilter,
        state: { pageIndex }
      } = useTable(
        {
          columns,
          data,
          initialState: { sortBy: specificSortBy, pageSize: pageSize, hiddenColumns: hiddenColumns },
        },
        useFilters,
        useGlobalFilter, // Ajoutez useGlobalFilter ici
        sortBy,
        usePagination
      )

    const {
        clearAllFilters
    } = useFiltersMemory()

    // Manage global search
    // -------------------
    const [dataTableFilter, setDataTableFilter] = React.useState('')
    React.useEffect(() => setGlobalFilter(dataTableFilter), [dataTableFilter, setGlobalFilter])

    const resetFilters = () => {
        setDataTableFilter('')
        clearAllFilters()
        // Réinitialiser les filtres pour chaque colonne
        headerGroups.forEach(headerGroup => {
            headerGroup.headers.forEach(column => {
                // Si la colonne est filtrable et que le filtre est défini
                if (column.filterable && isNotEmpty(column.filterValue)) {
                    setFilter(column.id, ['']) // Réinitialiser le filtre sur une valeur vide
                  }
            });
        });
    }

    return (
        <>
            <div className={'c-form-group c-form-group--picto u-mg-bottom-l'}>
                {hasSearchBar &&
                    <>
                        <SearchIcon size="25px" className="c-input-picto" color="#005a7c" />
                        <input
                            className={'c-input u-pd-left-xl'}
                            id={'tableglobalFilter'}
                            name={'tableglobalFilter'}
                            type={'text'}
                            placeholder={'Rechercher dans la liste'}
                            value={dataTableFilter}
                            style={{ maxWidth: '400px' }}
                            onChange={(event) => setDataTableFilter(event.target.value)}
                        />
                    </>
                }
            </div>
            {hasFilters && <button onClick={resetFilters} className="u-underline u-blue">Réinitialiser tous les filtres</button>}

            <table className={`table-container ${className}`} {...getTableProps()}>
                <TableHeader headerGroups={headerGroups} isSortable={isSortable} rowClassName={rowClassName} />
                <TableBody getTableBodyProps={getTableBodyProps} rows={isPaginable ? page : rows} prepareRow={prepareRow} rowClassName={rowClassName} />
            </table>
            {data?.length > 10 && isPaginable && <Pagination pageOptions={pageOptions} gotoPage={gotoPage} canPreviousPage={canPreviousPage} pageIndex={pageIndex} pageCount={pageCount} canNextPage={canNextPage} paginationLength={paginationLength} />}
        </>
    )
}

Table.propTypes = {
    columns: arrayOf(
        shape({
            Header: oneOfType([string, func]),
            accessor: oneOfType([string, func]),
        })
    ).isRequired,
    data: arrayOf(object),
    specificSortBy: arrayOf(
        shape({
            id: string,
            asc: bool,
            desc: bool
        })
    ),
    isSortable: bool,
    className: string,
    rowClassName: string,
    hasSearchBar: bool,
    isPaginable: bool,
    pageSize: number,
    paginationLength: number,
    hiddenColumns: arrayOf(string)
}

export default Table