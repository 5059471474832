import React from 'react'

import { useAlert } from 'hooks/useAlert'
import { useRequestFolder } from 'pages/back-office/Folders/RequestFolder/EquipmentFolder/context'
import FormElement from 'components/shared/FormElement'
import * as CatalogApi from 'services/api/CatalogApi'

import dateService from 'services/date'
import { isNotEmpty, isNotEmptyNumber } from 'utils/validation'
import { ADD_EQUIPMENT_STATUS, BIKE_TIES_LABEL } from './AdditionalEquipment'
import { equipmentList } from 'components/catalog/Equipment/list'


export const AddEquipment = ({ additionalEquipmentArray, providerEquipment }) => {
    const { addAlert } = useAlert()
    const { request, fetchRequestEquipments } = useRequestFolder()

    const defaultProviderList = { label: 'Choisissez une dénomination', value: 0 }
    const [isResetProviderDefaultValue, setIsResetProviderDefaultValue] = React.useState(false)

    const defaultFormFields = {
        date: new Date(),
        name: defaultProviderList.value,
        placeNumber: '',
        quantity: '',
        price: '',
        category: '',
        equipment: ''
    }

    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [errors, setErrors] = React.useState([])
    const [providerServicesList, setProviderServicesList] = React.useState()

    const providerNameSelected = providerEquipment?.find(equipment => equipment?.equipments_supply_id === formFields.name)
    const isBikeTiesEquipmentSeleted = formFields?.category === BIKE_TIES_LABEL ?? false

    // Nous devons renvoyer pour chaque équipement si celui-ci est optionnel ou non
    // Nous en aurons besoin lors du calcul de la prime
    const isAdditionalEquipment = Boolean(equipmentList.filter(i => i.optional)?.find(item => {
        return item?.shortLabel === formFields?.category
    })) ?? false

    React.useEffect(() => {
        // A partir du moment où la date est renseignée une fois, elle se complète de manière automatique pour les autres équipements renseignés sur les autres lignes
        if(isNotEmpty(additionalEquipmentArray)) {
            setFormFields(s => ({
                ...s,
                date: new Date(additionalEquipmentArray[0].date)
            }))
        }
    }, [additionalEquipmentArray]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        // La catégorie et l'équipement sont remplis automatiquement une fois la dénomination renseignée
        if(isNotEmpty(formFields.name) && formFields.name !== defaultProviderList.value) {
            setFormFields(s => ({
                ...s,
                category: providerNameSelected.category,
                equipment: providerNameSelected.equipment
            }))
        }
    }, [formFields.name]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if(isNotEmpty(providerEquipment)) {
            setProviderServicesList(() => [
                defaultProviderList,
                ...providerEquipment.map((item) => {
                    return {
                        value: item.equipments_supply_id,
                        label: item.denomination,
                    }
                }),
            ])
        }
    }, [providerEquipment]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleInputChange = (event) => {
        const target = event.target
        const name = target.name
        setFormFields(s => ({
            ...s,
            [name]: target.value
        }))
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const handleSubmit = async () => {
        // Validate inputs
        const formReady = !(errors.length > 0)

        if (!formReady) {
            setShowLocalErrors(true)
        }
        else {
            try {
                const payload = {
                    request_id: request.request_id,
                    equipments_supply_id: providerNameSelected.equipments_supply_id,
                    equipments_supply_data: {
                        date: dateService.formatDate(new Date(formFields.date), 'yyyy-MM-dd HH:mm:ss'),
                        equipment: formFields.equipment,
                        category: formFields.category,
                        name: providerNameSelected.denomination,
                        quantity: formFields.quantity,
                        ...(isNotEmptyNumber(formFields.placeNumber) ? { placeNumber: formFields.placeNumber } : {}),
                        price: formFields.price,
                        optional: isAdditionalEquipment,
                    },
                    status: ADD_EQUIPMENT_STATUS
                }
                const { getError } = await CatalogApi.editRequestEquipments(payload)
                if (getError()) throw getError()
                setFormFields(defaultFormFields) // On reset les champs
                setIsResetProviderDefaultValue(true)
                setShowLocalErrors(false)
                fetchRequestEquipments(request.request_id) // On fetch le tableau pour le mettre à jour
                setIsResetProviderDefaultValue(false)
            } catch (error) {
                addAlert('error', error?.toString())
            } 
        }
    }

    const formElementCommonProps = {
        className: 'u-mg-hz-s',
		showErrors: showLocalErrors,
		onValidate: handleValidation,
		onChange: handleInputChange,
	}

    const disabledButton = !isNotEmpty(providerNameSelected) && formFields.name === defaultProviderList.value

    return (
        <form className='u-bg-grey30 u-pd-m u-mg-bottom-s'>
            <h4 className='u-mg-hz-s u-fs-xs u-yellow'>Ajouter une ligne</h4>
            <div className='u-flex u-fs-xs'>
                <FormElement
                    {...formElementCommonProps}
                    value={formFields.date}
                    name="date"
                    type="date"
                    label="Date facture"
                    readonly={isNotEmpty(additionalEquipmentArray)}
                    required
                />

                <FormElement
                    {...formElementCommonProps}
                    value={formFields.name}
                    options={providerServicesList}
                    name="name"
                    type="select"
                    label="Dénomination"
                    required
                    className="u-width-250"
                    forceDefaultValue={isResetProviderDefaultValue}
                />

                {isBikeTiesEquipmentSeleted && <FormElement
                    {...formElementCommonProps}
                    value={formFields.placeNumber}
                    name="placeNumber"
                    type="number"
                    label="Nb de place"
                    className="u-mg-hz-s"
                    required
                />}

                <FormElement
                    {...formElementCommonProps}
                    value={formFields.quantity}
                    name="quantity"
                    type="number"
                    label="Quantité"
                    className="u-mg-hz-s"
                    required
                />

                <FormElement
                    {...formElementCommonProps}
                    value={formFields.price}
                    name="price"
                    type="number"
                    label="Prix unitaire HT"
                    required
                />
                <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={disabledButton}
                    className="c-btn c-btn--s c-btn--primary u-mg-m u-pd-s"
                >Ajouter</button>
            </div>

        </form>
    )
}
