import React from 'react'
import ProtectedLayout from 'layouts/Protected'
import MyAudits from './MyAudits'

const AuditHomePage = () => {
    return (
        <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main">
            <MyAudits />
        </ProtectedLayout>
    )
}

export default AuditHomePage