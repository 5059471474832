import React from 'react'
import { NavLink, useRouteMatch, useParams } from 'react-router-dom'
import { privateRoutes } from 'router/routes'
import './styles.scss'

const Breadcrumb = () => {
    const { beneficiaryId, requestId } = useParams()
    const matchHome = useRouteMatch(privateRoutes.backOffice.backOfficeHome.path)?.isExact
    const matchHomePilotage = useRouteMatch(privateRoutes.backOffice.participantsAdministration.path)?.isExact
    const matchEntityFolder = useRouteMatch(privateRoutes.backOffice.entityFolder.path)?.isExact
    const matchRequestFolder = useRouteMatch(privateRoutes.backOffice.requestFolder.path)?.isExact
    
    const matchBeneficiaryAdministration = useRouteMatch(privateRoutes.backOffice.beneficiariesAdministration.path)
    const matchParticipantAdministration = useRouteMatch(privateRoutes.backOffice.participantsAdministration.path)
    const matchEquipmentProviderAdministration = useRouteMatch(privateRoutes.backOffice.providersAdministration.path)
    const matchFolder = matchEntityFolder || matchRequestFolder || matchParticipantAdministration || matchBeneficiaryAdministration || matchEquipmentProviderAdministration

    const id = requestId || beneficiaryId

    return ( 
        <div className="breadcrumb">
            {matchFolder && (
                <NavLink
                    to={privateRoutes.backOffice.backOfficeHome.path} className='text'
                    activeClassName='is-active' isActive={() => matchHome}
                    onClick={e => matchHome ? matchHome && e.preventDefault() : matchHomePilotage && e.preventDefault()}
                >
                    { matchFolder === matchEntityFolder || matchRequestFolder ? 'MES DOSSIERS' : 'GESTION DES INTERVENANTS' }
                </NavLink>
            )}
            { matchFolder && (
                <span>
                    <span className="text">{'  >  '}</span>
                    <NavLink
                        to={privateRoutes.backOffice.entityFolder.path} className="text"
                        activeClassName='is-active' isActive={() => matchFolder}
                        onClick={e => matchFolder && e.preventDefault()}
                    >
                        { matchFolder === matchEntityFolder || matchRequestFolder ? `DOSSIER ${id}` : 'PRESTATAIRE' }
                    </NavLink>
                </span>
            )}
        </div>
    )
}

export default Breadcrumb