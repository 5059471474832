import React from 'react'
import Header from 'components/Header'
import Footer from 'components/Footer'

const PublicLayout = ({
    children,
    className,
    showHeader = false,
    showFooter = true,
    fixedLayout = false
}) => (
    <div className={
        'u-sticky-view '
        + (showHeader ? 'u-pd-top-navbar ' : ' ')
        + (fixedLayout ? 'l-fixed-layout ' : ' ')
    }>
        { showHeader && <Header /> }

        <main className={
            'c-main-content u-flex u-flex-dir-col '
            + (className || '')
        }>
            { children }
            {showFooter && fixedLayout && <Footer />}
        </main>

        {showFooter && !fixedLayout && <Footer />}

    </div>
)

export default PublicLayout
