import React, { useCallback, useState, useEffect } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { useModal } from 'hooks/useModal'
import { useAlert } from 'hooks/useAlert'
import { privateRoutes } from 'router/routes'
import { isValidFrenchPhoneNumber } from 'utils/validation'
import { 
    backOfficeRelationshipManager,
    BACKOFFICE_USER_STATUS, 
    ADVISOR_PERMISSION, 
    SUPERVISOR_PERMISSION, 
    RELATIONSHIP_MANAGER_PERMISSION, 
    RELATIONSHIP_OPERATOR_PERMISSION, 
    backOfficeRelationshipOperator
} from 'utils/domain/user'
import { PASSWORD_LENGTH } from 'utils/constants'
import { getValidPassword } from 'utils/functions'

import Card from 'components/platform/Card'
import RowInformations from 'components/platform/Informations/RowInformations'
import FormElement from 'components/shared/FormElement'
import ModalConfirmation from 'components/shared/ModalConfirmation'
import BackOfficeLayout from 'layouts/back-office'
import { useAuth } from 'hooks/useAuth'

import * as UserApi from 'services/api/UserApi'
import { getBackOfficeFolders } from 'services/api/CatalogApi'
import dateService from 'services/date'

const AccreditationDetail = () => {
    const isEditMode = useRouteMatch(privateRoutes.backOffice.accreditationDetail.path)?.isExact
    const isCreationMode = useRouteMatch(privateRoutes.backOffice.accreditationCreation.path)?.isExact

    const { userId } = useParams()
    const history = useHistory()
    const { addAlert } = useAlert()
    const { showModal, hideModal } = useModal()
    const {
        isABackOfficeManagementUser,
        isABackOfficeManagementSupervisor,
        isABackOfficeLeadUser,
        isABackOfficeRelationshipManager
    } = useAuth()

    const [ userDetail, setUserDetail ] = useState()
    const [ disabledActions, setDisabledActions ] = useState()
    const [ isDeleteAllowed, setIsDeleteAllowed ] = useState(false)

    const [ formFields, setFormFields ] = useState({
        password: '',
        passwordConfirmation: '',
        permission: 1
    })
    const [ showLocalErrors, setShowLocalErrors ] = useState(false)
    const [ errors, setErrors ] = useState([])
 
    const submitLabel = isEditMode ? 'Enregistrer' : 'Ajouter'  
    const subtitle = isEditMode ? "Modification d'un utilisateur" : "Ajout d'un utilisateur"  
    const deleteActionTooltip = isDeleteAllowed ? '' : 'Cet utilisateur ne peut pas être supprimé car il a des dossiers affectés en cours.'

    React.useEffect(() => {
        if(userId) load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId])

    const load = async () => {
        await loadUserDetail()
        await loadUserFolders()
    }

    const loadUserDetail = async () => {
        try {
            setDisabledActions(true)
            const { res, getError } = await UserApi.getBackOfficeUser(userId)
            if(getError()) throw getError()
            setUserDetail(res)
            setFormFields({
                ...res,
                ...res.description
            })
            setDisabledActions(false)
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }

    const loadUserFolders = async () => {
        try {
            setDisabledActions(true)
            const { res, getError } = await getBackOfficeFolders()
            if(getError()) throw getError()
            const userFolders = [ ...res.entities, ...res.requests ].filter(folder => folder.fub_user_id === userId)
            setIsDeleteAllowed(userFolders.length < 1)
            setDisabledActions(false)
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }
    
    const handleInputChange = (event) => {
        const target = event.target
        const name = target.name
        setFormFields(s => ({
            ...s,
            [name]: target.value
        }))
    }
    
    const handleValidation = useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const onDelete = () => {
        showModal(ModalConfirmation, { 
            title: 'Êtes vous sûr de vouloir supprimer cet utilisateur', 
            validate: deleteUser
        })
    }

    const onSubmit = () => {
        const formReady = !(errors.length > 0)
        if (!formReady) {
            setShowLocalErrors(true)
            return
        }
        if(isEditMode) {
            update()
        } else {
            add()
        }
    }

    const deleteUser = async () => {
        try {
            setDisabledActions(true)
            const { getError } = await UserApi.updateBackOfficeUser(userDetail.user_id, {
                ...userDetail,
                status: BACKOFFICE_USER_STATUS.DELETED
            })
            if(getError()) throw getError()
            history.push(privateRoutes.backOffice.accreditations.path)
            addAlert('success', "L'utilisateur a bien été supprimé.")
            hideModal()
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
        }
    }

    const add = async () => {
        try {
            setDisabledActions(true)
            const { res: createdUser, getError } = await UserApi.createBackOfficeUser({
                mail: formFields.mail,
                permission: Number(formFields.permission),
                password: formFields.password,
                description: {
                    last_name: formFields.last_name,
                    first_name: formFields.first_name,
                    phone_number: formFields.phone_number,
                    arrival_date: dateService.getCurrentDate('dd/MM/y')
                }
            })
            if(getError()) throw getError()
            copyPasswordToClipboard(formFields.password)
            addAlert('success', "L'utilisateur a bien été ajouté.")
            history.push(privateRoutes.backOffice.accreditationDetail.navLink({ userId: createdUser.user_id }))
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
        }
    }

    const update = async () => {
        try {
            setDisabledActions(true)
            const { getError } = await UserApi.updateBackOfficeUser(userDetail.user_id, {
                ...userDetail,
                permission: formFields.permission,
                description: {
                    ...userDetail.description,
                    last_name: formFields.last_name,
                    first_name: formFields.first_name,
                    phone_number: formFields.phone_number,
                }
            })
            if(getError()) throw getError()
            addAlert('success', "L'utilisateur a bien été modifié.")
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
        }
    }

    const onCancel = () => {
        history.goBack()
    }

    const copyPasswordToClipboard = (password) => {
        if(navigator) {
            navigator.clipboard.writeText(password)
            addAlert('info', 'Le mot de passe généré a été copié dans le presse papier.')
        } else {
            addAlert('error', 'Impossible de copier le mot de passe dans le presse papier.')
        }
    }

    const handlePasswordGeneration = () => {
        const passwordGenerated = getValidPassword(PASSWORD_LENGTH)
        setFormFields(s => ({
            ...s,
            password: passwordGenerated
        }))
        copyPasswordToClipboard(passwordGenerated)
    }

    const availableLeadUserRoles = [
        { id: 'advisor', value: ADVISOR_PERMISSION, label: 'Conseiller' },
        { id: 'supervisor', value: SUPERVISOR_PERMISSION, label: 'Superviseur' },
         { id: 'relationship-manager', value: RELATIONSHIP_MANAGER_PERMISSION, label: backOfficeRelationshipManager.label },
    ]

    const availableSupervisorRoles = [
        { id: 'advisor', value: ADVISOR_PERMISSION, label: 'Conseiller' },
        { id: 'supervisor', value: SUPERVISOR_PERMISSION, label: 'Superviseur' },
        { id: 'relationship-operator', value: RELATIONSHIP_OPERATOR_PERMISSION, label: backOfficeRelationshipOperator.label },
    ]

    const [ arrivedDate ] = [
        {
            label: "Date d'arivée",
            value: userDetail?.description?.arrival_date
        },
    ]

    const isUserCanBeDeleted = userDetail?.permission === ADVISOR_PERMISSION || userDetail?.permission === RELATIONSHIP_MANAGER_PERMISSION || userDetail?.permission === RELATIONSHIP_OPERATOR_PERMISSION

    return (
        <BackOfficeLayout>
            <div className="u-flex u-flex-dir-col">
                <h1 className="c-h1 u-bold u-primary">Gestion des habilitations</h1>
                <h3 className="c-h3 u-bold u-secondary u-mg-top-m">{subtitle}</h3>
                <Card className="u-mg-top-l" title="Informations de l'utilisateur">
                    <div className={'u-flex u-flex-dir-row u-flex-around u-mg-xl'}>
                        <FormElement
                            value={formFields.last_name}
                            name="last_name"
                            type="text"
                            label="Nom"
                            className="u-flex u-flex-dir-col l-grid u-flex-around u-mg-right-xl"
                            required
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                        />

                        <FormElement
                            value={formFields.first_name}
                            name="first_name"
                            type="text"
                            label="Prénom"
                            className="u-flex u-flex-dir-col l-grid u-flex-around u-mg-right-xl"
                            required
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className={'u-flex u-flex-dir-row u-flex-around u-mg-xl'}>
                        <FormElement
                            value={formFields.mail}
                            name="mail"
                            type="text"
                            label="Email"
                            className="u-flex u-flex-dir-col l-grid u-mg-right-xl"
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={isEditMode}
                            readonly={isEditMode}
                            required
                        />
                        <FormElement
                            value={formFields.phone_number}
                            name="phone_number"
                            type="text"
                            label="Téléphone"
                            className="u-flex u-flex-dir-col l-grid u-mg-right-xl"
                            customRules={[
                                {
                                    key: 'structure-phone-invalid',
                                    check: (value) => Boolean(value) ? isValidFrenchPhoneNumber(value) : true,
                                    message: "Le format de numéro de téléphone n'est pas valide."
                                }
                            ]}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                        />
                    </div>
                    {isCreationMode && (
                        <div className={'u-flex u-flex-dir-row u-flex-1 u-mg-xl'}>
                            <div className="u-flex u-flex-dir-col u-mg-right-xl">
                                <FormElement
                                    value={formFields.password}
                                    name="password"
                                    type="password"
                                    passwordCreate={true}
                                    label="Mot de passe"
                                    className="u-mg-bottom-s"
                                    required
                                    disabled
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                />
                                <p className="u-fs-xs u-mg-bottom-m">(8 caractères minimum, au moins 1 chiffre, au moins 1 minuscule, au moins 1 majuscule )</p>
                            </div>

                            <button
                                type="button"
                                className="c-btn c-btn--s c-btn--secondary c-btn--back-office u-pd-s u-mg-right-xl"
                                onClick={handlePasswordGeneration}
                            >
                                Générer le mot de passe
                            </button>
                        </div>
                    )}
                    {isEditMode && (
                        <RowInformations 
                            className="u-mg-xl" 
                            classNameLabel="c-label"
                            classNameValue="u-grey90 u-fs-xs u-pd-left-m"
                            informations={[arrivedDate]} 
                        />
                    )}
                </Card>
                
                <Card className="u-mg-top-l">
                    <FormElement
                        value={parseInt(formFields.permission)}
                        name="permission"
                        type="radio"
                        label="Type d'utilisateur :"
                        options={isABackOfficeLeadUser ? availableLeadUserRoles : availableSupervisorRoles}
                        className="u-mg-bottom-l"
                        classNameOptionsContainer="u-flex-dir-col"
                        classNameLabel="u-mg-bottom-m"
                        classNameOption="u-flex-center-vt"
                        required
                        readonly={isEditMode}
                        disabled={isEditMode}
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                    />
                </Card>

                <div className="u-flex u-flex-dir-row u-flex-end u-mg-top-m">
                        <div data-tip={deleteActionTooltip}>
                            {(isEditMode && isUserCanBeDeleted) && (
                                <button
                                    type="button"
                                    className="c-btn c-btn--s c-btn--danger c-btn--back-office u-pd-hz-m u-mg-m u-pd-s"
                                    onClick={onDelete}
                                    disabled={disabledActions || !isDeleteAllowed}
                                >
                                    Supprimer
                                </button>
                            )}
                        </div>
                    <button
                        type="button"
                        className="c-btn c-btn--s c-btn--white c-btn--back-office u-pd-hz-m u-mg-m u-pd-s"
                        onClick={onCancel}
                        disabled={disabledActions}
                    >
                        Annuler
                    </button>
                    <button
                        type="button"
                        className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-mg-m u-pd-s"
                        onClick={onSubmit}
                        disabled={disabledActions}
                    >
                        {submitLabel}
                    </button>
                </div>
            </div>
            <ReactTooltip place="top" type="dark" effect="solid"/>
        </BackOfficeLayout>
    )
}

export default AccreditationDetail