import React from 'react'
import { NavLink } from 'react-router-dom'
import PublicLayout from 'layouts/Public'
import FormSignup from 'components/auth/FormSignup'

const SignupPage = () => {
    return (
        <PublicLayout showHeader={true} className="u-pd-hz-m u-pd-top-m u-pd-hz-0@main u-pd-top-xl@main">
            <div className="l-form-width u-mg-hz-auto">
                <h1 className="c-h1 u-bold u-primary u-center u-mg-bottom-m">Création de compte</h1>
                <div className="u-flex u-flex-center-hz u-mg-bottom-xl">
                    <NavLink to="/se-connecter" className="u-fs-xs u-underline">Déjà inscrit ?</NavLink>
                </div>
            </div>
            <FormSignup />
        </PublicLayout>
    )
}

export default SignupPage
