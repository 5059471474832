import { number, object, string } from 'prop-types'
import { NavLink } from 'react-router-dom'
import CatalogRoutes from 'components/catalog/CatalogRoutes'
import { isValidated } from 'utils/domain/request'
import { formatNumberAndAddEurCurrency } from 'utils/number'
import dateService from 'services/date'
import { isNotEmpty } from 'utils/validation'

const getMonitoringUrlFromGender = (gender) =>
    CatalogRoutes.find(requestGender => requestGender.gender === gender)?.monitoringUrl

const BenefitCell = ({ value: benefit }) => (
    <div className="u-primary u-bold u-left">
        {benefit}
    </div>
)
BenefitCell.propTypes = {
    value: string
}

const FolderNumberCell = ({ value: folderNumber, cell }) => {
    if (!('gender' in cell.row.original)) return folderNumber
    return (
        <NavLink
            exact
            to={getMonitoringUrlFromGender(cell.row.original.gender)}
            className="u-underline"
        >
            {folderNumber}
        </NavLink>
    )
}
FolderNumberCell.propTypes = {
    value: string,
    cell: object
}

const AmountCell = ({ value: amount }) => (
    <div>
        {formatNumberAndAddEurCurrency(amount)}
    </div>
)
AmountCell.propTypes = {
    value: number
}

const BonusCell = ({ value: bonus, cell }) => {

    // Two types of bonus :
    // 1 : bonus = the initial bonus which should have been refunded if the bonus was necessary
    // 2 : realBonus = the bonus that the beneficiary will receive
    // NB: in regularisation case, the bonus is the refunded amount
    const bonusAmount = isNotEmpty(cell?.row?.original?.realBonus) ? cell.row.original.realBonus : bonus
    const isRealBonus = isNotEmpty(cell?.row?.original?.realBonus) && (cell.row.original.realBonus !== bonus)

    return (
        <div className="u-center">
            {/* the real bonus is display if exists and if it's different from bonus */}
            { isRealBonus &&
                <>
                    <span className='u-text-decoration-line-through'>{formatNumberAndAddEurCurrency(bonus)}</span>
                    <span className='u-mg-left-m u-bold '>{formatNumberAndAddEurCurrency(bonusAmount)}</span>
                </>
            }
            { !isRealBonus &&
                <>
                    <span>{formatNumberAndAddEurCurrency(bonus)}</span>
                </>
            }
        </div>
    )
}
BonusCell.propTypes = {
    value: number,
    cell: object,
}

const StatusCell = ({ value: labelStatus, cell }) => (
    <div className={`${isValidated(cell.row.original.status) ? 'u-green90' : 'u-primary'} u-center`}>
        {labelStatus}
    </div>
)
StatusCell.propTypes = {
    value: string,
    cell: object
}

const CreatedDate = ({ value: date }) => dateService.formatDate(date)

CreatedDate.propTypes = {
    value: string
}

export const bonusColumns = [
    {
        Header: 'Prestation',
        accessor: 'genderLabel',
        enableRowSpan: true,
        className: 'c-table__cell--5 u-left',
        Cell: BenefitCell
    },
    {
        Header: 'N° de dossier',
        accessor: 'request_fub_id',
        className: 'c-table__cell--4 u-right',
        Cell: FolderNumberCell
    },
    {
        Header: () => <span className="u-break-spaces">Date de la demande</span>,
        accessor: 'create_date',
        className: 'c-table__cell--4 u-right',
        Cell: CreatedDate
    },
    {
        Header: () => <span className="u-break-spaces">Montant HT de la facture ou du devis</span>,
        accessor: 'amount',
        className: 'c-table__cell--3 u-right',
        Cell: AmountCell
    },
    {
        Header: () => <span className="u-break-spaces">Prime correspondante</span>,
        accessor: 'bonus',
        className: 'c-table__cell--3 u-right',
        Cell: BonusCell
    },
    {
        Header: 'Statut',
        accessor: 'statusLabel',
        className: 'c-table__cell--3 u-center',
        Cell: StatusCell
    }
]