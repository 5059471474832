import React from 'react'
import { objectOf, shape, string } from 'prop-types'
import { formatNumberAndAddEurCurrency } from 'utils/number'
import Card from 'components/platform/Card'

const RegularisationOverview = ({ beneficiary }) => {
    const regularisationFolders = beneficiary?.regularisationFolders

    if (Boolean(regularisationFolders?.length === 0)) return <></>

    return (
        <Card>
           <div class="u-mg-bottom-s u-blue u-fs-m u-bold">Dossier de régularisation</div>
           {regularisationFolders.map(folder =>
                <div key={folder?.request_id}>
                    <hr className="u-mg-vt-l" />
                    <h4 className="u-secondary">
                        N° {folder?.request_fub_id}
                    </h4>
                    <div className="u-flex u-flex-dir-row">
                        <div className="u-mg-right-xl">
                            <span className="u-grey90">Statut : </span>
                            <span className="u-success">{folder?.regularisation_status === 0 ? 'Validé' : 'Payé'}</span>
                        </div>
                        <div className="u-mg-right-xl">
                            <span className="u-grey90">Conseiller : </span>
                            <span className="u-primary">{folder?.fub_user_name}</span>
                        </div>
                        <div className="u-mg-right-xl">
                            <span className="u-grey90">Montant de la régularisation : </span>
                            <span className="u-primary">{formatNumberAndAddEurCurrency(+folder?.real_amount_to_refund)}</span>
                        </div>
                    </div>
                    <div className="u-mg-top-l">
                        <div className="u-grey90">Commentaire :</div>
                        <div className="u-primary">{folder?.comment}</div>
                    </div>
                </div>
            )}
        </Card>
    )
}

RegularisationOverview.propTypes = {
    beneficiary: shape({
        requestsGroupedByGender: objectOf(
            shape({
                request_id: string.isRequired,
                status: string.isRequired
            })
        )
    }),
}

export default RegularisationOverview