import { EQUIPMENT_PROVIDER_ID, GENDER_PROVIDER_VALUE, } from 'utils/domain/provider'
import { equipmentList } from 'components/catalog/Equipment/list'
import { servicesEnum } from 'utils/domain/provider'
import { formationList as mobilityFormationList } from 'components/catalog/MobilityHelp/list'
import { formationList as technicalFormationList } from 'components/catalog/Technical/list'
import { formationList as adviceAndSupportFormationList } from 'components/catalog/AdviceAndSupport/list'
import { normalizeBoolean, removeSpaces } from 'utils/functions'

const handleAreaList = (area) => {
    if (typeof area === 'string') {
        return area.split(',').filter(n => !!n).map(n => {
            // force minimum 2 digits
            const number = n.toLocaleString('fr-FR', { minimumIntegerDigits: 2 })
            return parseInt(number)
        })
    }
    if (typeof area === 'number') {
        return area.toString().split(/,|\./).filter(n => !!n).map(n => {
            // force minimum 2 digits
            const number = n.toLocaleString('fr-FR', { minimumIntegerDigits: 2 })
            return parseInt(number)
        })
    }
    return null
}

const getProviderServices = ({ 
    catalogMobilityData: MOB,
    catalogAdviceAndSupportData: AEC,
    catalogScopingMeetingData: RCA,
    catalogAuditData: AUD
}) => {
    const isMobility = MOB ? Object.values(MOB).some(service => normalizeBoolean(service)) : false
    const isAdviceAndSupport = AEC ? Object.values(AEC).some(service => normalizeBoolean(service)) : false
    const isScopingMeeting = RCA ? normalizeBoolean(RCA.framing_meeting_available) || RCA.lot !== 0 : false
    const isAudit = AUD ? normalizeBoolean(AUD.audit_available) || AUD.lot_audit !== 0 : false

    const services = []
    if(isMobility) services.push(servicesEnum.mobility)
    if(isAdviceAndSupport) services.push(servicesEnum.adviceAndSupport)
    if(isScopingMeeting) services.push(servicesEnum.scopingMeeting)
    if(isAudit) services.push(servicesEnum.audit)

    return services
}

export const getProviderTableCorrelationHeader = dataType => {
    return Object.keys(getProviderTableCorrelation([], dataType, true))
}

export const getProviderTableCorrelation = (columns = [], dataType, needAllColumns = false) => {

    const structureData = {
        structureName: columns[0] || null,
        raison_sociale: columns[1] || null,
        structureSiret: columns[2] ? removeSpaces(columns[2]) : null,
        ...(columns[3] ? { structureRna: columns[3] || null } : {}),
        structureCodeApe: columns[4] || null,
        structureAddress: columns[5] || null,
        structurePostCode: columns[6] || null,
        structureCity: columns[7] || null,
        structurePhonenumber: columns[8] || null,
        contactEmail: columns[9] || null,
        website: columns[10] || null,
        informations: columns[11] || null
    }
    const equipmentProviderContactData = {
        contactGender: columns[12] || null,
        contactLastname: columns[13] || null,
        contactFirstname: columns[14] || null,
        contactPhonenumber: columns[15] || null,
    }
    const catalogEquipmentData = {
        [equipmentList[0].id]: columns[16] || 'Non', // bike_hitch
        [equipmentList[1].id]: columns[17] || 'Non', // bike_shelter
        [equipmentList[2].id]: columns[18] || 'Non', // bike_secure_storage
        [equipmentList[3].id]: columns[19] || 'Non', // bike_pumps
        [equipmentList[4].id]: columns[20] || 'Non', // lockers
        [equipmentList[5].id]: columns[21] || 'Non', // bike_toolkit
        installation: columns[22] || 'Non',
        france_installation: columns[23] || 'Non',
        dom_tom_installation: columns[24] || 'Non',
        france_supply: columns[25] || 'Non',
        dom_tom_supply: columns[26] || 'Non',
    }
    const catalogMobilityData = {
        [mobilityFormationList[1].id]: columns[12] || 'Non', // training-back-to-bike
        [mobilityFormationList[0].id]: columns[13] || 'Non', // driving-safely-company
        [mobilityFormationList[2].id]: columns[14] || 'Non', // training-electric-bike
        [mobilityFormationList[3].id]: columns[15] || 'Non', // training-traffic-code
        [technicalFormationList[0].id]: columns[16] || 'Non', // technical-check
        [technicalFormationList[1].id]: columns[17] || 'Non', // technical-maintenance
        [technicalFormationList[2]?.id]: columns[18] || 'Non', // technical-workshops
    }
    const infosAdviceAndSupportData = {
        area: handleAreaList(columns[19]) || '',
    }
    const catalogAdviceAndSupportData = {
        [adviceAndSupportFormationList[0].id]: columns[20] || 'Non', // diagnostic-mobility
        [adviceAndSupportFormationList[1].id]: columns[21] || 'Non', // setup-and-training
        [adviceAndSupportFormationList[2].id]: columns[22] || 'Non', // action-plan
        [adviceAndSupportFormationList[3].id]: columns[23] || 'Non', // audit-preparation
    }
    const catalogScopingMeetingData = {
        framing_meeting_available: normalizeBoolean(columns[24] || 'Non').toString(),
        lot: columns[25] || 0
    }
    const catalogAuditData = {
        audit_available: normalizeBoolean(columns[26] || 'Non').toString(),
        lot_audit: columns[27] || 0
    }

    const missingFields = {
        siret: structureData.structureSiret,
        mail: columns[9] || null,
        gender: GENDER_PROVIDER_VALUE
    }

    const providerServices = getProviderServices({
        catalogMobilityData,
        catalogAdviceAndSupportData,
        catalogScopingMeetingData,
        catalogAuditData
    })

    if (dataType === EQUIPMENT_PROVIDER_ID) {
        return ({
            services: servicesEnum.equipment,
            ...structureData,
            ...equipmentProviderContactData,
            ...catalogEquipmentData,
            ...missingFields
        })
    }

    if(needAllColumns){
        return {
            services: providerServices.join(','),
            ...structureData,
            ...catalogMobilityData,
            ...({ ...infosAdviceAndSupportData, ...catalogAdviceAndSupportData }),
            ...catalogScopingMeetingData,
            ...catalogAuditData,
            ...missingFields
        }
    }

    return {
        services: providerServices.join(','),
        ...structureData,
        ...(providerServices.includes(servicesEnum.mobility) ? catalogMobilityData : {}),
        ...(providerServices.includes(servicesEnum.adviceAndSupport)
            ? { ...infosAdviceAndSupportData, ...catalogAdviceAndSupportData }
            : {}
        ),
        ...(providerServices.includes(servicesEnum.scopingMeeting) ? catalogScopingMeetingData : {}),
        ...(providerServices.includes(servicesEnum.audit) ? catalogAuditData : {}),
        ...missingFields
    }
}

export const getProviderKeywords = (data) => {
    const providerServices = data?.services?.split(',')
    let keywords = ''
    keywords += `O-S-${data?.structureSiret} `
    keywords += `O-CP-${data?.structurePostCode} `
    if(data?.structureCodeApe) keywords += `O-APE-${data?.structureCodeApe} `
    keywords += `O-G-${data?.gender} `
    if(data?.contactFirstname) keywords += `O-FN-${data?.contactFirstname} `
    if(data?.contactLastname) keywords += `O-LN-${data?.contactLastname} `
    if (data?.structureRna) keywords += 'O-RNA-' + data?.structureRna + ' '
    if(data?.raison_sociale) keywords += `${data?.raison_sociale} `
    keywords += `${data?.structureAddress} `
    keywords += `${data?.structureCity} `
    keywords += `${data?.contactEmail} `
    if (providerServices.includes(servicesEnum.equipment)) {
        keywords += `O-${equipmentList[0].id?.toUpperCase()}-${data[equipmentList[0].id]} ` // bike_lock
        keywords += `O-${equipmentList[1].id?.toUpperCase()}-${data[equipmentList[1].id]} ` // bike_shelter
        keywords += `O-${equipmentList[2].id?.toUpperCase()}-${data[equipmentList[2].id]} ` // bike_secure_storage
        keywords += `O-${equipmentList[3].id?.toUpperCase()}-${data[equipmentList[3].id]} ` // bike_pumps
        keywords += `O-${equipmentList[4].id?.toUpperCase()}-${data[equipmentList[4].id]} ` // lockers
        keywords += `O-${equipmentList[5].id?.toUpperCase()}-${data[equipmentList[5].id]} ` // bike_toolkit
    }
    if (providerServices.includes(servicesEnum.mobility)) {
        keywords += `O-${mobilityFormationList[0].id?.toUpperCase()}-${data[mobilityFormationList[0].id]} ` // training-back-to-bike
        keywords += `O-${mobilityFormationList[1].id?.toUpperCase()}-${data[mobilityFormationList[1].id]} ` // driving-safely-company
        keywords += `O-${mobilityFormationList[2].id?.toUpperCase()}-${data[mobilityFormationList[2].id]} ` // training-electric-bike
        keywords += `O-${mobilityFormationList[3].id?.toUpperCase()}-${data[mobilityFormationList[3].id]} ` // training-traffic-code
        keywords += `O-${technicalFormationList[0].id?.toUpperCase()}-${data[technicalFormationList[0].id]} ` // technical-check
        keywords += `O-${technicalFormationList[1].id?.toUpperCase()}-${data[technicalFormationList[1].id]} ` // technical-maintenance
        keywords += `O-${technicalFormationList[2].id?.toUpperCase()}-${data[technicalFormationList[2].id]} ` // technical-workshops
    }
    if (providerServices.includes(servicesEnum.adviceAndSupport)) {
        keywords += 'O-A-' + data?.area?.toString().replaceAll(/\s?,\s?/g, '-') + ' '
    }
    
    return keywords
}