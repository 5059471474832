import React from 'react'
import { string, func, bool, object, shape } from 'prop-types'
import FormElement from 'components/shared/FormElement'

const FranceInstallation = ({
  formFields = {},
  formEvents = {},
  showLocalErrors = {},
  handleValidation = () => {},
  handleInputChange = () => {}
}) => {
  
  return (
    <FormElement
      value={formFields.franceInstallation ? true : false}
      options={[
        { value: true, label: 'Oui' },
        { value: false, label: 'Non' }
      ]}
      classNameOption="c-btn u-mg-right-m input-hidden"
      classNameLabel="u-mg-vt-m"
      name="franceInstallation"
      type="radio"
      label="Pose en France métropolitaine"
      showErrors={showLocalErrors}
      onValidate={handleValidation}
      onChange={handleInputChange}
      disabled={!formEvents.editable}
    />
  )
}

FranceInstallation.propTypes = {
  formFields: shape({
    structureName: string,
    structureSiret: string,
    structureAddress: string,
    structureCity: string,
    structurePostCode: string,
    structurePhoneNumber: string,
    emailConnexion: string,
    beneficiaryEmail: string,
    structureEmployeeQuantity: string,
    structureEmployeeCategory: string
  }),
  setFormFields: func,
  formEvents: object,
  showLocalErrors: bool,
  handleValidation: func,
  handleInputChange: func
}

export default FranceInstallation
