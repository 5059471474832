import React from 'react'
import FilterIcon from 'components/svg/Filter'
import dateService from 'services/date'
import { useFiltersMemory } from 'hooks/useFiltersMemory'
import { useLocation } from 'react-router-dom'

export const DateColumnFilter = ({
  column: { filterValue = [], setFilter, preFilteredRows, id, meta },
}) => {
  const [show, setShow] = React.useState(false)
  const wrapperRef = React.useRef(null)

  const {
    saveFilter
} = useFiltersMemory()

  const location = useLocation()
  const pageKey = location.pathname

  const handleChangeMin = (event) => {
    const val = event.target.value
    setFilter((old = []) => [val ? val : undefined, old[1]])
    saveFilter(pageKey, id + 'min', val ? val : undefined)
  }

  const handleChangeMax = (event) => {
    const val = event.target.value
    setFilter((old = []) => [
      old[0],
      val ? val : undefined
    ])
    saveFilter(pageKey, id + 'max', val ? val : undefined)
  }

  const displayFilter = (e) => {
    e.stopPropagation()
    setShow((prevShow) => !prevShow)
  }

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && show) {
        setShow(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [show, setShow])

  // Render a multi-select box
  return (
    <div className="u-relative u-flex u-flex-center-hz u-flex-1" ref={wrapperRef}>
      <button className="filter-trigger u-flex u-flex-center-vt" onClick={displayFilter}>
        <FilterIcon size="25px" className="c-input-picto" color={'#005a7c'} />
        {meta && meta?.label && <span className="u-mg-left-s u-semibold">{meta.label}</span>}
      </button>
      <div className={`filter ${show ? 'show' : 'hide'}`}>
        Min: <input id="date" type="date" value={filterValue[0] || ''} onChange={handleChangeMin} />
        Max: <input id="date-max" type="date" value={filterValue[1] || ''} onChange={handleChangeMax} />
      </div>
    </div>
  )
}

export function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? new Date(`${filterValues[0]} 00:00:00`) : undefined
  const ed = filterValues[1] ? new Date(`${filterValues[1]} 00:00:00`) : undefined
  if (ed || sd) {
    return rows.filter((r) => {
      // format data
      const cellDate = dateService.convertStringToDate(r.values[id])

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed
      } else if (sd) {
        return cellDate >= sd
      } else {
        return cellDate <= ed
      }
    })
  } else {
    return rows
  }
}