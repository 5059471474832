import dateService from 'services/date'

// ------------------
// Tiers
// ------------------
// Note: tiers = people we have to pay

export const tiersHeader = [
    'Nom* (s.nom)',
    'Nom alternatif (s.name_alias)',
    'Maison mère (s.parent)',
    'État (s.status)',
    'Code client (s.code_client)',
    'Code fournisseur (s.code_fournisseur)',
    'Code comptable client (s.code_compta)',
    'Code comptable fournisseur (s.code_compta_fournisseur)',
    'Adresse (s.address)',
    'Code postal (s.zip)',
    'Ville (s.town)',
    'Code État / Province (s.fk_departement)',
    'Code pays (s.fk_pays)',
    'Téléphone (s.phone)',
    'Fax (s.fax)',
    'Url (s.url)',
    'Email (s.email)',
    'Skype (s.skype)',
    'Salariés (s.fk_effectif)',
    'Type du tiers (s.fk_typent)',
    'Type d\'entité légale (s.fk_forme_juridique)',
    'Identifiant professionnel 1 (s.siren)',
    'Identifiant professionnel 2 (s.siret)',
    'Identifiant professionnel 3 (s.ape)',
    'Identifiant professionnel 4 (s.idprof4)',
    'Identifiant professionnel 5 (s.idprof5)',
    'Identifiant professionnel 6 (s.idprof6)',
    'Numéro TVA (s.tva_intra)',
    'Capital (s.capital)',
    'Statut prospect (s.fk_stcomm)',
    'Note (privée) (s.note_private)',
    'Note (publique) (s.note_public)',
    'Client* (s.client)',
    'Fournisseur* (s.fournisseur)',
    'Potentiel du prospect (s.fk_prospectlevel)',
    'Type de paiement - Client (s.mode_reglement)',
    'Conditions de paiement - Client (s.cond_reglement)',
    'Type de paiement - fournisseur (s.mode_reglement_supplier)',
    'Conditions de paiement - fournisseur (s.cond_reglement_supplier)',
    'Montant encours autorisé (s.outstanding_limit)',
    'Compte bancaire paiements (s.fk_account)',
    'Incoterms (s.fk_incoterms)',
    'Assujetti à la TVA (s.tva_assuj)',
    'BarCode (s.barcode)',
    'Langue par défaut (s.default_lang)',
    'Canvas (s.canvas)',
    'Date création (s.datec)',
    'Utiliser plusieurs devises (s.fk_multicurrency)',
    'Devise (s.multicurrency_code)',
    'Lien de signature (extra.signature_link)',
]
export const mapTiers = (tiers) => tiers.map(tier => {
    const tierData = tier?.entity_data
    return [
        `OEPV${tier?.entity_id}`,
        tierData?.structureName,
        null,
        1,
        ...Array(1).fill(null),
        `F${tier?.entity_id}`,
        ...Array(2).fill(null),
        tierData?.structureAddress,
        tierData?.structurePostCode,
        tierData?.structureCity,
        ...Array(1).fill(null),
        1,
        tierData?.structurePhonenumber,
        ...Array(2).fill(null),
        tierData?.contactEmail,
        ...Array(5).fill(null),
        tierData?.structureSiret,
        ...Array(9).fill(null),
        0, // is customer ?
        1, // is supplier ?
        ...Array(3).fill(null),
        2, // reglement mode
        1, // reglement condition
        ...Array(11).fill(null),
    ]
})

// ------------------
// Tiers RIB
// ------------------

export const tiersRIBHeader = [
    'Libellé* (sr.label)',
    'Nom du tiers* (sr.fk_soc)',
    'Date création* (sr.datec)',
    'Banque (sr.bank)',
    'Code banque (sr.code_banque)',
    'Code guichet (sr.code_guichet)',
    'Numéro de compte* (sr.number)',
    'Clé (sr.cle_rib)',
    'BIC/SWIFT (sr.bic)',
    'IBAN (sr.iban_prefix)',
    'Domiciliation du compte (sr.domiciliation)',
    'Nom du propriétaire du compte (sr.proprio)',
    'Adresse du propriétaire du compte (sr.owner_address)',
    'Défaut (sr.default_rib)',
    'RUM (sr.rum)',
    'Type ban is defaut (sr.type)',
]
export const mapTiersRIB = (tiers) => tiers.map(tier => {
    const tierData = tier?.entity_data
    const formatStrucutreName = (name) => {
        return name
        .trim().toLowerCase()
        .replace(/\s+/g, '-') // replaces spaces by hyphens
        .normalize('NFD').replace(/\p{Diacritic}/gu, '') // replaces accented chars by unaccented chars
        .toUpperCase()
    }
    const formattedBankAccountHolder = tierData?.bank_account_holder?.trim()
        ? tierData.bank_account_holder.trim()
        : tierData?.structureName
            ? `RIB_${formatStrucutreName(tierData.structureName)}`
            : null
    const formattedIban = tierData?.iban ? tierData.iban.trim().replace(/\s+/g, '-').replace(/-/g, '') : null

    return [
        formattedBankAccountHolder,
        `OEPV${tier?.entity_id}`,
        dateService.formatDate(tier?.create_date, 'y-MM-dd'),
        ...Array(3).fill(null),
        formattedIban,
        null,
        tierData?.bic || null,
        formattedIban,
        ...Array(3).fill(null),
        1, // default rib
        null,
        'ban'
    ]
})

// ------------------
// Refund infos ("entête")
// ------------------

export const refundInfosHeader = [
    'Réf. facture* (f.ref)',
    'Réf. fournisseur (f.ref_supplier)',
    'Type* (f.type)',
    'Supplier/Vendor* (f.fk_soc)',
    'Date création facture (f.datec)',
    'Date facturation (f.datef)',
    'Date limite règlement (f.date_lim_reglement)',
    'Total HT (f.total_ht)',
    'Total TTC (f.total_ttc)',
    'Total TVA (f.total_tva)',
    'Facture payée (f.paye)',
    'Statut facture (f.fk_statut)',
    'Modifier Id (f.fk_user_modif)',
    'Validator Id (f.fk_user_valid)',
    'Invoice Source Id (f.fk_facture_source)',
    'Project Id (f.fk_projet)',
    'Bank Account* (f.fk_account)',
    'Note facture (f.note_public)',
    'Note (privée) (f.note_private)',
    'Payment Condition (f.fk_cond_reglement)',
    'Payment Mode (f.fk_mode_reglement)',
    'Modèle de document (f.model_pdf)',
    'Validation Date (f.date_valid)',
]
export const mapRefundInfosTiers = (refunds) => {
    // regroup the same provider requests together
    const refundsSetByEntityId = Object.values(refunds.reduce((acc, curr) => {
        return {
            ...acc,
            [curr.entity_id]: curr
        }
    }, {}))

    return refundsSetByEntityId.map(refund => ([
        `PROV.${refund?.billing_id}`,
        `F${refund?.entity_id}`,
        0,
        `OEPV${refund?.entity_id}`,
        ...Array(5).fill(null),
        0,
        0, // 0 = brouillon, 1 = payé
        0, // 0 = brouillon, 1 = validé
        ...Array(3).fill(null),
        5,
        'OEPV',
        ...Array(2).fill(null),
        1,
        2,
        'vide pour le moment',
        null
    ]))
}

// ------------------
// Refund details ("lignes de facture")
// ------------------

export const refundDetailsHeader = [
    'Réf. facture* (fd.fk_facture_fourn)',
    'Ligne de facture parente (fd.fk_parent_line)',
    'IdProduct (fd.fk_product)',
    'Libellé (fd.label)',
    'Description de ligne (fd.description)',
    'P.U. HT (fd.pu_ht)',
    'P.U TTC (fd.pu_ttc)',
    'Quantité pour la ligne (fd.qty)',
    'Reduc. (fd.remise_percent)',
    'Vat Source Code (fd.vat_src_code)',
    'Type de ligne (0=produit, 1=service) (fd.product_type)',
    'Taux de TVA de la ligne (fd.tva_tx)',
    'Montant HT de la ligne (fd.total_ht)',
    'Montant TVA de la ligne (fd.tva)',
    'Montant TTC de la ligne (fd.total_ttc)',
    'Start Date (fd.date_start)',
    'End Date (fd.date_end)',
    'Unit (fd.fk_unit)',
]
export const mapRefundDetailsTiers = (refunds) => {

    const refundsFormatted = Object.values(refunds.reduce((acc, curr) => {
        const valueId = `${curr.entity_id}-${curr.is_zni}`

        // Does value already exist ?
        const existingValue = acc[valueId]

        // Values to add
        const existingRequestAmount = parseInt(existingValue?.request_amount_to_refund ?? 0)
        const newRequestAmount = parseInt(curr?.request_amount_to_refund ?? 0)
        const quantity = existingValue?.quantity ? existingValue?.quantity + 1 : 1
        const refundType = (() => {
            switch(curr.gender) {
                case '1':
                    return curr.is_zni ? 'audits en ZNI' : 'audits hors ZNI'
                case '0':
                    return 'réunions de cadrage'
                default:
                    return null
            }
        })()

        const newValue = {
            ...curr,
            request_amount_to_refund: existingRequestAmount + newRequestAmount,
            quantity: quantity,
            type: refundType
        }
        return ({
            ...acc,
           [valueId]: newValue,
        })
    }, {}))

    return refundsFormatted.map(refund => {
        return [
            `PROV.${refund?.billing_id}`,
            ...Array(2).fill(null),
            refund?.type,
            null,
            200, // TODO: refund?.request_amount_to_refund
            200, // TODO: refund?.request_amount_to_refund,
            refund?.quantity,
            ...Array(2).fill(null),
            1,
            0,
            refund?.request_amount_to_refund,
            0,
            refund?.request_amount_to_refund,
            ...Array(3).fill(null),
        ]
    })
}

// ------------------
// Refund details for beneficiaries ("lignes de facture")
// ------------------

export const mapRefundDetailsBeneficiariesTiers = (refunds) => {
    return refunds.map(refund => {
        const isRegularisation = refund.gender.toString() === '3'
        const refundType = (() => {
            switch(refund?.gender_request?.toString() ?? null) {
                case '0':
                    return 'Prestations d\'équipements (EQU)'
                case '1':
                    return 'Prestations de services éducatifs mobilité (MOB)'
                case '2':
                    return 'Prestations de services techniques (TEC)'
                case '3':
                    return 'Prestations d\'accompagnement et conseil (AEC)'
                default:
                    return null
            }
        })()
        return [
            `PROV.${refund?.billing_id}`,
            ...Array(2).fill(null),
            isRegularisation ? 'Régularisation' : refundType,
            refund?.request_fub_id,
            refund?.request_amount_to_refund,
            refund?.request_amount_to_refund,
            1,
            ...Array(2).fill(null),
            1,
            0,
            refund?.request_amount_to_refund,
            0,
            refund?.request_amount_to_refund,
            ...Array(3).fill(null),
        ]
    })
}


// ------------------
// GLOBAL FUNCTION
// ------------------

export const getEntitiesToExport = (entities, entityType) => {
    const header = {
        tiers: tiersHeader,
        tiersRIB: tiersRIBHeader,
        refundInfos: refundInfosHeader,
        refundDetails: refundDetailsHeader,
        refundDetailsBeneficiaries: refundDetailsHeader,
    }[entityType]
    const mapper = {
        tiers: mapTiers,
        tiersRIB: mapTiersRIB,
        refundInfos: mapRefundInfosTiers,
        refundDetails: mapRefundDetailsTiers,
        refundDetailsBeneficiaries: mapRefundDetailsBeneficiariesTiers
    }[entityType]

    // prepare data
    return [
        // Header array
        header,
        // Data arrays
        ...mapper(entities)
    ]
}