import {
    admin,
    backOfficeManagementAdvisor,
    backOfficeManagementSupervisor,
    backOfficeLeadAdvisor,
    backOfficeLeadSupervisor,
    backOfficeRelationshipManager,
    backOfficeRelationshipOperator
} from 'utils/domain/user'
import {
    users
} from 'utils/constants'
import { STEPS as AUTODIAG_STEPS } from 'pages/protected/Autodiag/context'

import { beneficiary } from 'utils/domain/beneficiary'
import {
    equipmentProvider,
    scopingMeetingProvider,
    auditProvider
} from 'utils/domain/provider'

export const isAnAuthorizedUser = (user, path) => {
    const userGender = user?.entity_data?.gender
    const userServices = user?.entity_data?.services?.split(',')
    const userPermission = user?.entity_data?.permission

    const userRoles = users.filter(user => {
        return user.value === userGender
            && (Boolean(userServices?.length) ? userServices.includes(user.serviceId) : true)
            && (Boolean(userPermission) ? userPermission === user.permission : true)
    }).map(user => user.role)

    if(userRoles.length === 0) {
        return false
    }

    const currentPath = Object.values({
        ...privateRoutes,
        ...publicRoutes,
        ...privateRoutes.backOffice
    }).find(access => access.path === path)

    if(!currentPath) {
        return false
    }
    // Check for the intersection between currentPath.roles and userRoles
    return currentPath.roles?.some(role => userRoles.includes(role)) || true
}

export const publicRoutes = {
    login: {
        path: '/se-connecter',
    },
    signin: {
        path: '/inscription'
    },
    lostPassword: {
        path: '/mot-de-passe-perdu'
    },
    legalNotices: {
        path: '/mentions-legales'
    },
    termsOfUse: {
        path: '/conditions-generales-d-utilisation'
    },
    notFound: {
        path: '/page-introuvable'
    },
    maintenance: {
        path: '/maintenance'
    },
    backOffice: {
        pattern: '/back-office',
        example: {
            path: '/back-office/exemple'
        },
        login: {
            path: '/back-office/se-connecter'
        },
        lostPassword: {
            path: '/back-office/mot-de-passe-perdu'
        }
    }
}

export const privateRoutes = {
    home: {
        path: '/accueil',
        roles: [beneficiary.role, equipmentProvider.role, scopingMeetingProvider.role, admin.role]
    },
    myAccount: {
        path: '/mon-compte',
        roles: [beneficiary.role, equipmentProvider.role, scopingMeetingProvider.role]
    },
    commitmentCharter: {
        path: '/charte-acces',
        roles: [beneficiary.role]
    },
    autoDiag: {
        path: '/auto-diagnostic/:step',
        navLink: ({
            step
        }) => `/auto-diagnostic/${step}`,
        roles: [beneficiary.role],
        paramsAllowed: Object.keys(AUTODIAG_STEPS)
    },
    scopingMeeting: {
        pattern: '/reunion-de-cadrage',
        path: '/reunion-de-cadrage',
        roles: [beneficiary.role]
    },
    audit: {
        pattern: '/audit',
        path: '/audit',
        roles: [beneficiary.role]
    },
    label: {
        path: '/label',
        roles: [beneficiary.role]
    },
    catalog: {
        pattern: '/catalogue',
        path: '/catalogue',
        roles: [beneficiary.role]
    },
    catalogItem: {
        path: '/catalogue/:slug',
        roles: [beneficiary.role]
    },
    serviceRequest: {
        path: '/demande-de-prestation',
        roles: [beneficiary.role]
    },
    serviceRequestItem: {
        path: '/demande-de-prestation/:slug',
        roles: [beneficiary.role]
    },
    acceptCGU: {
        path: '/accepter-les-CGU',
        roles: [beneficiary.role, equipmentProvider.role, scopingMeetingProvider.role]
    },
    error: {
        path: '/error',
        roles: [beneficiary.role, equipmentProvider.role, scopingMeetingProvider.role]
    },
    success: {
        path: '/success',
        roles: [beneficiary.role, equipmentProvider.role, scopingMeetingProvider.role]
    },
    monitoringActivitiesItem: {
        path: '/suivi-de-mes-activites/:slug',
        roles: [beneficiary.role]
    },
    monitoringActivities: {
        path: '/suivi-de-mes-activites',
        roles: [beneficiary.role],
        autodiagnostic: {
            path: '/suivi-de-mes-activites/autodiagnostic',
            roles: [beneficiary.role]
        },
        scopingMeeting: {
            path: '/suivi-de-mes-activites/reunion-de-cadrage',
            roles: [beneficiary.role]
        },
        commitmentCharter: {
            path: '/suivi-de-mes-activites/charte-acces',
            roles: [beneficiary.role]
        },
        benefits: {
            path: '/suivi-de-mes-activites/prestations',
            roles: [beneficiary.role],
            equipment: {
                path: '/suivi-de-mes-activites/prestations/prestation-equipement'
            },
            mobility: {
                path: '/suivi-de-mes-activites/prestations/prestation-formation-velo-au-quotidien'
            },
            technical: {
                path: '/suivi-de-mes-activites/prestations/prestation-maintenance-reparation'
            },
            adviceAndSupport: {
                path: '/suivi-de-mes-activites/prestations/accompagnement-et-conseils'
            }
        },
        audit: {
            path: '/suivi-de-mes-activites/audit',
            roles: [beneficiary.role]
        }
    },
    bonus: {
        path: '/prime',
        roles: ['beneficiary']
    },
    auditedBeneficiaryDetails: {
        path: '/beneficiaire/:beneficiaryId',
        navLink: ({ userId: beneficiaryId }) => `/beneficiaire/${beneficiaryId}`,
        roles: [auditProvider.role]
    },
    backOffice: {
        backOfficeHome: {
            path: '/back-office',
            roles: [
                backOfficeManagementAdvisor.role,
                backOfficeManagementSupervisor.role,
                backOfficeLeadAdvisor.role,
                backOfficeLeadSupervisor.role
            ]
        },
        participantsAdministration: {
            path: '/back-office/intervenants-administration',
            roles: [
                backOfficeLeadAdvisor.role,
                backOfficeLeadSupervisor.role
            ],
        },
        participantCreationAdministration: {
            path: '/back-office/intervenants-administration/creation',
            roles: [
                backOfficeLeadAdvisor.role,
                backOfficeLeadSupervisor.role
            ],
        },
        participantDetailsAdministration: {
            path: '/back-office/intervenants-administration/modification/:userId',
            navLink: ({ userId }) => `/back-office/intervenants-administration/modification/${userId}`,
            roles: [
                backOfficeLeadAdvisor.role,
                backOfficeLeadSupervisor.role
            ],
        },
        providersAdministration: {
            path: '/back-office/fournisseurs-equipements-administration',
            roles: [
                backOfficeLeadAdvisor.role,
                backOfficeLeadSupervisor.role
            ]
        },
        providerCreationAdministration: {
            path: '/back-office/fournisseurs-equipements-administration/creation',
            roles: [
                backOfficeLeadAdvisor.role,
                backOfficeLeadSupervisor.role
            ]
        },
        providerDetailAdministration: {
            path: '/back-office/fournisseurs-equipements-administration/:userId',
            navLink: ({ userId }) => `/back-office/fournisseurs-equipements-administration/${userId}`,
            roles: [
                backOfficeLeadAdvisor.role,
                backOfficeLeadSupervisor.role
            ]
        },
        providerModificationAdministration: {
            path: '/back-office/fournisseurs-equipements-administration/:userId/modification',
            navLink: ({ userId }) => `/back-office/fournisseurs-equipements-administration/${userId}/modification`,
            roles: [
                backOfficeLeadAdvisor.role,
                backOfficeLeadSupervisor.role
            ]
        },
        beneficiariesAdministration: {
            path: '/back-office/beneficiaires-administration',
            roles: [
                backOfficeLeadAdvisor.role,
                backOfficeLeadSupervisor.role
            ]
        },
        beneficiaryDetailAdministration: {
            path: '/back-office/beneficiaires-administration/:userId',
            navLink: ({ userId }) => `/back-office/beneficiaires-administration/${userId}`,
            roles: [
                backOfficeLeadAdvisor.role,
                backOfficeLeadSupervisor.role
            ]
        },
        accreditations: {
            path: '/back-office/habilitations',
            roles: [
                backOfficeManagementAdvisor.role,
                backOfficeManagementSupervisor.role,
                backOfficeLeadSupervisor.role
            ]
        },
        accreditationDetail: {
            path: '/back-office/habilitation/:userId',
            navLink: ({ userId }) => `/back-office/habilitation/${userId}`,
            roles: [backOfficeManagementSupervisor.role, backOfficeLeadAdvisor.role]
        },
        accreditationCreation: {
            path: '/back-office/habilitation',
            roles: [backOfficeManagementSupervisor.role, backOfficeLeadAdvisor.role]
        },
        folders: {
            path: '/back-office/mes-dossiers',
            roles: [backOfficeManagementAdvisor.role, backOfficeManagementSupervisor.role]
        },
        requestFolder: {
            path: '/back-office/mes-dossiers/beneficiaire/:beneficiaryId/demande/:requestId',
            navLink: ({
                beneficiaryId,
                requestId
            }) => `/back-office/mes-dossiers/beneficiaire/${beneficiaryId}/demande/${requestId}`,
            roles: [backOfficeManagementAdvisor.role, backOfficeManagementSupervisor.role]
        },
        entityFolder: {
            path: '/back-office/mes-dossiers/beneficiaire/:beneficiaryId',
            navLink: ({
                beneficiaryId
            }) => `/back-office/mes-dossiers/beneficiaire/${beneficiaryId}`,
            roles: [backOfficeManagementAdvisor.role, backOfficeManagementSupervisor.role]
        },
        beneficiaries: {
            path: '/back-office/beneficiaires',
            roles: [
                backOfficeManagementSupervisor.role,
                backOfficeRelationshipOperator.role
            ]
        },
        beneficiaryDetails: {
            path: '/back-office/beneficiaire/:beneficiaryId',
            navLink: ({ beneficiaryId }) => `/back-office/beneficiaire/${beneficiaryId}`,
            roles: [backOfficeManagementSupervisor.role]
        },
        beneficiaryRegularisation: {
            path: '/back-office/beneficiaire/:beneficiaryId/regularisation',
            navLink: ({ beneficiaryId }) => `/back-office/beneficiaire/${beneficiaryId}/regularisation`,
            roles: [backOfficeManagementSupervisor.role]
        },
        payments: {
            path: '/back-office/paiements',
            roles: [
                backOfficeLeadAdvisor.role,
                backOfficeLeadSupervisor.role
            ]
        },
        management: {
            path: '/back-office/pilotage',
            roles: [
                backOfficeLeadAdvisor.role,
                backOfficeLeadSupervisor.role,
                backOfficeRelationshipManager.role
            ]
        },
        uploadproviderdata: {
            path: '/back-office/table-de-correspondance',
            roles: [
                backOfficeLeadAdvisor.role,
                backOfficeLeadSupervisor.role
            ]
        },
    }
}
