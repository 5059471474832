import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useHistory } from 'react-router-dom'

import { useBasket } from 'hooks/useBasket'
import { useAuth } from 'hooks/useAuth'
import { useAlert } from 'hooks/useAlert'
import { useModal } from 'hooks/useModal'
import { servicesEnum } from 'utils/domain/provider'
import { REQUEST_SUPPORT_VALUE } from 'utils/domain/request'
import { getUserDepartment } from 'utils/geo'
import { scrollToTop } from 'utils/scrollTo'
import { privateRoutes } from 'router/routes'
import { createCatalogRequest, addProvidersRequest, getProviders, remapProviders, providerFilters } from '../catalogUtils'

import ProvidersTable from 'components/platform/ProvidersTable'
import FormElement from 'components/shared/FormElement'
import { Markdown } from 'components/shared/Markdown'
import ModalConfirmation from 'components/shared/ModalConfirmation'
import BidModalConfirmationBody from '../BidModalConfirmationBody'
import CatalogRoutes from '../CatalogRoutes'

const defaultFormEvents = {
    ready: true
}

const AdviceAndSupportBid = () => {
    const { firstItem: currentItem, currentBasket, setBasket } = useBasket()
    const { user } = useAuth()
    const history = useHistory()

    // Manage dynamic state property
    if (!currentItem) history.push(`${privateRoutes.catalog.path}/${CatalogRoutes[3].url}`)

    const defaultFormFields = React.useMemo(() => ({
        basket: [currentItem],
        selectedProviders: [],
        mapRadius: 600, //temp
        comment: '',
        id: null,
    }), [currentItem])

    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [showGlobalError, setShowGlobalError] = React.useState(false)

    const { addAlert } = useAlert()
    const { showModal, hideModal } = useModal()

    React.useEffect(() => {
        // Generate an uuid for the bid each time the view refresh
        // prevent submitting multiple times the same bid
        setFormFields((s) => ({
            ...s,
            id: uuidv4()
        }))
    }, [])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
            ? target.checked
            : target.type === 'file'
                ? target
                : target.value
        const name = target.name

        setFormFields(formFields => ({ ...formFields, [name]: value }))
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((errors) => {
            const cleanErrors = [...errors.filter((e) => e.origin !== name)]
            return [ ...cleanErrors, ...errs ]
        })
    }, [])

    const handleSuccess = () => {
        hideModal()
        addAlert('success', 'Votre demande de prestation a bien été envoyée !')
        setBasket(null)
        history.push({
            pathname: privateRoutes.success.path,
            state: { urlRedirect: privateRoutes.monitoringActivities.benefits.adviceAndSupport.path }
        })
    }
    
    const handleError = (error) => {
        addAlert('error', error?.toString())
        setShowGlobalError(true)
        setFormEvents((formEvents) => ({ ...formEvents, ready: true }))
        scrollToTop()
    }

    const price = currentItem?.id === 'cycling-strategy' && Number(user?.entity_data?.structureEmployeeQuantity) >= 250 ? currentItem?.maxUnitPrice : currentItem?.unitPrice

    const handleCreateRequest = () => createCatalogRequest({
        user,
        requestId: formFields?.id,
        requestGender: REQUEST_SUPPORT_VALUE,
        requestDescription: {
            type: currentItem?.id,
            comment: formFields?.comment.replace(/"/g, "'"),
            amount: price
        }
    })
        
    const handleEditRequest = (request) => addProvidersRequest({
        request,
        providersList: formFields?.selectedProviders
    }) 

    const handleConfirmation = async () => {
        setShowLocalErrors(false)
        // prevent form to be submitted again
        setFormEvents((currentFormEvents) => ({ ...currentFormEvents, ready: false }))

        try {
            const { res: createRequest, getError: getCreateRequestError } = await handleCreateRequest()
            if (getCreateRequestError()) throw getCreateRequestError()

            const { getError: getEditRequestError } = await handleEditRequest(createRequest)
            if (getEditRequestError()) throw getEditRequestError()

            handleSuccess()
        } catch(error) {
            handleError(error)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        const hasSelectedProviders = formFields?.selectedProviders?.length > 0
        if (!hasSelectedProviders) addAlert('error', 'Veuillez sélectionner un prestataire.')
        
        // Validate inputs
        const formReady = !(errors.length > 0) && hasSelectedProviders

        if (!formReady) {
            setShowLocalErrors(true)
        } else {
            showModal(
                ModalConfirmation,
                {
                    title: 'Confirmer la demande de prestation',
                    body: BidModalConfirmationBody,
                    validate: handleConfirmation
                }
            )
        }
    }

    // Handle providers
    const [providers, setProviders] = React.useState([])
    const [filteredProviders, setFilteredProviders] = React.useState([])
    const [onlyShowSelectedProviders, setOnlyShowSelectedProviders] = React.useState(false)
    
    const applyFilters = () => {
        const { service: serviceFilter } = providerFilters(currentBasket)

        const userDepartment = getUserDepartment(user)
        const isCorseDepartments = userDepartment === 20
        // entity_data when getEntities, no entity_data when getEntitiesByLocation
        const filteredProviders = providers
            // We check if the postCode of the user is equal to one of areas from providers
            // The area array contains integer and string elements such as "2A" and 75
            // Warning : for Corse departments, we need to compare the user department with areas from providers (2A or 2B)
            .filter(provider => provider.area?.find(
                areaItem => isCorseDepartments 
                    ? areaItem === '2A' || areaItem === '2B' 
                    : areaItem === userDepartment || parseInt(areaItem) === userDepartment
                )
            )
            .filter(provider => serviceFilter(provider))

        setFilteredProviders(filteredProviders)
    }

    const handleGetProviders = async () => {
        const { res: loadProviders, error: loadProvidersError } = await getProviders({
            services: servicesEnum.adviceAndSupport,
            remapProviders: (res) => remapProviders(res),
        })

        if (loadProvidersError) addAlert('error', loadProvidersError?.toString())
        else setProviders(loadProviders)
    }

    React.useEffect(() => {
        handleGetProviders()
    }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if(formFields?.selectedProviders.length < 1) {
            setOnlyShowSelectedProviders(false)
        }
    }, [formFields?.selectedProviders])

    React.useEffect(() => {
        applyFilters()
    }, [providers]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <h1 className="c-h1 u-secondary u-center u-mg-vt-xl">{currentItem?.label}</h1>

            {currentItem?.content &&
                <section>
                    <Markdown>{currentItem.content}</Markdown>
                </section>
             }

            <hr className="u-mg-vt-xl" />

            <form>

                {showGlobalError &&
                    <p className="u-fs-xs u-danger u-center u-mg-bottom-m">Un problème est survenu lors de la demande de prestation.</p>
                }

                <section>
                    <h2 className="c-h2 u-center u-primary u-bold u-mg-bottom-l">Prestataire</h2>
                    <p className="u-center u-mg-hz-auto">
                        Vous ne pouvez choisir qu'un seul prestataire pour cette formation
                    </p>

                    {/* Show selection */}
                    <div className="u-flex">
                        <FormElement
                            value={onlyShowSelectedProviders}
                            name="onlyShowSelectedProviders"
                            type="checkbox"
                            label=""
                            checkboxLabel={
                                <span>
                                    Afficher uniquement la sélection
                                </span>
                            }
                            // disabled={formFields?.selectedProviders?.length === 0}
                            className={'u-flex-1 u-pd-vt-m'}
                            classNameInput=""
                            classNameOption=""
                            onChange={(e) => setOnlyShowSelectedProviders(e.target?.checked)}
                            disabled={formFields?.selectedProviders.length < 1}
                        />
                    </div>

                    <ProvidersTable
                        data={onlyShowSelectedProviders ? formFields?.selectedProviders : filteredProviders}
                        hasSelectableRows={true}
                        selection={formFields?.selectedProviders}
                        selectionLength={1}
                        setSelection={(value) => handleInputChange({ target: { name: 'selectedProviders', value } })}
                        className="u-pd-bottom-l"
                        displayDistance={false}
                    />
                </section>

                <hr className="u-mg-vt-xl" />

                <section>
                    <h2 className="c-h2 u-center u-primary u-bold u-mg-bottom-l">Informations supplémentaires</h2>
                    <FormElement
                        value={formFields.comment}
                        name="comment"
                        type="textarea"
                        label="Commentaire"
                        placeholder="Nombres de personnes, niveaux, dates espérées, …"
                        className="u-mg-hz-auto u-mg-vt-xl"
                        style={{ maxWidth: '600px' }}
                        required={false}
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                    />
                </section>
                <div className="u-flex u-flex-center-hz u-mg-top-l u-mg-bottom-m">
                    {formEvents.ready &&
                        <button
                            type="submit"
                            className="c-btn c-btn--primary u-pd-hz-l"
                            onClick={handleSubmit}
                        >
                            Envoyer ma demande de prestation
                        </button>
                    }
                    {!formEvents.ready &&
                        <div className="c-spinner"></div>
                    }
                </div>
            </form>
        </>
    )
}

export default AdviceAndSupportBid