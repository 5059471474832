import React from 'react'
import { useTable, useSortBy, usePagination, useFilters, useGlobalFilter } from 'react-table'
import Emoji from 'components/shared/Emoji'
import FormElement from 'components/shared/FormElement'

const PAGINATION_LENGTH = 10

const UploadDataTable = ({
    data,
    headers,
    currentFilter,
    setDataFilter,
    uploadStatusList,
    className
}) => {
    // Columns definition
    const columns = React.useMemo(
        () => headers.map(h => ({
            Header: h,
            accessor: h,
        })),
        [headers]
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        // rows,
        page, // the rows for the active page

        // pagination utilities
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        setGlobalFilter,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 10
            },
            // autoResetSelectedRows: false
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    // Manage global search
    // -------------------
    const [dataTableFilter, setDataTableFilter] = React.useState('')
    React.useEffect(() => setGlobalFilter(dataTableFilter), [dataTableFilter, setGlobalFilter])

    // Manage pagination
    // -------------------
    const middleDistance = Math.round((PAGINATION_LENGTH - 1) / 2)
    const pageShouldBeHiddenInPagination = (index) => {
        // in the first pages,
        // pagination should not exceed PAGINATION_LENGTH
        const condition1 = (pageIndex <= middleDistance && index > PAGINATION_LENGTH - 1)
        // in the middle pages,
        // show x pages before currentPage and y pages after currentPage
        const condition2 = (
            (pageIndex > middleDistance)
            && (pageIndex < (pageOptions.length - middleDistance))
            && (index < (pageIndex - middleDistance) || index > (pageIndex + middleDistance - 1))
        )
        // in the last pages,
        // always show the x last pages
        const condition3 = (
            (pageIndex > (pageOptions.length - 1 - middleDistance))
            && index < (pageOptions.length - PAGINATION_LENGTH)
        )
        return (condition1 || condition2 || condition3)
    }

    const getFilterLabel = (uploadStatus) => {
        return uploadStatusList.find(i => i.value === uploadStatus)?.label
    }
    const getFilterColor = (uploadStatus) => {
        return uploadStatusList.find(i => i.value === uploadStatus)?.color || null
    }
    
    return (
        <>
            <div className={'c-table-data ' + (className ? className : '')}>

                <div className="c-table-data__header u-mg-bottom-0@main u-pd-bottom-l">

                    <div className="u-flex u-flex-dir-col u-flex-dir-row@main u-flex-between u-mg-bottom-l u-mg-bottom-0@main">
                        <div className="u-flex-1">
                            {/* Global filter */}
                            <div className={'c-form-group c-form-group--picto u-mg-bottom-l u-mg-bottom-0@main'}>
                                <label htmlFor="tableglobalFilter" className="c-label u-mg-bottom-xs">Rechercher</label>
                                <svg className="c-input-picto" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512">
                                    <path d="M505.749 475.587l-145.6-145.6c28.203-34.837 45.184-79.104 45.184-127.317C405.333 90.926 314.41.003 202.666.003S0 90.925 0 202.669s90.923 202.667 202.667 202.667c48.213 0 92.48-16.981 127.317-45.184l145.6 145.6c4.16 4.16 9.621 6.251 15.083 6.251s10.923-2.091 15.083-6.251c8.341-8.341 8.341-21.824-.001-30.165zM202.667 362.669c-88.235 0-160-71.765-160-160s71.765-160 160-160 160 71.765 160 160-71.766 160-160 160z"/>
                                </svg>
                                <input
                                    className={'c-input u-pd-left-xl'}
                                    id={'tableglobalFilter'}
                                    name={'tableglobalFilter'}
                                    type={'text'}
                                    placeholder={''}
                                    value={dataTableFilter}
                                    style={{ maxWidth: '400px' }}
                                    onChange={(event) => setDataTableFilter(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className="u-flex u-flex-end@main u-mg-bottom-l u-mg-bottom-0@main">
                            <FormElement
                                value={currentFilter}
                                options={uploadStatusList}
                                name={'filter'}
                                type="select"
                                label="Trier par status"
                                style={{ minWidth: '300px' }}
                                className=""
                                required={false}
                                onChange={e => setDataFilter(e?.target?.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="c-table-data-container">
                    <table className="c-table c-table--responsive" {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr className="c-table__row" {...headerGroup.getHeaderGroupProps()}>
                                    <th
                                        className={'c-table__cell u-bg-secondary u-pd-m@main '}
                                        style={{ flexBasis: '200px', width: '200px' }}
                                    >
                                        Statut
                                    </th>
                                    <th
                                        className={'c-table__cell u-bg-secondary u-pd-m@main '}
                                        style={{ flexBasis: '200px', width: '200px' }}
                                    >
                                        Erreur
                                    </th>
                                    <th
                                        className={'c-table__cell u-bg-secondary u-pd-m@main '}
                                        style={{ flexBasis: '200px', width: '200px' }}
                                    >
                                        Entity ID
                                    </th>
                                    {headerGroup.headers.map(column => {
                                        return (
                                            <th
                                                className={'c-table__cell u-pd-m '}
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                style={{ flexBasis: '200px', width: '200px' }}
                                            >
                                                <span className={'u-ellipsis'}>{column.render('Header')}</span>
                                                <span>
                                                    {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? ' 🔽'
                                                        : ' 🔼'
                                                    : ''}
                                                </span>
                                            </th>
                                        )
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <tr className={ 'c-table__row u-pd-m u-pd-0@main'} {...row.getRowProps()}>
                                        <td
                                            className={`c-table__cell u-pd-m@main u-${getFilterColor(row?.original?.uploadStatus) || ''}`}
                                            style={{ flexBasis: '200px', width: '200px' }}
                                        >
                                            {getFilterLabel(row?.original?.uploadStatus)}
                                        </td>
                                        <td
                                            className={'c-table__cell u-pd-m@main'}
                                            style={{ flexBasis: '200px', width: '200px' }}
                                        >
                                            {row?.original?.error ? row?.original?.error : '-'}
                                        </td>
                                        <td
                                            className={'c-table__cell u-pd-m@main'}
                                            style={{ flexBasis: '200px', width: '200px' }}
                                        >
                                            {row?.original?.entity_id ? row?.original?.entity_id : '-'}
                                        </td>
                                        {row.cells.map(cell => {
                                            return (
                                                <td
                                                    className={'c-table__cell u-pd-m@main '}
                                                    style={{ flexBasis: '200px', width: '200px' }}
                                                    {...cell.getCellProps()}
                                                >
                                                    <span className={'u-ellipsis'}>{cell.render('Cell')}</span>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    { data.length === 0 &&
                        <p className="u-center u-mg-top-m">
                            Aucune donnée disponible pour le moment
                        </p>
                    }
                </div>

                

                {/* Pagination */}
                { pageOptions && pageOptions.length > 1 &&
                    <div className="u-flex u-flex-center-hz">
                        <div className="c-pagination">
                            <button
                                type="button" className="c-btn c-btn--primary u-pd-hz-s u-mg-hz-m"
                                onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                            >
                                {'<<'}
                            </button>
                            {pageOptions.map(index => {
                                if (pageShouldBeHiddenInPagination(index)) return <React.Fragment key={index}></React.Fragment>
                                return (
                                    <button
                                        type="button"
                                        key={index}
                                        className={(index === pageIndex ? 'u-bold' : '')}
                                        style={{ width: '30px' }}
                                        onClick={() => gotoPage(index)}
                                    >
                                        {index + 1}
                                    </button>
                                )
                            })}
                            <button
                                type="button" className="c-btn c-btn--primary u-pd-hz-s u-mg-hz-m"
                                onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}
                            >
                                {'>>'}
                            </button>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default UploadDataTable