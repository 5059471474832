import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { privateRoutes } from 'router/routes'

import { useAlert } from 'hooks/useAlert'
import * as AuditApi from 'services/api/audit/auditApi'
import dateService from 'services/date'
import saveBlob from 'utils/saveBlob'

import ProtectedLayout from 'layouts/Protected'
import { ReactComponent as OneStar } from 'assets/images/labellisation/LOGO_PROVELO_1etoile.svg'
import { ReactComponent as TwoStars } from 'assets/images/labellisation/LOGO_PROVELO_2etoiles.svg'
import { ReactComponent as ThreeStars } from 'assets/images/labellisation/LOGO_PROVELO_3etoiles.svg'

import goldLabelingKitPdfFile from './kit_labellisation_or.pdf'
import silverLabelingKitPdfFile from './kit_labellisation_argent.pdf'
import bronzeLabelingKitPdfFile from './kit_labellisation_bronze.pdf'

import './styles.scss'

const Label = () => {
    const { addAlert } = useAlert()
    const [myAudit, setMyAudit] = useState()
    const history = useHistory()

    useEffect(() => {
        fetchMyAudit()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // used if the beneficiary is already on this page and that in the meantime an auditor set audit_label = 0
        // as soon as the beneficiary refreshes the page, it redirects him
        if(myAudit?.audit_label === 0) history.push(privateRoutes.home.path)
    }, [myAudit])

    const fetchMyAudit = async () => {
        try {
            const { res: myAudit, getError } = await AuditApi.getMyAudit()
            if (getError()) throw getError()
            setMyAudit(myAudit)
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }

    const getCorrespondingLabel = () => {
        if(myAudit?.audit_label === 1) return { label: 'bronze', image: <OneStar/>, kit: bronzeLabelingKitPdfFile }
        if(myAudit?.audit_label === 2) return { label: 'argent', image: <TwoStars/>, kit: silverLabelingKitPdfFile }
        if(myAudit?.audit_label === 3) return { label: 'or', image: <ThreeStars/>, kit: goldLabelingKitPdfFile }

        return { label: '', image: <></>, kit: null }
    }

    const myLabelName = getCorrespondingLabel().label
    const myCertificateUrl = myAudit?.audit_media?.documents?.find(document => document.media_gender === 'LABEL_AUDIT')?.full_media_url || ''

    const downloadMyCertificate = async () => {
        const documentTitle = `mon-certificat-de-labellisation-${myLabelName}-${dateService.getCurrentDate('dd-MM-y')}.pdf`

        const certificate = await fetch(myCertificateUrl)
        const blob = await certificate.blob()
        saveBlob(blob, documentTitle)
    }

    const downloadMyLabelingKit = async () => {
        const labelingKit = await fetch(getCorrespondingLabel().kit)
        const blobFile = await labelingKit.blob()
        saveBlob(blobFile, `mon-kit-de-labellisation-${myLabelName}.pdf`)
    }

    return (
        <ProtectedLayout>
            <div className="l-container-xxl u-mg-top-l">
                <div className="u-flex u-flex-dir-col">
                    <h1 className="c-h1 u-secondary u-center u-uppercase u-mg-bottom-xl">Mon label {myLabelName}</h1>
                    <div className="label-body u-mg-hz-auto">
                        {getCorrespondingLabel().image}
                        <div className="u-primary u-bold u-center">Félicitations ! Vous avez obtenu un label {myLabelName} à l'issue de l'audit.</div>
                        <button
                            type="button"
                            className="c-btn c-btn--secondary u-pd-hz-xl u-mg-hz-auto u-mg-top-l"
                            onClick={downloadMyCertificate}
                            style={{ maxWidth: '300px' }}
                        >
                            Télécharger l’attestation
                        </button>
                        <button
                            type="button"
                            className="c-btn c-btn--secondary u-pd-hz-xl u-mg-hz-auto u-mg-vt-l"
                            onClick={downloadMyLabelingKit}
                            style={{ maxWidth: '300px' }}
                        >
                            Télécharger mon kit de labellisation
                        </button>

                        <div className="u-danger u-center u-mg-bottom-xl u-mg-top-xl">Garder précieusement votre attestation. A la fin du programme, cette plateforme fermera.</div>
                    </div>
                </div>
            </div>
        </ProtectedLayout>
    )
}

export default Label