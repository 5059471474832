import React from 'react'
import { bool, func, node, number, shape, string } from 'prop-types'

import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'

import { formatNumberAndAddEurCurrency } from 'utils/number'
import { FOLDER_PAID } from 'utils/domain/request'
import { isNotEmpty } from 'utils/validation'

const Request = ({
    request,
    bonus,
    bidDocuments,
    pdfOverview,
    children
}) => {
    return (
        <div className="u-flex u-flex-dir-col">
            <hr className="u-mg-vt-m" />
            <h4 className="u-secondary">
                {request.label && `${request.label} - `} N° {request.fubId}    
            </h4>
            <div className="u-flex u-flex-dir-row u-fs-xs">
                <div className="u-mg-right-xl">
                    <span className="u-grey90">Statut : </span>
                    <span className="u-primary">{request.statusLabel}</span>
                </div>
                {request.provider && (
                    <div className="u-mg-right-xl">
                        <span className="u-grey90">Prestataire : </span>
                        <span className="u-primary">{request.provider}</span>
                    </div>
                )}
                {bonus.isDisplay && (
                    <div className="u-mg-right-xl">
                        <span className="u-grey90">{request.status === FOLDER_PAID ? 'Prime versée : ' : 'Prime à verser : ' }</span>
                        <span className="u-primary">{bonus.realBonus ? formatNumberAndAddEurCurrency(bonus.realBonus) : formatNumberAndAddEurCurrency(bonus.amount)}</span>
                    </div>
                )}
            </div>
            {isNotEmpty(request.requestDate) && <p className="u-grey90 u-fs-xs u-mg-top-xs">Date de demande de prestation : <span className="u-primary">{request.requestDate}</span></p>}

            {pdfOverview.isDisplay && (
                <div className="u-flex u-flex-dir-row u-fs-xs u-mg-top-l">
                    <InformationToVisualize 
                        label={pdfOverview.title}
                        onClickToAction={pdfOverview.onClick}
                        url={pdfOverview.url}
                        iconColor="green"
                        iconSize={30}
                    />
                </div>
            )}
            { children }
            { bidDocuments.isDisplay && (
                <>
                    <h4 className="u-mg-top-m u-primary">Justificatifs</h4>
                    <div className="u-flex u-flex-dir-row u-fs-xs">
                        <InformationToVisualize
                            className="u-mg-right-xl" 
                            label="Facture acquitée" 
                            url={bidDocuments?.bill?.full_media_url} 
                            iconColor="green"
                            iconSize={30}
                        />
                        <InformationToVisualize 
                            label="Justificatifs de paiement" 
                            url={bidDocuments?.proofOfPayment?.full_media_url} 
                            iconColor="green"
                            iconSize={30}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

const Document = shape({
    full_media_url: string
})

Request.propTypes = {
    request: shape({
        fubId: string.isRequired,
        label: string,
        statusLabel: string.isRequired,
        status: number,
        provider: string,
        requestDate: string,
    }).isRequired,
    bonus: shape({
        isDisplay: string,
        amount: string,
        realAmount: string,
    }).isRequired,
    bidDocuments: shape({
        isDisplay: bool,
        bill: Document,
        proofOfPayment: Document,
    }),
    pdfOverview: shape({
        isDisplay: bool,
        title: string.isRequired,
        onClick: func,
        url: string
    }).isRequired,
    children: node.isRequired
}

Request.Detail = ({ children }) => (
    <div className="u-flex u-flex-dir-row u-fs-xs u-mg-top-l">
        {children}
    </div>
)

Request.Detail.propTypes = {
    children: node.isRequired
}

Request.Documents = ({ title, children }) => (
    <>
        <h4 className="u-mg-top-m u-primary">{title}</h4>
        <div className="u-flex u-flex-dir-row l-grid u-fs-xs">
            {children}
        </div>
    </>
)

Request.Documents.propTypes = {
    title: string.isRequired,
    children: node.isRequired
}

export default Request