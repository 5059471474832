
export const ASKED_LABEL = 'Demandé' // 1
export const ORDERED_LABEL = 'Commandé' // 2
export const REALIZED_LABEL = 'Réalisé' // 3
export const CANCELED_LABEL = 'Annulé' // 4
export const RESERVED_LABEL = 'Bloqué' // 5
export const PAID_LABEL = 'Payé' // 12

// audit status
export const ASKED_STATUS = 1
export const ORDERED_STATUS = 2
export const REALIZED_STATUS = 3
export const CANCELED_STATUS = 4
export const RESERVED_STATUS = 5
export const PAID_STATUS = 12

export const isAskedAuditStatus = (status) => status === ASKED_STATUS
export const isOrderedAuditStatus = (status) => status === ORDERED_STATUS
export const isRealizedAuditStatus = (status) => status === REALIZED_STATUS
export const isCanceledAuditStatus = (status) => status === CANCELED_STATUS
export const isReservedAuditStatus = (status) => status === RESERVED_STATUS
export const isPaidAuditStatus = (status) => status === PAID_STATUS

export const AUDITS_PROCESS_STATUS = [
  { label: ASKED_LABEL, value: ASKED_STATUS, className: 'asked' },
  { label: ORDERED_LABEL, value: ORDERED_STATUS, className: 'ordered' },
  { label: REALIZED_LABEL, value: REALIZED_STATUS, className: 'realized' },
  { label: CANCELED_LABEL, value: CANCELED_STATUS, className: 'canceled' },
  { label: RESERVED_LABEL, value: RESERVED_STATUS, className: 'reserved' },
  { label: PAID_LABEL, value: PAID_STATUS, className: 'paid' },
]

// audit document genders
export const AUDIT_REPORT_DOC_GENDER = 'RAPPORT_AUDIT'
export const AUDIT_CERTIFICATION_DOC_GENDER = 'LABEL_AUDIT'
export const AUDIT_CGV_DOC_GENDER = 'CGV_AUDIT'

export const isAuditReportDocument = (mediaGender) => mediaGender === AUDIT_REPORT_DOC_GENDER
export const isCertifDocument = (mediaGender) => mediaGender === AUDIT_CERTIFICATION_DOC_GENDER
export const isCgvDocument = (mediaGender) => mediaGender === AUDIT_CGV_DOC_GENDER

export const hasAnAudit = (audit) => audit?.create_date !== undefined

export const getLabelFromAuditStatus = (auditStatus) =>
  AUDITS_PROCESS_STATUS.find((status) => status.value === auditStatus)?.label

export const getClassNameByAuditStatus = (auditStatus) =>
  AUDITS_PROCESS_STATUS.find((status) => status.value === auditStatus)?.className

