import React from 'react'

const Step4 = () => (
    <div className="u-pd-top-xl">
        <div className="u-primary u-bold u-mg-bottom-m">
            Le sentiment d'insécurité est l'un des premiers freins à l'utilisation du vélo. 
            Pourtant, rouler à vélo sans crainte et en améliorant sa sécurité, ça s'apprend!    
        </div>
        <ul className="u-pd-left-l u-mg-top-m u-mg-bottom-m">
            <li>
                Avec des <span className="u-secondary u-bold">formations alliant conseils théoriques et mises en pratique</span>, l'employeur peut agir pour améliorer la sécurité sur les trajets domicile-travail ou professionnels.
            </li>
            <li>
                La distribution de <span className="u-secondary u-bold">kits de sécurité et l'analyse des risques et des accidents</span> contribuent aussi à la sécurité au travail.
            </li>
        </ul>
        Pour assurer ses obligations en matière de sécurité au travail, l'employeur doit mettre en place des mesures de prévention et une analyse des risques. C'est pourquoi la tenue de formations pour la pratique du vélo au quotidien et un suivi spécifique de l'accidentalité vélo sont 2 critères obligatoires pour pouvoir être labellisé employeur pro-vélo.  
    </div> 
)

export default Step4


 


