import React, { useEffect, useState } from 'react'
import { node, shape, string } from 'prop-types'
import { pdf } from '@react-pdf/renderer'

import autodiagFile from './autodiagnostic.xlsx'

import RestitutionPDF from 'pages/protected/Autodiag/Steps/RestitutionPDF'

import * as EntityApi from 'services/api/EntityApi'
import dateService from 'services/date'

import { useAuth } from 'hooks/useAuth'
import { useAlert } from 'hooks/useAlert'

import saveBlob from 'utils/saveBlob'
import autodiagService from 'utils/autodiag'

export const STEP_0 = 'introduction'
export const STEP_1 = 'pilotage-strategie'
export const STEP_2 = 'communication-motivation'
export const STEP_3 = 'services'
export const STEP_4 = 'securite'
export const STEP_5 = 'equipements'
export const STEP_6 = 'restitution'

export const STEPS = {
    [STEP_0]: {
        position: 0,
        title: 'Introduction'
    }, 
    [STEP_1]: {
        position: 1,
        title: 'Pilotage et stratégie'
    }, 
    [STEP_2]: {
        position: 2,
        title: 'Communication et motivation'
    }, 
    [STEP_3]: {
        position: 3,
        title: 'Services'
    }, 
    [STEP_4]: {
        position: 4,
        title: 'Sécurité'
    }, 
    [STEP_5]: {
        position: 5,
        title: 'Equipement',
        isLastStep: true,
    }, 
    [STEP_6]: {
        position: 6,
        title: 'Restitution'
    }
}

export const AUTODIAG_STATUS_PROGRESS = 0
export const AUTODIAG_STATUS_DONE = 1

const AutoDiagContext = React.createContext({
    savingAutodiag: false,
    autodiagUser: {},
    autodiagsCollection: [],
    lastAutoDiagCompleted: {},
    blankQuestions: [],
    fetchAutoDiags: () => {},
    getQuestionsByAutoDiagId: () => {},
    setQuestions: () => {},
    saveAutoDiag: () => {},
    getLastAutoDiagCompletedByBeneficiary: () => {},
    getLastAutoDiagCompleted: () => {},
    getLastAutoDiag: () => {},
    getAutoDiagForFill: () => {},
    getRestitutionScore: () => {},
    getScoreExplanation: () => {},
    getRestitutionBinary: () => {},
    downloadAutodiagFile: () => {},
})

export const useAutoDiag = () => {
    const context = React.useContext(AutoDiagContext)
    if (context === undefined) {
      throw new Error('useAutoDiag must be used within a AutoDiagContextProvider')
    }
  
    return context
}


const AutoDiagContextProvider = ({ children, autodiagUser }) => {
    const { user, isABeneficiaryUser } = useAuth()
    const { addAlert } = useAlert()

    const [autodiagsCollection, setAutodiagsCollection] = useState([])
    const [blankQuestions, setBlankQuestions] = useState([])
    const [explanations, setExplanations] = useState()
    const [savingAutodiag, setSavingAutodiag] = useState(false)

    useEffect(() => {
        if(isABeneficiaryUser) {
            initAutoDiag()
        }
    }, [isABeneficiaryUser, autodiagUser, user]) // eslint-disable-line react-hooks/exhaustive-deps

   

    const getScoreExplanation = (autodiagScore) => {
        return explanations?.find(({ score }) => {
            // Split argument: https://www.fileformat.info/info/unicode/char/2026/index.htm
            const range = score.toString().split('…')
            if(!range[1]) {
                return Number(range[0]) === autodiagScore.mandatoryRequirementMetScore
            }
            return autodiagScore.mandatoryRequirementMetScore >= Number(range[0]) &&
                autodiagScore.mandatoryRequirementMetScore <= Number(range[1])
        })?.explanation
    }

    const initAutoDiag = async () => {
        try {
            await fetchAutoDiags()
            const { result: autodiagQuestions, explanations } = await autodiagService.importAutodiagFile()
            setBlankQuestions(autodiagQuestions)
            setExplanations(explanations)
        } catch(error) {
            addAlert('error', error)
        }
    }

    const getLastAutoDiagCompleted = (autodiags) => {
        const autodiagsToFormat = autodiags || autodiagsCollection || []

        return autodiagService.getLastAutoDiagCompletedFromAutodiags(autodiagsToFormat)
    }

    const getQuestionsByAutoDiagId = (autoDiagId) => {
        const autoDiag = autodiagsCollection.find(({ diag_id }) => diag_id === autoDiagId)
        if(!autoDiag) {
            return []
        }

        return Object.fromEntries(
            Object.entries(blankQuestions).map(([step, questions]) => (
                [
                    step, 
                    questions.map(question => {
                        if(autoDiag.diag_data?.survey[step]) {
                            const surveyQuestion = autoDiag.diag_data?.survey[step].find(({ num_question }) => num_question === question.num_question)
                            return {
                                ...surveyQuestion,
                                ...question,
                                userResponses: surveyQuestion?.userResponses
                            }
                        }   
                        return question
                    })
                ]
            ))
        )
    }
    
    const getLastAutoDiag = () => {
        if(autodiagsCollection.length) {
            return autodiagsCollection.sort(autodiagService.sortAutoDiagsById)[0]
        }
        return null
    }

    const getAutoDiagForFill = () => {
        const lastAutoDiag = getLastAutoDiag()
        if(lastAutoDiag && lastAutoDiag.diag_status === AUTODIAG_STATUS_PROGRESS) {
            return lastAutoDiag
        }
        return null
    }

    const getLastAutoDiagCompletedByBeneficiary = async (entity_id) => {
        const { res: autodiags, getError } = await EntityApi.getAutoDiagByEntity(entity_id)
        if(getError()) throw getError()
        return getLastAutoDiagCompleted(autodiags)
    }

    const fetchAutoDiags = async () => {
        const { res: autodiags, getError } = await EntityApi.getAutoDiags()
        if(getError()) throw getError()
        const isAutodiagsArray = Array.isArray(autodiags)
        // Side effect to prevent multiple getAutoDiags calls
        if (isAutodiagsArray) setAutodiagsCollection(autodiags)
        return autodiags
    }

    const saveAutoDiag = async ({
        diag_id,
        questions,
        documents = []
    }, { finalizeAutodiag } = {}) => {
        try {
            setSavingAutodiag(true)
            const diag_status = finalizeAutodiag ? AUTODIAG_STATUS_DONE : AUTODIAG_STATUS_PROGRESS
            if(diag_id?.toString()) {
                const autodiagToUpdate = {
                    diag_data: {
                        survey: questions,
                        documents,
                    },
                    diag_status: diag_status
                }
    
                const { getError } = await EntityApi.updateAutoDiag(
                    diag_id,
                    autodiagToUpdate
                )
                await fetchAutoDiags()

                if(getError()) throw getError()
            } else {
                const autodiagToSave = {
                    entity_id: autodiagUser.entity_id,
                    diag_status: diag_status,
                    diag_data: {
                        survey: questions
                    }
                }
    
                const { getError } = await EntityApi.createAutoDiag(autodiagToSave)
                await fetchAutoDiags()
                if(getError()) throw getError()
            }
        } finally {
            setSavingAutodiag(false)
        }
    }

    const getRestitutionBinary = ({
        questions, 
        scoreExplanation, 
        restitutionScore,
    }) => {
        const generateRestitutionPDF = pdf(
            <RestitutionPDF 
                questions={questions}
                scoreExplanation={scoreExplanation}
                mandatoryRequirementMetScore={restitutionScore.mandatoryRequirementMetScore}
                date={dateService.getCurrentDate('dd/MM/y')}
                optionalRequirementMetScore={restitutionScore.optionalRequirementMetScore}
            />
        )
        return generateRestitutionPDF.toBlob()
    }


    const downloadAutodiagFile = async () => {
        const responseFile = await fetch(autodiagFile)
        const blobFile = await responseFile.blob()
        saveBlob(blobFile, 'autodiagnostic.xlsx')
    }



    const getRestitutionScore = autodiagService.getRestitutionScore

    return (
        <AutoDiagContext.Provider value={{
            savingAutodiag,
            autodiagUser,
            blankQuestions,
            getQuestionsByAutoDiagId,
            autodiagsCollection,
            saveAutoDiag,
            getRestitutionBinary,
            getRestitutionScore, 
            getLastAutoDiag,
            getLastAutoDiagCompleted,
            getLastAutoDiagCompletedByBeneficiary,
            getAutoDiagForFill,
            downloadAutodiagFile,
            getScoreExplanation,
            fetchAutoDiags
        }}>
            {process.env.REACT_APP_ENABLED_DOWNLOAD_AUTODIAG === 'true' && isABeneficiaryUser && (
                <div className="u-flex u-flex-dir-col u-pd-m">
                    <button className="c-btn c-btn--white u-pd-hz-xl u-flex-self-end u-mg-bottom-xl" onClick={downloadAutodiagFile}>
                        ⛲️ 🙏 Source de vérité 🙏 ⛲️ 
                    </button>
                </div>
            )}
            {children}
        </AutoDiagContext.Provider>
    )
}

AutoDiagContextProvider.propTypes = {
    children: node,
    autodiagUser: shape({
        entity_id: string.isRequired,
        entity_data: shape({
            structureName: string.isRequired
        })
    })
}

export default AutoDiagContextProvider