import React from 'react'
import { useParams } from 'react-router-dom'
import BackOfficeLayout from 'layouts/back-office'
import BeneficiaryDetails from 'components/platform/BeneficiaryDetails'

const BeneficiaryAdministrationDetail = () => {

  const { userId } = useParams()

  return (
    <BackOfficeLayout>
      <BeneficiaryDetails beneficiaryId={userId} />
    </BackOfficeLayout>
  )
}

export default BeneficiaryAdministrationDetail
