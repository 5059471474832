import React from 'react'
import { useParams } from 'react-router'

import ProtectedLayout from 'layouts/Protected'
import BeneficiaryDetails from 'components/platform/BeneficiaryDetails'

const AuditedBeneficiaryDetails = () => {

  const { beneficiaryId } = useParams()

  return (
    <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main">
      <div className='l-container-xxl'>
        <BeneficiaryDetails beneficiaryId={beneficiaryId} />
      </div>
    </ProtectedLayout>
  )
}

export default AuditedBeneficiaryDetails
