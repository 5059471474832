import React, { useEffect, useState, useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import { arrayOf, number, shape, string, func, bool } from 'prop-types'

import { PRIMARY_COLOR } from 'utils/icons'
import { stringSorter } from 'utils/sort'

import { useAlert } from 'hooks/useAlert'
import { getEntities } from 'services/api/CatalogApi'
import { getError } from 'services/api/errors'
import { ACTIVE_STATUSES_USER } from 'utils/domain/user'
import { auditProvider, equipmentProvider, mobilityProvider, scopingMeetingProvider, supportProvider } from 'utils/domain/provider'

import Card from 'components/platform/Card'
import Table from 'components/shared/Table'
import PlusIcon from 'components/svg/Plus'
import { UserDetail } from 'components/back-office/UsersInformations/Detail/index'
import { isNotEmpty } from 'utils/validation'

const UsersInformations = ({ textPage, usersCriteria, detailPageRoute, creationPageRoute }) => {

  const [ users, setUsers ] = useState([])

  const { addAlert } = useAlert()

  const PROVIDERS_SERVICE = equipmentProvider.serviceId
  const areProviderOrParticipantUsers = usersCriteria.services.includes(mobilityProvider.serviceId) 
                                          || usersCriteria.services.includes(supportProvider.serviceId)
                                          || usersCriteria.services.includes(scopingMeetingProvider.serviceId)
                                          || usersCriteria.services.includes(auditProvider.serviceId)
                                          || usersCriteria.services.includes(equipmentProvider.serviceId)

  useEffect(() => {
    try {
      loadUsers()
    } catch(error) {
        console.error(error)
        addAlert('error', error?.toString())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const usersAPIResponseMapper = (users) => {
    if(!Array.isArray(users)) {
      return []
    }
    return users.map((data, index) => {
      const user = data?.entity_data

      return {
        ...data,
        id: index,
        entityId: data.entity_id,
        structureName: user.structureName,
        mail: user.mail,
        contactEmail: user.contactEmail,
        structurePhonenumber: user.structurePhonenumber,
        postCode: user.structurePostCode,
        city: user.structureCity,
        siret: user.structureSiret,
        status: data?.status, // connexion status
        isActive: isNotEmpty(data?.entity_data?.active_entity) ? data.entity_data.active_entity : true // participants and providers status (if they are actives to do scoping meetings or audits)
      }
    })
  }

  const loadUsers = () => {
    usersCriteria.genders.forEach(async gender => {
      if(areProviderOrParticipantUsers) {
        // Providers
        const reponses = Promise.all(usersCriteria.services.map(service => getEntities({ gender, services: service })))
        reponses
          .then(values => {
            const allUsers = values.map(r => r.res).flat(1)
            
            // remove all duplicate users
            const usersFiltered = allUsers.filter((user, index) => 
              index === allUsers.findIndex((u) => (
                u.entity_id === user.entity_id
              ))
            )

            setUsers(usersAPIResponseMapper(usersFiltered))
          })
          .catch(error => getError(error))
      } else {
        // beneficiaries, BO users
        const { res: users, getError } = await getEntities({ gender })
        if(getError()) throw getError()
        const mappedUsers = usersAPIResponseMapper(users)
          setUsers(prevState => [...prevState, ...mappedUsers])
      }
    })
  }

  const getStatusColumn = () => {
    if(areProviderOrParticipantUsers) {
      return {
        Header: 'Statut',
        accessor: 'isActive',
        Cell: ({ cell }) => {
          if(cell.row.original.isActive === false) {
              return <div className="u-danger u-semibold">Inactif</div>
            } else {
              return <div className="u-success u-semibold">Actif</div>
            }
          }
        }
    }
    // else
    return {
      Header: 'Statut',
      accessor: 'status',
      sortType: (a, b) => {
        let statusA = 0
        let statusB = 0
        if(ACTIVE_STATUSES_USER.includes(a.values.status) || a.values.status === true || a.values.status === 'true') statusA = 1
        if(ACTIVE_STATUSES_USER.includes(b.values.status) || b.values.status === true || b.values.status === 'true') statusB = 1
        return statusA - statusB
      },
      Cell: ({ cell : status }) => {
        if(ACTIVE_STATUSES_USER.includes(status?.value) || status?.value === true || status?.value === 'true') {
            return <div className="u-success u-semibold">Actif</div>
        } else {
          return <div className="u-danger u-semibold">Inactif</div>
        }
      }
    }
  }

  const columns = useMemo(
    () => {
      const columnsArray = [
        {
          Header: 'Raison sociale',
          accessor: 'structureName',
          sortType: (a, b) => {
            const nameA = a.original.entity_data.structureName
            const nameB = b.original.entity_data.structureName
            return stringSorter(nameA, nameB)
          }
        },
        {
          Header: 'Email',
          accessor: 'contactEmail',
          sortType: (a, b) => {
            const emailA = a.original.entity_data.contactEmail
            const emailB = b.original.entity_data.contactEmail
            return stringSorter(emailA, emailB)
          }
        },
        {
          Header: 'Tel',
          accessor: 'structurePhonenumber',
        },
        {
          Header: 'Code postal',
          accessor: 'postCode',
        },
        {
          Header: 'Ville',
          accessor: 'city',
          sortType: (a, b) => {
            const cityA = a.original.entity_data.structureCity
            const cityB = b.original.entity_data.structureCity
            return stringSorter(cityA, cityB)
          }
        },
        {
          Header: 'Siret',
          accessor: 'siret',
        },
        {
          Header: 'Consulter',
          disableSortBy: true,
          accessor: 'entity_id',
          hideSortIcon: true,
          Cell: ({ cell }) => {
            return <UserDetail userId={cell.value} route={detailPageRoute} />
          },
        },
        getStatusColumn()
      ]

      // additional column if users are providers
      if(usersCriteria.services.toString() === PROVIDERS_SERVICE) {
        const additionnalColumn = {
          Header: 'Pose des équipements',
          accessor: 'entity_data.installation'
        }
        columnsArray.splice(6, 0, additionnalColumn)
      }

      return columnsArray
    },
  [users]) // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <div className="u-flex u-flex-dir-col">
        <h1 className="c-h1 u-bold u-primary">{textPage.title}</h1>
        { textPage.action && 
        <NavLink
          type="button"
          className="c-btn c-btn--green c-btn--back-office u-pd-hz-xl u-mg-m u-flex-self-end u-text-transform-none"
          to={creationPageRoute.path}
        >
          <PlusIcon color={PRIMARY_COLOR} />
          <span className='u-mg-left-s'>{textPage.action}</span>
        </NavLink>
        }
        <Card className="u-pd-top-xl u-mg-bottom-l">
            <Table columns={columns} data={users} />
        </Card>
    </div>
  )
}

UsersInformations.propTypes = {
  textPage: shape({
    title: string.isRequired,
    action: string
  }),
  usersCriteria: shape({
    genders: arrayOf(number).isRequired,
    services: arrayOf(string).isRequired
  }),
  detailPageRoute: shape({
    path: string.isRequired,
    navLink: func.isRequired,
    roles: arrayOf(string).isRequired
  }),
  creationPageRoute: shape({
    path: string.isRequired,
    roles: arrayOf(string).isRequired
  }),
  displayCreateUserBtn: bool,
}

export default UsersInformations
