import { privateRoutes } from 'router/routes'

const MonitoringRoutes = [
    {
        label: 'Autodiagnostic',
        url: privateRoutes.monitoringActivities.autodiagnostic.path
    },
    {
        label: 'Réunion de cadrage',
        url: privateRoutes.monitoringActivities.scopingMeeting.path
    },
    {
        label: "Charte d'accès",
        url: privateRoutes.monitoringActivities.commitmentCharter.path
    },
    {
        label: 'Prestations',
        url: `${privateRoutes.monitoringActivities.benefits.path}`
    },
    {
        label: 'Audit',
        url: privateRoutes.monitoringActivities.audit.path
    }
]

export default MonitoringRoutes