import React from 'react'
import { func } from 'prop-types'

import { useModal } from 'hooks/useModal'
import { useAlert } from 'hooks/useAlert'
import { useAuth } from 'hooks/useAuth'
import {
    CANCEL,
    REQUEST_REFUSED,
    REQUEST_EQUIPMENTS_VALUE,
    PICTURES_UPLOADED,
    getFeedbackToEquipmentBeneficiary,
    getLabelDateFromRequestStatus,
    isBetweenStatus,
    isCancelled,
    isNeedComplementAfterSignOffReportUploaded,
    isNeedComplementDocumentsBid
} from 'utils/domain/request'
import * as CatalogApi from 'services/api/CatalogApi'
import dateService from 'services/date'
import { cancelRequest, downloadRequestPDF, validateBidRequest } from '../catalogUtils'

import FormDocumentBid from 'components/platform/FormDocumentBid'
import EquipementPDF from 'components/pdf/EquipementPDF'
import ModalConfirmation from 'components/shared/ModalConfirmation'
import Accordion from 'components/shared/Accordion'
import Emoji from 'components/shared/Emoji'
import FormValidateBid from 'components/platform/FormValidateBid'
import FormPicturesValidation from 'components/platform/FormPicturesValidation'
import DangerIcon from 'components/svg/Danger'
import BidTable from './ServiceEquipmentBidTable'
import { isNotEmpty } from 'utils/validation'

const ValidationModalBody = ({ onValidateForm }) => (
    <div className="u-text-color">
        <p className="u-mg-bottom-s">
            Pour confirmer la validation de ce devis, veuillez indiquer ci-dessous le montant total.
        </p>
        {onValidateForm &&
            <FormValidateBid onValidateForm={onValidateForm} />
        }
    </div>
)
ValidationModalBody.propTypes = {
    onValidateForm: func
}

const CancelModalBody = () => (
    <div className="u-text-color">
        <p className="u-flex u-flex-center-vt u-mg-bottom-s">
            <DangerIcon size={20} className="u-mg-right-s" />
            <span className="u-bold">Attention</span>
        </p>
        <p className="u-mg-bottom-s">
            L'annulation du dossier sera définitive.
        </p>
        <p className="u-mg-bottom-s">
            Les prestataires sollicités seront automatiquement informés du refus de leur devis.
        </p>
    </div>
)

const ServiceEquipmentMonitoring = () => {
    const [requests, setRequests] = React.useState([])
    const [tableData, setTableData] = React.useState({})
    const [collapsedAccordion, setCollapsedAccordion] = React.useState(false)

    const { showModal, hideModal } = useModal()
    const { addAlert } = useAlert()
    const { user, refreshUser } = useAuth()

    const fetchData = async () => {
        try {
            await refreshUser()
            const { res: requests, getError } = await CatalogApi.getRequests(REQUEST_EQUIPMENTS_VALUE)
            if(getError()) throw getError()

            if(isNotEmpty(requests)) {
                setRequests(requests)
            }
        } catch (error) {
            addAlert('error', error?.toString())
        }
    }
    // fetch data on first load
    React.useEffect(() => fetchData(), []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSuccess = (successMessage) => {
        hideModal()
        addAlert('success', successMessage)
        // refresh global data
        fetchData()
    }

    // Providers (for table data) only are get when the accordion is open
    const getProvidersOnCallback = async (requestID) => {
        try {
            const { res: requestProviders, getError } = await CatalogApi.getRequestProviders(requestID)
            if(getError()) throw getError()
    
            setTableData(formerTableData => ({ ...formerTableData, [requestID]: requestProviders }))            
        } catch (error) {
            addAlert('error', error?.toString())
        }
    }

    const handleCancelRequest = async (request) => {
        try {
            const { getError: getCancelRequestError } = await cancelRequest({ request })
            if (getCancelRequestError()) throw getCancelRequestError()
            setCollapsedAccordion(true)

            handleSuccess('La demande a bien été annulée !')
            setCollapsedAccordion(false)
        } catch (error) {
            addAlert('error', error?.toString())
        }
    }
    
    const openCancelRequestModal = async (request) => {
        showModal(
            ModalConfirmation,
            {
                title: 'Voulez-vous annuler ce dossier ?',
                body: CancelModalBody,
                cancelLabel: 'Retour',
                validate: () => handleCancelRequest(request)
            }
        )
    }

    const handleBidValidation = async (request, selectedProviderId, props) => {
        try {
            const { getError: getValidateBidError } = await validateBidRequest({ 
                request,
                providerId: selectedProviderId,
                bidAmount: props?.bidAmount
            })
            if(getValidateBidError()) throw getValidateBidError()

            handleSuccess('Le devis a été validé')
            // refresh providers data (table)
            getProvidersOnCallback(request?.request_id)
        } catch (error) {
            addAlert('error', error?.toString())
        }
    }
    
    const openValidateBidModal = async ({ request, selectedProviderId }) => {
        showModal(
            ModalConfirmation,
            {
                title: 'Valider le devis ?',
                body: ValidationModalBody,
                cancelLabel: 'Retour',
                validate: (props) => handleBidValidation(request, selectedProviderId, props),
                disabledConfirmInit: true
            }
        )
    }

    const handleDownloadRequestPDF = async (request) => {
        const { error } = await downloadRequestPDF({
            user,
            request,
            pdfTemplate: EquipementPDF,
            pdfTitle: 'demande-de-devis',
            readRequestProviders: false
        })

        if (error) addAlert('error', "Une erreur s'est produite lors de la génération du dossier. Veuillez réessayer.")
    }

    
    const isUploadDisableFn = (requestStatus) => {
        if(isNeedComplementAfterSignOffReportUploaded(requestStatus)) return false
        if(isNeedComplementDocumentsBid(requestStatus)) return false
        return true
    }


    // if(pageLoading) return <></>

    return (
        <>
            {requests.map((request, index) => {
                return (
                    <Accordion
                    key={index}
                    className={`u-mg-bottom-xl ${isCancelled(request?.status) ? 'is-disabled' : ''}`}
                    head={() => (
                        <div className="u-pd-hz-m u-pd-vt-l">
                            <div className="u-flex u-flex-between u-flex-center-vt u-flex-dir-col u-flex-dir-row@main">
                                <div className="u-flex-1">
                                    <h3 className="c-h4 u-font-base u-bold u-mg-bottom-m u-mg-bottom-0@main">Devis n°{request?.request_fub_id}</h3>
                                </div>
                                <div className="u-flex-0 u-flex">
                                    {!(request?.status === CANCEL) &&
                                        <>
                                            <button
                                                type="button"
                                                className="c-btn c-btn--white u-pd-hz-l u-mg-hz-s u-mg-bottom-m u-mg-bottom-0@main"
                                                onClick={() => handleDownloadRequestPDF(request)}
                                            >
                                                Consulter ma demande (pdf)
                                            </button>
                                            <button
                                                type="button"
                                                className="c-btn c-btn--white u-pd-hz-xl u-mg-hz-s u-mg-bottom-m u-mg-bottom-0@main"
                                                onClick={() => openCancelRequestModal(request)}
                                                disabled={isBetweenStatus({ status: request.status, start: PICTURES_UPLOADED })}
                                            >
                                                Annuler
                                            </button>
                                        </>
                                    }
                                </div>
                            </div>
                            <p className="u-fs-s u-bold">{getFeedbackToEquipmentBeneficiary(request?.status)}</p><br />
                            <p className="u-fs-s">{getLabelDateFromRequestStatus(request?.status)} le {dateService.formatDate(request?.modify_date, 'dd/MM/y')}</p>
                        </div>
                    )}
                    openingCallback={() => getProvidersOnCallback(request?.request_id)}
                    closingCallback={collapsedAccordion}
                >
                    <div className="u-pd-hz-m u-pd-vt-l">
                        {request?.request_description?.full_media_url &&
                            <div className="u-right u-mg-bottom-s">
                                <a href={request?.request_description?.full_media_url} target={'_blank'} rel="noopener noreferrer" className="u-underline">
                                    Consulter la photo de l'emplacement des travaux
                                </a>
                            </div>
                        }
                        <div className="u-pd-bottom-xl">
                            <h4 className="u-fs-l u-bold u-mg-bottom-m">Sélection du devis</h4>
                            {tableData && tableData[request?.request_id] &&
                                <BidTable
                                    data={tableData[request?.request_id]}
                                    request={request}
                                    validateBid={openValidateBidModal}
                                />
                            }
                        </div>

                        <hr className="c-dotted-hr" />

                        <FormPicturesValidation
                            request={request}
                            refreshData={fetchData}
                            disabled={request?.status.toString().match(/^(0|1|2|9)$/)}
                        />
                        
                        <hr className="c-dotted-hr" />

                        {request.status !== REQUEST_REFUSED &&
                            <div className={'u-pd-hz-m u-pd-vt-l ' + (request?.status.toString().match(/^(0|1|2|3|4|41|42|9|10|72)$/) ? 'is-disabled' : '')}>
                                <h4 className="u-fs-l u-bold u-mg-bottom-m">Versement de la prime</h4>
                                <div className="u-bg-white u-br-l u-primary u-pd-m">
                                    <FormDocumentBid
                                        refreshData={fetchData}
                                        isUploadDisableFn={isUploadDisableFn}
                                        requestPosition={index} 
                                        request={request} 
                                        isSubmitFormAvailable={request.status} 
                                        user={user}
                                        labelSubmitDisabled="Veuillez faire valider la conformité des travaux"
                                        successMessage="Les informations pour accéder au versement de la prime ont bien été envoyées"
                                    /> 
                                </div>
                            </div>
                    }
                    </div>
                </Accordion>
                )
            }
                

            )}
            {requests.length === 0 &&
                <p className="u-center u-mg-top-m">
                    Aucune demande ne semble correspondre à votre recherche <Emoji label="visage triste" symbol="😟" />
                </p>
            }
        </>
    )
}

export default ServiceEquipmentMonitoring
