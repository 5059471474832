import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'

import PublicLayout from 'layouts/Public'
import FormSignin from 'components/auth/FormSignin'
import Logo from 'components/LogoOEPV'
import { privateRoutes } from 'router/routes'

const LOGO_SIZE = 120

const SigninPage = () => {
    const { isLoggedIn, isABackOfficeUser } = useAuth()
    const history = useHistory()
    const location = useLocation()

    React.useEffect(() => {
        const redirection = isLoggedIn && isABackOfficeUser ? privateRoutes.backOffice.backOfficeHome.path : privateRoutes.home.path
        const loggedRedirect = (!location?.state?.isNewUser && location?.state?.from) ? location?.state?.from : redirection

        // Redirect user if loggedIn
        if (isLoggedIn) history.push(loggedRedirect)
    }, [history, isLoggedIn]) // eslint-disable-line

    return (
        <PublicLayout fixedLayout={true}>
            <div className="l-grid u-flex-1">
                <div className="l-col-12 u-pd-bottom-l u-pd-hz-m u-pd-hz-0@main u-relative" style={{ paddingTop: `${LOGO_SIZE + 60}px` }}>
                    
                    <div className="u-absolute u-top-0 u-left-0 u-pd-m">
                        <Logo style={{ height: `${LOGO_SIZE}px`, width: 'auto' }} />
                    </div>

                    <div className="l-form-width u-mg-hz-auto">
                        <h1 className="c-h1 u-bold u-secondary u-center u-mg-bottom-xl">Connectez-vous au back office</h1>
                        <div className="u-mg-bottom-l">
                            <FormSignin backOfficeSignIn /> 
                        </div>
                    </div>
                </div>
            </div>

        </PublicLayout>
    )
}

export default SigninPage
