import React from 'react'
import { func } from 'prop-types'

import { useModal } from 'hooks/useModal'
import './styles.scss'

const ModalQuitConfirmation = ({ 
    quitAndSave,
    quitWithoutSave
}) => {
    const { hideModal } = useModal()
    
    const onBackToAutodiag = () => {
        hideModal()
    }

    return (
        <div className="u-pd-xl">
            <h2 className="c-h2 u-primary u-center u-mg-vt-l">Quitter l'autodiagnostic</h2>
                <div className="u-flex u-flex-center-hz u-center u-mg-bottom-xl">
                    <p>Souhaitez vous enregistrer et conserver les données de votre autodiagnostic en cours avant de quitter ?</p>
                </div>
            <div className="u-flex u-flex-dir-row u-mg-top-l u-flex-around">
                <button
                    type="button"
                    className="c-btn c-btn--white-bordered u-pd-hz-l u-mg-bottom-m u-mg-0@main"
                    onClick={quitWithoutSave}
                >
                    Quitter sans enregistrer
                </button>
                <button
                    type="button"
                    className="c-btn c-btn--primary u-pd-hz-l u-mg-bottom-m u-mg-0@main"
                    onClick={quitAndSave}
                >
                    Quitter en conservant mes données
                </button>
            </div>
            <div className="u-flex u-flex-center-hz u-mg-top-l">
                <button
                    type="button"
                    className="u-underline"
                    onClick={onBackToAutodiag}
                >
                    Rester sur la page et continuer mon autodiagnostic
                </button>
            </div>
        </div>
    )
}

ModalQuitConfirmation.propTypes = {
    quitAndSave: func,
    quitWithoutSave: func,
}

export default ModalQuitConfirmation