import React from "react"
import { Range as RangeSlider } from "rc-slider"
import "rc-slider/assets/index.css"
import { isNotEmpty } from "utils/validation"

const Range = ({
    // Attributes
    value,
    defaultValue,
    step,
    min,
    max,
    name,
    label = "",
    disabled,
    readonly = false,
    // Styles
    style,
    className,
    classNameLabel = "",
    // Error management
    required = false,
    customRules,
    showErrors,
    // Handler
    onChange,
    onValidate
}) => {
    const [errors, setErrors] = React.useState([])
    const [marks, setMarks] = React.useState(null)

    React.useEffect(() => {
        let tempMarks = {};
        [...Array(max)].forEach((element, index) => {
            tempMarks[index + 1] = {
                style: value.includes(index + 1) ? { fontWeight: "bold", fontSize: "16px" } : {},
                label: index + 1
            }
        })
        setMarks(tempMarks)
    }, [value, max])

    const rules = React.useMemo(() => [
        {
            key: "input-empty",
            check: (value) => required ? isNotEmpty(value) : true,
            message: "Ce champ est obligatoire"
        },
        ...(customRules || [])
    ], [required]) // eslint-disable-line
    // customRules in dependencies array trigger an infinite loop,
    // because custom rules include a check property defining a new function,
    // preventing useMemo to return a valid shallow-comparison
    // or event useDeepMemo to return a valid deep-comparison

    const validate = React.useCallback((currentValue) => {
        let newErrors = []
        rules.forEach(rule => {
            if (!rule.check(currentValue)) {
                newErrors.push({
                    origin: name,
                    rule: rule.key,
                    message: rule.message
                })
            }
        })
        setErrors(newErrors)
        onValidate(name, newErrors)
    }, [rules, name, onValidate])

    // validate on mount and each time value change
    React.useEffect(() => {
        validate(value)
        return () => {
            // On unmounted erros and validation are no longer needed
            setErrors([])
            if (onValidate) onValidate(name, [])
        }
    }, [value, validate, onValidate, name])

    // Currently triggering an error in console: https://github.com/react-component/slider/issues/613

    const handleChange = (event) => {
        let inputValue = {
            target: {
                name: name,
                value: event
            }
        }
        onChange(inputValue)
    }

    return (
        <>
            <div className={"c-form-group " + (className)} style={style}>
                {label && <label className={"c-label u-mg-bottom-s " + classNameLabel} htmlFor="code">{label} {required && "*"}</label>}
                {marks &&
                    <RangeSlider
                        dots
                        step={step}
                        min={min}
                        max={max}
                        allowCross={false}
                        value={value}
                        defaultValue={defaultValue}
                        marks={marks}
                        onChange={(e) => !readonly && handleChange(e)}
                        disabled={disabled}
                        className={"c-range u-pd-vt-m"}
                    />
                }

                {/* Error messages */}
                {showErrors && errors && errors.map((error, index) => {
                    return <p key={index} className="u-fs-xs u-danger">{error.message}</p>
                })}
            </div>
        </>
    )
}


export default Range
