import dateService from 'services/date'
import autodiagService from 'utils/autodiag'
import * as EntityApi from 'services/api/EntityApi'
import * as ScopingMeetingApi from 'services/api/scoping-meeting/ScopingMeetingApi'
import * as CatalogApi from 'services/api/CatalogApi'

import { AUTODIAG_STATUS_DONE, AUTODIAG_STATUS_PROGRESS } from 'pages/protected/Autodiag/context'
import { REALIZED_STATUS } from 'utils/domain/scopingMeetings'

export const getLastAutoDiagCompletedForDashboardAdmin = async (entity_id) => {
    try {
        const { res: autodiags, getError } = await EntityApi.getAutoDiagByEntityForDashboardAdmin(entity_id)
        if(getError()) throw getError()

        if(autodiags.length) return autodiagService.getLastAutoDiagCompletedFromAutodiags(autodiags) || autodiags[0]
    } catch (error) {
        console.error(error?.toString())
    }

    return null
}

export const getAutodiagInfosToDisplay = async (entityId) => {
    const autodiag = await getLastAutoDiagCompletedForDashboardAdmin(entityId)

    if(autodiag?.diag_status === AUTODIAG_STATUS_PROGRESS)
        return { label: 'En cours', date: dateService.formatDate(autodiag.create_date) }

    if(autodiag?.diag_status === AUTODIAG_STATUS_DONE)
        return { label: 'Terminé', date: dateService.formatDate(autodiag.modify_date) }

    return { label: 'Pas commencé', date: 'N/A' }
}

export const getBeneficiaryScopingMeeting = async (entityId) => {
    try {
        const { res: scopingMeeting, getError } = await ScopingMeetingApi.getScopingMeetingByEntityForDashboardAdmin(entityId)
        if (getError()) throw getError()
        
        return scopingMeeting
    } catch (error) {
        console.error(error?.toString())        
    }
    return null
}

export const getScopingMeetingInfosToDisplay = async (entityId) => {
    const scopingMeeting = await getBeneficiaryScopingMeeting(entityId)

    const isScopingMeetingRealized = scopingMeeting?.framing_meeting_status === REALIZED_STATUS

    return {
        label: isScopingMeetingRealized ? 'Oui' : 'Non',
        date: isScopingMeetingRealized ? dateService.formatDate(scopingMeeting.framing_meeting_date) : 'N/A'
    }
}

export const getKbisSiretInfosToDisplay = (beneficiary) => {
    const isKbisSiretValidated = !!beneficiary.entity_check?.is_check
    return {
        label: isKbisSiretValidated ? 'Oui' : 'Non',
        date: isKbisSiretValidated ? dateService.formatDate(beneficiary.entity_check.date_check) : 'N/A'
    }
}

export const getCommitmentCharterInfosToDisplay = (beneficiary) => {
    const isCommitmentCharterSigned = Boolean(beneficiary.charte_accept)
    return {
        label: isCommitmentCharterSigned ? 'Oui' : 'Non',
        date: isCommitmentCharterSigned ? dateService.formatDate(beneficiary.charte_accept) : 'N/A'
    }
}

export const getBeneficiary = async(beneficiaryId) => {
    const { res: entityResponse, getError } = await CatalogApi.getBackOfficeEntity(beneficiaryId)
    if (getError()) throw getError()  
    return {
        ...entityResponse,
        ...entityResponse?.entity_data
    }
}