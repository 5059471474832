import React from 'react'
import { shape, string } from 'prop-types'

import { generateThumbnailFromMedia, getFileExtension } from 'utils/file'
import { isNotEmpty } from 'utils/validation'

import './styles.scss'

const Picture = ({
    picture,
    label,
    alt
}) => {
    const [pdfThumbnail, setPdfThumbnail] = React.useState()
    const isPDFExtension = getFileExtension(picture?.full_media_url) === 'pdf'

    React.useEffect(() => {
		// Build thumbnail image from PDF
		if (isNotEmpty(picture?.full_media_url) && isPDFExtension) {
            generateThumbnailFromMedia({ media: picture?.full_media_url }).then((file) => {
                if (typeof file === 'string') setPdfThumbnail(file)
			})
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [picture])

    return (
        <a href={picture?.full_media_url} target="_blank" className="c-picture c-btn--download u-text-decoration-none u-blue u-center" rel="noreferrer noopener">
            {!isPDFExtension && <img className="picture" src={picture?.full_media_url} alt={alt} />}
            {isPDFExtension && <img src={pdfThumbnail} alt={alt} />}
            {label}
        </a>
    )
} 

Picture.propTypes = {
    picture: shape({
        full_media_url: string.isRequired
    }),
    label: string,
    alt: string.isRequired
}

export default Picture