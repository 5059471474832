import { useEffect, useState } from 'react'
import { object } from 'prop-types'

import { useAlert } from 'hooks/useAlert'

import DownloadIcon from 'components/svg/Download'

import { getColor } from 'utils/styles'

import AutoDiagProvider, { useAutoDiag } from 'pages/protected/Autodiag/context'

const DownloadCell = () => {
    const { addAlert } = useAlert()
    const { getLastAutoDiagCompletedByBeneficiary, autodiagUser } = useAutoDiag()
    const [document, setDocument] = useState()

    const fetchDocument = async () => {
        try {
            const lastAutoDiagCompleted = await getLastAutoDiagCompletedByBeneficiary(autodiagUser.entity_id)
            setDocument(lastAutoDiagCompleted?.diag_data?.documents?.[0])
        } catch(error) {
            addAlert('error', error?.toString())
        }
    }
    
    useEffect(() => {
        fetchDocument()
    }, [])

    if(!document?.full_media_url) return <></>
    
    return (
        <a
            className="u-mg-left-m"
            target="_blank" rel="noopener noreferrer"
            href={document?.full_media_url}
        >
            <DownloadIcon className="vertical-align" color={getColor().primary} />
        </a>
    )
}

const DownloadAutodiagCell = ({ cell }) => {
    const user = {
        entity_id: cell.row.original.entity_id,
        entity_data: cell.row.original.entity_data
    }
    return(
        <AutoDiagProvider autodiagUser={user}>
            <DownloadCell />
        </AutoDiagProvider>
    )
}
DownloadAutodiagCell.propTypes = {
    cell: object
}

const downloadAutodiagColumn = {
    Header: () => <span className="u-break-spaces">Télécharger l'autodiagnostic</span>,
    accessor: 'download',
    sortType: (a, b) => {
        const autodiagA = !! a.original?.download
        const autodiagB = !! b.original?.download
        return autodiagA - autodiagB
    },
    Cell: ({ cell }) => DownloadAutodiagCell({ cell })
}

export default downloadAutodiagColumn