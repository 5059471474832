import React from 'react'
import { useModal } from 'hooks/useModal'
import { useAlert } from 'hooks/useAlert'
import PicturesValidationSample from 'components/platform/PicturesValidationSample/PicturesValidationSample'
import PicturesUpload from 'components/platform/PictureUpload/PictureUpload'
import dateService from 'services/date'
import * as CatalogApi from 'services/api/CatalogApi'
import * as UtilsApi from 'services/api/UtilsApi'
import { isNotEmpty } from 'utils/validation'
import { getMediaUrlPrefix } from 'utils/domain/user'
import { 
    getLastPhotosOfHistory, 
    isNeedComplementPictures,
    isValidatedBid,
    isBetweenStatus,
    INIT,
    VALIDATED_BID,
    PICTURES_UPLOADED_BACK,
    PICTURES_VALIDATED,
    REQUEST_REFUSED
} from 'utils/domain/request'
import BulbIcon from 'components/svg/Bulb'

const defaultFormFields = {
    photos: []
}

const defaultFormEvents = {
    ready: true,
    disabledSubmit: true
}

const FormPicturesValidation = ({ request, refreshData, disabled }) => {
    const { showModal } = useModal()
    const [pictures, setPictures] = React.useState()

    React.useEffect(() => {
        initPictures()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request])

    const initPictures = async() => {
        const { res: history } = await CatalogApi.getRequestHistory(request?.request_id)
        setPictures(getLastPhotosOfHistory(history) || [])
    }
    
    const displayPictures = isBetweenStatus({ status: request?.status, start: INIT, end: PICTURES_UPLOADED_BACK }) ||
    isBetweenStatus({ status: request?.status, start: PICTURES_VALIDATED })

    const displayUploadInstructions = isBetweenStatus({ status: request?.status, start: INIT, end: VALIDATED_BID })
    const displayComplementInstructions = isNeedComplementPictures(request?.status)
    const couldUploadPictures = isValidatedBid(request?.status) || isNeedComplementPictures(request?.status) 

    const actionLabel = isNeedComplementPictures(request?.status) ? 'Modifier mes photos' : 'Charger mes photos'

    const showModalPhoto = () => {
        showModal(
            ModalPictureForm,
            {
                request,
                pictures,
                actionLabel,
                callback: () => {
                    // refresh parent data
                    refreshData()
                }

            },
            {
                style: { maxWidth: '1200px' },
                isClosable: true 
            }
        )
    }

    return (
        <div className={'u-pd-hz-m u-pd-vt-l ' + (disabled ? 'is-disabled' : '')}>
            <h4 className="u-fs-l u-bold u-mg-bottom-m">Validation de la conformité des travaux </h4>
                <div className="u-bg-white u-br-l u-primary u-pd-m">
                {displayUploadInstructions && (
                    <div className="u-pd-top-l u-pd-bottom-s">
                        Chargez les photos de vos travaux pour vérification de leur conformité avec les critères du programme. Vous serez notifié par email de la validation des travaux et pourrez verser la totalité du paiement au prestataire. 
                    </div>
                )}
                {displayComplementInstructions && (
                    <div className="u-pd-top-l u-pd-bottom-s u-mg-bottom-m">
                        <div className="u-center u-italic u-mg-bottom-m">Demande d'informations complémentaires</div>
                        Après verification de vos photos par nos experts, nous constatons que les photos soumises ne sont pas conformes.
                        Veuillez envoyer de nouvelles photos correspondant aux informations complémentaires demandées par le conseiller.
                        Chargez de nouvelles photos de vos travaux pour que nos experts vélo verifient leur conformité. 
                        Vous serez notifié de cette vérification par email.
                    </div>
                )}
                {displayPictures && 
                    <>
                        <PicturesValidationSample pictures={pictures} />

                        <div className='u-flex u-flex-center-vt u-flex-center-hz u-fs-xxs u-mg-bottom-l'>
                            <div className='u-mg-right-m'><BulbIcon /></div>
                            <p className='u-left'>4 photos ne vous permettent pas de montrer l’installation dans son intégralité ?<br />Compilez vos photos sur un document pdf et chargez-le à l’endroit des photos.</p>
                        </div>
                    </>
                }

                <div className="u-flex u-flex-center-hz">
                    {(disabled || couldUploadPictures) && // pictures have not been uploaded yet
                        <button
                            type="button"
                            className="c-btn c-btn--secondary u-pd-hz-l u-mg-bottom-m u-mg-bottom-0@main "
                            onClick={() => !disabled && showModalPhoto(true)}
                        >
                            {actionLabel}
                        </button>
                    }

                    {/* {isBetweenStatus({ status: request?.status, start: PICTURES_VALIDATED, end: REQUEST_REFUSED }) && // pictures have been validated
                        <div className="u-center u-black u-pd-bottom-xl">
                            <span>Vos photos ont été validées.</span>
                        </div>
                    } */}
                    
                    {request.status === REQUEST_REFUSED &&
                        <div className="u-center u-black u-pd-bottom-xl">
                            <span>Conformité refusée</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

const ModalPictureForm = ({ request, pictures, actionLabel, callback }) => {
    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)
    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [showGlobalError, setShowGlobalError] = React.useState(false)

    const { hideModal } = useModal()
    const { addAlert } = useAlert()

    React.useEffect(() => {
        setFormFields(s => ({
            ...s,
            photos: pictures
        }))
    }, [pictures])



    const handleSubmit = async (event) => {
        event.preventDefault()
        // Validate inputs
        const formReady = !(errors.length > 0) && formFields?.photos?.length === 4

        if (formFields?.photos?.length < 4) {
            setShowGlobalError(true)
            addAlert('error', 'Il faut remplir les quatres images comme montré précédement')
        }
        else if (!formReady) {
            setShowLocalErrors(true)
        }
        else {
            // hide error
            setShowLocalErrors(false)
            // prevent form to be submitted again
            setFormEvents({
                ...formEvents,
                ready: false
            })

            // Manage picture upload
            const requestPictures = []
            // see https://stackoverflow.com/a/37576787/7716596
            for (const picture of formFields?.photos) {
                const index = formFields?.photos.indexOf(picture)
                // Manage picture
                if (isNotEmpty(picture) && picture?.id) {
                    // 1 - get S3 url
                    const S3url = await UtilsApi.getS3Url(`${picture?.id}.${picture?.extension}`)
                    if (S3url && S3url?.url) {
                        // 2 - put picture
                        // issue with axios / blob / header authorization
                        // https://github.com/axios/axios/issues/1569
                        const savePicture = await fetch(
                            S3url.url,
                            {
                                method: 'PUT',
                                headers: { 'content-type': '' },
                                body: picture?.blob,
                            }
                        ).catch(e => null)

                        if (!savePicture) {
                            addAlert('error', "Erreur lors de l'enregistrement : nous n'avons pas pu enregistrer votre image.")
                        } else {
                            requestPictures.push({
                                media_gender: `PHOTO${index + 1}`,
                                media_id: picture?.id,
                                media_url: `${getMediaUrlPrefix()}${picture?.id}.${picture?.extension}`,
                                media_hash: picture?.value
                            })
                        }
                    }
                }
                if(picture?.media_id) {
                    requestPictures.push({
                        media_gender: picture.media_gender,
                        media_id: picture.media_id,
                        media_url: picture.media_url,
                        media_hash: picture.media_hash
                    })
                }
            }

            if (requestPictures.length !== 4) {
                addAlert('error', "Erreur : Toutes les photos n'ont pas pu être téléversées.")
                setFormEvents({
                    ...formEvents,
                    ready: false
                })
                return
            }

            // API Call: send pictures
            const editedRequest = {
                request_id: request?.request_id,
                request_data: {
                    operation_code: 'PHOTOS',
                    operation_data: {
                        documents: requestPictures,
                        operation_date: dateService.getCurrentDate('yyyy-MM-dd HH:mm:ss'),
                    }
                },
                request_search_data: null,
                last_row_hash: request?.row_hash
            }

            // TODO use getError to handle the potential exception
            const { res: editRequest } = await CatalogApi.editRequest(editedRequest)
            if (!editRequest || editRequest?.error) {
                switch (editRequest?.error?.code) {
                    case 'NotAuthorizedException':
                        addAlert('error', "Vous n'avez pas les droits nécessaires pour éditer cette demande.")
                        break
                    case 'RequestNotFoundException':
                        addAlert('error', "La demande n'a pas pu être mise à jour car le dossier initial n'a pas pu être identifié.")
                        break
                    case 'RequestVersionMismatchException':
                        addAlert('error', 'La version de la demande que vous essayez de modifier est obsolète.')
                        break
                    default:
                        addAlert('error', "Une erreur s'est produite lors de la mise à jour de la demande")
                }
            }
            else {
                addAlert('success', 'Les photos ont bien été envoyées !')
                hideModal()
                callback(true)
            }
        }        
    }

    return (
        <div className="u-pd-xl">
            <div className="u-bg-white u-br-l u-primary u-pd-m">
                <h1 className="c-h1 u-secondary u-center u-mg-bottom-l">{actionLabel}</h1>
                
                <PicturesValidationSample />

                <hr className="c-dotted-hr" />

                <div className="u-pd-top-l">
                    <form>
                        {showGlobalError &&
                            <p className="u-fs-xs u-danger u-mg-bottom-m">Un problème est survenu lors du chargement des photos</p>
                        }
                        <ul className="l-grid u-reset-list u-fs-xs u-mg-vt-l">
                            {[...Array(4).keys()].map((index) => 
                                <PicturesUpload 
                                    key={index}
                                    index={index} 
                                    formFields={formFields} 
                                    setFormFields={setFormFields} 
                                    setErrors={setErrors} 
                                    showLocalErrors={showLocalErrors}
                                    setFormEvents={setFormEvents}
                                />
                            )}
                        </ul>
                        <div className="u-flex u-flex-center-hz">
                            {formEvents.ready &&
                            <>
                                <button
                                    type="submit"
                                    className="c-btn c-btn--secondary u-pd-hz-l"
                                    onClick={handleSubmit}
                                    disabled={formEvents.disabledSubmit}
                                >
                                    Valider
                                </button>

                                <button
                                    type="button"
                                    className="c-btn c-btn--white-bordered u-pd-hz-l u-mg-left-l"
                                    onClick={hideModal}
                                >
                                    Annuler
                                </button>
                            </>
                            }
                            {!formEvents.ready &&
                                <div className="c-spinner"></div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default FormPicturesValidation