
export const getProviderDataTableCorrelationHeader = () => {
    return Object.keys(getProviderDataTableCorrelation([]))
}

export const getProviderDataTableCorrelation = (columns = []) => {
    const structureData = {
        id: columns[0] || null,
        siret: columns[1] || null,
        raison_sociale: columns[2] || null,
        category: columns[3] || null,
        equipment: columns[4] || null,
        name: columns[5] || null,
        status: columns[6] || null,
    }

    return {
        ...structureData,
    }
}