import React from 'react'

import { useModal } from 'hooks/useModal'
import { useAlert } from 'hooks/useAlert'
import { useAuth } from 'hooks/useAuth'
import {
    CANCEL,
    REQUEST_MOBILITY_VALUE,
    SIGN_OFF_SHEETS_UPLOADED,
    getFeedbackToTrainingBeneficiary,
    isBetweenStatus,
    isCancelled,
    isNeedComplementAfterSignOffSheetsUploaded,
    isNeedComplementDocumentsBid
} from 'utils/domain/request'
import { cancelRequest, downloadRequestPDF } from '../catalogUtils'
import * as CatalogApi from 'services/api/CatalogApi'
import dateService from 'services/date'

import Accordion from 'components/shared/Accordion'
import { formationList } from 'components/catalog/MobilityHelp/list'
import Emoji from 'components/shared/Emoji'
import MobilityHelpPDF from 'components/catalog/MobilityHelp/MobilityHelpPDF'
import FormDocumentBid from 'components/platform/FormDocumentBid'
import FormEmargement from 'components/platform/FormEmargement/FormEmargement'
import ModalConfirmation from 'components/shared/ModalConfirmation'
import BidTable from './ServiceMobilityHelpBidTable'
import BidModalCancelBody from '../BidModalCancelBody'

const ServiceMobilityHelpMonitoring = () => {
    const [requests, setRequests] = React.useState([])
    const [tableData, setTableData] = React.useState({})
    const { showModal, hideModal } = useModal()
    const { addAlert } = useAlert()
    const { user, refreshUser } = useAuth()

    const fetchData = async () => {
        try {
            await refreshUser()
            const { res: requests, getError } = await CatalogApi.getRequests(REQUEST_MOBILITY_VALUE)
            if(getError()) throw getError()

            setRequests(requests)
        } catch (error) {
            addAlert('error', error?.toString())
        }
    }

    // fetch data on first load
    React.useEffect(() => fetchData(), []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleGetRequesProviders = async (requestId) => {
        let requestProviders = []
        try {
            const { res, getError } = await CatalogApi.getRequestProviders(requestId)
            if(getError()) throw getError()

            requestProviders = [...res]
        } catch (error) {
            addAlert('error', error?.toString())
        }
        return requestProviders
    }
    // Providers (for table data) only are get when the accordion is open
    const getProvidersOnCallback = async (request) => {
        const requestProviders = await handleGetRequesProviders(request?.request_id)

        setTableData(formerTableData => ({
            ...formerTableData,
            [request?.request_id]: [{
                ...requestProviders[0],
                summary: {
                    label: formationList?.find(f => f.id === request?.request_description?.type)?.label,
                    personsQuantity: request?.request_description?.personsQuantity,
                    sessionsQuantity: request?.request_description?.sessionsQuantity,
                }
            }]
        }))
    }

    const handleCancelRequest = async (request) => {
        try {
            const { getError: getCancelRequestError } = await cancelRequest({ request })
            if (getCancelRequestError()) throw getCancelRequestError()

            hideModal()
            addAlert('success', 'La demande a bien été annulée !')
            // refresh
            fetchData()
        } catch (error) {
            addAlert('error', error?.toString())
        }
    }
    
    const openCancelRequestModal = async (request) => {
        showModal(
            ModalConfirmation,
            {
                title: 'Voulez-vous annuler ce dossier ?',
                body: BidModalCancelBody,
                cancelLabel: 'Retour',
                validate: () => handleCancelRequest(request)
            }
        )
    }

    const handleDownloadRequestPDF = async (request) => {
        const { error } = await downloadRequestPDF({
            user,
            request,
            pdfTemplate: MobilityHelpPDF
        })

        if (error) addAlert('error', "Une erreur s'est produite lors de la génération du dossier. Veuillez réessayer.")
    }

    const isUploadDisableFn = (requestStatus) => {
        if(isNeedComplementAfterSignOffSheetsUploaded(requestStatus)) return false
        if(isNeedComplementDocumentsBid(requestStatus)) return false

        return true
    }

    return (
        <>
            {requests.map((request, index) => (
                <Accordion
                    key={index}
                    className={`u-mg-bottom-xl ${isCancelled(request?.status) ? 'is-disabled' : ''}`}
                    head={() => (
                        <div className="u-pd-hz-m u-pd-vt-l">
                            <div className="u-flex u-flex-between u-flex-center-vt u-flex-dir-col u-flex-dir-row@main">
                                <div className="u-flex-1">
                                    <h3 className="c-h4 u-font-base u-bold u-mg-bottom-m u-mg-bottom-0@main">Demande de prestation n°{request?.request_fub_id}</h3>
                                </div>
                                <div className="u-flex-0 u-flex">
                                {request?.status !== CANCEL &&
                                    <>
                                        <button
                                            type="button"
                                            className="c-btn c-btn--white u-pd-hz-l u-mg-hz-s u-mg-bottom-m u-mg-bottom-0@main"
                                            onClick={() => handleDownloadRequestPDF(request)}
                                        >
                                            Consulter ma demande (pdf)
                                        </button>
                                        <button
                                            type="button"
                                            className="c-btn c-btn--white u-pd-hz-xl u-mg-hz-s u-mg-bottom-m u-mg-bottom-0@main"
                                            onClick={() => openCancelRequestModal(request)}
                                            disabled={isBetweenStatus({ status: request.status, start: SIGN_OFF_SHEETS_UPLOADED })}
                                        >
                                            Annuler
                                        </button>
                                    </>
                                }
                                </div>
                            </div>
                            <p className="u-fs-s u-bold">{getFeedbackToTrainingBeneficiary(request?.status)}</p><br />
                            <p className="u-fs-s">Envoyée le {dateService.formatDate(request?.create_date, 'dd/MM/y')}</p>
                        </div>
                    )}
                    openingCallback={() => getProvidersOnCallback(request)}
                >
                    <div className="u-pd-hz-m u-pd-vt-l">
                        <h4 className="u-fs-l u-bold u-mg-bottom-m">Retrouver les informations de ma demande de prestation</h4>
                        {tableData && tableData[request?.request_id] && <BidTable data={tableData[request?.request_id]} />}
                    </div>

                    <div className="u-pd-hz-m">
                        <h4 className="u-fs-l u-bold u-mg-bottom-m">Versement de la prime</h4>
                        <div className="u-bg-white u-br-l u-primary u-pd-m u-mg-bottom-m">
                            <FormEmargement 
                                requestPosition={index} 
                                request={request} 
                                refetchRequests={fetchData}
                            />     
                            <FormDocumentBid
                                refreshData={fetchData}
                                isUploadDisableFn={isUploadDisableFn}
                                requestPosition={index}
                                request={request} 
                                isSubmitFormAvailable={request.status} 
                                user={user}
                                labelSubmitDisabled="Veuillez envoyer la ou les feuilles d'émargement"
                                successMessage="Les informations pour accéder au versement de votre prime ont bien été envoyées."
                            />       
                        </div> 
                    </div>   
                    </Accordion>
            ))}
            {requests.length === 0 &&
                <p className="u-center u-mg-top-m">
                    Aucune demande ne semble correspondre à votre recherche <Emoji label="visage triste" symbol="😟" />
                </p>
            }
        </>
    )
}

export default ServiceMobilityHelpMonitoring
