import ProtectedLayout from 'layouts/Protected'
import React from 'react'

import AuditPlanning from './planning'

const Audit = () => {

    return (
        <ProtectedLayout>
            <AuditPlanning/>
        </ProtectedLayout>
    )
}

export default Audit