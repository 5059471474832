import React from 'react'

import { STEPS } from 'pages/protected/Autodiag/context'

import './styles.scss'
import { number, shape, string } from 'prop-types'

const Workflow = ({
    currentStep
}) => {
    const {
        position: currentPosition,
    } = currentStep

    return (
        <div className="u-flex u-flex-dir-row u-flex-between workflow">
            {Object.values(STEPS).slice(1).map((step, index) => (
                <div className="u-flex u-flex-dir-col u-flex-center-vt" key={index}>
                    {step.position === currentPosition && (
                        <div className="currentPosition u-primary u-bold u-fs-m">
                            {step.position}
                        </div>
                    )}
                     {step.position !== currentPosition && (
                        <div className="position" />
                    )}
                    <div className="dot">
                        <div className={`title ${step.position === currentPosition ? 'u-primary u-bold u-fs-m' : ''}`}>
                            {step.title}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

Workflow.propTypes = {
    currentStep: shape({
        position: number,
        title: string
    })
}

export default Workflow