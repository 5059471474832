export const getMyScopingMeetingError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour récupérer les informations de votre réunion de cadrage.'
        default:
            return "Une erreur s'est produite lors de la récupération votre réunion de cadrage."
    }
}

export const getMyScopingMeetingsError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour récupérer vos réunions de cadrage.'
        default:
            return "Une erreur s'est produite lors de la récupération de vos réunions de cadrage."
    }
}

export const getScopingMeetingsError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour récupérer les réunions de cadrage.'
        default:
            return "Une erreur s'est produite lors de la récupération des réunions de cadrage."
    }
}

export const getScopingMeetingError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour récupérer la réunion de cadrage.'
        default:
            return "Une erreur s'est produite lors de la récupération de la réunion de cadrage."
    }
}

export const planScopingMeetingError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour planifier une réunion de cadrage.'
        case 'AutodiagRequiredException':
            return "Vous n'avez pas encore finalisé votre autodiagnostic"
        default:
            return "Une erreur s'est produite lors de la tentative de mise à jour de la requête."
    }
}

export const cancelScopingMeetingError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour annuler une réunion de cadrage.'
        case 'NotFoundException':
            return 'Aucune réunion de cadrage n\'est associée à ce compte.'
        default:
            return "Une erreur s'est produite lors de la tentative de mise à jour de la requête."
    }
}

export const updateScopingMeetingError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour mettre à jour cette réunion de cadrage.'
        case 'NotFoundException':
            return 'Aucune réunion de cadrage n\'est associée à cette entité.'
        case 'InvalidArgument':
            return 'Il y a un problème avec cette réunion de cadrage.'
        default:
            return "Une erreur s'est produite lors de la tentative de mise à jour de cette réunion de cadrage."
    }
}