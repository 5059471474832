import React from "react"
import { useHistory } from "react-router-dom"
import FormElement from "components/shared/FormElement"
import * as UserApi from "services/api/UserApi"
import { useAlert } from "hooks/useAlert"
import { publicRoutes } from 'router/routes'
import { string } from "prop-types"

const defaultFormFields = {
    email: "",
    code: "",
    password: "",
    passwordConfirmation: ""
}

const defaultFormEvents = {
    ready: true,
    sent: false
}

const FormForgotPassword = ({
    successRedirection = publicRoutes.login.path
}) => {
    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [showEmailErrors, setShowEmailErrors] = React.useState(false)
    const [showGlobalError, setShowGlobalError] = React.useState(false)

    const history = useHistory()
    const { addAlert } = useAlert()

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setFormFields({
            ...formFields,
            [name]: value
        })
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            let cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault()
        // Validate inputs
        const formReady = !(errors.length > 0)

        if (!formReady) {
            setShowLocalErrors(true)
        }
        else {
            // hide error
            setShowLocalErrors(false)
            setShowEmailErrors(false)
            // prevent form to be submitted again
            setFormEvents({
                ...formEvents,
                ready: false
            })

            const updatePassword = await UserApi.updatePassword(formFields.code, formFields.password, formFields.email)
           
            if (!updatePassword || updatePassword?.error) {
                switch (updatePassword?.error?.code) {
                    case "NotAuthorizedException":
                        addAlert("error", "Le code de récupération n'est pas valide ou est expiré.")
                        break
                    default:
                        addAlert("error", "Une erreur s'est produite lors de la modification du mot de passe")
                }
                setShowGlobalError(true)
                setFormEvents({
                    ...formEvents,
                    ready: true
                })
            }
            else {
                addAlert("success", "Votre mot de passe a bien été mis à jour")
                // Redirect to login view
                history.push(successRedirection)
            }
        }
    }

    return (
        <form>
            {showGlobalError &&
                <p className="u-fs-xs u-danger u-mg-bottom-m">L'envoi de mail n'a pu être effectué.</p>
            }
            {/* Email */}
            <FormElement
                value={formFields.email}
                name="email"
                type="email"
                label="Email"
                className="u-mg-bottom-l"
                classNameLabel="u-secondary"
                initPassword={true}
                required={true}
                showErrors={showEmailErrors || showLocalErrors}
                setShowErrors={setShowEmailErrors}
                onValidate={handleValidation}
                onChange={handleInputChange}
            />

            {/* Code */}
            <FormElement
                value={formFields.code}
                name="code"
                type="text"
                label="Code de confirmation"
                className="u-mg-bottom-l"
                classNameLabel="u-secondary"
                required={true}
                showErrors={showLocalErrors}
                onValidate={handleValidation}
                onChange={handleInputChange}
            />

            {/* Password */}
            <FormElement
                value={formFields.password}
                name="password"
                type="password"
                label="Nouveau mot de passe"
                className="u-mg-bottom-s"
                classNameLabel="u-secondary"
                passwordCreate={true}
                required={true}
                showErrors={showLocalErrors}
                onValidate={handleValidation}
                onChange={handleInputChange}
            />
            <p className="u-fs-xs u-mg-bottom-l">(8 caractères minimum, au moins 1 chiffre, au moins 1 minuscule, au moins 1 majuscule )</p>

            {/* Password confirmation*/}
            <FormElement
                value={formFields.passwordConfirmation}
                name="passwordConfirmation"
                type="password"
                label="Confirmer le mot de passe"
                className="u-mg-bottom-l"
                classNameLabel="u-secondary"
                passwordToConfirm={formFields.password}
                required={true}
                showErrors={showLocalErrors}
                onValidate={handleValidation}
                onChange={handleInputChange}
            />

            <div className="u-flex u-flex-center-hz u-mg-top-l u-mg-bottom-l">
                {formEvents.ready &&
                    <button
                        type="submit"
                        className="c-btn c-btn--secondary u-pd-hz-xl"
                        onClick={handleSubmit}
                        style={{ maxWidth: "250px" }}
                    >
                        Valider
                    </button>
                }
                {!formEvents.ready && !formEvents.sent &&
                    <div className="c-spinner"></div>
                }
                {!formEvents.ready && formEvents.sent &&
                    <p className="u-center u-success">Un code de confirmation vous a été envoyé à ce mail.</p>
                }
            </div>
        </form>
    )
}

FormForgotPassword.propTypes = {
    successRedirection: string
}

export default FormForgotPassword

