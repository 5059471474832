import React from 'react'
import useMediaQuery from "hooks/useMediaQuery"

const AsideMenuContext = React.createContext({
    menuIsOpen: null,
    toggleAsideMenu: () => {},
})

const useAsideMenu = () => React.useContext(AsideMenuContext)

const AsideMenuProvider = ({ children }) => {
    let isMobile = useMediaQuery('(max-width: 991px)')
    const [menuIsOpen, setMenuIsOpen] = React.useState(isMobile ? false : true)

    const toggleAsideMenu = (isOpen = !menuIsOpen) => {
        setMenuIsOpen(isMobile ? isOpen : true)
        // Side effect
        // document && document.querySelector('body').classList[menuIsOpen ? "remove" : "add"]("u-overflow-hidden")
    }

    return (
        <AsideMenuContext.Provider value={{
            menuIsOpen, toggleAsideMenu
        }}>
            {children}
        </AsideMenuContext.Provider>
    )
}

export { useAsideMenu, AsideMenuProvider }