import { useAuth } from 'hooks/useAuth'
import ProtectedLayout from 'layouts/Protected'
import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { publicRoutes, privateRoutes } from 'router/routes'

import AutodiagProvider, { STEP_6 } from './context'
import Steps from './Steps'

const Autodiag = () => {
    const { step } = useParams()
    const { user } = useAuth()

    const questionsSteps = privateRoutes.autoDiag.paramsAllowed.slice(1, -1)

    if(!privateRoutes.autoDiag.paramsAllowed.includes(step)) {
        return <Redirect to={publicRoutes.notFound.path} />
    }

    return (
        <ProtectedLayout>
            <AutodiagProvider autodiagUser={user}>
                {step === privateRoutes.autoDiag.paramsAllowed[0] && <Steps.Introduction />}
                {questionsSteps.includes(step) && <Steps.Questions key={step} />}
                {step === STEP_6 && <Steps.Restitution />}
            </AutodiagProvider>
        </ProtectedLayout>
    )
}

export default Autodiag