import React, { useState } from 'react'
import { bool, func, oneOf, oneOfType, string } from 'prop-types'

import { useModal } from 'hooks/useModal'

const ModalConfirmation = ({ 
    title,
    subTitle,
    body,
    validate,
    confirmLabel = 'Confirmer',
    confirmType = 'primary',
    cancel = () => {},
    cancelLabel = 'Annuler',
    disabledConfirmInit = false,
    closeAfterValidation = false
}) => {
    const { hideModal } = useModal()
    const [disabledConfirm, setDisabledConfirm] = useState(disabledConfirmInit)
    const [disabledCancel, setDisabledCancel] = useState(false)
    const [bodyResponse, setBodyResponse] = useState(null)

    const cancelAndHideModal = () => {
        cancel()
        hideModal()
    }

    const disableButtons = (disabled) => {
        setDisabledConfirm(disabled)
        setDisabledCancel(disabled)
    }

    const handleValidationAndButtons = async () => {
        try {
            disableButtons(true)
            await validate(bodyResponse)
            
            if(closeAfterValidation) hideModal()
        } catch (error) {
            throw error
        } finally {
            disableButtons(false)
        }
    }

    const onValidateForm = (canValidate, props) => {
        setDisabledConfirm(!canValidate)
        if (props) setBodyResponse(props)
    }

    return (
        <div className="u-pd-xl">
            { title && <h2 className="c-h2 u-primary u-center u-mg-vt-l">{title}</h2> }
            { subTitle && <h4 className="c-h4 u-primary u-center u-mg-vt-l">{subTitle}</h4>}
            { body && (typeof body === 'string') &&
                <div className="u-flex u-flex-center-hz">
                    <p>{body}</p>
                </div>
            }
            { body && (typeof body === 'function') &&
                <div className="u-flex u-flex-center-hz">
                    {React.createElement(body, { onValidateForm })}
                </div>
            }
            <div className="u-flex u-flex-dir-row u-mg-top-l u-flex-around">
                <button
                    type="button" disabled={disabledCancel}
                    className="c-btn c-btn--white-bordered u-pd-hz-l u-mg-bottom-m u-mg-0@main"
                    onClick={cancelAndHideModal}
                >
                    {cancelLabel}
                </button>
                <button
                    type="button" disabled={disabledConfirm}
                    className={`c-btn c-btn--${confirmType} u-pd-hz-l u-mg-bottom-m u-mg-0@main`}
                    onClick={handleValidationAndButtons}
                >
                    {confirmLabel}
                </button>
            </div>
        </div>
    )
}

ModalConfirmation.propTypes = {
    title: string,
    subTitle: string,
    body: oneOfType([string, func]),
    validate: func,
    confirmLabel: string,
    confirmType: oneOf(['primary', 'green', 'secondary', 'white', 'white-bordered', 'danger', 'transparent']),
    cancel: func,
    cancelLabel: string,
    disabledConfirmInit: bool,
    closeAfterValidation: bool
}

export default ModalConfirmation