import React from 'react'
import { arrayOf, bool, func, number, object, string } from 'prop-types'
import { useTable, useSortBy, usePagination, useRowSelect, useFilters, useGlobalFilter } from 'react-table'
import ReactTooltip from 'react-tooltip'

import Emoji from 'components/shared/Emoji'
import ProviderNotFoundHelp from './ProviderNotFoundHelp'

const PAGINATION_LENGTH = 10
const PROVIDERS_LENGTH = 5

const isColumnEllipsis = (columnId) => columnId !== 'distance'

const ProvidersTable = ({
    data,
    hasSelectableRows = false,
    selection,
    selectionLength = PROVIDERS_LENGTH,
    setSelection,
    className,
    displayDistance = true,
    displayDomTom = false,
    isLoadingProvider = false,
    isGeolocation = false
}) => {
    // Columns definition
    const columns = React.useMemo(
        () => {
            const allColumns = [
                {
                    Header: 'Raison sociale',
                    accessor: 'structureName',
                },
                {
                    Header: 'Téléphone',
                    accessor: 'structurePhonenumber',
                },
                {
                    Header: 'Adresse e-mail',
                    accessor: 'contactEmail',
                },
                {
                    Header: 'Site internet',
                    accessor: 'website',
                },
                {
                    Header: 'Localisation',
                    accessor: 'structureCity',
                }
            ]
            if(displayDomTom) allColumns.push({ Header: 'Outre-mer', accessor: 'domTom' })
            if(displayDistance) allColumns.push({ Header: 'Distance à vol d\'oiseau', accessor: 'distance' })
            return allColumns
        },
        [selectionLength] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const {
        getTableProps,
        getTableBodyProps,
        // getToggleAllRowsSelectedProps, // select all rows in all pages
        headerGroups,
        prepareRow,
        // rows,
        page, // the rows for the active page

        // pagination utilities
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        setGlobalFilter,
        state: { pageIndex, selectedRowIds },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 10,
                selectedRowIds: (() => {
                    // filter selection and transform it into selectedRowIds object
                    // ie: {1: true, 2: true, 3: true, 4: true, 5: true}
                    return data ? data.reduce((acc, item, index) => {
                        if (selection.map(row => row.entity_id).includes(item.entity_id)) acc[index] = true
                        return acc
                    }, {}) : null
                })()
            },
            // autoResetSelectedRows: false
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect
    )

    const getColumnSize = React.useCallback((columnName) => {
        // sum should be 24
        switch (columnName) {
            case columns[0]?.accessor:
                return 'c-table__cell--7'
            case columns[1]?.accessor:
                return 'c-table__cell--4'
            case columns[2]?.accessor:
                return 'c-table__cell--8'
            case columns[3]?.accessor:
                return 'c-table__cell--6'
            case columns[4]?.accessor:
                return 'c-table__cell--5'
            case 'domTom': // conditional column
                return 'c-table__cell--4'
            case 'distance': // conditional column
                return 'c-table__cell--5'
            default:
                return 'u-flex-basis-auto'
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Manage global search
    // -------------------
    const [dataTableFilter, setDataTableFilter] = React.useState('')
    React.useEffect(() => setGlobalFilter(dataTableFilter), [dataTableFilter, setGlobalFilter])

    // Manage selection
    // -------------------
    // Each time the data change, the table is reinit and the selectedRowIds reset
    // To prevent infinite loop `set selectedRowIds ==> selectedRowIds change ==> reset selectedRowIds => ...`
    // the table should not react to the first selectedRowIds init
    const tableInitiated = React.useRef(false)
    React.useEffect(() => {
        if (tableInitiated.current && data) {
            // on selectedRowIds change, transform selection into an array of usable rows/item for the parent
            const newSelection = Object.keys(selectedRowIds).map(index => data[index])
            if (setSelection) setSelection(newSelection)
        }
        tableInitiated.current = true

        // Rebind tooltip
        ReactTooltip.rebuild()

        // react-table issue here: we must rely on selectedRowIds since selectedFlatRows is not consistent
        // see https://github.com/tannerlinsley/react-table/issues/2210
    }, [selectedRowIds]) // eslint-disable-line
    // Reset the init var each time data change
    React.useEffect(() => tableInitiated.current = false, [data])

    // Manage pagination
    // -------------------
    const middleDistance = Math.round((PAGINATION_LENGTH - 1) / 2)
    const pageShouldBeHiddenInPagination = (index) => {
        // in the first pages,
        // pagination should not exceed PAGINATION_LENGTH
        const condition1 = (pageIndex <= middleDistance && index > PAGINATION_LENGTH - 1)
        // in the middle pages,
        // show x pages before currentPage and y pages after currentPage
        const condition2 = (
            (pageIndex > middleDistance)
            && (pageIndex < (pageOptions.length - middleDistance))
            && (index < (pageIndex - middleDistance) || index > (pageIndex + middleDistance - 1))
        )
        // in the last pages,
        // always show the x last pages
        const condition3 = (
            (pageIndex > (pageOptions.length - 1 - middleDistance))
            && index < (pageOptions.length - PAGINATION_LENGTH)
        )
        return (condition1 || condition2 || condition3)
    }

    return (
        <>
            <div className={'c-table-providers ' + (className ? className : '')}>

                <div className="c-table-providers__header u-mg-bottom-0@main u-pd-bottom-l">

                    <div className="u-flex u-flex-dir-col u-flex-dir-row@main u-flex-end u-mg-bottom-l u-mg-bottom-0@main">
                        <div className="">
                            {/* Global filter */}
                            <div className={'c-form-group c-form-group--picto u-mg-bottom-l u-mg-bottom-0@main'}>
                                <svg className="c-input-picto" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512">
                                    <path d="M505.749 475.587l-145.6-145.6c28.203-34.837 45.184-79.104 45.184-127.317C405.333 90.926 314.41.003 202.666.003S0 90.925 0 202.669s90.923 202.667 202.667 202.667c48.213 0 92.48-16.981 127.317-45.184l145.6 145.6c4.16 4.16 9.621 6.251 15.083 6.251s10.923-2.091 15.083-6.251c8.341-8.341 8.341-21.824-.001-30.165zM202.667 362.669c-88.235 0-160-71.765-160-160s71.765-160 160-160 160 71.765 160 160-71.766 160-160 160z" />
                                </svg>
                                <input
                                    className={'c-input u-pd-left-xl'}
                                    name={'tableglobalFilter'}
                                    type={'text'}
                                    placeholder={''}
                                    value={dataTableFilter}
                                    style={{ maxWidth: '500px' }}
                                    onChange={(event) => setDataTableFilter(event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <ProviderNotFoundHelp isGeolocation={isGeolocation} />
                </div>

                <table className="c-table c-table--responsive" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr className="c-table__row" {...headerGroup.getHeaderGroupProps()}>
                                {hasSelectableRows &&
                                    <th className={'c-table__cell u-pd-m ' + getColumnSize()} style={{ minWidth: '45px' }}>
                                        {/* <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}
                                    </th>
                                }
                                {headerGroup.headers.map(column => (
                                    <th className={'c-table__cell u-pd-m ' + getColumnSize(column?.id)} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <span className={`${isColumnEllipsis(column?.id) ? 'u-ellipsis' : ''}`}>
                                            {column.render('Header')}
                                        </span>
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr className={'c-table__row u-pd-m u-pd-0@main'} {...row.getRowProps()}>
                                    {hasSelectableRows &&
                                        <td
                                            className={'c-table__cell u-pd-m@main ' + getColumnSize()}
                                            data-tip={`Vous avez déjà sélectionné ${selectionLength} prestataire${selectionLength > 1 ? 's' : ''}`}
                                            data-tip-disable={selection?.length >= selectionLength ? row.getToggleRowSelectedProps()?.checked : true}
                                        >
                                            <IndeterminateCheckbox
                                                disabled={!row.getToggleRowSelectedProps()?.checked && selection?.length >= selectionLength}
                                                className={!row.getToggleRowSelectedProps()?.checked && selection?.length >= selectionLength ? 'is-disabled' : ''}
                                                {...row.getToggleRowSelectedProps()}
                                            />
                                        </td>
                                    }
                                    {row.cells.map(cell => {
                                        return (
                                            <td className={'c-table__cell u-pd-m@main ' + getColumnSize(cell?.column?.id)} {...cell.getCellProps()}>
                                                { cell?.column?.id === 'website'
                                                        ? <a href={`https://${cell?.value}`} className="u-block" target="_blank" rel="noopener noreferrer">
                                                                <span className={'u-ellipsis'}>{cell.render('Cell')}</span>
                                                            </a>
                                                        : <span className={'u-ellipsis'}>{cell.render('Cell')}</span>
                                                }
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                {data.length === 0 &&
                    <p className="u-center u-mg-top-m">
                        Aucun prestataire ne semble correspondre à votre recherche <Emoji label="visage triste" symbol="😟" />
                    </p>
                }

                {/* Pagination */}
                {pageOptions && pageOptions.length > 1 &&
                    <div className="u-flex u-flex-center-hz">
                        <div className="c-pagination">
                            <button
                                type="button" className="c-btn c-btn--primary u-pd-hz-s u-mg-hz-m"
                                onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                            >
                                {'<<'}
                            </button>
                            {pageOptions.map(index => {
                                if (pageShouldBeHiddenInPagination(index)) return <React.Fragment key={index}></React.Fragment>
                                return (
                                    <button
                                        type="button"
                                        key={index}
                                        className={(index === pageIndex ? 'u-bold' : '')}
                                        style={{ width: '30px' }}
                                        onClick={() => gotoPage(index)}
                                    >
                                        {index + 1}
                                    </button>
                                )
                            })}
                            <button
                                type="button" className="c-btn c-btn--primary u-pd-hz-s u-mg-hz-m"
                                onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}
                            >
                                {'>>'}
                            </button>
                        </div>
                    </div>
                }
            </div>
            <ReactTooltip backgroundColor={'#C53762'} />
        </>
    )
}

ProvidersTable.propTypes = {
    data: arrayOf(object),
    hasSelectableRows: bool,
    selection: arrayOf(object),
    selectionLength: number,
    setSelection: func,
    className: string,
    displayDistance: bool,
    displayDomTom: bool,
    isLoadingProvider: bool,
    isGeolocation: bool
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, className, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input
                    className={'c-checkbox ' + (className || '')}
                    type="checkbox"
                    ref={resolvedRef}
                    {...rest}
                />
            </>
        )
    }
)

IndeterminateCheckbox.propTypes = {
    indeterminate: bool,
    className: string
}

export default ProvidersTable