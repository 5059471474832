import React from 'react'
import { number, string } from 'prop-types'

import { DEFAULT_SIZE } from 'utils/icons'

const Padlock = ({ size = DEFAULT_SIZE, className }) => {
  return(
    <svg id="Layer_1" enableBackground="new 0 0 34 34" height={size} viewBox="0 0 34 34" width={size} xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor">
        <path d="m26 14v-4c0-5-4-9-9-9s-9 4-9 9v4c-1.7 0-3 1.3-3 3v13c0 1.6 1.3 3 3 3h18c1.7 0 3-1.4 3-3v-13c0-1.7-1.3-3-3-3zm-16-4c0-3.9 3.1-7 7-7s7 3.1 7 7v4h-14zm8 14.8v2.2c0 .6-.4 1-1 1s-1-.4-1-1v-2.2c-1.2-.4-2-1.5-2-2.8 0-1.7 1.3-3 3-3s3 1.3 3 3c0 1.3-.8 2.4-2 2.8z"/>
    </svg>
  )
}

Padlock.propTypes = {
  size: number,
  className: string
}

export default Padlock