import React from 'react'

import FormElement from 'components/shared/FormElement'
import { isNotEmpty } from 'utils/validation'

import { isPDFExtension, generateThumbnailFromMedia } from 'utils/file'

const PictureUpload = ({
    index,
    formFields,
    setFormFields,
    showLocalErrors,
    setErrors,
    setFormEvents
}) => {
    const [pdfThumbnail, setPdfThumbnail] = React.useState()

    const handleInputChange = (event) => {
        const value = event.target
        const index = value.name
        const updatedPhotos = formFields.photos
        const reader = new FileReader()
        reader.onload = (e) => {
            // Create local url from blob
            value.localUrl = e.target.result
            updatedPhotos[index] = value
            setFormFields(s => ({
                ...s,
                photos: updatedPhotos
            }))
            setFormEvents(formEvents => ({
                ...formEvents,
                disabledSubmit: formFields?.photos?.length < 4
            }))
        }
        reader.readAsDataURL(value.blob)  
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const photo = formFields.photos[index]

    const getLabel = (index) => {
        switch (index) {
            case 0:
                return 'Vue d’ensemble de l’installation dans son environnement'
            case 1:
                return 'Vue d’ensemble de l’installation avec les supports d’attache visibles'
            case 2:
                return 'Zoom sur un support d’attache'
            default:
                return 'Photo complémentaire'
        }
    }

    React.useEffect(() => {
		// Build thumbnail image from PDF
		if (isNotEmpty(photo?.localUrl) && isPDFExtension(photo?.extension)) {
            generateThumbnailFromMedia({ media: photo?.localUrl }).then((file) => {
                console.log('file', file)
                if (typeof file === 'string') setPdfThumbnail(file)
			})
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [photo?.localUrl])


return (
    <li key={index} className="l-col-12 l-col-3@main u-pd-xs u-pd-hz-s">
            <FormElement
                value={photo}
                forcePlaceholder={photo?.full_media_url && 'Modifier la photo'}
                type="file"
                name={index}
                label={'Photo ' + (index + 1) + ' : ' + getLabel(index)}
                maxFileSize={10}
                acceptPDF={true}
                className="u-mg-bottom-m"
                required={true}
                showErrors={showLocalErrors}
                onValidate={handleValidation}
                onChange={handleInputChange}
            />
            <p className='u-fs-xxs'> jpg, png, pdf (10 Mo max) </p>
            {isNotEmpty(photo) && !isPDFExtension(photo?.extension) &&
                <>
                    {(photo?.localUrl || photo?.full_media_url)
                        && <img src={photo?.localUrl || photo?.full_media_url} alt="Votre document téléversé" />
                    }
                </>
            }

            {isPDFExtension(photo?.extension) &&
                <img src={pdfThumbnail} alt="Votre document téléversé" />
            }
    </li>
)

}

export default PictureUpload