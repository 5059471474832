import React from 'react'
import { useModal } from 'hooks/useModal'
import { func, string } from 'prop-types'

const ModalInfo = ({ 
    title = '', 
    body = '', 
    action,
    actionLabel = 'Confirmer'
}) => {
    const { hideModal } = useModal()

    const confirmAndHideModal = () => {
        action()
        hideModal()
    }

    return (
        <div className="u-pd-xl">
            { title && <h2 className="c-h2 u-primary u-center u-mg-vt-l">{title}</h2> }
            { body &&
                <div className="u-flex u-flex-center-hz">
                    <p>{body}</p>
                </div>
            }
            <div className="u-flex u-flex-dir-row u-mg-top-l u-flex-around">
                <button
                    type="button"
                    className={'c-btn c-btn--primary u-pd-hz-l u-mg-bottom-m u-mg-0@main'}
                    onClick={confirmAndHideModal}
                >
                    {actionLabel}
                </button>
            </div>
        </div>
    )
}

ModalInfo.propTypes = {
    title: string,
    body: string,
    action: func,
    actionLabel: string
}

export default ModalInfo