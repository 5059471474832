import React from 'react'
import SidePanel from 'components/back-office/layout/side-panel'
import Breadcrumb from 'components/back-office/layout/breadcrumb'
import Footer from 'components/Footer'
import { string, node, bool } from 'prop-types'
import './styles.scss'

const BackOfficeLayout = ({ children, className, showSidePanel = true, showFooter = true }) => {
    return (
        <div className="back-office-layout">
            { showSidePanel && <SidePanel /> }
            <div className="back-office-right-part">
                <Breadcrumb />
                <main className={`c-main-content u-flex u-flex-dir-col ${(className || '')}`}>
                    { children }
                </main>
            </div>
            {showFooter && <Footer className="back-office-footer" />}
        </div>
    )
}

BackOfficeLayout.propTypes = {
    children: node,
    className: string,
    showSidePanel: bool,
    showFooter: bool
}

export default BackOfficeLayout
