import React from 'react'
import {
    Text,
    View,
} from '@react-pdf/renderer'

import styles from 'components/pdf/PDFStyles'
import DataViewPDF from 'components/pdf/PDFDataView'
import RequestPDF from 'components/pdf/PDFRequestTemplate'
import { monitoringPDFpropTypes } from 'components/pdf/monitoringPDFpropTypes'

import { calculateMobilityHelpAmount, getMobilityHelpFormationByType } from 'utils/domain/request'

const MobilityHelpPDF = ({ beneficiary, request, provider, personsQuantity, sessionsQuantity }) => {
    const formation = getMobilityHelpFormationByType(request?.request_description?.type)
    const quantity = personsQuantity | sessionsQuantity
    const { totalAmount } = calculateMobilityHelpAmount({
        type: request?.request_description?.type,
        quantity
    })

    return (
        <RequestPDF
            beneficiary={beneficiary}
            title={`Demande de prestation n°${request?.request_fub_id}`}
            comment={request?.request_description?.comment}
            createDate={request?.create_date}
            provider={provider}
        >
            <View style={styles.section} wrap={false}>
                <View>
                    <Text style={[styles.h2, styles.textTransform, styles.bold]}>Prestation de formation à la pratique du vélo au quotidien souhaitée</Text>
                </View>
                <View style={styles.grid}>
                    <View style={styles.sectionLeft}>
                        <Text style={styles.h3}>{formation?.label}</Text>
                        {personsQuantity && !sessionsQuantity &&
                            <DataViewPDF
                                label="Nombre de personnes"
                                value={personsQuantity + ' personne(s)'}
                                styles={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: 200 }}
                            />
                        }
                        {sessionsQuantity &&
                            <DataViewPDF
                                label="Nombre de sessions"
                                value={sessionsQuantity + ' session(s)'}
                                styles={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: 200 }}
                            />
                        }
                        <DataViewPDF
                            label="Montant de la prestation"
                            value={(totalAmount || ' - ') + ' €'}
                            styles={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: 200 }}
                        />
                    </View>
                    <View style={styles.sectionRight}>
                        {/* Illustration */}
                    </View>
                </View>
            </View>
        </RequestPDF>
    )
}

MobilityHelpPDF.propTypes = monitoringPDFpropTypes

export default MobilityHelpPDF