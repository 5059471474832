import React from 'react'
import { object, string, func, shape, bool } from 'prop-types'
import { employeeCategory, getCorrespondingEmployeeCategory } from 'utils/domain/beneficiary'
import { isNotEmpty, isValidFrenchPhoneNumber } from 'utils/validation'
import { jsonHash } from 'utils/hash'

import Card from 'components/platform/Card'
import FormElement from 'components/shared/FormElement'
import EyeSee from 'components/svg/EyeSee'
import TrashIcon from 'components/svg/Trash'

const Informations = ({
    formFields = {},
    setFormFields = () => {},
    className = '',
    formEvents = {},
    showLocalErrors = {},
    handleValidation = () => {},
    setLastCategoryEmployeeKnow = () => {},
    lastCategoryEmployeeKnow = {}
}) => {

    React.useEffect(() => {
        let correspondingCategory = null

        // useEffect on formFields?.structureEmployeeQuantity 
        // => When the user changes the number of employees, we change the corresponding category in the select

        // useEffect on formFields?.structureEmployeeCategor 
        // => When we receive the structureEmployeeCategory, it's an object like : {value: 'employeeCategory-2', label: 'Entre 50 et 250 salariés'}
        // We only keep the value for the formFields then
        if(isNotEmpty(formFields?.structureEmployeeQuantity) || formFields?.structureEmployeeCategory?.value) {
            correspondingCategory = getCorrespondingEmployeeCategory(formFields?.structureEmployeeQuantity)
            setLastCategoryEmployeeKnow(correspondingCategory)
        } else {
            correspondingCategory = lastCategoryEmployeeKnow
        }

        // To prevent unnecessary reloading, we add jsonHash to compare the two data 
        if(isNotEmpty(correspondingCategory?.value)) {
            if(jsonHash(formFields.structureEmployeeCategory) !== jsonHash(correspondingCategory.value)) {
                setFormFields((s) => ({
                    ...s,
                    structureEmployeeCategory: correspondingCategory.value
                }))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields?.structureEmployeeQuantity, formFields?.structureEmployeeCategory])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
            ? target.checked
            : target.type === 'file'
                ? target
                : target.value
        const name = target.name

        setFormFields({
            ...formFields,
            [name]: value
        })
    }

    return (
        <>
            <Card title="Informations bénéficiaire" className={className}>
                <div className="l-grid">
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4 ">
                        {/* Raison Sociale */}
                        <FormElement
                            value={formFields.structureName}
                            name="structureName"
                            type="text"
                            label="Raison Sociale"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            required={true}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={!formEvents.editable} //
                        />
                    </div>
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                        {/* Nature de l'établissement */}
                        <FormElement
                            value={formFields.entityGender}
                            name="entityGender"
                            type="text"
                            label="Nature de l'établissement"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            required={true}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={true}
                        />
                    </div>
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                        {/* SIRET */}
                        <FormElement
                            value={formFields.structureSiret}
                            name="structureSiret"
                            type="text"
                            label="SIRET"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            required={true}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={true}
                        />
                    </div>
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                        {/* Adresse */}
                        <FormElement
                            value={formFields.structureAddress}
                            name="structureAddress"
                            type="text"
                            label="Adresse"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            required={true}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={!formEvents.editable}
                        />
                    </div>
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                        {/* Ville */}
                        <FormElement
                            value={formFields.structureCity}
                            name="structureCity"
                            type="text"
                            label="Ville"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            required={true}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={!formEvents.editable}
                        />
                    </div>
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                        {/* Code Postal */}
                        <FormElement
                            value={formFields.structurePostCode}
                            name="structurePostCode"
                            type="text"
                            label="Code Postal"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            required={true}
                            customRules={[
                                {
                                    key: 'structurePostCode-invalid',
                                    check: (value) => {
                                        return value ? (value.toString().length === 5) : true
                                    },
                                    message: "Le code postal n'est pas valide."
                                }
                            ]}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={!formEvents.editable}
                        />
                    </div>
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                        {/* Téléphone */}
                        <FormElement
                            value={formFields.structurePhoneNumber}
                            name="structurePhoneNumber"
                            type="text"
                            label="Tél de l'établissement"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            required={true}
                            customRules={[
                                {
                                    key: 'structure-phone-invalid',
                                    check: (value) => isValidFrenchPhoneNumber(value),
                                    message: "Le format de numéro de téléphone n'est pas valide."
                                }
                            ]}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={!formEvents.editable}
                        />
                    </div>
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                        {/* Date de création du compte */}
                        <FormElement
                            value={formFields.createDate}
                            name="createDate"
                            type="text"
                            label="Date de création du compte"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            required={true}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={true}
                        />
                    </div>
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                        {/* Responsable du compte */}
                        <FormElement
                            value={formFields.accountSupervisor}
                            name="accountSupervisor"
                            type="text"
                            label="Responsable du compte"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            required={true}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={true}
                        />
                    </div>
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                        {/* Tél du responsable */}
                        <FormElement
                            value={formFields.contactPhonenumber}
                            name="contactPhonenumber"
                            type="text"
                            label="Tél du responsable"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            required={true}
                            customRules={[
                                {
                                    key: 'structure-phone-invalid',
                                    check: (value) => isValidFrenchPhoneNumber(value),
                                    message: "Le format de numéro de téléphone n'est pas valide."
                                }
                            ]}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={!formEvents.editable}
                        />
                    </div>
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                        {/* Email de connexion */}
                        <FormElement
                            value={formFields.mail}
                            name="mail"
                            type="text"
                            label="E-mail de connexion"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            required={true}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={true}
                        />
                    </div>
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                        {/* Email de contact */}
                        <FormElement
                            value={formFields.contactEmail}
                            name="contactEmail"
                            type="mail"
                            label="E-mail de contact"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            required={true}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={!formEvents.editable}
                        />
                    </div>
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                        {/* Nombre d'employés */}
                        <FormElement
                            value={formFields.structureEmployeeQuantity}
                            name="structureEmployeeQuantity"
                            type="text"
                            label="Nombre d'employés"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            customRules={[
                                {
                                    key: 'employee-quantity-invalid',
                                    check: (value) => {
                                        return value ? (value > 0) : true
                                    },
                                    message: 'Le nombre de salariés doit être positif non nul.'
                                }
                            ]}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={!formEvents.editable}
                        />
                    </div>
                    <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                        {/* Catégorie salariés */}
                        <FormElement
                            value={lastCategoryEmployeeKnow}
                            options={employeeCategory}
                            name="structureEmployeeCategory"
                            type="select"
                            customRules={[
                                {
                                    key: 'employee-category-invalid',
                                    check: () => {
                                        // Validate that structureEmployeeCategory corresponds to the structureEmployeeQuantity
                                        return formFields.structureEmployeeQuantity
                                        ? getCorrespondingEmployeeCategory(formFields?.structureEmployeeQuantity)?.value === formFields.structureEmployeeCategory
                                        : true
                                    }, 
                                    message: 'Le nombre d\'employés doit être cohérent avec la catégorie salariés.'
                                }
                            ]}
                            localValidation={formFields.structureEmployeeCategory}
                            label="Catégorie salariés"
                            className="u-mg-bottom-m"
                            classNameInput="u-bg-transparent"
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                            disabled={true}
                        />
                    </div>
                    {formFields?.additionalDocument &&
                        <div className="u-mg-top-m u-pd-right-m u-pd-left-m l-col-4  ">
                            {/* Additional document */}
                            <div className="c-form-group">
                                <label className="c-label u-mg-bottom-xs ">Pièce complémentaire </label>
                                <div className='u-flex u-flex-between'>
                                    <a
                                        href={formFields?.additionalDocument?.full_media_url}
                                        target="_blank"
                                        className={'u-flex u-flex-center-hz u-flex-center-vt u-pd-s'}
                                        style={{ minWidth: '50px', borderRadius: '50px' }}
                                        rel="noreferrer noopener"
                                        aria-label={'Voir le fichier existant'}
                                        data-tip={'Voir le fichier existant'}
                                    >
                                        <span className='u-mg-right-s'>Voir le fichier</span> <EyeSee size={20} />
                                    </a>
                                    {formEvents.editable && 
                                        <button 
                                            title='Supprimer le document du bénéficiaire' 
                                            type="button" 
                                            className='u-mg-left-s' 
                                            onClick={() => setFormFields(prevFormFields => ({
                                                ...prevFormFields,
                                                isAdditionalDocumentDeleted: true,
                                            }))}
                                            >
                                            <TrashIcon size={22} color={formFields.isAdditionalDocumentDeleted ? 'red' : 'grey'} />
                                        </button>
                                    }
                                </div>
                                {formFields.isAdditionalDocumentDeleted && <div className='u-fs-xxs '>Le document du bénéficiaire sera supprimé dès que vous aurez validé les modifications.</div>}
                            </div>
                        </div>
                    }
                </div>  
            </Card>
        </>
    )
}

Informations.propTypes = {
    formFields: shape({
        structureName: string,
        entityGender: string,
        structureSiret: string,
        structureAddress: string,
        structureCity: string,
        structurePostCode: string,
        structurePhoneNumber: string,
        createDate: string,
        accountSupervisor: string,
        contactPhonenumber: string,
        mail: string,
        contactEmail: string,
        beneficiaryEmail: string,
        structureEmployeeQuantity: string,
        structureEmployeeCategory: string,
        additionalDocument: object
    }),
    setFormFields: func,
    className: string,
    formEvents: object,
    showLocalErrors: bool,
    handleValidation: func,
    setLastCategoryEmployeeKnow: func,
    lastCategoryEmployeeKnow: object
}

export default Informations