

export const equipmentBikeShelterWithFasteners = {
    label: 'Abri ouvert et supports d’attache ',
    shortLabel: 'Abri',
    id: 'bike_shelter_fasteners',
    optional: false,
    description: '',
    labelSelection: 'Nombre de vélos à attacher',
    paymentLimit: 650,
    order: 1
}

export const equipmentBikeShelter = {
    label: 'Abri ouvert pour attaches existantes ',
    shortLabel: 'Abri',
    id: 'bike_shelter',
    optional: false,
    description: '',
    labelSelection: 'Nombre de vélos à abriter',
    paymentLimit: 650,
    order: 2
}

export const equipmentBikeSecureStorageWithFasteners = {
    label: 'Consigne sécurisée et supports d’attache',
    shortLabel: 'Consigne',
    id: 'bike_secure_storage_fasteners',
    optional: false,
    description: '',
    labelSelection: 'Nombre de vélos à attacher',
    paymentLimit: 1200,
    order: 3
}

export const equipmentBikeSecureStorage = {
    label: 'Consigne sécurisée pour attaches existantes',
    shortLabel: 'Consigne',
    id: 'bike_secure_storage',
    optional: false,
    description: '',
    labelSelection: 'Nombre de vélos à abriter',
    paymentLimit: 1200,
    order: 4
}

export const equipmentBikeHitch = {
    label: 'Supports d’attache dans un abri ou local existant ',
    shortLabel: 'Attaches',
    id: 'bike_hitch',
    optional: false,
    description: '',
    labelSelection: 'Nombre de vélos à attacher',
    paymentLimit: 80,
    order: 5
}


export const equipmentBikePumps = {
    label: 'Station de gonflage',
    shortLabel: 'Station de gonflage',
    id: 'bike_pumps',
    optional: true,
    description: '',
    financing: null,
    paymentLimit: 300,
}

export const equipmentBikeToolkit = {
    label: "Station d'outillage",
    shortLabel: 'Station d’outillage',
    id: 'bike_toolkit',
    optional: true,
    description: null,
    financing: null,
    paymentLimit: 300,
}

export const equipmentLockers = {
    label: 'Casiers',
    shortLabel: 'Casiers',
    id: 'lockers',
    optional: true,
    description: null,
    financing: null,
    paymentLimit: 300,
}

export const equipmentList = [
    equipmentBikeShelter,
    equipmentBikeShelterWithFasteners,
    equipmentBikeSecureStorage,
    equipmentBikeSecureStorageWithFasteners,
    equipmentBikeHitch,
    equipmentBikePumps,
    equipmentBikeToolkit,
    equipmentLockers
]