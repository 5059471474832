import { zniList } from 'utils/geo'

export const PRIVATE_SECTOR_VALUE = 0
export const PRIVATE_SECTOR_ID = 'private-beneficiary'

export const PUBLIC_SECTOR_VALUE = 1
export const PUBLIC_SECTOR_ID = 'public-beneficiary'

export const ASSOCIATION_VALUE = 2
export const ASSOCIATION_ID = 'association-beneficiary'

export const PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE = 3
export const PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_ID = 'private-public-vocation-beneficiary'

export const BENEFICIARY_ROLE = 'beneficiary'
export const BENEFICIARY_ID = BENEFICIARY_ROLE

export const KBIS = 'KBIS'
export const SIRET = 'SIRET'
export const SIRENE = 'SIRENE'

export const employeeCategory = [
    { value: 'employeeCategory-1', label: 'Moins de 50 salariés' },
    { value: 'employeeCategory-2', label: 'Entre 50 et 250 salariés' },
    { value: 'employeeCategory-3', label: 'Entre 250 et 1000 salariés' },
    { value: 'employeeCategory-4', label: 'Plus de 1000 salariés' }
]

export const getEmployeeCategoryLabelFromCategoryValue = (categoryValue) => {
    const categoryValueFound = employeeCategory.find(category => category.value === categoryValue)?.label
    return categoryValueFound || ''
}

export const beneficiary = {
    role: BENEFICIARY_ROLE
}

const privateBeneficiary = {
    ...beneficiary,
    id: PRIVATE_SECTOR_ID,
    value: PRIVATE_SECTOR_VALUE,
    label: 'Secteur privé',
}

const privateWithPublicVocationBeneficiary = {
    ...beneficiary,
    id: PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_ID,
    value: PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE,
    label: 'Secteur privé à vocation publique',
}

const publicBeneficiary = {
    ...beneficiary,
    id: PUBLIC_SECTOR_ID,
    value: PUBLIC_SECTOR_VALUE,
    label: 'Secteur public',
}

const associationBeneficiary = {
    ...beneficiary,
    id: ASSOCIATION_ID,
    value: ASSOCIATION_VALUE,
    label: 'Association',
}

export const beneficiaryList = [    
    privateBeneficiary,
    privateWithPublicVocationBeneficiary,
    publicBeneficiary,
    associationBeneficiary
]

export const getBeneficiaryByValue = (value) => beneficiaryList.find(beneficiary => beneficiary.value === value)

export const isKBISFolder = (value) => value === PRIVATE_SECTOR_VALUE

export const isSIRENEFolder = (value) => value === PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE

export const isSIRETFolder = (value) => value === PUBLIC_SECTOR_VALUE || value === ASSOCIATION_VALUE

export const getFolderTypeLabel = (value) => {
    if(isKBISFolder(value)) return KBIS

    if(isSIRENEFolder(value)) return SIRENE

    if(isSIRETFolder(value)) return SIRET

    return null
}

export const getBackOfficeStatus = (entityCheck, isSendingFub = false) => {
    if(isSendingFub) return 'Transmis FUB'
    if(!entityCheck || !entityCheck.action_benef) return 'À valider'
    if(entityCheck && entityCheck.is_check) return 'Validé' // warning : entityCheck.is_check and entityCheck.action_benef can be true together. But if is_check is true, it has priority, so folder is validated.
    if(entityCheck && entityCheck.action_benef) return 'En attente'
    return 'Consulter'
}

export const BACK_OFFICE_ENTITY_OPERATIONS = {
    checkEntity: {
        validate: true,
        askMoreInformations: false,
    }
}

export const getCheckEntityBody = (entity, entityCheck) => ({
    entity_id: entity.id,
    entity_check: entityCheck,
    last_row_hash: entity.rowHash
})

export const isInZNIList = (postCode) => zniList.includes(parseInt(postCode))

// Automate the employee category
export const getCorrespondingEmployeeCategory = (quantity) => {
    if (!quantity) return null
    return (quantity < 50)
        ? employeeCategory[0]
        : (quantity > 249)
            ? (quantity > 999) ? employeeCategory[3] : employeeCategory[2]
            : employeeCategory[1]
}