import React from 'react'
import {
    Text,
    View,
} from '@react-pdf/renderer'
import { equipmentList } from 'components/catalog/Equipment/list'
import styles from './PDFStyles'
import DataViewPDF from './PDFDataView'
import RequestPDF from './PDFRequestTemplate'
import { monitoringPDFpropTypes } from './monitoringPDFpropTypes'
import { isNotEmptyNumber, isNotEmpty } from 'utils/validation'

const EquipementPDF = ({ beneficiary, request, provider }) => {

    return (
        <RequestPDF
            beneficiary={beneficiary}
            title={`Devis n°${request?.request_fub_id}`}
            comment={request?.request_description?.comment}
            createDate={request?.create_date}
            provider={provider}
        >
            <View style={styles.section} wrap={false}>
                <View>
                    <Text style={[styles.h2, styles.textTransform, styles.bold]}>Votre projet de stationnement</Text>
                </View>
                <View style={styles.grid}>
                    <View style={styles.sectionLeft}>
                       {equipmentList && equipmentList.filter(i => !i.optional).map((item, index) => {
                            const itemValue = isNotEmpty(request?.request_description[item?.id]) ? Number(request?.request_description[item?.id]) : 0
                            return (
                                <div key={index}>
                                    {isNotEmptyNumber(itemValue) &&
                                        <DataViewPDF
                                            label={item?.label}
                                            key={index}
                                            value={`${itemValue.toString()} place(s)`}
                                            styles={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: 300 }}
                                        />
                                    }
                                </div>
                            )
                        })}

                        {equipmentList && equipmentList.filter(i => i.optional).map((item, index) => {
                             const itemValue = isNotEmpty(request?.request_description[item?.id]) ? Number(request?.request_description[item?.id]) : 0
                            const optionalEquipmentLabel = () => {
                                switch (item.id) {
                                    case 'lockers':
                                        return 'casier(s)'
                                    case 'bike_toolkit':
                                            return "station(s) d'outillage"
                                    default:
                                        return 'station(s) de gonflage'
                                }
                            }
                            return (
                                <div key={index}>
                                     {isNotEmptyNumber(itemValue) &&
                                        <DataViewPDF
                                            label={item?.label.toString()}
                                            key={index}
                                            value={`${itemValue} ${optionalEquipmentLabel()}`}
                                            styles={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: 300 }}
                                        />
                                    }
                                </div>
                            )
                        })} 

                        <DataViewPDF
                            label={'Pose par le fournisseur'}
                            value={request?.request_description?.installation}
                            styles={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: 300 }}
                        />
                    </View>
                    <View style={styles.sectionRight}>
                        {/* Illustration */}
                    </View>
                </View>
            </View>
        </RequestPDF>
    )
}

EquipementPDF.propTypes = monitoringPDFpropTypes

export default EquipementPDF