import httpService from 'services/http'
import { getError } from 'services/api/errors'
import {
    getTiersError,
    getRefundsError,
    getRefundError,
    putRefundError
} from './errors'

export const getTiers = async () => {
    const res = await httpService.get('/oepvapi/backend_fub/tiers').catch(e => httpService.catchError(e))
    console.log('getTiers res: ', res)
    return {
        res,
        getError: getError(res, getTiersError)
    }
}

export const getRefunds = async () => {
    const res = await httpService.get('/oepvapi/backend_fub/refunds').catch(e => httpService.catchError(e))
    console.log('getRefunds res: ', res)
    return {
        res,
        getError: getError(res, getRefundsError)
    }
}

export const putRefund = async (refundGender) => {
    const res = await httpService.put(`/oepvapi/backend_fub/refund?gender=${refundGender}`).catch(e => httpService.catchError(e))
    console.log('putRefund res: ', res)
    return {
        res,
        getError: getError(res, putRefundError)
    }
}

export const getRefundById = async (refund_id) => {
    const res = await httpService.get(`/oepvapi/backend_fub/refund/${refund_id}`).catch(e => httpService.catchError(e))
    console.log('getRefundById res: ', res)
    return {
        res,
        getError: getError(res, getRefundError)
    }
}