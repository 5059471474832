import React from "react"
import { default as ReactDatePicker, registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { isNotEmpty } from "utils/validation"
import fr from 'date-fns/locale/fr'
import useMediaQuery from "hooks/useMediaQuery"

registerLocale('fr', fr)

const Datepicker = ({
    // Attributes
    value,
    name,
    label = "",
    filterDate = null,
    disabled,
    readonly = false,
    showYearDropdown = false,
    // Styles
    style,
    className,
    classNameLabel = "",
    classNameInput = "",
    // Error management
    required = false,
    customRules,
    showErrors,
    // Handler
    onChange,
    onValidate
}) => {
    const [errors, setErrors] = React.useState([])
    let isMobile = useMediaQuery('(max-width: 991px)')

    const rules = React.useMemo(() => [
        {
            key: "datepicker-empty",
            check: (value) => required ? isNotEmpty(value) : true,
            message: "Ce champ est obligatoire"
        },
        ...(customRules || [])
    ], [required]) // eslint-disable-line
    // customRules in dependencies array trigger an infinite loop,
    // because custom rules include a check property defining a new function,
    // preventing useMemo to return a valid shallow-comparison
    // or event useDeepMemo to return a valid deep-comparison

    const validate = React.useCallback((currentValue) => {
        let newErrors = []
        rules.forEach(rule => {
            if (!rule.check(currentValue)) {
                newErrors.push({
                    origin: name,
                    rule: rule.key,
                    message: rule.message
                })
            }
        })
        setErrors(newErrors)
        onValidate(name, newErrors)
    }, [rules, name, onValidate])

    // validate on mount and each time value change
    React.useEffect(() => {
        validate(value)
        return () => {
            // On unmounted erros and validation are no longer needed
            setErrors([])
            if (onValidate) onValidate(name, [])
        }
    }, [value, validate, onValidate, name])

    const handleChange = (event) => {
        const inputValue = {
            target: {
                name: name,
                value: event,
            }
        }
        onChange(inputValue)
    }

    return (
        <>
            <div className={"c-form-group " + (className)} style={style}>
                <label className={"c-label u-mg-bottom-xs " + classNameLabel} htmlFor="code">{label} {required && "*"}</label>
                <ReactDatePicker
                    selected={new Date(value)}
                    isDisabled={disabled}
                    locale="fr"
                    dateFormat="dd/MM/y"
                    showYearDropdown={showYearDropdown}
                    closeOnScroll={true}
                    filterDate={filterDate}
                    readOnly={readonly}
                    disabled={readonly}
                    // customInput={({ value, onClick }) => (
                    //     <input className="example-custom-input" onClick={onClick}>
                    //       {value}
                    //     </button>
                    // )}
                    className={"c-datepicker " + (classNameInput ? classNameInput : "")}
                    onChange={(event) => {
                        !readonly && onChange && handleChange(event)
                    }}
                    // open calendar in a portal on mobile
                    withPortal={isMobile}
                />

                {/* Error messages */}
                {showErrors && errors && errors.map((error, index) => {
                    return <p key={index} className="u-fs-xs u-danger">{error.message}</p>
                })}
            </div>
        </>
    )
}


export default Datepicker
