import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { boolean } from 'prop-types'
import { useAuth } from 'hooks/useAuth'
import { isNotEmpty } from 'utils/validation'
import FormElement from 'components/shared/FormElement'
import localStorageService, { REMEMBER_MAIL_KEY } from 'services/localStorage'
import { useAlert } from 'hooks/useAlert'
import { privateRoutes, publicRoutes } from 'router/routes'

import useBackOfficeRoutes from 'hooks/useBackOfficeRoutes'

const defaultFormFields = {
    email: process.env.REACT_APP_DEMO_EMAIL || '',
    password: process.env.REACT_APP_DEMO_PASSWORD || '',
    rememberMail: false
}

const defaultFormEvents = {
    ready: true
}

const FormSignin = ({
    backOfficeSignIn = false
}) => {
    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [showGlobalError, setShowGlobalError] = React.useState(false)

    const history = useHistory()
    const { addAlert } = useAlert()
    const { login } = useAuth()
    const { isCurrentRouteBackOffice } = useBackOfficeRoutes()
    const lostPasswordPath = isCurrentRouteBackOffice ? publicRoutes.backOffice.lostPassword.path : publicRoutes.lostPassword.path
    const homePath = isCurrentRouteBackOffice ? privateRoutes.backOffice.backOfficeHome.path : privateRoutes.home.path

    React.useEffect(() => {
        const email = localStorageService.get(REMEMBER_MAIL_KEY)
        if (isNotEmpty(email)) {
            setFormFields({
                ...formFields,
                email,
                rememberMail: true
            })
        }
    }, []) // eslint-disable-line

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setFormFields({
            ...formFields,
            [name]: value
        })
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault()
        // Validate inputs
        const formReady = !(errors.length > 0)

        if (!formReady) {
            setShowLocalErrors(true)
        }
        else {
            // hide error
            setShowLocalErrors(false)
            // prevent form to be submitted again
            setFormEvents({
                ...formEvents,
                ready: false
            })

            if (formFields.rememberMail) {
                localStorageService.set(REMEMBER_MAIL_KEY, formFields.email)
            } else {
                localStorageService.remove(REMEMBER_MAIL_KEY)
            }

            const signIn = await login(formFields.email.trim(), formFields.password, { backOfficeSignIn })

            if (!signIn || signIn?.error) {
                switch (signIn?.error?.code) {
                    case 'NotAuthorizedException':
                        addAlert('error', 'Mail ou mot de passe invalide.')
                        break
                    case 'TooManyRequestsException':
                        addAlert('error', 'Votre compte est bloqué, le nombre maximal de tentatives a été atteint.')
                        break
                    case 'EntityDisableException':
                        addAlert('error', 'Votre compte a été désactivé. Si vous pensez qu’il s’agit d’une erreur, envoyez un e-mail à contact@employeurprovelo.fr.')
                        break
                    default:
                        addAlert('error', "Une erreur s'est produite lors de la connexion")
                }
                setShowGlobalError(true)
                setFormEvents({
                    ...formEvents,
                    ready: true
                })
            } else {
                history.push(homePath)
            }
        }
    }

    return (
        <div className="l-form-width u-mg-hz-auto">
            <form className="">
                {showGlobalError &&
                    <p className="u-fs-xs u-danger u-mg-bottom-m">L'identifiant ou le mot de passe semble incorrect.</p>
                }
                {/* Mail */}
                <FormElement
                    value={formFields.email}
                    name="email"
                    type="email"
                    label="E-mail"
                    className="u-mg-bottom-l"
                    classNameLabel="u-secondary"
                    required={true}
                    showErrors={showLocalErrors}
                    onValidate={handleValidation}
                    onChange={handleInputChange}
					autocomplete="new-email"
                />

                {/* Password */}
                <FormElement
                    value={formFields.password}
                    name="password"
                    type="password"
                    label="Mot de passe"
                    className="u-mg-bottom-l"
                    classNameLabel="u-secondary"
                    required={true}
                    showErrors={showLocalErrors}
                    onValidate={handleValidation}
                    onChange={handleInputChange}
                    withPatternRule={false}
					autocomplete="new-password"
                />

                <div className="u-flex u-flex-end u-flex-center-vt u-flex-dir-col u-flex-dir-row@main">
                    <NavLink to={lostPasswordPath} className="u-fs-xs u-underline u-mg-bottom-s">Mot de passe perdu ?</NavLink>
                </div>

                {/* Remember id */}
                <FormElement
                    value={formFields.rememberMail}
                    name="rememberMail"
                    type="checkbox"
                    label=""
                    checkboxLabel="Se souvenir de moi"
                    className="u-pd-vt-m"
                    showErrors={showLocalErrors}
                    onValidate={handleValidation}
                    onChange={handleInputChange}
                />

                <div className="u-flex u-flex-center-hz u-mg-top-m u-mg-bottom-m">
                    {formEvents.ready &&
                        <button
                            type="submit"
                            className="c-btn c-btn--secondary u-pd-hz-xl"
                            onClick={handleSubmit}
                        >
                            Se connecter
                        </button>
                    }
                    {!formEvents.ready &&
                        <div className="c-spinner"></div>
                    }
                </div>
            </form>
        </div>
    )
}

FormSignin.propType = {
    backOfficeSignIn: boolean
}

export default FormSignin
