import React from 'react'
import { useParams } from 'react-router-dom'
import Emoji from 'components/shared/Emoji'
import CatalogRoutes from 'components/catalog/CatalogRoutes'

import ServiceEquipmentMonitoring from 'components/catalog/Equipment/ServiceEquipmentMonitoring'
import ServiceMobilityHelpMonitoring from 'components/catalog/MobilityHelp/ServiceMobilityHelpMonitoring'
import ServiceTechnicalMonitoring from 'components/catalog/Technical/ServiceTechnicalMonitoring'
import AdviceAndSupportMonitoring from 'components/catalog/AdviceAndSupport/AdviceAndSupportMonitoring'


const Benefit = () => {
    const { slug } = useParams()

    if(slug === CatalogRoutes[0].url)
        return (<ServiceEquipmentMonitoring/>)
    else if(slug === CatalogRoutes[1].url)
        return (<ServiceMobilityHelpMonitoring/>)
    else if(slug === CatalogRoutes[2].url)
        return (<ServiceTechnicalMonitoring/>)
    else if(slug === CatalogRoutes[3].url)
        return (<AdviceAndSupportMonitoring/>)

    return (
        <p className="u-center u-mg-top-m">
            Il semble y avoir un problème dans l'url de navigation <Emoji label="visage triste" symbol="😟" />
        </p>
    )
}

export default Benefit