import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from 'components/shared/ScrollToTop'
import Providers from 'components/shared/Providers'
import Seo from 'components/shared/Seo'
import Router from 'router/Router'

const App = () => {
    return (
        <Providers>
            <Seo />
            <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
                <ScrollToTop />
                <Router />
            </BrowserRouter>
        </Providers>
    )
}

export default App
