import Reparation from 'assets/images/Reparation.svg'
import Atelier_participatif from 'assets/images/Atelier_participatif.svg'

const generateQuantityOptions = (length) =>
    Array.from({ length }, (_, i) => ({ value: i + 1, label: i + 1 }))

export const bikeOverhaul = {
    label: 'Révision du vélo des salariés',
    shortLabel: 'Révision du vélo des salariés',
    id: 'bike-overhaul',
    picture: Reparation,
    description: `
Dans un atelier participatif, le mécano c'est le.a salarié.e ! Sur le lieu de travail, un mécanicien cycle qualifié met à sa disposition conseils, outillage et matériel. L'objectif est de les faire gagner en autonomie dans le réglage, l'entretien et la réparation de leur vélo. L'animateur montre et guide le personnel sur les gestes à réaliser pendant une séance d'une durée de 3h. Les besoins de réparation sont préalablement identifiés au moyen d'un questionnaire.
    `,
    duration: '½ à 1h',
    target: '4 à 14 vélos du personnel',
    place: 'A l\'entreprise',
    slot: 'Sur le temps de travail',
    price: '70€ HT par vélo (contrôle + entretien et petites réparations)',
    quantitySelection: {
        property: 'bikesQuantity',
        options: generateQuantityOptions(30),
        label: 'Vélos à contrôler/réparer'
    },
    unitPrice: 70,
    refundPortion: 0.4,
    display: true,
    content: `
### Objectifs
- Etablir un diagnostic de l’état du vélo
- Effectuer des réglages
- Procéder au changement de certaines pièces

### Contenu (adaptable selon les besoins)

Cette prestation combine à la fois du contrôle technique et de l’entretien. Néanmoins, il ne peut s’agir d’une remise en état complète d’un vélo qui nécessiterait plusieurs heures de travail. Pour la réussite et le succès de cette prestation, il est important que les salariés remplissent le « questionnaire préalable pour les participants ».

Contrôle d’une trentaine de points (liste non exhaustive) dont :
- Freins,
- Dérailleurs,
- Resserrage du jeu de direction,
- Nettoyage des galets et lubrification de la chaîne,
- Contrôle général de la transmission et des points de sécurité,
- Réglage des vitesses,
- Réglage des freins,
- Changement de chaîne, câbles, gaines, manettes, plaquettes de frein, cassette,
- Réparation d’une crevaison,
- Dévoilage d’une roue,
- Intervention éclairage.

### Modalités

- Nombre de vélos : minimum 4, maximum 14.
- Durée : 45 min par vélo.
- Lieu : site employeur (un endroit abrité et éclairé à proximité du lieu de stationnement des vélos).

### L’employeur

- Prend les inscriptions et transmet la liste des participants ainsi que le questionnaire à l’intervenant 7
jours avant la date de l’intervention. Cela permet à l’intervenant d’éventuellement prendre contact
avec le personnel pour avoir des précisions sur les besoins de réparation et les pièces à prévoir,
- Convient avec l’intervenant des modalités logistiques (dans l’établissement ou chez l’intervenant),
- Importe la [feuille d'émargement](https://employeurprovelo.fr/wp-content/uploads/2023/11/Feuille-emargement-Revision-velo-salaries-20231103.pdf) transmise par l'intervenant

### Coût de l’intervention

Prix forfaitaire de 70 € par vélo (un vélo par personne) pris en charge à 40 % par le programme, soit un reste à charge de 42 € par vélo. Les pièces détachées sont à la charge du personnel. Ce forfait comprend les frais de déplacement de l’intervenant, l’envoi et traitement du questionnaire préalable et le temps passé sur les vélos..
    `
}

export const technicalWorkshops = {
    label: 'Atelier mécanique participatif',
    shortLabel: 'Atelier mécanique participatif',
    id: 'technical-workshops',
    picture: Atelier_participatif,
    description: `
Dans un atelier participatif, le mécano c'est le.a salarié.e ! Sur le lieu de travail, un mécanicien cycle qualifié met à sa disposition conseils, outillage et matériel. L'objectif est de les faire gagner en autonomie dans le réglage, l'entretien et la réparation de leur vélo. L'animateur montre et guide le personnel sur les gestes à réaliser pendant une séance d'une durée de 3h. Les besoins de réparation sont préalablement identifiés au moyen d'un questionnaire.
    `,
    duration: '3h',
    target: '4 à 12 vélos par session',
    place: 'A l\'entreprise',
    slot: 'Sur le temps de travail',
    price: '400€ HT par session',
    quantitySelection: {
        property: 'sessionsQuantity',
        options: generateQuantityOptions(10),
        label: 'Nombre de sessions'
    },
    unitPrice: 400,
    refundPortion: 0.4,
    display: true,
    content: `
### Objectifs
- Gagner en autonomie sur l'entretien à la réparation de son propre vélo
- Être sensibilisé au bon entretien de son vélo (pression des pneus, huilage de la chaîne, vérification des freins…)
- Connaître les équipements obligatoires du vélo
- Obtenir des conseils pour les réglages ergonomiques et le confort

### Contenu (adaptable selon les besoins)

- Réglages du vélo (freins, vitesses, selle, guidon...)
- Réparations simples comme le changement :
   - d'étriers ou patins de frein
   - de câbles et gaines, de chaîne
   - de pédales
   - de chambre à air ou de pneu...
- Pose d’accessoire

### L’employeur

- Prend les inscriptions et transmet la liste des participant·e·s à l'intervenant 10 jours avant la date de l'intervention. Cela permet à l'intervenant d'éventuellement prendre contact avec le personnel pour avoir des précisions sur les besoins de réparation et les pièces à prévoir
- Met à disposition de l'intervenant :
   - un espace abrité et éclairé de 15-20m² à proximité des vélos à vérifier
   - deux tables et une chaise
   - des cartons ou une bâche pour protéger le sol
   - Importe la [feuille d'émargement](https://employeurprovelo.fr/wp-content/uploads/2023/12/Atelier-participatif_Feuille-demargement.pdf) transmise par l'intervenant sur la plateforme OEPV 

### Le salarié

- S'inscrit en amont de la session
- Remplit un questionnaire pour préciser son besoin
- Vient à la session avec son vélo

### Modalités

Coût forfaitaire de 400 € HT par session, pris en charge à 40% par le programme Objectif Employeur Pro-Vélo, soit un reste à charge pour l’employeur de 240 €. Les pièces sont à la charge du personnel qui doit les fournir. Ce forfait comprend les frais de déplacement de l’intervenant, l’envoi et traitement du questionnaire préalable et le temps d’animation.
    `
}

export const technicalCheck = {
    label: 'Révision du vélo des salariés',
    shortLabel: 'Révision du vélo des salariés',
    id: 'technical-check',
    picture: Reparation,
    duration: '½ à 1h',
    target: '4 à 14 vélos du personnel',
    place: 'A l\'entreprise',
    slot: 'Sur le temps de travail',
    price: '70€ HT par vélo (contrôle + réparation)',
    quantitySelection: {
        property: 'bikesQuantity',
        options: generateQuantityOptions(30),
        label: 'Vélos à contrôler/réparer'
    },
    unitPrice: 70,
    refundPortion: 0.4,
    display: false,
}


export const technicalMaintenance = {
    label: 'Entretien et réparation des vélos personnels des employés',
    shortLabel: 'Entretien réparation vélos',
    id: 'technical-maintenance',
    duration: '45 min max par vélo',
    target: '4 à 12 vélos par session',
    place: 'A l\'Entreprise ou sur le lieu de l\'intervenant ',
    slot: 'Sur le temps de travail',
    price: '50€ HT par vélo',
    quantitySelection: {
        property: 'bikesQuantity',
        options: generateQuantityOptions(30),
        label: 'Vélos à contrôler/réparer'
    },
    unitPrice: 50,
    refundPortion: 0.4,
    display: false,
}

export const formationList = [
    bikeOverhaul,
    technicalWorkshops,
    technicalCheck,
    technicalMaintenance
]