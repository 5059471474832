import { isEmpty } from 'lodash'
import { format, parseISO } from 'date-fns'
import dateService from 'services/date'
import XLSX from 'xlsx'
import { isValidFrenchPhoneNumber, isNotEmpty } from 'utils/validation'

const DATE_REGEX = /^\d{2}\/\d{2}\/\d{4}$/
const LONG_DATE_REGEX = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3}/

export const getFileType = (targetResult) => {
  let header = ''
  let type = ''

  const arr = new Uint8Array(targetResult).subarray(0, 4)
  for (let i = 0; i < arr.length; i++) {
    header += arr[i].toString(16)
  }

  switch (header) {
    case '89504e47':
      type = 'image/png'
      break
    case '47494638':
      type = 'image/gif'
      break
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      type = 'image/jpeg'
      break
    case '25504446':
      type = 'application/pdf'
      break
    default:
      type = 'unknown'
      break
  }

  return type
}

export const exportXlsx = (columns, columnsIsBO, isBo, data, name = '') => {
  // prepare data
  const tabToExport = []
  if (isBo) {
    tabToExport.push(Object.values(columnsIsBO))
    data.forEach((item) => {
      const rowToPush = []
      Object.keys(columnsIsBO).forEach((columnKey) => {
        rowToPush.push(item[columnKey])
      })
      tabToExport.push(rowToPush)
    })
  }
  if (!isBo) {
    tabToExport.push(Object.values(columns))
    data.forEach((item) => {
      const rowToPush = []
      Object.keys(columns).forEach((columnKey) => {
        rowToPush.push(item[columnKey])
      })
      tabToExport.push(rowToPush)
    })
  }
  // prepare xlsx
  const wb = XLSX.utils.book_new()
  //convertStringsToDate(tabToExport)
  const ws = XLSX.utils.aoa_to_sheet(tabToExport)
  addFormatsToCells(ws)
  // export sheet then file
  XLSX.utils.book_append_sheet(wb, ws, 'export-oepv')
  XLSX.writeFile(
    wb,
    `${name}-${dateService.getCurrentDate('yyyy-MM-dd')}.xlsx`
  )
}

export const convertDataToStringFromArray = (array) => {
  if (isEmpty(array) || array.length === 0) return array
  return array.map((item) => (isNotEmpty(item) ? item.toString() : ''))
}

export const addFormatsToCells = (sheet) => {
  const range = XLSX.utils.decode_range(sheet['!ref'])

  for (let row = range.s.r; row <= range.e.r; row++) {
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col })
      const cell = sheet[cellAddress]
      

      if (cell && isNotEmpty(cell.v)) {
        const cellValue = cell?.v.toString()

        // On va formatter les cellules en fonction de leur contenu afin qu'excel les reconnaisse comme tel
        // Si la cellule est un nombre
        if(!isNaN(cellValue) && !(DATE_REGEX.test(cellValue) || LONG_DATE_REGEX.test(cellValue)) && !isValidFrenchPhoneNumber(cellValue)) {
          cell.v = Math.round(cellValue)
          cell.z = '#,##0'

        // Si la cellule est une date : 2 possibilités
        } else if(LONG_DATE_REGEX.test(cellValue)){
          // Format de type 2022-07-05 09:33:02.010
          cell.z = 'dd/MM/yyyy'
          cell.v = format(parseISO(cellValue), 'dd/MM/yyyy'); // Formatage de la date avec date-fns
        } else if(DATE_REGEX.test(cellValue)){
          // Format de type 05/07/2022
          cell.z = 'dd/MM/yyyy'
        }
      }
    }
  }
}

export function convertStringsToDate(arr) {
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      if (typeof arr[i][j] === 'string' && DATE_REGEX.test(arr[i][j])) {
       const newDate = dateService.convertFrenchStringDateToEnglishStringDate(arr[i][j])
       console.log('newDate', newDate)
        return arr[i][j] = newDate
      }
    }
  }
  return arr
}
