import httpService from 'services/http'
import {
    getError,
    createAutoDiagError,
    updateAutoDiagError,
    getAutoDiagsError,
    updateStatusError,
    getAutoDiagsFromBackOfficeError
} from 'services/api/errors'

const RESPONSE_LIMITATION = 200

const getAutoDiags = async () => {
    const res = await httpService.get('oepvapi/autodiags', {}).catch(e => httpService.catchError(e))
    //console.log('getAutoDiags res: ', res)
    return {
        res,
        getError: getError(res, getAutoDiagsError),
    }
}

const getAllAutoDiagsFromBackOffice = async ({
    diagStatus,
    createBefore
}) => {
    const createdBeforeParam = createBefore?.toString() ? `created_before=${createBefore}` : null
    const diagStatusParam = diagStatus?.toString() ? `diag_status=${diagStatus}` : null
    const params = [createdBeforeParam, diagStatusParam].filter(param => !!param).join('&')
    const res = await httpService.get(`oepvapi/backend_fub/diags?${params}`).catch(e => httpService.catchError(e))

    // oepvapi/backend_fub/diags API route is limited to 200 response. To get all diag, we use a loop do/while.
    if (res.length === RESPONSE_LIMITATION) {
        let nextRes = []
        do {
            const { create_date } = res[res.length - 1]
            const newCreatedBeforeParam = create_date?.toString() ? `created_before=${create_date}` : null
            const params = [diagStatusParam, newCreatedBeforeParam].filter(param => !!param).join('&')
            nextRes = await httpService.get(`oepvapi/backend_fub/diags?${params}`).catch(e => httpService.catchError(e))
            res.push(...nextRes)
        } while (nextRes.length === RESPONSE_LIMITATION)
    }

    return {
        res,
        getError: getError(res, getAutoDiagsFromBackOfficeError),
    }
}

const getAutoDiag = async (diag_id) => {
    const res = await httpService.get(`oepvapi/autodiag/${diag_id}`, {}).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, getAutoDiagsError),
    }
}

const getAutoDiagByEntity = async (entity_id) => {
    const res = await httpService.get(`oepvapi/backend/autodiag/entity/${entity_id}`, {}).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, getAutoDiagsError),
    }
}

const getAutoDiagByEntityForDashboardAdmin = async (entity_id) => {
    const res = await httpService.get(`/oepvapi/backend_fub/autodiags/${entity_id}`, {}).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, getAutoDiagsError),
    }
}


const createAutoDiag = async (data) => {
    const res = await httpService.post('oepvapi/autodiag', data, {}).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, createAutoDiagError),
    }
}

const updateAutoDiag = async (diag_id, data) => {
    const res = await httpService.put(`oepvapi/autodiag/${diag_id}`, data, {}).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, updateAutoDiagError),
    }
}

const updateStatus = async (data) => {
    const res = await httpService.put(`oepvapi/entity`, data, {}).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, updateStatusError),
    }
}

export {
    getAutoDiags,
    getAllAutoDiagsFromBackOffice,
    getAutoDiag,
    getAutoDiagByEntity,
    getAutoDiagByEntityForDashboardAdmin,
    createAutoDiag,
    updateAutoDiag,
    updateStatus,
}