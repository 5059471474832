import React from "react"

const CrossIcon = ({ size, className }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="100"  width="100" viewBox="0 0 512.001 512.001" style={{ width: size, height: size }} className={className || ""} aria-hidden="true">
			<path fill="currentColor" d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z"/>
		</svg>
	)
}

export default CrossIcon
