import React from 'react'

const Accordion = ({
    head,
    children,
    options = { collapsed: true },
    openingCallback,
    className,
    closingCallback
}) => {
    const [collapsed, setCollapsed] = React.useState(!!options?.collapsed)
    React.useEffect(() => {
        if (!collapsed && openingCallback) openingCallback()
    }, [collapsed]) // eslint-disable-line

    React.useEffect(() => {
        if(closingCallback) setCollapsed(true)
    }, [closingCallback]) // eslint-disable-line

    return (
        <div className={`c-accordion ${options?.className || ""} ${className || ""}`}>
            <div className={`c-accordion__head ${options?.labelClassName || ""} ${!collapsed ? "is-visible" : ""}`}>
                {React.createElement(head)}
            </div>
            <div className={`c-accordion__body ${options?.bodyClassName || ""} ${!collapsed ? "is-visible u-pd-top-m" : ""}`}>
                {!collapsed && children}
            </div>
            <button
                type="button"
                className="c-accordion__trigger"
                onClick={() => setCollapsed(c => !c)}
            >
                <span className="u-fs-m u-bold">{collapsed ? "+" : "-"}</span>
            </button>
        </div>
    )
}

export default Accordion