import React from 'react'
import { useHistory } from 'react-router-dom'
import FormElement from 'components/shared/FormElement'
import { useBasket } from 'hooks/useBasket'
import { useAlert } from 'hooks/useAlert'
import { privateRoutes } from 'router/routes'
import { equipmentList } from './list'
import { isNotEmptyNumber, isNotEmpty } from 'utils/validation'

const defaultFormEvents = {
    ready: true
}

const ServiceEquipmentSelection = () => {
    const { currentBasket, setBasket } = useBasket()
    const { addAlert } = useAlert()
    const defaultFormFields = React.useMemo(() => ({
        // Add an entry in formFields for each equipment
        ...equipmentList.reduce((acc, { id, label }) => {
            // Lors d'un retour arrière, on envoie la quantité précédemment envoyée
            const currentLabel = currentBasket?.find(item => item.label === label)
            const quantity = currentBasket && isNotEmpty(currentLabel) ? currentLabel?.quantity : 0
            acc[id] = quantity
            return acc
        }, {})
    }), [currentBasket])

    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)

    const history = useHistory()

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        setFormFields(s => ({
            ...s,
            [name]: value
        }))
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault()
        // Validate inputs
        const formReady = !(errors.length > 0)
        if (!formReady) {
            setShowLocalErrors(true)
        }
        else {
            // Ici on ne vérifie pas les erreurs du formulaire car nous avons 6 champs input number que l'on va modifier ci-après, si nécessaire.
            const basket = equipmentList
                .filter(equipment => Number(formFields[equipment.id]) !== 0 && formFields[equipment.id] !== '') // On filtre sur les champs différents de zéro et non vide
                .map(equipment => {
                    return {
                        id: equipment.id,
                        label: equipment?.label,
                        quantity: isNotEmpty(formFields[equipment.id]) ? Math.abs(formFields[equipment.id]) : 0, // Si un des champs number est "" alors on le passe à 0
                        optional: equipment?.optional
                    }
                })
            if (!basket.length) {
                addAlert('error', 'Veuillez sélectionner au moins un équipement')
            } else {
                // hide error
                setShowLocalErrors(false)
                // prevent form to be submitted again
                setFormEvents({
                    ...formEvents,
                    ready: false
                })
                setBasket(basket)
                // Redirect to bid view
                history.push(`${privateRoutes.serviceRequest.path}/prestation-equipement`)
            }
        }
    }

    // Manage optional equipments
    const [showOptionalEquipments, setShowOptionalEquipments] = React.useState(false)
    React.useEffect(() => {
        // if there is a non-optional equip. in the basket, we can showOptionalEquipments
        const mandatoryEquipmentAreSelected = equipmentList
            .filter(equipment => !equipment?.optional && formFields[equipment.id] !== '0').length > 0
        setShowOptionalEquipments(mandatoryEquipmentAreSelected)
    }, [formFields])

    // On désactive la saisie des champs number si un des 5 présents dans configuration est complété (donc différent de 0) 
    const mandatoryEquipment = isNotEmptyNumber(formFields.bike_hitch) || isNotEmptyNumber(formFields.bike_secure_storage) || isNotEmptyNumber(formFields.bike_shelter) || isNotEmptyNumber(formFields.bike_secure_storage_fasteners) || isNotEmptyNumber(formFields.bike_shelter_fasteners)
    const optionalEquipment = isNotEmptyNumber(formFields.bike_pumps) || isNotEmptyNumber(formFields.bike_toolkit) || isNotEmptyNumber(formFields.lockers)
    const disabledConfigurationFields = (currentItem) => {
        return Number(currentItem) === 0 && mandatoryEquipment
    }

    return (
        <form>
            <section>
            <h3 className="c-h3 u-bold u-primary u-center u-mg-bottom-l">La configuration d'aménagement de votre projet</h3>
                <div className="l-grid u-mg-negative-hz-m">
                    {equipmentList
                        .filter(i => !i.optional)
                        .sort((a, b) => {
                            return a.order - b.order
                        })
                        .map((item, index) => {
                        return (
                            <div className="l-col-12 l-col-6@main u-pd-m" key={index}>
                                <article className={'c-item u-pd-m u-pd-l@main ' + (!disabledConfigurationFields(formFields[item.id]) ? '' : 'is-disabled')}>
                                    <div className="u-flex-1">
                                        <h3 className="c-h3 u-white u-bold u-mg-bottom-m">{item?.label}</h3>
                                    </div>
                                    <div className='u-flex u-flex-end-vt'>
                                        <FormElement
                                            value={formFields[item.id]}
                                            name={item.id}
                                            type="number"
                                            label={item?.labelSelection}
                                            className="u-flex-0 is-light"
                                            selectClassname="u-bg-transparent" 
                                            showErrors={showLocalErrors}
                                            onValidate={handleValidation}
                                            onChange={handleInputChange}
                                            disabled={disabledConfigurationFields(formFields[item.id])}
                                            customRules={[
                                                {
                                                    key: 'account-number-invalid',
                                                    check: (value) => value <= 10000,
                                                    message: 'Le nombre de vélos ne peut pas dépasser 10 000'
                                                }
                                            ]}
                                        />
                                        <p className='u-mg-left-m u-fs-xxs'>place(s)</p>
                                    </div>
                                </article>
                            </div>
                        )
                    })}
                </div>
            </section>

            <hr className="u-mg-vt-xl" />

            <section>
                <h3 className="c-h3 u-bold u-primary u-center u-mg-bottom-l">Equipements complémentaires</h3>
                <div className="l-grid u-mg-negative-hz-m">
                    {equipmentList && equipmentList.filter(i => i.optional).map((item, index) => {
                        const quantityLabel = () => {
                            switch (item.id) {
                                case 'lockers':
                                    return 'casier(s)'
                                case 'bike_toolkit':
                                        return "station(s) d'outillage"
                                default:
                                    return 'station(s) de gonflage'
                            }
                        }

                        return (
                            <div className={'l-col-12 l-col-6@main u-pd-m'} key={index}>
                                <article className={'c-item u-pd-m u-pd-l@main ' + (showOptionalEquipments ? '' : 'is-disabled')}>
                                    <h3 className="c-h3 u-white u-bold u-mg-bottom-l">{item?.label}</h3>
                                    {item?.description && item?.description.map((d, i) => (<p key={i} className="u-mg-bottom-m">{d}</p>))}
                                    {item?.financing && item?.financing.map((f, i) => (<p key={i} className="u-mg-bottom-m">{f}</p>))}
                                    <div className='u-flex u-flex-end-vt'>
                                        <FormElement
                                            value={formFields[item.id]}
                                            name={item.id}
                                            type="number"
                                            label="Quantité"
                                            className="u-flex-0 is-light"
                                            selectClassname="u-bg-transparent"
                                            showErrors={showLocalErrors}
                                            onValidate={handleValidation}
                                            onChange={handleInputChange}
                                            customRules={[
                                                {
                                                    key: 'account-number-invalid',
                                                    check: (value) => value <= 10000,
                                                    message: 'La quantité ne peut pas dépasser 10 000'
                                                }
                                            ]}
                                        />
                                        <p className='u-mg-left-m u-fs-xxs'>{quantityLabel()}</p>
                                    </div>
                                </article>
                            </div>
                        )
                    })}
                </div>
            </section>

            <div className="u-flex u-flex-center-hz u-pd-vt-l u-mg-bottom-l">
                <button
                    type="submit"
                    className="c-btn c-btn--primary u-pd-hz-xl"
                    onClick={handleSubmit}
                    disabled={!mandatoryEquipment && !optionalEquipment} // Il faut au moins un champ complété pour passer à l'étape suivante
                >
                    Continuer la demande
                </button>
            </div>

            <section>
                <h3 className="c-h3 u-bold u-primary u-center u-mg-bottom-m">Besoin d'informations ?</h3>
                <div className="u-flex u-flex-center-hz u-pd-vt-m u-mg-bottom-m">
                    <a href="https://employeurprovelo.fr/wp-content/uploads/2022/06/OEPV_Guide-stationnement_20220616_page.pdf" target={'_blank'} rel="noopener noreferrer" className="c-btn c-btn--secondary u-pd-hz-l u-mg-hz-m">
                        Guide stationnement vélo 
                    </a>
                    <a href="https://employeurprovelo.fr/objectif-employeur-pro-velo/catalogue/stationnement-velo/" target={'_blank'} rel="noopener noreferrer" className="c-btn c-btn--secondary u-pd-hz-l u-mg-hz-m">
                        Prise en charge financière 
                    </a>
                </div>
            </section>
        </form>
    )
}

export default ServiceEquipmentSelection
