import React from 'react'
import flow from 'lodash.flow'
import * as Ibantools from 'ibantools'

import { useAuth } from 'hooks/useAuth'
import { useAlert } from 'hooks/useAlert'
import * as UtilsApi from 'services/api/UtilsApi'
import * as UserApi from 'services/api/UserApi'
import { isValidFrenchPhoneNumber, isSiretValid, isNotEmpty, isBICValid, isIBANValid } from 'utils/validation'
import { objectsHaveDiverged } from 'utils/functions'
import { scrollToTop } from 'utils/scrollTo'
import { getKeywords } from 'utils/domain/scopingMeetings'

import ProtectedLayout from 'layouts/Protected'
import FormElement from 'components/shared/FormElement'

const defaultFormEvents = {
    ready: true,
    editable: false
}

const availabilityOptions = [
    { value: true, label: 'Oui' },
    { value: false, label: 'Non' }
]

const getFormFieldsFromUserData = (userData) => {
    return {
        structureName: userData?.structureName || '',
        structureSiret: userData?.structureSiret || '',
        structureAddress: userData?.structureAddress || '',
        structurePostCode: userData?.structurePostCode || '',
        structureCity: userData?.structureCity || '',
        structurePhonenumber: userData?.structurePhonenumber || '',
        connexionEmail: userData?.mail || '',
        contactEmail: userData?.contactEmail || '',
        website: userData?.website || '',
        framing_meeting_available: userData?.framing_meeting_available,
        lot: userData?.lot.toString() || '',
        iban: userData?.iban || '',
        bic: userData?.bic || '',
    }
}

const ScopingMeetingAccountPage = () => {
    const { user, refreshUser } = useAuth()

    const [formFields, setFormFields] = React.useState(getFormFieldsFromUserData(user?.entity_data))

    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [showGlobalError, setShowGlobalError] = React.useState(false)
    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)

    const { addAlert } = useAlert()

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
            ? target.checked
            : target.type === 'file'
                ? target
                : target.value
        const name = target.name

        setFormFields({ ...formFields, [name]: value })
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [ ...cleanErrors, ...errs ]
        })
    }, [])

    const getScopingMeetingUserToEdit = () => ({
        entity_id: user?.entity_id,
        entity_data: {
            ...flow([
                Object.entries,
                // only keep state properties starting with (structure|asso|contact)
                arr => arr.filter(([key, value]) => key.match(new RegExp(/^(structure|asso|contact).*$/))),
                Object.fromEntries
            ])(formFields),
            contactEmail: formFields?.contactEmail,
            website: formFields?.website,
            iban: formFields?.iban,
            bic: formFields?.bic
        },
        status: user?.status,
        keywords: getKeywords(formFields),
        last_row_hash: user?.row_hash
    })

    const handleSubmit = async (event) => {
        event.preventDefault()
        // Validate inputs
        const formReady = !(errors.length > 0)
        if (!formReady) {
            setShowLocalErrors(true)
            setShowGlobalError(true)
            addAlert('error', 'Erreur(s) dans le formulaire.')
            scrollToTop()
            return 
        }

        try {
            setShowGlobalError(false)
            setShowLocalErrors(false)
            // prevent form to be submitted again
            setFormEvents({ ...formEvents, ready: false })

            const editedUser = getScopingMeetingUserToEdit()

            // Recalculate coordinates if address has changed
            if (objectsHaveDiverged(user.entity_data, formFields, ['structureAddress', 'structurePostCode'])) {
                console.info('Coordinates have changed')
                const coordinates = await UtilsApi.getCoordinatesFromAdress(formFields?.structureAddress, formFields?.structurePostCode)                

                if (isNotEmpty(coordinates)) {
                    editedUser.entity_data['latitude'] = coordinates[1]
                    editedUser.entity_data['longitude'] = coordinates[0]
                } else {
                    throw new Error('Adresse non reconnue, code postal inexistant')
                }
            }

            await updateUser(editedUser)

            addAlert('success', 'Modification enregistrée')
            toggleEditableForm()
        } catch (error) {
            addAlert('error', error?.toString())
            setShowGlobalError(true)
        } finally {
            setFormEvents(oldFormEvents => ({ ...oldFormEvents, ready: true }))
        }
    }

    const updateUser = async (userToUpdate) => {
        const { getError } = await UserApi.updateUser({
            ...userToUpdate,
            entity_data: {
                ...user.entity_data,
                ...userToUpdate.entity_data
            }
        })
        if (getError()) throw getError()
    }

    const toggleEditableForm = () => {
        // Force the refresh of the user to avoid competitive access with old hash
        refreshUser()
        
        setFormEvents(formEvents => ({
            ...formEvents,
            editable: !formEvents.editable
        }))
    }

    const cancelModifications = () => {
        toggleEditableForm()
        setFormFields(getFormFieldsFromUserData(user?.entity_data))
    }

    const isDisabled = !formEvents.editable

    return (
        <ProtectedLayout className="my-account-page u-pd-hz-m u-pd-top-m u-pd-hz-0@main u-pd-top-xl@main">
            <h1 className="c-h1 u-secondary u-center u-uppercase u-mg-bottom-xl">Mon compte</h1>
            <div className="u-mg-bottom-l l-container-lg">
                <div className='u-primary u-bold u-mg-bottom-s'>Bienvenue sur votre espace personnel OEPV !</div>
                <div className="u-fs-xs">
                    Pour toute question sur le Programme ou pour modifier vos informations, contactez notre équipe support en envoyant un mail à l’adresse suivante :
                    <a className="u-primary u-va-baseline" href="mailto:catalogue@employeurprovelo.fr"> catalogue@employeurprovelo.fr</a>
                </div>
            </div>
            <form>

                {showGlobalError &&
                    <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur lors de la validation du formulaire.</p>
                }

                {/* Structure */}
                <div className="u-pd-top-m">
                    <div className="l-container-lg">
                        <h2 className="c-h2 u-bold u-primary u-mg-bottom-l">Nature de l'établissement</h2>
                        <div className="l-grid u-mg-negative-hz-m">
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Raison sociale */}
                                <FormElement
                                    value={formFields.structureName}
                                    name="structureName"
                                    type="text"
                                    label="Nom de l'établissement"
                                    className="u-mg-bottom-m"
                                    required={formEvents.editable}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main">
                                {/* SIRET */}
                                <FormElement
                                    value={formFields.structureSiret}
                                    name="structureSiret"
                                    type="text"
                                    label="SIRET du site"
                                    className="u-pd-m u-mg-bottom-m"
                                    maxLength={14}
                                    required={formEvents.editable}
                                    customRules={[
                                        {
                                            key: 'siret-invalid',
                                            check: (value) => isSiretValid(value),
                                            message: "Le numéro SIRET n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Address */}
                                <FormElement
                                    value={formFields.structureAddress}
                                    name="structureAddress"
                                    type="text"
                                    label="Adresse"
                                    className="u-mg-bottom-m"
                                    required={formEvents.editable}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* structurePostCode */}
                                <FormElement
                                    value={formFields.structurePostCode}
                                    name="structurePostCode"
                                    type="number"
                                    label="Code postal"
                                    className="u-mg-bottom-m"
                                    maxLength={5}
                                    required={formEvents.editable}
                                    customRules={[
                                        {
                                            key: 'structurePostCode-invalid',
                                            check: (value) => {
                                                return value ? (value.length === 5) : true
                                            },
                                            message: "Le code postal n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* City */}
                                <FormElement
                                    value={formFields.structureCity}
                                    name="structureCity"
                                    type="text"
                                    label="Ville"
                                    className="u-mg-bottom-m"
                                    required={formEvents.editable}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Tel */}
                                <FormElement
                                    value={formFields.structurePhonenumber}
                                    name="structurePhonenumber"
                                    type="text"
                                    label="Téléphone de l'établissement"
                                    className="u-mg-bottom-m"
                                    customRules={[
                                        {
                                            key: 'structure-phone-invalid',
                                            check: (value) => isValidFrenchPhoneNumber(value),
                                            message: "Le format de numéro de téléphone n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                    required={formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Email de connexion */}
                                <FormElement
                                    value={formFields.connexionEmail}
                                    name="connexionEmail"
                                    type="email"
                                    label="Email de connexion"
                                    required={formEvents.editable}
                                    className="u-mg-bottom-s"
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled
                                />
                                <p className="u-fs-xxs u-justify u-mg-bottom-m">Cet email correspond à votre identifiant pour vous connecter à la plateforme, c’est aussi celui qui sera utilisé si vous souhaitez modifier votre mot de passe de connexion. Il ne peut plus être modifié jusqu’à la fin du programme.</p>
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Email de contact */}
                                <FormElement
                                    value={formFields.contactEmail}
                                    name="contactEmail"
                                    type="email"
                                    label="Email de contact"
                                    required={formEvents.editable}
                                    showErrors={showLocalErrors}
                                    className="u-mg-bottom-s"
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                                <p className="u-fs-xxs u-justify u-mg-bottom-m">Cet email désigne l’adresse sur laquelle vous seront envoyés tous les emails de la plateforme (confirmations de demandes, informations sur votre parcours, validations de documents, récapitulatifs de commandes de prestations, bordereaux de versement de prime).</p>
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Website */}
                                <FormElement
                                    value={formFields.website}
                                    name="website"
                                    type="text"
                                    label="Site web"
                                    className="u-mg-bottom-m"
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Coordonnées bancaires */}
                <div className="u-pd-top-m ">
                    <div className="l-container-lg">
                        <h2 className="c-h2 u-bold u-primary u-mg-bottom-l">Coordonnées bancaires</h2>
                        <div className="l-grid u-mg-negative-hz-m">
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                <FormElement
                                    value={formFields.iban}
                                    name="iban"
                                    type="text"
                                    label="IBAN"
                                    className="u-mg-bottom-m"
                                    required={formEvents.editable}
                                    customRules={[
                                        {
                                            key: 'account-number-invalid',
                                            check: (value) => isIBANValid(value),
                                            message: "Le numéro IBAN n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    onBlur={(event) => {
                                        const result = { ...event }
                                        if (result.target) result.target.value = Ibantools.friendlyFormatIBAN(event.target.value)
                                        handleInputChange(result)
                                    }}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                <FormElement
                                    value={formFields.bic}
                                    name="bic"
                                    type="text"
                                    label="BIC"
                                    className="u-mg-bottom-m"
                                    required={formEvents.editable}
                                    customRules={[
                                        {
                                            key: 'account-number-invalid',
                                            check: (value) => isBICValid(value),
                                            message: "Le numéro BIC n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="u-flex u-flex-center-hz u-pd-top-l u-mg-negative-hz-m u-pd-hz-m u-mg-hz-0@main u-pd-hz-0@main">
                    {formEvents?.editable ?
                        <>                                
                            {formEvents.ready &&
                                <>
                                    <div className="u-pd-hz-l">
                                        <button
                                            type="button"
                                            className="c-btn c-btn--white-bordered c-btn--m u-pd-hz-xl u-mg-bottom-l"
                                            style={{ width: '100%', maxWidth: '460px' }}
                                            onClick={cancelModifications}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                    <div className="u-flex u-flex-center-hz u-pd-hz-l" style={{ minWidth: '250px' }}>
                                            <button
                                                type="submit"
                                                className="c-btn c-btn--primary c-btn--m u-pd-hz-xl u-mg-bottom-l"
                                                style={{ width: '100%', maxWidth: '460px' }}
                                                onClick={handleSubmit}
                                            >
                                                Enregistrer
                                            </button>
                                    </div>
                                </>
                            }
                            {!formEvents.ready &&
                                <div className="u-flex u-flex-center-hz u-pd-hz-l u-mg-bottom-l c-spinner"></div>
                            }
                        </>
                        :
                        <button
                            type="button"
                            className="c-btn c-btn--primary c-btn--m u-mg-bottom-l"
                            style={{ width: '100%', maxWidth: '460px' }}
                            onClick={toggleEditableForm}
                        >
                            Modifier mes coordonnées
                        </button>
                    }
                </div>

                {/* Prestations de l'entité */}
                <div className="u-bg-secondary u-pd-hz-m u-pd-vt-m u-pd-vt-xl@main">
                    <div className="l-container-lg">
                        <h2 className="c-h2 u-bold u-primary u-mg-bottom-l">Prestation de l'entité</h2>
                        <div className="l-grid u-mg-negative-hz-m">
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Scoping meeting BO availability */}
                                <FormElement
                                    value={formFields.framing_meeting_available}
                                    name="framing_meeting_available"
                                    type="radio"
                                    label="Intervenant actif"
                                    options={availabilityOptions}
                                    className="u-mg-bottom-l"
                                    classNameOption="u-mg-left-s"
                                    disabled
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Number of lots */}
                                <FormElement
                                    value={formFields.lot}
                                    name="lot"
                                    type="number"
                                    label="Nombre de lots"
                                    className="u-mg-bottom-m"
                                    classNameInput="u-bg-transparent"
                                    required={true}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ProtectedLayout >
    )
}

export default ScopingMeetingAccountPage