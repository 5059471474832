import React from 'react'
import { number, string } from 'prop-types'

import { DEFAULT_SIZE } from 'utils/icons'

const PaymentIcon = ({ size = DEFAULT_SIZE, className }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={size} height={size} className={className} aria-hidden="true">
			<path fill="currentColor" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"/>
		</svg>
	)
}

PaymentIcon.propTypes = {
    size: number,
	className: string
}

export default PaymentIcon
