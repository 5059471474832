import { format, isBefore, isAfter, isEqual, parse, endOfDay } from 'date-fns'
import { fr } from 'date-fns/locale'
import isValid from 'date-fns/isValid'
import isPast from 'date-fns/isPast'
import isToday from 'date-fns/isToday'
import getDay from 'date-fns/getDay'
import differenceInDays from 'date-fns/differenceInDays'

const dateService = {
    getCurrentDate(stringFormat = null) {
        return stringFormat
            ? format(new Date(), stringFormat, { locale: fr })
            : new Date()
    },

    formatDate(date, stringFormat = 'dd/MM/y') {
        try {
            const tzDate = typeof date === 'string' ? new Date(date?.substring(0, 10)) : date
            return format(new Date(tzDate), stringFormat, { locale: fr })
        } catch(err) {
            throw err
        }
    },

    isValidDate(date) {
        return isValid(date)
    },

    isNotInthePast(date) {
        return !isPast(date) || isToday(date)
    },

    isSunday(date) {
        const day = getDay(date)
        return day === 0
    },

    endOfDay(date) {
        return endOfDay(date)
    },

    convertFrenchStringDateToEnglishStringDate(date, patternIn = 'dd/MM/yyyy', patternOut = 'yyyy/MM/dd') {
        return date ? format(parse(date, patternIn, new Date()), patternOut) : null
    },

    getDaysDifferenceBetweenDates(dateStart, dateEnd) {
        const dStart = new Date(dateService.convertFrenchStringDateToEnglishStringDate(dateService.formatDate(dateStart)))
        const dEnd = new Date(dateService.convertFrenchStringDateToEnglishStringDate(dateService.formatDate(dateEnd)))
        return differenceInDays(dEnd, dStart)
    },

    isBeforeThan(dateA, dateB) {
        return isBefore(dateA, dateB)
    },

    isAfterThan(dateA, dateB) {
        return isAfter(dateA, dateB)
    },

    isEqualThan(dateA, dateB) {
        return isEqual(dateA, dateB)
    },
    
    convertStringToDate(dateString) {
        const [day, month, year] = dateString.split('/');
        const formattedDateString = `${year}-${month}-${day}`;
        const parsedDate = parse(formattedDateString, 'yyyy-MM-dd', new Date());
        return parsedDate;
      }
}

export default dateService