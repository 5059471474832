import React from "react"
import InputMail from "components/shared/InputMail"
import InputPassword from "components/shared/InputPassword"
import InputText from "components/shared/InputText"
import InputNumber from "components/shared/InputNumber"
import InputTextarea from "components/shared/InputTextarea"
import Checkbox from "components/shared/Checkbox"
import Radio from "components/shared/Radio"
import Select from "components/shared/Select"
import InputFile from "components/shared/InputFile"
import Datepicker from "components/shared/Datepicker"
import Range from "components/shared/Range"
import Slider from "components/shared/Slider"

const FormElement = (props) => {
    const renderSwitch = (type) => {
        switch (type) {

            case 'text':
                return <InputText {...props} />

            case 'number':
                return <InputNumber {...props} />

            case 'email':
                return <InputMail {...props} />

            case 'password':
                return <InputPassword {...props} />
            
            case 'textarea':
                return <InputTextarea {...props} />

            case 'checkbox':
                return <Checkbox {...props} />

            case 'radio':
                return <Radio {...props} />

            case 'select':
                return <Select {...props} />

            case 'file':
                return <InputFile {...props} />

            case 'date':
                return <Datepicker {...props} />

            case 'range':
                return <Range {...props} />

            case 'slider':
                return <Slider {...props} />

            default:
                return <InputText {...props} />
        }

    }
    return (
        <>
            {renderSwitch(props.type)}
        </>
    )

}


export default FormElement
