import React from 'react'
import XLSX from 'xlsx'

import { useAlert } from 'hooks/useAlert'
import * as CatalogApi from 'services/api/CatalogApi'
import dateService from 'services/date'

import UploadProviderDataTable from './UploadProviderDataTable'
import {
    getProviderDataTableCorrelation,
    getProviderDataTableCorrelationHeader
} from './UploadProviderDataCorrelation'
import { GENDER_PROVIDER_VALUE } from 'utils/domain/provider'

import UploadIcon from 'components/svg/Upload'
import CrossIcon from 'components/svg/Cross'
import { isNotEmpty } from 'utils/validation'

const VALID_EXTENSIONS = ['xlsx', 'xls']

const uploadStatusList = [
    { value: -1, label: 'Tous', id: 'all' },
    { value: 0, label: 'Non-téléversé', id: 'not-uploaded' },
    { value: 1, label: 'Téléversé', id: 'uploaded', color: 'success' },
    { value: 2, label: 'Erreur', id: 'error', color: 'danger' },
    { value: 3, label: 'Supprimé', id: 'deleted', color: 'danger' }
]

const defaultFormEvents = {
    ready: true,
    done: false
}

const defaultFormFields = {
    startingRow: '1',
    file: null,
    data: [],
    uploadError: [],
    operationType: 'post'
}

export const FormUploadProviderData = () => {
    const { addAlert } = useAlert()

    const inputFileEl = React.useRef(null)
    const [data, setData] = React.useState([])
    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [errors, setErrors] = React.useState([])

    const handleRemove = (event) => {
        event.preventDefault()
        inputFileEl.current.value = ''
        setFormFields(s => ({
            ...s,
            file: null,
            data: [],
            uploadError: [],
        }))
    }

    const getAllEntities = async () => {
        try {
            const { res: readEntities, getError: getEntitiesError } = await CatalogApi.getEntities({ gender: GENDER_PROVIDER_VALUE })
            if(getEntitiesError()) throw getEntitiesError()
            return readEntities
            
        } catch (error) {
            addAlert('error', 'Problème à la lecture des entités')
            console.error(error?.toString())
        }
    }

    const handleXlsx = async (event) => {
        const file = [...event.target.files][0]
        if (file) {
            const fileName = file.name
            const fileExtension = fileName.split('.').pop()

            if (VALID_EXTENSIONS.indexOf(fileExtension) === -1) {
                addAlert('error', 'Extension de fichier invalide')
                return
            }

            const reader = new FileReader()
            const readFileAsBinary = !!reader.readAsBinaryString

            setFormFields(formFields => ({
                ...formFields,
                file: {
                    type: 'file',
                    fileName: fileName,
                    extension: fileExtension
                }
            }))

            // Get provider entity_id
            const allEntities = await getAllEntities()

            reader.onload = (e) => {
                // Parse data
                const bstr = e.target.result
                const wb = XLSX.read(bstr, { type: readFileAsBinary ? 'binary' : 'array' })
                // Get first worksheet
                const wsname = wb.SheetNames[0]
                const ws = wb.Sheets[wsname]
                // Convert array of arrays
                let data
                try {
                    data = XLSX.utils.sheet_to_json(ws, { header: 1, range: parseInt(formFields?.startingRow) })
                } catch (e) {
                    addAlert('error', 'Issue when reading sheet')
                    console.error('Issue when reading sheet', e)
                }

                if (data) {
                    // Format
                    const formatedData = data
                        .filter(row => row?.length > 0)
                        .filter(row => row[6] !== 'Ne pas modifier') // On n'affiche pas les lignes dont le statut est "Ne pas modifier"
                        .filter(row => row[6] !== 'Modifier') // On n'affiche pas les lignes dont le statut est "Modifier"
                        .map(row => getProviderDataTableCorrelation(row))
                        .map(row => {
                            const currentProvider = allEntities?.find(currentProvider => {
                               return (
                                    currentProvider.entity_data.siret === row?.siret?.toString()
                                )
                            })
                            const entityId = currentProvider?.entity_id
                            const isStatusField = isNotEmpty(row?.status)

                            return ({
                                error: entityId ? !isStatusField ? 'Veuillez compléter le statut' : '' : 'Fournisseur non trouvé',
                                entity_id: entityId ?? '',
                                ...row
                            })
                        })
                    setData(formatedData)
                }
            }
            readFileAsBinary
                ? reader.readAsBinaryString(file)
                : reader.readAsArrayBuffer(file)
        }
		
	}

    const addErrorOnRow = (providerId, error) => {
        // On va stocker les erreurs dans uploadError
        setFormFields(s => {
            // update data (for table)
            const updatedData = data
            const currentProviderSelected = updatedData.find(provider => provider?.id === providerId)
            currentProviderSelected.error += `${error}`
            currentProviderSelected.uploadStatus = uploadStatusList?.find(s => s.id === 'error').value
            // update error in state
            const updatedUploadError = s.uploadError
            const existingErrorindex = updatedUploadError.findIndex(e => e === providerId)
            if (!(existingErrorindex > -1)) {
                updatedUploadError.push(providerId)
            }
            return {
                ...s,
                data: updatedData,
                uploadError: updatedUploadError
            }
        })
    }

    const handleSubmit = async () => {
        // Validate inputs
        // On n'envoie pas à l'API les items dont le status est à "Ne pas modifier"
        const filteredData = formFields?.data.filter(item => !item.error).filter(item => {
            return (
                item?.status?.toString() !== 'Ne pas modifier'
            )
        } )
        // prevent form to be submitted again
        setFormEvents({ ...formEvents, ready: false })

        if(isNotEmpty(filteredData)) {
            let count = filteredData?.length

            for (const provider of filteredData) {
                const uploadedData = {
                    'equipments_supply_id': provider.id,
                    'entity_id': provider.entity_id,
                    'siret': provider.siret.toString(),
                    'supplier_name': provider.raison_sociale,
                    'category': provider.category,
                    'equipment': provider.equipment,
                    'denomination': provider.name,
                    'status': provider.status.toString() === 'Supprimer' ? 9 : 1, // Add : 1 / Delete : 9
                    'file_date': dateService.formatDate(new Date(), 'yyyy-MM-dd')
                }
                try {
                    const { getError } = await CatalogApi.createProviderData(uploadedData)
                    count--
                    
                    if (count === 0) setEndOfProcess()
                    if(getError()) throw getError()
                } catch (error) {
                    // S'il y a une erreur, on l'envoie à addErrorOnRow
                    addErrorOnRow(provider.id, `${error || 'default'}`)
                }
            }
        } else {
            setFormEvents(formEvents => ({ ...formEvents, ready: true }))
            addAlert('error', "Il n'y a pas de données valides à enregistrer")
        }

    }

    // Show alert when operation is done
    React.useEffect(() => {
        if (formEvents.done) {
            if (formFields?.uploadError.length >= data.length) {
                addAlert('error', "Une erreur s'est produite lors de l'import")
            }
            else if (formFields?.uploadError.length === 0) {
                addAlert('success', "L'import a été réalisé avec succès.")
            }
            else {
                addAlert('warning', 'L\'import a été réalisé mais nous avons rencontré des problèmes avec au moins 1 élément')
            }
        }
    }, [formEvents.done, formFields?.uploadError]) // eslint-disable-line react-hooks/exhaustive-deps

    const setEndOfProcess = () => {
        setTimeout(() => {
            setFormEvents(formEvents => ({ ...formEvents, ready: true, done: true }))
        }, 1000)
        
        // Reset form event
        setTimeout(() => {
            setFormEvents(formEvents => ({ ...formEvents, done: false }))
        }, 3000)
    }

    React.useEffect(() => {
        // Manage filter
        setFormFields(formFields => ({
            ...formFields,
            data: data
        })
    )}, [data])

    const dataHasError = formFields?.data.filter(item => item.error).length
    const dataValidateToUpload = formFields?.data.filter(item => !item.error).length

    return (
        <div className="l-col-12 u-pd-m">
            <form>
                <div className={'c-form-group'}>
                    <label className={'c-label u-mg-bottom-xs'}>Document Xlsx</label>
                    <input
                        ref={inputFileEl}
                        type="file"
                        className="u-sr-only"
                        id={'file'}
                        name={'file'}
                        onChange={handleXlsx}
                    />
                    <label className={'c-input-file'} htmlFor={'file'}>
                        <UploadIcon size={20} />
                        <div className="u-flex-1 u-pd-left-m">
                            {formFields?.file
                                ? <>{formFields?.file?.fileName}</>
                                : <>Ajouter un fichier</>
                            }
                        </div>
                        {formFields?.file && 
                            <button
                                onClick={handleRemove}
                                aria-label="Remove"
                            >
                                <CrossIcon size={20} />
                            </button>
                        }
                    </label>
                </div>

                {isNotEmpty(formFields?.data) &&
                    <div className='u-flex u-mg-top-m u-bg-grey50 u-pd-m'>
                        <p className='u-mg-right-l'>Lignes en erreur : <strong className='u-danger'>{dataHasError}</strong></p>
                        <p className='u-mg-right-l'>Lignes valides :  <strong className='u-success'>{dataValidateToUpload}</strong></p>
                        <p>Lignes totales :  <strong>{formFields?.data.length}</strong></p>
                    </div>
                }

                <UploadProviderDataTable
                    className="u-pd-vt-l u-mg-bottom-m"
                    data={formFields?.data}
                    headers={getProviderDataTableCorrelationHeader()}
                />

                <div className="u-flex u-flex-center-hz u-pd-vt-m">
                    {formEvents.ready &&
                        <button
                            type="button"
                            className={
                                'c-btn u-pd-hz-xl '
                                + (formFields.operationType === 'post' ? 'c-btn--secondary' : 'c-btn--danger')
                            }
                            onClick={handleSubmit}
                            disabled={errors.length > 0 || !(formFields?.file)}
                        >
                            {formFields.operationType === 'post' ? 'Ajouter' : 'Supprimer'} les données
                        </button>
                    }
                    {!formEvents.ready &&
                        <div className="c-spinner"></div>
                    }
                </div>
        </form>
    </div>
    )
}