import React from 'react'
import { object } from 'prop-types'

import { useAlert } from 'hooks/useAlert'
import saveBlob from 'utils/saveBlob'
import { getColor } from 'utils/styles'

import dateService from 'services/date'
import Card from 'components/platform/Card'
import DownloadIcon from 'components/svg/Download'


export const AuditDownload = ({ currentAudit }) => {

    const { addAlert } = useAlert()

    const cgvDoc = currentAudit?.audit_media?.documents?.find(doc => doc.media_gender === 'CGV_AUDIT')

    const downloadCGV = async () => {
        try {
            const extension = cgvDoc?.media_url?.split('.').pop()
            const documentTitle = `conditions-generales-vente-${dateService.getCurrentDate('dd-MM-y')}.${extension}`
            const meetingReport = await fetch(cgvDoc?.full_media_url)
            const blob = await meetingReport.blob()
            saveBlob(blob, documentTitle)
        } catch(error) {
            addAlert('error', error?.toString())
        }
    }

    if(!cgvDoc) return <></>

    return (
        <Card title="CGV">
            <button
                type="button" aria-label="Télécharger le rapport de réunion de cadrage"
                onClick={() => downloadCGV()}
            >
                <DownloadIcon size={40} className="vertical-align" color={getColor().primary} />
            </button>
        </Card>
    )
}

AuditDownload.propTypes = {
    downloadCGV: object
}