import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { privateRoutes } from 'router/routes'

import { useAlert } from 'hooks/useAlert'
import { useAuth } from 'hooks/useAuth'
import { useModal } from 'hooks/useModal'

import * as AuditApi from 'services/api/audit/auditApi'

import { hasAnAudit } from 'utils/domain/audit'

import ModalConfirmation from 'components/shared/ModalConfirmation'
import ModalInfo from 'components/shared/ModalInfo'

import './styles.scss'

const BACK_TO_HOME_PAGE = 'Retour à l\'accueil'

const MODAL_REQUEST_SENT_TITLE = 'Votre demande a été envoyée au prestataire'
const MODAL_REQUEST_SENT_BODY = `L’intervenant va rapidement vous contacter pour programmer
un rendez-vous pour votre audit ! Vous pouvez d’ores et déjà suivre votre audit
dans l’onglet « Suivi de mes activités » de votre espace personnel. `
const MODAL_REQUEST_SENT_CONFIRM_LABEL = 'Suivre mon audit'
const MODAL_REQUEST_SENT_CANCEL_LABEL = BACK_TO_HOME_PAGE


const MODAL_STRUCTURE_NOT_CHECKED_TITLE = 'Votre établissement n’a pas été vérifié'
const MODAL_STRUCTURE_NOT_CHECKED_BODY = `Vous pourrez planifier votre audit
lorsque votre KBIS, SIRET ou SIRENE aura été validé par nos équipes.`
const MODAL_STRUCTURE_NOT_CHECKED_CONFIRM_LABEL = BACK_TO_HOME_PAGE

const AuditPlanning = () => {
    const { showModal } = useModal()
    const { addAlert } = useAlert()
    const { user } = useAuth()
    const history = useHistory()
    const [ isPlanningAnAudit, setIsPlanningAnAudit ] = useState(false)
    const [hasPlannedAnAudit, setHasPlannedAnAudit] = useState({})

    const goToAuditMonitoringPage = () => {
        history.push(privateRoutes.monitoringActivities.audit.path)
    }
    
    const fetchMyAudit = async () => {
        try {
            const { res: audit, getError } = await AuditApi.getMyAudit()
            if (getError()) throw getError()

            const hasPlannedAnAudit = hasAnAudit(audit)
            setHasPlannedAnAudit(hasPlannedAnAudit)
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }

    useEffect(() => {
        fetchMyAudit()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const goToHomePage = () => {
        history.push(privateRoutes.home.path)
    }

    const showConfirmationModal = () => {
        showModal(
            ModalConfirmation,
            {
                title: MODAL_REQUEST_SENT_TITLE,
                body: MODAL_REQUEST_SENT_BODY,
                confirmLabel: MODAL_REQUEST_SENT_CONFIRM_LABEL,
                cancelLabel: MODAL_REQUEST_SENT_CANCEL_LABEL,
                validate: goToAuditMonitoringPage,
                cancel: goToHomePage,
                closeAfterValidation: true
            }
        )
    }

    const showStructureNotCheckedModal = () => {
        showModal(
            ModalInfo,
            {
                title: MODAL_STRUCTURE_NOT_CHECKED_TITLE,
                body: MODAL_STRUCTURE_NOT_CHECKED_BODY,
                actionLabel: MODAL_STRUCTURE_NOT_CHECKED_CONFIRM_LABEL,
                action: goToHomePage
            }
        )
    }
    
    const isKbisOrSiretValidated = !!user.entity_check?.is_check

    const planAnAudit = async () => {
        try {
            setIsPlanningAnAudit(true)
            if(!isKbisOrSiretValidated) {
                showStructureNotCheckedModal()
                setIsPlanningAnAudit(false)
            }

            const { getError } = await AuditApi.planAudit()
            if (getError()) throw getError()
            showConfirmationModal()
            setIsPlanningAnAudit(false)
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
            setIsPlanningAnAudit(false)
        }        
    }

    return (
        <div className="l-container audit-planning-page">
            <h1 className="c-h1 u-secondary u-bold u-center u-mg-top-l u-mg-bottom-l">
                Audit de labellisation
            </h1>
            <div className="u-mg-top-xl">
                <div className="u-pd-m">
                    <p className="u-primary u-bold u-mg-bottom-l">
                        L’audit de labellisation évalue la maturité cyclable du site d’un employeur selon les critères du cahier des charges du label Employeur Pro-Vélo.
                    </p>
                    <div className="u-black u-mg-bottom-m">
                        Le label Employeur Pro-Vélo valorise l’engagement des employeurs (privés ou publics) qui mettent en œuvre des actions ambitieuses en faveur du vélo, pour faire évoluer les comportements et les usages. Il est délivré au regard du respect du <a href="https://employeurprovelo.fr/wp-content/uploads/2021/06/Referentiel-LEPV_A4_BD02.pdf" target="_blank" rel="noreferrer" className="u-underline u-primary u-va-baseline">cahier des charges Employeur Pro-Vélo</a> pour une durée de 3 ans.
                    </div>
                    <div className="u-black u-mg-bottom-m">
                        Pour être labellisé, un site employeur doit satisfaire les 8 critères obligatoires et obtenir un score minimum de 25% parmi les critères complémentaires. Le respect de ces conditions permet d’obtenir le label Bronze. Un score plus élevé permet d’obtenir le label Argent ou Or <a href="https://employeurprovelo.fr/wp-content/uploads/2022/04/Reglement_label_employeur_pro_velo_20220316.pdf" target="_blank" rel="noreferrer" className="u-underline u-primary u-va-baseline">[Plus de détails]</a>.
                    </div>
                    <div className="u-black u-mg-bottom-m">
                        L’audit  de  labellisation  vise  à  analyser  de  manière  factuelle  les  différents  critères  définis  dans  le cahier des charges. L’objectif est de s’assurer du respect des critères obligatoires et d’attribuer un score pour les critères complémentaires.
                    </div>
                    <p className="u-black u-mg-bottom-m u-italic nb-audit">        
                        NB : A ce stade une personne référente vélo sur le site devrait avoir été nommée. Cela constitue l’un des 8 critères obligatoires.
                    </p>
                </div>
                <div className="u-pd-hz-xl u-pd-vt-l in-pratice-panel">
                    <h2 className="c-h4 u-secondary u-mg-bottom-m">En pratique</h2>
                    <p className="u-black u-mg-bottom-m">
                        L’audit de labellisation Employeur Pro-Vélo est <strong>entièrement pris en charge</strong> dans le cadre du Programme. Il est mené par un <strong>organisme de labellisation</strong> sélectionné par le programme OEPV.
                    </p>
                    <div className="u-black u-mg-bottom-m">
                        L’organisme de labellisation contacte le référent vélo du site demandant l’audit pour :
                        <ul>
                            <li> faire signer des <strong>Conditions Générales de Vente (CGV)</strong>. Les CGV sont les mêmes pour tous les bénéficiaires du programme OEPV</li>
                            <li> présenter le <strong>plan d’audit</strong></li>
                            <li> déterminer une <strong>date de visite et d’entretien</strong>.<span className='u-danger'> Attention : Un délai de 3 mois maximum s’écoule entre la signature des CGV et la réalisation de l’audit</span></li>
                            <li> présenter les <strong>pièces à rassembler</strong> et à transmettre en amont de l’audit</li>
                        </ul>
                    </div>
                    <div className="u-black u-mg-bottom-m">
                        L’organisme de labellisation réalise ensuite une visite sur site qui est constituée :
                        <ul>
                            <li> d’un <strong>entretien</strong> avec le référent vélo et toute personne compétente</li>
                            <li> de la <strong>visite in situ</strong> des équipements (emplacements stationnement vélo, vestiaires, douches)</li>
                        </ul>
                    </div>
                    <p className="u-black u-mg-bottom-m">
                        La visite sur site est conditionnée par la transmission des pièces au préalable.
                    </p>
                    <p className="u-black u-mg-bottom-m">
                        Le <strong>résultat de l’audit de labellisation</strong> est transmis sur cette plateforme, dans le suivi des activités.
                    </p>
                    <p className="u-black u-mg-bottom-m u-danger">
                        <strong>Attention :</strong> Il n'est pas possible <strong>d'annuler</strong> ou <strong>reporter</strong> l'audit <strong>moins de 15 jours avant la date définie</strong> avec l'Organisme de labellisation.
                        Si l'employeur fait une demande d'annulation ou de report d'audit 15 jours avant la date fixée de visite sur site, il devra s'acquitter auprès de la FUB d'un <strong>forfait de 2 500€</strong>. 
                        <strong> Tant qu'il n'aura pas versé ce forfait</strong>, il ne pourra pas planifier avec l'organisme de labellisation une nouvelle date d'audit ou faire une nouvelle demande d'audit.
                    </p>
                    <p className="u-black u-mg-bottom-m">
                    Ces éléments sont précisés dans les <strong>Conditions Générales de Vente, ils ne sont pas négociables</strong>.
                    </p>
                </div>
            </div>

            <div className="u-flex u-flex-center-hz u-mg-top-l u-mg-bottom-l">
                { hasPlannedAnAudit && 
                    <button className="c-btn c-btn--primary u-pd-hz-xl u-mg-hz-m" onClick={goToAuditMonitoringPage}>
                        Suivre mon audit
                    </button>
                }
                { !hasPlannedAnAudit && 
                    <button className="c-btn c-btn--primary u-pd-hz-xl u-mg-hz-m" onClick={planAnAudit} disabled={isPlanningAnAudit}>
                        Planifier mon audit de labellisation
                    </button>
                }
            </div>
        </div>
    )
}

export default AuditPlanning