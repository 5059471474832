import React from "react"
import FormElement from "components/shared/FormElement"
import { useAlert } from "hooks/useAlert"
import * as CatalogApi from "services/api/CatalogApi"
import * as UtilsApi from "services/api/UtilsApi"
import dateService from "services/date"
import { getMediaUrlPrefix } from "utils/domain/user"

const defaultFormEvents = {
    ready: true
}

const defaultFormFields = {
    bid: null,
    bidId: ""
}

const FormUploadBid = ({ className, onSuccess, request }) => {
    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)

    const { addAlert } = useAlert()

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
                        ? target.checked
                        : target.type === 'file'
                            ? target
                            : target.value
        const name = target.name

        setFormFields(s => ({
            ...s,
            [name]: value
        }))
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            let cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault()
        // Validate inputs
        const formReady = !(errors.length > 0)

        if (!formReady) {
            setShowLocalErrors(true)
        }
        else {
            // hide error
            setShowLocalErrors(false)
            // prevent form to be submitted again
            setFormEvents({
                ...formEvents,
                ready: false
            })

            // ----------------------------------
            // Uploading a bid is a 3-steps process
            // ----------------------------------

            // 1 - get S3 url
            const S3url = await UtilsApi.getS3Url(`${formFields?.bid?.id}.${formFields?.bid?.extension}`)
            if (!S3url || !S3url?.url) {
                addAlert("error", "Erreur lors de l'enregistrement : nous n'avons pas pu enregistrer votre devis.")
                setFormEvents({
                    ...formEvents,
                    ready: true
                })
            }
            else {
                // 2 - put bid
                // issue with axios / blob / header authorization
                // https://github.com/axios/axios/issues/1569
                const saveBid = await fetch(
                    S3url.url,
                    { 
                        method: 'PUT',
                        headers: { "content-type" : "" },
                        body: formFields?.bid?.blob,
                    }
                ).catch(e => null)

                if (!saveBid) {
                    addAlert("error", "Erreur lors de l'enregistrement : nous n'avons pas pu enregistrer votre devis.")
                }
                else {
                    // 3 - manage bid request
                    const editBackendRequestParameters = {
                        request_id : request?.request_id,
                        request_data: {
                            operation_code: "QUOTE",
                            operation_data: {
                                operation_date: dateService.getCurrentDate(`yyyy-MM-dd HH:mm:ss`),
                                media_gender: "QUOTE",
                                media_id: formFields?.bid?.id,
                                media_url: `${getMediaUrlPrefix()}${formFields?.bid?.id}.${formFields?.bid?.extension}`,
                                media_hash: formFields?.bid?.value
                            }
                        },
                        request_search_data: null,
                        last_row_hash : request?.row_hash
                    }

                    const editBackendRequest = await CatalogApi.editBackendRequest(editBackendRequestParameters)
                    if (!editBackendRequest || editBackendRequest?.error) {
                        switch (editBackendRequest?.error?.code) {
                            case "NotAuthorizedException":
                                addAlert("error", "Vous n'avez pas les droits nécessaires pour téléverser un devis.")
                                break
                            case "RequestNotFoundException":
                                addAlert("error", "Votre devis n'a pas pu être mis à jour car le dossier initial n'a pas pu être identifié.")
                                break
                            case "RequestVersionMismatchException":
                                addAlert("error", "La version de la demande est obsolète.")
                                break
                            default:
                                addAlert("error", "Une erreur s'est produite lors du téléversement")
                        }
                        setFormEvents({
                            ...formEvents,
                            ready: true
                        })
                    }
                    else {
                        addAlert("success", "Votre devis a bien été téléversé !")
                        if (onSuccess) onSuccess()
                    }
                }
            }
        }
    }

    return (
        <form className={" " + (className || "")}>
            <div className="l-grid">
                <div className="l-col-12 l-col-8@main u-pd-m">
                    <FormElement
                        value={formFields.bid}
                        type="file"
                        name="bid"
                        label="Sélectionner le document"
                        maxFileSize={10}
                        acceptPDF={true}
                        className="u-flex-1 u-mg-bottom-m"
                        required={true}
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                    />
                    <p className="u-fs-xs u-mg-bottom-m">Un document de 10Mo maximum au format pdf, png ou jpg</p>
                </div>
                <div className="l-col-12 l-col-4@main u-pd-m u-flex u-flex-center-hz">
                    {formEvents.ready &&
                        <button
                            type="submit"
                            className="c-btn c-btn--secondary u-pd-hz-xl"
                            onClick={handleSubmit}
                            disabled={errors.length > 0}
                        >
                            Téléverser
                        </button>
                    }
                    {!formEvents.ready &&
                        <div className="c-spinner"></div>
                    }
                </div>
            </div>
        </form>
    )
}

export default FormUploadBid
