import { arrayOf, oneOfType, bool, func, shape, string, number, object } from 'prop-types'
import React from 'react'
import { isNotEmpty, isInt } from 'utils/validation'

const ALLOWED_KEY_VALUES = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']

const InputNumber = ({
    // Attributes
    id = null,
    value = '',
    name,
    label = '',
    placeholder = '',
    unit = '',
    disabled,
    readonly = false,
    // Styles
    style,
    className = '',
    classNameLabel = '',
    classNameInput = '',
    // Error management
    required = false,
    customRules,
    showErrors,
    // Handler
    onChange,
    onValidate,
    ...rest
}) => {
    const inputRef = React.useRef(null)
    const [errors, setErrors] = React.useState([])

    const rules = React.useMemo(() => [
        {
            key: 'input-empty',
            check: (value) => required ? isNotEmpty(value) : true,
            message: 'Ce champ est obligatoire'
        },
        {
            key: 'input-is-not-integer',
            check: (value) => value ? Number.isInteger(value) || isInt(value) : true,
            message: 'Ce champ doit être un nombre'
        },
        ...(customRules || [])
    ], [required]) // eslint-disable-line
    // customRules in dependencies array trigger an infinite loop,
    // because custom rules include a check property defining a new function,
    // preventing useMemo to return a valid shallow-comparison
    // or event useDeepMemo to return a valid deep-comparison


    const validate = React.useCallback((currentValue) => {
        
        const newErrors = []
        rules.forEach(rule => {
            if (!rule.check(currentValue)) {
                newErrors.push({
                    origin: name,
                    rule: rule.key,
                    message: rule.message
                })
            }
        })
        setErrors(newErrors)
        onValidate(name, newErrors)
    }, [rules, name, onValidate])

    // validate on mount and each time value change
    React.useEffect(() => {
        validate(value)
        return () => {
            // On unmounted erros and validation are no longer needed
            setErrors([])
            if (onValidate) onValidate(name, [])
        }
    }, [value, validate, onValidate, name])

    return (
        <>
            <div className={'c-form-group ' + className} style={style}>
                <label className={'c-label u-mg-bottom-xs ' + classNameLabel} htmlFor={id}>{label} {required && '*'}</label>

                <div className="u-flex u-flex-baseline-vt">
                    <input
                        ref={inputRef}
                        className={
                            'c-input '
                            + (showErrors && errors.length > 0 ? 'has-error ' : ' ')
                            + (classNameInput ? classNameInput : '')
                        }
                        id={id}
                        name={name}
                        type={'number'}
                        placeholder={placeholder}
                        readOnly={readonly}
                        value={value}
                        disabled={disabled}
                        required={required}
                        onChange={(e) => !readonly && onChange(e)}
                        onBlur={() => validate(value)}
                        // Disable Up and Down Arrows on Keyboard
                        onWheel={() => inputRef.current.blur()}
                        onKeyDown={(e) => {
                            if (ALLOWED_KEY_VALUES.includes(e.key)) return
                            
                            e.preventDefault()
                        }}
                        {...rest}
                    />
                    {unit && <span className="u-mg-left-s">{unit}</span>}
                </div>

                {/* Error messages */}
                {showErrors && errors && errors.map((error, index) => {
                    return <p key={index} className="u-fs-xs u-danger">{error.message}</p>
                })}
            </div>
        </>
    )
}

InputNumber.propTypes = {
    id: string,
    value: oneOfType([
        string,
        number
      ]),
    name: string,
    label: oneOfType([
        string,
        object
      ]),
    placeholder: string,
    unit: string,
    disabled: bool,
    readonly: bool,
    style: string,
    className: string,
    classNameLabel: string,
    classNameInput: string,
    required: bool,
    customRules: arrayOf(
        shape({
            key: string,
            check: func,
            message: string
        })
    ),
    showErrors: bool,
    onChange: func,
    onValidate: func,
}


export default InputNumber
