import React from 'react'
import ProtectedLayout from 'layouts/Protected'
import MyScopingMeetings from 'components/my-scoping-meetings/MyScopingMeetings'

const ScopingMeetingProviderPage = () => {
    return (
        <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main">
            <MyScopingMeetings/>
        </ProtectedLayout>
    )
}

export default ScopingMeetingProviderPage