import { ToastProvider, useToasts } from "react-toast-notifications"

const useAlert = (type, message) => {
    const { addToast } = useToasts()

    const addAlert = (type, message) => addToast(message, {
        appearance: type,
        autoDismiss: true
    })

    return { addAlert }
}

const AlertProvider = ({ children}) => {
    return (
        <ToastProvider placement={"top-center"}>
            {children}
        </ToastProvider>
    )
}

export { useAlert, AlertProvider }