import { ReactComponent as OneStar } from 'assets/images/labellisation/LOGO_PROVELO_1etoile.svg'
import { ReactComponent as TwoStars } from 'assets/images/labellisation/LOGO_PROVELO_2etoiles.svg'
import { ReactComponent as ThreeStars } from 'assets/images/labellisation/LOGO_PROVELO_3etoiles.svg'

export const WAITING_CERTIFICATION = -1
export const NO_CERTIFICATION = 0
export const BRONZE_LEVEL_CERTIFICATION = 1
export const SIVLER_LEVEL_CERTIFICATION = 2
export const GOLD_LEVEL_CERTIFICATION = 3

export const CERTIFICATION_LEVELS = [
  { label: 'Choisir un label', value: WAITING_CERTIFICATION, className: 'waiting-certif' },
  { label: 'Sans label', value: NO_CERTIFICATION, className: 'no-certif' },
  { label: 'Bronze', value: BRONZE_LEVEL_CERTIFICATION, className: 'bronze-certif' },
  { label: 'Argent', value: SIVLER_LEVEL_CERTIFICATION, className: 'silver-certif' },
  { label: 'Or', value: GOLD_LEVEL_CERTIFICATION, className: 'gold-certif' }
]

const certificationUtil = {
  getLabelByCertificationLevel(level) {
    return CERTIFICATION_LEVELS.find((certifLevel) => certifLevel.value === level)?.label
  },
  
  getClassNameByCertificationLevel(level) {
    return CERTIFICATION_LEVELS.find((certifLevel) => certifLevel.value === level)?.className
  },

  getAllCertificationsHTMLOptions() {
    return CERTIFICATION_LEVELS.map((level, index) => {
      return <option value={level.value} key={index} className={level.className}>{level.label}</option>
    })
  },

  getSVGByCertificationLevel(certificationLevel) {
    switch(certificationLevel) {
        case NO_CERTIFICATION: 
            return null
        case BRONZE_LEVEL_CERTIFICATION:
            return <OneStar />
        case SIVLER_LEVEL_CERTIFICATION:
            return <TwoStars />
        case GOLD_LEVEL_CERTIFICATION:
            return <ThreeStars />
        default:
    }
  }
}

export default certificationUtil
