import { object } from 'prop-types'
import React from 'react'

const ModalContext = React.createContext({
    component: null,
    modalProps: null,
    props: {},
    showModal: () => {},
    hideModal: () => {}
})

const useModal = () => React.useContext(ModalContext)

const ModalProvider = ({ children }) => {
    const [component, setComponent] = React.useState(null)
    const [modalProps, setModalProps] = React.useState({ isClosable: true })

    const showModal = (_component, _componentProps, _modalProps) => {
        setComponent(React.createElement(_component, _componentProps))
        if (_modalProps) setModalProps(_modalProps)
        // Side effect
        document && document.querySelector('body').classList.add('u-overflow-hidden')
    }
    const hideModal = () => {
        setComponent(null)
        // Side effect
        document && document.querySelector('body').classList.remove('u-overflow-hidden')
    }

    return (
        <ModalContext.Provider value={{
            component, modalProps, showModal, hideModal
        }}>
            {children}
        </ModalContext.Provider>
    )
}

ModalProvider.propTypes = {
    children: object
}

export { useModal, ModalProvider }