import React from 'react'
import FormElement from 'components/shared/FormElement'
import { func, string } from 'prop-types'

const FormValidateBid = ({ className, onValidateForm }) => {
    const [formFields, setFormFields] = React.useState({ bidAmount: '' })

    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    
    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
                        ? target.checked
                        : target.type === 'file'
                            ? target
                            : target.value
        const name = target.name

        setFormFields({ ...formFields, [name]: value })
    }

    const handleErrors = (errors) => {
        // Validate inputs
        const formReady = !(errors.length > 0)
        onValidateForm(formReady, { bidAmount: formFields?.bidAmount })
        setShowLocalErrors(!formReady)
    }

    const handleValidation = React.useCallback((name, errs) => {
        const cleanErrors = [...errors.filter((e) => e.origin !== name)]
        const newErrors = [ ...cleanErrors, ...errs ]
        setErrors(newErrors)

        handleErrors(newErrors)
    }, [formFields?.bidAmount]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <form className={className ? className : ''}>
            {/* {showGlobalError &&
                <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur lors de la validation du formulaire.</p>
            } */}
            
            <div className={'l-grid u-flex-center-hz u-mg-negative-hz-m'}>
                <div className="l-col-12 l-col-6@main u-pd-m">
                    {/* Bid amount */}
                    <FormElement
                        value={formFields.bidAmount}
                        name="bidAmount"
                        type="number"
                        label="Montant HT indiqué sur le devis"
                        unit="euros"
                        className="u-mg-bottom-m"
                        required={true}
                        showErrors={showLocalErrors}
                        onValidate={handleValidation}
                        onChange={handleInputChange}
                    />
                </div>       
            </div>
        </form>
    )
}

FormValidateBid.propTypes = {
    className: string,
    onValidateForm: func
}

export default FormValidateBid
