import React, { useEffect, useState } from 'react'
import { useParams, NavLink, useHistory } from 'react-router-dom'
import Spinner from 'react-svg-spinner'

import { privateRoutes } from 'router/routes'

import uploadFile from 'services/uploadFile'

import { fileHash } from 'utils/hash'
import { getMediaUrlPrefix } from 'utils/domain/user'
import autodiagService from 'utils/autodiag'

import { useAlert } from 'hooks/useAlert'
import { useModal } from 'hooks/useModal'
import { useAuth } from 'hooks/useAuth'

import { STEPS, useAutoDiag } from 'pages/protected/Autodiag/context'
import Description from 'pages/protected/Autodiag/Steps/Description'

import Workflow from '../Workflow'
import Responses from '../Responses'
import ModalQuitConfirmation from './ModalQuitConfirmation'
import StarIcon from 'components/svg/Star'
import { GREEN_90_COLOR } from 'utils/icons'
import arceaux_image from 'assets/images/arceaux_vs_pince-roues.png'

import './styles.scss'


const Loader = () => (
    <div className="u-mg-right-s">
        <Spinner size="18px" />
    </div>
)

const Questions = () => {
    const { addAlert } = useAlert()
    const { step } = useParams()
    const { showModal, hideModal: hideModalQuitConfirmation } = useModal()
    const history = useHistory()
    const { user } = useAuth()

    const {
        blankQuestions, 
        getAutoDiagForFill, 
        getQuestionsByAutoDiagId,
        getRestitutionScore, 
        getScoreExplanation, 
        saveAutoDiag, 
        savingAutodiag,
        getRestitutionBinary 
    } = useAutoDiag()

    const [ questionsStep, setQuestionsStep ] = useState([])

    const currentStep = STEPS[step]
    const autodiag = getAutoDiagForFill()

    useEffect(() => {
        setQuestionsStep(getQuestionsByAutoDiagId(autodiag?.diag_id)[step] || blankQuestions[step] || [])
    }, [autodiag?.diag_id, blankQuestions, getQuestionsByAutoDiagId, step])

    useEffect(() => {
        history.block((prompt) => {
            const { pathname } = prompt
            if(pathname.startsWith('/auto-diagnostic')) {
                return true
            }
            showModal(ModalQuitConfirmation, { quitWithoutSave: quitWithoutSave(pathname), quitAndSave: quitAndSave(pathname) })
            return false
        })

        return () => {
          history.block(() => {})
        }
    }, [history, showModal, questionsStep])


    const save = async ({ fromSaveButton, finalizeAutodiag }) => {
        try {
            const questionsToSave = {
                ...getQuestionsByAutoDiagId(autodiag?.diag_id),
                [step]: cleanOptionalQuestions(questionsStep),
            }

            const diag_id = autodiag?.diag_id

            if(finalizeAutodiag) {
                const restitutionScore = getRestitutionScore(questionsToSave)
                const binary = await getRestitutionBinary({
                    questions: questionsToSave, 
                    scoreExplanation: getScoreExplanation(restitutionScore), 
                    restitutionScore,
                }) 
                
                const filename = autodiagService.getFilename(user?.entity_data?.structureName)
                const mediaExtension = 'pdf'
                const hash_file = await fileHash(binary)
    
                const documentToSave = {
                    media_gender: 'AUTODIAG',
                    media_id: filename,
                    media_url: `${getMediaUrlPrefix()}${filename}.${mediaExtension}`,
                    media_hash: hash_file
                }
    
                await uploadFile('AUTODIAG', {
                    id: filename,
                    extension: mediaExtension,
                    blob: binary,
                })

                await saveAutoDiag({ diag_id, questions: questionsToSave, documents: [ documentToSave ] }, { finalizeAutodiag })       
            } else {
                await saveAutoDiag({ diag_id, questions: questionsToSave }, { finalizeAutodiag })       
            }
            
            if(fromSaveButton) addAlert('success', "L'autodiagnostic a bien été enregistré")
            else history.push(privateRoutes.autoDiag.navLink({ step: privateRoutes.autoDiag.paramsAllowed[currentStep.position + 1] }))
        } catch(error) {
            addAlert('error', error)
        }
    }

    const quitAndSave = (pathname) => () => {
        save({ fromSaveButton: true }).then(() => {
            history.block(() => {})
            history.push(pathname)
            hideModalQuitConfirmation()
        })
    }

    const quitWithoutSave = (pathname) => () => {
        hideModalQuitConfirmation()
        history.block(() => {})
        history.push(pathname)
    }

    const isConditionalQuestionToDisplay = (question) => {
        if(!question.conditional_question) {
            return false
        }
        const conditionalQuestion = question.conditional_question.split('___')[0].trim()
        const conditionalResponses = question.conditional_question.split('___')[1]
            .split('_').map(responseLetter => `response_${responseLetter.toLowerCase().trim()}`)
        const questionToBeAnswered = questionsStep.find(question => question.num_question === conditionalQuestion)
        return questionToBeAnswered.userResponses
                    ? questionToBeAnswered.userResponses.some(response => conditionalResponses.includes(response))
                    : false
    }

    const cleanOptionalQuestions = (questions) => {
        return questions.map(question => {
            if(question.conditional_question && !isConditionalQuestionToDisplay(question)) {
                return {
                    ...question,
                    userResponses: []
                }
            }
            return question
        })
    }

    const isDisplayResponseInRow = (question) => {
        const { response_a, response_b, response_c, response_d } = question
        const responseAB = [response_a, response_b]
        return !response_c && !response_d && responseAB.includes('Oui') && responseAB.includes('Non')
    }

    const setResponded = ({ question, responses }) => {
        const questionsStepUpdated = questionsStep.map(questionStep => {
            if(questionStep.num_question === question.num_question) {
                return {
                    ...questionStep,
                    userResponses: Array.from(responses).filter(([, response ]) => response.checked).map(([ key ]) => key)
                }
            }
            return questionStep
        })

        setQuestionsStep(questionsStepUpdated)
    }


    const isQuestionToDisplay = (question) => {
        return !question.conditional_question || isConditionalQuestionToDisplay(question)
    }

    const hasEveryResponded = questionsStep?.filter(question => isQuestionToDisplay(question))
        .every(question => question.userResponses ? question.userResponses.length > 0 : false)

    const getPreviousNav = () => {
        if(step === Object.keys(STEPS)[1])
            return <div className="l-col-3"/>
        
        return (
            <NavLink
                disabled={savingAutodiag} 
                className="l-col-3 c-btn c-btn--primary" 
                to={privateRoutes.autoDiag.navLink({ step: privateRoutes.autoDiag.paramsAllowed[currentStep.position - 1] })}
            >
                {savingAutodiag && <Loader />}Précédent
            </NavLink>
        )
    }

    const getNextNav = () => {
        const actionLabel = currentStep.isLastStep ? 'Terminer' : 'Suivant'
        if(hasEveryResponded){
            return (
                <button
                    disabled={savingAutodiag}
                    onClick={() => save({ finalizeAutodiag: currentStep.isLastStep })}
                    className="l-col-3 c-btn c-btn--primary"
                >                
                    {savingAutodiag && <Loader />}{actionLabel}
                </button>
            )
        }

        return (
            <button className="l-col-3 c-btn c-btn--primary" disabled>
                {actionLabel}
            </button>
        )
    }

    return (
        <div className="l-container">
            <h1 className="c-h1 u-primary u-bold u-center u-mg-top-l u-mg-bottom-l">
                {currentStep.position}. {currentStep.title}
            </h1>
            <Workflow currentStep={currentStep} />
            <Description step={step} />
            <div className="u-flex u-flex-dir-col u-mg-top-xxl u-mg-bottom-l">
                {questionsStep.filter(question => isQuestionToDisplay(question)).map((question) => {
                    const isConditionalQuestion = question.conditional_question
                    const isMultipleChoicesQuestion = question.question_type === 'Choix multiple'
                    return (
                        <div 
                            className="l-grid u-mg-top-m u-pd-m question"  
                            key={question.num_question}
                        >
                            {!isConditionalQuestion && (
                                <div className="u-secondary u-bold l-col-1">
                                    {question.num_question}
                                </div>
                            )}
                            {isConditionalQuestion && (<div className="l-col-2" />)}
                            <div className={isConditionalQuestion ? 'l-col-10' : 'l-col-11'}>
                                <div className="u-primary u-relative u-pd-right-l">
                                    {question.question}
                                    {isMultipleChoicesQuestion && <div className='u-bold u-fs-xs'>( Plusieurs réponses possibles )</div>}
                                    {question.requirement_type === 'O' && <div className="u-absolute u-right-0 u-top-0"><StarIcon color={GREEN_90_COLOR} size="20px" /></div>}
                                </div>
                                { // Specific illustration for the question : 5.1.1
                                    question.num_question === '5.1.1' && 
                                    <div className="u-pd-vt-m">
                                        <img alt="" width="700px" src={arceaux_image} />
                                    </div>
                                }
                                <div className={`u-flex u-mg-top-m ${isDisplayResponseInRow(question) ? 'u-flex-dir-row' : 'u-flex-dir-col'}`}>
                                    <Responses question={question} setResponded={setResponded}/>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className="u-flex u-flex-center-vt">
                <StarIcon color={GREEN_90_COLOR} size="20px" /> <em className="u-pd-left-s u-fs-xs">Critère obligatoire du cahier des charges Employeur Pro-vélo</em>
            </div>

            <div className="l-grid u-flex-around u-flex-center-vt u-mg-top-xxl u-mg-bottom-xxl">
                {getPreviousNav()}
                <button
                    disabled={savingAutodiag}
                    onClick={() => save({ fromSaveButton: true })}
                    type="button"
                    className="l-col-4 c-btn c-btn--secondary"
                >
                    {savingAutodiag && <Loader />}Enregistrer
                </button>
                {getNextNav()}
            </div>
        </div>
    )
}

export default Questions