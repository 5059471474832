import React from "react"
import { motion, AnimatePresence } from "services/framerMotion"
import { useDrawer } from "hooks/useDrawer"
import CrossIcon from "components/svg/Cross"

export const DrawerOverlayVariants = {
    open: {
        opacity: "0.6",
        transition: {
            when: "beforeChildren",
            delay: "0.2"
        }
    },
    closed: {
        opacity: "0",
        transition: {
            when: "afterChildren"
        },
    }
}

export const DrawerVariants = {
    open: {
        x: "0%",
        transition: {
            delay: "0.2"
        }
    },
    closed: {
        x: "110%"
    }
}

const Drawer = ({ children, onClose, drawerClassName, ...props }) => {
    return (
        <AnimatePresence>
            {children && (
                <motion.div
                    key="drawer-wrapper"
                    className="c-drawer-wrapper">
                    <motion.div className={"c-drawer-overlay"}
                        key="drawer-overlay"
                        onClick={onClose}
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={DrawerOverlayVariants}>
                    </motion.div>

                    <motion.div className={"c-drawer " + (drawerClassName ? drawerClassName : "")}
                        {...props}
                        key="drawer"
                        onClick={e => e.stopPropagation()}
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={DrawerVariants}
                    >
                        <div className="u-flex u-flex-end u-pd-m">
                            <button onClick={onClose} aria-label="Fermer le menu" className="c-drawer__close u-pd-xs">
                                <CrossIcon size={25} />
                                <span className="u-mg-top-xxs u-fs-xxs">Fermer</span>
                            </button>
                        </div>

                        {children}
                    </motion.div>
                </motion.div>
            )}
         </AnimatePresence>
    )
}

const DrawerRoot = () => {
    let { component, hideDrawer } = useDrawer()
    return (
        <Drawer onClose={hideDrawer}>
            { component }
        </Drawer>
    )
}


export { Drawer as default, DrawerRoot }
