import React from 'react'
import { number, string } from 'prop-types'

import { DEFAULT_SIZE } from 'utils/icons'

const Statistic = ({ size = DEFAULT_SIZE, className }) => {
  return(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 640" width={size} height={size} className={className} aria-hidden="true">
  <path fill="currentColor" d="M502 437.325h-22V134.622c0-13.234-10.767-24-24-24h-34c-13.233 0-24 10.766-24 24v302.703h-40V200.622c0-13.234-10.767-24-24-24h-34c-13.233 0-24 10.766-24 24v236.703h-40v-264c0-13.234-10.767-24-24-24h-34c-13.233 0-24 10.766-24 24v264h-40v-198c0-13.234-10.767-24-24-24H56c-13.233 0-24 10.766-24 24v198H10a8 8 0 000 16h492a8 8 0 000-16z"/>
</svg>
  )
}

Statistic.propTypes = {
  size: number,
  className: string
}

export default Statistic