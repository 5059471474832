import Introduction from './Introduction'
import Questions from './Questions'
import Restitution from './Restitution'
import RestitutionPDF from './RestitutionPDF'

const Steps = {
    Introduction,
    Questions,
    Restitution,
    RestitutionPDF
}

export default Steps
