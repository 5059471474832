import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'

import PublicLayout from 'layouts/Public'
import FormSignin from 'components/auth/FormSignin'
import RegisterBlock from 'components/auth/RegisterBlock'
import Logo from 'components/LogoOEPV'
import { privateRoutes } from 'router/routes'
import illustration from 'assets/images/cycliste-signin.png'

const LOGO_SIZE = 120

const SigninPage = () => {
    const { isLoggedIn, isABackOfficeUser } = useAuth()
    const history = useHistory()
    const location = useLocation()

    React.useEffect(() => {
        const redirection = isABackOfficeUser ? privateRoutes.backOffice.backOfficeHome.path : privateRoutes.home.path
        const loggedRedirect = (!location?.state?.isNewUser && location?.state?.from) ? location?.state?.from : redirection
        
        // Redirect user if loggedIn
        if (isLoggedIn) history.push(loggedRedirect)
    }, [history, isLoggedIn]) // eslint-disable-line

    return (
        <PublicLayout fixedLayout={true}>
            <div className="l-grid u-flex-1">
                <div className="l-col-12 l-col-8@main u-flex u-flex-dir-col u-flex-center-hz u-relative" style={{ paddingTop: `${LOGO_SIZE - 60}px` }}>
                    
                    <div className="u-fixed u-top-0 u-left-0 u-pd-m u-mg-m@main u-mg-0">
                        <Logo style={{ height: `${LOGO_SIZE}px`, width: 'auto' }} />
                    </div>

                    <div className="l-form-width u-mg-hz-auto u-pd-m u-pd-vt-0@main u-pd-vt-xxl u-relative u-zi-1 u-bg-white">
                        <h1 className="c-h1 u-bold u-secondary u-center u-mg-bottom-xl u-pd-top-xl u-pd-top-0@main">Connectez-vous</h1>
                        <FormSignin />
                    </div>

                    <div className="u-absolute u-bottom-0 u-left-0 u-hide u-block@main">
                        <img alt="" src={illustration} />
                    </div>
                </div>
                <div className="l-col-12 l-col-4@main u-bg-primary u-pd-l u-pd-xxl@main">
                    <RegisterBlock className="u-white" />
                </div>
            </div>

        </PublicLayout>
    )
}

export default SigninPage
