import { string, number } from 'prop-types'
import React from 'react'
import { DEFAULT_COLOR, DEFAULT_SIZE } from 'utils/icons'

const UploadIcon = ({ size = DEFAULT_SIZE, className, color = DEFAULT_COLOR }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
			 style={{ width: size, height: size }} className={className} aria-hidden="true">
			<path fill={color} d="M472 312.642v139c0 11.028-8.972 20-20 20H60c-11.028 0-20-8.972-20-20v-139H0v139c0 33.084 26.916 60 60 60h392c33.084 0 60-26.916 60-60v-139h-40z"/>
			<path fill={color} d="M256 .358L131.716 124.642 160 152.926l76-76v311.716h40V76.926l76 76 28.284-28.284z"/>
		</svg>
	)
}

UploadIcon.propTypes = {
	size: number,
	className: string,
	color: string
}

export default UploadIcon
