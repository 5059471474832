import React from "react"
import PublicLayout from "layouts/Public"

const CGUPage = () => {
    return (
        <PublicLayout showHeader={true} className="u-pd-m u-pd-hz-0@main u-pd-vt-xl@main">
            <div className="l-container">
                <h1 className="c-h1 u-bold u-primary u-mg-bottom-l">Conditions Générales d'Utilisation</h1>
                <p className="u-mg-bottom-m">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate animi delectus eius sit vel architecto veniam expedita quibusdam, voluptates tenetur nesciunt. Voluptatibus sequi tenetur autem, voluptatem cumque exercitationem dolore. Obcaecati.</p>
            </div>
        </PublicLayout>
    )
}

export default CGUPage
