export const getTiersError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour récupérer ces informations.'
        default:
            return "Une erreur s'est produite lors de la tentative de récupération des données."
    }
}

export const getRefundsError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour récupérer ces informations.'
        default:
            return "Une erreur s'est produite lors de la tentative de récupération des données."
    }
}

export const getRefundError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour récupérer ces informations.'
        case 'InvalidArgument':
        default:
            return "Une erreur s'est produite lors de la tentative de récupération des données."
    }
}



export const putRefundError = (error) => {
    switch (error?.code) {
        case 'NotAuthorizedException':
            return 'Vous n\'avez pas les droits nécessaires pour effectuer cette action.'
        case 'TooManyRequestsException':
            return 'Certains dossiers envoyés ici ont déjà été payés.'
        case 'RequestsNotFoundException':
            return 'Tous les dossiers de ces tiers ont été remboursés'
        case 'InvalidArgument':
        default:
            return "Il n'y a aucun dossier à rembourser."
    }
}