import autodiagService from 'utils/autodiag'
import { convertDataToStringFromArray } from 'utils/downloadFile'
import { isArray } from 'utils/validation'
// ------------------------------- //
//             Export
// ------------------------------- //

const getUserResponsesFromCategoryQuestions = (questions) => {
  return questions.map((question) => {
    if (question.userResponses?.length > 1) {
      return question.userResponses.map((response) => question[response]).join('; ')
    } else {
      return question[question.userResponses]
    }
  })
}

export const getAutodiagToExport = async (autodiags, autodiagQuestionsFromFile) => {
  const questionsFormatted = []
  Object.values(autodiagQuestionsFromFile).flat().map(autodiagQuestion => questionsFormatted.push(`${autodiagQuestion.num_question} ${autodiagQuestion.question}`))

  const autodiagHeader = [
    ...Array(4).fill(null), 
    Object.keys(autodiagQuestionsFromFile)[0],
    ...Array(10).fill(null),
    Object.keys(autodiagQuestionsFromFile)[1],
    ...Array(11).fill(null),
    Object.keys(autodiagQuestionsFromFile)[2],
    ...Array(3).fill(null),
    Object.keys(autodiagQuestionsFromFile)[3],
    ...Array(2).fill(null),
    Object.keys(autodiagQuestionsFromFile)[4],
    ...Array(6).fill(null),
    'Points forts',
    ...Array(5).fill(null),
    'Critères obligatoires',
    ...Array(5).fill(null),
  ]

  const autodiagSubHeader = [
    'N° Bénéficiaire',
    'SIRET',
    'Nom de l\'établissement',
    'Date',
    ...questionsFormatted,
    ...Object.keys(autodiagQuestionsFromFile),
    'TOTAL /29',
    ...Object.keys(autodiagQuestionsFromFile),
    'TOTAL /8',
  ]
  
  const mapper = (autodiags) => {
    return autodiags.map(autodiag => {
      const questions = autodiag.diag_data.survey
      const managementStrategy = questions[Object.keys(autodiagQuestionsFromFile)[0]]
      const communicationMotivation = questions[Object.keys(autodiagQuestionsFromFile)[1]]
      const services = questions[Object.keys(autodiagQuestionsFromFile)[2]]
      const security = questions[Object.keys(autodiagQuestionsFromFile)[3]]
      const equipments = questions[Object.keys(autodiagQuestionsFromFile)[4]]

      const isQuestionsHasData = Object.values(questions).flat()?.length > 0
      const isManagementStrategyHasData = isArray(managementStrategy) && managementStrategy?.length > 0
      const isCommunicationMotivationHasData = isArray(communicationMotivation) && communicationMotivation?.length > 0
      const isServicesHasData = isArray(services) && services?.length > 0
      const isSecurityHasData = isArray(security) && security?.length > 0
      const isEquipmentHasData = isArray(equipments) && equipments?.length > 0

      const autodiagArray = [
        autodiag.entity_id,
        autodiag.siret,
        autodiag.structureName,
        autodiag.modify_date,
        ...isManagementStrategyHasData ? getUserResponsesFromCategoryQuestions(managementStrategy) : '',
        ...isCommunicationMotivationHasData ? getUserResponsesFromCategoryQuestions(communicationMotivation) : '',
        ...isServicesHasData ? getUserResponsesFromCategoryQuestions(services) : '',
        ...isSecurityHasData ? getUserResponsesFromCategoryQuestions(security) : '',
        ...isEquipmentHasData ? getUserResponsesFromCategoryQuestions(equipments) : '',
        isManagementStrategyHasData ? autodiagService.getTotalRespOptionnalStep(managementStrategy) : '',
        isCommunicationMotivationHasData ? autodiagService.getTotalRespOptionnalStep(communicationMotivation) : '',
        isServicesHasData ? autodiagService.getTotalRespOptionnalStep(services) : '',
        isSecurityHasData ? autodiagService.getTotalRespOptionnalStep(security) : '',
        isEquipmentHasData ? autodiagService.getTotalRespOptionnalStep(equipments) : '',
        isQuestionsHasData ? autodiagService.getTotalRespOptional(questions) : '',
        isManagementStrategyHasData ? autodiagService.getTotalRespMandatoryStep(managementStrategy) : '',
        isCommunicationMotivationHasData ? autodiagService.getTotalRespMandatoryStep(communicationMotivation) : '',
        isServicesHasData ? autodiagService.getTotalRespMandatoryStep(services) : '',
        isSecurityHasData ? autodiagService.getTotalRespMandatoryStep(security) : '',
        isEquipmentHasData ? autodiagService.getTotalRespMandatoryStep(equipments) : '',
        isQuestionsHasData ? autodiagService.getRestitutionScore(questions)?.mandatoryRequirementMetScore : '',
      ]

      return convertDataToStringFromArray(autodiagArray) //allows consistent alignment in excel column
    })
  }

  // prepare data
  return [
      // Header array
      autodiagHeader,
      autodiagSubHeader,
      ...await Promise.all(mapper(autodiags))
  ]
}