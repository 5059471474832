import React from 'react'
import { string } from 'prop-types'

import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'

const InterventionReport = ({ url = '' }) => (
    <div>
        <InformationToVisualize
            key={url} 
            label={'Vérifier la synthèse d’intervention'} 
            url={url} 
        />
    </div>
) 

InterventionReport.propTypes = {
    url: string
}

export default InterventionReport