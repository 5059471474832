import React, { useEffect, useState } from 'react'
import { useModal } from 'hooks/useModal'
import dateService from 'services/date'
import FormElement from 'components/shared/FormElement'
import { func, string } from 'prop-types'

const today = dateService.getCurrentDate('yyyy-MM-dd')

const ModalDateExport = ({
  title = '',
  validation = () => {},
  cancel = () => {}
}) => {
  const { hideModal } = useModal()
  const [ dates, setDates ] = useState({
    areDates: true,
    firstDate: today,
    lastDate: today
  })

  const cancelAndHide = () => {
    cancel()
    hideModal()
  }

  return (
    <div className="u-pd-xl">
      <h2 className="c-h2 u-primary u-center u-mg-vt-l">{title}</h2>

      <div className="u-flex u-flex-around c-datepicker">
        <label htmlFor="start-date">Date de début
          <input
            id="start-date" type="date" value={dates.firstDate} max={today}
            onChange={(event) => setDates(dates => ({ ...dates, firstDate: event.target.value }))}
            className='u-mg-left-s'
          />
        </label>
        <label htmlFor="end-date">Date de fin
          <input
            id="end-date" type="date" value={dates.lastDate} max={today}
            onChange={(event) => setDates(dates => ({ ...dates, lastDate: event.target.value }))}
            className='u-mg-left-s'
          />
        </label>
      </div>

      <div className="u-flex u-flex-center-hz">
        <FormElement
            value={!dates.areDates}
            name="exportAllFolders"
            type="checkbox"
            label=""
            checkboxLabel="Tout exporter"
            className="u-pd-vt-m"
            classNameInput=""
            classNameOption=""
            onChange={() => {
              setDates((prevDate) => {
                return {
                  areDates: !prevDate.areDates,
                  firstDate: Date,
                  lastDate: Date
                }
              })
            }}
        />
      </div>
      <div className="u-flex u-flex-dir-row u-mg-top-l u-flex-around">
          <button
              type="button"
              className="c-btn c-btn--white-bordered u-pd-hz-l u-mg-bottom-m u-mg-0@main"
              onClick={cancelAndHide}
          >
              Annuler
          </button>

          <button
              type="button"
              className={'c-btn c-btn--primary u-pd-hz-l u-mg-bottom-m u-mg-0@main'}
              onClick={() => validation(dates)}
          >
              Confirmer
          </button>
      </div>
    </div>
  )
}

ModalDateExport.propTypes = {
  title: string,
  validation: func.isRequired,
  cancel: func
}

export default ModalDateExport