import React from "react"
import { isNotEmpty, isPasswordValid } from "utils/validation"
import EyeSeeIcon from "components/svg/EyeSee"
import EyeHideIcon from "components/svg/EyeHide"

const InputPassword = ({
    // Attributes
    id = null,
    value,
    name,
    label = "",
    placeholder = "",
    disabled,
    readonly = false,
    autocomplete = "current-password",
    // Styles
    style,
    className = "",
    classNameLabel = "",
    classNameInput = "",
    // Error management
    passwordToConfirm,
    passwordCreate = false,
    required = false,
    customRules,
    showErrors,
    // Handler
    onChange,
    onValidate,
    withPatternRule = true
}) => {
    const [errors, setErrors] = React.useState([])
    const [passwordVisible, setPasswordVisible] = React.useState(false)
    const passwordType = passwordVisible ? "text" : "password"

    const patternRule = {
        key: 'password-invalid',
        check: (value) => (required || (isNotEmpty(value) && passwordCreate)) ? isPasswordValid(value) : true,
        message: "Le format du mot de passe n'est pas valide. Il doit avoir 8 caractères minimum, au moins 1 chiffre, au moins 1 minuscule, au moins 1 majuscule."
    }
    
    const rules = React.useMemo(() => [
        {
            key: "password-empty",
            check: (value) => required ? isNotEmpty(value) : true,
            message: "Vous devez entrer un mot de passe"
        },
        {
            key: "password-not-confirmed",
            check: (value) => (isNotEmpty(value) && passwordToConfirm) ? (passwordToConfirm === value) : true,
            message: "Ce mot de passe ne correspond pas au premier mot de passe entré"
        },
        ...(withPatternRule ? [patternRule] : []),
        ...(customRules || [])
    ], [required, passwordToConfirm, passwordCreate]) // eslint-disable-line
    // customRules in dependencies array trigger an infinite loop,
    // because custom rules include a check property defining a new function,
    // preventing useMemo to return a valid shallow-comparison
    // or event useDeepMemo to return a valid deep-comparison

    const validate = React.useCallback((currentValue) => {
        let newErrors = []
        rules.forEach(rule => {
            if (!rule.check(currentValue)) {
                newErrors.push({
                    origin: name,
                    rule: rule.key,
                    message: rule.message
                })
            }
        })
        setErrors(newErrors)
        onValidate(name, newErrors)
    }, [rules, name, onValidate])

    // validate on mount and each time value change
    React.useEffect(() => {
        validate(value)
        return () => {
            // On unmounted erros and validation are no longer needed
            setErrors([])
            if (onValidate) onValidate(name, [])
        }
    }, [value, validate, onValidate, name])

    return (
        <>
            <div className={"c-form-group " + className} style={style}>
                <label className={"c-label u-mg-bottom-xs " + classNameLabel} htmlFor={id}>{label} {required && "*"}</label>
                <input
                    className={
                        "c-input "
                        + (showErrors && errors.length > 0 ? "has-error " : " ")
                        + (classNameInput ? classNameInput : "")
                    }
                    id={id}
                    name={name}
                    type={passwordType}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    autoComplete={passwordCreate ? "new-password" : autocomplete}
                    required={required}
                    readOnly={readonly}
                    onChange={(e) => !readonly && onChange(e)}
                    onBlur={() => validate(value)}
                />

                { value.length > 0 &&
                    <button type="button" onClick={() => setPasswordVisible(!passwordVisible)}>
                    {passwordVisible ? <EyeSeeIcon size={25} /> : <EyeHideIcon size={25} />}
                    </button>
                }

                {/* Error messages */}
                {showErrors && errors && errors.map((error) => {
                    return <p key={error.message} className="u-fs-xs u-danger">{error.message}</p>
                })}
            </div>
        </>
    )
}


export default InputPassword
