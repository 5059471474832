import React, { useEffect, useState } from 'react'

import { useModal } from 'hooks/useModal'
import dateService from 'services/date'
import { func, object, string } from 'prop-types'

const ModalPlanning = ({
    title = '',
    validation = () => { },
    audit = {},
    cancel = () => { },
    minDate = '',
    maxDate = ''
}) => {

    const { planned_date: currentAuditDate } = audit
    const defaultDate = currentAuditDate ? dateService.formatDate(currentAuditDate, 'y-MM-dd') : dateService.getCurrentDate('y-MM-dd')

    const { hideModal } = useModal()
    const [date, setDate] = useState(defaultDate)
    const [disableConfirm, setDisableConfirm] = useState(false)

    useEffect(() => {
        setDisableConfirm(dateService.isValidDate(date))
    }, [date])

    const cancelAndHide = () => {
        cancel()
        hideModal()
    }

    return (
        <div className="u-pd-xl">
            <h2 className="c-h2 u-primary u-center u-mg-vt-l">{title}</h2>

            <div className="u-flex u-flex-center-hz">
                <input
                    id="date"
                    type="date"
                    value={date}
                    max={maxDate}
                    min={minDate}
                    onChange={(event) => setDate(event.target.value)}
                />
            </div>

            <div className="u-flex u-flex-dir-row u-mg-top-l u-flex-around">
                <button
                    type="button"
                    className="c-btn c-btn--white-bordered u-pd-hz-l u-mg-bottom-m u-mg-0@main"
                    onClick={cancelAndHide}
                >
                    Annuler
                </button>
                <button
                    type="button"
                    className={'c-btn c-btn--primary u-pd-hz-l u-mg-bottom-m u-mg-0@main'}
                    onClick={() => validation(date)}
                    disabled={disableConfirm}
                >
                    Confirmer
                </button>
            </div>
        </div>
    )
}

ModalPlanning.propTypes = {
    title: string,
    validation: func.isRequired,
    audit: object,
    cancel: func,
    minDate: string,
    maxDate: string,
}

export default ModalPlanning