import { useHistory } from 'react-router-dom'

import { publicRoutes } from 'router/routes'

const useBackOfficeRoutes = () => {
    const history = useHistory()

    return {
        isCurrentRouteBackOffice: history.location?.pathname?.startsWith(publicRoutes.backOffice.pattern)
    }
}

export default useBackOfficeRoutes