import React from 'react'
import { privateRoutes } from 'router/routes'
import BackOfficeLayout from 'layouts/back-office'
import UsersInformations from 'components/back-office/UsersInformations'
import { provider, mobilityProvider, supportProvider, scopingMeetingProvider, auditProvider } from 'utils/domain/provider'

const ParticipantsUsers = () => {

  const textPage = {
    title: 'Intervenants',
    action: 'Créer un intervenant'
  }

  const participantsCriteria = {
    genders: [provider.value],
    services: [mobilityProvider.serviceId, supportProvider.serviceId, scopingMeetingProvider.serviceId, auditProvider.serviceId ]
  }

  const detailPageRoute = privateRoutes.backOffice.participantDetailsAdministration
  const creationPageRoute = privateRoutes.backOffice.participantCreationAdministration

  return (
    <BackOfficeLayout>
      <UsersInformations 
        textPage={textPage} 
        usersCriteria={participantsCriteria} 
        detailPageRoute={detailPageRoute} 
        creationPageRoute={creationPageRoute}
      />
    </BackOfficeLayout>
  )
}

export default ParticipantsUsers
