import httpService from 'services/http'
import { getError } from 'services/api/errors'
import {
    cancelScopingMeetingError,
    getMyScopingMeetingError,
    getMyScopingMeetingsError,
    getScopingMeetingsError,
    getScopingMeetingError,
    planScopingMeetingError,
    updateScopingMeetingError
} from './errors'

export const getMyScopingMeeting = async () => {
    const res = await httpService.get('oepvapi/framing_meeting').catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, getMyScopingMeetingError),
    }
}

export const getMyScopingMeetings = async () => {
    const res = await httpService.get('oepvapi/backend/framing_meetings').catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, getMyScopingMeetingsError),
    }
}

export const getScopingMeetingByEntityForDashboardAdmin = async (entityId) => {
    const res = await httpService.get(`/oepvapi/backend_fub/framing_meeting/${entityId}`).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, getScopingMeetingError)
    }
}

export const planScopingMeeting = async () => {
    const res = await httpService.post('oepvapi/framing_meeting', {}, {}).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, planScopingMeetingError),
    }
}

export const cancelScopingMeeting = async () => {
    const res = await httpService.put('oepvapi/cancel_framing_meeting', {}).catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, cancelScopingMeetingError),
    }
}

export const updateScopingMeeting = async (scopingMeeting) => {
    const res = await httpService.put(`/oepvapi/backend/framing_meeting/${scopingMeeting.entity_id}`, scopingMeeting, {})
                    .catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, updateScopingMeetingError),
    }
}

export const getScopingMeetings = async () => {
    const res = await httpService.get('/oepvapi/backend_fub/meetings').catch(e => httpService.catchError(e))
    return {
        res,
        getError: getError(res, getScopingMeetingsError),
    }
}