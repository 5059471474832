import React from 'react'

import Restitution from 'components/autodiag/Restitution'
import Emoji from 'components/shared/Emoji'
import { useAuth } from 'hooks/useAuth'
import AutoDiagProvider, { useAutoDiag } from 'pages/protected/Autodiag/context'

const AutodiagnosticMonitoring = () => {
    const { getLastAutoDiagCompleted } = useAutoDiag()

    if(!getLastAutoDiagCompleted()) {
        return (
            <p className="u-center u-mg-top-m">
                Vous n'avez pas encore complété votre autodiagnostic <Emoji label="visage triste" symbol="😟" />
            </p>
        )
    }

    return (
        <Restitution />
    )
}

const AutoDiagnosticMonitoringWithProvider = () => {
    const { user } = useAuth()
    return (
        <AutoDiagProvider autodiagUser={user}>
            <AutodiagnosticMonitoring/>
        </AutoDiagProvider>
    )
}

export default AutoDiagnosticMonitoringWithProvider