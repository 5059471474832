import Remise_en_selle from 'assets/images/Remise_en_selle.svg'
import Velo_abords_entreprise from 'assets/images/Velo_abords_entreprise.svg'
import Formation_VAE from 'assets/images/Formation_VAE.svg'
import Code_de_la_route from 'assets/images/Code_de_la_route.svg'

const generateQuantityOptions = (length) =>
    Array.from({ length }, (_, i) => ({ value: i + 1, label: i + 1 }))
    
export const drivingSafelyCompany = {
    label: 'Formation « Rouler en sécurité​ »',
    shortLabel: 'Formation « Rouler en sécurité​ »',
    id: 'driving-safely-company',
    picture: Velo_abords_entreprise,
    description: 'D\'une durée de 3h environ, cette formation allie, selon les besoins, théorie, réglages du vélo et mise en situation pour apprendre à circuler à vélo en sécurité.',
    duration: '1h30​',
    target: '3 à 6 personnes par session',
    place: 'A l\'entreprise et aux alentours',
    slot: 'Sur le temps de travail',
    price: '350€ HT par session',
    quantitySelection: {
        property: 'sessionsQuantity',
        options: generateQuantityOptions(10),
        label: 'Nombre de sessions'
    },
    unitPrice: 350,
    refundPortion: 0.6,
    display: true,
    content: `
### Objectifs

- Être capable de circuler à vélo en sécurité avec les autres usagers,
- Connaître les bons gestes et les bonnes pratiques pour inciter l’usage du vélo comme moyen de transport

### Contenu (adaptable selon les besoins)

- Séquence 1 : préparation du vélo et conseils pratiques (30 min)
   - Réglages ergonomiques et sécuritaires (selle, guidon, freins),
   - Conseils d'entretien,
   - L’équipement du vélo (éclairage, vêtements).
- Séquence 2 : bien circuler à vélo sur son trajet, mise en situation (1h)
   - Connaître les droits et devoirs du cycliste,
   - Observer les aménagements et savoir les utiliser,
   - S’initier aux gestes de conduite.

### Modalités

- Nombre de participants : minimum 3 et jusqu’à 6
- La formation se déroule sur le temps de travail ou durant la pause méridienne, sur le site de l’employeur et aux alentours (pour la mise en situation).
- Lieu : site de l’employeur et aux alentours.
- Durée : 1h30

### L’employeur

- Prend les inscriptions et transmet la liste des participants à l’intervenant 7 jours avant la date de la formation,
- Informe les salariés qu’ils devront avoir un vélo personnel ou mis à disposition par l’employeur (freins fonctionnels, lumières, vélo en état de rouler),
- Met à disposition de l’intervenant des équipements pédagogiques (une salle de réunion équipée d’un ordinateur et vidéoprojecteur, d’un tableau blanc ou paperboard) et si possible un espace extérieur pour la préparation des vélos et des exercices pratiques,
- Importe la [feuille d'émargement](https://employeurprovelo.fr/wp-content/uploads/2023/10/Feuille-emargement-Rouler-en-securite-20231019.pdf) transmise par l'intervenant sur la plateforme OEPV 

### L'intervenant formateur

- Adapte le contenu de la formation aux besoins de l’employeur et des salariés participants,
- Prépare un itinéraire autour de l’environnement du lieu de travail

### Le salarié

- Vient avec son vélo personnel ou un vélo mis à disposition par l’employeur (freins fonctionnels, lumières, vélo en état de rouler).

### Coût de la formation

Coût de 350 € HT pris en charge à 60% par le programme Objectif Employeur Pro-Vélo, soit un reste à charge pour l’employeur de 140 €. 
    `
}

export const backToBikeTraining = {
    label: 'Formation "Remise en selle"',
    shortLabel: 'Remise en selle',
    picture: Remise_en_selle,
    id: 'training-back-to-bike',
    description: 'D\'une durée de 2h, cette formation s\'adresse aux salarié·e·s sachant déjà rouler à vélo mais n\'osant pas le faire pour des trajets du quotidien pour diverses raisons (interruption de la pratique, besoin de conseils et de mise à niveau, crainte pour la sécurité...).',
    duration: '2h',
    target: '1 à 3 personnes par session',
    place: 'Local Vélo Ecole',
    slot: 'Sur le temps de travail ou non',
    price: '120€ HT par personne',
    quantitySelection: {
        property: 'personsQuantity',
        options: generateQuantityOptions(20),
        label: 'Nombre de participants'
    },
    unitPrice: 120,
    refundPortion: 0.6,
    display: true,
    content: `
### Objectifs

En fonction du niveau des personnes, la formation propose conseils théoriques, mise en situation et conseils pour s'équiper et régler son vélo. Ses objectifs sont :

- Acquérir les outils et connaissances nécessaires pour changer son regard sur la pratique du vélo comme moyen de déplacement
- Gagner en confiance à vélo et donner les clés pour acquérir les bons réflexes sur la route, et ainsi se déplacer en toute sécurité

### Contenu

- Séquence 1 (avant la formation) : faire connaissance et (re)découvrir le vélo
   - Envoi d'un questionnaire et d'informations sur l'usage du vélo
- Séquence 2 : un vélo sûr et adapté à la pratique (30 à 45 min)
   - Point sur les besoins (trajets, motivations, équipement, type de vélo) et les offres (achat, location, entretien)
   - Vérification du vélo du participant et réglages simples avant mise en pratique
- Séquence 3 : rouler à vélo en confiance (1h15 à 1h30)
   - Conseils pratiques et remise d'un guide (voir, être vu, anticiper, rester vigilant, identification des situations à risque, choisir son itinéraire)
   - Mise en situation en fonction des besoins (exercice de conduite dans un espace protégé, accompagnement sur un itinéraire, identification des aménagements)

### L’employeur

- Prend les inscriptions et les transmet à l'intervenant
- L'employeur peut choisir plusieurs séances pour un même salarié
- Importe la [feuille d'émargement](https://employeurprovelo.fr/wp-content/uploads/2023/12/RES_Feuille-demargement.pdf) transmise par l'intervenant sur la plateforme OEPV 

### L'intervenant

- Contacte les inscrits pour recueillir des renseignements utiles à la formation et définir une date
- Réalise la formation et retourne la feuille d'émargement à l'employeur

### Le salarié

- Vient avec le vélo qu'il souhaite utiliser régulièrement (freins fonctionnels, lumières, vélo en état de rouler) ou s'en procure un (certains intervenants peuvent mettre à disposition des vélos sur demande et sous conditions)

### Modalités

Coût de 120€ HT par personne et par séance pris en charge à 60% par le programme Objectif Employeur Pro-Vélo, soit un reste à charge pour l'employeur de 48€ par personne et par séance.

Les participants peuvent bénéficier de plusieurs séances en fonction de leur niveau, avec une prise en charge de 60 % de chaque séance par le programme pour l'employeur.

    `,
}

export const praticalElectricBikeTraining = {
    label: 'Formation pratique « Prise en main vélo à assistance électrique (VAE/VCAE) »',
    shortLabel: 'Prise en main vélo à assistance électrique (VAE/VCAE)',
    id: 'pratical-training-electric-bike',
    picture: Formation_VAE,
    description: 'D\'une durée d\'1h, cette formation pratique permet la prise en main d\'un vélo à assistance électrique (VAE) : fonctionnement, maniabilité, exercices. Elle s\'adresse à des personnes ayant déjà une pratique du vélo sans assistance.',
    duration: '1h',
    target: '3 à 8 personnes par session',
    place: 'A l\'entreprise',
    slot: 'Sur le temps de travail ou pause méridienne',
    price: '230€ HT par session',
    quantitySelection: {
        property: 'sessionsQuantity',
        options: generateQuantityOptions(10),
        label: 'Nombre de sessions'
    },
    unitPrice: 230,
    refundPortion: 0.6,
    display: true,
    content: `
### Objectifs

- Prendre en main un VAE et/ou un VCAE,
- Être en mesure d’adapter sa conduite (allure, anticipation, distance de freinage...).

### Contenu

- Les particularités de la conduite du VAE/VCAE,
- Les fonctionnalités et l’entretien du VAE/VCAE,
- Pratique en espace protégé : maniabilité, exercices.

### Modalités

- Nombre de participants : minimum 3, maximum 8.
- La formation se déroule sur le temps de travail ou durant la pause méridienne, sur le site de l’employeur.
- Lieu : site employeur hors voirie publique, espace clos et sécurisé (délimitation d’un espace sur le parking de l’employeur).

### L’employeur

- Prend les inscriptions et transmet la liste des participants à l’intervenant 7 jours avant la date de la formation
- Fournit les vélos à assistance électrique à son personnel (ou indique à l'intervenant qu'il n'en dispose pas afin que celui-ci puisse éventuellement les fournir)
- Si possible délimite un espace extérieur de 1 000 m² environ pour l'évolution des cyclistes
- Importe la [feuille d'émargement](https://employeurprovelo.fr/wp-content/uploads/2023/10/Feuille-emargement-VAE-VCAE-20231019.pdf) transmise par l'intervenant

### L’intervenant formateur

- Adapte le contenu de la formation aux besoins de l’employeur et des salariés participants, et devra respecter certaines étapes qui sont précisées sur la fiche spécifique au moniteur.

### Coût de la formation

Coût de 230 € HT pris en charge à 60 % par le programme Objectif Employeur Pro-Vélo, soit un reste à charge pour l’employeur de 92 €.
    `,    
}

export const trafficCodeTraining = {
    label: 'Formation théorique « La route vue du guidon »',
    shortLabel: 'La route vue du guidon',
    id: 'training-traffic-code',
    picture: Code_de_la_route,
    description: 'D\'une durée de 2h, cette formation théorique permet d\'acquérir les connaissances nécessaires pour améliorer et sécuriser sa pratique de conduite vélo et/ou voiture. Elle se déroule en groupe (4 à 20 personnes) sur le lieu de travail. D’une durée de 2h, cette formation théorique permet d’acquérir les connaissances nécessaires pour améliorer et sécuriser sa pratique de conduite vélo et/ou voiture. Elle se déroule en groupe (4 à 20 personnes) sur le lieu de travail, s’adresse à tous publics (du cycliste expérimenté au non cycliste) et s’appuie sur des exemples concrets et des conseils de bonnes pratiques',
    duration: '2h',
    target: '4 à 20 personnes par session',
    place: 'A l\'entreprise',
    slot: 'Sur le temps de travail',
    price: '300€ HT par session',
    quantitySelection: {
        property: 'sessionsQuantity',
        options: generateQuantityOptions(10),
        label: 'Nombre de sessions'
    },
    unitPrice: 300,
    refundPortion: 0.6,
    display: true,
    content: `
### Objectifs

- Acquérir (ou mettre à jour) ses connaissances nécessaires pour adapter sa pratique de conduite vélo et/ou voiture
- Gagner en confiance
- Renforcer sa prise en compte des usagers vulnérables comme les personnes à pied ou à vélo

### Contenu

- Séquence 1 : Accueil et brise-glace (25 min)
- Séquence 2 : Bien s'équiper (20 min)
   - L'équipement du vélo
   - L'équipement du cycliste
   - Les réglages et l'entretien du vélo
- Séquence 3 : Les risques et les conseils pour les réduire et circuler plus en sécurité (40 min)
   - Les risques majeurs
   - Les bonnes pratiques pour rouler plus en sécurité
   - Les comportements à adopter en cas d'accident
- Séquence 4 : Connaitre les aménagements et les évolutions du Code de la route (20 min)
   - Les aménagements
   - Les particularités du Code pour les cyclistes
- Séquence 5 : Questions-réponses et fin de séance (15 min)

### L’employeur

- Prend les inscriptions et transmet la liste des participant·e·s à l'intervenant 5 jours avant la date de la formation
- Met à disposition de l'intervenant des équipements pédagogiques (une salle de réunion équipée d'un ordinateur et vidéoprojecteur, d'un tableau velleda ou paperboard)
- Importe la [feuille d'émargement](https://employeurprovelo.fr/wp-content/uploads/2023/12/Route-vue-du-guidon_Feuille-demargement.pdf) transmise par l'intervenant sur la plateforme OEPV 

### L'intervenant

Adapte le contenu de la formation aux besoins de l'employeur et des salarié·e·s participant.e.s ;

### Modalités

Coût de 300 € HT par session pris en charge à 60% par le programme Objectif Employeur Pro-Vélo, soit un reste à charge pour l’employeur de 120 €.
    `,
}

export const companyTraining = {
    label: 'Formation "Vélo aux abords du lieu de travail"',
    shortLabel: 'Vélo aux abords du lieu de travail',
    id: 'training-company',
    description: 'D\'une durée de 3h environ, cette formation allie, selon les besoins, théorie, réglages du vélo et mise en situation pour apprendre à circuler à vélo en sécurité.',
    duration: '3h',
    target: '3 à 6 personnes par session',
    place: 'A l\'entreprise',
    slot: 'Sur le temps de travail',
    price: '700€ HT par session',
    quantitySelection: {
        property: 'sessionsQuantity',
        options: generateQuantityOptions(10),
        label: 'Nombre de sessions'
    },
    unitPrice: 700,
    refundPortion: 0.6,
    display: false,
}

export const electricBikeTraining = {
    label: 'Formation pratique prise en main vélo à assistance électrique (VAE)',
    shortLabel: 'Prise en main vélo à assistance électrique (VAE)',
    id: 'training-electric-bike',
    description: 'D\'une durée d\'1h, cette formation pratique permet la prise en main d\'un vélo à assistance électrique (VAE) : fonctionnement, maniabilité, exercices. Elle s\'adresse à des personnes ayant déjà une pratique du vélo sans assistance.',
    duration: '1h',
    target: '3 à 8 personnes par session',
    place: 'A l\'entreprise',
    slot: 'Sur le temps de travail ou pause méridienne',
    price: '150€ HT par session',
    quantitySelection: {
        property: 'sessionsQuantity',
        options: generateQuantityOptions(10),
        label: 'Nombre de sessions'
    },
    unitPrice: 150,
    refundPortion: 0.6,
    display: false,
}

export const formationList = [
    companyTraining,
    drivingSafelyCompany,
    backToBikeTraining,
    praticalElectricBikeTraining,
    electricBikeTraining,
    trafficCodeTraining
]