import React from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { useBasket } from 'hooks/useBasket'
import { useAlert } from 'hooks/useAlert'
import { useModal } from 'hooks/useModal'
import { useAuth } from 'hooks/useAuth'
import { privateRoutes } from 'router/routes'
import { servicesEnum } from 'utils/domain/provider'
import { isNotEmpty } from 'utils/validation'
import { getMediaUrlPrefix } from 'utils/domain/user'
import { REQUEST_EQUIPMENTS_VALUE } from 'utils/domain/request'
import { scrollToTop } from 'utils/scrollTo'
import uploadFile from 'services/uploadFile'
import { addProvidersRequest, createCatalogRequest, getProviders, providerFilters, remapProviders } from '../catalogUtils'
import CatalogRoutes from '../CatalogRoutes'

import FormElement from 'components/shared/FormElement'
import ProvidersTable from 'components/platform/ProvidersTable'
import ModalConfirmation from 'components/shared/ModalConfirmation'

import { normalizeBoolean } from 'utils/functions'

const installationList = [
    { value: 'Oui', label: 'Pose incluse' },
    { value: 'Non', label: 'Pose non incluse' }
]

const defaultFormEvents = {
    ready: true
}

const ServiceEquipmentBid = () => {
    const { currentBasket, setBasket } = useBasket()
    const history = useHistory()

    if(!currentBasket) history.push(`${privateRoutes.catalog.path}/${CatalogRoutes[0].url}`)

    const defaultFormFields = React.useMemo(() => ({
        basket: currentBasket,
        installation: 'Oui',
        providersSelected: [],
        comment: '',
        picture: null,
        id: null
    }), [currentBasket])

    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [showGlobalError, setShowGlobalError] = React.useState(false)

    const { addAlert } = useAlert()
    const { showModal, hideModal } = useModal()
    const { user } = useAuth()

    React.useEffect(() => {
        // Generate an uuid for the bid each time the view refresh
        // prevent submitting multiple times the same bid
        setFormFields((s) => ({
            ...s,
            id: uuidv4()
        }))
    }, [])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
            ? target.checked
            : target.type === 'file'
                ? target
                : target.value
        const name = target.name

        setFormFields(s => ({
            ...s,
            [name]: value
        }))
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const handleSuccess = () => {
        hideModal()
        addAlert('success', 'Votre demande de devis a bien été envoyée !')
        setBasket(null)
        history.push({
            pathname: privateRoutes.success.path,
            state: { urlRedirect: privateRoutes.monitoringActivities.benefits.equipment.path }
        })
    }
    
    const handleError = (error) => {
        addAlert('error', error?.toString())
        setShowGlobalError(true)
        setFormEvents({ ...formEvents, ready: true })
        scrollToTop()
    }

    const handlePicture = async () => {
        let requestPicture = {}
        if (isNotEmpty(formFields?.picture)) {
            const { name: gender } = await uploadFile('ORIGNALPHOTO', {
                id: formFields?.picture?.id,
                extension: formFields?.picture?.extension,
                blob: formFields?.picture?.blob
            })

            requestPicture = {
                media_gender: gender,
                media_id: formFields?.picture?.id,
                media_url: `${getMediaUrlPrefix()}${formFields?.picture?.id}.${formFields?.picture?.extension}`,
                media_hash: formFields?.picture?.value
            }
        }
        return requestPicture
    }

    const handleCreateBidRequest = (requestPicture) => createCatalogRequest({
        user,
        requestId: formFields?.id,
        requestGender: REQUEST_EQUIPMENTS_VALUE,
        requestDescription: {
            ...formFields?.basket.reduce((acc, { id, quantity }) => {
                acc[id] = quantity
                return acc
            }, {}),
            comment: formFields?.comment.replace(/"/g, "'"),
            installation: formFields?.installation
        },
        requestPicture
    })

    const handleSelectedProviders = async (createRequest) => addProvidersRequest({
        request: createRequest,
        providersList: formFields?.providersSelected
    })

    const handleConfirmation = async () => {
        // hide error
        setShowLocalErrors(false)
        // prevent form to be submitted again
        setFormEvents((currentFormEvents) => ({ ...currentFormEvents, ready: false }))

        try {
            const requestPicture = await handlePicture()

            const { res: createRequest, getError: getBidRequestError } = await handleCreateBidRequest(requestPicture)
            if (getBidRequestError()) throw getBidRequestError()

            const { getError: getSelectedProvidorsError } = await handleSelectedProviders(createRequest)
            if (getSelectedProvidorsError()) throw getSelectedProvidorsError()

            handleSuccess()
        } catch(error) {
            handleError(error)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!(formFields?.providersSelected?.length > 0)) {
            addAlert('error', "Veuillez sélectionner au moins un fournisseur d'équipements.")
        }

        if (!formFields?.installation) {
            addAlert('error', 'Veuillez sélectionner un type de pose.')
        }

        // Validate inputs
        const formReady = !(errors.length > 0)
                        && formFields?.providersSelected?.length > 0
                        && formFields?.basket.length > 0
                        && formFields?.installation

        if (!formReady) {
            setShowLocalErrors(true)
        }
        else {
            showModal(
                ModalConfirmation,
                {
                    title: ' Envoyer votre demande de devis au(x) prestataire(s) ? ',
                    validate: handleConfirmation
                }
            )

        }
    }

    // Handle providers
    const [providers, setProviders] = React.useState([])
    const [filteredProviders, setFilteredProviders] = React.useState([])
    const [onlyShowSelectedProviders, setOnlyShowSelectedProviders] = React.useState(false)

    const hasInstallation = normalizeBoolean(formFields?.installation)
    
    const applyFilters = () => {
        const { installation: installationFilter, basket: basketFilter } = providerFilters(currentBasket)
        
        const filteredProviders = providers
            .filter(provider => basketFilter(provider))
            .filter(provider => hasInstallation ? installationFilter(provider) : true)
        setFilteredProviders(filteredProviders)
    }

    const handleGetProviders = async () => {
        const { res: loadProviders, error: loadProvidersError } = await getProviders({
            services: servicesEnum.equipment,
            remapProviders: (res) => remapProviders(res, hasInstallation)
        })

        if (loadProvidersError) addAlert('error', loadProvidersError?.toString())
        else setProviders(loadProviders)
    }

    React.useEffect(() => {
        handleGetProviders()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if(formFields?.providersSelected.length < 1) {
            setOnlyShowSelectedProviders(false)
        }
    }, [formFields?.providersSelected])

    React.useEffect(() => {
        applyFilters()
    }, [providers, formFields?.installation]) // eslint-disable-line react-hooks/exhaustive-deps
    

    return (
        <form>

            {showGlobalError &&
                <p className="u-fs-xs u-danger u-mg-bottom-m">Un problème est survenu lors de la demande de devis.</p>
            }

            <hr className="u-mg-vt-xl" />

            <section>
                <h2 className="c-h2 u-center u-primary u-bold u-mg-bottom-l">Votre projet de stationnement </h2>
                <div className="u-flex u-flex-dir-col u-flex-dir-row@main u-flex-between">
                    <div>
                        <h3 className="c-h3 u-primary u-mg-bottom-m">Synthèse de votre commande :</h3>
                        {formFields?.basket && formFields?.basket.length > 0 &&
                            <>
                                <ul className="u-reset-list">
                                    {formFields?.basket.filter(item => !item?.optional && item.quantity !== 0).map((item, index) => {
                                        return (
                                            <li className="u-mg-bottom-s" key={index}>
                                                <span className="u-bold">{item.label}</span> : {item.quantity} place(s)
                                            </li>
                                        )
                                    })}
                                    {formFields?.basket.filter(item => item?.optional && item.quantity !== 0).map((item, index) => {
                                           const optionalEquipmentLabel = () => {
                                            switch (item.id) {
                                                case 'lockers':
                                                    return 'casier(s)'
                                                case 'bike_toolkit':
                                                        return "station(s) d'outillage"
                                                default:
                                                    return 'station(s) de gonflage'
                                            }
                                        }

                                        return (
                                            <li className="u-mg-bottom-s" key={index}>
                                                <span className="u-bold">{item.label}</span> : {item.quantity} {optionalEquipmentLabel()}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </>
                        }
                        {(!formFields?.basket || formFields?.basket?.length === 0) &&
                            <p>Vous n'avez sélectionné aucun équipement.</p>
                        }
                    </div>
                    <div>
                        <div className="u-bg-primary u-pd-l u-br-l u-mg-bottom-m">
                            <h3 className="c-h3 u-white u-mg-bottom-m">Pose des équipements *</h3>
                            {/* Installation */}
                            <FormElement
                                value={formFields.installation}
                                name="installation"
                                type="radio"
                                options={installationList}
                                className="is-light u-mg-bottom-l"
                                classNameOptionsContainer="u-flex-dir-col"
                                classNameLabel="u-mg-bottom-m"
                                classNameOption="u-flex-center-vt"
                                classNameOptionLabel="u-white"
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="l-grid u-mg-negative-hz-m">
                    <div className="l-col-12 l-col-8@main u-pd-m">
                        {/* Comment */}
                        <FormElement
                            value={formFields.comment}
                            name="comment"
                            type="textarea"
                            label="Ajouter des compléments d’information à votre demande "
                            placeholder="Description du site, dimension de l’espace,…"
                            className="u-mg-bottom-l"
                            required={false}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="l-col-12 l-col-4@main u-pd-m">
                        {/* picture */}
                        <FormElement
                            value={formFields.picture}
                            type="file"
                            name="picture"
                            label="Ajouter une photo"
                            maxFileSize={10}
                            acceptPDF={false}
                            className="u-mg-bottom-m"
                            required={false}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                        />
                        <p className="u-fs-xs u-mg-bottom-m">Une photo de 10Mo maximum au format png ou jpg</p>
                    </div>
                </div>
            </section>

            <hr className="u-mg-bottom-xl" />

            <section>
                <h2 className="c-h2 u-center u-primary u-bold u-mg-bottom-l">Sélection du prestataire</h2>
                <p className="u-center u-mg-hz-auto">
                    Vous pouvez sélectionner jusqu'à 5 fournisseurs.<br />
                </p>
                {/* Show selection */}
                <FormElement
                    value={onlyShowSelectedProviders}
                    name="onlyShowSelectedProviders"
                    type="checkbox"
                    label=""
                    checkboxLabel={
                        <span>
                            Afficher uniquement la sélection
                        </span>
                    }
                    // disabled={formFields?.providersSelected?.length === 0}
                    className={'u-pd-vt-m'}
                    classNameInput=""
                    classNameOption=""
                    onChange={(e) => setOnlyShowSelectedProviders(e.target?.checked)}
                    disabled={formFields?.providersSelected.length < 1}
                />

                <ProvidersTable
                    data={onlyShowSelectedProviders ? formFields?.providersSelected : filteredProviders}
                    hasSelectableRows={true}
                    selection={formFields?.providersSelected}
                    setSelection={(value) => handleInputChange({ target: { name: 'providersSelected', value } })}
                    className="u-pd-bottom-l"
                    displayDomTom
                    displayDistance={false}
                />

                <div className="u-flex u-flex-center-hz u-mg-top-l u-mg-bottom-m">
                    {formEvents.ready &&
                        <button
                            type="submit"
                            className="c-btn c-btn--secondary u-pd-hz-l"
                            onClick={handleSubmit}
                            disabled={formFields?.providersSelected.length === 0}
                        >
                            Envoyer la demande de devis
                        </button>
                    }
                    {!formEvents.ready &&
                        <div className="c-spinner"></div>
                    }
                </div>
            </section>
        </form>
    )
}

export default ServiceEquipmentBid
