import { pdf } from '@react-pdf/renderer'

import * as CatalogApi from 'services/api/CatalogApi'
import dateService from 'services/date'

import { normalizeBoolean } from 'utils/functions'
import saveBlob from 'utils/saveBlob'
import { getQuantityOfServiceRequestsFromHistory } from 'utils/domain/request'
import { 
    equipmentBikeShelterWithFasteners, 
    equipmentBikeShelter,
    equipmentBikeSecureStorage,
    equipmentBikeHitch,
    equipmentBikeSecureStorageWithFasteners 
} from 'components/catalog/Equipment/list'

export const getUserKeywords = (data) => {
    let keywords = ''
    keywords += 'O-S-' + data?.structureSiret + ' '
    keywords += 'O-CP-' + data?.structurePostCode + ' '
    keywords += 'O-APE-' + data?.structureCodeApe + ' '
    keywords += 'O-FN-' + data?.contactFirstname + ' '
    keywords += 'O-LN-' + data?.contactLastname + ' '
    keywords += data?.contactEmail + ' '
    keywords += data?.structureName + ' '
    keywords += data?.structureAddress + ' '
    keywords += data?.structurePostCode + ' '
    keywords += data?.structureCity + ' '
    keywords += data?.contactFirstname + ' '
    keywords += data?.contactLastname + ' '
    return keywords
}

export const createCatalogRequest = async ({
    user,
    requestId,
    requestGender,
    requestDescription,
    requestPicture = {}
}) => {
    const request = {
        local_id: requestId,
        request_data: {
            operation_code: 'INIT',
            operation_data: {
                gender: requestGender,
                operation_date: dateService.getCurrentDate('yyyy-MM-dd HH:mm:ss'),
                request_description: requestDescription,
                ...requestPicture
            }
        },
        request_search_data: null,
        keywords: getUserKeywords(user?.entity_data)
    }
    return await CatalogApi.createRequest(request)
}

export const addProvidersRequest = async ({
    request,
    providersList
}) => {
    const editedRequest = {
        request_id: request?.request_id,
        request_data: {
            operation_code: 'PROVIDER',
            operation_data: {
                // only send an array of the providers' entity_id
                // keep `providers` property for backend management
                providers: providersList?.map(p => p?.entity_id),
                operation_date: dateService.getCurrentDate('yyyy-MM-dd HH:mm:ss'),
            }
        },
        request_search_data: null,
        last_row_hash: request?.row_hash
    }
    return await CatalogApi.editRequest(editedRequest)
}

export const getProviders = async ({
    services,
    remapProviders = (items) => items,
    latitude,
    longitude,
    mapRadius
}) => {
    const filterProvidersByLocation = latitude && longitude
    const providersRes = { res: null, error: null }
    try {
        const activeEntities = true // param used to search only active providers
        const { res: entities, getError } = filterProvidersByLocation 
            ? await CatalogApi.getEntitiesLocation({ services, latitude, longitude, mapRadius, activeEntities })
            : await CatalogApi.getEntities({ services, activeEntities })
        if (getError()) throw getError()

        // Sort by date of creation if not get by location, the oldest appears the first
        if(!filterProvidersByLocation)
            entities.sort((a, b) => (a.create_date > b.create_date) ? 1 : ((b.create_date > a.create_date) ? -1 : 0))

        providersRes.res = remapProviders(entities)
    } catch (error) {
        providersRes.error = error
    } finally {
        return providersRes
    }
}

export const cancelRequest = async ({
    request
}) => {
    const canceledRequest = {
        request_id: request?.request_id,
        request_data: {
            operation_code: 'CAN',
            operation_data: {
                operation_date: dateService.getCurrentDate('yyyy-MM-dd HH:mm:ss')
            }
        },
        request_search_data: null,
        last_row_hash: request?.row_hash
    }

    return await CatalogApi.editRequest(canceledRequest)
}

export const attendanceSheetRequest = async ({
    request,
    operationCode,
    documents
}) => {
    const canceledRequest = {
        request_id: request.request_id,
        request_data: {
            operation_code: operationCode,
            operation_data: {
                documents,
                operation_date: dateService.getCurrentDate('yyyy-MM-dd HH:mm:ss'),
                comment: request?.request_data?.operation_data?.comment
            },
        },
        request_search_data: null,
        last_row_hash: request?.row_hash
    }

    return await CatalogApi.editRequest(canceledRequest)
}

export const validateBidRequest = async ({ request, providerId, bidAmount }) => {
    const editedRequest = {
        request_id: request?.request_id,
        request_data: {
            operation_code: 'VAL',
            operation_data: {
                provider_id: providerId,
                operation_date: dateService.getCurrentDate('yyyy-MM-dd HH:mm:ss'),
                amount: parseInt(bidAmount)
            }
        },
        request_search_data: null,
        last_row_hash: request?.row_hash
    }

    return await CatalogApi.editRequest(editedRequest)
}

export const getQuantity = async (request_id, { isFromBackOffice }) => {

    const getHistory = isFromBackOffice ? CatalogApi.getBackOfficeRequestHistory : CatalogApi.getRequestHistory
   
    const { res: requestHistory, getError } = await getHistory(request_id)
    if (getError()) throw getError()

    return getQuantityOfServiceRequestsFromHistory(requestHistory)
}

export const downloadRequestPDF = async ({
    user,
    request,
    pdfTemplate: PDFTemplate,
    pdfTitle = 'demande-de-prestation',
    provider,
    readRequestProviders = true,
    isFromBackOffice = false
}) => {
    const generatedPDF = { res: null, error: null }
    const currentDate = dateService.getCurrentDate('dd-MM-y')
    try {
        let requestProviders = []

        const {
            sessionsQuantity,
            personsQuantity
        } = await getQuantity(request?.request_id, { isFromBackOffice })

        if(readRequestProviders) {
            const { res, getError: getErrorRequestProviders } = await CatalogApi.getRequestProviders(request?.request_id)
            if (getErrorRequestProviders()) throw getErrorRequestProviders()

            requestProviders = [...res]
        }

        const generatedBlob = request && user?.entity_data && await pdf((
            <PDFTemplate
                beneficiary={user?.entity_data}
                request={request}
                provider={provider || requestProviders[0]?.provider_data}
                sessionsQuantity={sessionsQuantity}
                personsQuantity={personsQuantity}
            />
        )).toBlob()
        saveBlob(generatedBlob, `${pdfTitle}-${request?.request_fub_id}-${currentDate}.pdf`, true)
        generatedPDF.res = generatedBlob
    } catch (error) {
        generatedPDF.error = error
    } finally {
        return generatedPDF
    }
}

export const remapProviders = (providers, installation = false) => {
    return providers.map(formerProvider => ({
            ...formerProvider.entity_data,
            entity_id: formerProvider.entity_id,
            distance: formerProvider.distance ? `${(formerProvider.distance / 1000).toFixed(0)}km` : 'Non précisé',
            domTom: installation ? formerProvider.entity_data.dom_tom_installation : formerProvider.entity_data.dom_tom_supply || 'Non précisé',
            france: installation ? formerProvider.entity_data.france_installation : formerProvider.entity_data.france_supply || 'Non précisé',
        })
    )
}

export const providerFilters = (basket) => ({
    installation: (provider) => (normalizeBoolean(provider?.dom_tom_installation) || normalizeBoolean(provider?.france_installation)),
    basket: (provider) => basket?.reduce((acc, item) => {
        // equipmentBikeShelter => 'Abri',
        // equipmentBikeSecureStorage => 'Consigne',
        // equipmentBikeHitch => 'Attaches',

        // Si nous avons dans le panier cet équipement : Consigne sécurisée et supports d’attache
        if(item.id === equipmentBikeSecureStorageWithFasteners.id) {
            // Alors on regarde si le fournisseur fait Consignes ET Attaches
            return (acc && normalizeBoolean(provider[equipmentBikeSecureStorage.id]) && normalizeBoolean(provider[equipmentBikeHitch.id]))
        }

        // Si nous avons dans le panier cet équipement : Abri ouvert et supports d’attache
        if(item.id === equipmentBikeShelterWithFasteners.id){
            // Alors on regarde si le fournisseur fait Abris ET Attaches
            return (acc && normalizeBoolean(provider[equipmentBikeShelter.id]) && normalizeBoolean(provider[equipmentBikeHitch.id]))
        }

        return (acc && normalizeBoolean(provider[item.id]))
    }, true),
    service: (provider) => normalizeBoolean(provider[basket[0].id])
})