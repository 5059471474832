import React from 'react'
import { number, string } from 'prop-types'

const BulbIcon = ({ style, size = 45 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height={size} width={size} viewBox="0 0 27 27" aria-hidden="true" style={style}>
            <path d="M15.177 25.897C14.932 26 14.62 26 14 26s-.932 0-1.177-.103a1.343 1.343 0 0 1-.722-.732c-.089-.218-.1-.487-.1-.975a.688.688 0 0 1 .69-.69h2.619a.69.69 0 0 1 .69.69c-.002.488-.012.757-.101.975a1.343 1.343 0 0 1-.722.732Z" fill="#005A7C"/>
            <path d="M17 20.326a7 7 0 1 0-6 0V21c0 .932 0 1.398.152 1.765a2 2 0 0 0 1.083 1.083C12.602 24 13.068 24 14 24c.932 0 1.398 0 1.765-.152a2 2 0 0 0 1.083-1.083C17 22.398 17 21.932 17 21v-.674Z" fill="#F2B825"/><path d="M11 14h4m-2 0v6m3-.674a7 7 0 1 0-6 0V20c0 .932 0 1.398.152 1.765a2 2 0 0 0 1.083 1.083C11.602 23 12.068 23 13 23c.932 0 1.398 0 1.765-.152a2 2 0 0 0 1.083-1.083C16 21.398 16 20.932 16 20v-.674Z" stroke="#000" fill="transparent" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 3v1M4 14H3m3.5-6.5-.6-.6M21 6.5l.6-.6M25 14h-1" stroke="#F2B825" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M13 2v1M3 13H2m3.5-6.5-.6-.6M20 5.5l.6-.6M24 13h-1" stroke="#000" fill="transparent" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13 25h0c-.621 0-.932 0-1.177-.082-.327-.11-.586-.321-.722-.586-.084-.166-.098-.37-.1-.726a.607.607 0 0 1 .606-.606h2.787a.607.607 0 0 1 .605.606c-.002.356-.016.56-.1.726-.136.265-.395.476-.722.586C13.932 25 13.62 25 13 25Z" stroke="#000" fill="transparent" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
	)
}

BulbIcon.propTypes = {
      size: number,
      style: string
}

export default BulbIcon
