import React from 'react'
import { NavLink } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { entityGenderList } from 'utils/constants'
import { privateRoutes } from 'router/routes'

import Logo from 'components/LogoOEPVWhite'
import { string } from 'prop-types'
import { ASSOCIATION_VALUE, PRIVATE_SECTOR_VALUE, PUBLIC_SECTOR_VALUE, PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE } from 'utils/domain/beneficiary'
import { GENDER_PROVIDER_VALUE } from 'utils/domain/provider'
import { ADMIN_USER_GENDER } from 'utils/domain/user'

const Header = ({ className }) => {
    const { user, logout } = useAuth()
    
    // Disambiguate role
    const disambiguateRole = React.useCallback(
        (role) => {
            switch (parseInt(role)) {
                case PRIVATE_SECTOR_VALUE:
                case PUBLIC_SECTOR_VALUE:
                case ASSOCIATION_VALUE:
                case PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE:
                    setCurrentRole('beneficiary')
                    break
                case GENDER_PROVIDER_VALUE:
                    setCurrentRole('provider')
                    break
                case ADMIN_USER_GENDER:
                    setCurrentRole('admin')
                    break
                case entityGenderList.find(e => e.id === 'management')?.value:
                    setCurrentRole('management')
                    break
                default:
                    break
            }
        },
        []
    )
    const [currentRole, setCurrentRole] = React.useState(null)
    React.useEffect(() => disambiguateRole(user?.entity_data?.gender), [disambiguateRole, user])

    // Menu mobile
    const [isMenuOpen, setIsMenuOpen] = React.useState(false)
	const handleMenuToggle = () => {
		setIsMenuOpen(!isMenuOpen)
		// Side effect
		document.querySelector('body').classList[isMenuOpen ? 'remove' : 'add']('u-overflow-hidden')
	}
    React.useEffect(() => {
        return () => {
            if (isMenuOpen) document.querySelector('body').classList.remove('u-overflow-hidden')
        }
    }, [isMenuOpen])

    return (
        <>
            <header className={
                'c-header u-pd-left-m u-pd-right-l '
                + (user ? 'is-logged ' : ' ')
                + (isMenuOpen ? ' is-open ' : ' ')
                + (className ? className : '')
            }>
                <NavLink exact to="/" className="u-pd-s">
                    <Logo className="c-header__logo" />
                </NavLink>

                {user &&
                    <div className="u-flex u-flex-end u-flex-center-vt u-height-full@main">
                        <nav className={'c-header__menu u-pd-right-l@main'}>
                            <ul className="c-header__nav">
                                {currentRole === 'beneficiary' &&
                                    <>
                                        <li className="u-pd-left-l@main">
                                            <NavLink exact to={privateRoutes.home.path} className="u-pd-s">
                                                Accueil
                                            </NavLink>
                                        </li>
                                        <li className="u-pd-left-l@main">
                                            <NavLink exact to={privateRoutes.myAccount.path} className="u-pd-s">
                                                Mon compte
                                            </NavLink>
                                        </li>
                                        <li className="u-pd-left-l@main">
                                            <NavLink exact to={privateRoutes.monitoringActivities.path} className="u-pd-s">
                                                Suivi de mes activités
                                            </NavLink>
                                        </li>
                                        <li className="u-pd-left-l@main">
                                            <NavLink exact to={privateRoutes.bonus.path} className="u-pd-s">
                                                Ma prime
                                            </NavLink>
                                        </li>
                                    </>
                                }
                                {currentRole === 'provider' &&
                                    <>
                                        <li className="u-pd-left-l@main">
                                            <NavLink exact to={privateRoutes.home.path} className="u-pd-s">
                                                Accueil
                                            </NavLink>
                                        </li>
                                        <li className="u-pd-left-l@main">
                                            <NavLink exact to={privateRoutes.myAccount.path} className="u-pd-s">
                                                Mon compte
                                            </NavLink>
                                        </li>
                                    </>
                                }
                                {currentRole === 'management' &&
                                    <>
                                        <li className="u-pd-left-l@main">
                                            <NavLink exact to={privateRoutes.home.path} className="u-pd-s">
                                                Mes réunions de cadrage
                                            </NavLink>
                                        </li>
                                        <li className="u-pd-left-l@main">
                                            <NavLink exact to={privateRoutes.myAccount.path} className="u-pd-s">
                                                Mon compte
                                            </NavLink>
                                        </li>
                                    </>
                                }
                                <li className="u-pd-s u-pd-left-l@main">
                                    <button onClick={logout}>
                                        Déconnexion
                                    </button>
                                </li>
                            </ul>
                        </nav>
                        <button className={'c-header__toggle u-pd-hz-m u-clickable u-mg-left-s' + (isMenuOpen ? ' is-open' : '')} onClick={() => handleMenuToggle()}>
                            <span></span>
                        </button>
                    </div>
                }

            </header>
        </>
    )
}

Header.propTypes = {
    className: string
}

export default Header
