import React from 'react'
import { arrayOf, number, shape, string } from 'prop-types'
import {
    Page,
    Text,
    Link,
    View,
    Document,
    Image,
    Font,
    StyleSheet
} from '@react-pdf/renderer'

import { STEPS } from 'pages/protected/Autodiag/context'
import { getColor } from 'utils/styles'
import Logo from 'assets/images/logo-oepv.png'
import Star from 'assets/images/star.png'
import autodiagService from 'utils/autodiag'

// Register font
// Import the direct link to the ttf file, example : https://react-pdf.org/repl
// https://gist.github.com/karimnaaji/b6c9c9e819204113e9cabf290d580551
Font.register({ family: 'Asap Condensed', src: 'https://fonts.gstatic.com/s/asap/v5/o5RUA7SsJ80M8oDFBnrDbg.ttf' })
Font.register({ family: 'Source Sans Pro', src: 'https://fonts.gstatic.com/s/sourcesanspro/v9/ODelI1aHBYDBqgeIAH2zlNRl0pGnog23EMYRrBmUzJQ.ttf' })

const primaryFontFamily = 'Asap Condensed'

// Width first col header
const COL1_WIDTH = 40
// Width both last col header -> for same width
const COLN_WIDTH = (100 - COL1_WIDTH) / 2

const styles = StyleSheet.create({
    page: {
        backgroundColor: getColor().white,
        fontFamily: 'Source Sans Pro',
        fontSize: 10,
        paddingVertical: 20,
        paddingHorizontal: 30
    },
    frontContent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 40,
    },
    frontTitle: {
        fontFamily: primaryFontFamily,
        fontSize: 28,
        lineheight: 32,
        color: getColor().primary,
        textAlign: 'center',
        marginTop: 15,
        letterSpacing: '-1px'
    },
    wrapLogo: {
        marginBottom: 50,
        textAlign: 'center',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center'
    },
    logo: {
        width: 180,
        display: 'block',
    },
    star: {
        width: 13,
        height: 13,
    },
    frontWorkflow: {
        marginTop: 20,
    },
    frontScore: {
        display: 'flex',
        flexDirection: 'row',
        marginVertical: 15,
        marginHorizontal: 110,
        padding: 10,
        width: 320,
        marginTop: 20,
        fontFamily: primaryFontFamily,
        fontSize: 14,
        color: getColor().primary,
        textAlign: 'center',
        backgroundColor: '#EFF2F9',
        borderRadius: 10,
    },
    frontScoreExplanation: {
        color: getColor().primary,
        textAlign: 'center',
        fontFamily: 'Helvetica',
        lineHeight: 1.5,
        marginTop: 30,
    },
    frontExplanationLegend: {
        lineHeight: 1.5,
    },
    frontAdvise: {
        marginTop: 30,
        textAlign: 'center',
        lineHeight: 1.5,
        marginBottom: 40,
    },

    frontLegend: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 10,
        justifyContent: 'space-between'
    },

    okLegend: {
        color: getColor().success,
        borderColor: getColor().success,
        backgroundColor: getColor().white,
        padding: 10,
        borderRadius: 10,
        borderStyle: 'solid',
        borderWidth: 1,
    },

    nokLegend: {
        color: getColor().primary,
        borderColor: getColor().primary,
        backgroundColor: getColor().white,
        padding: 10,
        borderRadius: 10,
        borderStyle: 'solid',
        borderWidth: 1,
    },

    koLegend: {
        color: getColor().danger,
        borderColor: getColor().danger,
        backgroundColor: getColor().white,
        padding: 10,
        borderRadius: 10,
        borderStyle: 'solid',
        borderWidth: 1,
    },

    starlegend: {
        fontFamily: primaryFontFamily,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },

    step: {
        paddingBottom: 30,
        marginTop: 30,
    },
    questions: {
        flexWrap: 'wrap'
    },
    stepTitle: {
        fontSize: 26,
        textAlign: 'center',
        color: getColor().primary,
        marginBottom: 30,
        fontFamily: primaryFontFamily,
        letterSpacing: '-1px'
    },
    link: {
        color: getColor().primary,
        display: 'flex',
        flexDirection: 'row',
    },
    question: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 25,
        marginLeft: 15,
        marginRight: 15,
        textAlign: 'left'
    },
    numQuestion: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 12,
        color: getColor().secondary,
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 60
    },
    content: {
        flexGrow: 1,
    },
    conditionalQuestionContent: {
        marginLeft: 55,
    },
    questionContent: {
        color: getColor().primary,
    },
    answerContent: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: 50,
        marginTop: 20,
    },
    answer: {
        padding: 10,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 10,
        alignSelf: 'flex-start',
        marginRight: 10,
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 200,
    },
    ok: {
        color: getColor().success,
        borderColor: getColor().success,
    },
    nok: {
        color: getColor().primary,
        borderColor: getColor().primary,
    },
    ko: {
        color: getColor().danger,
        borderColor: getColor().danger,
    },
    questionExplanation: {
        display: 'flex',
        flexDirection: 'row',
        padding: 10,
        flexGrow: 1,
        backgroundColor: '#EFF2F9',
        borderRadius: 10
    },
    table: { 
        display: 'table', 
        width: 'auto', 
        margin: `${20} auto`,
      }, 
      tableRowHeader: { 
        margin: 'auto', 
        flexDirection: 'row',
        backgroundColor: getColor().primary,
        color: getColor().white,
        borderRadius: 10,
      },
      tableRowBody: {
        color: getColor().primary, 
        flexDirection: 'row',
      },
      tableCol1Header: { 
        width: COL1_WIDTH + '%', 
        borderStyle: 'solid', 
        borderColor: getColor().white,
        borderWidth: 1,
        borderLeftWidth: 0, 
        borderTopWidth: 0
      },     
      tableColHeader: {
        width: COLN_WIDTH + '%', 
        borderStyle: 'solid', 
        borderColor: getColor().white,
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0
      },   
      tableCol1: { 
        width: COL1_WIDTH + '%',
        borderStyle: 'solid', 
        borderColor: getColor().white,
        borderWidth: 1,
        borderLeftWidth: 0, 
        borderTopWidth: 0,
      },
      tableCol: { 
        width: COLN_WIDTH + '%',
        borderStyle: 'solid', 
        borderColor: getColor().white,
        borderWidth: 1,
        borderLeftWidth: 0, 
        borderTopWidth: 0
      }, 
      tableCellHeader: {
        fontFamily: primaryFontFamily,
        fontSize: 15,
        padding: 5,
        margin: 'auto'
      },
      tableCell1: {
        fontFamily: primaryFontFamily,
        padding: 15,
        margin: 'auto' + 0,
      },
      tableCell: {
        padding: 15,
        margin: 'auto' + 0,
      }
})

const RestitutionPDF = ({
    questions,
    scoreExplanation,
    date,
    mandatoryRequirementMetScore
}) => {

    const getLink = (text) => {
        const splitRegex = /(?:\[(.*?)\]\s\((.*?)\))/g
        const [
            label,
            href
        ] = text.split(splitRegex).filter(Boolean)
        return <Link wrap={false} src={href} style={styles.link}>{label}</Link>
    }

    const formatExplanation = (explanation) => {
        const linkRegex = /(\[(?:.*?)\]\s\((?:.*?)\))/g
        
        if(explanation === null) return <></>

        const textChunk = explanation.split(linkRegex)

        return (
            <Text style={styles.questionExplanation}>
                {textChunk.map(text => {
                    const isLink = text.match(linkRegex)
                    if(isLink) {
                        return getLink(text)
                    }
                    return text
                })}
            </Text>
        )
    }

    const getAnswers = (question) => question.userResponses.map(response => ({
        key: response,
        content: question[response],
        explanation: formatExplanation(question[`${response}_explanation`]),
        report: question[`${response}_report`].toLowerCase(),
    }))

    const getMandatoryRequirementMetScoreRender = () => {

        if(mandatoryRequirementMetScore === 1) {
            return 'Vous validez 1 critère obligatoire sur 8'
        }

        return `Vous validez ${mandatoryRequirementMetScore} critères obligatoires sur 8`
    }

    const Br = () => '\n'

    const scoreGlobal = autodiagService.getRestitutionScore(questions)

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap={false}>
                <View style={styles.frontContent}>
                    <View style={styles.wrapLogo}>
                        <Image src={Logo} style={styles.logo} />
                    </View>
                    <Text style={styles.frontTitle}>
                        Restitution de votre autodiagnostic <Br />
                        employeur pro-vélo <Br />
                        du {date}
                    </Text>

                    <View style={styles.frontScore}>
                        <Text>{getMandatoryRequirementMetScoreRender()}</Text>
                    </View>
                    <Text style={styles.frontScoreExplanation}>
                        {scoreExplanation}
                    </Text>
                    <Text style={styles.frontAdvise}>
                        Prenez connaissance des commentaires proposés pour chaque question, ils contiennent des conseils pour vous faire
                        progresser dans votre démarche pro-vélo. Nous vous conseillons aussi la lecture du livre blanc 
                        <a href="https://employeurprovelo.fr/wp-content/uploads/2021/06/LivreBlanc_EmployeursProVelo_Light.pdf " target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}> « devenir un employeur pro vélo »</a>. 
                        Une couleur verte signifie que votre réponse satisfait le critère, une couleur bleue qu’un point de vigilance est
                        signalé, une couleur rouge que le critère n’est pas rempli.
                    </Text>

                    <Text style={styles.frontExplanationLegend}>Comment lire le rapport ?</Text>
                    <Text style={styles.frontExplanationLegend}>Le code couleur ci-dessous indique votre niveau de conformité pour chaque critère :</Text>
                    {/* Add 00 just for display block on pdf. Without any content, the div doesn't display... */}
                    <View style={styles.frontLegend}>
                        <Text style={styles.okLegend}>Votre réponse satisfait le critère</Text>
                        <Text style={styles.nokLegend}>Un point de vigilance est signalé</Text>
                        <Text style={styles.koLegend}>Le critère n'est pas rempli</Text>
                    </View>

                    <div style={styles.starlegend}>
                        <Image src={Star} style={{ width: 15, height: 15, marginRight: 5 }} /> 
                        <Text>Critère obligatoire</Text>
                    </div>
                </View>
            </Page>
            {Object.entries(questions).map(([step, questionsStep]) => (
                <Page size="A4" style={styles.page} wrap={false} key={step}>
                    <View style={styles.step}>
                        <View style={styles.stepTitle}>
                            <Text>{STEPS[step].position}. {STEPS[step].title}</Text>
                        </View>
                        {questionsStep.filter(question => question.userResponses?.length).map(question => {
                            const isConditionalQuestion = question.conditional_question
                            return (
                                <View style={styles.question} wrap={false} key={question.num_question}>
                                    <Text style={{
                                            ...styles.numQuestion,
                                            ...(isConditionalQuestion ? styles.conditionalQuestionContent : {})
                                            }}>
                                                {question.num_question}
                                    </Text>
                                    <View style={styles.content}>
                                        <Text style={styles.questionContent}>
                                            {question.question}
                                            {question.requirement_type === 'O' && <Image src={Star} style={styles.star} />}
                                        </Text>
                                        
                                        {getAnswers(question).map(answer => (
                                            <View style={styles.answerContent} key={answer.key}>
                                                <Text style={{ ...styles.answer, ...styles[answer.report] }}>{answer.content}</Text>
                                                    {answer.explanation}
                                            </View>
                                        ))}

                                    </View>
                                </View>
                            )}
                        )}
                    </View>
                </Page>
            ))}

            <Page size="A4" style={styles.page} wrap={false}>
                <View style={styles.table}>
                    <View style={styles.tableRowHeader}>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>Partie de l&apos;autodiagnostic</Text> 
                        </View> 
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Nombre de points forts identifiés (hors critères obligatoires)</Text> 
                        </View> 
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Nombre de critères obligatoires atteints</Text> 
                        </View> 
                    </View>
                    {Object.entries(questions).map(([step, questionsStep]) => (
                        <View style={styles.tableRowBody}>
                            <View style={styles.tableCol1}>
                                <Text style={styles.tableCell1}>{STEPS[step].title}</Text> 
                            </View>
                            <View style={styles.tableCol}>
                                {/* envoyer questionsStep */}
                                <Text style={styles.tableCell}>{autodiagService.getTotalRespOptionnalStep(questionsStep)}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{autodiagService.getTotalRespMandatoryStep(questionsStep)}</Text>
                            </View>
                        </View>
                    ))}
            
                    <View style={styles.tableRowBody}>
                        <View style={styles.tableCol1}>
                            <Text style={styles.tableCell1}>TOTAL</Text>
                        </View>
                        <View style={styles.tableCol}>
                            {/* envoyer question */}
                            <Text style={styles.tableCell1}>{autodiagService.getTotalRespOptional(questions)}/{scoreGlobal.nbrOptional}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            {/* envoyer question */}
                            <Text style={styles.tableCell1}>{scoreGlobal.mandatoryRequirementMetScore}/{scoreGlobal.nbrMandatory}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

RestitutionPDF.propTypes = {
    questions: shape(
        arrayOf(
            shape({
                userResponses: arrayOf(string),
                num_question: string,
                question: string,
            })
        )
    ),
    scoreExplanation: string,
    date: string,
    mandatoryRequirementMetScore: number
}

export default RestitutionPDF
