const saveBlob = (blob, fileName, targetFile = false) => {
    // create link
    const tempLink = document.createElement('a')
    document.body.appendChild(tempLink)
    tempLink.style = 'display: none'

    // populate blob
    const url = window.URL.createObjectURL(blob)
    if (targetFile) window.open(url, '_blank', '')

    tempLink.href = url
    tempLink.download = fileName

    // download
    if (!targetFile) tempLink.click()

    // Remove link
    if (!targetFile) window.URL.revokeObjectURL(url)
    // Adding setTimeout if open blob file in new tab => https://stackoverflow.com/questions/63109880/blob-createobjecturl-download-not-working-in-chrome
    if (targetFile) setTimeout(function(){ window.URL.revokeObjectURL(url)}, 900000) // 15 minutes

    tempLink.parentNode.removeChild(tempLink)
}

export default saveBlob