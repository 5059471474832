import httpService from 'services/http'
import { 
    getDeleteEmailError,
    getError,
    getSignUpError,
    getUserError,
    getUsersError,
    getUpdateBackOfficeUserError,
    getCreateBackOfficeUserError,
    getForgotPasswordError
} from 'services/api/errors'

const validateEmail = async (mail) => {
    const res = await httpService.post('oepvapi/check_email', mail, {}).catch(e => httpService.catchError(e))
    // console.log('validateEmail res: ', res)
    return res
}

// Only works in dev env
const deleteEmail = async (mail) => {
    const res = await httpService.get(`oepvapi/delete_email/${mail}`, {}).catch(e => httpService.catchError(e))
    // console.log('deleteEmail res: ', res)
    return {
        res,
        getError: getError(res, getDeleteEmailError)
    }
}

const signUp = async (data) => {
    const res = await httpService.post('oepvapi/entity', data, {}).catch(e => httpService.catchError(e))
    // console.log('signUp res: ', res)
    return {
        res,
        getError: getError(res, getSignUpError)
    }
}

const signIn = async (mail, password) => {
    const res = await httpService.post('oepvapi/signin', { mail, password }, {}).catch(e => httpService.catchError(e))
    // console.log('signin res: ', res)
    return res
}

const getUser = async (entity_id) => {
    const res = await httpService.get(`oepvapi/entity/${entity_id}`, {}).catch(e => httpService.catchError(e))
    // console.log('getUser res: ', res)
    return res
}

const updateUser = async (data) => {
    const res = await httpService.put('oepvapi/entity', data, {}).catch(e => httpService.catchError(e))
    // console.log('updateUser res: ', res)
    return {
        res,
        getError: getError(res, getUserError),
    }
}


const getBackOfficeUsers = async () => {
    const res = await httpService.get('oepvapi/users', {}).catch(e => httpService.catchError(e))
    // console.log('getBackOfficeUsers res: ', res)
    return {
        res,
        getError: getError(res, getUsersError)
    }
}

const getBackOfficeUser = async (userId) => {
    const res = await httpService.get(`oepvapi/user/${userId}`, {}).catch(e => httpService.catchError(e))
    // console.log('getBackOfficeUser res: ', res)
    return {
        res,
        getError: getError(res, getUserError)
    } 
}

const createBackOfficeUser = async (data) => {
    const res = await httpService.post('oepvapi/user', data, {}).catch(e => httpService.catchError(e))
    // console.log('createBackOfficeUser res: ', res)
    return {
        res,
        getError: getError(res, getCreateBackOfficeUserError)
    } 
}

const updateBackOfficeUser = async (userId, data) => {
    const res = await httpService.put(`oepvapi/user/${userId}`, data, {}).catch(e => httpService.catchError(e))
    // console.log('updateBackOfficeUser res: ', res)
    return {
        res,
        getError: getError(res, getUpdateBackOfficeUserError)
    } 
}

const forgotPassword = async (email) => {
    const res = await httpService.get(`oepvapi/initpassword/${email}`, {}).catch(e => httpService.catchError(e))
    // console.log('forgotPassword res: ', res)
    return {
        res,
        getError: getError(res, getForgotPasswordError)
    }
}

const updatePassword = async (code, newpassword, mail) => {
    const apiObj = {
        mail,
        newpassword,
        recovery_password: code
    }
    const res = await httpService.post('oepvapi/setpassword', apiObj, {}).catch(e => httpService.catchError(e))
    return res
}

export { 
    validateEmail,
    deleteEmail,
    signUp, 
    signIn, 
    getUser, 
    updateUser, 
    getBackOfficeUsers,
    getBackOfficeUser, 
    updateBackOfficeUser, 
    createBackOfficeUser,
    updatePassword, 
    forgotPassword,
}
