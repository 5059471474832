import React from 'react'
import { arrayOf, bool, func, shape, string } from 'prop-types'

import ChevronExpandIcon from 'components/svg/ChevronExpand'
import ChevronUpIcon from 'components/svg/ChevronUp'
import ChevronDownIcon from 'components/svg/ChevronDown'

const TableHeader = ({
  headerGroups,
  isSortable = true,
  rowClassName = 'flex-row',
}) => {
  const getOrderIcon = (column) => {
    if (column?.hideSortIcon) return <></>
    return column.isSorted ? (
      column.isSortedDesc ? (
        <ChevronDownIcon size={{ height: '12px', width: '17px' }} />
      ) : (
        <ChevronUpIcon size={{ height: '12px', width: '17px' }} />
      )
    ) : (
      <ChevronExpandIcon size="16px" />
    )
  };


  return (
    <thead>
      {
        // Loop over the header rows
        headerGroups.map((headerGroup) => (
          // Apply the header row props
          <tr {...headerGroup.getHeaderGroupProps()}>
            {
              // Loop over the headers in each row
              headerGroup.headers.map((column, index) => {

                return (
                  // Apply the header cell props
                  <th
                    className={`${rowClassName} ${column.className || ''}`}
                    {...(isSortable &&
                      column.getHeaderProps(column.getSortByToggleProps()))}
                    key={index}
                  >
                    <span className="u-fs-xs u-flex u-flex-center-hz">
                      {isSortable && <div className="u-mg-right-s ">{getOrderIcon(column)}</div>}
                      {column.Filter ? column.render('Filter') : null}
                      <span className="u-semibold">
                        {column.render('Header')}
                      </span>
                    </span>
                  </th>
                )
              } )
            }
          </tr>
        ))
      }
    </thead>
  )
};

const columnProp = shape({
  getHeaderProps: func.isRequired,
  getSortByToggleProps: func,
  render: func.isRequired,
})

const headerGroupProp = shape({
  getHeaderGroupProps: func.isRequired,
  headers: arrayOf(columnProp).isRequired,
})

TableHeader.propTypes = {
  headerGroups: arrayOf(headerGroupProp).isRequired,
  isSortable: bool,
  rowClassName: string,
}

export default TableHeader
