import React from 'react'
import { useAuth } from 'hooks/useAuth'
import { useAlert } from 'hooks/useAlert'
import { useHistory } from 'react-router-dom'
import { privateRoutes } from 'router/routes'

import AutoDiagProvider, { useAutoDiag, AUTODIAG_STATUS_DONE } from 'pages/protected/Autodiag/context'
import * as ScopingMeetingApi from 'services/api/scoping-meeting/ScopingMeetingApi'
import { PAID_STATUS, REALIZED_STATUS } from 'utils/domain/scopingMeetings'
import { isCommitmentCharterAlreadySigned } from 'utils/domain/user'
import { isObjectEmpty } from 'utils/validation'

const CheckBeneficiaryAccessWithProvider = () => {
    const history = useHistory()
    const { user } = useAuth()
    const { addAlert } = useAlert()
    const { fetchAutoDiags } = useAutoDiag()

    // The master mail is a unique mail that is allowed to infringe roadmap steps
    const isMasterEmail = user.entity_data?.mail === process.env.REACT_APP_MASTER_EMAIL

    const checkIsScopingMeetingDone = async () => {
        try {
            const { res: scopingMeeting, getError } = await ScopingMeetingApi.getMyScopingMeeting()
            if (getError()) throw getError()
            return scopingMeeting?.framing_meeting_status === REALIZED_STATUS || scopingMeeting?.framing_meeting_status === PAID_STATUS
        } catch(error) {
            console.error(error?.toString())
            return false
        }
    }

    const checkIsCharterDone = () => {
        return isCommitmentCharterAlreadySigned(user)
    }

    const checkAutoDiagDone = (autodiags = []) => {
        return !isObjectEmpty(autodiags) ? autodiags?.some(({ diag_status }) => diag_status === AUTODIAG_STATUS_DONE) : false
    }

    const checkCurrentPathAccess = async () => {
        const currentPathname = history.location?.pathname
        // Can access scoping meeting ?
        if (currentPathname?.startsWith(privateRoutes.scopingMeeting?.pattern)) {
            const autoDiags = await fetchAutoDiags()
            if (!checkAutoDiagDone(autoDiags)) {
                addAlert('error', "Vous n'avez pas encore finalisé votre autodiagnostic")
                history.push(privateRoutes.home.path)
            }
        }
        // Can access commitment charter ?
        else if (currentPathname?.startsWith(privateRoutes.commitmentCharter.path)) {
            const isScopingMeetingDone = await checkIsScopingMeetingDone()
            if (!isScopingMeetingDone) {
                addAlert('error', "Vous n'avez pas encore effectué votre réunion de cadrage")
                history.push(privateRoutes.home.path)
            }
        }
        // Can access catalog ?
        else if (currentPathname?.startsWith(privateRoutes.catalog?.pattern)) {
            const isCharterDone = checkIsCharterDone()
            if (!isCharterDone) {
                addAlert('error', "Vous n'avez pas encore signé la charte d'accès")
                history.push(privateRoutes.home.path)
            }
        }
    }

    React.useEffect(() => {
        !isMasterEmail && checkCurrentPathAccess()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <></>
}

const CheckBeneficiaryAccess = () => {
    const { user } = useAuth()

    return (
        <AutoDiagProvider autodiagUser={user}>
            <CheckBeneficiaryAccessWithProvider />
        </AutoDiagProvider>
    )
}

export default CheckBeneficiaryAccess
