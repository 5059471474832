import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import Information from './Information'

const RowInformations = ({
    informations,
    informationClassName = '',
    className = '',
    classNameLabel = '',
    classNameValue = ''
}) => (
    <div className={`u-flex u-flex-dir-col ${className}`}>
        <div className={`u-flex u-flex-dir-row ${informations.length > 1 ? 'u-flex-around' : ''}`}>
            {informations.map((information, index) => 
                <Information
                    key={index} 
                    className={`${informations.length > 1 ? 'u-flex-around' : ''} ${informationClassName}`}
                    classNameLabel={classNameLabel}
                    classNameValue={classNameValue}
                    label={information.label} 
                    value={information.value} 
                />
            )}
        </div>
    </div>
)

RowInformations.propTypes = {
    informations: arrayOf(
        shape({
            label: string,
            value: string,
        })
    ),
    informationClassName: string,
    className: string,
    classNameLabel: string,
    classNameValue: string
}

export default RowInformations