import React, { useEffect, useState } from 'react'

import Card from 'components/platform/Card'
import Emoji from 'components/shared/Emoji'
import ModalConfirmation from 'components/shared/ModalConfirmation'
import Table from 'components/shared/Table'
import DownloadIcon from 'components/svg/Download'

import { useAlert } from 'hooks/useAlert'
import { useModal } from 'hooks/useModal'

import { cancelScopingMeeting, getMyScopingMeeting } from 'services/api/scoping-meeting/ScopingMeetingApi'
import dateService from 'services/date'

import saveBlob from 'utils/saveBlob'
import { getColor } from 'utils/styles'
import { REALIZED_STATUS, PAID_STATUS, hasAScopingMeeting } from 'utils/domain/scopingMeetings' 
import { isNotEmpty } from 'utils/validation'

const columns = [
    {
        Header: 'Date de la demande',
        accessor: (data) => dateService.formatDate(data?.create_date),
    },
    {
        Header: 'Raison sociale de l\'intervenant',
        accessor: 'provider_data.structureName',
    },
    {
        Header: 'Email de l\'intervenant',
        accessor: 'provider_data.contactEmail',
    },
    {
        Header: 'Téléphone',
        accessor: 'provider_data.structurePhonenumber',
    }
]

const ScopingMeetingMonitoring = () => {
    const { addAlert } = useAlert()
    const { showModal } = useModal()

    const [myScopingMeeting, setMyScopingMeeting] = useState({})
    
    const initScopingMeetingData = async () => {
        try {
            const { res: scopingMeeting, getError } = await getMyScopingMeeting()
            if (getError()) throw getError()

            setMyScopingMeeting(scopingMeeting)
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }

    useEffect(() => {
        initScopingMeetingData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const hasPlannedAScopingMeeting = hasAScopingMeeting(myScopingMeeting)

    const displayReport = () => {
        const url = myScopingMeeting?.framing_meeting_media_rapport_url?.full_media_url
        window.open(url || '', '_blank').focus()
    }

    const downloadReport = async () => {
        try {
            const extension = myScopingMeeting?.framing_meeting_media_rapport_url?.media_url?.split('.').pop()
            const report = await fetch(myScopingMeeting?.framing_meeting_media_rapport_url?.full_media_url)
            const documentTitle = `mon-rapport-de-reunion-de-cadrage-${dateService.getCurrentDate('dd-MM-y')}.${extension}`
            const blob = await report.blob()
            saveBlob(blob, documentTitle)
        } catch(error) {
            addAlert('error', error?.toString())
        }
    }

    const cancelMyScopingMeeting = async () => {
        try {
            const { getError } = await cancelScopingMeeting()
            if (getError()) throw getError()

            setMyScopingMeeting({})
            addAlert('success', 'Votre réunion de cadrage a bien été annulée')
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }

    const handleCancel = () => {
        
        showModal(
            ModalConfirmation,
            {
                title: 'Annuler la demande de réunion de cadre',
                body: 'En cliquant sur le bouton "Confirmer", vous annulez votre demande de réunion de cadrage.',
                validate: cancelMyScopingMeeting,
                closeAfterValidation: true
            }
        )
    }

    const isScopingMeetingRealizedOrPaid = myScopingMeeting.framing_meeting_status === REALIZED_STATUS || myScopingMeeting.framing_meeting_status === PAID_STATUS
 
    const meetingReportUrl = myScopingMeeting.framing_meeting_media_rapport_url

    const scopingMeetingDate = () => {
        if(isScopingMeetingRealizedOrPaid) return `réalisé le ${dateService.formatDate(myScopingMeeting.framing_meeting_date)}`
        if(isNotEmpty(myScopingMeeting?.planned_date)) return `planifié le ${dateService.formatDate(myScopingMeeting.planned_date)}`
        else return `demandé le ${dateService.formatDate(myScopingMeeting.create_date)}`    
    }

    if(!hasPlannedAScopingMeeting) {
        return (
            <p className="u-center u-mg-top-m">
                Vous n'avez pas encore prévu de réunion de cadrage <Emoji label="visage triste" symbol="😟" />
            </p>
        )
    }

    return (
        <div className='u-flex u-flex-dir-col u-pd-hz-xl'>
            <div className="u-primary u-bold u-mg-left-m u-mg-bottom-l">
                Retrouver les informations de ma réunion de cadrage :
            </div>
            <Card
                title={`Entretien ${scopingMeetingDate()}`}
                className="u-pd-top-xl u-pd-bottom-xl u-pd-hz-xl u-mg-bottom-l u-mg-hz-l"
            >
                <Table columns={columns} data={[myScopingMeeting]} isSortable={false} hasSearchBar={false} />
            </Card>
            <div className='u-flex u-mg-hz-auto'>
                { isScopingMeetingRealizedOrPaid && meetingReportUrl &&
                    <>
                        <button
                            type="button" onClick={displayReport}
                            className="c-btn c-btn--secondary u-pd-hz-l u-uppercase"
                        >
                            Consulter mon rapport
                        </button>
                        <button className="u-mg-left-m" type="button" onClick={downloadReport} aria-label="Télécharger mon rapport">
                                <DownloadIcon className="vertical-align" color={getColor().primary} />
                        </button>
                    </>
                }
                { isScopingMeetingRealizedOrPaid && !meetingReportUrl &&
                    <p className="u-black u-mg-bottom-m u-italic u-grey90">        
                        Aucun rapport transmis pour le moment
                    </p>
                }
                { !isScopingMeetingRealizedOrPaid &&
                    <button
                        type="button" onClick={handleCancel}
                        className="c-btn c-btn--secondary u-pd-hz-l"
                    >
                        Annuler ma demande de réunion
                    </button>
                }
            </div>
        </div>
    )
}

export default ScopingMeetingMonitoring