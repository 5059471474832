import React from 'react'
import ProtectedLayout from 'layouts/Protected'
import { useAuth } from 'hooks/useAuth'
import { useAlert } from 'hooks/useAlert'
import { getKeywords } from 'components/auth/FormSignup'
import * as UserApi from 'services/api/UserApi'
import { isValidFrenchPhoneNumber, isNotEmpty } from 'utils/validation'
import FormElement from 'components/shared/FormElement'
import { equipmentList } from 'components/catalog/Equipment/list'
import { string } from 'prop-types'
import { getCoordinatesFromAdress } from 'services/api/UtilsApi'
import { objectsHaveDiverged } from 'utils/functions'
import { scrollToTop } from 'utils/scrollTo'

const defaultFormEvents = {
    ready: true,
    editable: false
}

const ProviderAccountPage = ({ className }) => {
    const { user, refreshUser } = useAuth()
    const { addAlert } = useAlert()
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)
    const [formFields, setFormFields] = React.useState({})
    const [showGlobalError, setShowGlobalError] = React.useState(false)
    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)

    const getFormFieldsFromUserData = (userData) => {
        return {
            lockers: 'Non',
            structureName: '',
            mail: '',
            contactEmail: '',
            contactLastname: '',
            contactFirstname: '',
            structurePhonenumber: '',
            website: '',
            structureAddress: '',
            structurePostCode: '',
            structureCity: '',
            bike_hitch: 'Non',
            bike_pumps: 'Non',
            bike_secure_storage: 'Non',
            bike_shelter: 'Non',
            bike_toolkit: 'Non',
            france_installation: 'Non',
            dom_tom_installation: 'Non',
            france_supply: 'Non',
            dom_tom_supply: 'Non',
            ...userData,
        }
    }

    React.useEffect(() => {
        setFormFields(formField => ({
            ...formField,
            ...getFormFieldsFromUserData(user?.entity_data)
        }))
    }, [user])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
            ? target.checked
            : target.type === 'file'
                ? target
                : target.value
        const name = target.name

        setFormFields({
            ...formFields,
            [name]: value
        })
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault()
        // Validate inputs
        const formReady = !(errors.length > 0)

        if (!formReady) {
            setShowLocalErrors(true)
            setShowGlobalError(true)
            addAlert('error', 'Erreur(s) dans le formulaire.')
            scrollToTop()
        } else {
            // hide error
            setShowGlobalError(false)
            setShowLocalErrors(false)
            // prevent form to be submitted again
            setFormEvents({
                ...formEvents,
                ready: false
            })

            // Manage API
            const editedUser = {
                entity_id: user?.entity_id,
                entity_data: formFields,
                status: user?.status,
                keywords: getKeywords(formFields),
                last_row_hash: user?.row_hash
            }

            // Recalculate coordinates if address has changed
            if (objectsHaveDiverged(user.entity_data, formFields, ['structureAddress', 'structurePostCode'])) {
                console.log('Coordinates have changed')
                const coordinates = await getCoordinatesFromAdress(formFields?.structureAddress, formFields?.structurePostCode)                

                if (isNotEmpty(coordinates)) {
                    editedUser.entity_data['latitude'] = coordinates[1]
                    editedUser.entity_data['longitude'] = coordinates[0]
                } else {
                    addAlert('error', 'Adresse non reconnue, code postal inexistant.')
                    setShowGlobalError(true)
                    return
                }
            }

            updateUser(editedUser)
        }
    }

    const updateUser = async (userToUpdate) => {
        try {
            const { getError } = await UserApi.updateUser(userToUpdate)
            if (getError()) throw getError()

            addAlert('success', 'Modification enregistrée')
            refreshUser()
            toggleEditableForm()
        } catch(error) {
            addAlert('error', error?.toString())
            setShowGlobalError(true)
        } finally {
            setFormEvents(formEvents => ({
                ...formEvents,
                ready: true
            }))
        }
    }

    const cancelModifications = () => {
        toggleEditableForm()
        setFormFields(getFormFieldsFromUserData(user?.entity_data))
    }

    const toggleEditableForm = () => {
        setFormEvents({
            ...formEvents,
            editable: !formEvents.editable
        })
    }

    return (
        <ProtectedLayout className="u-pd-hz-m u-pd-top-m u-pd-hz-0@main u-pd-top-xl@main">
            <h1 className="c-h1 u-secondary u-center u-uppercase u-mg-bottom-xl">Mon compte</h1>
            <form className={className ? className : ''}>
                {showGlobalError &&
                    <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur lors de la validation du formulaire.</p>
                }
                {/* Structure */}
                <div className="u-pd-top-m u-pd-top-xl@main u-mg-bottom-l">
                    <div className="l-container-lg">
                        <h2 className="c-h2 u-bold u-primary u-mg-bottom-l">Information de mon entreprise</h2>
                        <div className="l-grid u-mg-negative-hz-m ">
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Raison sociale */}
                                <FormElement
                                    value={formFields.raison_sociale}
                                    name="raison_sociale"
                                    type="text"
                                    label="Raison sociale"
                                    className="u-mg-bottom-m"
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={true}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Lastname */}
                                <FormElement
                                    value={formFields.contactLastname}
                                    name="contactLastname"
                                    type="text"
                                    label="Nom du responsable"
                                    className="u-mg-bottom-m"
                                    classNameInput="u-bg-transparent"
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                    required={formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Firstname */}
                                <FormElement
                                    value={formFields.contactFirstname}
                                    name="contactFirstname"
                                    type="text"
                                    label="Prénom du responsable"
                                    className="u-mg-bottom-m"
                                    classNameInput="u-bg-transparent"
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                    required={formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Tel */}
                                <FormElement
                                    value={formFields.structurePhonenumber}
                                    name="structurePhonenumber"
                                    type="text"
                                    label="Téléphone"
                                    className="u-mg-bottom-m"
                                    customRules={[
                                        {
                                            key: 'structure-phone-invalid',
                                            check: (value) => isValidFrenchPhoneNumber(value),
                                            message: "Le format de numéro de téléphone n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                    required={formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* website */}
                                <FormElement
                                    value={formFields.website}
                                    name="website"
                                    type="text"
                                    label="Site internet"
                                    className="u-mg-bottom-m"
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                    required={formEvents.editable}
                                />
                            </div>

                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Address */}
                                <FormElement
                                    value={formFields.structureAddress}
                                    name="structureAddress"
                                    type="text"
                                    label="Adresse"
                                    className="u-mg-bottom-m"
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                    required={formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* structurePostCode */}
                                <FormElement
                                    value={formFields.structurePostCode + ''}
                                    name="structurePostCode"
                                    type="number"
                                    label="Code postal"
                                    className="u-mg-bottom-m"
                                    maxLength={5}
                                    customRules={[
                                        {
                                            key: 'structurePostCode-invalid',
                                            check: (value) => {
                                                return value ? (value.length === 5) : true
                                            },
                                            message: "Le code postal n'est pas valide."
                                        }
                                    ]}
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                    required={formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* City */}
                                <FormElement
                                    value={formFields.structureCity}
                                    name="structureCity"
                                    type="text"
                                    label="Ville"
                                    className="u-mg-bottom-m"
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={!formEvents.editable}
                                    required={formEvents.editable}
                                />
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Email de connexion */}
                                <FormElement
                                    value={formFields.mail}
                                    name="mail"
                                    type="email"
                                    label="E-mail de connexion"
                                    className="u-mg-bottom-s"
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={true}
                                />
                                <p className="u-fs-xxs u-justify u-mg-bottom-m">Cet email correspond à votre identifiant pour vous connecter à la plateforme, c’est aussi celui qui sera utilisé si vous souhaitez modifier votre mot de passe de connexion. Il ne peut plus être modifié jusqu’à la fin du programme.</p>
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* Email de contact */}
                                <FormElement
                                    value={formFields.contactEmail}
                                    name="contactEmail"
                                    type="email"
                                    label="E-mail de contact"
                                    className="u-mg-bottom-s"
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    showErrors={showLocalErrors}
                                    disabled={!formEvents.editable}
                                    required={formEvents.editable}
                                />
                                <p className="u-fs-xxs u-justify u-mg-bottom-m">Cet email désigne l’adresse sur laquelle vous seront envoyés tous les emails de la plateforme (confirmations de demandes, informations sur votre parcours, validations de documents, récapitulatifs de commandes de prestations, bordereaux de versement de prime).</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="u-flex u-flex-center-hz u-pd-top-l u-mg-negative-hz-m u-pd-hz-m u-mg-hz-0@main u-pd-hz-0@main">
                    {formEvents?.editable ?
                        <>

                            <div className="u-pd-l">
                                <button
                                    type="button"
                                    className="c-btn c-btn--white-bordered c-btn--m u-pd-hz-xl u-mg-bottom-xl"
                                    style={{ width: '100%', maxWidth: '460px' }}
                                    onClick={cancelModifications}
                                >
                                    Annuler
                                </button>
                            </div>
                            <div className="u-flex u-flex-center-hz u-pd-l" style={{ minWidth: '250px' }}>
                                <button
                                    type="submit"
                                    className="c-btn c-btn--primary c-btn--m u-pd-hz-xl u-mg-bottom-xl"
                                    style={{ width: '100%', maxWidth: '460px' }}
                                    onClick={handleSubmit}
                                >
                                    Enregistrer
                                </button>
                            </div>
                        </>
                        :
                        <button
                            type="button"
                            className="c-btn c-btn--primary c-btn--m u-pd-hz-l u-mg-bottom-xl"
                            style={{ width: '100%', maxWidth: '460px' }}
                            onClick={toggleEditableForm}
                        >
                            Modifier
                        </button>
                    }
                </div>

                <p className="u-pd-bottom-xl u-center">
                    Si vous souhaitez modifier votre raison sociale ou votre adresse e-mail,<br />
                    contactez le Programme OEPV en envoyant un mail à l’adresse suivante : catalogue@employeurprovelo.fr
                </p>

                {/* expertises */}
                <div className="u-bg-secondary u-pd-m  u-pd-hz-0@main u-pd-vt-xl@main">
                    <div className="l-container-lg">
                        <div className="l-grid u-mg-negative-hz-m">
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* equipements*/}
                                <h3 className="c-h3 u-primary u-mg-bottom-l">Equipements</h3>
                                <FormElement 
                                    value={formFields.bike_hitch === 'Oui'}
                                    name="bike_hitch"
                                    type="checkbox"
                                    checkboxLabel="Attaches"
                                    className="u-mg-bottom-m"
                                    classNameInput="u-bg-transparent"
                                    disabled={true}
                                />

                                <FormElement 
                                    value={formFields.bike_shelter === 'Oui'}
                                    name="bike_shelter"
                                    type="checkbox"
                                    checkboxLabel="Abris"
                                    className="u-mg-bottom-m"
                                    classNameInput="u-bg-transparent"
                                    disabled={true}
                                />

                                <FormElement 
                                    value={formFields.bike_secure_storage === 'Oui'}
                                    name="bike_secure_storage"
                                    type="checkbox"
                                    checkboxLabel="Consignes"
                                    className="u-mg-bottom-m"
                                    classNameInput="u-bg-transparent"
                                    disabled={true}
                                />

                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* optional equipments */}
                                <h3 className="c-h3 u-primary u-mg-bottom-l">Equipements complémentaires</h3>
                                {equipmentList && equipmentList.filter(i => i.optional).map((equipement, index) => (
                                    <div className="" key={index}>
                                        <FormElement
                                            value={formFields[equipement.id] === 'Oui'}
                                            name={equipement.id}
                                            type="checkbox"
                                            checkboxLabel={equipement.label}
                                            className="u-pd-s "
                                            classNameOption="u-flex-start-vt"
                                            onValidate={handleValidation}
                                            onChange={handleInputChange}
                                            disabled={true}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="l-col-12 l-col-6@main u-pd-m">
                                {/* // TODO, probablement supprimer la colonne installation et gérer les nouvelles (france/dom-tom)*/}
                                {/* installation */}
                                <FormElement
                                    value={formFields.installation}
                                    name="contactRole"
                                    type="text"
                                    label="Pose des équipements"
                                    className="u-mg-bottom-m"
                                    classNameInput="u-bg-transparent"
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <p className="u-center">
                        Si vous souhaitez modifier votre offre d’équipements, écrivez-nous à catalogue@employeurprovelo.fr
                    </p>
                </div>
            </form>
        </ProtectedLayout>
    )
}

ProviderAccountPage.propTypes = {
    className: string
}

export default ProviderAccountPage
