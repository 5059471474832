import React from 'react'
import { string, func, bool, object, shape } from 'prop-types'
import FormElement from 'components/shared/FormElement'

const EquipmentProviderServices = ({
  formFields = {},
  formEvents = {},
  showLocalErrors = {},
  handleValidation = () => {},
  handleInputChange = () => {}
}) => (
  <div className='u-flex u-flex-dir-col u-mg-top-m u-mg-right-auto'>
    <h5 className='u-primary u-mg-bottom-m'>Équipements</h5>
    <FormElement 
      value={formFields.bikeHitch}
      name="bikeHitch"
      type="checkbox"
      checkboxLabel="Attaches"
      className="u-mg-bottom-m"
      classNameInput="u-bg-transparent"
      showErrors={showLocalErrors}
      onValidate={handleValidation}
      onChange={handleInputChange}
      disabled={!formEvents.editable}
    />
    <FormElement 
      value={formFields.bikeShelter}
      name="bikeShelter"
      type="checkbox"
      checkboxLabel="Abris"
      className="u-mg-bottom-m"
      classNameInput="u-bg-transparent"
      showErrors={showLocalErrors}
      onValidate={handleValidation}
      onChange={handleInputChange}
      disabled={!formEvents.editable}
    />      
    <FormElement 
      value={formFields.bikeSecureStorage}
      name="bikeSecureStorage"
      type="checkbox"
      checkboxLabel="Consignes"
      className="u-mg-bottom-m"
      classNameInput="u-bg-transparent"
      showErrors={showLocalErrors}
      onValidate={handleValidation}
      onChange={handleInputChange}
      disabled={!formEvents.editable}
    />
  </div>
)

EquipmentProviderServices.propTypes = {
  formFields: shape({
    structureName: string,
    structureSiret: string,
    structureAddress: string,
    structureCity: string,
    structurePostCode: string,
    structurePhoneNumber: string,
    emailConnexion: string,
    beneficiaryEmail: string,
    structureEmployeeQuantity: string,
    structureEmployeeCategory: string
  }),
  setFormFields: func,
  formEvents: object,
  showLocalErrors: bool,
  handleValidation: func,
  handleInputChange: func
}

export default EquipmentProviderServices