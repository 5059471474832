import React, { useEffect, useState } from 'react'

import { useModal } from 'hooks/useModal'
import { useAlert } from 'hooks/useAlert'
import { getEmployeeCategoryLabelFromCategoryValue } from 'utils/domain/beneficiary'
import { getMyScopingMeetings, updateScopingMeeting } from 'services/api/scoping-meeting/ScopingMeetingApi'
import dateService from 'services/date'
import { getColor } from 'utils/styles'

import columns from 'components/my-scoping-meetings/columns'
import { REALIZED_STATUS, getLabelFromMeetingStatus } from 'utils/domain/scopingMeetings' 

import Card from 'components/platform/Card'
import Table from 'components/shared/Table'
import DownloadIcon from 'components/svg/Download'
import RealizedScopingMeetingModal from 'components/my-scoping-meetings/RealizedScopingMeetingModal'

import './styles.scss'
import { exportXlsx } from 'utils/file'

const columnsToExport = {
    create_date: 'Date de création',
    structureName: 'Raison sociale',
    structureEmployeeCategory: 'Nombre d\'employés',
    contactPhonenumber: 'Téléphone',
    contactEmail: 'Email',
    postalCode: 'Code postal',
    meetingStatusLabel: 'Statut',
    framingMeetingDate: 'Date de la réunion',
    hasMeetingReport: 'Rapport de cadrage'
}

const MyScopingMeetings = () => {
    const { showModal } = useModal()
    const { addAlert } = useAlert()
    const [myScopingMeetings, setMyScopingMeetings] = useState([])

    useEffect(() => {
        fetchMyScopingMeetings()
    }, [])

    const fetchMyScopingMeetings = async () => {
        try {
            const { res: scopingMeetings, getError } = await getMyScopingMeetings()
            if (getError()) throw getError()

            const mappedScopingMeetings = Array.isArray(scopingMeetings) ? 
                scopingMeetings.map((meeting, index) => {
                    const meetingBeneficiary = meeting?.entity_data
                    return {
                        ...meeting,
                        id: index,
                        framingMeetingDate: meeting.framing_meeting_date ? dateService.formatDate(meeting.framing_meeting_date) : null,
                        create_date: dateService.formatDate(meeting.create_date),
                        structureName: meetingBeneficiary?.structureName,
                        structureEmployeeCategory: meetingBeneficiary?.structureEmployeeCategory ? getEmployeeCategoryLabelFromCategoryValue(meetingBeneficiary?.structureEmployeeCategory) : null,
                        contactPhonenumber: meetingBeneficiary?.structurePhonenumber,
                        contactEmail: meetingBeneficiary?.contactEmail,
                        postalCode: meetingBeneficiary?.structurePostCode,
                        meetingStatus: meeting.framing_meeting_status,
                        meetingStatusLabel: getLabelFromMeetingStatus(meeting.framing_meeting_status),
                        download: meeting?.framing_meeting_media_rapport_url?.full_media_url,
                        hasMeetingReport: meeting?.framing_meeting_media_rapport_url?.full_media_url ? 'Oui' : 'Non'
                    }
                })
                : []
            
            setMyScopingMeetings(mappedScopingMeetings)
        } catch(error){
            addAlert('error', error?.toString())
        }
    }

    const updateScopingMeetingStatus = async (entityId, meetingStatus, idRow, oldMeetingStatus) => {
        const scopingMeetingToUpdate = {
            entity_id: entityId,
            framing_meeting_status: meetingStatus
        }

        try {
            const { getError } = await updateScopingMeeting(scopingMeetingToUpdate)
            if (getError()) throw getError()
            fetchMyScopingMeetings()
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
            setDataStatusFromId(idRow, oldMeetingStatus)
        }
    }

    const setDataStatusFromId = (id, meetingStatus) => {
        setMyScopingMeetings(prevScopingMeeting => {
            return prevScopingMeeting.map(data => data.id === id ? { ...data, meetingStatus } : data)
        })
    }

    const handleEditScopingMeetingStatus = (originalRowValues, newMeetingStatus) => {
        const entityId = originalRowValues.entity_id
        const idRow = originalRowValues.id
        const oldMeetingStatus = originalRowValues.meetingStatus
        
        setDataStatusFromId(originalRowValues.id, newMeetingStatus)

        if(newMeetingStatus === REALIZED_STATUS)
            showRealizedScopingMeetingModal(entityId, idRow, oldMeetingStatus)
        else
            updateScopingMeetingStatus(entityId, newMeetingStatus, idRow, oldMeetingStatus)
        
    } 

    const showRealizedScopingMeetingModal = (entityId, idRow, oldMeetingStatus) => {
        showModal(RealizedScopingMeetingModal,
            {
                entityId,
                cancel: () => setDataStatusFromId(idRow, oldMeetingStatus),
                fetchMyScopingMeetings: fetchMyScopingMeetings
            },
            { isClosable: false }
        )
    }

    return (
        <div className='u-mg-hz-xxl u-mg-bottom-l my-scoping-meetings'>
            <h1 className="c-h1 u-secondary u-bold u-center u-mg-top-l u-mg-bottom-l u-uppercase">
                Mes réunions de cadrage
            </h1>
            <div className="u-flex u-flex-between u-flex-center-vt">
                <p className="u-primary u-bold u-mg-bottom-xl">Retrouver toutes mes réunions de cadrage:</p>
                <button
                    className="c-btn c-btn--primary c-btn--m u-pd-hz-l u-mg-bottom-xl"
                    onClick={() => exportXlsx(columnsToExport, myScopingMeetings, 'export-mes-réunions-de-cadrage')}
                >
                    <DownloadIcon className="u-mg-right-l" color={getColor().white} />
                    Exporter mes réunions
                </button>
            </div>
            <Card className="u-pd-top-xl u-mg-bottom-l">
                <Table
                    columns={columns({ setData: handleEditScopingMeetingStatus, refreshData: fetchMyScopingMeetings })}
                    data={myScopingMeetings}
                    specificSortBy={[{ id: 'meetingStatus', asc: true }]}
                    isPaginable={true}
                />
            </Card>
        </div>
    )
}

export default MyScopingMeetings