import React from 'react'

const Step3 = () => (
    <div className="u-pd-top-xl">
        Comme n'importe quel véhicule, <span className="u-primary u-bold">un vélo s'entretient et se répare</span>, pour pouvoir toujours compter sur lui et apprendre à surmonter les petits contretemps comme une crevaison. 
        Vous pouvez contribuer à développer la "vélonomie" (contraction de vélo et autonomie) de vos équipes, pour renforcer la fiabilité et le plaisir de se déplacer à vélo en :
        <ul className="u-pd-left-l u-mg-top-m u-mg-bottom-m">
            <li>
                mettant à disposition <span className="u-secondary u-bold">des outils</span>, 
            </li>
            <li>
                faisant intervenir <span className="u-secondary u-bold">un prestataire pour l'entretien ou la réparation</span> (action obligatoire dans le cadre du cahier des charges employeur pro-vélo),
            </li>
            <li>
                organisant des <span className="u-secondary u-bold">séances d'essai</span>,
            </li>
            <li>
                mettant <span className="u-secondary u-bold">à disposition une flotte de vélos</span>...
            </li>
        </ul>
    </div>
)

export default Step3


