import React from 'react'
import { NavLink } from 'react-router-dom'

import * as UtilsApi from 'services/api/UtilsApi'
import { isNotEmpty } from 'utils/validation'

const MAX_SIGNUP_DATE = 20241231235900000

const RegisterBlock = ({ className }) => {
    const [isSignupAvailable, setIsSignupAvailable] = React.useState(true)

    const fetchCurrentDate = async () => {
        const getDateFromServer = await UtilsApi.getDateFromServer()
        if(isNotEmpty(getDateFromServer) && Number(getDateFromServer?.serverdate) > MAX_SIGNUP_DATE) setIsSignupAvailable(false)
    }

    React.useEffect(() => {
        fetchCurrentDate()
    }, [])


    return (
        <div className={(className ? className : '')}>
            <h2 className="c-h2 u-bold u-center u-mg-bottom-xl">Découvrez le Programme "Objectif Employeur Pro-Vélo"</h2>
            <p className="u-mg-bottom-xl">Le Programme Objectif Employeur Pro-Vélo (OEPV) vise à accompagner les employeurs dans le développement d’une culture vélo. Des mesures incitatives permettent aux employeurs de déployer des équipements et services « Pro-Vélo » pour les déplacements de leurs collaborateurs, clients et fournisseurs. Pour en bénéficier, inscrivez-vous au Programme OEPV ! </p>
            <div className="u-flex u-flex-center-hz u-mg-top-l">
               {isSignupAvailable && <NavLink to="/inscription" className="c-btn c-btn--white u-pd-hz-xl u-mg-bottom-l">Inscription</NavLink>}
               {!isSignupAvailable && <p className='u-center u-bold'>Les inscriptions ne sont plus possibles</p>}
            </div>
        </div>
    )
}

export default RegisterBlock
