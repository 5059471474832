import React from 'react'
import FormDownloadData from 'components/admin/FormDownloadData'
import BackOfficeLayout from 'layouts/back-office'
import Card from 'components/platform/Card'

const index = () => {
  return (
    <BackOfficeLayout>
      <div className="u-flex u-flex-dir-col">
          <h1 className="c-h1 u-bold u-primary">Pilotage du programme</h1>
          <Card className="u-flex u-flex-dir-col u-flex-start-vt u-mg-top-l" title="Exporter les listes">
            <FormDownloadData />
          </Card>
      </div>
    </BackOfficeLayout>
  )
}

export default index
