import React from "react"
import UploadIcon from "components/svg/Upload"
import EyeSee from "components/svg/EyeSee"
import { fileHash } from "utils/hash"
import { isFileNotEmpty } from "utils/validation"
import { v4 as uuidv4 } from "uuid"
import ReactTooltip from "react-tooltip"

const VALID_EXTENSIONS = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'pdf', 'PDF']
const VALID_EXTENSIONS_NOPDF = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG']

const InputFile = ({
    value,
    defaultValue,
    forcePlaceholder = false,
    name,
    label = "",
    placeholder = defaultValue ? "Charger un nouveau fichier" : "Ajouter un fichier",
    disabled,
    readonly = false,
    // Styles
    style,
    className = "",
    classNameLabel = "",
    widthClassName = "u-max-w-90-percent",
    // Error management
    required = false,
    acceptPDF = true,
    maxFileSize = 20, // mo
    customRules,
    showErrors,
    // Handler
    onChange,
    onValidate
}) => {
    const [selectedFile, setSelectedFile] = React.useState(value || null)
    const [fileError, setFileError] = React.useState(false)
    const [sizeError, setSizeError] = React.useState(false)
    const [errors, setErrors] = React.useState([])
    const rules = React.useMemo(() => [
        {
            key: "input-empty",
            check: (value) => required ? isFileNotEmpty(value) : true,
            message: "Ce champ est obligatoire"
        },
        ...(customRules || [])
    ], [required]) // eslint-disable-line
    // customRules in dependencies array trigger an infinite loop,
    // because custom rules include a check property defining a new function,
    // preventing useMemo to return a valid shallow-comparison
    // or event useDeepMemo to return a valid deep-comparison

    const validate = React.useCallback((currentValue) => {
        let newErrors = []
        rules.forEach(rule => {
            if (!rule.check(currentValue)) {
                newErrors.push({
                    origin: name,
                    rule: rule.key,
                    message: rule.message
                })
            }
        })
        setErrors(newErrors)
        onValidate(name, newErrors)
    }, [rules, name, onValidate])

    // validate on mount and each time value change
    React.useEffect(() => {
        validate(value)
        return () => {
            // On unmounted errors and validation are no longer needed
            setErrors([])
            if (onValidate) onValidate(name, [])
        }
    }, [value, validate, onValidate, name]) // eslint-disable-line

    const getLabel = () => {
        if(forcePlaceholder) return forcePlaceholder
        return selectedFile ? selectedFile.fileName : placeholder
    } 

    const handleChange = async (event) => {
        const file = [...event.target.files][0]

        if (file) {
            const fileName = file.name
            const fileSize = file.size
            const fileExtension = fileName.split('.').pop()
            const fileType = file.type === 'application/pdf' ? 'application/pdf' : 'image/png'

            const validExtensions = acceptPDF ? VALID_EXTENSIONS : VALID_EXTENSIONS_NOPDF

            if (validExtensions.indexOf(fileExtension) === -1) {
                setFileError(true)
                return
            }

            if (fileSize > (maxFileSize * 1000000)) {
                setSizeError(true)
                return
            }

            const hash_file = await fileHash(file)

            // console.log("hash_pdf", hash_file);

            const reader = new FileReader()
            reader.addEventListener("loadend", (event) => {
                // Update the uploaded file type and header
                let header = ""
                let type = ""

                const arr = (new Uint8Array(event.target.result)).subarray(0, 4)
                for (let i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16)
                }

                switch (header) {
                    case "89504e47":
                        type = "image/png"
                        break
                    case "47494638":
                        type = "image/gif"
                        break
                    case "ffd8ffe0":
                    case "ffd8ffe1":
                    case "ffd8ffe2":
                    case "ffd8ffe3":
                    case "ffd8ffe8":
                        type = "image/jpeg"
                        break
                    case "25504446":
                        type = "application/pdf"
                        break
                    default:
                        type = "unknown"
                        break
                }
                // console.log(">>>>>>< type ",type)

                if (!type || type === 'unknown') {
                    setFileError(true)
                    return
                }

                // Create the blob file
                const blob = new Blob([new Uint8Array(reader.result)], { type: fileType })

                let inputValue = {
                    target: {
                        type: "file",
                        name: name,
                        id: uuidv4(),
                        value: hash_file,
                        fileName: fileName,
                        blob: blob,
                        extension: fileExtension
                    }
                }

                setFileError(false)
                setSizeError(false)
                setSelectedFile(inputValue.target)
                onChange(inputValue)
            })
            reader.readAsArrayBuffer(file)
        }

    }

    return (
        <>
            <div className={`c-form-group ${className} ${widthClassName}`} style={style}>
                <label className={"c-label u-mg-bottom-xs " + classNameLabel}>{label} {required && "*"}</label>

                {disabled
                    ?
                        <div>
                            {defaultValue
                                ? <a href={defaultValue?.full_media_url} target="_blank" className={"c-btn c-btn--download"} rel="noreferrer noopener">
                                    <EyeSee size={20} />
                                    <span className="u-mg-left-s">Voir le fichier existant</span>
                                </a>
                                : <span className="u-inline-block u-italic u-fs-xs u-mg-top-s">Aucun fichier existant</span>
                            }
                        </div>
                    :
                        <div className="u-flex">
                            <input
                                type="file"
                                className="u-sr-only"
                                id={name}
                                name={name}
                                onChange={(e) => !readonly && handleChange(e)}
                                onBlur={() => validate(value)}
                                disabled={disabled}
                                readOnly={readonly}
                                required={required}
                            />
                            <label className={`c-input-file ${(disabled ? "is-disabled" : "")} ${widthClassName}`} htmlFor={name}>
                                <UploadIcon size={20} />
                                <div className="u-ellipsis u-pd-left-m">
                                    {getLabel()}
                                </div>
                            </label>
                            {defaultValue?.full_media_url &&
                                <>
                                    <a
                                        href={defaultValue?.full_media_url}
                                        target="_blank"
                                        className={"c-btn"}
                                        style={{ minWidth: "50px", borderRadius: "50px" }}
                                        rel="noreferrer noopener"
                                        aria-label={"Voir le fichier existant"}
                                        data-tip={"Voir le fichier existant"}
                                    >
                                        <EyeSee size={20} />
                                    </a>
                                    <ReactTooltip backgroundColor={"#005a7c"} />
                                </>
                            }
                        </div>
                }

                {fileError &&
                    <p className="u-fs-xs u-danger">Les fichiers autorisés sont les images au format PNG ou JPG {acceptPDF && <>ainsi que les documents PDF</>}</p>
                }
                {sizeError &&
                    <p className="u-fs-xs u-danger">Votre fichier excède les {maxFileSize}mo autorisés.</p>
                }
                {/* Error messages */}
                {showErrors && errors && errors.map((error, i) => {
                    return <p key={i} className="u-fs-xs u-danger">{error.message}</p>
                })}
            </div>
        </>
    )
}

export default InputFile